/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitOrderingMethod = {
  Precycled: "precycled",
  OnlineOrder: "online_order",
  PartnerStore: "partner_store",
} as const;
export type BikeBenefitOrderingMethod =
  (typeof BikeBenefitOrderingMethod)[keyof typeof BikeBenefitOrderingMethod];

export function BikeBenefitOrderingMethodFromJSON(
  json: any,
): BikeBenefitOrderingMethod {
  return BikeBenefitOrderingMethodFromJSONTyped(json, false);
}

export function BikeBenefitOrderingMethodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderingMethod {
  return json as BikeBenefitOrderingMethod;
}

export function BikeBenefitOrderingMethodToJSON(
  value?: BikeBenefitOrderingMethod | null,
): any {
  return value as any;
}
