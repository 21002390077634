import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import {
  BikeBenefitContractRead,
  BikeBenefitContractState,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import { Tag, TagProps } from "@vapaus/ui-v2";

import { getIsContractEndingSoon } from "../utils/getIsContractEndingSoon";

interface OrderStatusProps extends Pick<TagProps, "size"> {
  order: Pick<BikeBenefitOrderRead, "state">;
  contract?: Pick<
    BikeBenefitContractRead,
    "state" | "cancellationReason" | "endDate"
  >;
}

export function OrderStatus({ order, contract, size }: OrderStatusProps) {
  const { t } = useTranslation();
  const { variant, text } = getTagProps(t, order, contract);

  return <Tag variant={variant} text={text} size={size} />;
}

interface GetTagPropsReturn {
  variant: TagProps["variant"];
  text: string;
}

function getTagProps(
  t: TFunction,
  order: OrderStatusProps["order"],
  contract?: OrderStatusProps["contract"],
): GetTagPropsReturn {
  switch (contract?.state) {
    case BikeBenefitContractState.CancelledPendingPayment:
    case BikeBenefitContractState.EndedPendingPayment:
      return {
        variant: "error",
        text: t("userV2:contractStatus.paymentRequired"),
      };
    case BikeBenefitContractState.Cancelled:
      return {
        variant: "cancelled",
        text: t("userV2:contractStatus.cancelled"),
      };
    case BikeBenefitContractState.Active:
      return getIsContractEndingSoon(contract?.endDate)
        ? {
            variant: "pending",
            text: t("userV2:contractStatus.activeEndingSoon"),
          }
        : { variant: "accepted", text: t("userV2:contractStatus.active") };
    case BikeBenefitContractState.Ended:
      return { variant: "done", text: t("userV2:contractStatus.ended") };
    case BikeBenefitContractState.Scheduled:
      return { variant: "pending", text: t("userV2:contractStatus.scheduled") };
    case BikeBenefitContractState.ActivePendingCorrection:
      return {
        variant: "pending",
        text: t("userV2:contractStatus.pendingCorrection"),
      };
    case BikeBenefitContractState.ActivePendingRevision:
      return {
        variant: "pending",
        text: t("userV2:contractStatus.pendingRevision"),
      };
    case BikeBenefitContractState.ActivePendingRedemption:
      return {
        variant: "pending",
        text: t("userV2:contractStatus.pendingRedemption"),
      };
    case BikeBenefitContractState.ActivePendingReturn:
      return {
        variant: "pending",
        text: t("userV2:contractStatus.pendingReturn"),
      };
    case BikeBenefitContractState.Invalidated:
      return {
        variant: "neutral",
        text: t("userV2:contractStatus.invalidated"),
      };
  }

  switch (order.state) {
    case BikeBenefitOrderState.Draft:
      return { variant: "pending", text: t("userV2:orderStatus.draft") };
    case BikeBenefitOrderState.Cancelled:
      return { variant: "cancelled", text: t("userV2:orderStatus.cancelled") };
    case BikeBenefitOrderState.ReadyForDelivery:
      return {
        variant: "waiting",
        text: t("userV2:orderStatus.pendingDelivery"),
      };
    case BikeBenefitOrderState.WaitingForPayment:
      return {
        variant: "error",
        text: t("userV2:orderStatus.paymentRequired"),
      };
  }

  return { variant: "neutral", text: t("userV2:orderStatus.unknown") };
}
