import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import {
  ResponseError,
  type ReturnProcessInvoicingAction,
  type ReturnProcessPlanInvoicingIn,
  type ReturnProcessPreliminaryData,
  type ReturnProcessRead,
  type StorageObjectRead,
  UserReturnProcessCreate,
  UserReturnProcessesApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userReturnProcessesApi = new UserReturnProcessesApi(
  apiConfiguration,
);

export const useGetReturnProcess = (
  returnProcessId?: string,
  options?: UseQueryOptions<ReturnProcessRead>,
) =>
  useQuery<ReturnProcessRead, ResponseError>(
    ["returnProcess", returnProcessId],
    () =>
      userReturnProcessesApi.userReturnProcessesGetReturnProcess({
        returnProcessId: returnProcessId as string,
      }),
    {
      ...options,
      enabled: !!returnProcessId,
    },
  );

export const useGetReturnProcessPreliminaryData = (
  bikeBenefitContractId?: string,
  isEndOfLeaseReturn?: boolean,
  options?: UseQueryOptions<ReturnProcessPreliminaryData>,
) =>
  useQuery<ReturnProcessPreliminaryData, ResponseError>(
    ["returnProcessPreliminaryData", bikeBenefitContractId],
    () =>
      userReturnProcessesApi.userReturnProcessesGetReturnProcessPreliminaryData(
        {
          bikeBenefitContractId: bikeBenefitContractId as string,
          isEndOfLeaseReturn: Boolean(isEndOfLeaseReturn),
        },
      ),
    options,
  );

export const useGetReturnProcessPlanInvoicing = (
  params: ReturnProcessPlanInvoicingIn,
  options?: UseQueryOptions<Array<ReturnProcessInvoicingAction>>,
) =>
  useQuery<Array<ReturnProcessInvoicingAction>, ResponseError>(
    ["returnProcessPlanInvoicing", params],
    () =>
      userReturnProcessesApi.userReturnProcessesUserPlanReturnProcessInvoicing({
        returnProcessPlanInvoicingIn: params,
      }),
    options,
  );

export const usePostReturnProcessUploadPicture = (
  options?: UseMutationOptions<StorageObjectRead, ResponseError, Blob>,
) =>
  useMutation<StorageObjectRead, ResponseError, Blob>(
    (file: Blob) =>
      userReturnProcessesApi.userReturnProcessesUserUploadReturnProcessPicture({
        file,
      }),
    options,
  );

export const useCreateReturnProcess = () =>
  useMutation<ReturnProcessRead, ResponseError, UserReturnProcessCreate>(
    (data) =>
      userReturnProcessesApi.userReturnProcessesUserCreateReturnProcess({
        userReturnProcessCreate: data,
      }),
  );
