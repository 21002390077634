/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SaleInvoiceLineType } from "./SaleInvoiceLineType";
import {
  SaleInvoiceLineTypeFromJSON,
  SaleInvoiceLineTypeFromJSONTyped,
  SaleInvoiceLineTypeToJSON,
} from "./SaleInvoiceLineType";

/**
 *
 * @export
 * @interface SaleInvoiceLineCreate
 */
export interface SaleInvoiceLineCreate {
  /**
   *
   * @type {SaleInvoiceLineType}
   * @memberof SaleInvoiceLineCreate
   */
  type: SaleInvoiceLineType;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLineCreate
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  vatRate: number;
}

/**
 * Check if a given object implements the SaleInvoiceLineCreate interface.
 */
export function instanceOfSaleInvoiceLineCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "quantity" in value;
  isInstance = isInstance && "vatRate" in value;

  return isInstance;
}

export function SaleInvoiceLineCreateFromJSON(
  json: any,
): SaleInvoiceLineCreate {
  return SaleInvoiceLineCreateFromJSONTyped(json, false);
}

export function SaleInvoiceLineCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLineCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: SaleInvoiceLineTypeFromJSON(json["type"]),
    description: json["description"],
    price: json["price"],
    quantity: json["quantity"],
    vatRate: json["vat_rate"],
  };
}

export function SaleInvoiceLineCreateToJSON(
  value?: SaleInvoiceLineCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: SaleInvoiceLineTypeToJSON(value.type),
    description: value.description,
    price: value.price,
    quantity: value.quantity,
    vat_rate: value.vatRate,
  };
}
