import styled, { css } from "styled-components";

import { Flex } from "@vapaus/ui-v2";

export const StyledRow = styled(Flex)`
  > div {
    width: 50%;
  }

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      flex-wrap: wrap;

      > div {
        width: 100%;
      }
    `)}
`;
