import styled, { AnyStyledComponent, css } from "styled-components";

import { Flex, Paper, StorefrontIcon, Typography } from "@vapaus/ui-v2";

type FeaturedShopLogoProps = {
  name: string;
  imageUrl?: string;
  onClick: () => void;
  size?: {
    height: number;
    width: number;
  };
};

export function FeaturedShopLogo({
  name,
  imageUrl,
  size,
  onClick,
}: FeaturedShopLogoProps) {
  return (
    <StyledRoot
      direction="column"
      align="center"
      gap="xs"
      onClick={onClick}
      fullWidth
      fullHeight
    >
      <StyledPaper shadow="sm" role="button" $height={size?.height}>
        <Flex fullWidth fullHeight justify="center">
          {imageUrl ? (
            <StyledLogoImage src={imageUrl} alt={name} />
          ) : (
            <StyledStorefrontIcon size="lg" color="neutral.main" />
          )}
        </Flex>
      </StyledPaper>
      <Typography weight="bold" align="center">
        {name}
      </Typography>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledPaper = styled(Paper)<{ $height?: number }>`
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : "100%")};
  border-radius: ${({ theme }) => theme.spacing.xs};
  aspect-ratio: 1;
  padding: 0;
  overflow: hidden;
`;

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledStorefrontIcon = styled(
  StorefrontIcon as unknown as AnyStyledComponent,
)`
  ${({ theme }) =>
    theme.breakpoints.sm.up(css`
      font-size: 96px;
    `)}
`;
