import React, { useEffect, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "tiny-invariant";

import {
  BenefitDefinitionTermsRead,
  BikeBenefitContractRead,
  BikeBenefitContractState,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import {
  Button,
  DownloadIcon,
  Flex,
  Markdown,
  Modal,
  useToaster,
} from "@vapaus/ui-v2";

import { useFindOrdersQuery, useSearchContracts } from "../api/benefit";
import { userBenefitDefinitionsAPI } from "../api/benefitDefinitions";
import { useAcceptBenefitDefinitionTerms } from "../api/benefitDefitinionTerms";

export function BenefitTermsChecker() {
  const { t } = useTranslation();
  const { data: orders } = useFindOrdersQuery();
  const { data: contracts } = useSearchContracts();
  const [updatedTerms, setUpdatedTerms] = useState<
    BenefitDefinitionTermsRead | undefined
  >();
  const [contractOrOrder, setContractOrOrder] = useState<
    BikeBenefitOrderRead | BikeBenefitContractRead | undefined
  >();
  const toaster = useToaster();

  const acceptedOrders =
    orders?.items.filter(
      (order) =>
        order.state === BikeBenefitOrderState.ReadyForDelivery ||
        order.state === BikeBenefitOrderState.WaitingForPayment,
    ) || [];
  const activeContracts =
    contracts?.items.filter(
      (contract) => contract.state === BikeBenefitContractState.Active,
    ) || [];
  const contractsAndOrders = [...activeContracts, ...acceptedOrders];
  const acceptTerms = useAcceptBenefitDefinitionTerms();

  useQueries({
    queries: contractsAndOrders.map((contractOrOrder) => ({
      queryKey: [
        "currentBenefitDefinitionTerms",
        contractOrOrder.benefitDefinitionId,
      ],
      queryFn: () =>
        userBenefitDefinitionsAPI.benefitDefinitionsGetBenefitDefinitionCurrentTerms(
          {
            benefitId: contractOrOrder.benefitDefinitionId,
          },
        ),
      onSuccess(terms: BenefitDefinitionTermsRead) {
        if (
          contractOrOrder?.benefitDefinitionTermsId !== terms.id &&
          !updatedTerms
        ) {
          setUpdatedTerms(terms);
          setContractOrOrder(contractOrOrder);
        }
      },
    })),
  });

  const handleAcceptTerms = () => {
    invariant(updatedTerms);
    invariant(contractOrOrder);
    acceptTerms.mutate({
      benefitDefinitionTermsId: updatedTerms.id,
      orderOrContractId: contractOrOrder.id,
    });
  };

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      handleClose();
      toaster.add("success", t("userV2:benefitTermsChecker.successMessage"));
    }
  }, [acceptTerms.isSuccess, t, toaster]);

  const handleClose = () => {
    setUpdatedTerms(undefined);
    setContractOrOrder(undefined);
  };

  const onPdfButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      updatedTerms?.pdfObject?.publicUrl,
      "_blank",
      "noopener,noreferrer",
    );
  };

  if (!updatedTerms) {
    return null;
  }

  return (
    <Modal
      isOpen={!!updatedTerms}
      size="lg"
      title={t("userV2:benefitTermsChecker.title")}
      description={t("userV2:benefitTermsChecker.description")}
      confirmText={t("userV2:benefitTermsChecker.confirmText")}
      onConfirm={handleAcceptTerms}
      isSubmitting={acceptTerms.isLoading}
    >
      {updatedTerms?.content ? (
        <Markdown content={updatedTerms.content as string} />
      ) : updatedTerms.pdfObject?.publicUrl ? (
        <StyledButtonWrapper>
          <Button variant="outlined" onClick={onPdfButtonClick}>
            <Flex>
              <DownloadIcon />
              {t("userV2:benefitTermsChecker.downloadButton")}
            </Flex>
          </Button>
        </StyledButtonWrapper>
      ) : null}
    </Modal>
  );
}

const StyledButtonWrapper = styled.div`
  text-align: center;
`;
