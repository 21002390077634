/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeSummaryRead } from "./BikeSummaryRead";
import {
  BikeSummaryReadFromJSON,
  BikeSummaryReadFromJSONTyped,
  BikeSummaryReadToJSON,
} from "./BikeSummaryRead";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { ShopSummaryRead } from "./ShopSummaryRead";
import {
  ShopSummaryReadFromJSON,
  ShopSummaryReadFromJSONTyped,
  ShopSummaryReadToJSON,
} from "./ShopSummaryRead";
import type { UserSummaryRead } from "./UserSummaryRead";
import {
  UserSummaryReadFromJSON,
  UserSummaryReadFromJSONTyped,
  UserSummaryReadToJSON,
} from "./UserSummaryRead";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventRead
 */
export interface ShopMaintenanceEventRead {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventRead
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventRead
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  id: string;
  /**
   *
   * @type {Currency}
   * @memberof ShopMaintenanceEventRead
   */
  currency: Currency;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventRead
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  bikeId?: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventRead
   */
  vapausCode?: string;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventRead
   */
  totalPrice: number;
  /**
   *
   * @type {boolean}
   * @memberof ShopMaintenanceEventRead
   */
  isBillable: boolean;
  /**
   *
   * @type {BikeSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  bike: BikeSummaryRead;
  /**
   *
   * @type {UserSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  user?: UserSummaryRead;
  /**
   *
   * @type {ShopSummaryRead}
   * @memberof ShopMaintenanceEventRead
   */
  shop: ShopSummaryRead;
}

/**
 * Check if a given object implements the ShopMaintenanceEventRead interface.
 */
export function instanceOfShopMaintenanceEventRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "partsPrice" in value;
  isInstance = isInstance && "workPrice" in value;
  isInstance = isInstance && "workType" in value;
  isInstance = isInstance && "workDescription" in value;
  isInstance = isInstance && "workDate" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "totalPrice" in value;
  isInstance = isInstance && "isBillable" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "shop" in value;

  return isInstance;
}

export function ShopMaintenanceEventReadFromJSON(
  json: any,
): ShopMaintenanceEventRead {
  return ShopMaintenanceEventReadFromJSONTyped(json, false);
}

export function ShopMaintenanceEventReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    id: json["id"],
    currency: CurrencyFromJSON(json["currency"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    bikeId: !exists(json, "bike_id") ? undefined : json["bike_id"],
    bikeBenefitContractId: !exists(json, "bike_benefit_contract_id")
      ? undefined
      : json["bike_benefit_contract_id"],
    shopId: json["shop_id"],
    vapausCode: !exists(json, "vapaus_code") ? undefined : json["vapaus_code"],
    totalPrice: json["total_price"],
    isBillable: json["is_billable"],
    bike: BikeSummaryReadFromJSON(json["bike"]),
    user: !exists(json, "user")
      ? undefined
      : UserSummaryReadFromJSON(json["user"]),
    shop: ShopSummaryReadFromJSON(json["shop"]),
  };
}

export function ShopMaintenanceEventReadToJSON(
  value?: ShopMaintenanceEventRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parts_price: value.partsPrice,
    work_price: value.workPrice,
    work_type: WorkTypesToJSON(value.workType),
    work_description: value.workDescription,
    work_date: value.workDate.toISOString().substring(0, 10),
    id: value.id,
    currency: CurrencyToJSON(value.currency),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    bike_id: value.bikeId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
    shop_id: value.shopId,
    vapaus_code: value.vapausCode,
    total_price: value.totalPrice,
    is_billable: value.isBillable,
    bike: BikeSummaryReadToJSON(value.bike),
    user: UserSummaryReadToJSON(value.user),
    shop: ShopSummaryReadToJSON(value.shop),
  };
}
