/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ReturnFee = {
  Never: "never",
  Always: "always",
} as const;
export type ReturnFee = (typeof ReturnFee)[keyof typeof ReturnFee];

export function ReturnFeeFromJSON(json: any): ReturnFee {
  return ReturnFeeFromJSONTyped(json, false);
}

export function ReturnFeeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnFee {
  return json as ReturnFee;
}

export function ReturnFeeToJSON(value?: ReturnFee | null): any {
  return value as any;
}
