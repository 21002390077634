export enum Path {
  myBikes = "my-bikes",
  getABike = "get-a-bike",
  calculator = "calculator",
  benefits = "benefits",
  userProfile = "user-profile",
  billing = "billing",
  profile = "profile",
  settings = "settings",
  support = "support",
  logout = "logout",
  onboarding = "onboarding",
  addNewBenefit = "add-new-benefit",
  accessRequest = "access-request",
  activation = "activation",
  benefitGuideline = "benefit-guideline",
  goodToKnow = "good-to-know",
  localPartners = "local-partners",
  onlinePartners = "online-partners",
  preCycled = "precycled",
}
