import styled from "styled-components";

import { makeTransition } from "../styles";

export type ProgressBarProps = {
  progressPercentage: number;
};

export function ProgressBar({ progressPercentage }: ProgressBarProps) {
  return (
    <StyledRoot data-testid="progress-bar">
      <StyledProgress progressPercentage={progressPercentage} />
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  height: 8px;
  width: 100%;
  background-color: rgba(47, 77, 82, 0.05);
  border-radius: ${({ theme }) => theme.radius.progressBar};
`;

const StyledProgress = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ progressPercentage }) => progressPercentage}%;
  background-color: ${({ theme }) => theme.palette.secondary1.main};
  border-radius: ${({ theme }) => theme.radius.progressBar};
  ${makeTransition("width", "complex", "easeInOut")}
`;
