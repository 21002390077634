import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useSearchParams } from "react-router-dom";

import { PaymentServiceProvider } from "@vapaus/api-codegen";

import { usePayInvoice } from "../../../../../api/invoice";
import { VISMA_PAY_RETURN_KEY } from "../../../../../constants/vismaPay";

export function usePayRedemptionOrReturnInvoice() {
  const payInvoice = usePayInvoice();
  const queryClient = useQueryClient();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const onPayInvoice = useCallback(
    (saleInvoiceId: string, pathName?: string) => {
      const currentParams = new URLSearchParams(searchParams);
      currentParams.set(VISMA_PAY_RETURN_KEY, saleInvoiceId);
      payInvoice.mutate(
        {
          saleInvoiceId,
          bodyUserInvoicesPaySaleInvoice: {
            paymentServiceProvider: PaymentServiceProvider.VismaPay,
            returnUrl: `${
              window.location.origin + (pathName ?? location.pathname)
            }?${currentParams.toString()}`,
          },
        },
        {
          onSuccess: ({ link }) => {
            queryClient.invalidateQueries(["paymentConsents"]);
            if (!link) return;
            window.location.assign(link);
          },
        },
      );
    },
    [location.pathname, payInvoice, queryClient, searchParams],
  );

  return { onPayInvoice, isLoading: payInvoice.isLoading };
}
