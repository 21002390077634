import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CircularButton, Logo, Notification, PersonIcon } from "@vapaus/ui-v2";
import { getStringInitials } from "@vapaus/utils";

import { useCurrentUser } from "../../api";
import { AppName } from "../../constants";
import { TermsConsentPanel } from "./TermsConsentPanel";
import { TermsOfServiceContent } from "./TermsOfServiceContent";
import { useTermsConsentPanel } from "./useTermsConsentPanel";

export type TermsOfServiceUpdatedProps = {
  app: AppName;
};

export function TermsOfServiceUpdated({ app }: TermsOfServiceUpdatedProps) {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const { appUserTerms, areTermsUpdated } = useTermsConsentPanel(app);
  const initials = getStringInitials(user?.fullName ?? "", 2);

  return (
    <Root>
      <Header>
        <Logo />
        {initials?.length === 2 ? (
          <CircularButton text={initials} variant="outlined" />
        ) : (
          <CircularButton icon={<PersonIcon />} variant="outlined" />
        )}
      </Header>
      {areTermsUpdated && (
        <NotificationWrapper>
          <Notification
            type="neutral"
            message={t("authV2:termsOfService.notificationMessage")}
          />
        </NotificationWrapper>
      )}
      <TermsOfServiceContent content={appUserTerms?.content}>
        <TermsConsentPanel app={app} />
      </TermsOfServiceContent>
    </Root>
  );
}

const Root = styled.div`
  min-height: 100vh;
`;

const Header = styled.header`
  height: ${({ theme }) => theme.height.navBar};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border.secondary.main};
`;

const NotificationWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border.secondary.main};
`;
