import { Trans, useTranslation } from "react-i18next";

import { Currency } from "@vapaus/generated";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../packages/i18n/src/hooks";
import { BenefitCalculationWithExtra } from "../types";
import { DescribedValue } from "./DescribedValue";
import { InfoBox } from "./InfoBox";

interface CostAfterTaxSavingsProps {
  calculation?: BenefitCalculationWithExtra;
}

export function CostAfterTaxSavings({ calculation }: CostAfterTaxSavingsProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(Currency.EUR);
  const formatPercent = usePercentFormat({
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <InfoBox
      background="secondary1.lighter"
      title={t("calculator:costAfterTaxSavings.title")}
      value={formatCurrency(calculation?.costAfterSavings)}
      description={
        calculation ? (
          calculation.savings > 0 ? (
            <Trans
              i18nKey="calculator:costAfterTaxSavings.description"
              values={{
                costAfterSavings: calculation.costAfterSavings,
                savings: calculation.savings,
                formatParams: {
                  costAfterSavings: { currency: "EUR" },
                  savings: { currency: "EUR" },
                },
              }}
              defaults="Thanks to the income tax savings, you will pay {{ costAfterSavings, currency }} for your bike, which is {{ savings, currency }} less than if you bought it directly from the store."
            />
          ) : (
            <Trans
              i18nKey="calculator:costAfterTaxSavings.descriptionNoSavings"
              values={{
                costAfterSavings: calculation.costAfterSavings,
                savings: Math.abs(calculation.savings),
                formatParams: {
                  costAfterSavings: { currency: "EUR" },
                  savings: { currency: "EUR" },
                },
              }}
              defaults="You will pay {{ costAfterSavings, currency }} for your bike, which is {{ savings, currency }} more than if you bought it directly from the store. But it is still less than what it would cost with most leasing provider."
            />
          )
        ) : (
          "-"
        )
      }
      summaryTitle={t("calculator:costAfterTaxSavings.title")}
      summaryValue={formatCurrency(calculation?.costAfterSavings)}
    >
      <DescribedValue
        title={t("calculator:costAfterTaxSavings.salaryDeduction.title")}
        value={formatCurrency(calculation?.totalValueAmount)}
        description={t(
          "calculator:costAfterTaxSavings.salaryDeduction.description",
          { value: calculation?.leasingPeriodMonths },
        )}
      />
      <DescribedValue
        title={t("calculator:costAfterTaxSavings.taxSavings.title")}
        value={formatCurrency(calculation?.taxAmountReduction)}
        description={t("calculator:costAfterTaxSavings.taxSavings.description")}
      />
      <DescribedValue
        title={t("calculator:costAfterTaxSavings.redemptionValue.title", {
          redemptionPercentage: formatPercent(
            calculation?.redemptionPercentage,
          ),
        })}
        value={formatCurrency(calculation?.residualValue)}
        description={t(
          "calculator:costAfterTaxSavings.redemptionValue.description",
          {
            redemptionPercentage: formatPercent(
              calculation?.redemptionPercentage,
            ),
          },
        )}
      />
    </InfoBox>
  );
}
