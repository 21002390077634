import { createSvgIcon } from "./SvgIcon";

export const YoutubeIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.5817 7.15506C21.4687 6.72945 21.2459 6.341 20.9355 6.02861C20.6252 5.71622 20.2382 5.49084 19.8133 5.37506C18.2542 4.95422 12 4.95422 12 4.95422C12 4.95422 5.74583 4.95422 4.18583 5.37506C3.76117 5.49105 3.37441 5.7165 3.06423 6.02888C2.75405 6.34125 2.53133 6.72959 2.41833 7.15506C2 8.72506 2 12.0001 2 12.0001C2 12.0001 2 15.2751 2.41833 16.8451C2.53132 17.2707 2.75415 17.6591 3.06449 17.9715C3.37484 18.2839 3.76181 18.5093 4.18667 18.6251C5.74583 19.0459 12 19.0459 12 19.0459C12 19.0459 18.2542 19.0459 19.8142 18.6251C20.2391 18.5093 20.6261 18.284 20.9364 17.9716C21.2468 17.6592 21.4696 17.2707 21.5825 16.8451C22 15.2751 22 12.0001 22 12.0001C22 12.0001 22 8.72506 21.5817 7.15506ZM9.95417 14.9734V9.02672L15.1817 12.0001L9.95417 14.9734Z" />
  </svg>,
  "Youtube",
);
