import {
  ChangeEvent,
  FocusEvent,
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
} from "react";

import clsx from "clsx";
import styled, { css } from "styled-components";

import { useForwardedRef } from "@vapaus/utils";

import { makeTransition } from "../styles";

export type BaseInputTypeAttribute =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "month"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export type BaseInputChangeHandler = (
  event: ChangeEvent<HTMLInputElement>,
) => void;

export type BaseInputFocusHandler = (
  event: FocusEvent<HTMLInputElement>,
) => void;

export type InputMode =
  | "search"
  | "text"
  | "decimal"
  | "email"
  | "tel"
  | "url"
  | "none"
  | "numeric"
  | undefined;

export interface BaseInputProps {
  value?: string | number;
  onChange?: BaseInputChangeHandler;
  onFocus?: BaseInputFocusHandler;
  onBlur?: BaseInputFocusHandler;
  type?: BaseInputTypeAttribute;
  id?: string;
  name?: string;
  inputMode?: InputMode;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  minLength?: number;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  step?: number;
  startAdornment?: string | ReactNode;
  endAdornment?: string | ReactNode;
  error?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  hideArrowsForTypeNumber?: boolean;
  fullWidth?: boolean;
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  function BaseInput(
    {
      value,
      onChange,
      onFocus,
      onBlur,
      type = "text",
      id,
      name,
      inputMode,
      autoComplete,
      autoFocus,
      disabled,
      max,
      maxLength,
      min,
      minLength,
      pattern,
      placeholder,
      required,
      step,
      startAdornment,
      endAdornment,
      error,
      onKeyDown,
      className,
      hideArrowsForTypeNumber = false,
      fullWidth,
    },
    ref,
  ) {
    const inputInternalRef = useForwardedRef<HTMLInputElement>(ref);

    const handleInputFocus = () => inputInternalRef.current?.focus();

    return (
      <Root
        hideArrowsForTypeNumber={hideArrowsForTypeNumber}
        className={clsx(disabled && "disabled", error && "error", className)}
        onClick={handleInputFocus}
        fullWidth={fullWidth}
      >
        {startAdornment && <Adornment>{startAdornment}</Adornment>}
        <Input
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
          id={id}
          name={name}
          inputMode={inputMode}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          step={step}
          ref={inputInternalRef}
          onKeyDown={onKeyDown}
          {...(hideArrowsForTypeNumber && {
            onWheel: (event) => event.currentTarget.blur(),
          })}
        />
        {endAdornment && <Adornment>{endAdornment}</Adornment>}
      </Root>
    );
  },
);

const Root = styled.div<{
  hideArrowsForTypeNumber: boolean;
  fullWidth?: boolean;
}>`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  border-color: ${({ theme }) => theme.palette.primary1.main};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  height: 56px;
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.none};
  cursor: text;

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  flex: ${({ fullWidth }) => (fullWidth ? 1 : "auto")};

  ${makeTransition("all", "standard", "easeOut")}
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary1.light};
  }

  &:active,
  &:focus-within {
    box-shadow: ${({ theme }) => theme.shadows.input};
  }

  &.disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.error};
  }

  ${({ hideArrowsForTypeNumber }) =>
    hideArrowsForTypeNumber &&
    css`
      /* Chrome, Safari, Edge, Opera */

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
      }

      /* Firefox */

      input[type="number"] {
        -moz-appearance: textfield;
      }
    `};
`;

const Input = styled.input`
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  height: 24px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  color: ${({ theme }) => theme.typography.defaultColor};
  outline: none;

  &::placeholder {
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }
`;

const Adornment = styled.div`
  &:first-child {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }

  &:last-child {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }

  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.typography.defaultColor};
`;
