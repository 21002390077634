/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionAccessRequestStatus } from "./BenefitDefinitionAccessRequestStatus";
import {
  BenefitDefinitionAccessRequestStatusFromJSON,
  BenefitDefinitionAccessRequestStatusFromJSONTyped,
  BenefitDefinitionAccessRequestStatusToJSON,
} from "./BenefitDefinitionAccessRequestStatus";

/**
 *
 * @export
 * @interface UserBenefitDefinitionAccessRequestRead
 */
export interface UserBenefitDefinitionAccessRequestRead {
  /**
   *
   * @type {BenefitDefinitionAccessRequestStatus}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  status?: BenefitDefinitionAccessRequestStatus;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  businessId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  benefitDefinitionId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  resolvedByUserId?: string;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  resolvedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  deletedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestRead
   */
  organisationId?: string;
}

/**
 * Check if a given object implements the UserBenefitDefinitionAccessRequestRead interface.
 */
export function instanceOfUserBenefitDefinitionAccessRequestRead(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "userEmail" in value;
  isInstance = isInstance && "id" in value;

  return isInstance;
}

export function UserBenefitDefinitionAccessRequestReadFromJSON(
  json: any,
): UserBenefitDefinitionAccessRequestRead {
  return UserBenefitDefinitionAccessRequestReadFromJSONTyped(json, false);
}

export function UserBenefitDefinitionAccessRequestReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserBenefitDefinitionAccessRequestRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, "status")
      ? undefined
      : BenefitDefinitionAccessRequestStatusFromJSON(json["status"]),
    message: !exists(json, "message") ? undefined : json["message"],
    businessId: !exists(json, "business_id") ? undefined : json["business_id"],
    benefitDefinitionId: !exists(json, "benefit_definition_id")
      ? undefined
      : json["benefit_definition_id"],
    userId: json["user_id"],
    userEmail: json["user_email"],
    resolvedByUserId: !exists(json, "resolved_by_user_id")
      ? undefined
      : json["resolved_by_user_id"],
    resolvedAt: !exists(json, "resolved_at")
      ? undefined
      : new Date(json["resolved_at"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
    id: json["id"],
    organisationId: !exists(json, "organisation_id")
      ? undefined
      : json["organisation_id"],
  };
}

export function UserBenefitDefinitionAccessRequestReadToJSON(
  value?: UserBenefitDefinitionAccessRequestRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: BenefitDefinitionAccessRequestStatusToJSON(value.status),
    message: value.message,
    business_id: value.businessId,
    benefit_definition_id: value.benefitDefinitionId,
    user_id: value.userId,
    user_email: value.userEmail,
    resolved_by_user_id: value.resolvedByUserId,
    resolved_at:
      value.resolvedAt === undefined
        ? undefined
        : value.resolvedAt.toISOString(),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
    id: value.id,
    organisation_id: value.organisationId,
  };
}
