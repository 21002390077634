/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface EnumEntry
 */
export interface EnumEntry {
  /**
   *
   * @type {string}
   * @memberof EnumEntry
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof EnumEntry
   */
  label: string;
}

/**
 * Check if a given object implements the EnumEntry interface.
 */
export function instanceOfEnumEntry(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "value" in value;
  isInstance = isInstance && "label" in value;

  return isInstance;
}

export function EnumEntryFromJSON(json: any): EnumEntry {
  return EnumEntryFromJSONTyped(json, false);
}

export function EnumEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnumEntry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: json["value"],
    label: json["label"],
  };
}

export function EnumEntryToJSON(value?: EnumEntry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
    label: value.label,
  };
}
