import { useMutation, useQuery } from "@tanstack/react-query";

import {
  PaginatedSaleInvoiceOut,
  PaymentDetailsRead,
  ResponseError,
  SaleInvoiceRead,
  UserInvoicesApi,
  UserInvoicesPaySaleInvoiceRequest,
  UserInvoicesSearchSaleInvoicesRequest,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userInvoiceAPI = new UserInvoicesApi(apiConfiguration);

export const useSearchInvoice = (
  params?: UserInvoicesSearchSaleInvoicesRequest,
  options?: UseQueryOptions<PaginatedSaleInvoiceOut>,
) =>
  useQuery<PaginatedSaleInvoiceOut, ResponseError>(
    ["invoices", params],
    () => userInvoiceAPI.userInvoicesSearchSaleInvoices(params),
    options,
  );

export const useGetInvoice = (
  id?: string,
  options?: UseQueryOptions<SaleInvoiceRead>,
) =>
  useQuery<SaleInvoiceRead, ResponseError>(
    ["invoices", id],
    () =>
      userInvoiceAPI.userInvoicesGetSaleInvoice({
        saleInvoiceId: id as string,
      }),
    {
      ...options,
      enabled: !!id,
    },
  );

export const usePayInvoice = () =>
  useMutation<
    PaymentDetailsRead,
    ResponseError,
    UserInvoicesPaySaleInvoiceRequest
  >((data) => userInvoiceAPI.userInvoicesPaySaleInvoice(data));
