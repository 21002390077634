import { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { BikeBenefitContractRead, Currency } from "@vapaus/api-codegen";
import { Button, Flex, Modal, TextButton } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../../../../packages/i18n/src/hooks";
import { getIsContractEndingSoon } from "../../../../../../../utils/getIsContractEndingSoon";
import { WizardPageViewLayout } from "../../../../WizardPageViewLayout";
import { useRedemptionProcessContext } from "../../../context/RedemptionProcessContext";

export function RedeemBikeWelcomeView() {
  const { t } = useTranslation();
  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();
  const { onNext, calculation } = useRedemptionProcessContext();
  const formatCurrency = useCurrencyFormat(contract.currency || Currency.Eur);
  const formatDate = useDateFormat();
  const isEndingSoon = getIsContractEndingSoon(contract.endDate);
  const [isContractEndDateModalOpen, setIsContractEndDateModalOpen] =
    useState<boolean>(false);

  return (
    <WizardPageViewLayout
      title={t("userV2:redemptionProcess.redeemBike.title")}
      description={
        isEndingSoon ? (
          <Trans
            t={t}
            i18nKey="userV2:redemptionProcess.redeemBike.descriptionEndingSoon"
            values={{
              date: formatDate(calculation?.plannedRedemptionDate),
              residualValue: formatCurrency(calculation?.redemptionPrice),
            }}
            components={{ 1: <b /> }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="userV2:redemptionProcess.redeemBike.description"
            values={{
              date: formatDate(calculation?.plannedRedemptionDate),
              value: formatCurrency(calculation?.redemptionPrice),
            }}
            components={{ 1: <b /> }}
          />
        )
      }
    >
      <StyledRoot gap="lg" align="center" direction="column" fullWidth>
        <StyledButton onClick={onNext} fullWidth>
          {t("commonV2:continue")}
        </StyledButton>
        {!isEndingSoon ? (
          <TextButton
            size="md"
            onClick={() => setIsContractEndDateModalOpen(true)}
          >
            {t("userV2:redemptionProcess.redeemBike.howDateIsDetermined")}
          </TextButton>
        ) : null}
      </StyledRoot>
      <Modal
        isOpen={isContractEndDateModalOpen}
        title={t(
          "userV2:redemptionProcess.redeemBike.howDateIsDeterminedTitle",
        )}
        description={t(
          "userV2:redemptionProcess.redeemBike.howDateIsDeterminedContent",
        )}
        confirmText={t("commonV2:gotIt")}
        onClose={() => setIsContractEndDateModalOpen(false)}
        onConfirm={() => setIsContractEndDateModalOpen(false)}
      />
    </WizardPageViewLayout>
  );
}

const StyledRoot = styled(Flex)`
  margin-top: -1rem;
`;

const StyledButton = styled(Button)`
  max-width: 384px;
`;
