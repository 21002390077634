import { useTranslation } from "react-i18next";

import { Currency as NewCurrency } from "@vapaus/api-codegen";
import { Currency } from "@vapaus/generated";

type UseCurrencyFormatOptions = {
  maximumFractionDigits?: number;
};

export const useCurrencyFormat = (
  currency: Currency | NewCurrency,
  options?: UseCurrencyFormatOptions,
) => {
  const { i18n } = useTranslation();
  const currencyFormatter = new Intl.NumberFormat(i18n.language, {
    ...options,
    style: "currency",
    currency: currency,
  });
  return (value?: number | null) =>
    value !== undefined && value !== null
      ? currencyFormatter.format(value)
      : "-";
};
