import { useMutation } from "@tanstack/react-query";

import type {
  EIdentPostEIdentRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { EIdentApi } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

const eIdentApi = new EIdentApi(apiConfiguration);

export const useEIdent = () =>
  useMutation<unknown, ResponseError, EIdentPostEIdentRequest>((data) =>
    eIdentApi.eIdentPostEIdent(data),
  );
