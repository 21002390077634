import { useTranslation } from "react-i18next";

import { BikeBenefitOrderRead } from "@vapaus/api-codegen";
import { getOrderTotal, useGetAdditionalServiceLabel } from "@vapaus/common-v2";
import { useEnumEntries } from "@vapaus/shared-api";
import { Card, Flex, HorizontalLine, Typography } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../packages/i18n/src/hooks";

export interface BikeOrderCardProps {
  order: BikeBenefitOrderRead;
}

export function BikeOrderCard({ order }: BikeOrderCardProps) {
  const { t } = useTranslation();
  const { getEnumLabel: getDriveTypeLabel } = useEnumEntries("BikeDriveType");
  const { getEnumLabel: getFrameTypeLabel } = useEnumEntries("BikeFrameType");
  const { getEnumLabel: getAccessoryTypeLabel } =
    useEnumEntries("AccessoryType");
  const { getAdditionalServiceLabel } = useGetAdditionalServiceLabel();
  const formatCurrency = useCurrencyFormat(order.currency);

  return (
    <Card title={t("userV2:myBike.bikeOrderCard.title")}>
      <Flex direction="column" gap="md">
        <div>
          <Flex justify="space-between">
            <Typography weight="bold">
              {t("userV2:myBike.bikeOrderCard.bike")}
            </Typography>
            <Typography weight="bold">
              {formatCurrency(order.bikePrice)}
            </Typography>
          </Flex>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.manufacturer")}{" "}
            {order.bike.bikeBrand.name}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.model")} {order.bike.model}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.colour")} {order.bike.colour}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.size")} {order.bike.size}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.driveType")}{" "}
            {getDriveTypeLabel(order.bike.driveType)}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.frameType")}{" "}
            {getFrameTypeLabel(order.bike.frameType)}
          </Typography>
          <Typography>
            {t("userV2:myBike.bikeOrderCard.serialNumber")}{" "}
            {order.bike.serialNumber}
          </Typography>
        </div>

        {order.accessories.length > 0 && (
          <div>
            <Typography weight="bold">
              {t("userV2:myBike.bikeOrderCard.accessories")}
            </Typography>
            {order.accessories.map((accessory) => (
              <Flex key={accessory.id} justify="space-between">
                <Typography>
                  {getAccessoryTypeLabel(accessory.accessoryType)}
                  {": "}
                  {`${accessory.manufacturer} ${accessory.model}`}
                </Typography>
                <Typography weight="bold">
                  {formatCurrency(accessory.purchasePrice)}
                </Typography>
              </Flex>
            ))}
          </div>
        )}

        {order.additionalServices.length > 0 && (
          <div>
            <Typography weight="bold">
              {t("userV2:myBike.bikeOrderCard.additionalServices")}
            </Typography>
            {order.additionalServices.map((additionalService) => (
              <Flex key={additionalService.id} justify="space-between">
                <Typography>
                  {getAdditionalServiceLabel(additionalService)}
                </Typography>
                <Typography weight="bold">
                  {formatCurrency(additionalService.priceGross)}
                </Typography>
              </Flex>
            ))}
          </div>
        )}

        <div>
          <Typography weight="bold">
            {t("userV2:myBike.bikeOrderCard.deliveryType.title")}
          </Typography>
          <Flex justify="space-between">
            <Typography>
              {order.deliveryTransport
                ? t("userV2:myBike.bikeOrderCard.deliveryType.delivery")
                : t("userV2:myBike.bikeOrderCard.deliveryType.pickup")}
            </Typography>
            <Typography weight="bold">
              {formatCurrency(order.deliveryTransport?.priceGross || 0)}
            </Typography>
          </Flex>
        </div>
        <HorizontalLine marginTop="none" marginBottom="none" />
        <Flex justify="space-between">
          <Typography weight="bold">
            {t("userV2:myBike.bikeOrderCard.total")}
          </Typography>
          <Typography weight="bold">
            {formatCurrency(getOrderTotal(order))}
          </Typography>
        </Flex>
      </Flex>
    </Card>
  );
}
