import styled from "styled-components";

import { Flex, Justify } from "../../Flex";
import { SpacingVariants } from "../../styles";
import { TabRowItem, type TabRowItemProps } from "../TabRowItem";

export type TabRowProps = {
  items: Array<TabRowItemProps>;
  padding?: SpacingVariants;
  justify?: Justify;
} & Pick<TabRowItemProps, "labelTypoVariant" | "labelWeight" | "border">;

export function TabRow({
  items,
  padding,
  justify = "center",
  ...rest
}: TabRowProps) {
  return (
    <StyledRoot
      gap="none"
      padding={padding}
      justify={justify}
      xsJustify="flex-start"
    >
      {items.map((item) => (
        <TabRowItem key={item.label + (item?.to || "")} {...item} {...rest} />
      ))}
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{ padding?: SpacingVariants }>`
  max-width: 100%;
  overflow-x: auto;
  padding-inline-start: ${({ theme, padding }) =>
    theme.spacing[padding || "md"]};
  padding-inline-end: ${({ theme, padding }) => theme.spacing[padding || "md"]};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
