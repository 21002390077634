import { createSvgIcon } from "./SvgIcon";

export const ArrowUpDownIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M10.9059 17.3L8.30593 14.7C7.98927 14.3833 7.91843 14.0208 8.09343 13.6125C8.26843 13.2042 8.58093 13 9.03093 13H14.1809C14.6309 13 14.9434 13.2042 15.1184 13.6125C15.2934 14.0208 15.2226 14.3833 14.9059 14.7L12.3059 17.3C12.2059 17.4 12.0976 17.475 11.9809 17.525C11.8643 17.575 11.7393 17.6 11.6059 17.6C11.4726 17.6 11.3476 17.575 11.2309 17.525C11.1143 17.475 11.0059 17.4 10.9059 17.3Z" />
    <path d="M12.306 6.3001L14.906 8.9001C15.2226 9.21676 15.2935 9.57926 15.1185 9.9876C14.9435 10.3959 14.631 10.6001 14.181 10.6001H9.03098C8.58098 10.6001 8.26848 10.3959 8.09348 9.9876C7.91848 9.57926 7.98931 9.21676 8.30598 8.9001L10.906 6.3001C11.006 6.2001 11.1143 6.1251 11.231 6.0751C11.3476 6.0251 11.4726 6.0001 11.606 6.0001C11.7393 6.0001 11.8643 6.0251 11.981 6.0751C12.0976 6.1251 12.206 6.2001 12.306 6.3001Z" />
  </svg>,
  "ArrowUpDown",
);
