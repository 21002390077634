/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const StorageObjectState = {
  Stored: "STORED",
  Uploading: "UPLOADING",
  Deleting: "DELETING",
  ErrorOnUpload: "ERROR_ON_UPLOAD",
  ErrorOnDeletion: "ERROR_ON_DELETION",
  OtherError: "OTHER_ERROR",
} as const;
export type StorageObjectState =
  (typeof StorageObjectState)[keyof typeof StorageObjectState];

export function StorageObjectStateFromJSON(json: any): StorageObjectState {
  return StorageObjectStateFromJSONTyped(json, false);
}

export function StorageObjectStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StorageObjectState {
  return json as StorageObjectState;
}

export function StorageObjectStateToJSON(
  value?: StorageObjectState | null,
): any {
  return value as any;
}
