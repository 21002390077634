import { ReactNode } from "react";

import { get } from "lodash-es";
import { Link } from "react-router-dom";
import styled, { AnyStyledComponent, css } from "styled-components";

import {
  Button,
  ButtonVariant,
  Flex,
  PaletteVariants,
  Paper,
  ShadowVariants,
  Typography,
} from "@vapaus/ui-v2";

type InfoCardProps = {
  title: string;
  description: string;
  icon: ReactNode;
  link: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  buttonText: string;
  backgroundColor?: PaletteVariants;
  shadow?: ShadowVariants;
  variant?: "small" | "default";
};

export function InfoCard({
  title,
  description,
  icon,
  link,
  target = "_self",
  buttonText,
  backgroundColor = "background.white",
  shadow = "md",
  variant = "default",
}: InfoCardProps) {
  return (
    <StyledRoot to={link} target={target} data-testid="info-card-root">
      <StyledPaper
        backgroundColor={backgroundColor}
        shadow={shadow}
        padding="lg"
      >
        <Flex
          direction="column"
          align="align"
          justify="space-between"
          fullHeight
          gap="md"
        >
          <Flex direction="column" align="center">
            {icon}
            <Typography
              variant={variant === "small" ? "bodyMd" : "h6"}
              weight={variant === "small" ? "bold" : "regular"}
              align="center"
            >
              {title}
            </Typography>
            <Typography
              variant={variant === "small" ? "bodySm" : "bodyMd"}
              marginBottom="xs"
              align="center"
            >
              {description}
            </Typography>
          </Flex>
          <Button size={variant} fullWidth>
            {buttonText}
          </Button>
        </Flex>
      </StyledPaper>
    </StyledRoot>
  );
}

const StyledRoot = styled(Link as AnyStyledComponent)`
  width: 100%;
`;

const StyledPaper = styled(Paper)<{ backgroundColor: string }>`
  height: 100%;
  background: ${({ theme, backgroundColor }) =>
    get(theme.palette, backgroundColor)};
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${theme.spacing.xl};
    `)}
`;
