/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  AdminMaintenanceEventCreate,
  AdminMaintenanceEventUpdate,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  MaintenanceEventRead,
  OrderDirection,
  PaginatedMaintenanceEventOut,
  ShopMaintenanceEventCreate,
  ShopMaintenanceEventRead,
  ShopMaintenanceEventUpdate,
  ShopPaginatedMaintenanceEventOut,
  WorkTypes,
} from "../models/index";
import {
  AdminMaintenanceEventCreateFromJSON,
  AdminMaintenanceEventCreateToJSON,
  AdminMaintenanceEventUpdateFromJSON,
  AdminMaintenanceEventUpdateToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MaintenanceEventReadFromJSON,
  MaintenanceEventReadToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedMaintenanceEventOutFromJSON,
  PaginatedMaintenanceEventOutToJSON,
  ShopMaintenanceEventCreateFromJSON,
  ShopMaintenanceEventCreateToJSON,
  ShopMaintenanceEventReadFromJSON,
  ShopMaintenanceEventReadToJSON,
  ShopMaintenanceEventUpdateFromJSON,
  ShopMaintenanceEventUpdateToJSON,
  ShopPaginatedMaintenanceEventOutFromJSON,
  ShopPaginatedMaintenanceEventOutToJSON,
  WorkTypesFromJSON,
  WorkTypesToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface MaintenanceEventsCreateMaintenanceEventRequest {
  shopMaintenanceEventCreate: ShopMaintenanceEventCreate;
}

export interface MaintenanceEventsCreateMaintenanceEvent0Request {
  adminMaintenanceEventCreate: AdminMaintenanceEventCreate;
}

export interface MaintenanceEventsDeleteMaintenanceEventRequest {
  maintenanceEventId: string;
}

export interface MaintenanceEventsDeleteMaintenanceEvent0Request {
  maintenanceEventId: string;
}

export interface MaintenanceEventsGetMaintenanceEventRequest {
  maintenanceEventId: string;
}

export interface MaintenanceEventsGetMaintenanceEvent0Request {
  maintenanceEventId: string;
}

export interface MaintenanceEventsSearchMaintenanceEventRequest {
  bikeId?: string;
  shopId?: string;
  vapausCode?: string;
  workType?: WorkTypes;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface MaintenanceEventsSearchMaintenanceEventsRequest {
  shopId?: string;
  vapausCode?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface MaintenanceEventsSearchUsersMaintenanceEventRequest {
  vapausCode?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface MaintenanceEventsUpdateMaintenanceEventRequest {
  maintenanceEventId: string;
  shopMaintenanceEventUpdate: ShopMaintenanceEventUpdate;
}

export interface MaintenanceEventsUpdateMaintenanceEvent0Request {
  maintenanceEventId: string;
  adminMaintenanceEventUpdate: AdminMaintenanceEventUpdate;
}

/**
 *
 */
export class MaintenanceEventsApi extends runtime.BaseAPI {
  /**
   * Create Maintenance Event
   */
  async maintenanceEventsCreateMaintenanceEventRaw(
    requestParameters: MaintenanceEventsCreateMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MaintenanceEventRead>> {
    if (
      requestParameters.shopMaintenanceEventCreate === null ||
      requestParameters.shopMaintenanceEventCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "shopMaintenanceEventCreate",
        "Required parameter requestParameters.shopMaintenanceEventCreate was null or undefined when calling maintenanceEventsCreateMaintenanceEvent.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/maintenance-events`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ShopMaintenanceEventCreateToJSON(
          requestParameters.shopMaintenanceEventCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Maintenance Event
   */
  async maintenanceEventsCreateMaintenanceEvent(
    requestParameters: MaintenanceEventsCreateMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MaintenanceEventRead> {
    const response = await this.maintenanceEventsCreateMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create Maintenance Event
   */
  async maintenanceEventsCreateMaintenanceEvent_1Raw(
    requestParameters: MaintenanceEventsCreateMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MaintenanceEventRead>> {
    if (
      requestParameters.adminMaintenanceEventCreate === null ||
      requestParameters.adminMaintenanceEventCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "adminMaintenanceEventCreate",
        "Required parameter requestParameters.adminMaintenanceEventCreate was null or undefined when calling maintenanceEventsCreateMaintenanceEvent_1.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/maintenance-events`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AdminMaintenanceEventCreateToJSON(
          requestParameters.adminMaintenanceEventCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Maintenance Event
   */
  async maintenanceEventsCreateMaintenanceEvent_1(
    requestParameters: MaintenanceEventsCreateMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MaintenanceEventRead> {
    const response = await this.maintenanceEventsCreateMaintenanceEvent_1Raw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Delete Maintenance Event
   */
  async maintenanceEventsDeleteMaintenanceEventRaw(
    requestParameters: MaintenanceEventsDeleteMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsDeleteMaintenanceEvent.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Maintenance Event
   */
  async maintenanceEventsDeleteMaintenanceEvent(
    requestParameters: MaintenanceEventsDeleteMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.maintenanceEventsDeleteMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Delete Maintenance Event
   */
  async maintenanceEventsDeleteMaintenanceEvent_2Raw(
    requestParameters: MaintenanceEventsDeleteMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsDeleteMaintenanceEvent_2.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Maintenance Event
   */
  async maintenanceEventsDeleteMaintenanceEvent_2(
    requestParameters: MaintenanceEventsDeleteMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.maintenanceEventsDeleteMaintenanceEvent_2Raw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Get Maintenance Event
   */
  async maintenanceEventsGetMaintenanceEventRaw(
    requestParameters: MaintenanceEventsGetMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopMaintenanceEventRead>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsGetMaintenanceEvent.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopMaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Maintenance Event
   */
  async maintenanceEventsGetMaintenanceEvent(
    requestParameters: MaintenanceEventsGetMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopMaintenanceEventRead> {
    const response = await this.maintenanceEventsGetMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Maintenance Event
   */
  async maintenanceEventsGetMaintenanceEvent_3Raw(
    requestParameters: MaintenanceEventsGetMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MaintenanceEventRead>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsGetMaintenanceEvent_3.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Maintenance Event
   */
  async maintenanceEventsGetMaintenanceEvent_3(
    requestParameters: MaintenanceEventsGetMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MaintenanceEventRead> {
    const response = await this.maintenanceEventsGetMaintenanceEvent_3Raw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Maintenance Event
   */
  async maintenanceEventsSearchMaintenanceEventRaw(
    requestParameters: MaintenanceEventsSearchMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedMaintenanceEventOut>> {
    const queryParameters: any = {};

    if (requestParameters.bikeId !== undefined) {
      queryParameters["bike_id"] = requestParameters.bikeId;
    }

    if (requestParameters.shopId !== undefined) {
      queryParameters["shop_id"] = requestParameters.shopId;
    }

    if (requestParameters.vapausCode !== undefined) {
      queryParameters["vapaus_code"] = requestParameters.vapausCode;
    }

    if (requestParameters.workType !== undefined) {
      queryParameters["work_type"] = requestParameters.workType;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/maintenance-events`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedMaintenanceEventOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Maintenance Event
   */
  async maintenanceEventsSearchMaintenanceEvent(
    requestParameters: MaintenanceEventsSearchMaintenanceEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedMaintenanceEventOut> {
    const response = await this.maintenanceEventsSearchMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Maintenance Events
   */
  async maintenanceEventsSearchMaintenanceEventsRaw(
    requestParameters: MaintenanceEventsSearchMaintenanceEventsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopPaginatedMaintenanceEventOut>> {
    const queryParameters: any = {};

    if (requestParameters.shopId !== undefined) {
      queryParameters["shop_id"] = requestParameters.shopId;
    }

    if (requestParameters.vapausCode !== undefined) {
      queryParameters["vapaus_code"] = requestParameters.vapausCode;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/maintenance-events`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopPaginatedMaintenanceEventOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Maintenance Events
   */
  async maintenanceEventsSearchMaintenanceEvents(
    requestParameters: MaintenanceEventsSearchMaintenanceEventsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopPaginatedMaintenanceEventOut> {
    const response = await this.maintenanceEventsSearchMaintenanceEventsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Users Maintenance Event
   */
  async maintenanceEventsSearchUsersMaintenanceEventRaw(
    requestParameters: MaintenanceEventsSearchUsersMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopPaginatedMaintenanceEventOut>> {
    const queryParameters: any = {};

    if (requestParameters.vapausCode !== undefined) {
      queryParameters["vapaus_code"] = requestParameters.vapausCode;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/maintenance-events`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopPaginatedMaintenanceEventOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Users Maintenance Event
   */
  async maintenanceEventsSearchUsersMaintenanceEvent(
    requestParameters: MaintenanceEventsSearchUsersMaintenanceEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopPaginatedMaintenanceEventOut> {
    const response = await this.maintenanceEventsSearchUsersMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update Maintenance Event
   */
  async maintenanceEventsUpdateMaintenanceEventRaw(
    requestParameters: MaintenanceEventsUpdateMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MaintenanceEventRead>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsUpdateMaintenanceEvent.",
      );
    }

    if (
      requestParameters.shopMaintenanceEventUpdate === null ||
      requestParameters.shopMaintenanceEventUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "shopMaintenanceEventUpdate",
        "Required parameter requestParameters.shopMaintenanceEventUpdate was null or undefined when calling maintenanceEventsUpdateMaintenanceEvent.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ShopMaintenanceEventUpdateToJSON(
          requestParameters.shopMaintenanceEventUpdate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Maintenance Event
   */
  async maintenanceEventsUpdateMaintenanceEvent(
    requestParameters: MaintenanceEventsUpdateMaintenanceEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MaintenanceEventRead> {
    const response = await this.maintenanceEventsUpdateMaintenanceEventRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update Maintenance Event
   */
  async maintenanceEventsUpdateMaintenanceEvent_4Raw(
    requestParameters: MaintenanceEventsUpdateMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MaintenanceEventRead>> {
    if (
      requestParameters.maintenanceEventId === null ||
      requestParameters.maintenanceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "maintenanceEventId",
        "Required parameter requestParameters.maintenanceEventId was null or undefined when calling maintenanceEventsUpdateMaintenanceEvent_4.",
      );
    }

    if (
      requestParameters.adminMaintenanceEventUpdate === null ||
      requestParameters.adminMaintenanceEventUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "adminMaintenanceEventUpdate",
        "Required parameter requestParameters.adminMaintenanceEventUpdate was null or undefined when calling maintenanceEventsUpdateMaintenanceEvent_4.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/maintenance-events/{maintenance_event_id}`.replace(
          `{${"maintenance_event_id"}}`,
          encodeURIComponent(String(requestParameters.maintenanceEventId)),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: AdminMaintenanceEventUpdateToJSON(
          requestParameters.adminMaintenanceEventUpdate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MaintenanceEventReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Maintenance Event
   */
  async maintenanceEventsUpdateMaintenanceEvent_4(
    requestParameters: MaintenanceEventsUpdateMaintenanceEvent0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MaintenanceEventRead> {
    const response = await this.maintenanceEventsUpdateMaintenanceEvent_4Raw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
