import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { SupportedLanguagesEnum, UserRead } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { userApi } from "@vapaus/shared-api";
import { useToaster } from "@vapaus/ui-v2";

export function useChangeLanguageModal(onClose: () => void) {
  const { t, i18n } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const { formMethods, mutation } = useFormMutation<
    { language?: SupportedLanguagesEnum },
    UserRead
  >(
    {
      mutationFn: (data) =>
        userApi.userUpdateCurrentUser({
          userUpdate: data,
        }),
      onSuccess: async (data) => {
        queryClient.invalidateQueries(["currentUser"]);
        await i18n.changeLanguage((data.language as string)?.toLowerCase());
        toaster.add(
          "success",
          t("commonV2:changeLanguageModal.successMessage"),
        );
        onClose();
      },
    },
    {
      defaultValues: {
        language:
          i18n.resolvedLanguage?.toUpperCase() as SupportedLanguagesEnum,
      },
    },
  );

  const handleSubmit = (value: string | null) => {
    if (!value) return;
    formMethods.setValue("language", value as SupportedLanguagesEnum);
    formMethods._handleSubmit();
  };

  return { formMethods, mutation, handleSubmit };
}
