import { SaleInvoiceStatus } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Flex, Tag, TagSize, TagVariant } from "@vapaus/ui-v2";

type InvoiceStatusProps = {
  status: SaleInvoiceStatus;
  size?: TagSize;
};

export function InvoiceStatus({ status, size }: InvoiceStatusProps) {
  const { getEnumLabel } = useEnumEntries("SaleInvoiceStatus");

  const variant: Record<SaleInvoiceStatus, TagVariant> = {
    paid: "accepted",
    overdue: "cancelled",
    due: "pending",
    open: "pending",
  };

  return (
    <Flex justify="flex-end">
      <Tag
        text={getEnumLabel(status) as string}
        variant={variant[status]}
        size={size}
      />
    </Flex>
  );
}
