import {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ValidationError,
} from "@vapaus/generated";

export type ApiError =
  | HTTPValidationError
  | ErrorResponse
  | ErrorWithCodeResponse;

interface FormError {
  field: string;
  message: string;
  type?: string;
  code?: string;
}

export function parseApiError(error: ApiError): Array<FormError> {
  if (!error.detail)
    return [
      {
        field: "root",
        message: "Unknown error!",
      },
    ];

  if (typeof error.detail === "string")
    return [
      {
        // Root is a special field of react hook form meaning that the error is not linked to a specific field
        // but to the form itself.
        field: "root",
        message: error.detail,
        code: "code" in error ? error.code : undefined,
      },
    ];

  return error.detail.map(parseValidationError);
}

export function parseValidationError(error: ValidationError): FormError {
  return {
    // Slice to remove the "body" from the loc, then join to follow react hook form field name notation
    field: error.loc.slice(1).join("."),
    message: error.msg,
    type: error.type,
  };
}
