import { Trans, useTranslation } from "react-i18next";

import { Path } from "../../../../constants/paths";
import { OnboardingFinalStepPageLayout } from "../../layout/OnboardingFinalStepPageLayout";
import { useOnboardingMachine } from "../../stateMachine";

export function BusinessIdRequestSentPage() {
  const { t } = useTranslation();
  const { businessId } = useOnboardingMachine();

  return (
    <OnboardingFinalStepPageLayout
      title={t("userV2:onboarding.businessIdRequestSent.title")}
      subtitle={
        <Trans
          i18nKey="userV2:onboarding.businessIdRequestSent.subtitle"
          values={{ businessId: businessId || "" }}
          components={{ b: <b /> }}
        />
      }
      nextButtonText={t("userV2:onboarding.businessIdRequestSent.gotIt")}
      nextButtonPath={`../${Path.benefits}`}
    />
  );
}
