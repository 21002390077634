import { ComponentType, FC } from "react";

import { get } from "lodash-es";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled, { AnyStyledComponent, css } from "styled-components";

import { SvgIconProps } from "../../Icons";
import { Typography } from "../../Typography";
import {
  Border,
  FontWeightVariants,
  TypographyVariants,
  makeTransition,
} from "../../styles";
import { RecursiveKeyOf } from "../../utils.types";

export type TabRowItemProps = {
  label: string;
  icon?: ComponentType<SvgIconProps>;
  disabled?: boolean;
  badgeCount?: number;
  to?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  rel?: string;
  labelTypoVariant?: TypographyVariants;
  labelWeight?: FontWeightVariants;
  border?: RecursiveKeyOf<Border>;
  end?: boolean;
};

export function TabRowItem({
  label,
  icon: Icon,
  badgeCount,
  labelWeight,
  labelTypoVariant,
  end = true,
  ...rest
}: TabRowItemProps) {
  return (
    <StyledRoot {...rest} replace preventScrollReset end={end}>
      {Icon && (
        <StyledIconWrapper>
          <Icon />
        </StyledIconWrapper>
      )}
      <Typography
        variant={labelTypoVariant || "h6"}
        weight={labelWeight}
        noWrap
      >
        {label}
      </Typography>
      {badgeCount ? (
        <StyledNotificationBadge
          role="status"
          variant="bodyXs"
          weight="bold"
          color="common.white"
          align="center"
        >
          {badgeCount > 9 ? "9+" : badgeCount}
        </StyledNotificationBadge>
      ) : null}
    </StyledRoot>
  );
}

type TabRowItemRootProps = Omit<
  TabRowItemProps,
  "icon" | "label" | "labelTypoVariant" | "labelWeight"
>;

const StyledRoot = styled(NavLink as AnyStyledComponent)<
  TabRowItemRootProps & NavLinkProps
>`
  opacity: ${({ theme }) => theme.opacity.dimmed};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: ${({ theme, border = "bold.light" }) =>
    get(theme.border, border)};
  &:not(.active) {
    border-color: transparent;
  }
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: ${theme.opacity.disabled};
      pointer-events: none;
    `}
  ${makeTransition("all", "shortest", "easeOut")}
`;

const StyledIconWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      display: none;
    `)};
`;

const StyledNotificationBadge = styled(Typography)`
  min-width: ${({ theme }) => theme.height.notificationBadge};
  height: ${({ theme }) => theme.height.notificationBadge};
  border-radius: ${({ theme }) => theme.height.notificationBadge};
  background: ${({ theme }) => theme.palette.secondary2.main};
  padding: 0 ${({ theme }) => theme.spacing.xxs};
`;
