import styled from "styled-components";

import {
  CircularButton,
  CloseIcon,
  Flex,
  Spinner,
  Typography,
} from "@vapaus/ui-v2";

import { ReturnProcessImage } from "../../../types";
import { formatFileSize } from "../utils/formatFileSize";

type UploadedPhotoPreviewProps = {
  file: ReturnProcessImage;
  onDelete: () => void;
  isLoading: boolean;
  onUploadedPhotoPreviewClick: (src: string) => void;
};

export function UploadedPhotoPreview({
  file,
  onDelete,
  isLoading,
  onUploadedPhotoPreviewClick,
}: UploadedPhotoPreviewProps) {
  return (
    <StyledPreviewContainer justify="space-between">
      <StyledOverflowHiddenFlex>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner data-testid="spinner" />
          </SpinnerContainer>
        ) : (
          <StyledImage
            onClick={() => onUploadedPhotoPreviewClick(file.src!)}
            src={file.src}
            alt={file.name}
            width="96px"
          />
        )}
        <StyledOverflowHiddenFlex direction="column" gap="xxs">
          <Typography weight="bold">{file.name}</Typography>
          <Typography variant="bodySm">
            {formatFileSize(file.size ?? 0)}
          </Typography>
        </StyledOverflowHiddenFlex>
      </StyledOverflowHiddenFlex>
      <CircularButton onClick={onDelete} icon={<CloseIcon />} />
    </StyledPreviewContainer>
  );
}

const StyledPreviewContainer = styled(Flex)`
  border: ${({ theme }) => theme.border.secondary.main};
  border-radius: ${({ theme }) => theme.radius.actionCard};
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.palette.background.white};
  min-height: 100px;
`;

const StyledOverflowHiddenFlex = styled(Flex)`
  overflow: hidden;
`;

const SpinnerContainer = styled(Flex)`
  border-radius: ${({ theme }) => theme.radius.actionCard};
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  width: 6rem;
  height: 4.5rem;
`;

const StyledImage = styled.img`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius.actionCard};
`;
