import { ReactNode, useState } from "react";

import styled, { css } from "styled-components";

import { Flex } from "@vapaus/ui-v2";

type UploadPhotoDragAndDropAreaProps = {
  uploadImages: (images: File[]) => void;
  children: ReactNode;
};

export function UploadPhotoDragAndDropArea({
  uploadImages,
  children,
}: UploadPhotoDragAndDropAreaProps) {
  const [isDragOverInProgress, setIsDragOverInProgress] =
    useState<boolean>(false);

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    setIsDragOverInProgress(false);
    const droppedImages = event.dataTransfer?.files;
    if (droppedImages?.length && droppedImages.length > 0) {
      uploadImages(Array.from(droppedImages));
    }
  };

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    setIsDragOverInProgress(true);
  };

  const onDragLeave = (event: DragEvent) => {
    event.preventDefault();
    setIsDragOverInProgress(false);
  };

  return (
    <StyledUploadContainer
      direction="column"
      align="center"
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      $isDragOverInProgress={isDragOverInProgress}
    >
      {children}
    </StyledUploadContainer>
  );
}

const StyledUploadContainer = styled(Flex)<{ $isDragOverInProgress: boolean }>`
  border: ${({ theme }) => theme.border.secondary.main};
  border-radius: ${({ theme }) => theme.radius.actionCard};
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.palette.background.white};

  ${({ $isDragOverInProgress }) =>
    $isDragOverInProgress &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary1.lighter};
    `}
`;
