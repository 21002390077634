import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { AnyStyledComponent, css } from "styled-components";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import {
  BunnyWithBike3DIcon,
  Button,
  Flex,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { useGetCurrentUser } from "../../../api/user";
import { Path } from "../../../constants/paths";

interface EmptyProps {
  hasActivation: boolean;
}

export function Empty({ hasActivation }: EmptyProps) {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUser();

  return (
    <Flex direction="column" align="center" gap="md">
      <BunnyWithBike3DIcon size="lg" />
      <Flex direction="column" align="center" gap="sm">
        <Typography variant="h1" align="center">
          {hasActivation
            ? t("userV2:myBikes.empty.title")
            : t("userV2:myBikes.empty.noActivation.title")}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {hasActivation
            ? t("userV2:myBikes.empty.description")
            : t("userV2:myBikes.empty.noActivation.description")}
        </Typography>
      </Flex>
      <Flex xsDirection="column" justify="center" fullWidth>
        <StyledLink to={`/${Path.getABike}`}>
          <Button xsFullWidth>{t("userV2:myBikes.empty.getABike")}</Button>
        </StyledLink>
        {user?.country === SupportedCountriesEnum.Fi ? (
          <StyledLink to={`/${Path.calculator}`}>
            <Button variant="outlined" xsFullWidth>
              {t("userV2:myBikes.empty.calculate")}
            </Button>
          </StyledLink>
        ) : null}
      </Flex>
      <Flex direction="column" align="center" gap="xs">
        <Typography align="center">{t("userV2:myBikes.empty.hint")}</Typography>
        <TextButton
          to={t("userV2:myBikes.empty.downloadGuide.link")}
          target="_blank"
          size="md"
        >
          {t("userV2:myBikes.empty.downloadGuide.text")}
        </TextButton>
      </Flex>
    </Flex>
  );
}

const StyledLink = styled(Link as AnyStyledComponent)`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
    `)}
`;
