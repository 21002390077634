import { useState } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import {
  BikeBenefitContractRead,
  ShopMaintenanceEventRead,
} from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Card,
  Lens3DIcon,
  Table,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useMaintenanceEventsTable } from "../hooks/useMaintenanceEventsTable";
import { EventCell } from "./EventCell";
import { MaintenanceEventShowModal } from "./MaintenanceEventShowModal";
import { PriceCell } from "./PriceCell";

interface MaintenanceTableCardProps {
  contract: BikeBenefitContractRead;
}

export function MaintenanceTableCard({ contract }: MaintenanceTableCardProps) {
  const { t } = useTranslation();
  const [maintenanceEvent, setMaintenanceEvent] =
    useState<ShopMaintenanceEventRead>();
  const { paginationOptions, sortingOptions, data, isLoading } =
    useMaintenanceEventsTable(contract);
  const { getEnumLabel } = useEnumEntries("WorkTypes");
  const { isMobile } = useBreakpoints();

  const columns: Array<ColumnDef<ShopMaintenanceEventRead>> = [
    {
      header: t("userV2:myBike.maintenanceEventsTable.columns.event"),
      enableSorting: true,
      accessorKey: "workDate",
      cell: ({ row }) => (
        <EventCell
          type={getEnumLabel(row.original.workType) ?? ""}
          date={row.original.workDate}
        />
      ),
      size: 40,
    },
    ...(isMobile
      ? []
      : [
          {
            header: t(
              "userV2:myBike.maintenanceEventsTable.columns.serviceProvider",
            ),
            enableSorting: true,
            accessorKey: "shopName",
            cell: ({ row }: { row: Row<ShopMaintenanceEventRead> }) => (
              <Typography align="right">{row.original.shop.name}</Typography>
            ),
            size: 30,
          },
        ]),
    {
      header: t("userV2:myBike.maintenanceEventsTable.columns.price"),
      enableSorting: true,
      accessorKey: "totalPrice",
      cell: ({ row }) => (
        <PriceCell
          price={row.original.totalPrice}
          currency={row.original.currency}
        />
      ),
    },
  ];

  return (
    <Card title={t("userV2:myBike.maintenanceEventsTable.title")}>
      {isLoading ? null : (
        <Table<ShopMaintenanceEventRead>
          data={data?.items ?? []}
          columns={columns}
          paginationOptions={paginationOptions}
          sortingOptions={sortingOptions}
          emptyStateComponent={
            <TableEmptyState
              icon={<Lens3DIcon size="lg" />}
              title={t("userV2:myBike.maintenanceEventsTable.emptyState.title")}
              subtitle={t(
                "userV2:myBike.maintenanceEventsTable.emptyState.subtitle",
              )}
            />
          }
          onRowClick={setMaintenanceEvent}
        />
      )}
      <MaintenanceEventShowModal
        isOpen={Boolean(maintenanceEvent)}
        onClose={() => setMaintenanceEvent(undefined)}
        contract={contract}
        maintenanceEvent={maintenanceEvent}
      />
    </Card>
  );
}
