import { createSvgIcon } from "./SvgIcon";

export const VisibilityIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.9998 16C13.2498 16 14.3123 15.5625 15.1873 14.6875C16.0623 13.8125 16.4998 12.75 16.4998 11.5C16.4998 10.25 16.0623 9.1875 15.1873 8.3125C14.3123 7.4375 13.2498 7 11.9998 7C10.7498 7 9.6873 7.4375 8.8123 8.3125C7.9373 9.1875 7.4998 10.25 7.4998 11.5C7.4998 12.75 7.9373 13.8125 8.8123 14.6875C9.6873 15.5625 10.7498 16 11.9998 16ZM11.9998 14.2C11.2498 14.2 10.6123 13.9375 10.0873 13.4125C9.5623 12.8875 9.2998 12.25 9.2998 11.5C9.2998 10.75 9.5623 10.1125 10.0873 9.5875C10.6123 9.0625 11.2498 8.8 11.9998 8.8C12.7498 8.8 13.3873 9.0625 13.9123 9.5875C14.4373 10.1125 14.6998 10.75 14.6998 11.5C14.6998 12.25 14.4373 12.8875 13.9123 13.4125C13.3873 13.9375 12.7498 14.2 11.9998 14.2ZM11.9998 19C9.68314 19 7.56647 18.3875 5.6498 17.1625C3.73314 15.9375 2.28314 14.2833 1.2998 12.2C1.2498 12.1167 1.21647 12.0125 1.1998 11.8875C1.18314 11.7625 1.1748 11.6333 1.1748 11.5C1.1748 11.3667 1.18314 11.2375 1.1998 11.1125C1.21647 10.9875 1.2498 10.8833 1.2998 10.8C2.28314 8.71667 3.73314 7.0625 5.6498 5.8375C7.56647 4.6125 9.68314 4 11.9998 4C14.3165 4 16.4331 4.6125 18.3498 5.8375C20.2665 7.0625 21.7165 8.71667 22.6998 10.8C22.7498 10.8833 22.7831 10.9875 22.7998 11.1125C22.8165 11.2375 22.8248 11.3667 22.8248 11.5C22.8248 11.6333 22.8165 11.7625 22.7998 11.8875C22.7831 12.0125 22.7498 12.1167 22.6998 12.2C21.7165 14.2833 20.2665 15.9375 18.3498 17.1625C16.4331 18.3875 14.3165 19 11.9998 19ZM11.9998 17C13.8831 17 15.6123 16.5042 17.1873 15.5125C18.7623 14.5208 19.9665 13.1833 20.7998 11.5C19.9665 9.81667 18.7623 8.47917 17.1873 7.4875C15.6123 6.49583 13.8831 6 11.9998 6C10.1165 6 8.3873 6.49583 6.8123 7.4875C5.2373 8.47917 4.03314 9.81667 3.1998 11.5C4.03314 13.1833 5.2373 14.5208 6.8123 15.5125C8.3873 16.5042 10.1165 17 11.9998 17Z" />
  </svg>,
  "Visibility",
);
