/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
} from "./BikeBrand";
import type { BikeCondition } from "./BikeCondition";
import {
  BikeConditionFromJSON,
  BikeConditionFromJSONTyped,
  BikeConditionToJSON,
} from "./BikeCondition";
import type { BikeDriveType } from "./BikeDriveType";
import {
  BikeDriveTypeFromJSON,
  BikeDriveTypeFromJSONTyped,
  BikeDriveTypeToJSON,
} from "./BikeDriveType";
import type { BikeFrameType } from "./BikeFrameType";
import {
  BikeFrameTypeFromJSON,
  BikeFrameTypeFromJSONTyped,
  BikeFrameTypeToJSON,
} from "./BikeFrameType";
import type { BikeStatus } from "./BikeStatus";
import {
  BikeStatusFromJSON,
  BikeStatusFromJSONTyped,
  BikeStatusToJSON,
} from "./BikeStatus";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";

/**
 *
 * @export
 * @interface BikeAdminRead
 */
export interface BikeAdminRead {
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  serialNumber: string;
  /**
   *
   * @type {Date}
   * @memberof BikeAdminRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeAdminRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {BikeCondition}
   * @memberof BikeAdminRead
   */
  condition: BikeCondition;
  /**
   *
   * @type {BikeStatus}
   * @memberof BikeAdminRead
   */
  status: BikeStatus;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  bikeBrandId: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  model: string;
  /**
   *
   * @type {BikeDriveType}
   * @memberof BikeAdminRead
   */
  driveType?: BikeDriveType;
  /**
   *
   * @type {BikeFrameType}
   * @memberof BikeAdminRead
   */
  frameType?: BikeFrameType;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof BikeAdminRead
   */
  purchasePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeAdminRead
   */
  purchaseCurrency: Currency;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  pictureId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeAdminRead
   */
  url?: string;
  /**
   *
   * @type {BikeBrand}
   * @memberof BikeAdminRead
   */
  bikeBrand: BikeBrand;
}

/**
 * Check if a given object implements the BikeAdminRead interface.
 */
export function instanceOfBikeAdminRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "serialNumber" in value;
  isInstance = isInstance && "condition" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "bikeBrandId" in value;
  isInstance = isInstance && "model" in value;
  isInstance = isInstance && "size" in value;
  isInstance = isInstance && "colour" in value;
  isInstance = isInstance && "purchasePrice" in value;
  isInstance = isInstance && "purchaseCurrency" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "bikeBrand" in value;

  return isInstance;
}

export function BikeAdminReadFromJSON(json: any): BikeAdminRead {
  return BikeAdminReadFromJSONTyped(json, false);
}

export function BikeAdminReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeAdminRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    serialNumber: json["serial_number"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    condition: BikeConditionFromJSON(json["condition"]),
    status: BikeStatusFromJSON(json["status"]),
    bikeBrandId: json["bike_brand_id"],
    model: json["model"],
    driveType: !exists(json, "drive_type")
      ? undefined
      : BikeDriveTypeFromJSON(json["drive_type"]),
    frameType: !exists(json, "frame_type")
      ? undefined
      : BikeFrameTypeFromJSON(json["frame_type"]),
    size: json["size"],
    colour: json["colour"],
    purchasePrice: json["purchase_price"],
    purchaseCurrency: CurrencyFromJSON(json["purchase_currency"]),
    shopId: json["shop_id"],
    pictureId: !exists(json, "picture_id") ? undefined : json["picture_id"],
    url: !exists(json, "url") ? undefined : json["url"],
    bikeBrand: BikeBrandFromJSON(json["bike_brand"]),
  };
}

export function BikeAdminReadToJSON(value?: BikeAdminRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    serial_number: value.serialNumber,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    condition: BikeConditionToJSON(value.condition),
    status: BikeStatusToJSON(value.status),
    bike_brand_id: value.bikeBrandId,
    model: value.model,
    drive_type: BikeDriveTypeToJSON(value.driveType),
    frame_type: BikeFrameTypeToJSON(value.frameType),
    size: value.size,
    colour: value.colour,
    purchase_price: value.purchasePrice,
    purchase_currency: CurrencyToJSON(value.purchaseCurrency),
    shop_id: value.shopId,
    picture_id: value.pictureId,
    url: value.url,
    bike_brand: BikeBrandToJSON(value.bikeBrand),
  };
}
