import { useTranslation } from "react-i18next";

import { Flex, SpinningLogo, Typography } from "@vapaus/ui-v2";

import { usePaymentProcessCallback } from "../hooks/usePaymentProcessCallback";

type PaymentProcessingViewProps = {
  cancellationType: "redemption" | "return";
};

export function PaymentProcessingView({
  cancellationType,
}: PaymentProcessingViewProps) {
  const { t } = useTranslation();
  usePaymentProcessCallback(cancellationType);

  return (
    <Flex align="center" justify="center" fullHeight fullWidth>
      <Flex direction="column" align="center">
        <SpinningLogo />
        <Typography variant="h1" align="center">
          {t("userV2:redeemReturnSharedComponents.paymentProcessing.title")}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {t(
            "userV2:redeemReturnSharedComponents.paymentProcessing.description",
          )}
        </Typography>
      </Flex>
    </Flex>
  );
}
