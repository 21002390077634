import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Flex, TextButton, Typography } from "@vapaus/ui-v2";

type ClearFiltersSectionProps = {
  showingItemsText: string;
  onClick: () => void;
  buttonActive: boolean;
};

export function ClearFiltersSection({
  showingItemsText,
  onClick,
  buttonActive = false,
}: ClearFiltersSectionProps) {
  const { t } = useTranslation();

  return (
    <Flex xsDirection="column">
      <Typography weight="bold">{showingItemsText}</Typography>
      <StyledClearFiltersButtonWrapper>
        <TextButton size="md" onClick={onClick} disabled={!buttonActive}>
          {t("commonV2:clearAllFilters")}
        </TextButton>
      </StyledClearFiltersButtonWrapper>
    </Flex>
  );
}

const StyledClearFiltersButtonWrapper = styled.div`
  border-left: ${({ theme }) => theme.border.primary.main};
  padding-left: ${({ theme }) => theme.spacing.sm};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      border-left: none;
      padding-left: 0;
    `)}
`;
