import { useEffect, useRef } from "react";

import { TransitionFn, useSpringRef, useTransition } from "@react-spring/web";

type UsePageTransitionProps = {
  current: number;
  startUpStep?: number;
  onStart?: () => void;
  onFinished?: () => void;
};

export const usePageTransition = ({
  current,
  startUpStep = 0,
  onStart,
  onFinished,
}: UsePageTransitionProps): TransitionFn<
  number,
  {
    opacity: number;
    transform: string;
  }
> => {
  const previousIndex = useRef<number>(0);
  const isForward = current > previousIndex.current;

  const isStartUp = previousIndex.current === 0 && current === startUpStep;

  const transRef = useSpringRef();

  const from = {
    opacity: 0,
    transform: isForward ? "translate3d(100%,0,0)" : "translate3d(-65%,0,0)",
  };

  const enter = {
    opacity: 1,
    transform: "translate3d(0%,0,0)",
  };

  const transitions = useTransition(current, {
    ref: transRef,
    keys: null,
    from: isStartUp ? enter : from,
    enter,
    leave: {
      opacity: 0,
      marginRight: "-100%",
      transform: isForward ? "translate3d(-65%,0,0)" : "translate3d(100%,0,0)",
    },
    reverse: true,
    onStart: () => {
      onStart?.();
    },
    onRest: (_, __, item) => {
      previousIndex.current = item;
      onFinished?.();
    },
  });

  useEffect(() => {
    transRef.start();
    return () => {
      previousIndex.current = 0;
    };
  }, [current, transRef]);

  return transitions;
};
