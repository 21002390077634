/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Plan } from "./Plan";
import { PlanFromJSON, PlanFromJSONTyped, PlanToJSON } from "./Plan";
import type { PledgedLender } from "./PledgedLender";
import {
  PledgedLenderFromJSON,
  PledgedLenderFromJSONTyped,
  PledgedLenderToJSON,
} from "./PledgedLender";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface OrganisationRead
 */
export interface OrganisationRead {
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  businessId: string;
  /**
   *
   * @type {any}
   * @memberof OrganisationRead
   */
  path?: any | null;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  logoObjectId?: string;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof OrganisationRead
   */
  country: SupportedOrganisationCountries;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  email?: string;
  /**
   *
   * @type {PledgedLender}
   * @memberof OrganisationRead
   */
  pledgedLender?: PledgedLender;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  parentId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationRead
   */
  logoUrl?: string;
  /**
   *
   * @type {Plan}
   * @memberof OrganisationRead
   */
  plan?: Plan;
}

/**
 * Check if a given object implements the OrganisationRead interface.
 */
export function instanceOfOrganisationRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "businessId" in value;
  isInstance = isInstance && "country" in value;
  isInstance = isInstance && "id" in value;

  return isInstance;
}

export function OrganisationReadFromJSON(json: any): OrganisationRead {
  return OrganisationReadFromJSONTyped(json, false);
}

export function OrganisationReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganisationRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    description: !exists(json, "description") ? undefined : json["description"],
    businessId: json["business_id"],
    path: !exists(json, "path") ? undefined : json["path"],
    logoObjectId: !exists(json, "logo_object_id")
      ? undefined
      : json["logo_object_id"],
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    email: !exists(json, "email") ? undefined : json["email"],
    pledgedLender: !exists(json, "pledged_lender")
      ? undefined
      : PledgedLenderFromJSON(json["pledged_lender"]),
    id: json["id"],
    parentId: !exists(json, "parent_id") ? undefined : json["parent_id"],
    logoUrl: !exists(json, "logo_url") ? undefined : json["logo_url"],
    plan: !exists(json, "plan") ? undefined : PlanFromJSON(json["plan"]),
  };
}

export function OrganisationReadToJSON(value?: OrganisationRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    business_id: value.businessId,
    path: value.path,
    logo_object_id: value.logoObjectId,
    country: SupportedOrganisationCountriesToJSON(value.country),
    phone_number: value.phoneNumber,
    email: value.email,
    pledged_lender: PledgedLenderToJSON(value.pledgedLender),
    id: value.id,
    parent_id: value.parentId,
    logo_url: value.logoUrl,
    plan: PlanToJSON(value.plan),
  };
}
