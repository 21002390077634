import { SupportedCountriesEnum } from "@vapaus/api-codegen";

export const BusinessIdFormat: {
  [key in SupportedCountriesEnum]: string;
} = {
  [SupportedCountriesEnum.Fi]: "XXXXXXX-X",
  [SupportedCountriesEnum.Se]: "XXXXXX-XXXX",
};

export const BusinessIdSearchUrl: {
  [key in SupportedCountriesEnum]: string;
} = {
  [SupportedCountriesEnum.Fi]: "ytj.fi",
  [SupportedCountriesEnum.Se]: "bolagsverket.se",
};

export const BusinessIdPlaceholder: {
  [key in SupportedCountriesEnum]: string;
} = {
  [SupportedCountriesEnum.Fi]: "1234567-8",
  [SupportedCountriesEnum.Se]: "123456-7890",
};
