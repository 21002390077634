/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BenefitCalculation
 */
export interface BenefitCalculation {
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  taxablePackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  paymentsTotal: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  financialInterest: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyLeasingFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  monthlyInsuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  downPaymentAmount: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  yearlyDownPaymentInsuranceFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalFinancingCost: number;
  /**
   *
   * @type {number}
   * @memberof BenefitCalculation
   */
  totalServiceCost: number;
}

/**
 * Check if a given object implements the BenefitCalculation interface.
 */
export function instanceOfBenefitCalculation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "taxablePackagePrice" in value;
  isInstance = isInstance && "totalTaxableValue" in value;
  isInstance = isInstance && "paymentsTotal" in value;
  isInstance = isInstance && "financialInterest" in value;
  isInstance = isInstance && "redemptionPercentage" in value;
  isInstance = isInstance && "residualValue" in value;
  isInstance = isInstance && "monthlyLeasingFee" in value;
  isInstance = isInstance && "monthlyTaxableValue" in value;
  isInstance = isInstance && "monthlyInsuranceFee" in value;
  isInstance = isInstance && "downPaymentAmount" in value;
  isInstance = isInstance && "yearlyDownPaymentInsuranceFee" in value;
  isInstance = isInstance && "totalFinancingCost" in value;
  isInstance = isInstance && "totalServiceCost" in value;

  return isInstance;
}

export function BenefitCalculationFromJSON(json: any): BenefitCalculation {
  return BenefitCalculationFromJSONTyped(json, false);
}

export function BenefitCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    leasingPeriodMonths: json["leasing_period_months"],
    taxablePackagePrice: json["taxable_package_price"],
    totalTaxableValue: json["total_taxable_value"],
    paymentsTotal: json["payments_total"],
    financialInterest: json["financial_interest"],
    redemptionPercentage: json["redemption_percentage"],
    residualValue: json["residual_value"],
    monthlyLeasingFee: json["monthly_leasing_fee"],
    monthlyTaxableValue: json["monthly_taxable_value"],
    monthlyInsuranceFee: json["monthly_insurance_fee"],
    downPaymentAmount: json["down_payment_amount"],
    yearlyDownPaymentInsuranceFee: json["yearly_down_payment_insurance_fee"],
    totalFinancingCost: json["total_financing_cost"],
    totalServiceCost: json["total_service_cost"],
  };
}

export function BenefitCalculationToJSON(
  value?: BenefitCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    leasing_period_months: value.leasingPeriodMonths,
    taxable_package_price: value.taxablePackagePrice,
    total_taxable_value: value.totalTaxableValue,
    payments_total: value.paymentsTotal,
    financial_interest: value.financialInterest,
    redemption_percentage: value.redemptionPercentage,
    residual_value: value.residualValue,
    monthly_leasing_fee: value.monthlyLeasingFee,
    monthly_taxable_value: value.monthlyTaxableValue,
    monthly_insurance_fee: value.monthlyInsuranceFee,
    down_payment_amount: value.downPaymentAmount,
    yearly_down_payment_insurance_fee: value.yearlyDownPaymentInsuranceFee,
    total_financing_cost: value.totalFinancingCost,
    total_service_cost: value.totalServiceCost,
  };
}
