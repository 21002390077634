import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, TextButton, Typography, Warning3DIcon } from "@vapaus/ui-v2";

export function ErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);
  const handleTryAgain = () => window.location.reload();
  return (
    <StyledRoot>
      <StyledContent>
        <Warning3DIcon size="lg" />
        <div>
          <Typography variant="h1" align="center" fullWidth marginBottom="sm">
            {t("authV2:error.genericErrorPage.title")}
          </Typography>
          <Typography variant="bodyLg" align="center" fullWidth>
            {t("authV2:error.genericErrorPage.description")}
          </Typography>
        </div>
        <Button
          onClick={handleTryAgain}
          variant="primary1"
          marginBottom="sm"
          marginTop="sm"
          fullWidth
        >
          {t("authV2:error.genericErrorPage.tryAgain")}
        </Button>
        <TextButton onClick={handleGoBack} align="center">
          {t("authV2:error.genericErrorPage.goBack")}
        </TextButton>
      </StyledContent>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  min-height: calc(100vh - 80px);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 432px;
  max-width: 100%;
  margin: auto;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.md}`};
`;
