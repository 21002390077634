import { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { UserEmailRead } from "@vapaus/api-codegen";
import { useSendEmailVerificationToken } from "@vapaus/shared-api";
import { Modal, TextButton, Typography, useToaster } from "@vapaus/ui-v2";

type ResendVerificationButtonProps = {
  readonly email: UserEmailRead;
};

export function ResendVerificationButton({
  email,
}: ResendVerificationButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);

  const location = useLocation();

  const sendEmailVerification = useSendEmailVerificationToken();

  const toaster = useToaster();

  const handResendVerificationEmail = () => {
    sendEmailVerification.mutate(
      {
        userEmailId: email.id,
        resumePath: location.pathname,
      },
      {
        onSuccess: () => setIsOpen(true),
        onError: async (error) => {
          const message = await error.response.json();
          toaster.add("error", message.detail);
        },
      },
    );
  };

  const resendingText = t(
    "commonV2:alternateEmail.resendVerificationMailModal.resending",
  );
  const resendText = t(
    "commonV2:alternateEmail.resendVerificationMailModal.resend",
  );

  return (
    <>
      <TextButton onClick={handResendVerificationEmail} size="md">
        {sendEmailVerification.isLoading ? resendingText : resendText}
      </TextButton>
      <Modal
        isOpen={isOpen}
        title={t("commonV2:alternateEmail.resendVerificationMailModal.title")}
        confirmText={t("commonV2:gotIt")}
        onClose={handleCloseModal}
        onConfirm={handleCloseModal}
      >
        <Typography align="center" variant="bodyLg">
          <Trans
            t={t}
            i18nKey="commonV2:alternateEmail.resendVerificationMailModal.description"
            values={{ email: email.email }}
            components={{ 1: <strong /> }}
          />
        </Typography>
      </Modal>
    </>
  );
}
