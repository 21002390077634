import { snakeCase } from "lodash-es";

import { useTableParams } from "@vapaus/ui-v2";

import { useSearchInvoice } from "../../api/invoice";

interface BillingTableParams {
  bikeBenefitContractId?: string;
  bikeBenefitOrderId?: string;
}

export function useBillingTable({
  bikeBenefitContractId,
  bikeBenefitOrderId,
}: BillingTableParams = {}) {
  const defaultSortId = "createdAt";

  const { sorting, pagination, setSorting, setPagination } = useTableParams({
    sortId: defaultSortId,
  });

  const { data, isLoading } = useSearchInvoice(
    {
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      sortField: snakeCase(
        sorting[0]?.id === "null" ? defaultSortId : sorting[0]?.id,
      ),
      sortDirection: sorting[0]?.desc ? "desc" : "asc",
      bikeBenefitContractId,
      bikeBenefitOrderId,
    },
    {
      keepPreviousData: true,
    },
  );

  const paginationOptions = data?.total
    ? {
        totalItems: data.total,
        pagination,
        setPagination,
      }
    : undefined;

  const sortingOptions = {
    sorting,
    setSorting,
  };

  return {
    data: data?.items || [],
    isLoading,
    paginationOptions,
    sortingOptions,
  };
}
