import { ChangeEvent, forwardRef, useId } from "react";

import styled, { keyframes } from "styled-components";

import { Flex } from "../Flex";
import { makeTransition } from "../styles";

export interface RadioProps {
  label: string;
  name: string;
  value?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  { label, name, value, defaultChecked, disabled, id, ...rest },
  ref,
) {
  const generatedId = useId();
  id = id ?? generatedId;

  return (
    <Flex align="flex-start" gap="sm">
      <StyledInputWrapper align="flex-start">
        <StyledInput
          type="radio"
          id={id}
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          disabled={disabled}
          {...rest}
          ref={ref}
        />
      </StyledInputWrapper>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </Flex>
  );
});

const StyledInputWrapper = styled(Flex)`
  margin-top: 4px;
`;

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  cursor: pointer;
`;

const outlineKeyframe = keyframes`
  0% {
    outline-offset: 0;
  }

  100% {
    outline-offset: -0.2rem;
  }
`;

const StyledInput = styled.input`
  height: 16px;
  width: 16px;
  margin: 0;
  appearance: none;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.palette.background.white};
  background-color: ${({ theme }) => theme.palette.background.white};
  accent-color: ${({ theme }) => theme.palette.primary1.main};
  outline: 2px solid ${({ theme }) => theme.palette.primary1.main};

  ${makeTransition("background-color", "shortest", "easeOut")}
  &:hover {
    outline-color: ${({ theme }) => theme.palette.primary1.main};
  }

  &:checked {
    outline-color: ${({ theme }) => theme.palette.primary1.main};
    background-color: ${({ theme }) => theme.palette.primary1.main};
    animation: ${outlineKeyframe};
    animation-duration: 0.1s;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  &:focus-visible {
    outline-offset: 0;
  }

  &:disabled,
  &:disabled + label {
    opacity: ${({ theme }) => theme.opacity.disabled};
  }
`;
