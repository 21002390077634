import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Form, Input } from "@vapaus/form";
import { Modal, Notification, PersonIcon } from "@vapaus/ui-v2";

import { useChangeNameModal } from "./useChangeNameModal";

export type ChangeNameModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export function ChangeNameModal({ isOpen, onClose }: ChangeNameModalProps) {
  const { t } = useTranslation();

  const { formMethods, mutation, rootErrorMessage, handleReset } =
    useChangeNameModal(onClose);

  const handleCancel = () => {
    handleReset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("commonV2:changeNameModal.title")}
      description={t("commonV2:changeNameModal.description")}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:save")}
      onCancel={handleCancel}
      onClose={handleCancel}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <Form formMethods={formMethods}>
        <StyledContent>
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <Input
            name="firstName"
            label={t("commonV2:changeNameModal.firstName")}
            placeholder={t("commonV2:changeNameModal.firstName")}
            startAdornment={<PersonIcon />}
          />
          <Input
            name="lastName"
            label={t("commonV2:changeNameModal.lastName")}
            placeholder={t("commonV2:changeNameModal.lastName")}
            startAdornment={<PersonIcon />}
          />
        </StyledContent>
      </Form>
    </Modal>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;
