import { ForwardedRef, ReactNode, forwardRef, useId, useRef } from "react";

import { PolymorphicComponent, SelectOption } from "@mui/base";
import { Option } from "@mui/base/Option";
import { Popper as BasePopper, PopperTypeMap } from "@mui/base/Popper";
import {
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
  SelectRootSlotProps,
} from "@mui/base/Select";
import clsx from "clsx";
import { flatten } from "lodash-es";
import styled, { AnyStyledComponent, css } from "styled-components";

import { ArrowDropDownIcon } from "../Icons/ArrowDropDownIcon";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { StyledDropdownItemRoot } from "../dropdown/StyledDropdownItemRoot";
import { makeTransition } from "../styles";

export type SelectOptionWithoutIconType<TValue> = {
  label: string;
  value: TValue;
  disabled?: boolean;
  tooltip?: string;
};

export type SelectOptionWithIconType<TValue> =
  SelectOptionWithoutIconType<TValue> & {
    icon: ReactNode;
  };

type OptionsWithoutIconType<TValue> = Array<
  SelectOptionWithoutIconType<TValue>
>;
type OptionsWithIconType<TValue> = Array<SelectOptionWithIconType<TValue>>;

type SelectOptions<TValue> = {
  options: OptionsWithoutIconType<TValue> | OptionsWithIconType<TValue>;
  optionGroups?: never;
};

type SelectOptionGroups<TValue> = {
  options?: never;
  optionGroups:
    | Array<OptionsWithoutIconType<TValue>>
    | Array<OptionsWithIconType<TValue>>;
};

export type SelectCustomProps<TValue> = (
  | SelectOptions<TValue>
  | SelectOptionGroups<TValue>
) & {
  placeholder?: string;
  error?: string;
  label?: string;
  tooltip?: string;
  id?: string;
  fullWidth?: boolean;
};

export type SelectProps<
  TValue extends string | number,
  Multiple extends boolean,
> = BaseSelectProps<TValue, Multiple> & SelectCustomProps<TValue>;

export function Select<TValue extends string | number>({
  value,
  options,
  optionGroups,
  onChange,
  placeholder,
  disabled,
  error,
  id,
  label,
  tooltip,
  className,
  slotProps,
  fullWidth,
  slots,
}: SelectProps<TValue, false>) {
  const renderSelectedValue = (option: SelectOption<TValue> | null) => {
    if (option === null || option.value === null) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
    }
    const selectableOptions = options ?? flatten(optionGroups);
    const customOption = selectableOptions.find(
      (opt) => opt.value === option.value,
    );
    if (customOption && "icon" in customOption) {
      const icon = customOption.icon as ReactNode;

      return (
        <StyledSelectedValueWithIconContainer>
          {icon}
          <Typography noWrap variant="bodyMd" weight="bold">
            {customOption.label}
          </Typography>
        </StyledSelectedValueWithIconContainer>
      );
    }

    return (
      <StyledSelectedValueContainer>
        {customOption?.label}
      </StyledSelectedValueContainer>
    );
  };

  return (
    <CustomSelect<TValue, false>
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options ?? flatten(optionGroups)}
      disabled={disabled}
      error={error}
      id={id}
      label={label}
      tooltip={tooltip}
      renderValue={renderSelectedValue}
      slotProps={slotProps}
      className={className}
      fullWidth={fullWidth}
      slots={slots}
    >
      {options
        ? options.map((option) => (
            <CustomOption<TValue> key={option.value} {...option} />
          ))
        : optionGroups!.map((group, index) => {
            return (
              <StyledOptionsGroup key={index}>
                {group.map((option) => (
                  <CustomOption<TValue> key={option.value} {...option} />
                ))}
              </StyledOptionsGroup>
            );
          })}
    </CustomSelect>
  );
}

function CustomSelect<
  TValue extends string | number,
  Multiple extends boolean,
>({ ...props }: SelectProps<TValue, Multiple>) {
  const slots: SelectProps<TValue, Multiple>["slots"] = {
    root: CustomButton,
    listbox: StyledListbox,
    popper: Popper,
    ...props.slots,
  };

  const ref = useRef<HTMLDivElement>(null);

  const slotProps = {
    root: {
      className: props.className,
    },
    listbox: {
      style: {
        minWidth: ref.current?.offsetWidth ?? "auto",
      },
    },
    ...props.slotProps,
  };

  const generatedId = useId();
  const componentId = props.id ?? generatedId;

  return (
    <StyledSelectRoot fullWidth={props.fullWidth} ref={ref}>
      {props.label || props.tooltip ? (
        <StyledLabelContainer>
          {props.label && (
            <StyledLabel htmlFor={componentId}>{props.label}</StyledLabel>
          )}
          {props.tooltip && <StyledTooltip>{props.tooltip}</StyledTooltip>}
        </StyledLabelContainer>
      ) : null}
      <BaseSelect<TValue, Multiple, typeof StyledButton>
        {...props}
        className={clsx(props.error && "error")}
        slots={slots}
        slotProps={slotProps}
        id={componentId}
      />
      {props.error && (
        <StyledErrorText role="alert">{props.error}</StyledErrorText>
      )}
    </StyledSelectRoot>
  );
}

const CustomButton = forwardRef(function CustomButton<
  TValue extends string | number,
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <StyledButton {...props} ref={ref} className={props.className}>
      {props.children}
      <StyledAdornment>
        <ArrowDropDownIcon />
      </StyledAdornment>
    </StyledButton>
  );
});

type OptionProps<TValue> = {
  icon?: ReactNode;
  label: string;
  value: TValue;
  disabled?: boolean;
  tooltip?: string;
};

function CustomOption<TValue extends string | number>({
  icon,
  label,
  value,
  disabled = false,
  tooltip,
}: OptionProps<TValue>) {
  return (
    <Option value={value}>
      <StyledDropdownItemRoot disabled={disabled}>
        {icon}
        <Typography variant="bodyMd" weight="bold">
          {label}
        </Typography>
        {tooltip ? <StyledTooltip>{tooltip}</StyledTooltip> : null}
      </StyledDropdownItemRoot>
    </Option>
  );
}

const StyledOptionsGroup = styled.div`
  border-bottom: ${({ theme }) => theme.border.primary.light};
  padding: ${({ theme }) => theme.spacing.xs} 0;
`;

const StyledSelectedValueWithIconContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  flex: 1;
`;

const StyledSelectedValueContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
`;

const StyledSelectRoot = styled.div<{
  fullWidth?: boolean;
}>`
  display: inline-flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledTooltip = styled(Tooltip as AnyStyledComponent)`
  margin-left: auto;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.none};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  border-color: ${({ theme }) => theme.palette.primary1.main};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  height: 56px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  color: ${({ theme }) => theme.typography.defaultColor};

  ${makeTransition("all", "shortest", "easeOut")}
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary1.light};
  }

  &:active,
  &:focus-within {
    box-shadow: ${({ theme }) => theme.shadows.input};
  }

  &:disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.error};
  }

  ::placeholder {
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }
`;

const StyledAdornment = styled.div`
  &:first-child {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }

  &:last-child {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }

  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.typography.defaultColor};
`;

const StyledListbox = styled.ul`
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.white};
  border-radius: ${({ theme }) => theme.radius.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.palette.primary1.lighter};
  }

  max-height: 504px;
  outline: 0;
  list-style: none;
  padding: 0;
`;

const StyledErrorText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledPlaceholder = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  flex: 1;
  text-align: left;
`;

const Popper = styled(BasePopper)`
  z-index: ${({ theme }) => theme.zIndex.selectPopper};
` as PolymorphicComponent<PopperTypeMap<unknown, "div">>;
