import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Form } from "@vapaus/form";
import { Flex, Modal, Notification, Select } from "@vapaus/ui-v2";

import { supportedLanguages } from "../../../i18n/src/LanguageSelectV2";
import { useChangeLanguageModal } from "./useChangeLanguageModal";

export type ChangeLanguageModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export function ChangeLanguageModal({
  isOpen,
  onClose,
}: ChangeLanguageModalProps) {
  const { t } = useTranslation();
  const { formMethods, handleSubmit } = useChangeLanguageModal(onClose);
  const rootErrorMessage = formMethods.formState.errors.root?.message;
  const languageSelectError = formMethods.formState.errors.language?.message;
  return (
    <Modal
      isOpen={isOpen}
      title={t("commonV2:changeLanguageModal.title")}
      description={t("commonV2:changeLanguageModal.description")}
      onClose={onClose}
    >
      <Form formMethods={formMethods}>
        <Flex direction="column" gap="md" marginBottom="xs">
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <Controller
            name="language"
            control={formMethods.control}
            render={({ field: { value } }) => (
              <Select
                placeholder={t("commonV2:language")}
                options={supportedLanguages.map((language) => ({
                  label: language.name,
                  value: language.id,
                }))}
                value={value}
                onChange={(_, value) => handleSubmit(value)}
                error={languageSelectError}
              />
            )}
          />
        </Flex>
      </Form>
    </Modal>
  );
}
