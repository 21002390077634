import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { Button, Flex, Input, Modal, TextButton } from "@vapaus/ui-v2";

import { usePostEIdentRequest } from "../../../api/eIdent";
import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function VerifyYourIdentityPage() {
  const { t } = useTranslation();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isSsnModalOpen, setIsSsnModalOpen] = useState<boolean>(false);
  const [isVerificationFailedModalOpen, setIsVerificationFailedModalOpen] =
    useState<boolean>(false);
  const [ssn, setSsn] = useState<string>("");
  const { goToNext } = useOnboardingMachine();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isProductionOrStaging = ["staging", "prod"].includes(
    import.meta.env.VITE_ENV!,
  );
  const { mutate } = usePostEIdentRequest();

  useEffect(() => {
    if (searchParams.get("verifyError")) {
      setSearchParams();
      setIsVerificationFailedModalOpen(true);
    }
  }, [searchParams, setSearchParams]);

  const postEIdentRequest = (ssn?: string) =>
    mutate(
      { ssn, redirectUriPath: location.pathname },
      {
        onSuccess: ({ request_url, success }) => {
          if (request_url) {
            window.location.replace(request_url);
            return;
          }

          if (success) {
            goToNext();
          }
        },
      },
    );

  const handleVerifyIdentity = () => {
    if (isProductionOrStaging) {
      postEIdentRequest();
      return;
    }

    setIsSsnModalOpen(true);
  };

  const handleCloseVerificationFailedModal = () =>
    setIsVerificationFailedModalOpen(false);

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.verifyIdentity.title")}
      subtitle={t("userV2:onboarding.verifyIdentity.subtitle")}
      subtitleWidth="md"
    >
      <Flex direction="column" gap="md" align="center">
        <Button onClick={handleVerifyIdentity}>
          {t("userV2:onboarding.verifyIdentity.verifyIdentity")}
        </Button>
        <TextButton size="md" onClick={() => setIsInfoModalOpen(true)}>
          {t("userV2:onboarding.verifyIdentity.whyDoIhaveToVerify")}
        </TextButton>
      </Flex>
      <Modal
        isOpen={isInfoModalOpen}
        size="sm"
        onClose={() => setIsInfoModalOpen(false)}
        onConfirm={() => setIsInfoModalOpen(false)}
        title={t("userV2:onboarding.verifyIdentity.infoModal.title")}
        description={t(
          "userV2:onboarding.verifyIdentity.infoModal.description",
        )}
        confirmText={t("userV2:onboarding.verifyIdentity.infoModal.buttonText")}
      />
      {!isProductionOrStaging ? (
        <Modal
          isOpen={isSsnModalOpen}
          onClose={() => setIsSsnModalOpen(false)}
          onConfirm={() => postEIdentRequest(ssn)}
          title="Provide a valid SSN"
          description="You're on local or feature environment, so instead of getting your identity verified through e-ident, provide a valid Social Security Number"
          confirmText="Submit"
        >
          <Input
            value={ssn}
            onChange={(e) => setSsn(e.target.value)}
            label="Social Security Number"
            placeholder="E.g. 191184-451B"
            tooltip="You can generate a fake Finnish SSN here: https://www.fakenamegenerator.com/gen-random-fi-fi.php. It's the one called 'Henkilötunnus'."
          />
        </Modal>
      ) : null}
      <Modal
        isOpen={isVerificationFailedModalOpen}
        onClose={handleCloseVerificationFailedModal}
        onCancel={handleCloseVerificationFailedModal}
        onConfirm={handleVerifyIdentity}
        title={t(
          "userV2:onboarding.verifyIdentity.verificationFailedModal.title",
        )}
        description={t(
          "userV2:onboarding.verifyIdentity.verificationFailedModal.description",
        )}
        confirmText={t(
          "userV2:onboarding.verifyIdentity.verificationFailedModal.tryAgain",
        )}
        cancelText={t("commonV2:cancel")}
      />
    </OnboardingPageLayout>
  );
}
