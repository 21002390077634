import { useFormContext } from "react-hook-form";

import { Notification as UINotification } from "@vapaus/ui-v2";

export function Notification() {
  const { formState } = useFormContext();
  const rootErrorMessage = formState.errors.root?.message;

  if (!rootErrorMessage) return null;

  return <UINotification message={rootErrorMessage} type="error" />;
}
