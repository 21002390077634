/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BikeBenefitContractCancellationData = {
  exceptional_end_date: string;
  cancellation_reason: BikeBenefitContractCancellationData.cancellation_reason;
  cancellation_reason_description?: string;
};

export namespace BikeBenefitContractCancellationData {
  export enum cancellation_reason {
    REDEEMED = "redeemed",
    RETURNED = "returned",
    INSURANCE_THEFT = "insurance_theft",
    INSURANCE_ACCIDENT = "insurance_accident",
    OTHER = "other",
  }
}
