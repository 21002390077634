import { createSvgIcon } from "./SvgIcon";

export const EuroIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M15 21C13.0333 21 11.2833 20.4417 9.75 19.325C8.21667 18.2083 7.13333 16.7667 6.5 15H3C2.71667 15 2.47917 14.9042 2.2875 14.7125C2.09583 14.5208 2 14.2833 2 14C2 13.7167 2.09583 13.4792 2.2875 13.2875C2.47917 13.0958 2.71667 13 3 13H6.05C6.01667 12.8167 6 12.65 6 12.5V11.5C6 11.35 6.01667 11.1833 6.05 11H3C2.71667 11 2.47917 10.9042 2.2875 10.7125C2.09583 10.5208 2 10.2833 2 10C2 9.71667 2.09583 9.47917 2.2875 9.2875C2.47917 9.09583 2.71667 9 3 9H6.5C7.13333 7.23333 8.21667 5.79167 9.75 4.675C11.2833 3.55833 13.0333 3 15 3C15.9167 3 16.7875 3.125 17.6125 3.375C18.4375 3.625 19.2083 3.98333 19.925 4.45C20.225 4.65 20.3875 4.93333 20.4125 5.3C20.4375 5.66667 20.3083 5.99167 20.025 6.275C19.825 6.475 19.5708 6.59167 19.2625 6.625C18.9542 6.65833 18.6583 6.59167 18.375 6.425C17.875 6.14167 17.3417 5.91667 16.775 5.75C16.2083 5.58333 15.6167 5.5 15 5.5C13.75 5.5 12.6125 5.82083 11.5875 6.4625C10.5625 7.10417 9.78333 7.95 9.25 9H15C15.2833 9 15.5208 9.09583 15.7125 9.2875C15.9042 9.47917 16 9.71667 16 10C16 10.2833 15.9042 10.5208 15.7125 10.7125C15.5208 10.9042 15.2833 11 15 11H8.6C8.56667 11.1833 8.54167 11.35 8.525 11.5C8.50833 11.65 8.5 11.8167 8.5 12C8.5 12.1833 8.50833 12.35 8.525 12.5C8.54167 12.65 8.56667 12.8167 8.6 13H15C15.2833 13 15.5208 13.0958 15.7125 13.2875C15.9042 13.4792 16 13.7167 16 14C16 14.2833 15.9042 14.5208 15.7125 14.7125C15.5208 14.9042 15.2833 15 15 15H9.25C9.78333 16.05 10.5625 16.8958 11.5875 17.5375C12.6125 18.1792 13.75 18.5 15 18.5C15.6 18.5 16.1917 18.4167 16.775 18.25C17.3583 18.0833 17.8917 17.8583 18.375 17.575C18.6583 17.4083 18.95 17.3375 19.25 17.3625C19.55 17.3875 19.8083 17.5083 20.025 17.725C20.3083 18.0083 20.4375 18.3333 20.4125 18.7C20.3875 19.0667 20.225 19.35 19.925 19.55C19.2083 20.0167 18.4375 20.375 17.6125 20.625C16.7875 20.875 15.9167 21 15 21Z" />
  </svg>,
  "Euro",
);
