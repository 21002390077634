/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { CalculationCorrectionData } from "./CalculationCorrectionData";
import {
  CalculationCorrectionDataFromJSON,
  CalculationCorrectionDataFromJSONTyped,
  CalculationCorrectionDataToJSON,
} from "./CalculationCorrectionData";
import type { CalculationRevisionData } from "./CalculationRevisionData";
import {
  CalculationRevisionDataFromJSON,
  CalculationRevisionDataFromJSONTyped,
  CalculationRevisionDataToJSON,
} from "./CalculationRevisionData";

/**
 *
 * @export
 * @interface BodyCalculationsGetFixedPeriodCalculation
 */
export interface BodyCalculationsGetFixedPeriodCalculation {
  /**
   *
   * @type {string}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  fixedDownPaymentAmount?: number;
  /**
   *
   * @type {CalculationCorrectionData}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  contractCorrection?: CalculationCorrectionData;
  /**
   *
   * @type {CalculationRevisionData}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  contractRevision?: CalculationRevisionData;
  /**
   * Total package price (excluding maintenance budget)
   * @type {number}
   * @memberof BodyCalculationsGetFixedPeriodCalculation
   */
  totalPackagePrice: number;
}

/**
 * Check if a given object implements the BodyCalculationsGetFixedPeriodCalculation interface.
 */
export function instanceOfBodyCalculationsGetFixedPeriodCalculation(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "totalPackagePrice" in value;

  return isInstance;
}

export function BodyCalculationsGetFixedPeriodCalculationFromJSON(
  json: any,
): BodyCalculationsGetFixedPeriodCalculation {
  return BodyCalculationsGetFixedPeriodCalculationFromJSONTyped(json, false);
}

export function BodyCalculationsGetFixedPeriodCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyCalculationsGetFixedPeriodCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    benefitDefinitionId: json["benefit_definition_id"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    leasingPeriodMonths: json["leasing_period_months"],
    fixedDownPaymentAmount: !exists(json, "fixed_down_payment_amount")
      ? undefined
      : json["fixed_down_payment_amount"],
    contractCorrection: !exists(json, "contract_correction")
      ? undefined
      : CalculationCorrectionDataFromJSON(json["contract_correction"]),
    contractRevision: !exists(json, "contract_revision")
      ? undefined
      : CalculationRevisionDataFromJSON(json["contract_revision"]),
    totalPackagePrice: json["total_package_price"],
  };
}

export function BodyCalculationsGetFixedPeriodCalculationToJSON(
  value?: BodyCalculationsGetFixedPeriodCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    benefit_definition_id: value.benefitDefinitionId,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    leasing_period_months: value.leasingPeriodMonths,
    fixed_down_payment_amount: value.fixedDownPaymentAmount,
    contract_correction: CalculationCorrectionDataToJSON(
      value.contractCorrection,
    ),
    contract_revision: CalculationRevisionDataToJSON(value.contractRevision),
    total_package_price: value.totalPackagePrice,
  };
}
