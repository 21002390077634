import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useGetOrder } from "../../../api/benefit";
import { BillingTable } from "../../../components/BillingTable";

export function TabPanelBilling() {
  const { orderId } = useParams();
  invariant(orderId);
  const { t } = useTranslation();
  const { data: order } = useGetOrder(orderId);
  const [searchParams] = useSearchParams();

  const billingDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (billingDivRef && searchParams.get("focus")) {
      billingDivRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [searchParams]);

  if (!order) return null;

  return (
    <div ref={billingDivRef}>
      <BillingTable
        title={t("userV2:myBike.billing.title")}
        bikeBenefitOrderId={order.id}
        bikeBenefitContractId={order.currentBikeBenefitContractId}
      />
    </div>
  );
}
