import { useTranslation } from "react-i18next";

import { WizardPageViewLayout } from "../../../../WizardPageViewLayout";
import { ImageAddressCard } from "./ImageAddressCard";
import bikeCenterSE from "./images/bikeCenterSE.jpg";

export function BikeCenterInfoSwedenView() {
  const { t } = useTranslation();
  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.bikeCenterInfo.title")}
      description={t("userV2:returnProcess.bikeCenterInfo.sweden.description")}
    >
      <ImageAddressCard
        image={bikeCenterSE}
        url="https://maps.app.goo.gl/BDojuZKMDYNEwhUZ9"
      />
    </WizardPageViewLayout>
  );
}
