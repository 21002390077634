import { useQuery } from "@tanstack/react-query";

import {
  MaintenanceEventsApi,
  MaintenanceEventsSearchUsersMaintenanceEventRequest,
  ResponseError,
  ShopPaginatedMaintenanceEventOut,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

const maintenanceApi = new MaintenanceEventsApi(apiConfiguration);

export const useSearchMaintenanceEvents = (
  params: MaintenanceEventsSearchUsersMaintenanceEventRequest,
  options?: UseQueryOptions<ShopPaginatedMaintenanceEventOut>,
) =>
  useQuery<ShopPaginatedMaintenanceEventOut, ResponseError>({
    ...options,
    queryKey: ["maintenanceEvents", params],
    queryFn: () =>
      maintenanceApi.maintenanceEventsSearchUsersMaintenanceEvent(params),
  });
