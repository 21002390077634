import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Form, Input, PasswordInput } from "@vapaus/form";
import {
  Button,
  Checkbox,
  Flex,
  MailIcon,
  Notification,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { AppName } from "../../constants";
import { AuthLayout } from "../AuthLayout";
import { useSignUpPage } from "./useSignUpPage";

export type SignUpPageProps = {
  app: AppName;
};

export function SignUpPage({ app }: SignUpPageProps) {
  const { t } = useTranslation();

  const { formMethods, mutation } = useSignUpPage(app);
  const rootErrorMessage = formMethods.formState.errors.root?.message;
  const areTermsAccepted = formMethods.watch("acceptTerms");

  return (
    <AuthLayout
      title={t("authV2:signUp.title")}
      description={t("authV2:signUp.description")}
    >
      <Form formMethods={formMethods}>
        <StyledRoot direction="column" gap="md">
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <Flex direction="column" gap="sm">
            <Input
              name="email"
              placeholder={t("authV2:signUp.email.placeholder")}
              startAdornment={<MailIcon />}
              label={t("authV2:signUp.email.label")}
            />
            <PasswordInput
              name="password"
              placeholder={t("authV2:signUp.password.placeholder")}
              label={t("authV2:signUp.password.label")}
            />
          </Flex>
          <Checkbox
            {...formMethods.register("acceptTerms")}
            label={
              <Typography variant="bodyLg">
                <Trans t={t} i18nKey="authV2:signUp.termsOfService">
                  <span>I have read and agree to </span>
                  <TextButton to="/terms-of-service" target="_blank">
                    Vapaus’ Terms of Service
                  </TextButton>
                  <span>and</span>
                  <TextButton
                    to={t("authV2:links.privacyPolicyUrl")}
                    target="_blank"
                  >
                    Privacy Policy
                  </TextButton>
                </Trans>
              </Typography>
            }
          />
          <Button
            variant="primary1"
            isLoading={mutation.isLoading}
            fullWidth
            disabled={!areTermsAccepted}
          >
            {t("authV2:signUp.createAccount")}
          </Button>
          <Flex direction="column" gap="xs">
            <Typography variant="bodyLg" align="center">
              <Trans t={t} i18nKey="authV2:signUp.alreadyHaveAccount">
                Already have an account?
                <TextButton to="/sign-in">Log in</TextButton>
              </Trans>
            </Typography>
          </Flex>
        </StyledRoot>
      </Form>
    </AuthLayout>
  );
}

const StyledRoot = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing.xs};

  a {
    text-decoration: none;
  }
`;
