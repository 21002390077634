import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Form, Input, PasswordInput } from "@vapaus/form";
import {
  Button,
  Checkbox,
  MailIcon,
  Notification,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { AppName } from "../../constants";
import { AuthLayout } from "../AuthLayout";
import { useCreatePasswordPage } from "./useCreatePasswordPage";

type CreatePasswordPageProps = {
  app: AppName;
};

export function CreatePasswordPage({ app }: CreatePasswordPageProps) {
  const { t } = useTranslation();
  const { formMethods, mutation } = useCreatePasswordPage(app);
  const rootErrorMessage = formMethods.formState.errors.root?.message;

  return (
    <AuthLayout
      title={t("authV2:createPassword.title")}
      description={t("authV2:createPassword.description")}
    >
      <Form formMethods={formMethods}>
        <StyledRoot>
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <Input
            name="email"
            label={t("authV2:createPassword.workEmail")}
            startAdornment={<MailIcon />}
            disabled
          />
          <PasswordInput
            name="newPassword"
            label={t("authV2:createPassword.createPassword")}
            placeholder={t("authV2:createPassword.passwordPlaceholder")}
          />
          {app === "user" && (
            <Checkbox
              {...formMethods.register("acceptTerms")}
              label={
                <Typography variant="bodyLg">
                  <Trans t={t} i18nKey="authV2:createPassword.termsOfService">
                    <span>I have read and agree to </span>
                    <TextButton to="/terms-of-service" target="_blank">
                      Vapaus’ Terms of Service
                    </TextButton>
                    <span>and</span>
                    <TextButton
                      to={t("authV2:links.privacyPolicyUrl")}
                      target="_blank"
                    >
                      Privacy Policy
                    </TextButton>
                  </Trans>
                </Typography>
              }
            />
          )}
          <Button
            variant="primary1"
            marginBottom="sm"
            marginTop="sm"
            disabled={!formMethods.formState.isValid}
            isLoading={mutation.isLoading}
            fullWidth
          >
            {t("authV2:createPassword.createVapausAccount")}
          </Button>
          <Typography variant="bodyLg" align="center">
            <Trans t={t} i18nKey="authV2:createPassword.alreadyHaveAccount">
              <span>Already have an account?</span>
              <TextButton to="/sign-in">Log in</TextButton>
            </Trans>
          </Typography>
        </StyledRoot>
      </Form>
    </AuthLayout>
  );
}

const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;
