import { Outlet } from "react-router-dom";

import { AppName } from "../../constants";
import { AuthContext } from "../../context";
import { useAuth } from "./useAuth";

export type AuthProps = {
  readonly app: AppName;
};

export function Auth({ app }: AuthProps) {
  const authContext = useAuth(app);

  if (authContext.isLoading) return null;

  return (
    <AuthContext.Provider value={authContext}>
      <Outlet />
    </AuthContext.Provider>
  );
}
