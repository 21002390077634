import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PaymentServiceProvider } from "@vapaus/api-codegen";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  AnimatedErrorIcon,
  Button,
  Flex,
  Modal,
  Typography,
  useToaster,
} from "@vapaus/ui-v2";
import { CountryCurrencies } from "@vapaus/utils";

import vismaPayLogo from "../../../../../assets/vismaPay.png";
import { useCreatePaymentConsent } from "../../../../api/paymentConsent";
import { Path } from "../../../../constants/paths";
import { useVismaPayCardCallback } from "./useVismaPayCardCallback";

export function AddCardButton() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const createPaymentConsent = useCreatePaymentConsent();
  const { data: user } = useCurrentUser();

  const toaster = useToaster();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    clearError();
  };
  const handleOpenModal = () => setIsModalOpen(true);

  const { errorMessage, clearError } = useVismaPayCardCallback();

  const handleCancelModal = () => {
    createPaymentConsent.reset();
    handleCloseModal();
  };

  const handleAddPaymentCard = () => {
    clearError();
    if (!user?.country) {
      toaster.add("error", t("userV2:paymentCard.pleaseCompleteOnboarding"));
      navigate("/" + Path.onboarding);
      return;
    }

    createPaymentConsent.mutate(
      {
        paymentServiceProvider: PaymentServiceProvider.VismaPay,
        currency: CountryCurrencies[user.country],
        returnUrl: window.location.origin + window.location.pathname,
      },
      {
        onSuccess: ({ link }) => {
          queryClient.invalidateQueries(["paymentConsents"]);
          if (!link) return;
          window.location.replace(link);
        },
      },
    );
  };

  const isError = createPaymentConsent.isError || !!errorMessage;

  return (
    <>
      <Button onClick={handleOpenModal}>
        {t("userV2:paymentCard.addCard")}
      </Button>
      <Modal
        isOpen={isModalOpen || !!errorMessage}
        onClose={isError ? undefined : handleCloseModal}
        onCancel={handleCancelModal}
        onConfirm={handleAddPaymentCard}
        cancelText={t("commonV2:cancel")}
        confirmText={
          isError ? t("userV2:paymentCard.tryAgain") : t("commonV2:continue")
        }
        isSubmitting={createPaymentConsent.isLoading}
      >
        {isError ? (
          <Flex direction="column" align="center" gap="none">
            <AnimatedErrorIcon />
            <Typography variant="h2" align="center" marginBottom="md">
              {t("userV2:paymentCard.addPaymentCardFailedTitle")}
            </Typography>
            <Typography variant="bodyLg" align="center">
              {t("userV2:paymentCard.addPaymentCardFailed")}
            </Typography>
          </Flex>
        ) : (
          <Flex direction="column" align="center" gap="md">
            <img src={vismaPayLogo} alt="Visma Pay Logo" />
            <Typography variant="bodyLg" align="center">
              {t("userV2:paymentCard.addCardNotice")}
            </Typography>
          </Flex>
        )}
      </Modal>
    </>
  );
}
