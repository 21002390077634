import { useQuery } from "@tanstack/react-query";

import {
  PaginatedUserShopOut,
  ResponseError,
  ShopLocation,
  UserShopApi,
  UserShopRead,
  UserShopSearchShopsRequest,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const shopApi = new UserShopApi(apiConfiguration);

export const useGetShop = (shopId?: string) =>
  useQuery<UserShopRead, ResponseError>({
    queryKey: ["shop", shopId],
    queryFn: () => shopApi.userShopGetShop({ shopId: shopId as string }),
    enabled: Boolean(shopId),
  });

export const useSearchShops = (
  params: Omit<UserShopSearchShopsRequest, "brandIds"> & {
    brandIds?: string[];
  },
) =>
  useQuery<PaginatedUserShopOut, ResponseError>({
    queryKey: ["shop", params],
    queryFn: () =>
      shopApi.userShopSearchShops({
        ...params,
        brandIds: params?.brandIds ? new Set(params.brandIds) : undefined,
      }),
    keepPreviousData: true,
  });

export const useSearchLocation = () =>
  useQuery<ShopLocation[], ResponseError>({
    queryKey: ["shopLocations"],
    queryFn: () => shopApi.userShopSearchShopLocations(),
    keepPreviousData: true,
  });
