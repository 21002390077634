import { ReactElement, ReactNode, cloneElement, useRef, useState } from "react";

import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";

import { ExpandMoreIcon } from "../Icons";
import type { PaperProps } from "../Paper";
import { Paper } from "../Paper";
import { Typography } from "../Typography";
import { isSafari } from "../styles";

const Head = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  align-items: center;
`;

const Container = styled.div<{
  hideOverflow?: boolean;
}>`
  overflow: ${({ hideOverflow }) => (hideOverflow ? "hidden" : "visible")};
`;

const InnerContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.md};
`;

export interface CardCollapsible extends PaperProps {
  title: string;
  icon?: ReactElement;
  children: ReactNode;
}

export function CardCollapsible({
  title,
  icon,
  radius,
  padding,
  children,
  shadow = "md",
  background = "background.white",
}: CardCollapsible) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAnimationEnded, setIsOpenAnimationEnded] =
    useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const springProps = useSpring({
    height: isOpen ? `${contentRef.current?.scrollHeight ?? 0}px` : "0px",
    opacity: isOpen ? 1 : 0,
    onStart: () => setIsOpenAnimationEnded(false),
    onRest(result) {
      if (result.value.height !== "0px") {
        // This is to allow us to have nested collapsible elements
        setIsOpenAnimationEnded(true);
      }
    },
    immediate: isSafari(),
  });
  const iconSpringProps = useSpring({
    rotate: isOpen ? 180 : 0,
  });

  return (
    <Paper
      background={background}
      radius={radius}
      padding={padding}
      shadow={shadow}
    >
      <Head onClick={() => setIsOpen(!isOpen)}>
        {icon &&
          cloneElement(icon, {
            size: "lg",
            colorOn: background,
            hideDown: "sm",
          })}
        <Typography variant="h2" colorOn={background} fullWidth>
          {title}
        </Typography>
        <animated.div style={iconSpringProps}>
          <ExpandMoreIcon size="lg" colorOn={background} />
        </animated.div>
      </Head>
      <Container hideOverflow={!isOpenAnimationEnded}>
        <animated.div
          style={{
            opacity: springProps.opacity,
            height: isOpenAnimationEnded ? "auto" : springProps.height,
          }}
        >
          <InnerContainer ref={contentRef}>{children}</InnerContainer>
        </animated.div>
      </Container>
    </Paper>
  );
}
