import React, { useContext } from "react";

type AuthContextType = {
  token: string | null;
  setToken: (token: string | null) => void;
  isLoggedIn: boolean;
};

export const AuthContext = React.createContext<AuthContextType>({
  token: null,
  setToken: () => null,
  isLoggedIn: false,
});

export const useAuthContext = () => useContext(AuthContext);
