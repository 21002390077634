import { useQuery } from "@tanstack/react-query";
import { SetOptional } from "type-fest";

import {
  BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation,
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation,
  CalculationsApi,
  ResponseError,
  SupportedCountriesEnum,
  WithTaxDetailsBenefitCalculation,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const calculationsAPI = new CalculationsApi(apiConfiguration);

type Params = SetOptional<
  BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation,
  "monthlyPayment" | "monthlyMaintenanceBudget"
>;

type PublicParams = SetOptional<
  BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation,
  "monthlyPayment" | "monthlyMaintenanceBudget" | "country"
>;

export type BenefitCalculationWithExtra = WithTaxDetailsBenefitCalculation & {
  costAfterSavings: number;
  savings: number;
  totalPackagePrice: number;
  monthlySalary: number;
};

export const useGetCalculations = (params: Params) => {
  const _params = {
    monthlyPayment: 100,
    monthlyMaintenanceBudget: 0,
    ...params,
  };
  return useQuery<
    WithTaxDetailsBenefitCalculation,
    ResponseError,
    BenefitCalculationWithExtra
  >({
    queryKey: ["calculations", _params],
    queryFn: () =>
      calculationsAPI.calculationsGetFixedMonthlyPaymentWithTaxCalculation({
        bodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation: _params,
      }),
    keepPreviousData: true,
    enabled: Boolean(params.benefitDefinitionId),
    select(data: WithTaxDetailsBenefitCalculation) {
      return {
        ...data,
        ...params,
        costAfterSavings:
          data.totalValueAmount - data.taxAmountReduction + data.residualValue,
        savings:
          data.taxAmountReduction -
          (data.totalValueAmount - params.totalPackagePrice) -
          data.residualValue,
      };
    },
  });
};

export const useGetPublicCalculations = (params: PublicParams) => {
  const _params = {
    monthlyPayment: 100,
    monthlyMaintenanceBudget: 0,
    country: SupportedCountriesEnum.Fi,
    ...params,
  };
  return useQuery<
    WithTaxDetailsBenefitCalculation,
    ResponseError,
    BenefitCalculationWithExtra
  >({
    queryKey: ["calculations", _params],
    queryFn: () =>
      calculationsAPI.calculationsGetPublicFixedMonthlyPaymentWithTaxCalculation(
        {
          bodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation:
            _params,
        },
      ),
    keepPreviousData: true,
    select(data: WithTaxDetailsBenefitCalculation) {
      return {
        ...data,
        ...params,
        costAfterSavings:
          data.totalValueAmount - data.taxAmountReduction + data.residualValue,
        savings:
          data.taxAmountReduction -
          (data.totalValueAmount - params.totalPackagePrice) -
          data.residualValue,
      };
    },
  });
};
