/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserSummaryRead
 */
export interface UserSummaryRead {
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryRead
   */
  fullName?: string;
}

/**
 * Check if a given object implements the UserSummaryRead interface.
 */
export function instanceOfUserSummaryRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "email" in value;

  return isInstance;
}

export function UserSummaryReadFromJSON(json: any): UserSummaryRead {
  return UserSummaryReadFromJSONTyped(json, false);
}

export function UserSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSummaryRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: !exists(json, "full_name") ? undefined : json["full_name"],
  };
}

export function UserSummaryReadToJSON(value?: UserSummaryRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    full_name: value.fullName,
  };
}
