import { useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import invariant from "tiny-invariant";

import { PageSpinner } from "@vapaus/ui-v2";

import { useGetContract, useGetOrder } from "../../api/benefit";
import { useGetRedemptionProcess } from "../../api/redemptionProcess";
import { useGetReturnProcess } from "../../api/returnProcess";
import { MyBikeHero } from "./components/MyBikeHero";
import { PaymentErrorModal } from "./components/RedemptionProcessWizard";

export function MyBikePage() {
  const { orderId } = useParams();
  invariant(orderId);

  const [paymentErrorModalOpenFor, setPaymentErrorModalOpenFor] = useState<
    "redemption" | "return" | undefined
  >(undefined);
  const { data: order, isLoading: isLoadingOrder } = useGetOrder(orderId);
  const { data: contract, isLoading: isLoadingContract } = useGetContract(
    order?.currentBikeBenefitContractId,
  );
  const { data: redemptionProcess } = useGetRedemptionProcess(
    contract?.currentRedemptionProcessId,
  );

  const { data: returnProcess } = useGetReturnProcess(
    contract?.currentReturnProcessId,
  );

  const handleOpenPaymentErrorModalFor = (
    cancellationType: "return" | "redemption",
  ) => setPaymentErrorModalOpenFor(cancellationType);
  const handleClosePaymentErrorModal = () =>
    setPaymentErrorModalOpenFor(undefined);

  const isLoading = Boolean(
    isLoadingOrder ||
      (order?.currentBikeBenefitContractId && isLoadingContract),
  );

  if (!order || isLoading) return <PageSpinner />;

  return (
    <div>
      <MyBikeHero order={order} contract={contract} />
      <StyledRoot>
        <StyledContainer>
          <Outlet context={{ contract, handleOpenPaymentErrorModalFor }} />
        </StyledContainer>
      </StyledRoot>
      {contract ? (
        <PaymentErrorModal
          saleInvoiceId={
            paymentErrorModalOpenFor === "redemption"
              ? redemptionProcess?.saleInvoiceId
              : returnProcess?.saleInvoiceId
          }
          isOpen={!!paymentErrorModalOpenFor}
          onClose={handleClosePaymentErrorModal}
        />
      ) : null}
    </div>
  );
}

const StyledRoot = styled.div`
  margin: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xl};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: ${({ theme }) => theme.spacing.md};
    `)}
`;

const StyledContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
