import { ResetEmailSentPage } from "./ResetEmailSentPage";
import { SendResetEmailPage } from "./SendResetEmailPage";
import { useForgotPasswordPage } from "./useForgotPasswordPage";

export function ForgotPasswordPage() {
  const value = useForgotPasswordPage();
  return value.resetEmailSent ? (
    <ResetEmailSentPage {...value} />
  ) : (
    <SendResetEmailPage {...value} />
  );
}
