import { useCurrencyFormat } from "packages/i18n/src/hooks";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { BikeBenefitContractRead } from "@vapaus/api-codegen";
import { Currency } from "@vapaus/generated";
import { Card, Flex, Typography } from "@vapaus/ui-v2";

interface MaintenanceBudgetCardProps {
  contract?: BikeBenefitContractRead;
}

export function MaintenanceBudgetCard({
  contract,
}: MaintenanceBudgetCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(contract?.currency || Currency.EUR);

  return (
    <Card title={t("userV2:myBike.maintenanceBudgetCard.title")}>
      {!contract ? null : contract.monthlyMaintenanceBudget === 0 ? (
        <Typography>
          {t("userV2:myBike.maintenanceBudgetCard.noBudget")}
        </Typography>
      ) : (
        <Flex direction="column" gap="md">
          <Typography>
            {t("userV2:myBike.maintenanceBudgetCard.description")}
          </Typography>
          <Flex xsDirection="column" gap="none" align="stretch">
            <StyledSplitContainer>
              <StyledCell>
                <Flex direction="column" gap="xs">
                  <Typography align="center">
                    {t("userV2:myBike.maintenanceBudgetCard.total")}
                  </Typography>
                  <Typography align="center" variant="h4">
                    {formatCurrency(contract?.totalMaintenanceBudget)}
                  </Typography>
                </Flex>
              </StyledCell>
              <StyledCell>
                <Flex direction="column" gap="xs">
                  <Typography align="center">
                    {t("userV2:myBike.maintenanceBudgetCard.available")}
                  </Typography>
                  <Typography align="center" variant="h4">
                    {contract
                      ? formatCurrency(
                          contract.monthlyMaintenanceBudget *
                            (contract.leasingPeriodMonths -
                              contract.remainingLeasingPeriodMonths),
                        )
                      : ""}
                  </Typography>
                </Flex>
              </StyledCell>
            </StyledSplitContainer>
            <StyledSplitContainer>
              <StyledCell>
                <Flex direction="column" gap="xs">
                  <Typography align="center">
                    {t("userV2:myBike.maintenanceBudgetCard.used")}
                  </Typography>
                  <Typography align="center" variant="h4">
                    {formatCurrency(contract?.spentMaintenanceBudget)}
                  </Typography>
                </Flex>
              </StyledCell>
              <StyledCell>
                <Flex direction="column" gap="xs">
                  <Typography align="center">
                    {t("userV2:myBike.maintenanceBudgetCard.remaining")}
                  </Typography>
                  <Typography align="center" variant="h4">
                    {contract
                      ? formatCurrency(contract.remainingMaintenanceBudget)
                      : ""}
                  </Typography>
                </Flex>
              </StyledCell>
            </StyledSplitContainer>
          </Flex>
          <Typography variant="bodySm">
            {t("userV2:myBike.maintenanceBudgetCard.hint")}
          </Typography>
        </Flex>
      )}
    </Card>
  );
}

const StyledSplitContainer = styled.div`
  width: 50%;
  display: flex;
  border-top: ${({ theme }) => theme.border.primary.light};
  &:not(:last-child) {
    border-right: ${({ theme }) => theme.border.primary.light};
  }

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
      border-right: none !important;
      &:last-child {
        border-top: none;
      }
    `)}
`;

const StyledCell = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing.sm};
  &:not(:last-child) {
    border-right: ${({ theme }) => theme.border.primary.light};
  }
`;
