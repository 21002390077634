import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Checkbox } from "@vapaus/ui-v2";

import { AppName } from "../../constants";
import { useTermsConsentPanel } from "./useTermsConsentPanel";

type TermsConsentPanelProps = {
  app: AppName;
};

export function TermsConsentPanel({ app }: TermsConsentPanelProps) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { handleAcceptTerms, handleLogout, areTermsUpdated } =
    useTermsConsentPanel(app);
  const updatedTermsLabel = t("authV2:termsOfService.checkBoxText");
  const notUpdatedTermsLabel = t(
    "authV2:termsOfService.checkBoxTextNotUpdated",
  );

  return (
    <StyledRoot>
      <Checkbox
        label={areTermsUpdated ? updatedTermsLabel : notUpdatedTermsLabel}
        onChange={(e) => setIsChecked(e.target.checked)}
      />
      <StyledGroupButtons>
        <Button disabled={!isChecked} onClick={handleAcceptTerms}>
          {t("authV2:termsOfService.acceptAndContinue")}
        </Button>
        <Button variant="outlined" onClick={handleLogout}>
          {t("authV2:signIn.logOut")}
        </Button>
      </StyledGroupButtons>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
  padding-top: ${({ theme }) => theme.spacing.xl};
  border-top: ${({ theme }) => theme.border.primary.light};
`;

const StyledGroupButtons = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xl};
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 100%;
      flex-direction: column;
      & > * {
        flex: 1;
      }
    `)}
`;
