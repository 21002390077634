import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { ApiError } from "@vapaus/generated";
import {
  Notification,
  Support3DIcon,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";
import { parseApiError } from "@vapaus/utils";

import lockImg from "../../../assets/Lock.png";
import { AuthLayout } from "../AuthLayout";
import { ForgotPasswordDataType } from "./useForgotPasswordPage";

export function ResetEmailSentPage({
  form: { mutation, formMethods },
}: Omit<ForgotPasswordDataType, "resetEmailSent">) {
  const { t } = useTranslation();
  const isLoading = mutation.isLoading;
  const emailAddress = formMethods.watch("email");
  const handleSendAgain = () => formMethods._handleSubmit();

  const status = mutation.status === "success" ? "success" : "error";
  const statusMessage =
    mutation.status === "success"
      ? t("authV2:forgotPassword.emailSent")
      : mutation.status === "error"
      ? parseApiError(mutation.error as unknown as ApiError, t)
      : undefined;

  return (
    <AuthLayout
      title={t("authV2:forgotPassword.checkYourEmail")}
      description={t("authV2:forgotPassword.emailSentMessage", {
        emailAddress,
      })}
      icon={<Support3DIcon size="md" />}
      bannerImage={lockImg}
    >
      <Root>
        <Typography variant="bodyLg" align="center" marginBottom="sm">
          <Trans t={t} i18nKey={"authV2:forgotPassword.didNotReceiveEmail"}>
            <span>Did not receive the email?</span>
            <TextButton onClick={handleSendAgain} disabled={isLoading}>
              Send again
            </TextButton>
          </Trans>
        </Typography>
        {statusMessage && (
          <Notification message={statusMessage} type={status} />
        )}
        <StyledFooterWrapper>
          <TextButton to="/sign-in" align="center">
            {t("authV2:forgotPassword.backToLogin")}
          </TextButton>
        </StyledFooterWrapper>
      </Root>
    </AuthLayout>
  );
}

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledFooterWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
  border-top: ${({ theme }) => theme.border.primary.light};
  display: flex;
  align-items: center;
  flex-direction: column;
`;
