/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface WithTaxDetailsBenefitCalculation
 */
export interface WithTaxDetailsBenefitCalculation {
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalContractAmount: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalValueAmount: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  residualValue: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageBefore: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageAfter: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxPercentageReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountBefore: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountAfter: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  taxAmountReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalFinancingCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalInsuranceCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  totalServiceCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  monthlyTaxReduction: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  actualMonthlyCost: number;
  /**
   *
   * @type {number}
   * @memberof WithTaxDetailsBenefitCalculation
   */
  vatRate: number;
}

/**
 * Check if a given object implements the WithTaxDetailsBenefitCalculation interface.
 */
export function instanceOfWithTaxDetailsBenefitCalculation(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "totalContractAmount" in value;
  isInstance = isInstance && "totalValueAmount" in value;
  isInstance = isInstance && "totalMaintenanceBudget" in value;
  isInstance = isInstance && "redemptionPercentage" in value;
  isInstance = isInstance && "residualValue" in value;
  isInstance = isInstance && "taxPercentageBefore" in value;
  isInstance = isInstance && "taxPercentageAfter" in value;
  isInstance = isInstance && "taxPercentageReduction" in value;
  isInstance = isInstance && "taxAmountBefore" in value;
  isInstance = isInstance && "taxAmountAfter" in value;
  isInstance = isInstance && "taxAmountReduction" in value;
  isInstance = isInstance && "totalFinancingCost" in value;
  isInstance = isInstance && "totalInsuranceCost" in value;
  isInstance = isInstance && "totalServiceCost" in value;
  isInstance = isInstance && "monthlyTaxReduction" in value;
  isInstance = isInstance && "actualMonthlyCost" in value;
  isInstance = isInstance && "vatRate" in value;

  return isInstance;
}

export function WithTaxDetailsBenefitCalculationFromJSON(
  json: any,
): WithTaxDetailsBenefitCalculation {
  return WithTaxDetailsBenefitCalculationFromJSONTyped(json, false);
}

export function WithTaxDetailsBenefitCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WithTaxDetailsBenefitCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    leasingPeriodMonths: json["leasing_period_months"],
    totalContractAmount: json["total_contract_amount"],
    totalValueAmount: json["total_value_amount"],
    totalMaintenanceBudget: json["total_maintenance_budget"],
    redemptionPercentage: json["redemption_percentage"],
    residualValue: json["residual_value"],
    taxPercentageBefore: json["tax_percentage_before"],
    taxPercentageAfter: json["tax_percentage_after"],
    taxPercentageReduction: json["tax_percentage_reduction"],
    taxAmountBefore: json["tax_amount_before"],
    taxAmountAfter: json["tax_amount_after"],
    taxAmountReduction: json["tax_amount_reduction"],
    totalFinancingCost: json["total_financing_cost"],
    totalInsuranceCost: json["total_insurance_cost"],
    totalServiceCost: json["total_service_cost"],
    monthlyTaxReduction: json["monthly_tax_reduction"],
    actualMonthlyCost: json["actual_monthly_cost"],
    vatRate: json["vat_rate"],
  };
}

export function WithTaxDetailsBenefitCalculationToJSON(
  value?: WithTaxDetailsBenefitCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    leasing_period_months: value.leasingPeriodMonths,
    total_contract_amount: value.totalContractAmount,
    total_value_amount: value.totalValueAmount,
    total_maintenance_budget: value.totalMaintenanceBudget,
    redemption_percentage: value.redemptionPercentage,
    residual_value: value.residualValue,
    tax_percentage_before: value.taxPercentageBefore,
    tax_percentage_after: value.taxPercentageAfter,
    tax_percentage_reduction: value.taxPercentageReduction,
    tax_amount_before: value.taxAmountBefore,
    tax_amount_after: value.taxAmountAfter,
    tax_amount_reduction: value.taxAmountReduction,
    total_financing_cost: value.totalFinancingCost,
    total_insurance_cost: value.totalInsuranceCost,
    total_service_cost: value.totalServiceCost,
    monthly_tax_reduction: value.monthlyTaxReduction,
    actual_monthly_cost: value.actualMonthlyCost,
    vat_rate: value.vatRate,
  };
}
