import { MouseEventHandler, ReactNode, forwardRef } from "react";

import { Modal as BaseModal } from "@mui/base/Modal";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";

import { useForwardedRef } from "@vapaus/utils";

import { ModalNavBar, type ModalNavBarProps } from "../ModalNavBar";
import { PaletteVariants } from "../styles";

export type FullPageModalProps = Omit<ModalNavBarProps, "bgColor"> & {
  children: ReactNode;
  topBarBgColor?: PaletteVariants;
  isOpen: boolean;
  hideNavigation?: boolean;
};

export function FullPageModal({
  children,
  topBarBgColor = "neutral.main",
  isOpen,
  hasBorder = false,
  title,
  onBack,
  onClose,
  hideNavigation = false,
}: FullPageModalProps) {
  return (
    <StyledRoot
      data-testid="full-page-modal-root"
      open={isOpen}
      onClose={onClose}
    >
      <Slide in={isOpen}>
        {hideNavigation ? null : (
          <ModalNavBar
            bgColor={topBarBgColor}
            hasBorder={hasBorder}
            title={title}
            onBack={onBack}
            onClose={onClose}
          />
        )}

        <StyledModalBox hideNavigation={hideNavigation}>
          {children}
        </StyledModalBox>
      </Slide>
    </StyledRoot>
  );
}

const StyledRoot = styled(BaseModal)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

interface SlideProps {
  children: ReactNode;
  in?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
}

const Slide = forwardRef<HTMLDivElement, SlideProps>(
  function Slide(props, ref) {
    const { in: isOpen, children, onEnter, onExited, ...other } = props;
    const innerRef = useForwardedRef<HTMLDivElement>(ref);
    const style = useSpring({
      from: { transform: "translateY(100%)" },
      to: { transform: isOpen ? "translateY(0%)" : "translateY(100%)" },
      onStart: () => {
        if (isOpen && onEnter) {
          onEnter(innerRef.current as HTMLDivElement, true);
        }
      },
      onRest: () => {
        if (!isOpen && onExited) {
          onExited(innerRef.current as HTMLDivElement, true);
        }
      },
    });

    return (
      <animated.div
        ref={ref}
        style={{
          ...style,
          width: "100vw",
          height: "100vh",
          position: "fixed",
        }}
        {...other}
      >
        {children}
      </animated.div>
    );
  },
);

const StyledModalBox = styled.div<{
  hideNavigation?: boolean;
}>`
  width: 100%;
  height: ${({ theme, hideNavigation }) =>
    `calc(100vh - ${hideNavigation ? "0px" : theme.height.navBar})`};
  background-color: ${({ theme }) => theme.palette.neutral.main};
  position: relative;
  overflow-y: auto;
`;
