import { ComponentType, ReactNode } from "react";

import styled, { css } from "styled-components";

import { CircularIcon } from "../CircularIcon";
import { Flex } from "../Flex";
import { SvgIconProps } from "../Icons";
import { ChevronRightIcon } from "../Icons/ChevronRightIcon";
import { Typography } from "../Typography";
import { makeTransition, useBreakpoints } from "../styles";

export type ActionCardProps = {
  icon: ComponentType<SvgIconProps>;
  firstLine: string;
  secondLine: string;
  thirdLine?: string;
  disabled?: boolean;
  onClick?: () => void;
  afterContent?: ReactNode;
};

export function ActionCard({
  icon,
  firstLine,
  secondLine,
  thirdLine,
  disabled,
  onClick,
  afterContent,
}: ActionCardProps) {
  const { isXs } = useBreakpoints();

  return (
    <StyledRoot
      gap="md"
      justify="space-between"
      disabled={disabled}
      onClick={onClick}
    >
      <StyledFlexContainer gap="md">
        {!isXs ? <CircularIcon icon={icon} /> : null}
        <StyledFlexContainer direction="column" gap="xxs">
          <Typography weight="bold">{firstLine}</Typography>
          <Typography>{secondLine}</Typography>
          {thirdLine ? (
            <Typography weight="bold">{thirdLine}</Typography>
          ) : null}
        </StyledFlexContainer>
      </StyledFlexContainer>
      <Flex gap="md">
        {afterContent}
        <ChevronRightIcon />
      </Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<Pick<ActionCardProps, "disabled">>`
  padding: calc(${({ theme }) => theme.spacing.md} - 2px)
    ${({ theme }) => theme.spacing.md};
  border: ${({ theme }) => theme.border.secondary.main};
  border-radius: ${({ theme }) => theme.radius.actionCard};
  background-color: ${({ theme }) => theme.palette.background.white};
  cursor: pointer;
  width: 100%;
  ${makeTransition("all", "shortest", "easeOut")}
  :hover {
    background-color: ${({ theme }) => theme.palette.primary2.light};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${({ theme }) => theme.opacity.disabled};
      pointer-events: none;
    `}
`;

const StyledFlexContainer = styled(Flex)`
  flex: 1 1 0;
  min-width: 0;
`;
