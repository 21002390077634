/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionCancellationCoverage } from "./BenefitDefinitionCancellationCoverage";
import {
  BenefitDefinitionCancellationCoverageFromJSON,
  BenefitDefinitionCancellationCoverageFromJSONTyped,
  BenefitDefinitionCancellationCoverageToJSON,
} from "./BenefitDefinitionCancellationCoverage";
import type { FinancingMethods } from "./FinancingMethods";
import {
  FinancingMethodsFromJSON,
  FinancingMethodsFromJSONTyped,
  FinancingMethodsToJSON,
} from "./FinancingMethods";
import type { Payer } from "./Payer";
import { PayerFromJSON, PayerFromJSONTyped, PayerToJSON } from "./Payer";
import type { Plan } from "./Plan";
import { PlanFromJSON, PlanFromJSONTyped, PlanToJSON } from "./Plan";
import type { ReturnFee } from "./ReturnFee";
import {
  ReturnFeeFromJSON,
  ReturnFeeFromJSONTyped,
  ReturnFeeToJSON,
} from "./ReturnFee";

/**
 *
 * @export
 * @interface BenefitDefinitionCreate
 */
export interface BenefitDefinitionCreate {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  organisationId: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  organisationBillingDetailsId: string;
  /**
   *
   * @type {Plan}
   * @memberof BenefitDefinitionCreate
   */
  plan: Plan;
  /**
   *
   * @type {Payer}
   * @memberof BenefitDefinitionCreate
   */
  excessMonthlyPayer?: Payer;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  endDate: Date;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  orderProcessingGracePeriodDays?: number;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  toWhom?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  what?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  how?: string;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumTaxablePackagePrice?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumMonthlyTaxableValue: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BenefitDefinitionCreate
   */
  fixedMonthlyTaxableValues?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  defaultResidualRatio: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  defaultMonthlyDecreaseInResidualValue: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isEndOfLeaseRedemptionPriceDiscounted?: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  fixedEndOfLeaseRedemptionPercentage?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumMaintenanceServiceMonthly: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumMaintenanceServiceMonthly: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumTotalPackagePrice: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isDownpaymentAllowed: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  standardCoverageInsuranceRate: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  fullCoverageInsuranceRate: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  insuranceDeductible: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  companyMonthlyCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  individualMonthlyCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  individualOnetimeCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  returnFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  redemptionFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  deliveryFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  customerServiceMonthlyFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  legacyWarrantyMonthlyFee: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isEmployeeNumberMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isCompanyMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isCostCenterMandatory: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  billingFrequency?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  paymentTerms?: number;
  /**
   * Netvisor code 2
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  serviceFeeProductCode?: string;
  /**
   * Netvisor code 1
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  bikeContractProductCode?: string;
  /**
   *
   * @type {FinancingMethods}
   * @memberof BenefitDefinitionCreate
   */
  financingMethod?: FinancingMethods;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  financingApplicationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionNumber?: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionExpirationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionMaxLeasingPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionTotalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionUsedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionRemainingAmount?: number;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  accessRulesUuid?: string;
  /**
   *
   * @type {BenefitDefinitionCancellationCoverage}
   * @memberof BenefitDefinitionCreate
   */
  cancellationCoverage: BenefitDefinitionCancellationCoverage;
  /**
   *
   * @type {ReturnFee}
   * @memberof BenefitDefinitionCreate
   */
  returnFeeCoverage: ReturnFee;
}

/**
 * Check if a given object implements the BenefitDefinitionCreate interface.
 */
export function instanceOfBenefitDefinitionCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "organisationId" in value;
  isInstance = isInstance && "organisationBillingDetailsId" in value;
  isInstance = isInstance && "plan" in value;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "minimumLeasingPeriod" in value;
  isInstance = isInstance && "maximumLeasingPeriod" in value;
  isInstance = isInstance && "minimumMonthlyTaxableValue" in value;
  isInstance = isInstance && "maximumMonthlyTaxableValue" in value;
  isInstance = isInstance && "defaultResidualRatio" in value;
  isInstance = isInstance && "defaultMonthlyDecreaseInResidualValue" in value;
  isInstance = isInstance && "minimumMaintenanceServiceMonthly" in value;
  isInstance = isInstance && "maximumMaintenanceServiceMonthly" in value;
  isInstance = isInstance && "maximumTotalPackagePrice" in value;
  isInstance = isInstance && "isDownpaymentAllowed" in value;
  isInstance = isInstance && "standardCoverageInsuranceRate" in value;
  isInstance = isInstance && "fullCoverageInsuranceRate" in value;
  isInstance = isInstance && "insuranceDeductible" in value;
  isInstance = isInstance && "companyMonthlyCancellationCoverage" in value;
  isInstance = isInstance && "individualMonthlyCancellationCoverage" in value;
  isInstance = isInstance && "individualOnetimeCancellationCoverage" in value;
  isInstance = isInstance && "returnFee" in value;
  isInstance = isInstance && "redemptionFee" in value;
  isInstance = isInstance && "deliveryFee" in value;
  isInstance = isInstance && "customerServiceMonthlyFee" in value;
  isInstance = isInstance && "legacyWarrantyMonthlyFee" in value;
  isInstance = isInstance && "isEmployeeNumberMandatory" in value;
  isInstance = isInstance && "isCompanyMandatory" in value;
  isInstance = isInstance && "isCostCenterMandatory" in value;
  isInstance = isInstance && "cancellationCoverage" in value;
  isInstance = isInstance && "returnFeeCoverage" in value;

  return isInstance;
}

export function BenefitDefinitionCreateFromJSON(
  json: any,
): BenefitDefinitionCreate {
  return BenefitDefinitionCreateFromJSONTyped(json, false);
}

export function BenefitDefinitionCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    organisationId: json["organisation_id"],
    organisationBillingDetailsId: json["organisation_billing_details_id"],
    plan: PlanFromJSON(json["plan"]),
    excessMonthlyPayer: !exists(json, "excess_monthly_payer")
      ? undefined
      : PayerFromJSON(json["excess_monthly_payer"]),
    description: !exists(json, "description") ? undefined : json["description"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    orderProcessingGracePeriodDays: !exists(
      json,
      "order_processing_grace_period_days",
    )
      ? undefined
      : json["order_processing_grace_period_days"],
    toWhom: !exists(json, "to_whom") ? undefined : json["to_whom"],
    what: !exists(json, "what") ? undefined : json["what"],
    how: !exists(json, "how") ? undefined : json["how"],
    minimumLeasingPeriod: json["minimum_leasing_period"],
    maximumLeasingPeriod: json["maximum_leasing_period"],
    maximumTaxablePackagePrice: !exists(json, "maximum_taxable_package_price")
      ? undefined
      : json["maximum_taxable_package_price"],
    minimumMonthlyTaxableValue: json["minimum_monthly_taxable_value"],
    maximumMonthlyTaxableValue: json["maximum_monthly_taxable_value"],
    fixedMonthlyTaxableValues: !exists(json, "fixed_monthly_taxable_values")
      ? undefined
      : json["fixed_monthly_taxable_values"],
    defaultResidualRatio: json["default_residual_ratio"],
    defaultMonthlyDecreaseInResidualValue:
      json["default_monthly_decrease_in_residual_value"],
    isEndOfLeaseRedemptionPriceDiscounted: !exists(
      json,
      "is_end_of_lease_redemption_price_discounted",
    )
      ? undefined
      : json["is_end_of_lease_redemption_price_discounted"],
    fixedEndOfLeaseRedemptionPercentage: !exists(
      json,
      "fixed_end_of_lease_redemption_percentage",
    )
      ? undefined
      : json["fixed_end_of_lease_redemption_percentage"],
    minimumMaintenanceServiceMonthly:
      json["minimum_maintenance_service_monthly"],
    maximumMaintenanceServiceMonthly:
      json["maximum_maintenance_service_monthly"],
    maximumTotalPackagePrice: json["maximum_total_package_price"],
    isDownpaymentAllowed: json["is_downpayment_allowed"],
    standardCoverageInsuranceRate: json["standard_coverage_insurance_rate"],
    fullCoverageInsuranceRate: json["full_coverage_insurance_rate"],
    insuranceDeductible: json["insurance_deductible"],
    companyMonthlyCancellationCoverage:
      json["company_monthly_cancellation_coverage"],
    individualMonthlyCancellationCoverage:
      json["individual_monthly_cancellation_coverage"],
    individualOnetimeCancellationCoverage:
      json["individual_onetime_cancellation_coverage"],
    returnFee: json["return_fee"],
    redemptionFee: json["redemption_fee"],
    deliveryFee: json["delivery_fee"],
    customerServiceMonthlyFee: json["customer_service_monthly_fee"],
    legacyWarrantyMonthlyFee: json["legacy_warranty_monthly_fee"],
    isEmployeeNumberMandatory: json["is_employee_number_mandatory"],
    isCompanyMandatory: json["is_company_mandatory"],
    isCostCenterMandatory: json["is_cost_center_mandatory"],
    billingFrequency: !exists(json, "billing_frequency")
      ? undefined
      : json["billing_frequency"],
    paymentTerms: !exists(json, "payment_terms")
      ? undefined
      : json["payment_terms"],
    serviceFeeProductCode: !exists(json, "service_fee_product_code")
      ? undefined
      : json["service_fee_product_code"],
    bikeContractProductCode: !exists(json, "bike_contract_product_code")
      ? undefined
      : json["bike_contract_product_code"],
    financingMethod: !exists(json, "financing_method")
      ? undefined
      : FinancingMethodsFromJSON(json["financing_method"]),
    financingApplicationNumber: !exists(json, "financing_application_number")
      ? undefined
      : json["financing_application_number"],
    financingDecisionNumber: !exists(json, "financing_decision_number")
      ? undefined
      : json["financing_decision_number"],
    financingDecisionDate: !exists(json, "financing_decision_date")
      ? undefined
      : new Date(json["financing_decision_date"]),
    financingDecisionExpirationDate: !exists(
      json,
      "financing_decision_expiration_date",
    )
      ? undefined
      : new Date(json["financing_decision_expiration_date"]),
    financingDecisionMaxLeasingPeriod: !exists(
      json,
      "financing_decision_max_leasing_period",
    )
      ? undefined
      : json["financing_decision_max_leasing_period"],
    financingDecisionTotalAmount: !exists(
      json,
      "financing_decision_total_amount",
    )
      ? undefined
      : json["financing_decision_total_amount"],
    financingDecisionUsedAmount: !exists(json, "financing_decision_used_amount")
      ? undefined
      : json["financing_decision_used_amount"],
    financingDecisionRemainingAmount: !exists(
      json,
      "financing_decision_remaining_amount",
    )
      ? undefined
      : json["financing_decision_remaining_amount"],
    accessRulesUuid: !exists(json, "access_rules_uuid")
      ? undefined
      : json["access_rules_uuid"],
    cancellationCoverage: BenefitDefinitionCancellationCoverageFromJSON(
      json["cancellation_coverage"],
    ),
    returnFeeCoverage: ReturnFeeFromJSON(json["return_fee_coverage"]),
  };
}

export function BenefitDefinitionCreateToJSON(
  value?: BenefitDefinitionCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    organisation_id: value.organisationId,
    organisation_billing_details_id: value.organisationBillingDetailsId,
    plan: PlanToJSON(value.plan),
    excess_monthly_payer: PayerToJSON(value.excessMonthlyPayer),
    description: value.description,
    start_date: value.startDate.toISOString().substring(0, 10),
    end_date: value.endDate.toISOString().substring(0, 10),
    order_processing_grace_period_days: value.orderProcessingGracePeriodDays,
    to_whom: value.toWhom,
    what: value.what,
    how: value.how,
    minimum_leasing_period: value.minimumLeasingPeriod,
    maximum_leasing_period: value.maximumLeasingPeriod,
    maximum_taxable_package_price: value.maximumTaxablePackagePrice,
    minimum_monthly_taxable_value: value.minimumMonthlyTaxableValue,
    maximum_monthly_taxable_value: value.maximumMonthlyTaxableValue,
    fixed_monthly_taxable_values: value.fixedMonthlyTaxableValues,
    default_residual_ratio: value.defaultResidualRatio,
    default_monthly_decrease_in_residual_value:
      value.defaultMonthlyDecreaseInResidualValue,
    is_end_of_lease_redemption_price_discounted:
      value.isEndOfLeaseRedemptionPriceDiscounted,
    fixed_end_of_lease_redemption_percentage:
      value.fixedEndOfLeaseRedemptionPercentage,
    minimum_maintenance_service_monthly: value.minimumMaintenanceServiceMonthly,
    maximum_maintenance_service_monthly: value.maximumMaintenanceServiceMonthly,
    maximum_total_package_price: value.maximumTotalPackagePrice,
    is_downpayment_allowed: value.isDownpaymentAllowed,
    standard_coverage_insurance_rate: value.standardCoverageInsuranceRate,
    full_coverage_insurance_rate: value.fullCoverageInsuranceRate,
    insurance_deductible: value.insuranceDeductible,
    company_monthly_cancellation_coverage:
      value.companyMonthlyCancellationCoverage,
    individual_monthly_cancellation_coverage:
      value.individualMonthlyCancellationCoverage,
    individual_onetime_cancellation_coverage:
      value.individualOnetimeCancellationCoverage,
    return_fee: value.returnFee,
    redemption_fee: value.redemptionFee,
    delivery_fee: value.deliveryFee,
    customer_service_monthly_fee: value.customerServiceMonthlyFee,
    legacy_warranty_monthly_fee: value.legacyWarrantyMonthlyFee,
    is_employee_number_mandatory: value.isEmployeeNumberMandatory,
    is_company_mandatory: value.isCompanyMandatory,
    is_cost_center_mandatory: value.isCostCenterMandatory,
    billing_frequency: value.billingFrequency,
    payment_terms: value.paymentTerms,
    service_fee_product_code: value.serviceFeeProductCode,
    bike_contract_product_code: value.bikeContractProductCode,
    financing_method: FinancingMethodsToJSON(value.financingMethod),
    financing_application_number: value.financingApplicationNumber,
    financing_decision_number: value.financingDecisionNumber,
    financing_decision_date:
      value.financingDecisionDate === undefined
        ? undefined
        : value.financingDecisionDate.toISOString().substring(0, 10),
    financing_decision_expiration_date:
      value.financingDecisionExpirationDate === undefined
        ? undefined
        : value.financingDecisionExpirationDate.toISOString().substring(0, 10),
    financing_decision_max_leasing_period:
      value.financingDecisionMaxLeasingPeriod,
    financing_decision_total_amount: value.financingDecisionTotalAmount,
    financing_decision_used_amount: value.financingDecisionUsedAmount,
    financing_decision_remaining_amount: value.financingDecisionRemainingAmount,
    access_rules_uuid: value.accessRulesUuid,
    cancellation_coverage: BenefitDefinitionCancellationCoverageToJSON(
      value.cancellationCoverage,
    ),
    return_fee_coverage: ReturnFeeToJSON(value.returnFeeCoverage),
  };
}
