import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Form, Input } from "@vapaus/form";
import { Button, MailIcon, TextButton, Typography } from "@vapaus/ui-v2";

import lockImg from "../../../assets/Lock.png";
import { AuthLayout } from "../AuthLayout";
import { ForgotPasswordDataType } from "./useForgotPasswordPage";

export function SendResetEmailPage(
  props: Omit<ForgotPasswordDataType, "resetEmailSent">,
) {
  const { t } = useTranslation();
  const { form } = props;

  return (
    <AuthLayout
      title={t("authV2:forgotPassword.title")}
      description={t("authV2:forgotPassword.description")}
      bannerImage={lockImg}
    >
      <Form formMethods={form.formMethods}>
        <StyledRoot>
          <Input
            name="email"
            placeholder={t("authV2:signIn.email")}
            startAdornment={<MailIcon />}
          />
          <Button
            variant="primary1"
            marginBottom="xs"
            marginTop="xs"
            isLoading={form.mutation.isLoading}
            fullWidth
          >
            {t("authV2:forgotPassword.sendMeLink")}
          </Button>
          <Typography variant="bodyLg" align="center">
            <Trans t={t} i18nKey="authV2:forgotPassword.rememberYourPassword">
              <span>Remember your password?</span>
              <TextButton to="/sign-in">Log in</TextButton>
            </Trans>
          </Typography>
        </StyledRoot>
      </Form>
    </AuthLayout>
  );
}

const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;
