import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserShopRead } from "@vapaus/api-codegen";
import { Card, Flex, Typography } from "@vapaus/ui-v2";

interface ShopCardProps {
  shop?: UserShopRead;
}

export function ShopCard({ shop }: ShopCardProps) {
  const { t } = useTranslation();

  return (
    <Card title={t("userV2:myBike.shopCard.title")}>
      <Flex xsDirection="column" xsAlign="flex-start">
        <StyledContent>
          <Typography weight="bold">
            {t("userV2:myBike.shopCard.shopName")}
          </Typography>
          <Typography marginBottom="sm">{shop?.name ?? "-"}</Typography>
          <Typography weight="bold">
            {t("userV2:myBike.shopCard.email")}
          </Typography>
          <Typography>{shop?.email ?? "-"}</Typography>
        </StyledContent>
        <StyledContent>
          <Typography weight="bold">
            {t("userV2:myBike.shopCard.website")}
          </Typography>
          <Typography marginBottom="sm">{shop?.website ?? "-"}</Typography>
          <Typography weight="bold">
            {t("userV2:myBike.shopCard.phone")}
          </Typography>
          <Typography>{shop?.phoneNumber ?? "-"}</Typography>
        </StyledContent>
      </Flex>
    </Card>
  );
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
