import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  AnimatedErrorIcon,
  AnimatedSuccessIcon,
  Flex,
  Input,
  Modal,
  Tooltip,
  Typography,
} from "@vapaus/ui-v2";

import { usePostEIdentRequest } from "../../../../../api/eIdent";

export type MissingSSNModalProps = {
  isOpen: boolean;
  missingUserInfoStatus?: "e-ident" | "incomplete-profile";
  onClose: () => void;
  onOpen: () => void;
  onOpenMissingInfoModal: () => void;
};

export function MissingSSNModal({
  isOpen,
  missingUserInfoStatus,
  onClose,
  onOpen,
  onOpenMissingInfoModal,
}: MissingSSNModalProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [modalView, setModalView] = useState<"verify" | "success" | "failed">(
    "verify",
  );
  const [ssn, setSsn] = useState<string>("");

  const isProductionOrStaging = ["staging", "prod"].includes(
    import.meta.env.VITE_ENV!,
  );

  const location = useLocation();

  const { mutate, isLoading } = usePostEIdentRequest();

  const handleVerifyNow = () =>
    mutate(
      { ssn, redirectUriPath: location.pathname },
      {
        onSuccess: ({ request_url }) => {
          if (request_url) {
            window.location.assign(request_url);
            return;
          }
          queryClient.invalidateQueries(["currentUser"]);
          afterSuccessCallBack();
        },
        onError: () => {
          setModalView("failed");
        },
      },
    );

  useEffect(() => {
    if (searchParams.get("verifyError")) {
      setSearchParams();
      setModalView("failed");
      onOpen();
    }
  }, [searchParams, setSearchParams, missingUserInfoStatus, onOpen]);

  const afterSuccessCallBack = useCallback(() => {
    setModalView("success");
    setTimeout(() => {
      if (!missingUserInfoStatus) {
        onClose();
      } else if (missingUserInfoStatus === "e-ident") {
        setModalView("verify");
      } else if (missingUserInfoStatus === "incomplete-profile") {
        onClose();
        onOpenMissingInfoModal();
      }
    }, 1000);
  }, [missingUserInfoStatus, onClose, onOpenMissingInfoModal]);

  useEffect(() => {
    if (searchParams.get("verifySuccess")) {
      setSearchParams();
      onOpen();
      afterSuccessCallBack();
    }
  }, [
    afterSuccessCallBack,
    missingUserInfoStatus,
    onClose,
    onOpen,
    onOpenMissingInfoModal,
    searchParams,
    setSearchParams,
  ]);

  const getModalProps = () => {
    const commonProps = {
      cancelText: t("commonV2:cancel"),
      onClose: onClose,
      onCancel: onClose,
      onConfirm: handleVerifyNow,
      isSubmitting: isLoading,
    };

    switch (modalView) {
      case "success":
        return {};
      case "failed":
        return {
          ...commonProps,
          confirmText: t("commonV2:tryAgain"),
        };
      default:
        return {
          ...commonProps,
          title: t("userV2:myBike.missingSSNModal.title"),
          description: t("userV2:myBike.missingSSNModal.description"),
          confirmText: t("userV2:myBike.missingSSNModal.submitText"),
          disableConfirm: !isProductionOrStaging && !ssn,
        };
    }
  };

  const modalProps = getModalProps();

  return (
    <Modal isOpen={isOpen} {...modalProps}>
      <Flex
        fullWidth
        justify="center"
        gap="none"
        align="center"
        direction="column"
      >
        {modalView === "verify" ? (
          <Flex direction="column" gap="md">
            <Tooltip
              trigger={
                <Typography
                  weight="bold"
                  decoration="underline"
                  align="center"
                  fullWidth
                >
                  {t("userV2:myBike.missingSSNModal.whyVerifyIdentity")}
                </Typography>
              }
            >
              {t("userV2:myBike.missingSSNModal.whyVerifyIdentityDescription")}
            </Tooltip>
            {!isProductionOrStaging ? (
              <Input
                value={ssn}
                onChange={(e) => setSsn(e.target.value)}
                label="Social Security Number"
                placeholder="E.g. 191184-451B"
                tooltip="For development only."
              />
            ) : null}
          </Flex>
        ) : null}
        {modalView === "success" ? (
          <>
            <AnimatedSuccessIcon />
            <Typography variant="h2" align="center" marginBottom="sm" fullWidth>
              {t("userV2:myBike.missingSSNModal.verifiedIdentity.title")}
            </Typography>
            <Typography
              variant="bodyLg"
              align="center"
              marginBottom="sm"
              fullWidth
            >
              {t("userV2:myBike.missingSSNModal.verifiedIdentity.description")}
            </Typography>
          </>
        ) : null}
        {modalView === "failed" ? (
          <>
            <AnimatedErrorIcon />
            <Typography variant="h2" align="center" marginBottom="sm" fullWidth>
              {t("userV2:myBike.missingSSNModal.failedIdentity.title")}
            </Typography>
            <Typography
              variant="bodyLg"
              align="center"
              fullWidth
              marginBottom="sm"
            >
              {t("userV2:myBike.missingSSNModal.failedIdentity.description")}
            </Typography>
          </>
        ) : null}
      </Flex>
    </Modal>
  );
}
