import { OnChangeFn, PaginationState } from "@tanstack/react-table";
import styled, { css } from "styled-components";

import { ChevronLeftIcon } from "../../Icons/ChevronLeftIcon";
import { ChevronRightIcon } from "../../Icons/ChevronRightIcon";
import { Select } from "../../Select";
import { Typography } from "../../Typography";

const pageSizeOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

export type PaginationProps = {
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  totalItems: number;
};

type PaginationComponentProps = {
  pageSize: number;
  setPageSize: (value: number) => void;
  paginationOptions: PaginationProps;
  canGetPreviousPage: boolean;
  getPreviousPage: () => void;
  canGetNextPage: boolean;
  getNextPage: () => void;
};

export function Pagination({
  pageSize,
  setPageSize,
  paginationOptions,
  canGetPreviousPage,
  getPreviousPage,
  canGetNextPage,
  getNextPage,
}: PaginationComponentProps) {
  const { pagination, totalItems } = paginationOptions;
  const { pageIndex } = pagination;

  const firstIndex = pageIndex * pageSize + 1;
  const maxIndex = (pageIndex + 1) * pageSize;
  const lastIndex = maxIndex < totalItems ? maxIndex : totalItems;

  return (
    <StyledPaginationContainer>
      <StyledSelectContainer>
        <Typography weight="bold">Rows per page:</Typography>
        <StyledSelect
          placeholder={""}
          value={pageSize}
          onChange={(e, value) => setPageSize(Number(value))}
          options={pageSizeOptions}
        />
      </StyledSelectContainer>
      <StyledResponsiveSection>
        <Typography weight="bold">
          {firstIndex}-{lastIndex} of {totalItems}
        </Typography>
        <StyledButtonsContainer>
          <StyledChangePageButton
            onClick={() => getPreviousPage()}
            disabled={!canGetPreviousPage}
          >
            <ChevronLeftIcon />
          </StyledChangePageButton>
          <StyledChangePageButton
            onClick={() => getNextPage()}
            disabled={!canGetNextPage}
          >
            <ChevronRightIcon />
          </StyledChangePageButton>
        </StyledButtonsContainer>
      </StyledResponsiveSection>
    </StyledPaginationContainer>
  );
}

const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.lg};
  padding-top: ${({ theme }) => theme.spacing.lg};
  border-top: ${({ theme }) => theme.border.primary.light};
  background-color: ${({ theme }) => theme.palette.background.white};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      flex-direction: column;
    `)}
`;

const StyledSelectContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledSelect = styled(Select<number>)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.transparent};
  padding: 0;
  box-shadow: none;
  height: auto;

  &:active,
  &:focus-within {
    box-shadow: none;
  }

  &:first-child > div {
    margin: 0;
  }
`;

const StyledResponsiveSection = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      justify-content: space-between;
      width: 100%;
    `)}
`;

const StyledButtonsContainer = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const StyledChangePageButton = styled.button`
  background: ${({ theme }) => theme.palette.common.transparent};
  border: none;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  padding: 0;

  &:disabled {
    opacity: ${({ theme }) => theme.opacity.disabled};
    pointer-events: none;
  }
`;
