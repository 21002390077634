import { ReactNode } from "react";

import styled, { css } from "styled-components";

import { makeTransition } from "../../styles";

export type NavigationButtonProps = {
  isActivated?: boolean;
  children: ReactNode;
};

function getButtonColors({ isActivated = false }: { isActivated?: boolean }) {
  return css`
    background-color: ${({ theme }) => theme.palette.common.transparent};
    color: ${({ theme }) => theme.palette.primary1.main};
    border: none;

    ${isActivated
      ? css`
          background-color: ${({ theme }) => theme.palette.primary2.main};
          box-shadow: ${({ theme }) => theme.shadows.inset};
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.palette.background.white};
            box-shadow: ${({ theme }) => theme.shadows.lg};
          }

          &:active {
            background-color: ${({ theme }) => theme.palette.background.white};
            box-shadow: ${({ theme }) => theme.shadows.inset};
          }
        `}

    &:disabled {
      background-color: ${({ theme }) => theme.palette.common.transparent};
      opacity: ${({ theme }) => theme.opacity.disabled};
      border: none;
      cursor: not-allowed;
      box-shadow: none;
    }
  `;
}

export const NavigationButton = styled.button<NavigationButtonProps>`
  height: ${({ theme }) => theme.typography.navigationButton.height};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.typography.navigationButton.fontSize};
  font-family: ${({ theme }) => theme.typography.navigationButton.fontFamily};
  font-weight: ${({ theme }) => theme.typography.navigationButton.fontWeight};
  line-height: ${({ theme }) => theme.typography.navigationButton.lineHeight};
  letter-spacing: ${({ theme }) =>
    theme.typography.navigationButton.letterSpacing};
  text-transform: ${({ theme }) =>
    theme.typography.navigationButton.textTransform};
  box-sizing: border-box;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius.button};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${getButtonColors};
  ${makeTransition("all", "shortest", "easeOut")};
`;
