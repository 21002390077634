import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  BikeBenefitContractRead,
  RedemptionProcessRead,
  RedemptionReason,
} from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import { useCreateRedemptionProcess } from "../../../../../api/redemptionProcess";
import { usePayRedemptionOrReturnInvoice } from "../../RedeemReturnSharedComponents/hooks/usePayRedemptionOrReturnInvoice";
import { useRedemptionProcessContext } from "../context/RedemptionProcessContext";

export function usePayForRedemptionProcess() {
  const { t } = useTranslation();
  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();
  const { onCompleted, calculation, redemptionReason } =
    useRedemptionProcessContext();
  const createRedemptionProcess = useCreateRedemptionProcess();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toaster = useToaster();

  const { onPayInvoice, isLoading: payInvoiceIsLoading } =
    usePayRedemptionOrReturnInvoice();

  const onCreateRedemptionProcess = (
    onSuccessCallback: (data: RedemptionProcessRead) => void,
  ) => {
    createRedemptionProcess.mutate(
      {
        bikeBenefitContractId: contract.id,
        plannedRedemptionDate: calculation?.plannedRedemptionDate as Date,
        redemptionReason: redemptionReason || RedemptionReason.EndOfLease,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["contracts"] });
          onSuccessCallback(data);
        },
        onError: () => {
          toaster.add(
            "error",
            t(
              "userV2:redemptionProcess.priceConfirmation.redemptionErrorMessage",
            ),
          );
        },
      },
    );
  };

  const handlePayLater = () => {
    onCreateRedemptionProcess(() => {
      onCompleted();
      navigate("..", { replace: true });
    });
  };

  const handlePayNow = () => {
    onCreateRedemptionProcess((data) => onPayInvoice(data.saleInvoiceId!));
  };

  return {
    handlePayLater,
    handlePayNow,
    isLoading: createRedemptionProcess.isLoading ?? payInvoiceIsLoading,
  };
}
