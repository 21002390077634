import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import {
  ActionCard,
  EmailReadIcon,
  Flex,
  UnsubscribeIcon,
} from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function AddNewEmployerPage() {
  const { t } = useTranslation();
  const { goToRequestByBusinessID, goToRequestByEmail } =
    useOnboardingMachine();

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.addNewEmployer.title")}
      subtitle={t("userV2:onboarding.addNewEmployer.subtitle")}
    >
      <StyledContainer>
        <Flex direction="column" gap="lg">
          <ActionCard
            icon={EmailReadIcon}
            firstLine={t("userV2:onboarding.addNewEmployer.workEmail.title")}
            secondLine={t(
              "userV2:onboarding.addNewEmployer.workEmail.subtitle",
            )}
            onClick={goToRequestByEmail}
          />
          <ActionCard
            icon={UnsubscribeIcon}
            firstLine={t("userV2:onboarding.addNewEmployer.noWorkEmail.title")}
            secondLine={t(
              "userV2:onboarding.addNewEmployer.noWorkEmail.subtitle",
            )}
            onClick={goToRequestByBusinessID}
          />
        </Flex>
      </StyledContainer>
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled.div`
  width: 37.5rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
