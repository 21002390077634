import { createSvgIcon } from "./SvgIcon";

export const UndoIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M7.9998 19C7.71647 19 7.47897 18.9042 7.2873 18.7125C7.09564 18.5209 6.9998 18.2834 6.9998 18C6.9998 17.7167 7.09564 17.4792 7.2873 17.2875C7.47897 17.0959 7.71647 17 7.9998 17H14.0998C15.1498 17 16.0623 16.6667 16.8373 16C17.6123 15.3334 17.9998 14.5 17.9998 13.5C17.9998 12.5 17.6123 11.6667 16.8373 11C16.0623 10.3334 15.1498 10 14.0998 10H7.7998L9.6998 11.9C9.88314 12.0834 9.9748 12.3167 9.9748 12.6C9.9748 12.8834 9.88314 13.1167 9.6998 13.3C9.51647 13.4834 9.28314 13.575 8.9998 13.575C8.71647 13.575 8.48314 13.4834 8.2998 13.3L4.6998 9.70005C4.5998 9.60005 4.52897 9.49172 4.4873 9.37505C4.44564 9.25838 4.4248 9.13338 4.4248 9.00005C4.4248 8.86672 4.44564 8.74172 4.4873 8.62505C4.52897 8.50838 4.5998 8.40005 4.6998 8.30005L8.2998 4.70005C8.48314 4.51672 8.71647 4.42505 8.9998 4.42505C9.28314 4.42505 9.51647 4.51672 9.6998 4.70005C9.88314 4.88338 9.9748 5.11672 9.9748 5.40005C9.9748 5.68338 9.88314 5.91672 9.6998 6.10005L7.7998 8.00005H14.0998C15.7165 8.00005 17.104 8.52505 18.2623 9.57505C19.4206 10.625 19.9998 11.9334 19.9998 13.5C19.9998 15.0667 19.4206 16.375 18.2623 17.425C17.104 18.475 15.7165 19 14.0998 19H7.9998Z" />
  </svg>,
  "Undo",
);
