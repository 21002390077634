import { useMutation } from "@tanstack/react-query";

import {
  EIdentApi,
  EIdentPostEIdentRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const eIdentAPI = new EIdentApi(apiConfiguration);

type EIdentLocalResponse = {
  success: boolean;
  request_url: never;
};

type EIdentProductionResponse = {
  success: never;
  request_url: string;
};

type EIdentResponse = EIdentLocalResponse | EIdentProductionResponse;

export const usePostEIdentRequest = () => {
  return useMutation<EIdentResponse, ResponseError, EIdentPostEIdentRequest>(
    ["eIdent"],
    ({ ssn, redirectUriPath }) =>
      eIdentAPI.eIdentPostEIdent({ ssn, redirectUriPath }),
  );
};
