/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const Currency = {
  Eur: "EUR",
  Sek: "SEK",
} as const;
export type Currency = (typeof Currency)[keyof typeof Currency];

export function CurrencyFromJSON(json: any): Currency {
  return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Currency {
  return json as Currency;
}

export function CurrencyToJSON(value?: Currency | null): any {
  return value as any;
}
