import { useTranslation } from "react-i18next";

import { BikeBenefitOrderRead } from "@vapaus/api-codegen";
import {
  BunnyOnBikeAlt3DIcon,
  Flex,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { BikeCard } from "./BikeCard";

interface BikesProps {
  orders: Array<BikeBenefitOrderRead>;
}

export function Bikes({ orders }: BikesProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" align="center" gap="lg">
      <BunnyOnBikeAlt3DIcon size="lg" />
      <Flex direction="column" align="center" gap="sm">
        <Typography variant="h1">{t("userV2:myBikes.bikes.title")}</Typography>
        <Typography variant="bodyLg" align="center">
          {t("userV2:myBikes.bikes.description")}
        </Typography>
      </Flex>
      <Flex direction="column" fullWidth gap="md">
        {orders.map((order) => (
          <BikeCard key={order.id} order={order} />
        ))}
      </Flex>
      <Flex direction="column" align="center" gap="xs">
        <Typography align="center">{t("userV2:myBikes.bikes.hint")}</Typography>
        <TextButton
          to={t("userV2:myBikes.bikes.newsletter.link")}
          target="_blank"
          size="md"
        >
          {t("userV2:myBikes.bikes.newsletter.text")}
        </TextButton>
      </Flex>
    </Flex>
  );
}
