import {
  useCurrencyFormat,
  useDateFormat,
  usePercentFormat,
} from "packages/i18n/src/hooks";
import { useTranslation } from "react-i18next";

import {
  BikeBenefitContractRead,
  Currency,
  ShopMaintenanceEventRead,
} from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import {
  Flex,
  HorizontalLine,
  Modal,
  RowItem,
  Typography,
} from "@vapaus/ui-v2";

type MaintenanceEventShowModalProps = {
  isOpen: boolean;
  onClose: () => void;
  maintenanceEvent?: ShopMaintenanceEventRead;
  contract: BikeBenefitContractRead;
};

export function MaintenanceEventShowModal({
  isOpen,
  onClose,
  maintenanceEvent,
  contract,
}: MaintenanceEventShowModalProps) {
  const { t } = useTranslation();

  const { getEnumLabel } = useEnumEntries("WorkTypes");

  const formatDate = useDateFormat();
  const formatPercent = usePercentFormat();
  const formatCurrency = useCurrencyFormat(
    maintenanceEvent?.currency || Currency.Eur,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" gap="md">
        <Flex direction="column" align="center" gap="xxs">
          <Typography variant="h6">
            {maintenanceEvent?.bike.bikeBrand.name}{" "}
            {maintenanceEvent?.bike.model}
          </Typography>
          <Typography variant="h2">
            {getEnumLabel(maintenanceEvent?.workType)}
          </Typography>
          <Typography>
            {maintenanceEvent?.bike.serialNumber} ·{" "}
            {maintenanceEvent?.vapausCode}
          </Typography>
        </Flex>
        <Flex direction="column" align="center">
          <Flex direction="column" align="center" gap="xxs">
            <Typography weight="bold">
              {t("userV2:myBike.maintenanceEventModal.serviceDescription")}
            </Typography>
            <Typography align="center">
              {maintenanceEvent?.workDescription}
            </Typography>
          </Flex>
        </Flex>
        <HorizontalLine marginTop="none" marginBottom="none" />
        <Flex direction="column">
          <RowItem
            label={t("userV2:myBike.maintenanceEventModal.serviceProvider")}
            value={maintenanceEvent?.shop.name}
          />
          <RowItem
            label={t("userV2:myBike.maintenanceEventModal.date")}
            value={formatDate(maintenanceEvent?.workDate)}
          />
          <RowItem
            label={t("userV2:myBike.maintenanceEventModal.parts", {
              value: formatPercent(contract.flatVatRate),
            })}
            value={formatCurrency(maintenanceEvent?.partsPrice)}
          />
          <RowItem
            label={t("userV2:myBike.maintenanceEventModal.labour", {
              value: formatPercent(contract.flatVatRate),
            })}
            value={formatCurrency(maintenanceEvent?.workPrice)}
          />
        </Flex>
        <HorizontalLine marginTop="none" marginBottom="none" />
        <RowItem
          label={t("userV2:myBike.maintenanceEventModal.total")}
          value={formatCurrency(maintenanceEvent?.totalPrice)}
          valueIsBold
        />
      </Flex>
    </Modal>
  );
}
