import { createSvgIcon } from "./SvgIcon";

export const InventoryIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M15.5 17.125L20.45 12.175C20.6333 11.9917 20.8667 11.9 21.15 11.9C21.4333 11.9 21.6667 11.9917 21.85 12.175C22.0333 12.3583 22.125 12.5917 22.125 12.875C22.125 13.1583 22.0333 13.3917 21.85 13.575L16.2 19.225C16 19.425 15.7667 19.525 15.5 19.525C15.2333 19.525 15 19.425 14.8 19.225L11.95 16.375C11.7667 16.1917 11.675 15.9583 11.675 15.675C11.675 15.3917 11.7667 15.1583 11.95 14.975C12.1333 14.7917 12.3667 14.7 12.65 14.7C12.9333 14.7 13.1667 14.7917 13.35 14.975L15.5 17.125ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.175C9.35833 2.41667 9.71667 1.9375 10.25 1.5625C10.7833 1.1875 11.3667 1 12 1C12.6667 1 13.2625 1.1875 13.7875 1.5625C14.3125 1.9375 14.6667 2.41667 14.85 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9C21 9.28333 20.9042 9.52083 20.7125 9.7125C20.5208 9.90417 20.2833 10 20 10C19.7167 10 19.4792 9.90417 19.2875 9.7125C19.0958 9.52083 19 9.28333 19 9V5H17V7C17 7.28333 16.9042 7.52083 16.7125 7.7125C16.5208 7.90417 16.2833 8 16 8H8C7.71667 8 7.47917 7.90417 7.2875 7.7125C7.09583 7.52083 7 7.28333 7 7V5H5V19H10C10.2833 19 10.5208 19.0958 10.7125 19.2875C10.9042 19.4792 11 19.7167 11 20C11 20.2833 10.9042 20.5208 10.7125 20.7125C10.5208 20.9042 10.2833 21 10 21H5ZM12 5C12.2833 5 12.5208 4.90417 12.7125 4.7125C12.9042 4.52083 13 4.28333 13 4C13 3.71667 12.9042 3.47917 12.7125 3.2875C12.5208 3.09583 12.2833 3 12 3C11.7167 3 11.4792 3.09583 11.2875 3.2875C11.0958 3.47917 11 3.71667 11 4C11 4.28333 11.0958 4.52083 11.2875 4.7125C11.4792 4.90417 11.7167 5 12 5Z" />
  </svg>,
  "Inventory",
);
