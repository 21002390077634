import { ReactNode, useRef, useState } from "react";

import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";

import { Flex, Justify } from "../Flex";
import { ExpandMoreIcon } from "../Icons";
import { Typography } from "../Typography";
import { SpacingVariants, isSafari } from "../styles";

export interface CollapsibleLineItemProps {
  title: string;
  children: ReactNode;
  headerJustify?: Justify;
  headerPadding?: SpacingVariants;
  topBorder?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

export function CollapsibleLineItem({
  title,
  children,
  headerJustify = "space-between",
  headerPadding,
  topBorder,
  onToggle,
}: CollapsibleLineItemProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAnimationEnded, setIsOpenAnimationEnded] =
    useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const springProps = useSpring({
    height: isOpen ? `${contentRef.current?.scrollHeight ?? 0}px` : "0px",
    opacity: isOpen ? 1 : 0,
    onStart: () => setIsOpenAnimationEnded(false),
    onRest(result) {
      if (result.value.height !== "0px") {
        // This is to allow us to have nested collapsible elements
        setIsOpenAnimationEnded(true);
      }
    },
    immediate: isSafari(),
  });
  const iconSpringProps = useSpring({
    rotate: isOpen ? 180 : 0,
  });

  const handleOnClick = () => {
    setIsOpen(!isOpen);
    onToggle?.(!isOpen);
  };

  return (
    <StyledRoot $topBorder={topBorder}>
      <StyledHead
        align="center"
        justify={headerJustify}
        $padding={headerPadding}
        onClick={handleOnClick}
        gap="xs"
      >
        <Typography weight="bold">{title}</Typography>
        <animated.div style={iconSpringProps}>
          <ExpandMoreIcon size="md" />
        </animated.div>
      </StyledHead>
      <StyledContainer hideOverflow={!isOpenAnimationEnded}>
        <animated.div
          style={{
            opacity: springProps.opacity,
            height: isOpenAnimationEnded ? "auto" : springProps.height,
            overflow: "hidden",
          }}
        >
          <StyledInnerContainer ref={contentRef}>
            {children}
          </StyledInnerContainer>
        </animated.div>
      </StyledContainer>
    </StyledRoot>
  );
}

const StyledHead = styled(Flex)<{
  $padding?: SpacingVariants;
}>`
  cursor: pointer;
  padding-top: ${({ theme, $padding = "md" }) => theme.spacing[$padding]};
  padding-bottom: ${({ theme, $padding = "md" }) => theme.spacing[$padding]};
`;

const StyledContainer = styled.div<{
  hideOverflow?: boolean;
}>`
  overflow: ${({ hideOverflow }) => (hideOverflow ? "hidden" : "visible")};
`;

const StyledInnerContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.md};
`;

const StyledRoot = styled.div<{
  $topBorder?: boolean;
}>`
  border-top: ${({ theme, $topBorder = true }) =>
    $topBorder ? theme.border.primary.light : "none"};
`;
