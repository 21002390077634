import styled, { keyframes } from "styled-components";

import { Flex } from "../Flex";
import { Logo } from "../Logo";

export function SpinningLogo() {
  return (
    <StyledRoot align="center" justify="center">
      <Logo size="sm" />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  animation: ${keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`} 2s linear infinite;
`;
