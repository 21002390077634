import bikePath from "./IconImages/Bike.png";
import bookPath from "./IconImages/Book.png";
import bookingsPath from "./IconImages/Bookings.png";
import bunnyOnBikePath from "./IconImages/BunnyOnBike.png";
import bunnyOnBikeAltPath from "./IconImages/BunnyOnBikeAlt.png";
import bunnyStandingFrontPath from "./IconImages/BunnyStandingFront.png";
import bunnyWithBikePath from "./IconImages/BunnyWithBike.png";
import calculatorPath from "./IconImages/Calculator.png";
import cardPath from "./IconImages/Card.png";
import circledBikePath from "./IconImages/CircledBike.png";
import contractPath from "./IconImages/Contract.png";
import keyBoxPath from "./IconImages/Key_Box.png";
import lensPath from "./IconImages/Lens.png";
import lockPath from "./IconImages/Lock.png";
import notePath from "./IconImages/Note.png";
import partyPath from "./IconImages/Party.png";
import phonePath from "./IconImages/Phone.png";
import phoneKeyPath from "./IconImages/Phone_Key.png";
import searchPath from "./IconImages/Search.png";
import settingsPath from "./IconImages/Settings.png";
import supportPath from "./IconImages/Support.png";
import warningPath from "./IconImages/Warning.png";
import { create3DIcon } from "./create3DIcon";

export const Bike3DIcon = create3DIcon(bikePath, "Bike");
export const Book3DIcon = create3DIcon(bookPath, "Book");
export const Bookings3DIcon = create3DIcon(bookingsPath, "Bookings");
export const BunnyOnBike3DIcon = create3DIcon(bunnyOnBikePath, "BunnyOnBike");
export const BunnyOnBikeAlt3DIcon = create3DIcon(
  bunnyOnBikeAltPath,
  "BunnyOnBikeAlt",
);
export const BunnyStandingFront3DIcon = create3DIcon(
  bunnyStandingFrontPath,
  "BunnyStandingFront",
);
export const BunnyWithBike3DIcon = create3DIcon(
  bunnyWithBikePath,
  "BunnyWithBike",
);
export const Calculator3DIcon = create3DIcon(calculatorPath, "Calculator");
export const Card3DIcon = create3DIcon(cardPath, "Card");
export const Contract3DIcon = create3DIcon(contractPath, "Contract");
export const KeyBox3DIcon = create3DIcon(keyBoxPath, "KeyBox");
export const Lens3DIcon = create3DIcon(lensPath, "Lens");
export const Lock3DIcon = create3DIcon(lockPath, "Lock");
export const Party3DIcon = create3DIcon(partyPath, "Party");
export const PhoneKey3DIcon = create3DIcon(phoneKeyPath, "PhoneKey");
export const Phone3DIcon = create3DIcon(phonePath, "Phone");
export const Settings3DIcon = create3DIcon(settingsPath, "Settings");
export const Support3DIcon = create3DIcon(supportPath, "Support");
export const Warning3DIcon = create3DIcon(warningPath, "Warning");
export const Search3DIcon = create3DIcon(searchPath, "Search");
export const Note3DIcon = create3DIcon(notePath, "Note");
export const CircledBike3DIcon = create3DIcon(circledBikePath, "Circled");
