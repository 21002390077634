/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  UserTerms,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  UserTermsFromJSON,
  UserTermsToJSON,
} from "../models/index";
import * as runtime from "../runtime";

/**
 *
 */
export class UserTermsApi extends runtime.BaseAPI {
  /**
   * Current User Terms
   */
  async userTermsCurrentUserTermsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserTerms>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/user-terms/current`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserTermsFromJSON(jsonValue),
    );
  }

  /**
   * Current User Terms
   */
  async userTermsCurrentUserTerms(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserTerms> {
    const response = await this.userTermsCurrentUserTermsRaw(initOverrides);
    return await response.value();
  }
}
