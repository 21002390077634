import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import * as z from "zod";

import {
  Button,
  Flex,
  Input,
  MailIcon,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

const emailSchema = z.object({
  email: z.string().email(),
});

export function AddWorkEmailPage() {
  const { t } = useTranslation();
  const { createNewEmail, goToRequestByBusinessID, isLoading } =
    useOnboardingMachine();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ resolver: zodResolver(emailSchema) });

  const handleAddEmail = ({ email }: FieldValues) => createNewEmail(email);

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.addWorkEmail.title")}
      subtitle={t("userV2:onboarding.addWorkEmail.subtitle")}
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(handleAddEmail)}>
          <Flex direction="column" gap="md" align="center">
            <Input
              fullWidth
              required
              type="email"
              startAdornment={<MailIcon />}
              placeholder={t("userV2:onboarding.addWorkEmail.placeholder")}
              label={t("userV2:onboarding.addWorkEmail.label")}
              {...register("email")}
            />
            <Button
              type="submit"
              fullWidth
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
      <Flex direction="column" gap="xs" align="center">
        <Typography>
          {t("userV2:onboarding.addWorkEmail.dontHaveWorkEmail")}
        </Typography>
        <TextButton size="md" onClick={goToRequestByBusinessID}>
          {t("userV2:onboarding.addWorkEmail.sendRequestUsingBusinessId")}
        </TextButton>
      </Flex>
    </OnboardingPageLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
