import { useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

export function useBackButton() {
  const [searchParams] = useSearchParams();
  const [searchChangeCounter, setSearchChangeCounter] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.size) {
      setSearchChangeCounter((prevCounter) => prevCounter + 1);
    }
  }, [searchParams]);

  return () => navigate(-searchChangeCounter);
}
