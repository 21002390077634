import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UserBenefitDefinitionAccessRequestRead } from "@vapaus/api-codegen";
import { ActionCard, BadgeIcon } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../api/benefitDefinitions";
import { Path } from "../../../constants/paths";
import { getBenefitStatusTag } from "../../../utils/getBenefitStatusTag";

type AccessRequestListItemProps = {
  accessRequest?: UserBenefitDefinitionAccessRequestRead;
};

export function AccessRequestListItem({
  accessRequest,
}: AccessRequestListItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: benefitDefinition } = useGetBenefitDefinition(
    accessRequest?.benefitDefinitionId,
  );

  if (!accessRequest?.status) {
    return null;
  }

  if (accessRequest?.benefitDefinitionId && !benefitDefinition) {
    return null;
  }

  return (
    <ActionCard
      icon={BadgeIcon}
      firstLine={
        benefitDefinition?.organisation.name || accessRequest.businessId || ""
      }
      secondLine={accessRequest.userEmail}
      thirdLine={benefitDefinition?.name ?? t("userV2:benefit.noBenefitLinked")}
      afterContent={getBenefitStatusTag({
        t,
        status: accessRequest.status,
        isBenefitDefinitionActive: benefitDefinition?.active ?? true,
      })}
      onClick={() => navigate(`${Path.accessRequest}/${accessRequest?.id}`)}
    />
  );
}
