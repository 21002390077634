import { Outlet, RouteObject } from "react-router-dom";

import { Path } from "../../constants/paths";
import { AccessRequestBenefitPage } from "./components/AccessRequestBenefitPage";
import { ActivationBenefitPage } from "./components/ActivationBenefitPage";
import { BenefitGuidelineTabContent } from "./components/BenefitGuidelineTabContent";
import { EmploymentDetailsTabContent } from "./components/EmploymentDetailsTabContent";

const tabPaths = [
  {
    path: Path.benefitGuideline,
    element: <BenefitGuidelineTabContent />,
  },
  {
    index: true,
    element: <EmploymentDetailsTabContent />,
  },
];

export const benefitRoutes: RouteObject[] = [
  {
    path: Path.accessRequest,
    element: <Outlet />,
    children: [
      {
        path: ":accessRequestId",
        element: <AccessRequestBenefitPage />,
        children: tabPaths,
      },
    ],
  },
  {
    path: Path.activation,
    element: <Outlet />,
    children: [
      {
        path: ":activationId",
        element: <ActivationBenefitPage />,
        children: tabPaths,
      },
    ],
  },
];
