/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CarRead } from "./CarRead";
import type { LockType } from "./LockType";

export type VehicleCar = {
  vehicle_type: VehicleCar.vehicle_type;
  name: string;
  /**
   * ID of the Bike/Car/Scooter
   */
  mapped_id: string;
  organisation_id: string;
  is_active?: boolean;
  fleet_id?: string;
  how_to_use?: string;
  lock_id?: string;
  lock_type?: LockType;
  id: string;
  created_at?: string;
  car: CarRead;
};

export namespace VehicleCar {
  export enum vehicle_type {
    CAR = "car",
  }
}
