import { useEffect, useRef, useState } from "react";

import { animated } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { usePageTransition } from "@vapaus/common-v2";
import { Flex, Modal, ModalNavBar, ProgressBar } from "@vapaus/ui-v2";

import { EMAIL_VERIFICATION_RETURN_PROCESS_KEY } from "../../../../constants/emailVerification";
import { EmailAddressView } from "../RedeemReturnSharedComponents/RedeemReturnEmailAddressView";
import { PaymentProcessingView } from "../RedeemReturnSharedComponents/RedeemReturnPaymentProcessing";
import { useReturnProcessContext } from "./context/ReturnProcessContext";
import {
  AccessoriesView,
  BikeCenterInfoView,
  ChoosePickupDetailsView,
  ChooseReturnMethodView,
  PaymentSummaryView,
  ReturnChecklistView,
  ReturnReasonView,
  UploadPhotosView,
} from "./pages";

type ReturnProcessWizardViewProps = {
  onClose: () => void;
};

export function ReturnProcessWizardView({
  onClose,
}: ReturnProcessWizardViewProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isExitConfirmationModalOpen, setIsExitConfirmationModalOpen] =
    useState<boolean>(false);
  const { step, onNext, onPrevious, stateMatches, isContractEndingSoon } =
    useReturnProcessContext();
  const RETURN_PROCESS_WIZARD_STEPS_TOTAL = 10;
  const WIZARD_STARTUP_STEP = isContractEndingSoon ? 2 : 1;
  const [isTransitionFinished, setIsTransitionFinished] =
    useState<boolean>(true);

  const transitions = usePageTransition({
    current: step,
    startUpStep: WIZARD_STARTUP_STEP,
    onStart: () => setIsTransitionFinished(false),
    onFinished: () => setIsTransitionFinished(true),
  });
  const containerDivRef = useRef<HTMLDivElement>(null);

  const onBack = step > WIZARD_STARTUP_STEP ? onPrevious : undefined;

  useEffect(() => {
    if (containerDivRef.current) {
      containerDivRef.current.scrollTo({ top: 0 });
    }
  }, [step]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const emailVerificationResumePath =
    location.pathname + "?" + EMAIL_VERIFICATION_RETURN_PROCESS_KEY;

  return (
    <StyledRoot
      data-testid="return-process-modal"
      direction="column"
      gap="none"
    >
      <ModalNavBar
        bgColor="common.transparent"
        hasBorder={false}
        title={
          <Flex align="center" justify="center">
            <StyledProgressBarContainer>
              <ProgressBar
                progressPercentage={
                  (100 * step) / RETURN_PROCESS_WIZARD_STEPS_TOTAL
                }
              />
            </StyledProgressBarContainer>
          </Flex>
        }
        onClose={() => setIsExitConfirmationModalOpen(true)}
        onBack={onBack}
        backEnabled={onBack && isTransitionFinished}
      />
      <StyledContent ref={containerDivRef} fullWidth fullHeight>
        {stateMatches("processingPayment") ? (
          <PaymentProcessingView cancellationType="return" />
        ) : (
          <>
            {transitions((style) => (
              <animated.div style={{ ...style, width: "100%", height: "100%" }}>
                {stateMatches("returnReason") ? <ReturnReasonView /> : null}
                {stateMatches("chooseReturnMethod") ? (
                  <ChooseReturnMethodView />
                ) : null}
                {stateMatches("bikeCenterInfo") ? <BikeCenterInfoView /> : null}
                {stateMatches("choosePickupDetails") ? (
                  <ChoosePickupDetailsView />
                ) : null}
                {stateMatches("accessories") ? <AccessoriesView /> : null}
                {stateMatches("uploadPhotos") ? <UploadPhotosView /> : null}
                {stateMatches("returnChecklist") ? (
                  <ReturnChecklistView />
                ) : null}
                {stateMatches("emailAddress") ? (
                  <EmailAddressView
                    onNext={onNext}
                    emailVerificationResumePath={emailVerificationResumePath}
                  />
                ) : null}
                {stateMatches("priceConfirmation") ? (
                  <PaymentSummaryView />
                ) : null}
              </animated.div>
            ))}
          </>
        )}
      </StyledContent>
      <Modal
        isOpen={isExitConfirmationModalOpen}
        title={t("userV2:returnProcess.exitConfirmationModal.title")}
        description={t(
          "userV2:returnProcess.exitConfirmationModal.description",
        )}
        confirmText={t("userV2:returnProcess.exitConfirmationModal.title")}
        cancelText={t("commonV2:goBack")}
        onClose={() => setIsExitConfirmationModalOpen(false)}
        onCancel={() => setIsExitConfirmationModalOpen(false)}
        onConfirm={onClose}
      />
    </StyledRoot>
  );
}

const StyledProgressBarContainer = styled.div`
  width: 12.5rem;
`;

const StyledRoot = styled(Flex)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const StyledContent = styled(Flex)`
  overflow-y: scroll;
  overflow-x: hidden;
`;
