import { ComponentType, ReactElement } from "react";

import styled from "styled-components";

import { SvgIconProps } from "../../Icons";
import { DropdownHeader, DropdownHeaderProps } from "../DropdownHeader";
import { DropdownItem } from "../DropdownItem";
import { SelectableDropdownItem } from "../SelectableDropdownItem";

export type DropdownItemWithoutIconType = {
  label: string;
  value: string;
  onItemClick?: () => void;
};

export type DropdownItemWithIconType = DropdownItemWithoutIconType & {
  icon: ComponentType<SvgIconProps>;
};

type SelectableDropdownMenuProps = {
  selected: string;
  onHoverAdornment?: ComponentType<SvgIconProps>;
};

export type DropdownMenuProps = {
  header?: DropdownHeaderProps;
  items: Array<DropdownItemWithoutIconType> | Array<DropdownItemWithIconType>;
  onItemClick: (value: string) => void;
  selectable?: SelectableDropdownMenuProps;
};

export const DropdownMenu = ({
  header,
  items,
  onItemClick,
  selectable,
}: DropdownMenuProps): ReactElement => (
  <StyledRoot data-testid="dropdown-menu-root">
    {header && <DropdownHeader {...header} />}
    {items.map((item) =>
      selectable ? (
        <SelectableDropdownItem
          key={item.value}
          {...selectable}
          selected={item.value === selectable.selected}
          onClick={() => onItemClick(item.value)}
          {...item}
        />
      ) : (
        <DropdownItem
          key={item.value}
          onClick={() =>
            item.onItemClick ? item.onItemClick() : onItemClick(item.value)
          }
          {...item}
        />
      ),
    )}
  </StyledRoot>
);

const StyledRoot = styled.div`
  background: ${({ theme }) => theme.palette.background.white};
  border-radius: ${({ theme }) => theme.radius.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  overflow-y: auto;
  max-height: 50vh;
`;
