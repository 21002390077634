import styled, { css } from "styled-components";

import { ArrowDropDownIcon } from "../Icons/ArrowDropDownIcon";
import { Typography } from "../Typography";
import { makeTransition } from "../styles";

export type AccountSwitcherProps = {
  title: string;
  selectedAccount: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

export function AccountSwitcher({
  title,
  selectedAccount,
  onClick,
  disabled = false,
}: AccountSwitcherProps) {
  return (
    <StyledRoot disabled={disabled} onClick={!disabled ? onClick : undefined}>
      <div>
        <Typography variant="bodySm">{title}</Typography>
        <Typography variant="bodyMd" weight="bold" noWrap>
          {selectedAccount}
        </Typography>
      </div>
      <ArrowDropDownIcon />
    </StyledRoot>
  );
}

const StyledRoot = styled.div<Pick<AccountSwitcherProps, "disabled">>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  ${makeTransition("all", "shortest", "easeOut")}

  ${(props) =>
    props.disabled
      ? css`
          opacity: ${({ theme }) => theme.opacity.disabled};
          cursor: not-allowed;
        `
      : css`
          &:hover {
            opacity: ${({ theme }) => theme.opacity.muted};
          }
        `};
`;
