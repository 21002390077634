import { useQuery } from "@tanstack/react-query";

import {
  BenefitApi,
  BikeBenefitOrderRead,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const benefitAPI = new BenefitApi(apiConfiguration);

export const useGetBikeBenefitOrder = (
  vapausCode?: string,
  options?: UseQueryOptions<BikeBenefitOrderRead>,
) =>
  useQuery<BikeBenefitOrderRead, ResponseError>(
    ["orders", vapausCode],
    () =>
      benefitAPI.benefitGetBikeBenefitOrder({
        vapausCode: vapausCode as string,
      }),
    {
      ...options,
      enabled: !!vapausCode,
    },
  );
