import { createSvgIcon } from "./SvgIcon";

export const ArrowDropUpIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M9.42498 13.9999C8.97498 13.9999 8.66248 13.7957 8.48748 13.3874C8.31248 12.9791 8.38331 12.6166 8.69998 12.2999L11.3 9.6999C11.4 9.5999 11.5083 9.5249 11.625 9.4749C11.7416 9.4249 11.8666 9.3999 12 9.3999C12.1333 9.3999 12.2583 9.4249 12.375 9.4749C12.4916 9.5249 12.6 9.5999 12.7 9.6999L15.3 12.2999C15.6166 12.6166 15.6875 12.9791 15.5125 13.3874C15.3375 13.7957 15.025 13.9999 14.575 13.9999H9.42498Z" />
  </svg>,
  "ArrowDropUp",
);
