import styled from "styled-components";

import { Typography } from "@vapaus/ui-v2";

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  &:last-child {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

interface LabeledValuesProps {
  title: string;
  items: Array<{
    label: string;
    value: string;
  }>;
}

export function LabeledValues({ title, items }: LabeledValuesProps) {
  return (
    <div>
      <Typography weight="bold" marginBottom="xxs">
        {title}
      </Typography>
      {items.map(({ label, value }) => (
        <Row key={label}>
          <Typography>{label}</Typography>
          <Typography>{value}</Typography>
        </Row>
      ))}
    </div>
  );
}
