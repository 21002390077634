import { useState } from "react";

import { useTranslation } from "react-i18next";

import { AddNewEmailModal } from "@vapaus/settings";
import { Button } from "@vapaus/ui-v2";

type AddNewEmailButtonProps = {
  emailVerificationResumePath: string;
};

export function AddNewEmailButton({
  emailVerificationResumePath,
}: AddNewEmailButtonProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)} fullWidth>
        {t("userV2:redeemReturnSharedComponents.emailAddress.modal.title")}
      </Button>
      <AddNewEmailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t(
          "userV2:redeemReturnSharedComponents.emailAddress.modal.title",
        )}
        description={t(
          "userV2:redeemReturnSharedComponents.emailAddress.modal.description",
        )}
        resumePath={emailVerificationResumePath}
      />
    </>
  );
}
