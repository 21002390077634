import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BenefitDefinitionUserRead, Plan } from "@vapaus/api-codegen";
import { HowItWorks, ResultBox, SliderInput } from "@vapaus/calculator";
import { Calculator3DIcon, Flex, Paper, Typography } from "@vapaus/ui-v2";

import { useGetCalculations } from "../../api/calculations";
import { StyledContent } from "./components/StyledContent";
import { StyledRoot } from "./components/StyledRoot";
import { StyledRow } from "./components/StyledRow";

type CalculatorDefaultPageProps = {
  benefitDefinition: BenefitDefinitionUserRead;
};

export function CalculatorDefaultPage({
  benefitDefinition,
}: CalculatorDefaultPageProps) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    totalPackagePrice: 2000,
    monthlySalary: 3800,
  });

  const handleChange =
    (field: "totalPackagePrice" | "monthlySalary") => (value: number) => {
      setData({ ...data, [field]: value });
    };

  const showDisclaimer =
    benefitDefinition?.plan === Plan.Enterprise &&
    benefitDefinition.fixedMonthlyTaxableValues &&
    benefitDefinition.fixedMonthlyTaxableValues.length > 1 &&
    Math.max(...benefitDefinition.fixedMonthlyTaxableValues) > 100;

  const { data: calculation } = useGetCalculations({
    ...data,
    benefitDefinitionId: benefitDefinition?.id as string,
  });

  return (
    <StyledRoot>
      <StyledContent>
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" gap="lg">
            <Calculator3DIcon size="lg" />
            <Flex direction="column" align="center" gap="sm">
              <Typography variant="h1" align="center">
                {t("userV2:calculator.title")}
              </Typography>
              <Typography variant="bodyLg" align="center">
                {t("userV2:calculator.description")}
              </Typography>
            </Flex>
          </Flex>
          <StyledRow gap="lg" align="stretch">
            <Paper shadow="md">
              <Flex direction="column" gap="xl">
                <SliderInput
                  label={t("calculator:packagePrice")}
                  helpText={t("calculator:packagePriceHelp")}
                  name="packagePrice"
                  step={50}
                  min={400}
                  max={
                    benefitDefinition?.plan === Plan.Enterprise
                      ? 4400
                      : benefitDefinition?.plan === Plan.Pro
                      ? 4000
                      : 3000
                  }
                  value={data.totalPackagePrice}
                  onChange={handleChange("totalPackagePrice")}
                />
                <SliderInput
                  label={t("calculator:monthlySalary")}
                  helpText={t("calculator:monthlySalaryHelp")}
                  name="monthlySalary"
                  step={100}
                  min={1100}
                  max={8000}
                  value={data.monthlySalary}
                  onChange={handleChange("monthlySalary")}
                />
              </Flex>
            </Paper>
            <ResultBox calculation={calculation} />
          </StyledRow>
          <Flex direction="column" gap="lg" align="center">
            <HowItWorks calculation={calculation} />
            {showDisclaimer && (
              <StyledDisclaimerContainer>
                <Typography variant="bodySm" align="center">
                  <Trans
                    i18nKey="userV2:calculator.disclaimer"
                    values={{
                      value: Math.max(
                        ...benefitDefinition!.fixedMonthlyTaxableValues!,
                      ),
                    }}
                    components={{
                      1: (
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={t("userV2:calculator.customerServiceLink")}
                        >
                          customer service team
                        </Link>
                      ),
                    }}
                  />
                </Typography>
              </StyledDisclaimerContainer>
            )}
          </Flex>
        </Flex>
      </StyledContent>
    </StyledRoot>
  );
}

const StyledDisclaimerContainer = styled.div`
  max-width: 500px;
`;
