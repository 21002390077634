import { forwardRef } from "react";

import {
  Switch as BaseSwitch,
  type SwitchProps,
  switchClasses,
} from "@mui/base/Switch";
import styled from "styled-components";

import { makeTransition } from "../styles";

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  function Switch(props, ref) {
    return (
      <BaseSwitch
        slots={{
          root: StyledRoot,
          input: (inputProps) => (
            <input ref={ref} type="checkbox" role="switch" {...inputProps} />
          ),
        }}
        {...props}
      />
    );
  },
);

const StyledRoot = styled.span`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  cursor: pointer;
  ${makeTransition("all", "standard", "easeOut")}

  &.${switchClasses.disabled} {
    opacity: ${({ theme }) => theme.opacity.disabled};
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: ${({ theme }) => theme.border.bold.light};
    border-radius: 24px;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    transition-property: all;
  }

  & .${switchClasses.thumb} {
    box-sizing: border-box;
    display: block;
    width: 12px;
    height: 12px;
    top: 6px;
    left: 6px;
    border-radius: 12px;
    position: relative;
    background-color: ${({ theme }) => theme.palette.primary1.main};
    ${makeTransition("all", "standard", "easeOut")}
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: calc(100% - 18px);
      background-color: ${({ theme }) => theme.palette.common.white};
    }

    .${switchClasses.track} {
      border: none;
      background-color: ${({ theme }) => theme.palette.secondary1.main};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
