import { startCase } from "lodash-es";
import { Link } from "react-router-dom";
import styled, { AnyStyledComponent } from "styled-components";

import { BikeBenefitOrderRead } from "@vapaus/api-codegen";
import { ActionCard, PedalBikeIcon } from "@vapaus/ui-v2";

import { useGetContract } from "../../../api/benefit";
import { useGetBenefitDefinition } from "../../../api/benefitDefinitions";
import { OrderStatus } from "../../../components/OrderStatus";

interface BikeCardProps {
  order: BikeBenefitOrderRead;
}

export function BikeCard({ order }: BikeCardProps) {
  const { data: benefitDefinition } = useGetBenefitDefinition(
    order.benefitDefinitionId,
  );
  const { data: contract, status } = useGetContract(
    order.currentBikeBenefitContractId,
  );

  const isContractReady =
    !order.currentBikeBenefitContractId || status === "success";

  return (
    <StyledLink to={order.id}>
      <ActionCard
        icon={PedalBikeIcon}
        firstLine={startCase(
          `${order.bike.bikeBrand.name} ${order.bike.model}`,
        )}
        // Use an invisible character if the data is not yet available so the height of the card doesn't change when it's available
        secondLine={benefitDefinition?.organisation.name ?? "‎"}
        afterContent={
          isContractReady && <OrderStatus order={order} contract={contract} />
        }
      />
    </StyledLink>
  );
}

const StyledLink = styled(Link as AnyStyledComponent)`
  width: 100%;
`;
