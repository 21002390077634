/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventCreate
 */
export interface ShopMaintenanceEventCreate {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventCreate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventCreate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventCreate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventCreate
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventCreate
   */
  bikeBenefitContractId: string;
}

/**
 * Check if a given object implements the ShopMaintenanceEventCreate interface.
 */
export function instanceOfShopMaintenanceEventCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "partsPrice" in value;
  isInstance = isInstance && "workPrice" in value;
  isInstance = isInstance && "workType" in value;
  isInstance = isInstance && "workDescription" in value;
  isInstance = isInstance && "workDate" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "bikeBenefitContractId" in value;

  return isInstance;
}

export function ShopMaintenanceEventCreateFromJSON(
  json: any,
): ShopMaintenanceEventCreate {
  return ShopMaintenanceEventCreateFromJSONTyped(json, false);
}

export function ShopMaintenanceEventCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    shopId: json["shop_id"],
    bikeBenefitContractId: json["bike_benefit_contract_id"],
  };
}

export function ShopMaintenanceEventCreateToJSON(
  value?: ShopMaintenanceEventCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parts_price: value.partsPrice,
    work_price: value.workPrice,
    work_type: WorkTypesToJSON(value.workType),
    work_description: value.workDescription,
    work_date: value.workDate.toISOString().substring(0, 10),
    shop_id: value.shopId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
  };
}
