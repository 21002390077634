/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface UserAdminCreate
 */
export interface UserAdminCreate {
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  city?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof UserAdminCreate
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof UserAdminCreate
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAdminCreate
   */
  fleetAccessCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminCreate
   */
  employeeNumber?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAdminCreate
   */
  benefitDefinitionAccessCodes?: Array<string>;
}

/**
 * Check if a given object implements the UserAdminCreate interface.
 */
export function instanceOfUserAdminCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "email" in value;

  return isInstance;
}

export function UserAdminCreateFromJSON(json: any): UserAdminCreate {
  return UserAdminCreateFromJSONTyped(json, false);
}

export function UserAdminCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAdminCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    password: !exists(json, "password") ? undefined : json["password"],
    address: !exists(json, "address") ? undefined : json["address"],
    postCode: !exists(json, "post_code") ? undefined : json["post_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    country: !exists(json, "country")
      ? undefined
      : SupportedCountriesEnumFromJSON(json["country"]),
    language: !exists(json, "language")
      ? undefined
      : SupportedLanguagesEnumFromJSON(json["language"]),
    fleetAccessCodes: !exists(json, "fleet_access_codes")
      ? undefined
      : json["fleet_access_codes"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    company: !exists(json, "company") ? undefined : json["company"],
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
    benefitDefinitionAccessCodes: !exists(
      json,
      "benefit_definition_access_codes",
    )
      ? undefined
      : json["benefit_definition_access_codes"],
  };
}

export function UserAdminCreateToJSON(value?: UserAdminCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    address: value.address,
    post_code: value.postCode,
    city: value.city,
    country: SupportedCountriesEnumToJSON(value.country),
    language: SupportedLanguagesEnumToJSON(value.language),
    fleet_access_codes: value.fleetAccessCodes,
    first_name: value.firstName,
    last_name: value.lastName,
    phone_number: value.phoneNumber,
    company: value.company,
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
    benefit_definition_access_codes: value.benefitDefinitionAccessCodes,
  };
}
