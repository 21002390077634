import { Controller, useFormContext } from "react-hook-form";

import {
  Checkbox as UICheckbox,
  CheckboxProps as UICheckboxProps,
} from "@vapaus/ui-v2";

interface Checkbox extends UICheckboxProps {
  name: string;
}

export function Checkbox({
  name,
  onChange,
  onBlur,
  required,
  ...rest
}: Checkbox) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required, onChange, onBlur }}
      render={({ field }) => (
        <UICheckbox
          checked={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          required={required}
          {...rest}
        />
      )}
    />
  );
}
