/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  Msg,
  PasswordResetToken,
  Token,
  UserCreate,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MsgFromJSON,
  MsgToJSON,
  PasswordResetTokenFromJSON,
  PasswordResetTokenToJSON,
  TokenFromJSON,
  TokenToJSON,
  UserCreateFromJSON,
  UserCreateToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface AuthRecoverPasswordRequest {
  email: string;
}

export interface AuthResetPasswordRequest {
  email: string;
  passwordResetToken: PasswordResetToken;
}

export interface AuthSignInRequest {
  username: string;
  password: string;
  grantType?: string;
  scope?: string;
  clientId?: string;
  clientSecret?: string;
}

export interface AuthSignUpRequest {
  userCreate: UserCreate;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * We always send a successful response even if the email is not found for security reason.
   * Recover Password
   */
  async authRecoverPasswordRaw(
    requestParameters: AuthRecoverPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling authRecoverPassword.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/auth/password-recovery/{email}`.replace(
          `{${"email"}}`,
          encodeURIComponent(String(requestParameters.email)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * We always send a successful response even if the email is not found for security reason.
   * Recover Password
   */
  async authRecoverPassword(
    requestParameters: AuthRecoverPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.authRecoverPasswordRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Reset Password
   */
  async authResetPasswordRaw(
    requestParameters: AuthResetPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Token>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling authResetPassword.",
      );
    }

    if (
      requestParameters.passwordResetToken === null ||
      requestParameters.passwordResetToken === undefined
    ) {
      throw new runtime.RequiredError(
        "passwordResetToken",
        "Required parameter requestParameters.passwordResetToken was null or undefined when calling authResetPassword.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/auth/reset-password/{email}`.replace(
          `{${"email"}}`,
          encodeURIComponent(String(requestParameters.email)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PasswordResetTokenToJSON(requestParameters.passwordResetToken),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenFromJSON(jsonValue),
    );
  }

  /**
   * Reset Password
   */
  async authResetPassword(
    requestParameters: AuthResetPasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Token> {
    const response = await this.authResetPasswordRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Sign In
   */
  async authSignInRaw(
    requestParameters: AuthSignInRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Token>> {
    if (
      requestParameters.username === null ||
      requestParameters.username === undefined
    ) {
      throw new runtime.RequiredError(
        "username",
        "Required parameter requestParameters.username was null or undefined when calling authSignIn.",
      );
    }

    if (
      requestParameters.password === null ||
      requestParameters.password === undefined
    ) {
      throw new runtime.RequiredError(
        "password",
        "Required parameter requestParameters.password was null or undefined when calling authSignIn.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const consumes: runtime.Consume[] = [
      { contentType: "application/x-www-form-urlencoded" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.grantType !== undefined) {
      formParams.append("grant_type", requestParameters.grantType as any);
    }

    if (requestParameters.username !== undefined) {
      formParams.append("username", requestParameters.username as any);
    }

    if (requestParameters.password !== undefined) {
      formParams.append("password", requestParameters.password as any);
    }

    if (requestParameters.scope !== undefined) {
      formParams.append("scope", requestParameters.scope as any);
    }

    if (requestParameters.clientId !== undefined) {
      formParams.append("client_id", requestParameters.clientId as any);
    }

    if (requestParameters.clientSecret !== undefined) {
      formParams.append("client_secret", requestParameters.clientSecret as any);
    }

    const response = await this.request(
      {
        path: `/api/auth/sign-in`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenFromJSON(jsonValue),
    );
  }

  /**
   * Sign In
   */
  async authSignIn(
    requestParameters: AuthSignInRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Token> {
    const response = await this.authSignInRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Sign Up
   */
  async authSignUpRaw(
    requestParameters: AuthSignUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Token>> {
    if (
      requestParameters.userCreate === null ||
      requestParameters.userCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "userCreate",
        "Required parameter requestParameters.userCreate was null or undefined when calling authSignUp.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/auth/sign-up`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserCreateToJSON(requestParameters.userCreate),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenFromJSON(jsonValue),
    );
  }

  /**
   * Sign Up
   */
  async authSignUp(
    requestParameters: AuthSignUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Token> {
    const response = await this.authSignUpRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
