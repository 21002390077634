import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserBenefitDefinitionAccessRequestRead } from "@vapaus/api-codegen";
import { Card3DIcon, Flex, Typography } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";
import { AccessRequestListItem } from "./AccessRequestListItem";

type PendingRequestPageProps = {
  accessRequest: UserBenefitDefinitionAccessRequestRead;
};

export function PendingRequestPage({ accessRequest }: PendingRequestPageProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" align="center" gap="md">
      <Card3DIcon size="lg" />
      <Flex direction="column" align="center" gap="md">
        <Flex direction="column" align="center" gap="sm">
          <Typography variant="h1" align="center">
            {t("userV2:benefits.pendingRequest.title")}
          </Typography>
          <Typography variant="bodyLg" align="center">
            <Trans
              i18nKey="userV2:benefits.pendingRequest.description"
              components={{ b: <b /> }}
            />
          </Typography>
        </Flex>
        <AccessRequestListItem accessRequest={accessRequest} />
        <Typography align="center">
          <Trans i18nKey="userV2:benefits.pendingRequest.didYouLinkWrongBenefit">
            Did you link the wrong benefit?
            <Link to={`/${Path.addNewBenefit}`}>Link another benefit</Link>`
          </Trans>
        </Typography>
      </Flex>
    </Flex>
  );
}
