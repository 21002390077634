import { BikeBrand } from "@vapaus/api-codegen";
import { Typography } from "@vapaus/ui-v2";

type ShopBrandsProps = {
  brands: BikeBrand[];
};

export function ShopBrands({ brands = [] }: ShopBrandsProps) {
  const brandNames = brands.map((brand) => brand.name).join(", ");
  return (
    <Typography variant="bodySm" align="right" noWrap>
      {brandNames}
    </Typography>
  );
}
