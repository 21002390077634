import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import { BikeBenefitContractRead, PaymentStatus } from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import { useGetInvoice } from "../../../../../api/invoice";
import {
  useSearchLatestPaymentWithInvoiceId,
  useVismaPayCallback,
} from "../../../../../api/paymentConsent";
import { useGetRedemptionProcess } from "../../../../../api/redemptionProcess";
import { useGetReturnProcess } from "../../../../../api/returnProcess";
import { Path } from "../../../../../constants/paths";

export function usePaymentProcessCallback(
  cancellationType: "redemption" | "return",
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const { contract, handleOpenPaymentErrorModalFor } = useOutletContext<{
    contract: BikeBenefitContractRead;
    handleOpenPaymentErrorModalFor: (
      cancellationType: "redemption" | "return",
    ) => void;
  }>();

  const [currentRedemptionProcessId, setCurrentRedemptionProcessId] = useState(
    cancellationType === "redemption"
      ? contract.currentRedemptionProcessId
      : undefined,
  );
  const [currentReturnProcessId, setCurrentReturnProcessId] = useState(
    cancellationType === "return" ? contract.currentReturnProcessId : undefined,
  );

  const { data: redemptionProcess } = useGetRedemptionProcess(
    currentRedemptionProcessId,
  );

  const { data: returnProcess } = useGetReturnProcess(currentReturnProcessId);

  const vismaPayInvoiceId =
    cancellationType === "redemption"
      ? redemptionProcess?.saleInvoiceId
      : returnProcess?.saleInvoiceId;
  const { data: vismaPayInvoice } = useGetInvoice(vismaPayInvoiceId);

  const { data: invoicePayment } = useSearchLatestPaymentWithInvoiceId(
    vismaPayInvoice?.id,
  );

  const latestPayment = invoicePayment?.items?.[0];
  const vismaPayCallback = useVismaPayCallback();

  const goToContract = useCallback(() => {
    setCurrentRedemptionProcessId(undefined);
    setCurrentReturnProcessId(undefined);
    navigate(`/${Path.myBikes}/${contract.bikeBenefitOrderId}`, {
      replace: true,
      state: { paymentResumePath: location.pathname },
    });
  }, [contract.bikeBenefitOrderId, location.pathname, navigate]);

  useEffect(() => {
    if (searchParams.get("ORDER_NUMBER")) {
      vismaPayCallback.mutate(
        {
          oRDERNUMBER: searchParams.get("ORDER_NUMBER") || undefined,
          rETURNCODE: searchParams.get("RETURN_CODE") || undefined,
          sETTLED: searchParams.get("SETTLED") || undefined,
          iNCIDENTID: searchParams.get("INCIDENT_ID") || undefined,
          aUTHCODE: searchParams.get("AUTHCODE") || undefined,
        },
        {
          onSettled: () => {
            queryClient.invalidateQueries(["invoices"]);
            queryClient.invalidateQueries(["invoicePayments"]);
          },
        },
      );
      setSearchParams();
    }
  }, [
    setSearchParams,
    queryClient,
    searchParams,
    vismaPayCallback,
    vismaPayInvoice,
  ]);

  useEffect(() => {
    if (!latestPayment) return;
    const paymentErrors = [
      PaymentStatus.Failed,
      PaymentStatus.Cancelled,
      PaymentStatus.Unknown,
    ];

    if ((paymentErrors as PaymentStatus[]).includes(latestPayment.status)) {
      handleOpenPaymentErrorModalFor(cancellationType);
      goToContract();
    } else if (latestPayment.status === PaymentStatus.Charged) {
      toaster.add("success", t("userV2:billingTable.paymentSuccessful"));
      goToContract();
    }

    return () => {
      queryClient.removeQueries({ queryKey: ["invoicePayments"] });
    };
  }, [
    cancellationType,
    currentRedemptionProcessId,
    currentReturnProcessId,
    latestPayment,
    goToContract,
    handleOpenPaymentErrorModalFor,
    t,
    toaster,
    queryClient,
  ]);
}
