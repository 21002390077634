import { useTranslation } from "react-i18next";

import { useCurrentUserEmails } from "@vapaus/shared-api";
import { Button, Flex, Paper, Typography } from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../WizardPageViewLayout";
import { AddNewEmailButton } from "./components/AddNewEmailButton";

type EmailAddressViewProps = {
  onNext: () => void;
  emailVerificationResumePath: string;
};

export function EmailAddressView({
  onNext,
  emailVerificationResumePath,
}: EmailAddressViewProps) {
  const { t } = useTranslation();
  const { data: userEmails } = useCurrentUserEmails();
  return (
    <WizardPageViewLayout
      title={t("userV2:redeemReturnSharedComponents.emailAddress.title")}
      description={t(
        "userV2:redeemReturnSharedComponents.emailAddress.description",
      )}
    >
      <Paper shadow="md">
        <Flex direction="column" align="center" gap="lg">
          <Flex direction="column" align="center" gap="sm">
            <Typography weight="bold" align="center">
              {t(
                "userV2:redeemReturnSharedComponents.emailAddress.linkedEmail",
              )}
            </Typography>
            <Flex direction="column" justify="center" gap="xs">
              {(userEmails?.items ?? []).map((email) => (
                <Typography key={email.id} align="center">
                  {email.email}{" "}
                  {!email.emailVerified
                    ? t(
                        "userV2:redeemReturnSharedComponents.emailAddress.unverified",
                      )
                    : ""}{" "}
                </Typography>
              ))}
            </Flex>
          </Flex>
          <Flex direction="column" align="center" fullWidth>
            <AddNewEmailButton
              emailVerificationResumePath={emailVerificationResumePath}
            />
            <Button onClick={onNext} fullWidth>
              {t("commonV2:continue")}
            </Button>
          </Flex>
          <Typography align="center">
            {t("userV2:redeemReturnSharedComponents.emailAddress.footerNote")}
          </Typography>
        </Flex>
      </Paper>
    </WizardPageViewLayout>
  );
}
