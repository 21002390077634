import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";

import { debounce, isEmpty } from "lodash-es";
import styled, { AnyStyledComponent } from "styled-components";

import { CircularButton } from "../CircularButton";
import { CloseIcon } from "../Icons";
import { SearchIcon } from "../Icons/SearchIcon";
import { BaseInput } from "../Input";

export type SearchBarProps = {
  placeholder: string;
  onSearch: (value: string) => void;
  searchOnClick?: boolean;
  allowEmptySearch?: boolean;
  disabled?: boolean;
  className?: string;
  defaultValue?: string;
  fullWidth?: boolean;
};

export function SearchBar({
  placeholder,
  onSearch,
  searchOnClick = false,
  allowEmptySearch = true,
  disabled = false,
  fullWidth,
  className,
  defaultValue = "",
}: SearchBarProps) {
  const [value, setValue] = useState<string>(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onSearchDebounced = useMemo(
    () => debounce((value: string) => onSearch(value), 500),
    [onSearch],
  );

  const clearValue = () => {
    setValue("");
    onSearch("");
    inputRef.current?.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    if (!searchOnClick) {
      onSearchDebounced(value);
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSearch(value);
    }
  };

  const shouldDisableSearch = !allowEmptySearch && isEmpty(value);

  return (
    <BaseInput
      className={className}
      ref={inputRef}
      value={value}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      onKeyDown={
        searchOnClick && !shouldDisableSearch
          ? (event) => handleEnter(event)
          : undefined
      }
      startAdornment={<SearchIcon />}
      endAdornment={
        <StyledAdornment>
          {value ? (
            <CircularButton
              icon={<CloseIcon />}
              size="md"
              onClick={clearValue}
            />
          ) : null}
          {searchOnClick ? (
            <StyledCircularButton
              data-testid="searchButton"
              icon={<SearchIcon />}
              variant="secondary"
              size="md"
              disabled={shouldDisableSearch}
              onClick={() => onSearch(value)}
            />
          ) : null}
        </StyledAdornment>
      }
    />
  );
}

const StyledAdornment = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledCircularButton = styled(CircularButton as AnyStyledComponent)`
  margin-right: -1rem;
`;
