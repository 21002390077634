import { get } from "lodash-es";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { Input as UIInput, InputProps as UIInputProps } from "@vapaus/ui-v2";

export type InputProps = Omit<UIInputProps, "error" | "pattern"> &
  Pick<
    RegisterOptions,
    "validate" | "pattern" | "valueAsNumber" | "valueAsDate"
  > & {
    name: string;
  };

export function Input({
  name,
  required,
  min,
  max,
  maxLength,
  minLength,
  validate,
  value,
  onChange,
  onBlur,
  pattern,
  valueAsNumber,
  valueAsDate,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  // Annotation needed because typescript doesn't seem to get the type right somehow ¯\_(ツ)_/¯
  const errorMessage = get(errors, name)?.message as string | undefined;

  return (
    <UIInput
      {...register(name, {
        required,
        min,
        max,
        maxLength,
        minLength,
        validate,
        value,
        onChange,
        onBlur,
        // Typescript is not happy with the type somehow
        /* eslint-disable @typescript-eslint/no-explicit-any */
        pattern: pattern as any,
        valueAsNumber: valueAsNumber as any,
        valueAsDate: valueAsDate as any,
        /* eslint-enable @typescript-eslint/no-explicit-any */
      })}
      error={errorMessage}
      {...rest}
    />
  );
}
