/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitContractRead } from "./BikeBenefitContractRead";
import {
  BikeBenefitContractReadFromJSON,
  BikeBenefitContractReadFromJSONTyped,
  BikeBenefitContractReadToJSON,
} from "./BikeBenefitContractRead";

/**
 *
 * @export
 * @interface PaginatedBikeBenefitContractOut
 */
export interface PaginatedBikeBenefitContractOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBikeBenefitContractOut
   */
  total: number;
  /**
   *
   * @type {Array<BikeBenefitContractRead>}
   * @memberof PaginatedBikeBenefitContractOut
   */
  items: Array<BikeBenefitContractRead>;
}

/**
 * Check if a given object implements the PaginatedBikeBenefitContractOut interface.
 */
export function instanceOfPaginatedBikeBenefitContractOut(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedBikeBenefitContractOutFromJSON(
  json: any,
): PaginatedBikeBenefitContractOut {
  return PaginatedBikeBenefitContractOutFromJSONTyped(json, false);
}

export function PaginatedBikeBenefitContractOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBikeBenefitContractOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BikeBenefitContractReadFromJSON),
  };
}

export function PaginatedBikeBenefitContractOutToJSON(
  value?: PaginatedBikeBenefitContractOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(BikeBenefitContractReadToJSON),
  };
}
