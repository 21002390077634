import { memo } from "react";

import styled from "styled-components";

export const iconSizes = {
  lg: "200px",
  md: "128px",
  sm: "96px",
};

export type IconSizeType = keyof typeof iconSizes;

export type Icon3DProps = {
  className?: string;
  size?: IconSizeType;
};

const Image = styled.img<{
  size?: IconSizeType;
}>`
  width: ${({ size = "sm" }) => iconSizes[size]};
  height: ${({ size = "sm" }) => iconSizes[size]};
`;

export function create3DIcon(path: string, displayName: string) {
  function Component(props: Icon3DProps) {
    return (
      <Image
        data-testid={`${displayName}3DIcon`}
        {...props}
        src={path}
        alt={displayName}
      />
    );
  }

  if (process.env.NODE_ENV !== "production") {
    Component.displayName = `${displayName}Icon`;
  }

  return memo(Component);
}
