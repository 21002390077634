import { useTranslation } from "react-i18next";

import { ArrowBackIcon, Flex, TextButton } from "@vapaus/ui-v2";

import { useBackButton } from "./useBackButton";

type BackButtonProps = {
  label?: string;
  to?: string;
};

export function BackButton({ label, to }: BackButtonProps) {
  const { t } = useTranslation();
  const handleOnBack = useBackButton();
  const buttonText = label ?? t("commonV2:back");

  const navProps = to ? { to } : { onClick: handleOnBack };

  return (
    <TextButton size="md" {...navProps}>
      <Flex gap="xs">
        <ArrowBackIcon size="sm" />
        <span>{buttonText}</span>
      </Flex>
    </TextButton>
  );
}
