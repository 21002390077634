import { useTranslation } from "react-i18next";

import { AnimatedErrorIcon, Flex, Typography } from "@vapaus/ui-v2";

export function InvoicePaymentErrorView() {
  const { t } = useTranslation();
  return (
    <Flex direction="column" align="center" gap="none">
      <AnimatedErrorIcon />
      <Typography variant="h2" align="center" marginBottom="sm">
        {t("userV2:billingTable.unableToPayInvoice")}
      </Typography>
      <Typography variant="bodyLg" align="center">
        {t("userV2:billingTable.unableToPayMessage")}
      </Typography>
    </Flex>
  );
}
