/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  Enum,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
} from "../models/index";
import {
  EnumFromJSON,
  EnumToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models/index";
import * as runtime from "../runtime";

/**
 *
 */
export class ConstantsApi extends runtime.BaseAPI {
  /**
   * Get Enums
   */
  async constantsGetEnumsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Enum>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/enums`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(EnumFromJSON),
    );
  }

  /**
   * Get Enums
   */
  async constantsGetEnums(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Enum>> {
    const response = await this.constantsGetEnumsRaw(initOverrides);
    return await response.value();
  }
}
