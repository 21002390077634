import { createSvgIcon } from "./SvgIcon";

export const HighlightIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4.95017 7.325L4.22517 6.6C4.0085 6.4 3.896 6.16667 3.88767 5.9C3.87934 5.63333 3.9835 5.4 4.20017 5.2C4.40017 5 4.63767 4.89583 4.91267 4.8875C5.18767 4.87917 5.42517 4.975 5.62517 5.175L6.35017 5.9C6.5335 6.08333 6.62934 6.3125 6.63767 6.5875C6.646 6.8625 6.55017 7.1 6.35017 7.3C6.16684 7.48333 5.93767 7.57917 5.66267 7.5875C5.38767 7.59583 5.15017 7.50833 4.95017 7.325ZM12.0002 5C11.7168 5 11.4793 4.90417 11.2877 4.7125C11.096 4.52083 11.0002 4.28333 11.0002 4V3C11.0002 2.71667 11.096 2.47917 11.2877 2.2875C11.4793 2.09583 11.7168 2 12.0002 2C12.2835 2 12.521 2.09583 12.7127 2.2875C12.9043 2.47917 13.0002 2.71667 13.0002 3V4C13.0002 4.28333 12.9043 4.52083 12.7127 4.7125C12.521 4.90417 12.2835 5 12.0002 5ZM17.7002 7.325C17.5002 7.125 17.4002 6.88333 17.4002 6.6C17.4002 6.31667 17.5002 6.075 17.7002 5.875L18.4002 5.175C18.5835 4.99167 18.8127 4.9 19.0877 4.9C19.3627 4.9 19.6002 5 19.8002 5.2C19.9835 5.38333 20.0752 5.61667 20.0752 5.9C20.0752 6.18333 19.9835 6.41667 19.8002 6.6L19.1002 7.3C18.9002 7.5 18.6668 7.60417 18.4002 7.6125C18.1335 7.62083 17.9002 7.525 17.7002 7.325ZM11.0002 22C10.4502 22 9.97934 21.8042 9.58767 21.4125C9.196 21.0208 9.00017 20.55 9.00017 20V17L6.57517 14.575C6.39184 14.3917 6.25017 14.1792 6.15017 13.9375C6.05017 13.6958 6.00017 13.4417 6.00017 13.175V10C6.00017 9.71667 6.096 9.47917 6.28767 9.2875C6.47934 9.09583 6.71684 9 7.00017 9H17.0002C17.2835 9 17.521 9.09583 17.7127 9.2875C17.9043 9.47917 18.0002 9.71667 18.0002 10V13.175C18.0002 13.4417 17.9502 13.6958 17.8502 13.9375C17.7502 14.1792 17.6085 14.3917 17.4252 14.575L15.0002 17V20C15.0002 20.55 14.8043 21.0208 14.4127 21.4125C14.021 21.8042 13.5502 22 13.0002 22H11.0002ZM11.0002 20H13.0002V16.175L16.0002 13.175V11H8.00017V13.175L11.0002 16.175V20Z" />
  </svg>,
  "Highlight",
);
