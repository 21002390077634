import { useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { AuthRecoverPasswordRequest, Msg } from "@vapaus/api-codegen";
import { UseFormMutationReturn, useFormMutation } from "@vapaus/form";

import { authApi } from "../../api";

export type ForgotPasswordDataType = {
  form: UseFormMutationReturn<AuthRecoverPasswordRequest, Msg>;
  resetEmailSent: boolean;
};

export function useForgotPasswordPage() {
  const [resetEmailSent, setResetEmailSent] = useState<boolean>(false);

  const { t } = useTranslation();

  const forgotPasswordSchema = useMemo(
    () =>
      zod.object({
        email: zod
          .string()
          .nonempty(t("authV2:signIn.enterEmail"))
          .email(t("authV2:signIn.enterValidEmail")),
      }),
    [t],
  );

  type ForgotPasswordType = zod.infer<typeof forgotPasswordSchema>;

  const form = useFormMutation<ForgotPasswordType, Msg>(
    {
      mutationFn: (data) => authApi.authRecoverPassword(data),
      onSuccess() {
        setResetEmailSent(true);
      },
    },
    { resolver: zodResolver(forgotPasswordSchema) },
  );

  return { form, resetEmailSent };
}
