import { TFunction } from "i18next";
import { startCase } from "lodash-es";

import {
  ApiError,
  HTTPValidationError,
  ValidationError,
} from "@vapaus/generated";

// TODO: it would be great if we could have the ApiException and ErrorCode automatically generated
export enum ErrorCode {
  INTEGRITY_ERROR = "INTEGRITY_ERROR",
  USER_TERMS_NOT_ACCEPTED = "USER_TERMS_NOT_ACCEPTED",
  SHOP_TERMS_NOT_ACCEPTED = "SHOP_TERMS_NOT_ACCEPTED",
  PROVIDER_TERMS_NOT_ACCEPTED = "PROVIDER_TERMS_NOT_ACCEPTED",
  USER_EMAIL_AlREADY_EXISTS_SAME_ACCOUNT = "USER_EMAIL_AlREADY_EXISTS_SAME_ACCOUNT",
  CALCULATION_MAINTENANCE_BUDGET_TOO_HIGH = "CALCULATION_MAINTENANCE_BUDGET_TOO_HIGH",
}

type ApiException = {
  code?: ErrorCode;
  detail: string;
};

type ErrorBody = ValidationError | HTTPValidationError | ApiException;

export const parseApiError = (error_: ApiError, t: TFunction): string => {
  const error = error_.body as ErrorBody;
  if (error) {
    if ("msg" in error) return error.msg;
    if (typeof error.detail === "string") return error.detail;
    if (Array.isArray(error.detail))
      return `${startCase(error.detail[0].loc[1] as string)}: ${
        error.detail[0].msg
      }`;
  }

  return t("common:error.genericMessage");
};
