import React from "react";

import { orderBy } from "lodash-es";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  UserBenefitActivationRead,
  UserBenefitDefinitionAccessRequestRead,
} from "@vapaus/api-codegen";
import { BenefitDefinitionAccessRequestStatus } from "@vapaus/generated";
import { Card3DIcon, Flex, Typography } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";
import { AccessRequestListItem } from "./AccessRequestListItem";
import { ActivationListItem } from "./ActivationListItem";

type BenefitsListPageProps = {
  accessRequests: Array<UserBenefitDefinitionAccessRequestRead>;
  activations: Array<UserBenefitActivationRead>;
};

export function BenefitsListPage({
  accessRequests,
  activations,
}: BenefitsListPageProps) {
  const { t } = useTranslation();

  const benefits = orderBy(
    [
      ...accessRequests.filter(
        (accessRequest) =>
          accessRequest.status !==
            BenefitDefinitionAccessRequestStatus.ACCEPTED &&
          accessRequest.status !==
            BenefitDefinitionAccessRequestStatus.CANCELLED,
      ),
      ...activations,
    ],
    "updated_at",
    "desc",
  );

  return (
    <Flex direction="column" align="center" gap="lg">
      <Card3DIcon size="lg" />
      <Flex direction="column" align="center" gap="sm">
        <Typography variant="h1" align="center">
          {t("userV2:benefits.benefitsList.title")}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {t("userV2:benefits.benefitsList.description")}
        </Typography>
      </Flex>
      <Typography align="center">
        <Link to={`/${Path.addNewBenefit}`}>
          {t("userV2:benefits.benefitsList.linkBenefit")}
        </Link>
      </Typography>
      <Flex direction="column" align="center" gap="md" fullWidth>
        {benefits.map((benefit) =>
          isAccessRequest(benefit) ? (
            <AccessRequestListItem key={benefit.id} accessRequest={benefit} />
          ) : (
            <ActivationListItem key={benefit.id} activation={benefit} />
          ),
        )}
      </Flex>
      <Typography align="center">
        <Trans i18nKey="userV2:benefits.benefitsList.dontSeeYourBenefit">
          Don't see your benefit here?
          <Link to={`/${Path.addNewBenefit}`}>Link another benefit</Link>`
        </Trans>
      </Typography>
    </Flex>
  );
}

function isAccessRequest(
  benefit: UserBenefitDefinitionAccessRequestRead | UserBenefitActivationRead,
): benefit is UserBenefitDefinitionAccessRequestRead {
  return "status" in benefit;
}
