import { ReactNode, forwardRef } from "react";

import { FieldValues, FormProvider } from "react-hook-form";
import styled from "styled-components";

import { FormHandleSubmit, FormMethods } from "./useFormMutation";

// override forwardRef to allow it to work with generic in this case
// this allows us to do something like <Form<SomeFormType> while still forwarding the ref
declare module "react" {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

export interface FormProps<TData extends FieldValues = FieldValues> {
  children: ReactNode;
  formMethods: FormMethods<TData>;
  name?: string;
  onSubmit?: FormHandleSubmit;
}

function FormInner<TData extends FieldValues = FieldValues>(
  { children, formMethods, name, onSubmit }: FormProps<TData>,
  ref: React.ForwardedRef<HTMLFormElement>,
) {
  const { _handleSubmit, ...formMethodsOnly } = formMethods;
  return (
    <FormProvider {...formMethodsOnly}>
      <StyledForm
        onSubmit={onSubmit ?? _handleSubmit}
        ref={ref}
        name={name}
        role="form"
      >
        {children}
      </StyledForm>
    </FormProvider>
  );
}

export const Form = forwardRef(FormInner);

const StyledForm = styled.form`
  width: 100%;
`;
