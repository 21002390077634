import { sum } from "lodash-es";

import { BikeBenefitOrderRead } from "@vapaus/api-codegen";

export function getOrderTotal(order: BikeBenefitOrderRead): number {
  return (
    order.bikePrice +
    sum(
      order.accessories
        .map((accessory) => accessory.purchasePrice)
        .filter((price) => Boolean(price)),
    ) +
    sum(
      order.additionalServices
        .map((additionalService) => additionalService.priceGross)
        .filter((price) => Boolean(price)),
    ) +
    (order.deliveryTransport?.priceGross || 0)
  );
}
