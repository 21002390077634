import React, { ReactElement, ReactNode } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  BenefitDefinitionAccessRequestStatus,
  BenefitDefinitionUserRead,
} from "@vapaus/api-codegen";
import { Hero } from "@vapaus/common-v2";
import { OrderApproveIcon, PersonIcon, Typography } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";
import { getBenefitStatusTag } from "../../../utils/getBenefitStatusTag";

type BenefitHeroProps = {
  benefitId: string;
  benefitDefinition?: BenefitDefinitionUserRead;
  status?: BenefitDefinitionAccessRequestStatus;
  isActivationActive?: boolean;
  businessId?: string;
};

export function BenefitHero({
  benefitId,
  benefitDefinition,
  status,
  isActivationActive,
  businessId,
}: BenefitHeroProps) {
  const { t } = useTranslation();

  return (
    <Hero
      background={"background.white"}
      title={benefitDefinition?.organisation.name ?? businessId ?? "-"}
      inBetweenContent={
        <StyledSubtitleWrapper>
          <Typography weight="bold">
            {benefitDefinition?.name ?? t("userV2:benefit.noBenefitLinked")}
          </Typography>
        </StyledSubtitleWrapper>
      }
      {...getBenefitHeroProps({
        t,
        status,
        isActivationActive,
        isBenefitDefinitionActive: benefitDefinition?.active ?? true,
      })}
      backButtonText={t("userV2:benefit.backToBenefits")}
      backButtonPath="../.."
      tabs={isActivationActive !== undefined ? getTabs(t, benefitId) : []}
    />
  );
}

interface GetBenefitHeroProps {
  t: TFunction;
  status?: BenefitDefinitionAccessRequestStatus;
  isActivationActive?: boolean;
  isBenefitDefinitionActive: boolean;
}

interface GetBenefitHeroPropsReturn {
  description: ReactElement | string;
  beforeContent?: ReactNode;
}

function getBenefitHeroProps({
  t,
  status,
  isActivationActive,
  isBenefitDefinitionActive,
}: GetBenefitHeroProps): GetBenefitHeroPropsReturn {
  if (!isBenefitDefinitionActive) {
    return {
      description: t("userV2:benefit.description.deactivated"),
      beforeContent: getBenefitStatusTag({
        t,
        size: "large",
        isBenefitDefinitionActive: isBenefitDefinitionActive,
        ...(status
          ? {
              status: status,
              isActivationActive: undefined,
            }
          : {
              isActivationActive: isActivationActive!,
              status: undefined,
            }),
      }),
    };
  }

  switch (status) {
    case "pending":
      return {
        description: t("userV2:benefit.description.pending"),
        beforeContent: getBenefitStatusTag({
          t,
          size: "large",
          status: status,
          isBenefitDefinitionActive: isBenefitDefinitionActive,
        }),
      };
    case "denied":
      return {
        description: t("userV2:benefit.description.denied"),
        beforeContent: getBenefitStatusTag({
          t,
          size: "large",
          status: status,
          isBenefitDefinitionActive: isBenefitDefinitionActive,
        }),
      };
    case "expired":
      return {
        description: t("userV2:benefit.description.expired"),
        beforeContent: getBenefitStatusTag({
          t,
          size: "large",
          status: status,
          isBenefitDefinitionActive: isBenefitDefinitionActive,
        }),
      };
  }

  return {
    description: isActivationActive
      ? t("userV2:benefit.description.approved")
      : t("userV2:benefit.description.revoked"),
    beforeContent: getBenefitStatusTag({
      t,
      size: "large",
      isActivationActive: isActivationActive!,
      isBenefitDefinitionActive: isBenefitDefinitionActive,
    }),
  };
}

function getTabs(t: TFunction, benefitId: string) {
  return [
    {
      icon: OrderApproveIcon,
      label: t("userV2:benefit.tabs.benefitGuideline.tabName"),
      to: Path.benefitGuideline,
    },
    {
      icon: PersonIcon,
      label: t("userV2:benefit.tabs.employmentDetails.tabName"),
      to: `../${benefitId}`,
    },
  ];
}

const StyledSubtitleWrapper = styled.div`
  margin-top: -12px;
`;
