import styled from "styled-components";

import { CircularButton, CloseIcon, FullPageModal } from "@vapaus/ui-v2";

type FullPagePhotoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  src: string;
};

export function FullPagePhotoModal({
  isOpen,
  onClose,
  src,
}: FullPagePhotoModalProps) {
  return (
    <FullPageModal isOpen={isOpen} hideNavigation>
      <img src={src} alt="photoPreview" width="100%" />
      <StyledCloseIconContainer>
        <CircularButton
          variant="outlined"
          icon={<CloseIcon />}
          onClick={onClose}
        />
      </StyledCloseIconContainer>
    </FullPageModal>
  );
}

const StyledCloseIconContainer = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacing.md};
  right: ${({ theme }) => theme.spacing.md};
`;
