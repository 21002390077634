/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrganisationRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrganisationReadFromJSON,
  OrganisationReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopOrganisationGetOrganisationRequest {
  organisationId: string;
}

/**
 *
 */
export class ShopOrganisationApi extends runtime.BaseAPI {
  /**
   * Get Organisation
   */
  async shopOrganisationGetOrganisationRaw(
    requestParameters: ShopOrganisationGetOrganisationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrganisationRead>> {
    if (
      requestParameters.organisationId === null ||
      requestParameters.organisationId === undefined
    ) {
      throw new runtime.RequiredError(
        "organisationId",
        "Required parameter requestParameters.organisationId was null or undefined when calling shopOrganisationGetOrganisation.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/organisations/{organisation_id}`.replace(
          `{${"organisation_id"}}`,
          encodeURIComponent(String(requestParameters.organisationId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganisationReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Organisation
   */
  async shopOrganisationGetOrganisation(
    requestParameters: ShopOrganisationGetOrganisationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrganisationRead> {
    const response = await this.shopOrganisationGetOrganisationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
