import { useTranslation } from "react-i18next";

import { Path } from "../../../constants/paths";
import { OnboardingFinalStepPageLayout } from "../layout/OnboardingFinalStepPageLayout";

export function ActivationCreatedPage() {
  const { t } = useTranslation();

  return (
    <OnboardingFinalStepPageLayout
      title={t("userV2:onboarding.activationCreated.title")}
      subtitle={t("userV2:onboarding.activationCreated.subtitle")}
      nextButtonText={t("userV2:onboarding.activationCreated.continue")}
      nextButtonPath={`/${Path.myBikes}`}
    />
  );
}
