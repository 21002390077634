import {
  BenefitDefinitionSummaryRead,
  BikeBenefitOrderRead,
} from "@vapaus/api-codegen";
import { OrderCalculationParams } from "@vapaus/shared-api";

import { getOrderTotal } from "./getOrderTotal";

export function getOrderCalculationParams(
  order: BikeBenefitOrderRead,
  benefitDefinition?: BenefitDefinitionSummaryRead,
): OrderCalculationParams {
  return {
    plan: benefitDefinition?.plan,
    benefitDefinitionId: order.benefitDefinitionId,
    monthlyMaintenanceBudget: order.monthlyMaintenanceBudget,
    totalPackagePrice: getOrderTotal(order),
    hasFixedTaxableValue: Boolean(order.fixedMonthlyTaxableValue),
    fixedMonthlyTaxableValue: order.fixedMonthlyTaxableValue,
    fixedDownPaymentAmount: order.fixedDownPaymentAmount,
    leasingPeriodMonths: order.leasingPeriodMonths,
    contractCorrection: order.correctsBikeBenefitContractId
      ? { correctedContractId: order.correctsBikeBenefitContractId }
      : undefined,
    contractRevision:
      order.revisesBikeBenefitContractId && order.revisionApplyDate
        ? {
            revisedContractId: order.revisesBikeBenefitContractId,
            revisionApplyDate: order.revisionApplyDate,
          }
        : undefined,
  };
}
