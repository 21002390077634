/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BenefitDefinitionAccessRequestStatus = {
  Accepted: "accepted",
  Cancelled: "cancelled",
  Denied: "denied",
  Expired: "expired",
  Pending: "pending",
  Invalid: "invalid",
} as const;
export type BenefitDefinitionAccessRequestStatus =
  (typeof BenefitDefinitionAccessRequestStatus)[keyof typeof BenefitDefinitionAccessRequestStatus];

export function BenefitDefinitionAccessRequestStatusFromJSON(
  json: any,
): BenefitDefinitionAccessRequestStatus {
  return BenefitDefinitionAccessRequestStatusFromJSONTyped(json, false);
}

export function BenefitDefinitionAccessRequestStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRequestStatus {
  return json as BenefitDefinitionAccessRequestStatus;
}

export function BenefitDefinitionAccessRequestStatusToJSON(
  value?: BenefitDefinitionAccessRequestStatus | null,
): any {
  return value as any;
}
