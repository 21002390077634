import React, {
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { get } from "lodash-es";
import styled, { useTheme } from "styled-components";

import { CircularButton } from "../CircularButton";
import { Flex } from "../Flex";
import { ArrowBackIcon, ArrowForwardIcon } from "../Icons";
import { Typography } from "../Typography";
import { SpacingVariants, Theme, useBreakpoints } from "../styles";

interface CarouselProps<ItemType> {
  title: string;
  data: ItemType[];
  renderItem: ComponentType<{
    item: ItemType;
    size: {
      height: number;
      width: number;
    };
  }>;
  itemsPerPage?: number;
  gap?: SpacingVariants;
  endPadding?: SpacingVariants;
  hideNavigation?: boolean;
  maxHeight?: number;
}

export function Carousel<ItemType extends { id: string | number }>({
  title,
  data = [],
  itemsPerPage = 5,
  renderItem: ItemComponent,
  gap = "sm",
  endPadding = "none",
  hideNavigation,
  maxHeight,
}: CarouselProps<ItemType>) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const viewPortRef = useRef<HTMLDivElement>(null);
  const [maxScroll, setMaxScroll] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [scrollCounter, setScrollCounter] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [itemDimensions, setItemDimensions] = useState({ height: 0, width: 0 });
  const COMPARISON_TOLERANCE = 2;

  const viewPortWidth = viewPortRef.current?.clientWidth ?? 0;
  const totalWidth = carouselRef.current?.scrollWidth ?? 0;

  const theme = useTheme() as Theme;

  const breakPoints = useBreakpoints();

  const updateButtonStates = useCallback(() => {
    if (viewPortRef.current) {
      const scrollLeft = viewPortRef.current.scrollLeft;
      setPrevDisabled(scrollLeft <= COMPARISON_TOLERANCE);
      setNextDisabled(scrollLeft >= maxScroll - COMPARISON_TOLERANCE);
    }
  }, [maxScroll]);

  useEffect(() => {
    if (viewPortRef.current && carouselRef.current) {
      const totalGaps =
        (itemsPerPage - 1) * parseInt(get(theme.spacing, gap)) +
        2 * parseInt(get(theme.spacing, endPadding));

      setMaxScroll(totalWidth - viewPortWidth);
      setNextDisabled(totalWidth <= viewPortWidth);

      const pageWidth = viewPortWidth - totalGaps;
      const itemWidth = pageWidth / itemsPerPage;
      const itemHeight =
        maxHeight && itemWidth > maxHeight ? maxHeight : itemWidth;
      setPageWidth(pageWidth);
      setItemDimensions({ width: itemWidth, height: itemHeight });
    }
  }, [
    viewPortWidth,
    totalWidth,
    itemsPerPage,
    data,
    theme.spacing,
    gap,
    endPadding,
    breakPoints.isLg,
    breakPoints.isMd,
    breakPoints.isMobile,
    breakPoints.isSm,
    breakPoints.isXl,
    breakPoints.isXs,
    maxHeight,
  ]);

  const handleScroll = useCallback(() => {
    if (viewPortRef.current) {
      const scrollLeft = viewPortRef.current.scrollLeft;
      setScrollCounter(Math.round(scrollLeft / pageWidth));
      updateButtonStates();
    }
  }, [pageWidth, updateButtonStates]);

  const handlePrevClick = useCallback(() => {
    if (viewPortRef.current && viewPortRef.current.scrollLeft) {
      const newScrollCounter = scrollCounter - 1;
      setScrollCounter(newScrollCounter);
      const newScrollPosition = newScrollCounter * pageWidth;
      viewPortRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
      updateButtonStates();
    }
  }, [pageWidth, scrollCounter, updateButtonStates]);

  const handleNextClick = useCallback(() => {
    if (viewPortRef.current) {
      const newScrollPosition =
        (scrollCounter + 1) * pageWidth +
        2 * parseInt(get(theme.spacing, endPadding));
      if (newScrollPosition <= maxScroll) {
        const newScrollCounter = scrollCounter + 1;
        setScrollCounter(newScrollCounter);
        viewPortRef.current.scrollTo({
          left: newScrollPosition,
          behavior: "smooth",
        });
      } else {
        const newScrollCounter = Math.ceil(maxScroll / pageWidth);
        setScrollCounter(newScrollCounter);
        viewPortRef.current.scrollTo({ left: maxScroll, behavior: "smooth" });
      }
      updateButtonStates();
    }
  }, [
    endPadding,
    maxScroll,
    pageWidth,
    scrollCounter,
    theme.spacing,
    updateButtonStates,
  ]);

  return (
    <Flex direction="column" gap="md">
      <Flex justify="space-between">
        <Flex flex={1}>
          <Typography variant="h2">{title}</Typography>
        </Flex>
        {!hideNavigation ? (
          <Flex>
            <CircularButton
              variant="outlined"
              data-testid="back-button"
              icon={<ArrowBackIcon />}
              onClick={handlePrevClick}
              disabled={prevDisabled}
              size="md"
            />
            <CircularButton
              variant="outlined"
              data-testid="next-button"
              icon={<ArrowForwardIcon />}
              onClick={handleNextClick}
              disabled={nextDisabled}
              size="md"
            />
          </Flex>
        ) : null}
      </Flex>
      <StyledCarouselScroller
        ref={viewPortRef}
        onScroll={handleScroll}
        endPadding={endPadding}
        data-testid="carousel-viewport"
      >
        <Flex ref={carouselRef} padding="sm" align="stretch" gap={gap}>
          {data.map((item) => (
            <ItemComponent key={item.id} item={item} size={itemDimensions} />
          ))}
        </Flex>
      </StyledCarouselScroller>
    </Flex>
  );
}

const StyledCarouselScroller = styled.div<{
  endPadding: SpacingVariants;
}>`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  flex: 1;
  margin-left: -${({ endPadding, theme }) => get(theme.spacing, endPadding)};
  margin-right: -${({ endPadding, theme }) => get(theme.spacing, endPadding)};

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;
