import styled, { AnyStyledComponent } from "styled-components";

import { Flex, StorefrontIcon } from "@vapaus/ui-v2";

type ShopLogoProps = {
  logoUrl?: string;
};

export function ShopLogo({ logoUrl }: ShopLogoProps) {
  return (
    <StyledIconWrapper align="center" justify="center">
      {logoUrl ? (
        <StyledLogoImage src={logoUrl} alt="Logo" />
      ) : (
        <StyledStorefrontIcon />
      )}
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled(Flex)`
  width: 80px;
  height: 80px;
  border: ${({ theme }) => theme.border.secondary.main};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  border-radius: 100%;
  overflow: hidden;
`;

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledStorefrontIcon = styled(
  StorefrontIcon as unknown as AnyStyledComponent,
)`
  font-size: 2.25rem;
`;
