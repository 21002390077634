import { useCallback, useMemo, useRef } from "react";

import { PaginationState, SortingState, Updater } from "@tanstack/react-table";
import { mapValues } from "lodash-es";
import { useSearchParams } from "react-router-dom";

type Value = string | number | boolean;

type Params<ExtraParams = undefined> = {
  q?: string;
  sortId?: string;
  sortDesc?: boolean;
} & Partial<PaginationState> &
  (ExtraParams extends undefined ? NonNullable<unknown> : ExtraParams);

type UseTableParamsType<ExtraParams = undefined> = {
  q: string;
  sorting: SortingState;
  pagination: PaginationState;
  setSorting: (toggleSorting: Updater<SortingState>) => void;
  setPagination: (updater: Updater<PaginationState>) => void;
  setSearchQuery: (value: string) => void;
  setExtraParams: (params: Partial<ExtraParams>) => void;
} & (ExtraParams extends undefined ? NonNullable<unknown> : ExtraParams);

export function useTableParams<ExtraParams = undefined>(
  defaultValues?: Params<ExtraParams>,
): UseTableParamsType<ExtraParams> {
  // make the default stable, disclaimer: this also prevents updating the default value once they have been set
  const initialValues = useRef<Params<ExtraParams>>({
    q: "",
    pageSize: 10,
    pageIndex: 0,
    sortDesc: true,
    sortId: null,
    ...defaultValues,
  } as Params<ExtraParams>);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageSize = parseInt(
    searchParams.get("pageSize") || String(initialValues.current.pageSize),
  );
  const pageIndex = parseInt(
    searchParams.get("pageIndex") || String(initialValues.current.pageIndex),
  );
  const sortId = searchParams.get("sortId") || initialValues.current.sortId;
  const sortDesc = !searchParams.get("sortDesc")
    ? initialValues.current.sortDesc
    : searchParams.get("sortDesc") === String(initialValues.current.sortDesc)
    ? initialValues.current.sortDesc
    : !initialValues.current.sortDesc;

  const setParams = useCallback(
    (params: Partial<Params<ExtraParams>>) => {
      setSearchParams(
        (prevParams) => {
          const nextParams = {
            ...initialValues.current,
            ...Object.fromEntries(prevParams.entries()),
            ...params,
          };
          const result = toUrlParams(nextParams, initialValues.current);
          return result;
        },
        { preventScrollReset: true },
      );
    },
    [setSearchParams, initialValues],
  );

  const setExtraParams = useCallback(
    (params: Partial<ExtraParams>) =>
      setParams({ ...params, pageIndex: 0 } as Partial<Params<ExtraParams>>),
    [setParams],
  );

  const setPagination = useCallback(
    (updater: Updater<PaginationState>) => {
      const pagination =
        // As described in the Tanstack Table doc https://tanstack.com/table/v8/docs/api/features/pagination#setpagination
        // the updater can be a function that takes the initial pagination & return the new one or just the new pagination
        typeof updater === "function"
          ? updater({ pageIndex, pageSize })
          : updater;
      if (pagination.pageSize !== pageSize) pagination.pageIndex = 0;
      setParams(pagination as Partial<Params<ExtraParams>>);
    },
    [pageIndex, pageSize, setParams],
  );

  const sorting: SortingState = useMemo(
    () =>
      sortId !== undefined && sortDesc !== undefined
        ? [{ id: sortId, desc: sortDesc }]
        : [],
    [sortDesc, sortId],
  );

  const setSorting = useCallback(
    (toggleSorting: Updater<SortingState>) => {
      const nextSorting =
        // As described in the Tanstack Table doc https://tanstack.com/table/v8/docs/api/features/sorting#setsorting
        // the toggleSorting can be a function that takes the initial sorting & return the new one or just the new sorting
        typeof toggleSorting === "function"
          ? toggleSorting(sorting)
          : toggleSorting;
      setParams({
        sortId: nextSorting[0]?.id ?? null,
        sortDesc: nextSorting[0]?.desc ?? initialValues.current.sortDesc,
        pageIndex: 0,
      } as Partial<Params<ExtraParams>>);
    },
    [sorting, setParams],
  );

  const setSearchQuery = useCallback(
    (value: string) => {
      const params = { q: value, pageIndex: 0 } as Params<ExtraParams>;
      setParams(params);
    },
    [setParams],
  );

  const filteredParamsArray = Array.from(
    Object.entries({
      ...initialValues.current,
      ...Object.fromEntries(searchParams.entries()),
    }),
  ).filter(
    ([key]) => !["pageSize", "pageIndex", "sortId", "sortDesc"].includes(key),
  );
  const searchQueryAndExtraFields = Object.fromEntries(filteredParamsArray);

  return {
    setSorting,
    setPagination,
    setSearchQuery,
    setExtraParams,
    sorting,
    pagination: { pageIndex, pageSize },
    ...searchQueryAndExtraFields,
  } as UseTableParamsType<ExtraParams>;
}

function filterDefault(
  nextParams: Record<string, Value | undefined>,
  defaultValues: Record<string, Value>,
): Record<string, Value> {
  const filteredEntries = Object.entries(nextParams).filter(([key, value]) => {
    // Ignore key that doesn't have a default value or if the value is undefined
    if (!(defaultValues && key in defaultValues) || value === undefined)
      return false;

    const defaultValue = defaultValues?.[key];
    // ignore values that are the same as the default, so the url is not polluted with useless junk
    return String(value) !== String(defaultValue);
  }) as Array<[string, Value]>;
  return Object.fromEntries(filteredEntries);
}

function convertToUrlValues(
  nextParams: Record<string, Value>,
): Record<string, string> {
  return mapValues(nextParams, (value) => String(value));
}

function toUrlParams<ExtraParams extends Record<string, Value>>(
  nextParams: Record<string, Value | undefined>,
  defaultValues: Params<ExtraParams>,
): URLSearchParams {
  const filteredParams = filterDefault(nextParams, defaultValues);
  const convertedParams = convertToUrlValues(filteredParams);
  return new URLSearchParams(convertedParams);
}
