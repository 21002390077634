import { useCallback, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { escapeRegExp } from "lodash-es";

import { ResponseError } from "@vapaus/api-codegen";
import {
  AutocompleteSelect,
  AutocompleteSelectOptionType,
  AutocompleteSelectProps,
} from "@vapaus/ui-v2";

type RequestParametersType = {
  q: string;
};

export type AutocompleteSelectAsyncProps<TDataType> = {
  queryFn: (params: RequestParametersType) => Promise<TDataType>;
  queryKey: string;
  querySelect: (data: TDataType) => AutocompleteSelectOptionType[];
} & Omit<AutocompleteSelectProps, "options">;

export function AutocompleteSelectAsync<TDataType = unknown>({
  queryFn,
  queryKey,
  querySelect,
  ...rest
}: AutocompleteSelectAsyncProps<TDataType>) {
  const [query, setQuery] = useState<string>("");

  const { data, isFetching } = useQuery<
    TDataType,
    ResponseError,
    AutocompleteSelectOptionType[]
  >([queryKey, query], () => queryFn({ q: query }), {
    select: querySelect,
    keepPreviousData: true,
  });

  const updateQuery = useCallback(
    (value: string) => {
      if (
        (data || []).filter((option) =>
          new RegExp(escapeRegExp(value), "i").test(option?.label),
        ).length === 0 ||
        (value === "" && query)
      )
        setQuery(value);
    },
    [data, query, setQuery],
  );

  return (
    <AutocompleteSelect
      {...rest}
      options={data || []}
      onInputChange={(_, value) => updateQuery(value)}
      isLoading={isFetching}
    />
  );
}
