import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  BenefitCancellationCoverage,
  BikeBenefitOrderRead,
} from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useToaster } from "@vapaus/ui-v2";

import { userBenefitApi } from "../../../../../api/benefit";

const acceptOrderFormSchema = z.object({
  acceptedVersion: z.string(),
  optionalCancellationCoverage: z.boolean(),
  isDownPaymentInsured: z.boolean(),
  guidelineAccepted: z.boolean(),
});

export type AcceptOrderFormSchema = z.infer<typeof acceptOrderFormSchema>;

export function useAcceptOrderForm(order: BikeBenefitOrderRead) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toaster = useToaster();

  const { formMethods, mutation } = useFormMutation<
    AcceptOrderFormSchema,
    BikeBenefitOrderRead
  >(
    {
      mutationFn: (formData) =>
        userBenefitApi.benefitAcceptBikeBenefitOrder({
          vapausCode: order.vapausCode,
          bodyBenefitAcceptBikeBenefitOrder: formData,
        }),
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["orders"] });
        queryClient.invalidateQueries({ queryKey: ["contracts"] });
        toaster.add("success", t("userV2:myBike.orderConfirmed"));
      },
    },
    {
      resolver: zodResolver(acceptOrderFormSchema),
      defaultValues: {
        acceptedVersion: order.currentVersion,
        optionalCancellationCoverage:
          Boolean(order.cancellationCoverage) &&
          order.cancellationCoverage === BenefitCancellationCoverage.Employee,
        isDownPaymentInsured: order.isDownPaymentInsured,
        guidelineAccepted: false,
      },
    },
  );

  return {
    formMethods,
    mutation,
  };
}
