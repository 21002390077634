import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, ModalNavBar } from "@vapaus/ui-v2";

import { useCurrentAppTerm } from "../../api";
import { AppName } from "../../constants";
import { TermsOfServiceContent } from "./TermsOfServiceContent";

export type TermsOfServicePageProps = {
  app: AppName;
};

export function TermsOfServicePage({ app }: TermsOfServicePageProps) {
  const { t } = useTranslation();
  const { data } = useCurrentAppTerm(app);

  const backToSignUp = () => window.close();

  return (
    <Root>
      <ModalNavBar
        title={t("authV2:termsOfService.termsOfService")}
        onClose={backToSignUp}
      />
      <TermsOfServiceContent content={data?.content}>
        <Button onClick={backToSignUp} marginTop="lg">
          {t("commonV2:goBack")}
        </Button>
      </TermsOfServiceContent>
    </Root>
  );
}

const Root = styled.div`
  min-height: 100vh;
`;
