import { BaseSyntheticEvent } from "react";

import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import {
  FieldValues,
  Path,
  SubmitErrorHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FetchError, ResponseError } from "@vapaus/api-codegen";

import { ApiError, parseApiError } from "../utils/parseApiError";

type SubmitHandler<
  TData extends FieldValues,
  TFormData extends FieldValues | undefined = undefined,
> = (
  data: TFormData extends FieldValues ? TFormData : TData,
  event?: BaseSyntheticEvent,
) => TData | undefined;

export interface UseFormMutationOptions<
  TData extends FieldValues = FieldValues,
  TFormData extends FieldValues | undefined = undefined,
> {
  onValid?: SubmitHandler<TData, TFormData>;
  onInvalid?: SubmitErrorHandler<
    TFormData extends FieldValues ? TFormData : TData
  >;
}

export type FormHandleSubmit = (e?: BaseSyntheticEvent) => Promise<void>;

export type FormMethods<TData extends FieldValues = FieldValues> =
  UseFormReturn<TData> & {
    _handleSubmit: FormHandleSubmit;
  };

export interface UseFormMutationReturn<
  TData extends FieldValues = FieldValues,
  TResult = unknown,
  TFormData extends FieldValues | undefined = undefined,
  TError extends ResponseError | FetchError = ResponseError,
> {
  formMethods: FormMethods<TFormData extends FieldValues ? TFormData : TData>;
  mutation: UseMutationResult<TResult, TError, TData>;
}

export function useFormMutation<
  TData extends FieldValues = FieldValues,
  TResult = unknown,
  TFormData extends FieldValues | undefined = undefined,
  TError extends ResponseError | FetchError = ResponseError,
>(
  { onError, ...mutationOptions }: UseMutationOptions<TResult, TError, TData>,
  formProps?: UseFormProps<TFormData extends FieldValues ? TFormData : TData>,
  { onValid, onInvalid }: UseFormMutationOptions<TData, TFormData> = {},
): UseFormMutationReturn<TData, TResult, TFormData, TError> {
  const formMethods =
    useForm<TFormData extends FieldValues ? TFormData : TData>(formProps);
  const { t } = useTranslation();
  const mutation = useMutation<TResult, TError, TData>({
    ...mutationOptions,
    async onError(error: TError, variables: TData, context: unknown) {
      formMethods.clearErrors();
      if (error instanceof FetchError) {
        formMethods.setError("root", {
          message: t("commonV2:api.error-no-response") ?? undefined,
        });
      } else {
        const _error = (await error?.response?.clone().json()) as ApiError;
        const errors = parseApiError(_error);
        errors.forEach((error) =>
          formMethods.setError(
            error.field as
              | Path<TFormData extends FieldValues ? TFormData : TData>
              | "root",
            {
              message: error.message,
            },
          ),
        );
      }
      onError?.(error, variables, context);
    },
  });

  const _handleSubmit: FormHandleSubmit = formMethods.handleSubmit(
    (
      data: TFormData extends FieldValues ? TFormData : TData,
      event?: React.BaseSyntheticEvent,
    ) => {
      if (mutation.isLoading) {
        console.warn(
          "useFormMutation: The form is already submitted and waiting for a response from the api.",
        );
        return;
      }
      const submittedData = (onValid?.(data, event) ?? data) as TData;
      mutation.mutate(submittedData);
    },
    onInvalid,
  );

  return { formMethods: { ...formMethods, _handleSubmit }, mutation };
}
