import { get } from "lodash-es";
import { RegisterOptions, useFormContext } from "react-hook-form";

import {
  PasswordInput as UIPasswordInput,
  PasswordInputProps as UIPasswordInputProps,
} from "@vapaus/ui-v2";

export type PasswordInputProps = Omit<
  UIPasswordInputProps,
  "error" | "pattern"
> &
  Pick<RegisterOptions, "validate" | "pattern"> & {
    name: string;
  };

export function PasswordInput({
  name,
  required,
  validate,
  value,
  onChange,
  onBlur,
  disabled,
  pattern,
  ...rest
}: PasswordInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string | undefined;

  return (
    <UIPasswordInput
      {...register(name, {
        required,
        validate,
        value,
        onChange,
        onBlur,
        disabled,
        // Typescript is not happy with the type somehow
        /* eslint-disable @typescript-eslint/no-explicit-any */
        pattern: pattern as any,
      })}
      error={errorMessage}
      {...rest}
    />
  );
}
