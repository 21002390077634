import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UserBenefitActivationRead } from "@vapaus/api-codegen";
import { ActionCard, BadgeIcon } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../api/benefitDefinitions";
import { useGetCurrentUser } from "../../../api/user";
import { Path } from "../../../constants/paths";
import { getBenefitStatusTag } from "../../../utils/getBenefitStatusTag";

type ActivationListItemProps = {
  activation: UserBenefitActivationRead;
};

export function ActivationListItem({ activation }: ActivationListItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: benefitDefinition } = useGetBenefitDefinition(
    activation?.benefitDefinitionId,
  );

  const { data: user } = useGetCurrentUser();

  if (!benefitDefinition || !activation) {
    return null;
  }

  return (
    <ActionCard
      icon={BadgeIcon}
      firstLine={
        benefitDefinition.organisation.name ||
        activation.accessRequest?.businessId ||
        ""
      }
      secondLine={activation.accessRequest?.userEmail ?? user?.email ?? ""}
      thirdLine={benefitDefinition.name}
      afterContent={getBenefitStatusTag({
        t,
        isActivationActive: activation.isActive,
        isBenefitDefinitionActive: benefitDefinition.active,
      })}
      onClick={() =>
        navigate(
          `${Path.activation}/${activation?.id}/${Path.benefitGuideline}`,
        )
      }
    />
  );
}
