import { useState } from "react";

import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import {
  BenefitTermsModal,
  getOrderCalculationParams,
} from "@vapaus/common-v2";
import { useOrderCalculation } from "@vapaus/shared-api";
import { Flex } from "@vapaus/ui-v2";

import { useGetOrder } from "../../../api/benefit";
import { useGetBenefitDefinition } from "../../../api/benefitDefinitions";
import { useGetShop } from "../../../api/shop";
import { BikeOrderCard } from "./BikeOrderCard";
import { CancellationCoverageCard } from "./CancellationCoverageCard";
import { DownPaymentCard } from "./DownPaymentCard";
import { LeasingCostsCard } from "./LeasingCostsCard";
import { MaintenanceCard } from "./MaintenanceCard";
import { ShopCard } from "./ShopCard";

export function TabPanelOrderDetails() {
  const { orderId } = useParams();
  invariant(orderId);
  const { data: order } = useGetOrder(orderId);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { data: shop } = useGetShop(order?.shopId);
  const { data: benefitDefinition } = useGetBenefitDefinition(
    order?.benefitDefinitionId,
  );

  const orderCalculationParams = order
    ? getOrderCalculationParams(order, benefitDefinition)
    : undefined;
  const { data: calculation } = useOrderCalculation(orderCalculationParams, {
    enabled: Boolean(benefitDefinition && orderCalculationParams),
  });

  if (!order || !calculation) return null;

  return (
    <>
      <Flex direction="column" gap="lg">
        <ShopCard shop={shop} />
        <BikeOrderCard order={order} />
        <MaintenanceCard order={order} />
        <DownPaymentCard order={order} />
        <CancellationCoverageCard
          order={order}
          benefitDefinition={benefitDefinition}
          onModalOpen={() => setIsTermsModalOpen(true)}
        />
        <LeasingCostsCard order={order} calculation={calculation} />
      </Flex>
      <BenefitTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title={benefitDefinition?.organisation.name ?? ""}
        content={benefitDefinition?.currentBenefitDefinitionTerm?.content ?? ""}
      />
    </>
  );
}
