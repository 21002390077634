import { ComponentType } from "react";

import styled, { css } from "styled-components";

import { CircularIcon } from "../CircularIcon";
import { Flex } from "../Flex";
import { SvgIconProps } from "../Icons";
import { Typography } from "../Typography";

type OptionButtonProps = {
  icon: ComponentType<SvgIconProps>;
  title: string;
  subtitle: string;
  onClick: () => void;
  disabled?: boolean;
};

export function OptionButton({
  icon: Icon,
  title,
  subtitle,
  onClick,
  disabled,
}: OptionButtonProps) {
  return (
    <StyledRoot
      as="button"
      padding="lg"
      direction="column"
      align="center"
      onClick={onClick}
      disabled={disabled}
    >
      <CircularIcon icon={Icon} />
      <Flex direction="column" gap="xxs" align="center" fullWidth>
        <Typography weight="bold" align="center">
          {title}
        </Typography>
        <Typography align="center">{subtitle}</Typography>
      </Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{
  disabled?: boolean;
}>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing.xs};
  border: ${({ theme }) => theme.border.secondary.main};
  width: 288px;
  max-width: 100%;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 100%;
    `)}
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary2.lighter};
  }
  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: ${theme.opacity.disabled};
      pointer-events: none;
    `}
`;
