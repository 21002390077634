import { ReactNode } from "react";

import { Flex } from "../Flex";
import { Paper, PaperProps } from "../Paper";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";

export interface CardProps extends Pick<PaperProps, "shadow"> {
  title?: string;
  subtitle?: ReactNode;
  children: ReactNode;
  className?: string;
  tooltipMessage?: string;
}

export function Card({
  title,
  subtitle,
  shadow = "md",
  children,
  className,
  tooltipMessage,
}: CardProps) {
  return (
    <Paper shadow={shadow} className={className}>
      {title ? (
        <Flex align="flex-start" justify="space-between">
          <Typography variant="h2" marginBottom={subtitle ? "sm" : "lg"}>
            {title}
          </Typography>
          {tooltipMessage && <Tooltip>{tooltipMessage}</Tooltip>}
        </Flex>
      ) : null}
      {subtitle && <Typography marginBottom="lg">{subtitle}</Typography>}
      {children}
    </Paper>
  );
}
