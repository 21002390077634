import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { AnimatedErrorIcon, Flex, Modal, Typography } from "@vapaus/ui-v2";

import { usePayRedemptionOrReturnInvoice } from "../hooks/usePayRedemptionOrReturnInvoice";

type RedemptionPaymentErrorModalProps = {
  onClose: () => void;
  isOpen: boolean;
  saleInvoiceId?: string;
};

export function PaymentErrorModal({
  isOpen,
  onClose,
  saleInvoiceId,
}: RedemptionPaymentErrorModalProps) {
  const { t } = useTranslation();
  const { onPayInvoice, isLoading } = usePayRedemptionOrReturnInvoice();

  const { state } = useLocation();
  const { paymentResumePath } = state ?? {};

  const handleTryAgain = useCallback(() => {
    if (saleInvoiceId) onPayInvoice(saleInvoiceId, paymentResumePath);
  }, [onPayInvoice, saleInvoiceId, paymentResumePath]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelText={t("commonV2:close")}
      confirmText={t("commonV2:tryAgain")}
      onCancel={onClose}
      onConfirm={handleTryAgain}
      isSubmitting={isLoading}
    >
      <Flex direction="column" align="center" gap="none">
        <AnimatedErrorIcon />
        <Typography variant="h2" align="center" marginBottom="sm">
          {t(
            "userV2:redeemReturnSharedComponents.paymentProcessing.paymentFailed",
          )}
        </Typography>
        <Typography variant="bodyLg" align="center">
          {t(
            "userV2:redeemReturnSharedComponents.paymentProcessing.paymentFailedMessage",
          )}
        </Typography>
      </Flex>
    </Modal>
  );
}
