/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { PaymentConsentStatus } from "./PaymentConsentStatus";
import {
  PaymentConsentStatusFromJSON,
  PaymentConsentStatusFromJSONTyped,
  PaymentConsentStatusToJSON,
} from "./PaymentConsentStatus";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
} from "./PaymentServiceProvider";

/**
 *
 * @export
 * @interface PaymentConsentDetailsRead
 */
export interface PaymentConsentDetailsRead {
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  userId: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentConsentDetailsRead
   */
  dateCreated: Date;
  /**
   *
   * @type {Currency}
   * @memberof PaymentConsentDetailsRead
   */
  currency: Currency;
  /**
   *
   * @type {PaymentConsentStatus}
   * @memberof PaymentConsentDetailsRead
   */
  status: PaymentConsentStatus;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  failureReason?: string;
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof PaymentConsentDetailsRead
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  externalAuthorisationPaymentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  externalConsentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  sourceType?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  sourceBrand?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  sourceLabel?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentConsentDetailsRead
   */
  sourceCardExpirationYear?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentConsentDetailsRead
   */
  sourceCardExpirationMonth?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentConsentDetailsRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentConsentDetailsRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentConsentDetailsRead
   */
  link?: string;
}

/**
 * Check if a given object implements the PaymentConsentDetailsRead interface.
 */
export function instanceOfPaymentConsentDetailsRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "dateCreated" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "paymentServiceProvider" in value;

  return isInstance;
}

export function PaymentConsentDetailsReadFromJSON(
  json: any,
): PaymentConsentDetailsRead {
  return PaymentConsentDetailsReadFromJSONTyped(json, false);
}

export function PaymentConsentDetailsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentConsentDetailsRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
    dateCreated: new Date(json["date_created"]),
    currency: CurrencyFromJSON(json["currency"]),
    status: PaymentConsentStatusFromJSON(json["status"]),
    failureReason: !exists(json, "failure_reason")
      ? undefined
      : json["failure_reason"],
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    externalAuthorisationPaymentId: !exists(
      json,
      "external_authorisation_payment_id",
    )
      ? undefined
      : json["external_authorisation_payment_id"],
    externalConsentId: !exists(json, "external_consent_id")
      ? undefined
      : json["external_consent_id"],
    sourceType: !exists(json, "source_type") ? undefined : json["source_type"],
    sourceBrand: !exists(json, "source_brand")
      ? undefined
      : json["source_brand"],
    sourceLabel: !exists(json, "source_label")
      ? undefined
      : json["source_label"],
    sourceCardExpirationYear: !exists(json, "source_card_expiration_year")
      ? undefined
      : json["source_card_expiration_year"],
    sourceCardExpirationMonth: !exists(json, "source_card_expiration_month")
      ? undefined
      : json["source_card_expiration_month"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    link: !exists(json, "link") ? undefined : json["link"],
  };
}

export function PaymentConsentDetailsReadToJSON(
  value?: PaymentConsentDetailsRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
    date_created: value.dateCreated.toISOString().substring(0, 10),
    currency: CurrencyToJSON(value.currency),
    status: PaymentConsentStatusToJSON(value.status),
    failure_reason: value.failureReason,
    payment_service_provider: PaymentServiceProviderToJSON(
      value.paymentServiceProvider,
    ),
    external_authorisation_payment_id: value.externalAuthorisationPaymentId,
    external_consent_id: value.externalConsentId,
    source_type: value.sourceType,
    source_brand: value.sourceBrand,
    source_label: value.sourceLabel,
    source_card_expiration_year: value.sourceCardExpirationYear,
    source_card_expiration_month: value.sourceCardExpirationMonth,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    link: value.link,
  };
}
