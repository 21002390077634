/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { SaleInvoiceLine } from "./SaleInvoiceLine";
import {
  SaleInvoiceLineFromJSON,
  SaleInvoiceLineFromJSONTyped,
  SaleInvoiceLineToJSON,
} from "./SaleInvoiceLine";
import type { SaleInvoiceStatus } from "./SaleInvoiceStatus";
import {
  SaleInvoiceStatusFromJSON,
  SaleInvoiceStatusFromJSONTyped,
  SaleInvoiceStatusToJSON,
} from "./SaleInvoiceStatus";
import type { SaleInvoiceTransaction } from "./SaleInvoiceTransaction";
import {
  SaleInvoiceTransactionFromJSON,
  SaleInvoiceTransactionFromJSONTyped,
  SaleInvoiceTransactionToJSON,
} from "./SaleInvoiceTransaction";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
} from "./StorageObjectRead";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface SaleInvoiceRead
 */
export interface SaleInvoiceRead {
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  date: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  dueDate: Date;
  /**
   *
   * @type {Currency}
   * @memberof SaleInvoiceRead
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  pdfObjectId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerUserId: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  bikeBenefitOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  invoiceNumberPrefix: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  invoiceNumberSerial: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerFullName: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerAddress: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerPostCode: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  customerCity: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof SaleInvoiceRead
   */
  customerCountry: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  revertsInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  invoiceNumber: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceRead
   */
  balance: number;
  /**
   *
   * @type {SaleInvoiceStatus}
   * @memberof SaleInvoiceRead
   */
  status: SaleInvoiceStatus;
  /**
   *
   * @type {Array<SaleInvoiceLine>}
   * @memberof SaleInvoiceRead
   */
  saleInvoiceLines: Array<SaleInvoiceLine>;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof SaleInvoiceRead
   */
  pdfObject?: StorageObjectRead;
  /**
   *
   * @type {Array<SaleInvoiceTransaction>}
   * @memberof SaleInvoiceRead
   */
  saleInvoiceTransactions: Array<SaleInvoiceTransaction>;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceRead
   */
  vapausCode?: string;
}

/**
 * Check if a given object implements the SaleInvoiceRead interface.
 */
export function instanceOfSaleInvoiceRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "date" in value;
  isInstance = isInstance && "dueDate" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "customerUserId" in value;
  isInstance = isInstance && "invoiceNumberPrefix" in value;
  isInstance = isInstance && "invoiceNumberSerial" in value;
  isInstance = isInstance && "vat" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "customerFullName" in value;
  isInstance = isInstance && "customerAddress" in value;
  isInstance = isInstance && "customerPostCode" in value;
  isInstance = isInstance && "customerCity" in value;
  isInstance = isInstance && "customerCountry" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "invoiceNumber" in value;
  isInstance = isInstance && "balance" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "saleInvoiceLines" in value;
  isInstance = isInstance && "saleInvoiceTransactions" in value;

  return isInstance;
}

export function SaleInvoiceReadFromJSON(json: any): SaleInvoiceRead {
  return SaleInvoiceReadFromJSONTyped(json, false);
}

export function SaleInvoiceReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json["date"]),
    dueDate: new Date(json["due_date"]),
    currency: CurrencyFromJSON(json["currency"]),
    pdfObjectId: !exists(json, "pdf_object_id")
      ? undefined
      : json["pdf_object_id"],
    customerUserId: json["customer_user_id"],
    bikeBenefitOrderId: !exists(json, "bike_benefit_order_id")
      ? undefined
      : json["bike_benefit_order_id"],
    bikeBenefitContractId: !exists(json, "bike_benefit_contract_id")
      ? undefined
      : json["bike_benefit_contract_id"],
    invoiceNumberPrefix: json["invoice_number_prefix"],
    invoiceNumberSerial: json["invoice_number_serial"],
    vat: json["vat"],
    total: json["total"],
    customerFullName: json["customer_full_name"],
    customerAddress: json["customer_address"],
    customerPostCode: json["customer_post_code"],
    customerCity: json["customer_city"],
    customerCountry: SupportedCountriesEnumFromJSON(json["customer_country"]),
    revertsInvoiceId: !exists(json, "reverts_invoice_id")
      ? undefined
      : json["reverts_invoice_id"],
    id: json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    invoiceNumber: json["invoice_number"],
    balance: json["balance"],
    status: SaleInvoiceStatusFromJSON(json["status"]),
    saleInvoiceLines: (json["sale_invoice_lines"] as Array<any>).map(
      SaleInvoiceLineFromJSON,
    ),
    pdfObject: !exists(json, "pdf_object")
      ? undefined
      : StorageObjectReadFromJSON(json["pdf_object"]),
    saleInvoiceTransactions: (
      json["sale_invoice_transactions"] as Array<any>
    ).map(SaleInvoiceTransactionFromJSON),
    vapausCode: !exists(json, "vapaus_code") ? undefined : json["vapaus_code"],
  };
}

export function SaleInvoiceReadToJSON(value?: SaleInvoiceRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString().substring(0, 10),
    due_date: value.dueDate.toISOString().substring(0, 10),
    currency: CurrencyToJSON(value.currency),
    pdf_object_id: value.pdfObjectId,
    customer_user_id: value.customerUserId,
    bike_benefit_order_id: value.bikeBenefitOrderId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
    invoice_number_prefix: value.invoiceNumberPrefix,
    invoice_number_serial: value.invoiceNumberSerial,
    vat: value.vat,
    total: value.total,
    customer_full_name: value.customerFullName,
    customer_address: value.customerAddress,
    customer_post_code: value.customerPostCode,
    customer_city: value.customerCity,
    customer_country: SupportedCountriesEnumToJSON(value.customerCountry),
    reverts_invoice_id: value.revertsInvoiceId,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    invoice_number: value.invoiceNumber,
    balance: value.balance,
    status: SaleInvoiceStatusToJSON(value.status),
    sale_invoice_lines: (value.saleInvoiceLines as Array<any>).map(
      SaleInvoiceLineToJSON,
    ),
    pdf_object: StorageObjectReadToJSON(value.pdfObject),
    sale_invoice_transactions: (
      value.saleInvoiceTransactions as Array<any>
    ).map(SaleInvoiceTransactionToJSON),
    vapaus_code: value.vapausCode,
  };
}
