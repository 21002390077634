import { get } from "lodash-es";
import styled, { css } from "styled-components";

import {
  PaletteVariants,
  RadiusVariants,
  ShadowVariants,
  SpacingVariants,
  Theme,
} from "../styles";

export interface PaperProps {
  background?: PaletteVariants;
  radius?: RadiusVariants;
  padding?: SpacingVariants;
  marginBottom?: SpacingVariants;
  shadow?: ShadowVariants;
}

function makePadding({
  theme,
  padding = "xl",
}: {
  theme: Theme;
  padding?: SpacingVariants;
}) {
  return css`
    padding: ${theme.spacing[padding]};
    ${padding !== "none" &&
    theme.breakpoints.sm.down(css`
      padding: ${() => {
        const index = theme.sizes.indexOf(padding);
        const mobilePadding = theme.sizes[Math.max(0, index - 1)];
        return theme.spacing[mobilePadding];
      }};
    `)}
  `;
}

export const Paper = styled.div<PaperProps>`
  background-color: ${({ background = "background.white", theme }) =>
    get(theme.palette, background)};
  border-radius: ${({ radius = "card", theme }) => theme.radius[radius]};
  ${makePadding};
  box-shadow: ${({ shadow = "none", theme }) => theme.shadows[shadow]};
  margin-bottom: ${({ marginBottom = "none", theme }) =>
    theme.spacing[marginBottom]};
  width: 100%;
  transform: translate3d(
    0%,
    0,
    0
  ); // this is needed for smooth animation with safari
`;
