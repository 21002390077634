import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { HowItWorks, ResultBox, SliderInput } from "@vapaus/calculator";
import { Calculator3DIcon, Flex, Paper, Typography } from "@vapaus/ui-v2";

import { useGetPublicCalculations } from "../../api/calculations";
import { NoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/NoApprovedBenefitsBanner";
import { StyledContent } from "./components/StyledContent";
import { StyledRoot } from "./components/StyledRoot";
import { StyledRow } from "./components/StyledRow";

export function CalculatorNoBenefitPage() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    totalPackagePrice: 2000,
    monthlySalary: 3800,
  });

  const handleChange =
    (field: "totalPackagePrice" | "monthlySalary") => (value: number) => {
      setData({ ...data, [field]: value });
    };

  const { data: calculation } = useGetPublicCalculations(data);

  return (
    <>
      <NoApprovedBenefitsBanner />
      <StyledRoot>
        <StyledContent>
          <Flex direction="column" gap="lg">
            <Flex direction="column" align="center" gap="lg">
              <Calculator3DIcon size="lg" />
              <Flex direction="column" align="center" gap="sm">
                <Typography variant="h1" align="center">
                  {t("userV2:calculator.title")}
                </Typography>
                <Typography variant="bodyLg" align="center">
                  {t("userV2:calculator.description")}
                </Typography>
              </Flex>
            </Flex>
            <StyledRow gap="lg" align="stretch">
              <Paper shadow="md">
                <Flex direction="column" gap="xl">
                  <SliderInput
                    label={t("calculator:packagePrice")}
                    helpText={t("calculator:packagePriceHelp")}
                    name="packagePrice"
                    step={50}
                    min={400}
                    max={4000}
                    value={data.totalPackagePrice}
                    onChange={handleChange("totalPackagePrice")}
                  />
                  <SliderInput
                    label={t("calculator:monthlySalary")}
                    helpText={t("calculator:monthlySalaryHelp")}
                    name="monthlySalary"
                    step={100}
                    min={1100}
                    max={8000}
                    value={data.monthlySalary}
                    onChange={handleChange("monthlySalary")}
                  />
                </Flex>
              </Paper>
              <ResultBox calculation={calculation} />
            </StyledRow>
            <Flex direction="column" gap="lg" align="center">
              <Typography variant="bodySm">
                {t("userV2:calculator.footnote")}
              </Typography>
              <HowItWorks calculation={calculation} />
            </Flex>
          </Flex>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
