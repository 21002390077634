import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useLogout } from "@vapaus/auth-v2";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  CircularButton,
  DropdownMenu,
  DropdownMenuProps,
  PersonIcon,
} from "@vapaus/ui-v2";
import { getStringInitials } from "@vapaus/utils";

import { PopupWrapper } from "../PopupWrapper/PopupWrapper";

type ProfileDropdownProps = Pick<DropdownMenuProps, "header" | "items"> &
  Partial<Pick<DropdownMenuProps, "onItemClick">> & {
    profileMenuTrigger?: HTMLElement | null;
    handleProfileMenuClick?: (event: React.MouseEvent<HTMLElement>) => void;
    closeProfileMenu?: () => void;
  };

export function ProfileDropdown({
  profileMenuTrigger,
  handleProfileMenuClick,
  closeProfileMenu,
  onItemClick,
  ...rest
}: ProfileDropdownProps) {
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();
  const [menuTrigger, setMenuTrigger] = useState<null | HTMLElement>(null);
  const trigger = profileMenuTrigger ?? menuTrigger;

  const handleProfileButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuTrigger(menuTrigger ? null : event.currentTarget);
  };

  const closeMenu = () => {
    setMenuTrigger(null);
    closeProfileMenu?.();
  };

  const handleItemClick = (value: string) => {
    if (onItemClick) {
      onItemClick(value);
    }
    if (value === "logout") handleLogout();
    else navigate(value);
    closeMenu();
  };

  const profileButtonLabel = getStringInitials(user?.fullName, 2);

  return (
    <>
      <PopupWrapper triggerElement={trigger} onOutsideClick={closeMenu}>
        <DropdownMenu {...rest} onItemClick={handleItemClick} />
      </PopupWrapper>
      <CircularButton
        variant="outlined"
        {...(profileButtonLabel?.length === 2
          ? { text: profileButtonLabel }
          : { icon: <PersonIcon /> })}
        onClick={handleProfileMenuClick ?? handleProfileButtonClick}
      />
    </>
  );
}
