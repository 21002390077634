import { useParams } from "react-router-dom";

import { useGetBenefitDefinitionsActivations } from "../../../api/benefitDefinitions";
import { BenefitPage } from "../BenefitPage";

export function ActivationBenefitPage() {
  const { activationId } = useParams();

  const { data: activations } = useGetBenefitDefinitionsActivations(
    {
      ids: [activationId ?? ""],
    },
    {
      enabled: !!activationId,
    },
  );

  const activation = activations?.items[0];

  if (!activationId || !activation?.benefitDefinitionId) {
    return null;
  }

  return (
    <BenefitPage
      benefitId={activationId}
      benefitDefinitionId={activation.benefitDefinitionId}
      isActivationActive={activation.isActive}
    />
  );
}
