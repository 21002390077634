import { useTranslation } from "react-i18next";

import { UserShopRead } from "@vapaus/api-codegen";
import { Modal } from "@vapaus/ui-v2";

import { CustomModalContent } from "./CustomModalContent";
import { GenericModalContent } from "./GenericModalContent";
import { useHardCodedContent } from "./useHardCodedContent";

type GenericShopModalProps = {
  shop?: UserShopRead;
  onClose: () => void;
};

export function ShopDetailModal({ shop, onClose }: GenericShopModalProps) {
  const { t } = useTranslation();

  const hardCodedContent = useHardCodedContent(shop);

  return (
    <Modal
      isOpen={!!shop}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() =>
        window.open(
          hardCodedContent?.ctaLink || shop?.website,
          "_blank",
          "noopener,noreferrer",
        )
      }
      cancelText={t("commonV2:close")}
      confirmText={
        hardCodedContent?.ctaText ||
        t("userV2:getABike.genericShopModal.visitWebsite")
      }
    >
      {hardCodedContent ? (
        <CustomModalContent shop={shop} hardCodedContent={hardCodedContent} />
      ) : (
        <GenericModalContent shop={shop} />
      )}
    </Modal>
  );
}
