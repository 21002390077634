import { useOutletContext } from "react-router-dom";

import {
  BikeBenefitContractRead,
  Payer,
  SaleInvoiceLineType,
} from "@vapaus/api-codegen";

import { useGetReturnProcessPlanInvoicing } from "../../../../../../../api/returnProcess";
import { useReturnProcessContext } from "../../../context/ReturnProcessContext";

export function usePaymentSummary() {
  const {
    pickupAddressDetails,
    preliminaryData,
    isContractEndingSoon,
    returnReason,
    accessoryIdsSelectedForRedemption,
    returnMethod,
  } = useReturnProcessContext();

  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();

  const { data: paymentSummary, isLoading } = useGetReturnProcessPlanInvoicing({
    bikeBenefitContractId: contract?.id,
    isEndOfLeaseReturn: isContractEndingSoon,
    leaseEndDate: preliminaryData!.leaseEndDate,
    redeemedAccessoryIds: accessoryIdsSelectedForRedemption,
    ...(returnMethod === "pickup" && {
      pickupDetails: {
        address: pickupAddressDetails.address!,
        postCode: pickupAddressDetails.postalCode!,
        city: pickupAddressDetails.city!,
        country: pickupAddressDetails.country!,
        requestedPickupDate: preliminaryData!.leaseEndDate,
      },
    }),
    returnReason: returnReason!,
  });

  const returnFeeInvoiceLine = paymentSummary?.find(
    (invoiceLine) => invoiceLine.lineIn.type === SaleInvoiceLineType.ReturnFee,
  );
  const returnFee =
    returnFeeInvoiceLine && returnFeeInvoiceLine.payer === Payer.Employee
      ? returnFeeInvoiceLine.lineIn.price *
        (1 + returnFeeInvoiceLine.lineIn.vatRate) *
        returnFeeInvoiceLine.lineIn.quantity
      : null;

  const accessoriesInvoiceLines = paymentSummary?.filter(
    (invoiceLine) =>
      invoiceLine.lineIn.type ===
      SaleInvoiceLineType.RedemptionFeeAccessoriesOnly,
  );
  const accessories =
    accessoriesInvoiceLines && accessoriesInvoiceLines.length > 0
      ? accessoriesInvoiceLines.map((invoiceLine) => ({
          description: invoiceLine.lineIn.description,
          price:
            invoiceLine.lineIn.price *
            (1 + invoiceLine.lineIn.vatRate) *
            invoiceLine.lineIn.quantity,
        }))
      : null;
  const totalAccessoriesPrice =
    accessories
      ?.map((accessory) => accessory.price)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) ??
    0;

  const overspentMaintenanceBudgetInvoiceLine = paymentSummary?.find(
    (invoiceLine) =>
      invoiceLine.lineIn.type ===
      SaleInvoiceLineType.OverspentMaintenanceBudget,
  );
  const overspentMaintenanceBudget = overspentMaintenanceBudgetInvoiceLine
    ? overspentMaintenanceBudgetInvoiceLine.lineIn.price *
      (1 + overspentMaintenanceBudgetInvoiceLine.lineIn.vatRate)
    : null;

  const pickupInvoiceLine = paymentSummary?.find(
    (invoiceLine) => invoiceLine.lineIn.type === SaleInvoiceLineType.Delivery,
  );
  const deliveryFee =
    pickupInvoiceLine && pickupInvoiceLine.payer === Payer.Employee
      ? pickupInvoiceLine.lineIn.price * (1 + pickupInvoiceLine.lineIn.vatRate)
      : null;

  const totalPaymentAmount =
    (returnFee ?? 0) +
    totalAccessoriesPrice +
    (overspentMaintenanceBudget ?? 0) +
    (deliveryFee ?? 0);

  return {
    isLoading,
    returnFee,
    accessories,
    totalAccessoriesPrice,
    overspentMaintenanceBudget,
    deliveryFee,
    totalPaymentAmount,
  };
}
