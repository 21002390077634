/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserSummaryExtendedRead
 */
export interface UserSummaryExtendedRead {
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserSummaryExtendedRead
   */
  employeeNumber?: string;
}

/**
 * Check if a given object implements the UserSummaryExtendedRead interface.
 */
export function instanceOfUserSummaryExtendedRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "email" in value;

  return isInstance;
}

export function UserSummaryExtendedReadFromJSON(
  json: any,
): UserSummaryExtendedRead {
  return UserSummaryExtendedReadFromJSONTyped(json, false);
}

export function UserSummaryExtendedReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSummaryExtendedRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: !exists(json, "full_name") ? undefined : json["full_name"],
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
  };
}

export function UserSummaryExtendedReadToJSON(
  value?: UserSummaryExtendedRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    email: value.email,
    full_name: value.fullName,
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
  };
}
