import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { BenefitDefinitionUserRead, UserRead } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useCurrentUser, userApi } from "@vapaus/shared-api";
import { useToaster } from "@vapaus/ui-v2";

interface UseMissingInfoFormArgs {
  onSuccess: () => void;
  benefitDefinition: BenefitDefinitionUserRead;
}

export function useMissingInfoForm({
  onSuccess,
  benefitDefinition,
}: UseMissingInfoFormArgs) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();

  const showNameFields = !user?.firstName || !user?.lastName;
  const showAddressFields = !user?.address || !user?.postCode || !user?.city;

  const missingInfoSchema = useMemo(() => {
    let schemaObject = {};

    if (showNameFields) {
      schemaObject = {
        firstName: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        lastName: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      };
    }

    if (showAddressFields) {
      schemaObject = {
        ...schemaObject,
        address: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        postCode: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        city: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      };
    }

    if (benefitDefinition.isEmployeeNumberMandatory) {
      schemaObject = {
        ...schemaObject,
        employeeNumber: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      };
    }
    if (benefitDefinition.isCostCenterMandatory) {
      schemaObject = {
        ...schemaObject,
        costCenter: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      };
    }

    return zod.object(schemaObject);
  }, [t, benefitDefinition, showNameFields, showAddressFields]);

  type MissingInfoSchema = zod.infer<typeof missingInfoSchema>;

  const { formMethods, mutation } = useFormMutation<
    MissingInfoSchema,
    UserRead
  >(
    {
      mutationFn: (formData) =>
        userApi.userUpdateCurrentUser({
          userUpdate: formData,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
        toaster.add(
          "success",
          t("userV2:myBike.missingInfoModal.successMessage"),
        );
        onSuccess();
      },
    },
    {
      resolver: zodResolver(missingInfoSchema),
    },
  );

  return { formMethods, mutation, user, showNameFields, showAddressFields };
}
