/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const Plan = {
  Custom: "custom",
  Legacy: "legacy",
  Starter: "starter",
  Pro: "pro",
  Enterprise: "enterprise",
  SwedenPilot: "sweden_pilot",
} as const;
export type Plan = (typeof Plan)[keyof typeof Plan];

export function PlanFromJSON(json: any): Plan {
  return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Plan {
  return json as Plan;
}

export function PlanToJSON(value?: Plan | null): any {
  return value as any;
}
