/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserEmailRead
 */
export interface UserEmailRead {
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailRead
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserEmailRead
   */
  emailVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserEmailRead
   */
  primary: boolean;
}

/**
 * Check if a given object implements the UserEmailRead interface.
 */
export function instanceOfUserEmailRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "emailVerified" in value;
  isInstance = isInstance && "primary" in value;

  return isInstance;
}

export function UserEmailReadFromJSON(json: any): UserEmailRead {
  return UserEmailReadFromJSONTyped(json, false);
}

export function UserEmailReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserEmailRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    userId: json["user_id"],
    email: json["email"],
    emailVerified: json["email_verified"],
    primary: json["primary"],
  };
}

export function UserEmailReadToJSON(value?: UserEmailRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user_id: value.userId,
    email: value.email,
    email_verified: value.emailVerified,
    primary: value.primary,
  };
}
