import { ReactNode } from "react";

import styled, { css } from "styled-components";
import invariant from "tiny-invariant";

import { Flex } from "../Flex";
import { Typography } from "../Typography";
import { addAlpha, defaultTheme } from "../styles";

export type NotificationType = "success" | "error" | "warning" | "neutral";

interface NotificationStateDescription {
  border: string;
  background: string;
}

type VariantsDescription = {
  [key in NotificationType]: NotificationStateDescription;
};

const variants: VariantsDescription = {
  success: {
    border: defaultTheme.palette.secondary1.main,
    background: defaultTheme.palette.secondary1.lighter,
  },
  error: {
    border: defaultTheme.palette.secondary2.main,
    background: defaultTheme.palette.secondary2.lighter,
  },
  warning: {
    border: defaultTheme.palette.secondary3.main,
    background: defaultTheme.palette.secondary3.lighter,
  },
  neutral: {
    border: addAlpha(defaultTheme.palette.primary1.main, 0.2),
    background: defaultTheme.palette.neutral.lighter,
  },
};

function makeNotificationColor({
  type = "neutral",
}: {
  type?: NotificationType;
}) {
  const variantDesc = variants[type];

  return css`
    background-color: ${variantDesc.background};
    border-color: ${variantDesc.border};
  `;
}

type BaseProps = {
  type: NotificationType;
  className?: string;
};

type NotificationWithChildren = BaseProps & {
  children: ReactNode;
  message?: never;
};

type NotificationWithMessage = BaseProps & {
  children?: never;
  message: string;
};

export type NotificationProps =
  | NotificationWithChildren
  | NotificationWithMessage;

export function Notification({
  message,
  children,
  type,
  className,
}: NotificationProps) {
  invariant(
    !children || !message,
    "Notification: cannot be used with both children and message!",
  );
  return (
    <StyledRoot
      align="center"
      gap="md"
      type={type}
      role="alert"
      className={className}
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <Typography
          variant="bodyMd"
          align="center"
          weight="bold"
          fullWidth
          aria-label="alert-message"
        >
          {message}
        </Typography>
      )}
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)<{ type: NotificationType }>`
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.radius.notification};
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};
  ${makeNotificationColor}
`;
