import { useTranslation } from "react-i18next";

import { SaleInvoiceRead } from "@vapaus/api-codegen";
import { Modal } from "@vapaus/ui-v2";

import { useGetInvoice } from "../../../api/invoice";
import { InvoiceDetailsView } from "./InvoiceDetailsView";
import { InvoicePaymentErrorView } from "./InvoicePaymentErrorView";
import { InvoicePaymentView } from "./InvoicePaymentView";
import { PaymentProcessingView } from "./PaymentProcessingView";
import { useInvoiceViewModal } from "./useInvoiceViewModal";

type InvoiceViewModalProps = {
  onClose: () => void;
  onResumePaymentProcess: (invoice: SaleInvoiceRead) => void;
  openedInvoice?: SaleInvoiceRead;
};

export function InvoiceViewModal({
  onClose,
  onResumePaymentProcess,
  openedInvoice,
}: InvoiceViewModalProps) {
  const { t } = useTranslation();
  const { data: latestInvoice } = useGetInvoice(openedInvoice?.id);

  const invoice = latestInvoice || openedInvoice;

  const {
    handleCancel,
    handleConfirm,
    modalConfirmText,
    currentView,
    isLoading,
  } = useInvoiceViewModal(onClose, onResumePaymentProcess, invoice);

  return (
    <Modal
      isOpen={!!invoice}
      onClose={
        ["paymentProcessing", "payError"].includes(currentView)
          ? undefined
          : onClose
      }
      cancelText={t("commonV2:cancel")}
      confirmText={modalConfirmText}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      isSubmitting={isLoading}
    >
      {currentView === "paymentProcessing" && <PaymentProcessingView />}
      {currentView === "payError" && <InvoicePaymentErrorView />}
      {currentView === "payment" && <InvoicePaymentView />}
      {currentView === "invoice" && invoice && (
        <InvoiceDetailsView invoice={invoice} />
      )}
    </Modal>
  );
}
