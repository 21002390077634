import { useQuery } from "@tanstack/react-query";

import { CurrentUserRead, ResponseError, UserApi } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const userAPI = new UserApi(apiConfiguration);

export const useGetCurrentUser = () =>
  useQuery<CurrentUserRead, ResponseError>({
    queryKey: ["currentUser"],
    queryFn: () => userAPI.userReadCurrentUser(),
  });
