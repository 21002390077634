/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  /**
   *
   * @type {string}
   * @memberof Token
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  tokenType: string;
}

/**
 * Check if a given object implements the Token interface.
 */
export function instanceOfToken(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "accessToken" in value;
  isInstance = isInstance && "tokenType" in value;

  return isInstance;
}

export function TokenFromJSON(json: any): Token {
  return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Token {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json["access_token"],
    tokenType: json["token_type"],
  };
}

export function TokenToJSON(value?: Token | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_token: value.accessToken,
    token_type: value.tokenType,
  };
}
