import { ComponentType, MouseEventHandler, ReactElement } from "react";

import { SvgIconProps } from "../../Icons";
import { Typography } from "../../Typography";
import { StyledDropdownItemRoot } from "../StyledDropdownItemRoot";

export type DropdownItemProps = {
  icon?: ComponentType<SvgIconProps>;
  label: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
};

export const DropdownItem = ({
  icon: Icon,
  label,
  onClick,
  disabled = false,
}: DropdownItemProps): ReactElement => (
  <StyledDropdownItemRoot
    onClick={!disabled ? onClick : undefined}
    disabled={disabled}
    data-testid="dropdown-item-root"
  >
    {Icon && <Icon />}
    <Typography variant="bodyMd" weight="bold">
      {label}
    </Typography>
  </StyledDropdownItemRoot>
);
