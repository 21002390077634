import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import {
  BadgeIcon,
  CalculateIcon,
  MobileNavigationButton,
  PedalBikeIcon,
  ShoppingCartIcon,
} from "@vapaus/ui-v2";

import { useGetCurrentUser } from "../../../api/user";
import { Path } from "../../../constants/paths";

export function MobileNavigationButtons(): ReactNode {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUser();

  return (
    <>
      <NavLink to={Path.myBikes}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={PedalBikeIcon}
            label={t("userV2:navigation.buttons.myBikes")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      <NavLink to={Path.getABike}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={ShoppingCartIcon}
            label={t("userV2:navigation.buttons.getABike")}
            isActivated={isActive}
          />
        )}
      </NavLink>
      {user?.country === SupportedCountriesEnum.Fi ? (
        <NavLink to={Path.calculator}>
          {({ isActive }) => (
            <MobileNavigationButton
              icon={CalculateIcon}
              label={t("userV2:navigation.buttons.calculator")}
              isActivated={isActive}
            />
          )}
        </NavLink>
      ) : null}
      <NavLink to={Path.benefits}>
        {({ isActive }) => (
          <MobileNavigationButton
            icon={BadgeIcon}
            label={t("userV2:navigation.buttons.benefits")}
            isActivated={isActive}
          />
        )}
      </NavLink>
    </>
  );
}
