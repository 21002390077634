import { useTranslation } from "react-i18next";

import { ShopType } from "@vapaus/api-codegen";
import {
  BuildIcon,
  Flex,
  ShoppingCartIcon,
  StorefrontIcon,
  Tooltip,
} from "@vapaus/ui-v2";

type ShopServicesProps = {
  services: ShopType[];
};

export function ShopServices({ services }: ShopServicesProps) {
  const { t } = useTranslation();
  return (
    <Flex justify="flex-end" gap="xs">
      {services.map((type) => {
        if (type === ShopType.LocalStore)
          return (
            <Tooltip key={type} trigger={<StorefrontIcon />}>
              {t("userV2:getABike.partnerShops.serviceTypes.localShop")}
            </Tooltip>
          );
        if (type === ShopType.OnlineStore)
          return (
            <Tooltip key={type} trigger={<ShoppingCartIcon />}>
              {t("userV2:getABike.partnerShops.serviceTypes.onlineShop")}
            </Tooltip>
          );
        return (
          <Tooltip key={type} trigger={<BuildIcon />}>
            {t("userV2:getABike.partnerShops.serviceTypes.maintenance")}
          </Tooltip>
        );
      })}
    </Flex>
  );
}
