import React from "react";
import ReactDOM from "react-dom/client";

import { init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { CssBaseline, Fonts, ToastProvider, createTheme } from "@vapaus/ui-v2";

import "./i18n";
import { createRoutes } from "./routes";

if (
  import.meta.env.VITE_ENV &&
  ["prod", "staging", "feature"].includes(import.meta.env.VITE_ENV)
) {
  init({
    dsn: "https://5c3bb1ae253c7ee5dc145d04e1d777c1@o1371810.ingest.us.sentry.io/4506512718626816",
    integrations: [],
  });
}

const queryClient = new QueryClient();

const theme = createTheme();

const router = createBrowserRouter(createRoutes());

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Fonts />
        <CssBaseline />
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
