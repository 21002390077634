import { forwardRef, useId } from "react";

import styled, { AnyStyledComponent, css } from "styled-components";

import { Tooltip } from "../Tooltip";
import { BaseInput, BaseInputProps } from "./BaseInput";

export interface InputProps extends Omit<BaseInputProps, "error"> {
  label?: string;
  tooltip?: string;
  error?: string;
  fullWidth?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { label, tooltip, error, id, fullWidth, ...rest },
  ref,
) {
  const generatedId = useId();
  id = id ?? generatedId;

  return (
    <StyledRoot fullWidth={fullWidth}>
      {label || tooltip ? (
        <StyledLabelContainer>
          {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
          {tooltip && <StyledTooltip>{tooltip}</StyledTooltip>}
        </StyledLabelContainer>
      ) : null}
      <BaseInput id={id} error={Boolean(error)} {...rest} ref={ref} />
      {error && <StyledErrorText role="alert">{error}</StyledErrorText>}
    </StyledRoot>
  );
});

const StyledRoot = styled.div<{
  fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledTooltip = styled(Tooltip as AnyStyledComponent)`
  margin-left: auto;
`;

const StyledErrorText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;
