/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BodyUserMergeUsers,
  CurrentUserRead,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  MasterPermissions,
  Msg,
  OrderDirection,
  PaginatedUserEmailOut,
  PaginatedUserOut,
  PasswordChange,
  Response400UserCreateUserEmail,
  Token,
  UserAdminCreate,
  UserEmailPrivateCreate,
  UserEmailRead,
  UserRead,
  UserTerms,
  UserUpdate,
  VerificationCode,
  VerificationToken,
} from "../models/index";
import {
  BodyUserMergeUsersFromJSON,
  BodyUserMergeUsersToJSON,
  CurrentUserReadFromJSON,
  CurrentUserReadToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MasterPermissionsFromJSON,
  MasterPermissionsToJSON,
  MsgFromJSON,
  MsgToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedUserEmailOutFromJSON,
  PaginatedUserEmailOutToJSON,
  PaginatedUserOutFromJSON,
  PaginatedUserOutToJSON,
  PasswordChangeFromJSON,
  PasswordChangeToJSON,
  Response400UserCreateUserEmailFromJSON,
  Response400UserCreateUserEmailToJSON,
  TokenFromJSON,
  TokenToJSON,
  UserAdminCreateFromJSON,
  UserAdminCreateToJSON,
  UserEmailPrivateCreateFromJSON,
  UserEmailPrivateCreateToJSON,
  UserEmailReadFromJSON,
  UserEmailReadToJSON,
  UserReadFromJSON,
  UserReadToJSON,
  UserTermsFromJSON,
  UserTermsToJSON,
  UserUpdateFromJSON,
  UserUpdateToJSON,
  VerificationCodeFromJSON,
  VerificationCodeToJSON,
  VerificationTokenFromJSON,
  VerificationTokenToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserAcceptUserTermsRequest {
  userTermsId: string;
}

export interface UserChangePasswordRequest {
  passwordChange: PasswordChange;
}

export interface UserCreateUserRequest {
  userAdminCreate: UserAdminCreate;
}

export interface UserCreateUserEmailRequest {
  userEmailPrivateCreate: UserEmailPrivateCreate;
  resumePath?: string;
}

export interface UserDeleteUserEmailRequest {
  userEmailId: string;
}

export interface UserDownloadUserTermsRequest {
  userTermsId: string;
}

export interface UserGetUserRequest {
  userId: string;
  validateForOrdering?: boolean;
}

export interface UserGetUserEmailRequest {
  userEmailId: string;
}

export interface UserGetUserTermsRequest {
  userTermsId: string;
}

export interface UserMakeUserEmailPrimaryRequest {
  userEmailId: string;
}

export interface UserMergeUsersRequest {
  bodyUserMergeUsers: BodyUserMergeUsers;
}

export interface UserSearchUserEmailsRequest {
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface UserSearchUsersRequest {
  shopId?: string;
  benefitDefinitionId?: string;
  masterPermission?: MasterPermissions;
  includeDeleted?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface UserSendEmailVerificationTokenRequest {
  userEmailId: string;
  resumePath?: string;
}

export interface UserSendInvitationEmailRequest {
  userId: string;
}

export interface UserSoftDeleteUserRequest {
  userId: string;
}

export interface UserUpdateCurrentUserRequest {
  userUpdate: UserUpdate;
}

export interface UserUpdateUserRequest {
  userId: string;
  userUpdate: UserUpdate;
}

export interface UserVerifyEmailRequest {
  verificationToken: VerificationToken;
}

export interface UserVerifyPhoneNumberRequest {
  verificationCode: VerificationCode;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   * Accept User Terms
   */
  async userAcceptUserTermsRaw(
    requestParameters: UserAcceptUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userTermsId === null ||
      requestParameters.userTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "userTermsId",
        "Required parameter requestParameters.userTermsId was null or undefined when calling userAcceptUserTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/user-terms/{user_terms_id}/accept`.replace(
          `{${"user_terms_id"}}`,
          encodeURIComponent(String(requestParameters.userTermsId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept User Terms
   */
  async userAcceptUserTerms(
    requestParameters: UserAcceptUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userAcceptUserTermsRaw(requestParameters, initOverrides);
  }

  /**
   * Change Password
   */
  async userChangePasswordRaw(
    requestParameters: UserChangePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Token>> {
    if (
      requestParameters.passwordChange === null ||
      requestParameters.passwordChange === undefined
    ) {
      throw new runtime.RequiredError(
        "passwordChange",
        "Required parameter requestParameters.passwordChange was null or undefined when calling userChangePassword.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/change-password`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PasswordChangeToJSON(requestParameters.passwordChange),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenFromJSON(jsonValue),
    );
  }

  /**
   * Change Password
   */
  async userChangePassword(
    requestParameters: UserChangePasswordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Token> {
    const response = await this.userChangePasswordRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create User
   */
  async userCreateUserRaw(
    requestParameters: UserCreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userAdminCreate === null ||
      requestParameters.userAdminCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "userAdminCreate",
        "Required parameter requestParameters.userAdminCreate was null or undefined when calling userCreateUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserAdminCreateToJSON(requestParameters.userAdminCreate),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Create User
   */
  async userCreateUser(
    requestParameters: UserCreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.userCreateUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create User Email
   */
  async userCreateUserEmailRaw(
    requestParameters: UserCreateUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserEmailRead>> {
    if (
      requestParameters.userEmailPrivateCreate === null ||
      requestParameters.userEmailPrivateCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "userEmailPrivateCreate",
        "Required parameter requestParameters.userEmailPrivateCreate was null or undefined when calling userCreateUserEmail.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.resumePath !== undefined) {
      queryParameters["resume_path"] = requestParameters.resumePath;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserEmailPrivateCreateToJSON(
          requestParameters.userEmailPrivateCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserEmailReadFromJSON(jsonValue),
    );
  }

  /**
   * Create User Email
   */
  async userCreateUserEmail(
    requestParameters: UserCreateUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserEmailRead> {
    const response = await this.userCreateUserEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Delete User Email
   */
  async userDeleteUserEmailRaw(
    requestParameters: UserDeleteUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userEmailId === null ||
      requestParameters.userEmailId === undefined
    ) {
      throw new runtime.RequiredError(
        "userEmailId",
        "Required parameter requestParameters.userEmailId was null or undefined when calling userDeleteUserEmail.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails/{user_email_id}`.replace(
          `{${"user_email_id"}}`,
          encodeURIComponent(String(requestParameters.userEmailId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete User Email
   */
  async userDeleteUserEmail(
    requestParameters: UserDeleteUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userDeleteUserEmailRaw(requestParameters, initOverrides);
  }

  /**
   * Download User Terms
   */
  async userDownloadUserTermsRaw(
    requestParameters: UserDownloadUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userTermsId === null ||
      requestParameters.userTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "userTermsId",
        "Required parameter requestParameters.userTermsId was null or undefined when calling userDownloadUserTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/user-terms/{user_terms_id}/download`.replace(
          `{${"user_terms_id"}}`,
          encodeURIComponent(String(requestParameters.userTermsId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Download User Terms
   */
  async userDownloadUserTerms(
    requestParameters: UserDownloadUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userDownloadUserTermsRaw(requestParameters, initOverrides);
  }

  /**
   * Get User
   */
  async userGetUserRaw(
    requestParameters: UserGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling userGetUser.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.validateForOrdering !== undefined) {
      queryParameters["validate_for_ordering"] =
        requestParameters.validateForOrdering;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Get User
   */
  async userGetUser(
    requestParameters: UserGetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.userGetUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get User Email
   */
  async userGetUserEmailRaw(
    requestParameters: UserGetUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserEmailRead>> {
    if (
      requestParameters.userEmailId === null ||
      requestParameters.userEmailId === undefined
    ) {
      throw new runtime.RequiredError(
        "userEmailId",
        "Required parameter requestParameters.userEmailId was null or undefined when calling userGetUserEmail.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails/{user_email_id}`.replace(
          `{${"user_email_id"}}`,
          encodeURIComponent(String(requestParameters.userEmailId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserEmailReadFromJSON(jsonValue),
    );
  }

  /**
   * Get User Email
   */
  async userGetUserEmail(
    requestParameters: UserGetUserEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserEmailRead> {
    const response = await this.userGetUserEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get User Terms
   */
  async userGetUserTermsRaw(
    requestParameters: UserGetUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserTerms>> {
    if (
      requestParameters.userTermsId === null ||
      requestParameters.userTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "userTermsId",
        "Required parameter requestParameters.userTermsId was null or undefined when calling userGetUserTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/user-terms/{user_terms_id}`.replace(
          `{${"user_terms_id"}}`,
          encodeURIComponent(String(requestParameters.userTermsId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserTermsFromJSON(jsonValue),
    );
  }

  /**
   * Get User Terms
   */
  async userGetUserTerms(
    requestParameters: UserGetUserTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserTerms> {
    const response = await this.userGetUserTermsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Make User Email Primary
   */
  async userMakeUserEmailPrimaryRaw(
    requestParameters: UserMakeUserEmailPrimaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    if (
      requestParameters.userEmailId === null ||
      requestParameters.userEmailId === undefined
    ) {
      throw new runtime.RequiredError(
        "userEmailId",
        "Required parameter requestParameters.userEmailId was null or undefined when calling userMakeUserEmailPrimary.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails/{user_email_id}/make-primary`.replace(
          `{${"user_email_id"}}`,
          encodeURIComponent(String(requestParameters.userEmailId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * Make User Email Primary
   */
  async userMakeUserEmailPrimary(
    requestParameters: UserMakeUserEmailPrimaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.userMakeUserEmailPrimaryRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Merge Users
   */
  async userMergeUsersRaw(
    requestParameters: UserMergeUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.bodyUserMergeUsers === null ||
      requestParameters.bodyUserMergeUsers === undefined
    ) {
      throw new runtime.RequiredError(
        "bodyUserMergeUsers",
        "Required parameter requestParameters.bodyUserMergeUsers was null or undefined when calling userMergeUsers.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users/merge`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyUserMergeUsersToJSON(requestParameters.bodyUserMergeUsers),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Merge Users
   */
  async userMergeUsers(
    requestParameters: UserMergeUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userMergeUsersRaw(requestParameters, initOverrides);
  }

  /**
   * Read Current User
   */
  async userReadCurrentUserRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CurrentUserRead>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CurrentUserReadFromJSON(jsonValue),
    );
  }

  /**
   * Read Current User
   */
  async userReadCurrentUser(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CurrentUserRead> {
    const response = await this.userReadCurrentUserRaw(initOverrides);
    return await response.value();
  }

  /**
   * Search User Emails
   */
  async userSearchUserEmailsRaw(
    requestParameters: UserSearchUserEmailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedUserEmailOut>> {
    const queryParameters: any = {};

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserEmailOutFromJSON(jsonValue),
    );
  }

  /**
   * Search User Emails
   */
  async userSearchUserEmails(
    requestParameters: UserSearchUserEmailsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserEmailOut> {
    const response = await this.userSearchUserEmailsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Users
   */
  async userSearchUsersRaw(
    requestParameters: UserSearchUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedUserOut>> {
    const queryParameters: any = {};

    if (requestParameters.shopId !== undefined) {
      queryParameters["shop_id"] = requestParameters.shopId;
    }

    if (requestParameters.benefitDefinitionId !== undefined) {
      queryParameters["benefit_definition_id"] =
        requestParameters.benefitDefinitionId;
    }

    if (requestParameters.masterPermission !== undefined) {
      queryParameters["master_permission"] = requestParameters.masterPermission;
    }

    if (requestParameters.includeDeleted !== undefined) {
      queryParameters["include_deleted"] = requestParameters.includeDeleted;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Users
   */
  async userSearchUsers(
    requestParameters: UserSearchUsersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserOut> {
    const response = await this.userSearchUsersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Send Email Verification Token
   */
  async userSendEmailVerificationTokenRaw(
    requestParameters: UserSendEmailVerificationTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    if (
      requestParameters.userEmailId === null ||
      requestParameters.userEmailId === undefined
    ) {
      throw new runtime.RequiredError(
        "userEmailId",
        "Required parameter requestParameters.userEmailId was null or undefined when calling userSendEmailVerificationToken.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.resumePath !== undefined) {
      queryParameters["resume_path"] = requestParameters.resumePath;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/emails/{user_email_id}/send-email-verification-token`.replace(
          `{${"user_email_id"}}`,
          encodeURIComponent(String(requestParameters.userEmailId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * Send Email Verification Token
   */
  async userSendEmailVerificationToken(
    requestParameters: UserSendEmailVerificationTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.userSendEmailVerificationTokenRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Send Invitation Email
   */
  async userSendInvitationEmailRaw(
    requestParameters: UserSendInvitationEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling userSendInvitationEmail.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users/{user_id}/send-invitation-email`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send Invitation Email
   */
  async userSendInvitationEmail(
    requestParameters: UserSendInvitationEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userSendInvitationEmailRaw(requestParameters, initOverrides);
  }

  /**
   * Send Phone Verification Code
   */
  async userSendPhoneVerificationCodeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/send-phone-verification-code`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * Send Phone Verification Code
   */
  async userSendPhoneVerificationCode(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.userSendPhoneVerificationCodeRaw(initOverrides);
    return await response.value();
  }

  /**
   * Soft Delete User
   */
  async userSoftDeleteUserRaw(
    requestParameters: UserSoftDeleteUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling userSoftDeleteUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Soft Delete User
   */
  async userSoftDeleteUser(
    requestParameters: UserSoftDeleteUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.userSoftDeleteUserRaw(requestParameters, initOverrides);
  }

  /**
   * Update Current User
   */
  async userUpdateCurrentUserRaw(
    requestParameters: UserUpdateCurrentUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userUpdate === null ||
      requestParameters.userUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "userUpdate",
        "Required parameter requestParameters.userUpdate was null or undefined when calling userUpdateCurrentUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdateToJSON(requestParameters.userUpdate),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Update Current User
   */
  async userUpdateCurrentUser(
    requestParameters: UserUpdateCurrentUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.userUpdateCurrentUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Update User
   */
  async userUpdateUserRaw(
    requestParameters: UserUpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling userUpdateUser.",
      );
    }

    if (
      requestParameters.userUpdate === null ||
      requestParameters.userUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        "userUpdate",
        "Required parameter requestParameters.userUpdate was null or undefined when calling userUpdateUser.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId)),
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdateToJSON(requestParameters.userUpdate),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Update User
   */
  async userUpdateUser(
    requestParameters: UserUpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.userUpdateUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Verify Email
   */
  async userVerifyEmailRaw(
    requestParameters: UserVerifyEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    if (
      requestParameters.verificationToken === null ||
      requestParameters.verificationToken === undefined
    ) {
      throw new runtime.RequiredError(
        "verificationToken",
        "Required parameter requestParameters.verificationToken was null or undefined when calling userVerifyEmail.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/verify-email`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: VerificationTokenToJSON(requestParameters.verificationToken),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * Verify Email
   */
  async userVerifyEmail(
    requestParameters: UserVerifyEmailRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.userVerifyEmailRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Verify Phone Number
   */
  async userVerifyPhoneNumberRaw(
    requestParameters: UserVerifyPhoneNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserRead>> {
    if (
      requestParameters.verificationCode === null ||
      requestParameters.verificationCode === undefined
    ) {
      throw new runtime.RequiredError(
        "verificationCode",
        "Required parameter requestParameters.verificationCode was null or undefined when calling userVerifyPhoneNumber.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/users/me/verify-phone-number`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: VerificationCodeToJSON(requestParameters.verificationCode),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserReadFromJSON(jsonValue),
    );
  }

  /**
   * Verify Phone Number
   */
  async userVerifyPhoneNumber(
    requestParameters: UserVerifyPhoneNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserRead> {
    const response = await this.userVerifyPhoneNumberRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
