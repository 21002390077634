/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
} from "./PaymentServiceProvider";

/**
 *
 * @export
 * @interface BodyPaymentsCreatePaymentConsent
 */
export interface BodyPaymentsCreatePaymentConsent {
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {Currency}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  returnUrl: string;
}

/**
 * Check if a given object implements the BodyPaymentsCreatePaymentConsent interface.
 */
export function instanceOfBodyPaymentsCreatePaymentConsent(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "paymentServiceProvider" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "returnUrl" in value;

  return isInstance;
}

export function BodyPaymentsCreatePaymentConsentFromJSON(
  json: any,
): BodyPaymentsCreatePaymentConsent {
  return BodyPaymentsCreatePaymentConsentFromJSONTyped(json, false);
}

export function BodyPaymentsCreatePaymentConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyPaymentsCreatePaymentConsent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    currency: CurrencyFromJSON(json["currency"]),
    returnUrl: json["return_url"],
  };
}

export function BodyPaymentsCreatePaymentConsentToJSON(
  value?: BodyPaymentsCreatePaymentConsent | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_service_provider: PaymentServiceProviderToJSON(
      value.paymentServiceProvider,
    ),
    currency: CurrencyToJSON(value.currency),
    return_url: value.returnUrl,
  };
}
