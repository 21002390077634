import styled from "styled-components";

export const Spinner = styled.div`
  border: 3px solid ${({ theme }) => theme.palette.common.white};
  border-top: 3px solid ${({ theme }) => theme.palette.primary1.main};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.5s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
