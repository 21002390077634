import styled, { css } from "styled-components";

import { makeTransition } from "../styles";

export const StyledDropdownItemRoot = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary1.main};
  ${makeTransition("all", "shortest", "easeOut")}

  ${(props) =>
    props.disabled
      ? css`
          opacity: ${({ theme }) => theme.opacity.disabled};
          cursor: not-allowed;
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.palette.neutral.lighter};
          }
        `}
`;
