/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BookingCalculation
 */
export interface BookingCalculation {
  /**
   *
   * @type {number}
   * @memberof BookingCalculation
   */
  durationHours: number;
  /**
   *
   * @type {number}
   * @memberof BookingCalculation
   */
  price: number;
}

/**
 * Check if a given object implements the BookingCalculation interface.
 */
export function instanceOfBookingCalculation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "durationHours" in value;
  isInstance = isInstance && "price" in value;

  return isInstance;
}

export function BookingCalculationFromJSON(json: any): BookingCalculation {
  return BookingCalculationFromJSONTyped(json, false);
}

export function BookingCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BookingCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    durationHours: json["duration_hours"],
    price: json["price"],
  };
}

export function BookingCalculationToJSON(
  value?: BookingCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    duration_hours: value.durationHours,
    price: value.price,
  };
}
