import { ComponentType } from "react";

import styled, { css } from "styled-components";

import { Flex } from "../../Flex";
import { SvgIconProps } from "../../Icons";
import { Typography } from "../../Typography";
import { makeTransition } from "../../styles";
import { NavigationButton } from "../NavigationButton";

type MobileNavigationButtonProps = {
  icon: ComponentType<SvgIconProps>;
  label: string;
  isActivated?: boolean;
  disabled?: boolean;
};

export const MobileNavigationButton = ({
  icon: Icon,
  label,
  isActivated = false,
  disabled = false,
}: MobileNavigationButtonProps) => {
  return (
    <StyledRoot
      data-testid="mobile-navigation-button-root"
      direction="column"
      align="center"
      gap="xxs"
      disabled={disabled}
    >
      <StyledNavigationButton isActivated={isActivated} disabled={disabled}>
        <Icon />
      </StyledNavigationButton>
      <Typography variant="bodyXs" weight="bold" disabled={disabled}>
        {label}
      </Typography>
    </StyledRoot>
  );
};

const StyledRoot = styled(Flex)<Pick<MobileNavigationButtonProps, "disabled">>`
  flex: 1;
  cursor: pointer;
  ${makeTransition("all", "shortest", "easeOut")};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};
`;

const StyledNavigationButton = styled(NavigationButton)`
  height: 2rem;
  padding: ${({ theme }) => theme.spacing.xxs}
    ${({ theme }) => theme.spacing.sm};
`;
