import { PagesContainer } from "../PagesContainer";
import { OnboardingPageWrapper } from "../layout/OnboardingPageWrapper";
import { useOnboardingMachine } from "./OnboardingMachineContext";

export function OnboardingContainer() {
  const { goBack, stateMatches, currentStep, totalSteps } =
    useOnboardingMachine();

  const shouldHideHeader =
    stateMatches("welcome") ||
    stateMatches("requestByBusinessId.requestSent") ||
    stateMatches("doneActivationCreated") ||
    stateMatches("doneRequestSent") ||
    stateMatches("loading");

  return (
    <OnboardingPageWrapper
      handleGoBack={goBack}
      shouldHideHeader={shouldHideHeader}
      progress={{ current: currentStep, total: totalSteps }}
    >
      <PagesContainer />
    </OnboardingPageWrapper>
  );
}
