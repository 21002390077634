import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Flex,
  Notification,
  Support3DIcon,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { AuthLayout } from "../AuthLayout";
import { useVerifyEmailPage } from "./useVerifyEmailPage";

export function VerifyEmailPage() {
  const { t } = useTranslation();

  const {
    activeOrPrimaryEmail,
    handleSendEmailVerificationToken,
    isLoading,
    status,
    statusMessage,
    handleBackToSignUp,
  } = useVerifyEmailPage();

  return (
    <AuthLayout
      title={t("authV2:verifyEmail.title")}
      description={t("authV2:verifyEmail.description", {
        emailAddress: activeOrPrimaryEmail?.email,
      })}
      icon={<Support3DIcon size="md" />}
    >
      <Flex direction="column" gap="md">
        <Typography variant="bodyLg" align="center">
          <span>{t("authV2:verifyEmail.didNotReceiveEmail")}</span>{" "}
          <TextButton
            onClick={handleSendEmailVerificationToken}
            disabled={isLoading}
          >
            {isLoading
              ? t("authV2:verifyEmail.sending")
              : t("authV2:verifyEmail.sendAgain")}
          </TextButton>
        </Typography>
        {statusMessage && (
          <Notification message={statusMessage} type={status} />
        )}
        <StyledFooterWrapper direction="column">
          <TextButton onClick={handleBackToSignUp} align="center">
            {t("authV2:verifyEmail.backToSignUp")}
          </TextButton>
        </StyledFooterWrapper>
      </Flex>
    </AuthLayout>
  );
}

const StyledFooterWrapper = styled(Flex)`
  padding: ${({ theme }) => theme.spacing.md};
  border-top: ${({ theme }) => theme.border.primary.light};
`;
