import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  useEffect,
  useState,
} from "react";

import { isNumber, isString } from "lodash-es";

import { Input, InputProps } from "./Input";

export interface NumberInputProps extends Omit<InputProps, "onChange"> {
  onChange?: (event: ChangeEvent<HTMLInputElement>, value?: number) => void;
  decimals?: number;
  defaultValue?: number;
}

export function valueToSafeString(value?: string | number): string {
  if (!value && value !== 0) return "";
  if (typeof value === "string") return value.replace(",", ".");
  return String(value);
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      name,
      onChange,
      onFocus,
      onBlur,
      decimals = 2,
      value: userValue,
      defaultValue,
      ...rest
    },
    ref,
  ) {
    const parseFn = decimals > 0 ? parseFloat : parseInt;
    const pattern =
      decimals > 0 ? `^-?[0-9]*([.,][0-9]{0,${decimals}})?$` : "^-?[0-9]*$";

    const numericUserValue =
      isNumber(userValue) && isFinite(userValue)
        ? userValue
        : isString(userValue)
        ? parseFn(userValue)
        : undefined;

    const [value, setValue] = useState(
      (numericUserValue ?? defaultValue)?.toFixed(decimals) ?? "",
    );
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      const currentValue = numericUserValue ?? defaultValue;
      if (currentValue && !focused) {
        setValue(currentValue.toFixed(decimals));
      }
    }, [numericUserValue, defaultValue, decimals, setValue]);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
      const { value } = event.target;
      const regExp = new RegExp(pattern);
      if (!regExp.test(value)) return; // if the value is not a number or a decimal we don't do anything

      setValue(value);
      onChange?.(
        event,
        value === "" ? undefined : parseFn(valueToSafeString(value)),
      );
    }

    function handleFocus(event: FocusEvent<HTMLInputElement>) {
      setFocused(true);
      onFocus?.(event);
    }

    function handleBlur(event: FocusEvent<HTMLInputElement>) {
      const { value } = event.target;
      setFocused(false);
      setValue(
        numericUserValue?.toFixed(decimals) ??
          (value === ""
            ? ""
            : parseFn(valueToSafeString(value)).toFixed(decimals)),
      );
      onBlur?.(event);
    }

    return (
      <Input
        {...rest}
        name={name}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        inputMode={decimals > 0 ? "decimal" : "numeric"}
        type="text"
        pattern={pattern}
      />
    );
  },
);
