import { useTranslation } from "react-i18next";

import {
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/api-codegen";
import { Checkbox } from "@vapaus/form";
import { Card, Flex } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../packages/i18n/src/hooks";
import { NameValueDescription } from "../../../components/NameValueDescription";
import { DOWN_PAYMENT_INSURANCE_PERCENTAGE } from "../../../constants/order";

interface DownPaymentCardProps {
  order: BikeBenefitOrderRead;
}

export function DownPaymentCard({ order }: DownPaymentCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const formatPercent = usePercentFormat();

  if (!order.fixedDownPaymentAmount) return null;

  return (
    <Card title={t("userV2:myBike.downPaymentCard.title")}>
      <Flex direction="column" gap="md">
        <NameValueDescription
          name={t("userV2:myBike.downPaymentCard.downPayment.name")}
          value={formatCurrency(order.fixedDownPaymentAmount)}
          description={t(
            "userV2:myBike.downPaymentCard.downPayment.description",
          )}
        />
        {order.state === BikeBenefitOrderState.Draft ? (
          <Checkbox
            name="isDownPaymentInsured"
            label={
              <NameValueDescription
                name={t(
                  "userV2:myBike.downPaymentCard.downPaymentInsurance.name",
                )}
                value={t("userV2:myBike.downPaymentCard.downPayment.value", {
                  value: formatCurrency(
                    order.fixedDownPaymentAmount *
                      DOWN_PAYMENT_INSURANCE_PERCENTAGE,
                  ),
                })}
                description={t(
                  "userV2:myBike.downPaymentCard.downPaymentInsurance.description",
                  { value: formatPercent(DOWN_PAYMENT_INSURANCE_PERCENTAGE) },
                )}
              />
            }
          />
        ) : order.isDownPaymentInsured ? (
          <NameValueDescription
            name={t("userV2:myBike.downPaymentCard.downPaymentInsurance.name")}
            value={t("userV2:myBike.downPaymentCard.downPayment.value", {
              value: formatCurrency(
                order.fixedDownPaymentAmount *
                  DOWN_PAYMENT_INSURANCE_PERCENTAGE,
              ),
            })}
            description={t(
              "userV2:myBike.downPaymentCard.downPaymentInsurance.insuredDescription",
              { value: formatPercent(DOWN_PAYMENT_INSURANCE_PERCENTAGE) },
            )}
          />
        ) : null}
      </Flex>
    </Card>
  );
}
