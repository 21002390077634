/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AdditionalServiceType } from "./AdditionalServiceType";
import {
  AdditionalServiceTypeFromJSON,
  AdditionalServiceTypeFromJSONTyped,
  AdditionalServiceTypeToJSON,
} from "./AdditionalServiceType";

/**
 *
 * @export
 * @interface AdditionalService
 */
export interface AdditionalService {
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  bikeBenefitOrderId: string;
  /**
   *
   * @type {AdditionalServiceType}
   * @memberof AdditionalService
   */
  serviceType: AdditionalServiceType;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof AdditionalService
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof AdditionalService
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the AdditionalService interface.
 */
export function instanceOfAdditionalService(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitOrderId" in value;
  isInstance = isInstance && "serviceType" in value;
  isInstance = isInstance && "priceGross" in value;
  isInstance = isInstance && "vatRate" in value;

  return isInstance;
}

export function AdditionalServiceFromJSON(json: any): AdditionalService {
  return AdditionalServiceFromJSONTyped(json, false);
}

export function AdditionalServiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdditionalService {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitOrderId: json["bike_benefit_order_id"],
    serviceType: AdditionalServiceTypeFromJSON(json["service_type"]),
    description: !exists(json, "description") ? undefined : json["description"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function AdditionalServiceToJSON(value?: AdditionalService | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_order_id: value.bikeBenefitOrderId,
    service_type: AdditionalServiceTypeToJSON(value.serviceType),
    description: value.description,
    price_gross: value.priceGross,
    vat_rate: value.vatRate,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
