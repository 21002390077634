import { AdditionalService, AdditionalServiceType } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";

export function useGetAdditionalServiceLabel() {
  const { getEnumLabel } = useEnumEntries("AdditionalServiceType");

  const getAdditionalServiceLabel = (additionalService: AdditionalService) => {
    if (
      additionalService.serviceType === AdditionalServiceType.Other &&
      additionalService.description
    ) {
      return additionalService.description;
    }

    return getEnumLabel(additionalService.serviceType);
  };

  return {
    getAdditionalServiceLabel,
  };
}
