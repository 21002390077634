import { createSvgIcon } from "./SvgIcon";

export const SupportIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM9.1 19.45L10.3 16.7C9.6 16.45 8.99583 16.0625 8.4875 15.5375C7.97917 15.0125 7.58333 14.4 7.3 13.7L4.55 14.85C4.93333 15.9167 5.525 16.85 6.325 17.65C7.125 18.45 8.05 19.05 9.1 19.45ZM7.3 10.3C7.58333 9.6 7.97917 8.9875 8.4875 8.4625C8.99583 7.9375 9.6 7.55 10.3 7.3L9.15 4.55C8.08333 4.95 7.15 5.55 6.35 6.35C5.55 7.15 4.95 8.08333 4.55 9.15L7.3 10.3ZM12 15C12.8333 15 13.5417 14.7083 14.125 14.125C14.7083 13.5417 15 12.8333 15 12C15 11.1667 14.7083 10.4583 14.125 9.875C13.5417 9.29167 12.8333 9 12 9C11.1667 9 10.4583 9.29167 9.875 9.875C9.29167 10.4583 9 11.1667 9 12C9 12.8333 9.29167 13.5417 9.875 14.125C10.4583 14.7083 11.1667 15 12 15ZM14.9 19.45C15.95 19.05 16.8708 18.4542 17.6625 17.6625C18.4542 16.8708 19.05 15.95 19.45 14.9L16.7 13.7C16.45 14.4 16.0667 15.0042 15.55 15.5125C15.0333 16.0208 14.4333 16.4167 13.75 16.7L14.9 19.45ZM16.7 10.25L19.45 9.1C19.05 8.05 18.4542 7.12917 17.6625 6.3375C16.8708 5.54583 15.95 4.95 14.9 4.55L13.75 7.35C14.4333 7.6 15.025 7.97917 15.525 8.4875C16.025 8.99583 16.4167 9.58333 16.7 10.25Z" />
  </svg>,
  "Support",
);
