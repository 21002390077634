import styled from "styled-components";

import { SpacingVariants } from "../styles";

export const HorizontalLine = styled.div<{
  marginTop?: SpacingVariants;
  marginBottom?: SpacingVariants;
}>`
  border-bottom: ${({ theme }) => theme.border.primary.light};
  margin-top: ${({ theme, marginTop = "md" }) => theme.spacing[marginTop]};
  margin-bottom: ${({ theme, marginBottom = "md" }) =>
    theme.spacing[marginBottom]};
`;
