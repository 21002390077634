import { ForwardedRef, forwardRef } from "react";

import { Option, optionClasses } from "@mui/base/Option";
import { Select, SelectProps, SelectRootSlotProps } from "@mui/base/Select";
import styled from "styled-components";

import { LanguageIcon } from "../Icons";
import { makeTransition } from "../styles";

function CustomSelect<TValue extends string, Multiple extends boolean>({
  variant,
  ...props
}: SelectProps<TValue, Multiple> & Pick<LanguageSelectCustomProps, "variant">) {
  const slots: SelectProps<TValue, Multiple>["slots"] = {
    root: CustomButton,
    listbox: StyledListbox,
    ...props.slots,
  };

  return (
    <Select<TValue, Multiple, typeof StyledButton>
      {...props}
      slots={slots}
      variant={variant}
    />
  );
}

const StyledOption = styled(Option)`
  ${makeTransition("background-color", "standard", "easeInOut")}
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.radius.languageSelect};
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }

  &.${optionClasses.selected} {
    background-color: ${({ theme }) => theme.palette.neutral.main};
    cursor: default;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.light};
  }

  &.${optionClasses.highlighted} {
    background-color: ${({ theme }) => theme.palette.neutral.light};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${({ theme }) => theme.palette.neutral.main};
    cursor: default;
  }
`;

const StyledListbox = styled.ul`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.palette.background.white};
  border-radius: ${({ theme }) => theme.radius.languageSelect};
  overflow: auto;
  outline: 0px;
  list-style: none;
`;

type StyledButtonProps = Required<Pick<LanguageSelectCustomProps, "variant">>;
const StyledButton = styled.button<StyledButtonProps>`
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
  text-transform: ${({ theme }) => theme.typography.button.textTransform};
  border-radius: ${({ theme }) => theme.radius.languageSelect};
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.palette.background.white};
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  color: ${({ theme, variant }) => theme.palette[variant].main};
  box-sizing: border-box;
  cursor: pointer;
  gap: 4px;
  line-height: 1;
  display: flex;
  align-items: center;
  ${makeTransition("all", "standard", "easeInOut")}

  &:hover, &:focus {
    border-color: ${({ theme, variant }) => theme.palette[variant].main};
    color: ${({ theme, variant }) => theme.palette[variant].main};
  }

  &:active {
    color: ${({ theme, variant }) => theme.palette[variant].main};
    border-color: transparent;
    box-shadow: ${({ theme }) => theme.shadows.inset};
  }
`;

const CustomButton = forwardRef(function CustomButton<
  TValue extends string,
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple> &
    Required<Pick<LanguageSelectCustomProps, "variant">>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <StyledButton {...props} ref={ref}>
      <LanguageIcon color={`${props.variant}.main`} /> {props.children}
    </StyledButton>
  );
});

export type LanguageSelectVariants = "primary1" | "primary2";

type LanguageSelectCustomProps = {
  options: Array<{ value: string; label: string }>;
  variant?: LanguageSelectVariants;
};

export type LanguageSelectProps<
  TValue extends string,
  Multiple extends boolean,
> = SelectProps<TValue, Multiple> & LanguageSelectCustomProps;

export function LanguageSelect({
  value,
  options,
  onChange,
  variant = "primary1",
}: LanguageSelectProps<string, false>) {
  return (
    <CustomSelect<string, false>
      value={value}
      variant={variant}
      onChange={onChange}
    >
      {options.map((option) => (
        <StyledOption key={option.value} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </CustomSelect>
  );
}
