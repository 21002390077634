import { createSvgIcon } from "./SvgIcon";

export const FacebookIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 12.0604C22 6.53792 17.5225 2.06042 12 2.06042C6.4775 2.06042 2 6.53792 2 12.0604C2 17.0521 5.65667 21.1888 10.4375 21.9388V14.9513H7.89833V12.0596H10.4375V9.85792C10.4375 7.35209 11.9308 5.96709 14.215 5.96709C15.3083 5.96709 16.4533 6.16292 16.4533 6.16292V8.62376H15.1917C13.9492 8.62376 13.5617 9.39459 13.5617 10.1854V12.0604H16.335L15.8917 14.9521H13.5617V21.9396C18.3433 21.1888 22 17.0513 22 12.0604Z" />
  </svg>,
  "Facebook",
);
