import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useCurrentUserEmails } from "@vapaus/shared-api";
import { Flex, LineItem, Typography } from "@vapaus/ui-v2";

import { AddNewEmailModal } from "./AddNewEmailModal";
import { AlternateEmailItem } from "./AlternateEmailItem";

export function AlternateEmailList() {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const { t } = useTranslation();
  const { data } = useCurrentUserEmails();
  const alternateEmails = (data?.items ?? []).filter((email) => !email.primary);

  return (
    <Flex direction="column" gap="md">
      <LineItem
        name={t("commonV2:alternateEmail.alternateEmailAddress")}
        content={
          <Typography>{t("commonV2:alternateEmail.description")}</Typography>
        }
        action={{
          label: t("commonV2:add"),
          onClick: () => setIsOpenAddModal(true),
        }}
      />

      <Flex direction="column" gap="md">
        {alternateEmails.map((email) => (
          <AlternateEmailItem key={email.id} email={email} />
        ))}
      </Flex>
      <AddNewEmailModal
        isOpen={isOpenAddModal}
        onClose={() => setIsOpenAddModal(false)}
      />
    </Flex>
  );
}
