import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ErrorPage } from "../ErrorPage";

export function ResetPasswordLinkExpiredErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleResetPassword = () => navigate("/forgot-password");
  const handleLogIn = () => navigate("/sign-in");

  return (
    <ErrorPage
      title={t("authV2:error.resetPasswordLinkExpiredErrorPage.title")}
      description={t(
        "authV2:error.resetPasswordLinkExpiredErrorPage.description",
      )}
      primaryButton={{
        text: t("authV2:error.resetPasswordLinkExpiredErrorPage.resetPassword"),
        onClick: handleResetPassword,
      }}
      secondaryButton={{
        text: t("authV2:error.resetPasswordLinkExpiredErrorPage.logIn"),
        onClick: handleLogIn,
      }}
    />
  );
}
