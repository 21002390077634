/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const AccessoryType = {
  Bell: "bell",
  BikeStand: "bike-stand",
  AttachableBikeStand: "attachable-bike-stand",
  CarrierRack: "carrier-rack",
  Handlebar: "handlebar",
  Helmet: "helmet",
  Lamps: "lamps",
  Lock: "lock",
  Mudguard: "mudguard",
  FendersMudguards: "fenders-mudguards",
  Other: "other",
  Pedals: "pedals",
  Saddle: "saddle",
  SteeringBracket: "steering-bracket",
  StuddedTires: "studded-tires",
  BagsBackpacks: "bags-backpacks",
  ClothingPersonalAccessories: "clothing-personal-accessories",
  Bottles: "bottles",
  BottleRack: "bottle-rack",
  Pumps: "pumps",
  ToolsSupplies: "tools-supplies",
  SpareParts: "spare-parts",
  ExtraPedalsDiscs: "extra-pedals-discs",
  Trailer: "trailer",
  ChildSeatsTrailers: "child-seats-trailers",
  TripComputersWattmetersCranks: "trip-computers-wattmeters-cranks",
} as const;
export type AccessoryType = (typeof AccessoryType)[keyof typeof AccessoryType];

export function AccessoryTypeFromJSON(json: any): AccessoryType {
  return AccessoryTypeFromJSONTyped(json, false);
}

export function AccessoryTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccessoryType {
  return json as AccessoryType;
}

export function AccessoryTypeToJSON(value?: AccessoryType | null): any {
  return value as any;
}
