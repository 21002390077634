import { MailIcon } from "../Icons";
import { Typography } from "../Typography";
import { FieldRoot, FieldRootProps } from "./Field";

interface EmailFieldProps extends Pick<FieldRootProps, "marginBottom" | "gap"> {
  email: string;
}

export function EmailField({ email, marginBottom, gap }: EmailFieldProps) {
  return (
    <FieldRoot
      marginBottom={marginBottom}
      as="a"
      href={`mailto:${email}`}
      gap={gap}
    >
      <MailIcon />
      <Typography weight="bold">{email}</Typography>
    </FieldRoot>
  );
}
