/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  city?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof UserCreate
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof UserCreate
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof UserCreate
   */
  fleetAccessCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  acceptedUserTermsId: string;
}

/**
 * Check if a given object implements the UserCreate interface.
 */
export function instanceOfUserCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "password" in value;
  isInstance = isInstance && "acceptedUserTermsId" in value;

  return isInstance;
}

export function UserCreateFromJSON(json: any): UserCreate {
  return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"],
    address: !exists(json, "address") ? undefined : json["address"],
    postCode: !exists(json, "post_code") ? undefined : json["post_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    country: !exists(json, "country")
      ? undefined
      : SupportedCountriesEnumFromJSON(json["country"]),
    language: !exists(json, "language")
      ? undefined
      : SupportedLanguagesEnumFromJSON(json["language"]),
    fleetAccessCodes: !exists(json, "fleet_access_codes")
      ? undefined
      : json["fleet_access_codes"],
    acceptedUserTermsId: json["accepted_user_terms_id"],
  };
}

export function UserCreateToJSON(value?: UserCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    address: value.address,
    post_code: value.postCode,
    city: value.city,
    country: SupportedCountriesEnumToJSON(value.country),
    language: SupportedLanguagesEnumToJSON(value.language),
    fleet_access_codes: value.fleetAccessCodes,
    accepted_user_terms_id: value.acceptedUserTermsId,
  };
}
