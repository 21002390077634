import { DateTime } from "luxon";

export function getIsContractEndingSoon(endDate?: Date) {
  if (!endDate) return false;
  const diffInMonths = DateTime.fromJSDate(endDate).diff(
    DateTime.now(),
    "months",
  ).months;
  return diffInMonths <= 1;
}
