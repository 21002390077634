import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Notification, Select } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function SelectBikeBenefitPage() {
  const { t } = useTranslation();
  const { selectedEmployerBenefits, selectedBenefit, setBenefit, goToNext } =
    useOnboardingMachine();

  const benefits = selectedEmployerBenefits.map((benefit) => {
    const statusInfo = !benefit.isAvailable
      ? t("userV2:onboarding.selectBenefit.isNotAvailable")
      : benefit.isPendingRequest
      ? t("userV2:onboarding.selectBenefit.isPendingRequest")
      : "";

    return {
      value: benefit.id,
      label: benefit.name + statusInfo,
    };
  });

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.selectBenefit.title")}
      subtitle={t("userV2:onboarding.selectBenefit.subtitle")}
    >
      <StyledContainer direction="column" gap="md">
        <Select
          options={benefits}
          required
          label={t("userV2:onboarding.selectBenefit.label")}
          tooltip={t("userV2:onboarding.selectBenefit.tooltip")}
          placeholder={t("userV2:onboarding.selectBenefit.placeholder")}
          value={selectedBenefit?.id}
          onChange={(_, value) => setBenefit(value!)}
          fullWidth
        />
        {selectedBenefit && !selectedBenefit.isAvailable ? (
          <Notification
            type="error"
            message={t(
              "userV2:onboarding.selectBenefit.notification.alreadyAdded",
            )}
          />
        ) : null}
        {selectedBenefit && selectedBenefit.isPendingRequest ? (
          <Notification
            type="error"
            message={t(
              "userV2:onboarding.selectBenefit.notification.requestPending",
            )}
          />
        ) : null}
        <Button
          fullWidth
          onClick={goToNext}
          disabled={
            !selectedBenefit ||
            !selectedBenefit.isAvailable ||
            selectedBenefit.isPendingRequest
          }
        >
          {t("userV2:onboarding.next")}
        </Button>
      </StyledContainer>
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled(Flex)`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
