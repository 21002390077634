import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";

import type {
  CurrentUserRead,
  Msg,
  PaginatedUserEmailOut,
  ProviderTerms,
  ResponseError,
  ShopTerms,
  UserTerms,
} from "@vapaus/api-codegen";
import {
  ProviderTermsApi,
  ShopTermsApi,
  UserApi,
  UserSendEmailVerificationTokenRequest,
  UserTermsApi,
} from "@vapaus/api-codegen";

import { AppName } from "../constants";
import { apiConfiguration } from "./_configuration";

const userApi = new UserApi(apiConfiguration);
const userTermsApi = new UserTermsApi(apiConfiguration);
const shopTermsApi = new ShopTermsApi(apiConfiguration);
const providerTermsApi = new ProviderTermsApi(apiConfiguration);

// FIXME: This is duplicated in the shared api packages,
// but we can't use the one from the shared packages here as it create a circular dependency.
// It would be nice to remove the need to import some stuff from the auth package into the shared api
export const useCurrentUser = (
  options?: Omit<
    UseQueryOptions<CurrentUserRead, ResponseError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<CurrentUserRead, ResponseError>(
    ["currentUser"],
    () => userApi.userReadCurrentUser(),
    { retry: 0, ...options },
  );

export const useCurrentAppTerm = (
  app: AppName = "user",
  options?: Omit<
    UseQueryOptions<UserTerms | ShopTerms | ProviderTerms, ResponseError>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryFn = {
    user: () => userTermsApi.userTermsCurrentUserTerms(),
    shop: () => shopTermsApi.shopTermsCurrentShopTerms(),
    provider: () => providerTermsApi.providerTermsCurrentProviderTerms(),
  };
  return useQuery<UserTerms | ShopTerms | ProviderTerms, ResponseError>(
    ["currentAppTerms"],
    () => queryFn[app](),
    options,
  );
};

export const useAcceptAppTerms = (app: AppName) => {
  const mutationFn = {
    user: (userTermsId: string) => userApi.userAcceptUserTerms({ userTermsId }),
    shop: (shopTermsId: string) =>
      shopTermsApi.shopTermsAcceptShopTerms({ shopTermsId }),
    provider: (providerTermsId: string) =>
      providerTermsApi.providerTermsAcceptProviderTerms({ providerTermsId }),
  };
  return useMutation<void, ResponseError, string>(mutationFn[app]);
};

export const useVerifyUserEmail = () =>
  useMutation<Msg, ResponseError, string>((token: string) =>
    userApi.userVerifyEmail({ verificationToken: { token } }),
  );

export const useCurrentUserEmails = (
  options?: Omit<
    UseQueryOptions<PaginatedUserEmailOut, ResponseError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedUserEmailOut, ResponseError>(
    ["currentUserEmails"],
    () => userApi.userSearchUserEmails(),
    options,
  );

export const useGetUserActiveOrCurrentEmail = (
  options?: Omit<
    UseQueryOptions<PaginatedUserEmailOut, ResponseError>,
    "queryKey" | "queryFn"
  >,
) => {
  const { data: emails, isLoading } = useCurrentUserEmails(options);
  const email =
    (emails?.items || []).find(
      (email) => email.emailVerified || email.primary,
    ) ?? null;
  return { email, isLoading };
};

export const useSendEmailVerificationToken = () =>
  useMutation<Msg, ResponseError, UserSendEmailVerificationTokenRequest>(
    (data) => userApi.userSendEmailVerificationToken(data),
  );
