import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { BikeCenterInfoDefaultView } from "./components/BikeCenterInfoDefaultView";
import { BikeCenterInfoSwedenView } from "./components/BikeCenterInfoSwedenView";

export function BikeCenterInfoView() {
  const { pickupAddressDetails } = useReturnProcessContext();

  return pickupAddressDetails.country === "FI" ? (
    <BikeCenterInfoDefaultView />
  ) : (
    <BikeCenterInfoSwedenView />
  );
}
