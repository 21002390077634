/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const SaleInvoiceStatus = {
  Open: "open",
  Paid: "paid",
  Due: "due",
  Overdue: "overdue",
} as const;
export type SaleInvoiceStatus =
  (typeof SaleInvoiceStatus)[keyof typeof SaleInvoiceStatus];

export function SaleInvoiceStatusFromJSON(json: any): SaleInvoiceStatus {
  return SaleInvoiceStatusFromJSONTyped(json, false);
}

export function SaleInvoiceStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceStatus {
  return json as SaleInvoiceStatus;
}

export function SaleInvoiceStatusToJSON(value?: SaleInvoiceStatus | null): any {
  return value as any;
}
