import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";

import { useGetBenefitDefinitionAccessRequests } from "../../api/benefitDefinitionAccessRequests";
import { useGetBenefitDefinitionsActivations } from "../../api/benefitDefinitions";
import { PageLayout } from "../../components/PageLayout";
import { BenefitsListPage } from "./components/BenefitsListPage";
import { EmptyPage } from "./components/EmptyPage";
import { PendingRequestPage } from "./components/PendingRequestPage";

export function BenefitsPage() {
  const { data: accessRequests } = useGetBenefitDefinitionAccessRequests();
  const { data: activations } = useGetBenefitDefinitionsActivations();

  const isOnlyOnePendingRequest =
    activations?.total === 0 &&
    accessRequests?.total === 1 &&
    accessRequests.items[0].status ===
      BenefitDefinitionAccessRequestStatus.Pending;

  if (!accessRequests && !activations) {
    return null;
  }

  return (
    <PageLayout>
      {accessRequests?.total === 0 && activations?.total === 0 ? (
        <EmptyPage />
      ) : isOnlyOnePendingRequest ? (
        <PendingRequestPage accessRequest={accessRequests.items[0]} />
      ) : (
        <BenefitsListPage
          accessRequests={accessRequests?.items || []}
          activations={activations?.items || []}
        />
      )}
    </PageLayout>
  );
}
