import { useTranslation } from "react-i18next";

import { PaymentConsentStatus } from "@vapaus/api-codegen";
import { Card, Flex, HorizontalLine } from "@vapaus/ui-v2";

import { useSearchPaymentConsents } from "../../../../api/paymentConsent";
import { AddCardButton } from "./AddCardButton";
import { PaymentCardItem } from "./PaymentCardItem";

export function PaymentCards() {
  const { t } = useTranslation();
  const { data } = useSearchPaymentConsents({
    status: new Set<PaymentConsentStatus>(["confirmed", "pending"]),
  });

  const paymentConsents = data?.items || [];

  return (
    <Card
      title={t("userV2:paymentCard.title")}
      subtitle={t("userV2:paymentCard.description")}
    >
      <Flex direction="column" align="flex-start" gap="lg">
        {paymentConsents.length ? (
          <Flex direction="column" gap="none" fullWidth>
            {paymentConsents.map((paymentConsent, i) => (
              <Flex key={paymentConsent.id} direction="column" gap="none">
                <PaymentCardItem paymentConsent={paymentConsent} />
                {i !== paymentConsents.length - 1 && (
                  <HorizontalLine marginBottom="lg" marginTop="lg" />
                )}
              </Flex>
            ))}
          </Flex>
        ) : null}
        <AddCardButton />
      </Flex>
    </Card>
  );
}
