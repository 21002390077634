import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Input } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { ProfileData, useOnboardingMachine } from "../stateMachine";

export function UserProfilePage() {
  const { t } = useTranslation();
  const { profileData, isLoading, saveProfileData, setProfileData, goToNext } =
    useOnboardingMachine();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<ProfileData>({ defaultValues: profileData });

  const handleSaveProfile = () => (!isDirty ? goToNext() : saveProfileData());

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.userProfile.title")}
      subtitle={t("userV2:onboarding.userProfile.subtitle")}
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(handleSaveProfile)}>
          <Flex direction="column" gap="md" align="center">
            <Input
              fullWidth
              placeholder={t("userV2:onboarding.userProfile.firstName")}
              label={t("userV2:onboarding.userProfile.firstName")}
              {...register("firstName", {
                required: true,
                onChange: (e) => setProfileData({ firstName: e.target.value }),
              })}
            />
            <Input
              fullWidth
              placeholder={t("userV2:onboarding.userProfile.lastName")}
              label={t("userV2:onboarding.userProfile.lastName")}
              {...register("lastName", {
                required: true,
                onChange: (e) => setProfileData({ lastName: e.target.value }),
              })}
            />
            <Input
              fullWidth
              placeholder={t("userV2:onboarding.userProfile.streetAddress")}
              label={t("userV2:onboarding.userProfile.streetAddress")}
              {...register("address", {
                required: true,
                onChange: (e) => setProfileData({ address: e.target.value }),
              })}
            />
            <Input
              fullWidth
              placeholder={t("userV2:onboarding.userProfile.postalCode")}
              label={t("userV2:onboarding.userProfile.postalCode")}
              {...register("postCode", {
                required: true,
                onChange: (e) => setProfileData({ postCode: e.target.value }),
              })}
            />
            <Input
              fullWidth
              placeholder={t("userV2:onboarding.userProfile.city")}
              label={t("userV2:onboarding.userProfile.city")}
              {...register("city", {
                required: true,
                onChange: (e) => setProfileData({ city: e.target.value }),
              })}
            />
            <Button
              type="submit"
              fullWidth
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
    </OnboardingPageLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
