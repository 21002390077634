/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionSummaryRead } from "./BenefitDefinitionSummaryRead";
import {
  BenefitDefinitionSummaryReadFromJSON,
  BenefitDefinitionSummaryReadFromJSONTyped,
  BenefitDefinitionSummaryReadToJSON,
} from "./BenefitDefinitionSummaryRead";
import type { MasterPermissions } from "./MasterPermissions";
import {
  MasterPermissionsFromJSON,
  MasterPermissionsFromJSONTyped,
  MasterPermissionsToJSON,
} from "./MasterPermissions";
import type { ShopRead } from "./ShopRead";
import {
  ShopReadFromJSON,
  ShopReadFromJSONTyped,
  ShopReadToJSON,
} from "./ShopRead";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface CurrentUserRead
 */
export interface CurrentUserRead {
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  lastName?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof CurrentUserRead
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  phoneNumberVerified?: boolean;
  /**
   *
   * @type {Array<MasterPermissions>}
   * @memberof CurrentUserRead
   */
  masterPermissions?: Array<MasterPermissions>;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  ssn?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  employeeNumber?: string;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof CurrentUserRead
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  isAnonymous?: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedUserTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedShopTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  acceptedProviderTermsId?: string;
  /**
   * array of codes to access fleets.
   * @type {Array<string>}
   * @memberof CurrentUserRead
   */
  fleetAccessCodes?: Array<string>;
  /**
   * array of codes to allow access to benefit definitions.
   * @type {Array<string>}
   * @memberof CurrentUserRead
   */
  benefitDefinitionAccessCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserRead
   */
  isDeleted: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentUserTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentShopTermsId?: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUserRead
   */
  currentProviderTermsId?: string;
  /**
   *
   * @type {Array<ShopRead>}
   * @memberof CurrentUserRead
   */
  managedShops: Array<ShopRead>;
  /**
   *
   * @type {Array<BenefitDefinitionSummaryRead>}
   * @memberof CurrentUserRead
   */
  managedBenefits: Array<BenefitDefinitionSummaryRead>;
}

/**
 * Check if a given object implements the CurrentUserRead interface.
 */
export function instanceOfCurrentUserRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "isDeleted" in value;
  isInstance = isInstance && "managedShops" in value;
  isInstance = isInstance && "managedBenefits" in value;

  return isInstance;
}

export function CurrentUserReadFromJSON(json: any): CurrentUserRead {
  return CurrentUserReadFromJSONTyped(json, false);
}

export function CurrentUserReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CurrentUserRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    country: !exists(json, "country")
      ? undefined
      : SupportedCountriesEnumFromJSON(json["country"]),
    address: !exists(json, "address") ? undefined : json["address"],
    postCode: !exists(json, "post_code") ? undefined : json["post_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    phoneNumberVerified: !exists(json, "phone_number_verified")
      ? undefined
      : json["phone_number_verified"],
    masterPermissions: !exists(json, "master_permissions")
      ? undefined
      : (json["master_permissions"] as Array<any>).map(
          MasterPermissionsFromJSON,
        ),
    ssn: !exists(json, "ssn") ? undefined : json["ssn"],
    company: !exists(json, "company") ? undefined : json["company"],
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
    language: !exists(json, "language")
      ? undefined
      : SupportedLanguagesEnumFromJSON(json["language"]),
    isAnonymous: !exists(json, "is_anonymous")
      ? undefined
      : json["is_anonymous"],
    acceptedUserTermsId: !exists(json, "accepted_user_terms_id")
      ? undefined
      : json["accepted_user_terms_id"],
    acceptedShopTermsId: !exists(json, "accepted_shop_terms_id")
      ? undefined
      : json["accepted_shop_terms_id"],
    acceptedProviderTermsId: !exists(json, "accepted_provider_terms_id")
      ? undefined
      : json["accepted_provider_terms_id"],
    fleetAccessCodes: !exists(json, "fleet_access_codes")
      ? undefined
      : json["fleet_access_codes"],
    benefitDefinitionAccessCodes: !exists(
      json,
      "benefit_definition_access_codes",
    )
      ? undefined
      : json["benefit_definition_access_codes"],
    id: json["id"],
    email: json["email"],
    isDeleted: json["is_deleted"],
    fullName: !exists(json, "full_name") ? undefined : json["full_name"],
    currentUserTermsId: !exists(json, "current_user_terms_id")
      ? undefined
      : json["current_user_terms_id"],
    currentShopTermsId: !exists(json, "current_shop_terms_id")
      ? undefined
      : json["current_shop_terms_id"],
    currentProviderTermsId: !exists(json, "current_provider_terms_id")
      ? undefined
      : json["current_provider_terms_id"],
    managedShops: (json["managed_shops"] as Array<any>).map(ShopReadFromJSON),
    managedBenefits: (json["managed_benefits"] as Array<any>).map(
      BenefitDefinitionSummaryReadFromJSON,
    ),
  };
}

export function CurrentUserReadToJSON(value?: CurrentUserRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    country: SupportedCountriesEnumToJSON(value.country),
    address: value.address,
    post_code: value.postCode,
    city: value.city,
    phone_number: value.phoneNumber,
    phone_number_verified: value.phoneNumberVerified,
    master_permissions:
      value.masterPermissions === undefined
        ? undefined
        : (value.masterPermissions as Array<any>).map(MasterPermissionsToJSON),
    ssn: value.ssn,
    company: value.company,
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
    language: SupportedLanguagesEnumToJSON(value.language),
    is_anonymous: value.isAnonymous,
    accepted_user_terms_id: value.acceptedUserTermsId,
    accepted_shop_terms_id: value.acceptedShopTermsId,
    accepted_provider_terms_id: value.acceptedProviderTermsId,
    fleet_access_codes: value.fleetAccessCodes,
    benefit_definition_access_codes: value.benefitDefinitionAccessCodes,
    id: value.id,
    email: value.email,
    is_deleted: value.isDeleted,
    full_name: value.fullName,
    current_user_terms_id: value.currentUserTermsId,
    current_shop_terms_id: value.currentShopTermsId,
    current_provider_terms_id: value.currentProviderTermsId,
    managed_shops: (value.managedShops as Array<any>).map(ShopReadToJSON),
    managed_benefits: (value.managedBenefits as Array<any>).map(
      BenefitDefinitionSummaryReadToJSON,
    ),
  };
}
