import { Trans, useTranslation } from "react-i18next";

import { Currency } from "@vapaus/generated";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../packages/i18n/src/hooks";
import { BenefitCalculationWithExtra } from "../types";
import { isOomi } from "../utils";
import { InfoBox } from "./InfoBox";
import { LabeledValues } from "./LabeledValues";

interface IncomeTaxSavingProps {
  calculation?: BenefitCalculationWithExtra;
}

export function IncomeTaxSaving({ calculation }: IncomeTaxSavingProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(Currency.EUR);
  const formatPercent = usePercentFormat();

  return (
    <InfoBox
      background="primary2.lighter"
      title={t("calculator:incomeTaxSaving.title")}
      value={formatCurrency(calculation?.taxAmountReduction)}
      description={
        <Trans
          i18nKey="calculator:incomeTaxSaving.description"
          values={{
            totalValueAmount: calculation?.totalValueAmount,
            leasingPeriodMonths: calculation?.leasingPeriodMonths,
            taxAmountReduction: calculation?.taxAmountReduction,
            formatParams: {
              totalValueAmount: { currency: "EUR" },
              taxAmountReduction: { currency: "EUR" },
            },
          }}
          defaults="Since a total of {{ totalValueAmount, currency }} will be deducted from your salary over {{ leasingPeriodMonths }} months, you will pay {{ taxAmountReduction, currency }} less in income taxes thanks to a reduced tax rate."
        />
      }
      summaryTitle={t("calculator:incomeTaxSaving.total.title", {
        value: calculation?.leasingPeriodMonths,
      })}
      summaryValue={formatCurrency(calculation?.taxAmountReduction)}
    >
      <LabeledValues
        title={t("calculator:incomeTaxSaving.taxPercentage.title")}
        items={[
          {
            label: isOomi()
              ? t("oomiCalculator:incomeTaxSaving.tax.withVapaus")
              : t("calculator:incomeTaxSaving.tax.withVapaus"),
            value: formatPercent(calculation?.taxPercentageAfter),
          },
          {
            label: isOomi()
              ? t("oomiCalculator:incomeTaxSaving.tax.withoutVapaus")
              : t("calculator:incomeTaxSaving.tax.withoutVapaus"),
            value: formatPercent(calculation?.taxPercentageBefore),
          },
        ]}
      />
      <LabeledValues
        title={t("calculator:incomeTaxSaving.taxPaid.title", {
          value: calculation?.leasingPeriodMonths,
        })}
        items={[
          {
            label: isOomi()
              ? t("oomiCalculator:incomeTaxSaving.tax.withVapaus")
              : t("calculator:incomeTaxSaving.tax.withVapaus"),
            value: formatCurrency(calculation?.taxAmountAfter),
          },
          {
            label: isOomi()
              ? t("oomiCalculator:incomeTaxSaving.tax.withoutVapaus")
              : t("calculator:incomeTaxSaving.tax.withoutVapaus"),
            value: formatCurrency(calculation?.taxAmountBefore),
          },
        ]}
      />
    </InfoBox>
  );
}
