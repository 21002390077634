import { ReactNode } from "react";

import styled from "styled-components";

import { Flex } from "@vapaus/ui-v2";

interface SplitProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
}

export function Split({ leftContent, rightContent }: SplitProps) {
  return (
    <StyledRoot>
      <StyledLeft>
        <Flex direction="column" align="center" gap="xxs">
          {leftContent}
        </Flex>
      </StyledLeft>
      <StyledRight>
        <Flex direction="column" align="center" gap="xxs">
          {rightContent}
        </Flex>
      </StyledRight>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  border-top: ${({ theme }) => theme.border.primary.light};
`;

const StyledLeft = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing.sm};
  border-right: ${({ theme }) => theme.border.primary.light};
`;

const StyledRight = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing.sm};
`;
