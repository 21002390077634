import { useEffect, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { decodeJwt } from "jose";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { Token } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";

import { apiConfiguration, authApi } from "../../api";
import { AvailableScopes } from "../../constants";
import { useAuthContext } from "../../context";

export const useSignInPage = (scope?: AvailableScopes) => {
  const { t, i18n } = useTranslation();
  const { setToken } = useAuthContext();

  const signInSchema = useMemo(
    () =>
      zod.object({
        username: zod
          .string()
          .transform((str) => str.trim())
          .pipe(
            zod
              .string()
              .nonempty(t("authV2:signIn.enterEmail"))
              .email(t("authV2:signIn.enterValidEmail")),
          ),
        password: zod
          .string()
          .nonempty(t("authV2:signIn.enterPassword"))
          .min(10, t("authV2:signIn.passwordTooShort")),
      }),
    [t],
  );

  type SignInDataType = zod.infer<typeof signInSchema>;

  const [scopeError, setScopeError] = useState<string | undefined>();

  const form = useFormMutation<SignInDataType, Token>(
    {
      mutationFn: (data) => {
        apiConfiguration.setLanguageHeader(i18n.resolvedLanguage);
        return authApi.authSignIn({ ...data, scope });
      },
      onSuccess(data) {
        const claims = decodeJwt(data.accessToken);
        const availableScope = claims.available_scopes as Array<string>;
        if (scope && !availableScope?.includes(scope)) {
          setScopeError(t("authV2:signIn.forbidden"));
          throw t("authV2:signIn.forbidden");
        }
        setToken(data.accessToken);
      },
    },
    { resolver: zodResolver(signInSchema) },
  );

  useEffect(() => {
    if (scopeError) {
      form.formMethods.setError("root", { message: scopeError });
      setScopeError(undefined);
    }
  }, [form.formMethods, scopeError]);

  return form;
};
