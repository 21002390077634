import styled from "styled-components";

import { Flex, Spinner } from "@vapaus/ui-v2";

export function PageSpinner() {
  return (
    <StyledLoadingContainer justify="center">
      <Spinner />
    </StyledLoadingContainer>
  );
}

const StyledLoadingContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.palette.background.secondary};
`;
