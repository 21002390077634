/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
  BodyBenefitAcceptBikeBenefitOrder,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBikeBenefitContractOut,
  PaginatedBikeBenefitOrderOut,
} from "../models/index";
import {
  BikeBenefitContractReadFromJSON,
  BikeBenefitContractReadToJSON,
  BikeBenefitOrderReadFromJSON,
  BikeBenefitOrderReadToJSON,
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateToJSON,
  BodyBenefitAcceptBikeBenefitOrderFromJSON,
  BodyBenefitAcceptBikeBenefitOrderToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBikeBenefitContractOutFromJSON,
  PaginatedBikeBenefitContractOutToJSON,
  PaginatedBikeBenefitOrderOutFromJSON,
  PaginatedBikeBenefitOrderOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface BenefitAcceptBikeBenefitOrderRequest {
  vapausCode: string;
  bodyBenefitAcceptBikeBenefitOrder: BodyBenefitAcceptBikeBenefitOrder;
}

export interface BenefitGetBikeBenefitContractRequest {
  contractId: string;
}

export interface BenefitGetBikeBenefitOrderRequest {
  vapausCode: string;
}

export interface BenefitSearchBikeBenefitContractsRequest {
  benefitDefinitionId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface BenefitSearchBikeBenefitOrdersRequest {
  states?: Set<BikeBenefitOrderState>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class BenefitApi extends runtime.BaseAPI {
  /**
   * Accept Bike Benefit Order
   */
  async benefitAcceptBikeBenefitOrderRaw(
    requestParameters: BenefitAcceptBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (
      requestParameters.vapausCode === null ||
      requestParameters.vapausCode === undefined
    ) {
      throw new runtime.RequiredError(
        "vapausCode",
        "Required parameter requestParameters.vapausCode was null or undefined when calling benefitAcceptBikeBenefitOrder.",
      );
    }

    if (
      requestParameters.bodyBenefitAcceptBikeBenefitOrder === null ||
      requestParameters.bodyBenefitAcceptBikeBenefitOrder === undefined
    ) {
      throw new runtime.RequiredError(
        "bodyBenefitAcceptBikeBenefitOrder",
        "Required parameter requestParameters.bodyBenefitAcceptBikeBenefitOrder was null or undefined when calling benefitAcceptBikeBenefitOrder.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-benefit-orders/{vapaus_code}/accept`.replace(
          `{${"vapaus_code"}}`,
          encodeURIComponent(String(requestParameters.vapausCode)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyBenefitAcceptBikeBenefitOrderToJSON(
          requestParameters.bodyBenefitAcceptBikeBenefitOrder,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Accept Bike Benefit Order
   */
  async benefitAcceptBikeBenefitOrder(
    requestParameters: BenefitAcceptBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.benefitAcceptBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Benefit Contract
   */
  async benefitGetBikeBenefitContractRaw(
    requestParameters: BenefitGetBikeBenefitContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitContractRead>> {
    if (
      requestParameters.contractId === null ||
      requestParameters.contractId === undefined
    ) {
      throw new runtime.RequiredError(
        "contractId",
        "Required parameter requestParameters.contractId was null or undefined when calling benefitGetBikeBenefitContract.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-benefit-contracts/{contract_id}`.replace(
          `{${"contract_id"}}`,
          encodeURIComponent(String(requestParameters.contractId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitContractReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Benefit Contract
   */
  async benefitGetBikeBenefitContract(
    requestParameters: BenefitGetBikeBenefitContractRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitContractRead> {
    const response = await this.benefitGetBikeBenefitContractRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Bike Benefit Order
   */
  async benefitGetBikeBenefitOrderRaw(
    requestParameters: BenefitGetBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BikeBenefitOrderRead>> {
    if (
      requestParameters.vapausCode === null ||
      requestParameters.vapausCode === undefined
    ) {
      throw new runtime.RequiredError(
        "vapausCode",
        "Required parameter requestParameters.vapausCode was null or undefined when calling benefitGetBikeBenefitOrder.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-benefit-orders/{vapaus_code}`.replace(
          `{${"vapaus_code"}}`,
          encodeURIComponent(String(requestParameters.vapausCode)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BikeBenefitOrderReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Bike Benefit Order
   */
  async benefitGetBikeBenefitOrder(
    requestParameters: BenefitGetBikeBenefitOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BikeBenefitOrderRead> {
    const response = await this.benefitGetBikeBenefitOrderRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Bike Benefit Contracts
   */
  async benefitSearchBikeBenefitContractsRaw(
    requestParameters: BenefitSearchBikeBenefitContractsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBenefitContractOut>> {
    const queryParameters: any = {};

    if (requestParameters.benefitDefinitionId !== undefined) {
      queryParameters["benefit_definition_id"] =
        requestParameters.benefitDefinitionId;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-benefit-contracts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBenefitContractOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Benefit Contracts
   */
  async benefitSearchBikeBenefitContracts(
    requestParameters: BenefitSearchBikeBenefitContractsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBenefitContractOut> {
    const response = await this.benefitSearchBikeBenefitContractsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Bike Benefit Orders
   */
  async benefitSearchBikeBenefitOrdersRaw(
    requestParameters: BenefitSearchBikeBenefitOrdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBenefitOrderOut>> {
    const queryParameters: any = {};

    if (requestParameters.states) {
      queryParameters["states"] = requestParameters.states;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-benefit-orders`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBenefitOrderOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Benefit Orders
   */
  async benefitSearchBikeBenefitOrders(
    requestParameters: BenefitSearchBikeBenefitOrdersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBenefitOrderOut> {
    const response = await this.benefitSearchBikeBenefitOrdersRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
