/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
} from "./PaymentServiceProvider";
import type { PaymentStatus } from "./PaymentStatus";
import {
  PaymentStatusFromJSON,
  PaymentStatusFromJSONTyped,
  PaymentStatusToJSON,
} from "./PaymentStatus";

/**
 *
 * @export
 * @interface PaymentDetailsRead
 */
export interface PaymentDetailsRead {
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  saleInvoiceId: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  dateCreated: Date;
  /**
   *
   * @type {number}
   * @memberof PaymentDetailsRead
   */
  amount: number;
  /**
   *
   * @type {Currency}
   * @memberof PaymentDetailsRead
   */
  currency: Currency;
  /**
   *
   * @type {PaymentStatus}
   * @memberof PaymentDetailsRead
   */
  status: PaymentStatus;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  failureReason?: string;
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof PaymentDetailsRead
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  externalPaymentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceType?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceBrand?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceLabel?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  paymentConsentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  link?: string;
}

/**
 * Check if a given object implements the PaymentDetailsRead interface.
 */
export function instanceOfPaymentDetailsRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "saleInvoiceId" in value;
  isInstance = isInstance && "dateCreated" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "paymentServiceProvider" in value;

  return isInstance;
}

export function PaymentDetailsReadFromJSON(json: any): PaymentDetailsRead {
  return PaymentDetailsReadFromJSONTyped(json, false);
}

export function PaymentDetailsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentDetailsRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    saleInvoiceId: json["sale_invoice_id"],
    dateCreated: new Date(json["date_created"]),
    amount: json["amount"],
    currency: CurrencyFromJSON(json["currency"]),
    status: PaymentStatusFromJSON(json["status"]),
    failureReason: !exists(json, "failure_reason")
      ? undefined
      : json["failure_reason"],
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    externalPaymentId: !exists(json, "external_payment_id")
      ? undefined
      : json["external_payment_id"],
    sourceType: !exists(json, "source_type") ? undefined : json["source_type"],
    sourceBrand: !exists(json, "source_brand")
      ? undefined
      : json["source_brand"],
    sourceLabel: !exists(json, "source_label")
      ? undefined
      : json["source_label"],
    paymentConsentId: !exists(json, "payment_consent_id")
      ? undefined
      : json["payment_consent_id"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    link: !exists(json, "link") ? undefined : json["link"],
  };
}

export function PaymentDetailsReadToJSON(
  value?: PaymentDetailsRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sale_invoice_id: value.saleInvoiceId,
    date_created: value.dateCreated.toISOString().substring(0, 10),
    amount: value.amount,
    currency: CurrencyToJSON(value.currency),
    status: PaymentStatusToJSON(value.status),
    failure_reason: value.failureReason,
    payment_service_provider: PaymentServiceProviderToJSON(
      value.paymentServiceProvider,
    ),
    external_payment_id: value.externalPaymentId,
    source_type: value.sourceType,
    source_brand: value.sourceBrand,
    source_label: value.sourceLabel,
    payment_consent_id: value.paymentConsentId,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    link: value.link,
  };
}
