import { useState } from "react";

import { useTranslation } from "react-i18next";

import { UserShopRead } from "@vapaus/api-codegen";
import { Carousel, useBreakpoints } from "@vapaus/ui-v2";

import { useSearchShops } from "../../../../../../api/shop";
import { useGetCurrentUser } from "../../../../../../api/user";
import { ShopDetailModal } from "../ShopsTable/components/ShopDetailModal";
import { FeaturedShopLogo } from "./FeaturedShopLogo";

export function FeaturedShops() {
  const [openedShop, setOpenedShop] = useState<UserShopRead>();
  const { data: user } = useGetCurrentUser();
  const { data } = useSearchShops({
    operationalCountry: user?.country ?? "FI",
    isFeatured: true,
  });
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();

  const renderItem = ({
    item: shop,
    size,
  }: {
    item: UserShopRead;
    size: {
      width: number;
      height: number;
    };
  }) => (
    <FeaturedShopLogo
      name={shop.name}
      imageUrl={shop.logoUrl}
      onClick={() => setOpenedShop(shop)}
      size={size}
    />
  );

  if (!data?.items?.length) return null;

  return (
    <>
      <Carousel
        title={t("userV2:getABike.featuredShops.title")}
        data={data.items}
        renderItem={renderItem}
        itemsPerPage={isXs ? 3 : 5}
        endPadding="sm"
        gap="md"
      />
      <ShopDetailModal
        shop={openedShop}
        onClose={() => setOpenedShop(undefined)}
      />
    </>
  );
}
