import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  BunnyOnBike3DIcon,
  Button,
  Flex,
  TabRow,
  TabRowItemProps,
  Typography,
} from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function GetABikeHero() {
  const { t } = useTranslation();

  const tabs: TabRowItemProps[] = [
    {
      label: t("userV2:getABike.getABike"),
      to: ".",
    },
    {
      label: t("userV2:getABike.goodToKnow"),
      to: Path.goodToKnow,
    },
  ];

  return (
    <StyledRoot direction="column" justify="center" align="center">
      <StyledContainer direction="column" align="center" gap="lg">
        <BunnyOnBike3DIcon size="lg" />
        <Flex direction="column" gap="sm">
          <Typography variant="h1" align="center">
            {t("userV2:getABike.hero.title")}
          </Typography>
          <Typography variant="bodyLg" align="center">
            {t("userV2:getABike.hero.description")}
          </Typography>
        </Flex>
        <Flex
          align="center"
          justify="center"
          xsDirection="column"
          xsAlign="stretch"
          xsJustify="stretch"
          fullWidth
        >
          <Link to="#precycled">
            <Button fullWidth>
              {t("userV2:getABike.hero.buttons.precycled")}
            </Button>
          </Link>
          <Link to="#all-shops">
            <Button variant="outlined" fullWidth>
              {t("userV2:getABike.hero.buttons.viewAllShops")}
            </Button>
          </Link>
        </Flex>
      </StyledContainer>
      <TabRow items={tabs} />
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  width: 100%;
`;

const StyledContainer = styled(Flex)`
  width: 100%;
  max-width: 600px;
  margin: ${({ theme }) => `${theme.spacing.xxl} 0`};
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: 0 ${theme.spacing.md};
    `)}
`;
