import { forwardRef, useState } from "react";

import { CircularButton } from "../CircularButton";
import { LockIcon, VisibilityIcon, VisibilityOffIcon } from "../Icons";
import { Input, InputProps } from "../Input";

export type PasswordInputProps = Omit<
  InputProps,
  "type" | "endAdornment" | "startAdornment"
>;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props, ref) {
    const [show, setShow] = useState(false);
    return (
      <Input
        ref={ref}
        {...props}
        type={show ? "text" : "password"}
        startAdornment={<LockIcon />}
        endAdornment={
          <CircularButton
            icon={show ? <VisibilityOffIcon /> : <VisibilityIcon />}
            onClick={() => setShow((show) => !show)}
            size="md"
          />
        }
      />
    );
  },
);
