import { createSvgIcon } from "./SvgIcon";

export const InsightsIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M3 20C2.45 20 1.97917 19.8042 1.5875 19.4125C1.19583 19.0208 1 18.55 1 18C1 17.45 1.19583 16.9792 1.5875 16.5875C1.97917 16.1958 2.45 16 3 16H3.2625C3.3375 16 3.41667 16.0167 3.5 16.05L8.05 11.5C8.01667 11.4167 8 11.3375 8 11.2625V11C8 10.45 8.19583 9.97917 8.5875 9.5875C8.97917 9.19583 9.45 9 10 9C10.55 9 11.0208 9.19583 11.4125 9.5875C11.8042 9.97917 12 10.45 12 11C12 11.0333 11.9833 11.2 11.95 11.5L14.5 14.05C14.5833 14.0167 14.6625 14 14.7375 14H15.2625C15.3375 14 15.4167 14.0167 15.5 14.05L19.05 10.5C19.0167 10.4167 19 10.3375 19 10.2625V10C19 9.45 19.1958 8.97917 19.5875 8.5875C19.9792 8.19583 20.45 8 21 8C21.55 8 22.0208 8.19583 22.4125 8.5875C22.8042 8.97917 23 9.45 23 10C23 10.55 22.8042 11.0208 22.4125 11.4125C22.0208 11.8042 21.55 12 21 12H20.7375C20.6625 12 20.5833 11.9833 20.5 11.95L16.95 15.5C16.9833 15.5833 17 15.6625 17 15.7375V16C17 16.55 16.8042 17.0208 16.4125 17.4125C16.0208 17.8042 15.55 18 15 18C14.45 18 13.9792 17.8042 13.5875 17.4125C13.1958 17.0208 13 16.55 13 16V15.7375C13 15.6625 13.0167 15.5833 13.05 15.5L10.5 12.95C10.4167 12.9833 10.3375 13 10.2625 13H10C9.96667 13 9.8 12.9833 9.5 12.95L4.95 17.5C4.98333 17.5833 5 17.6625 5 17.7375V18C5 18.55 4.80417 19.0208 4.4125 19.4125C4.02083 19.8042 3.55 20 3 20ZM4 9.975L3.375 8.625L2.025 8L3.375 7.375L4 6.025L4.625 7.375L5.975 8L4.625 8.625L4 9.975ZM15 9L14.05 6.95L12 6L14.05 5.05L15 3L15.95 5.05L18 6L15.95 6.95L15 9Z" />
  </svg>,
  "Insights",
);
