import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useCurrentUser, useGetUserActiveOrCurrentEmail } from "../../api";
import { AppName } from "../../constants";
import { useApiTokenState } from "./useApiTokenState";

type CurrentTermsKeyType =
  | "currentUserTermsId"
  | "currentShopTermsId"
  | "currentProviderTermsId";

type AcceptedTermsKeyType =
  | "acceptedUserTermsId"
  | "acceptedShopTermsId"
  | "acceptedProviderTermsId";

const currentTermsKeys: Record<AppName, CurrentTermsKeyType> = {
  user: "currentUserTermsId",
  provider: "currentProviderTermsId",
  shop: "currentShopTermsId",
};

const acceptedTermsKeys: Record<AppName, AcceptedTermsKeyType> = {
  user: "acceptedUserTermsId",
  provider: "acceptedProviderTermsId",
  shop: "acceptedShopTermsId",
};

export const allowedAnonymousRoutes = [
  "/forgot-password",
  "/sign-up",
  "/sign-in",
  "/reset-password",
  "/set-password",
];

const specialRoutes = [...allowedAnonymousRoutes, "/terms"];

export function useAuth(app: AppName): {
  token: string | null;
  setToken: (token: string | null) => void;
  isLoggedIn: boolean;
  isLoading: boolean;
} {
  const [token, setToken] = useApiTokenState();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const {
    data: user,
    error,
    status,
    isLoading: isLoadingCurrentUser,
  } = useCurrentUser({
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  // if there is an error when we try to get the current user, se remove the token to log out.
  useEffect(() => {
    if (error) setToken(null);
  }, [error, setToken]);

  const { email: primaryEmail, isLoading: isLoadingActiveOrPrimaryEmail } =
    useGetUserActiveOrCurrentEmail({
      enabled: !!token && status === "success",
    });

  const currentTermsKey = currentTermsKeys[app];
  const acceptedTermsKey = acceptedTermsKeys[app];

  const currentTerms = user?.[currentTermsKey];
  const acceptedTerms = user?.[acceptedTermsKey];

  const isLoading =
    !!token && (isLoadingCurrentUser ?? isLoadingActiveOrPrimaryEmail);

  const shouldNavigateToSignIn =
    (!token || error) &&
    !allowedAnonymousRoutes.includes(location.pathname) &&
    location.pathname !== "/terms-of-service";

  const shouldNavigateToEmailNotVerified =
    token &&
    primaryEmail &&
    !primaryEmail.emailVerified &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/email-not-verified";

  const shouldNavigateToTerms =
    token &&
    currentTerms &&
    currentTerms !== acceptedTerms &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/email-not-verified";

  const shouldNavigateToRoot =
    token &&
    currentTerms &&
    currentTerms === acceptedTerms &&
    specialRoutes.includes(location.pathname);

  useEffect(() => {
    if (user?.language) i18n.changeLanguage(user.language.toLowerCase());
  }, [user?.language, i18n]);

  useEffect(() => {
    if (shouldNavigateToSignIn) {
      navigate("/sign-in");
    } else if (shouldNavigateToEmailNotVerified) {
      navigate("/email-not-verified");
    } else if (shouldNavigateToTerms) {
      navigate("/terms");
    } else if (shouldNavigateToRoot) {
      // TODO: handle the case where a user is redirected to the sign-in or the terms route, we should then redirect to their
      // previous route
      navigate("/");
    }
  }, [
    navigate,
    shouldNavigateToSignIn,
    shouldNavigateToEmailNotVerified,
    shouldNavigateToTerms,
    shouldNavigateToRoot,
  ]);

  return useMemo(
    () => ({ token, setToken, isLoggedIn: Boolean(user && token), isLoading }),
    [token, setToken, user, isLoading],
  );
}
