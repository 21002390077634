import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as zod from "zod";

import { Token } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { SupportedLanguagesEnum } from "@vapaus/generated";

import { apiConfiguration, authApi, useCurrentAppTerm } from "../../api";
import { AppName } from "../../constants";
import { useAuthContext } from "../../context";

export const useSignUpPage = (app: AppName) => {
  const { t, i18n } = useTranslation();
  const { setToken } = useAuthContext();
  const navigate = useNavigate();

  const signUpSchema = useMemo(
    () =>
      zod.object({
        email: zod
          .string()
          .transform((str) => str.trim())
          .pipe(
            zod
              .string()
              .nonempty(t("authV2:signUp.enterEmail"))
              .email(t("authV2:signUp.enterValidEmail")),
          ),
        password: zod
          .string()
          .nonempty(t("authV2:signUp.enterPassword"))
          .min(10, t("authV2:signUp.passwordTooShort")),
        acceptTerms: zod.literal(true),
      }),
    [t],
  );

  const { data: appUserTerms } = useCurrentAppTerm(app, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  type SignUpDataType = zod.infer<typeof signUpSchema>;

  const form = useFormMutation<SignUpDataType, Token>(
    {
      mutationFn: (data) => {
        apiConfiguration.setLanguageHeader(i18n.resolvedLanguage);
        return authApi.authSignUp({
          userCreate: {
            ...data,
            acceptedUserTermsId: appUserTerms?.id ?? "",
            language:
              i18n.resolvedLanguage?.toUpperCase() as SupportedLanguagesEnum,
          },
        });
      },
      onSuccess: (data) => {
        setToken(data.accessToken);
        navigate("/email-not-verified");
      },
    },
    { resolver: zodResolver(signUpSchema) },
  );

  return form;
};
