/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface ShopMaintenanceEventUpdate
 */
export interface ShopMaintenanceEventUpdate {
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventUpdate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof ShopMaintenanceEventUpdate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof ShopMaintenanceEventUpdate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof ShopMaintenanceEventUpdate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof ShopMaintenanceEventUpdate
   */
  workDate: Date;
}

/**
 * Check if a given object implements the ShopMaintenanceEventUpdate interface.
 */
export function instanceOfShopMaintenanceEventUpdate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "partsPrice" in value;
  isInstance = isInstance && "workPrice" in value;
  isInstance = isInstance && "workType" in value;
  isInstance = isInstance && "workDescription" in value;
  isInstance = isInstance && "workDate" in value;

  return isInstance;
}

export function ShopMaintenanceEventUpdateFromJSON(
  json: any,
): ShopMaintenanceEventUpdate {
  return ShopMaintenanceEventUpdateFromJSONTyped(json, false);
}

export function ShopMaintenanceEventUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopMaintenanceEventUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
  };
}

export function ShopMaintenanceEventUpdateToJSON(
  value?: ShopMaintenanceEventUpdate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parts_price: value.partsPrice,
    work_price: value.workPrice,
    work_type: WorkTypesToJSON(value.workType),
    work_description: value.workDescription,
    work_date: value.workDate.toISOString().substring(0, 10),
  };
}
