import { SelectProps } from "@mui/base/Select/Select.types";
import { useTranslation } from "react-i18next";

import { useEnumEntries } from "@vapaus/shared-api";
import { Select } from "@vapaus/ui-v2";

type ShopTypeSelectProps = Pick<
  SelectProps<string, false>,
  "value" | "onChange"
>;

export function ShopTypeSelect({ value, onChange }: ShopTypeSelectProps) {
  const { t } = useTranslation();

  const { entries } = useEnumEntries("ShopType");

  const shopTypeSelectOptions = [
    { value: "", label: t("userV2:getABike.partnerShops.allServices") },
    ...(entries || []),
  ];

  return (
    <Select<string>
      options={shopTypeSelectOptions}
      value={value}
      onChange={onChange}
      fullWidth
    />
  );
}
