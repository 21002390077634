import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button, Calculator3DIcon, Flex, Typography } from "@vapaus/ui-v2";

import { StyledContent } from "./components/StyledContent";
import { StyledRoot } from "./components/StyledRoot";

export function CalculatorCustomOrLegacyBenefitPage() {
  const { t } = useTranslation();

  return (
    <StyledRoot>
      <StyledContent>
        <Flex direction="column" gap="lg">
          <Flex direction="column" align="center" gap="lg">
            <Calculator3DIcon size="lg" />
            <Flex direction="column" align="center" gap="sm">
              <Typography variant="h1" align="center">
                {t("userV2:calculator.title")}
              </Typography>
              <StyledDescriptionWrapper>
                <Typography variant="bodyLg" align="center">
                  {t("userV2:calculator.customOrLegacyBenefit.description")}
                </Typography>
              </StyledDescriptionWrapper>
            </Flex>
            <Link to="https://calculator.vapaus.io" target="_blank">
              <Button>
                {t("userV2:calculator.customOrLegacyBenefit.button")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </StyledContent>
    </StyledRoot>
  );
}

const StyledDescriptionWrapper = styled.div`
  max-width: 600px;
`;
