import { ReactNode } from "react";

import styled from "styled-components";

import { Typography } from "@vapaus/ui-v2";

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

interface DescribedValueProps {
  title: string;
  value: string;
  description: ReactNode;
}

export function DescribedValue({
  title,
  value,
  description,
}: DescribedValueProps) {
  return (
    <div>
      <Row>
        <Typography weight="bold">{title}</Typography>
        <Typography weight="bold">{value}</Typography>
      </Row>
      <Typography marginBottom="md">{description}</Typography>
    </div>
  );
}
