import { ReactNode, useRef, useState } from "react";

import { animated, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  ExpandMoreIcon,
  Paper,
  PaperProps,
  Typography,
  isSafari,
} from "@vapaus/ui-v2";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ShowHide = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const Container = styled.div`
  overflow: hidden;
`;

const TotalRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.primary1.main};
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
`;

const Value = styled(Typography)`
  flex-grow: 1;
`;

type InfoBoxProps = {
  children: ReactNode;
  title: string;
  value: string;
  description: ReactNode;
  summaryTitle: string;
  summaryValue: string;
} & Pick<PaperProps, "background">;

export function InfoBox({
  background,
  title,
  value,
  description,
  children,
  summaryTitle,
  summaryValue,
}: InfoBoxProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const springProps = useSpring({
    height: isOpen ? `${contentRef.current?.scrollHeight ?? 0}px` : "0px",
    opacity: isOpen ? 1 : 0,
    immediate: isSafari(),
  });
  const iconSpringProps = useSpring({
    rotate: isOpen ? 180 : 0,
  });

  return (
    <Paper background={background} padding="lg">
      <Row>
        <Typography variant="h3">{title}</Typography>
        <Value variant="h3" align="right">
          {value}
        </Value>
      </Row>
      <Typography marginBottom="md">{description}</Typography>
      <Container>
        <animated.div style={springProps}>
          <div ref={contentRef} style={{ height: 0 }}>
            {children}

            <TotalRow>
              <Typography weight="bold">{summaryTitle}</Typography>
              <Typography weight="bold">{summaryValue}</Typography>
            </TotalRow>
          </div>
        </animated.div>
      </Container>
      <ShowHide
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Typography weight="bold">
          {isOpen ? t("calculator:hideDetails") : t("calculator:showDetails")}
        </Typography>
        <animated.div style={iconSpringProps}>
          <ExpandMoreIcon />
        </animated.div>
      </ShowHide>
    </Paper>
  );
}
