import styled, { css } from "styled-components";

type PageRootProps = {
  size?: "wide" | "narrow" | "narrower";
};

export const PageRoot = styled.div<PageRootProps>`
  max-width: ${({ size = "narrow" }) =>
    size === "wide" ? "1296px" : size === "narrow" ? "1056px" : "896px"};
  margin: ${({ theme }) => theme.spacing.xl} auto;
  padding: 0 ${({ theme }) => theme.spacing.xl};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: 0 ${theme.spacing.lg} ${theme.height.navBar};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: 0 ${theme.spacing.md} ${theme.spacing.lg};
      margin-top: ${theme.spacing.xl};
    `)}
`;
