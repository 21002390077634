import { animated } from "@react-spring/web";

import { usePageTransition } from "@vapaus/common-v2";

import {
  ActivationCreatedPage,
  AddNewEmployerPage,
  AddWorkEmailPage,
  AdditionalDataPage,
  BenefitPreviewPage,
  BusinessIdRequestAdditionalDataPage,
  BusinessIdRequestPage,
  BusinessIdRequestSentPage,
  IdentityVerifiedPage,
  RequestSentPage,
  SelectBikeBenefitPage,
  SelectCountryPage,
  SelectEmployerPage,
  UserProfilePage,
  VerifyYourEmailPage,
  VerifyYourIdentityPage,
  WelcomePage,
} from "../pages";
import { useOnboardingMachine } from "../stateMachine";

export function PagesContainer() {
  const { stateMatches, currentStep, requireAdditionalDetails } =
    useOnboardingMachine();
  const transitions = usePageTransition({ current: currentStep });

  if (stateMatches("welcome")) {
    return <WelcomePage />;
  }

  return transitions((style) => (
    <animated.div style={{ ...style, width: "100%" }}>
      {stateMatches("selectCountry") && <SelectCountryPage />}
      {stateMatches("updateProfile.profileForm") && <UserProfilePage />}
      {stateMatches("verifyIdentity.initiateVerification") && (
        <VerifyYourIdentityPage />
      )}
      {stateMatches("verifyIdentity.verificationSuccessful") && (
        <IdentityVerifiedPage />
      )}
      {stateMatches("addNewEmployer") && <AddNewEmployerPage />}

      {stateMatches("requestByBusinessId.enterBusinessId") && (
        <BusinessIdRequestPage />
      )}
      {(stateMatches("requestByBusinessId.enterAdditionalDetails") ||
        stateMatches("requestByBusinessId.updatingAdditionalData") ||
        stateMatches("requestByBusinessId.sendingRequest")) && (
        <BusinessIdRequestAdditionalDataPage />
      )}
      {stateMatches("requestByBusinessId.requestSent") && (
        <BusinessIdRequestSentPage />
      )}

      {stateMatches("addEmailAddress") &&
        !(
          stateMatches("addEmailAddress.confirm") ||
          stateMatches("addEmailAddress.resendingVerification")
        ) && <AddWorkEmailPage />}
      {(stateMatches("addEmailAddress.confirm") ||
        stateMatches("addEmailAddress.resendingVerification")) && (
        <VerifyYourEmailPage />
      )}

      {stateMatches("selectEmployer") && <SelectEmployerPage />}
      {stateMatches("selectBenefit") && <SelectBikeBenefitPage />}

      {(stateMatches("selectionPreview") ||
        ((stateMatches("submitting.creatingActivation") ||
          stateMatches("submitting.sendingRequest")) &&
          !requireAdditionalDetails)) && <BenefitPreviewPage />}
      {(stateMatches("additionalDetails") ||
        ((stateMatches("submitting.creatingActivation") ||
          stateMatches("submitting.sendingRequest")) &&
          requireAdditionalDetails)) && <AdditionalDataPage />}

      {stateMatches("doneRequestSent") && <RequestSentPage />}
      {stateMatches("doneActivationCreated") && <ActivationCreatedPage />}
    </animated.div>
  ));
}
