import { ReactNode } from "react";

import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  BunnyStandingFront3DIcon,
  Button,
  Flex,
  Notification,
  Typography,
} from "@vapaus/ui-v2";

import { useApiError } from "./useApiError";

type OnboardingFinalStepPageLayoutProps = {
  title: string;
  subtitle: ReactNode;
  nextButtonText: string;
  nextButtonPath: string;
};

export function OnboardingFinalStepPageLayout({
  title,
  subtitle,
  nextButtonText,
  nextButtonPath,
}: OnboardingFinalStepPageLayoutProps) {
  const navigate = useNavigate();
  const { apiError } = useApiError();

  return (
    <StyledRoot direction="column" gap="md" align="center" justify="center">
      <BunnyStandingFront3DIcon size="lg" />
      <Flex direction="column" gap="sm" align="center">
        <StyledTitleWrapper>
          <Typography variant="h1" align="center">
            {title}
          </Typography>
        </StyledTitleWrapper>
        <StyledSubtitleWrapper>
          <Typography align="center">{subtitle}</Typography>
        </StyledSubtitleWrapper>
        {apiError ? (
          <StyledSubtitleWrapper>
            <Notification type="error" message={apiError} />
          </StyledSubtitleWrapper>
        ) : null}
      </Flex>
      <Button onClick={() => navigate(nextButtonPath)}>{nextButtonText}</Button>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.secondary1.light};
  min-height: 100vh;
  width: 100%;
`;

const StyledTitleWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;

const StyledSubtitleWrapper = styled.div`
  width: 35rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
