/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ReturnReason = {
  EmploymentTerminatedByEmployer: "employment_terminated_by_employer",
  EmploymentTerminatedByEmployee: "employment_terminated_by_employee",
  WantToSpendMoneyDifferently: "want_to_spend_money_differently",
  WantDifferentBike: "want_different_bike",
  NotSatisfiedWithBike: "not_satisfied_with_bike",
  NotUsingBenefitEnough: "not_using_benefit_enough",
  Other: "other",
  EndOfLease: "end_of_lease",
} as const;
export type ReturnReason = (typeof ReturnReason)[keyof typeof ReturnReason];

export function ReturnReasonFromJSON(json: any): ReturnReason {
  return ReturnReasonFromJSONTyped(json, false);
}

export function ReturnReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnReason {
  return json as ReturnReason;
}

export function ReturnReasonToJSON(value?: ReturnReason | null): any {
  return value as any;
}
