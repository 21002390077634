import { ReactNode, useState } from "react";

import { Popper } from "@mui/base/Popper";
import styled from "styled-components";

interface TooltipProps {
  className?: string;
  trigger?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

export function Tooltip({
  children,
  trigger,
  disabled,
  ...props
}: TooltipProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (disabled) return trigger;

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl === event.currentTarget) return;
    setAnchorEl(event.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PopperRoot
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {trigger ?? <PopperIcon>?</PopperIcon>}
      <StyledPopper open={open} anchorEl={anchorEl} role="tooltip">
        <PopperContent>{children}</PopperContent>
      </StyledPopper>
    </PopperRoot>
  );
}

const PopperIcon = styled.i`
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1rem;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  background-color: ${({ theme }) => theme.palette.primary1.transparent};
  color: ${({ theme }) => theme.palette.primary1.main};
  border: ${({ theme }) => theme.border.primary.main};
  border-radius: 100px;
  cursor: default;
`;

const PopperContent = styled.div`
  background-color: ${({ theme }) => theme.palette.primary1.main};
  color: ${({ theme }) => theme.palette.primary2.main};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.radius.tooltip};
  max-width: 360px;
`;

const PopperRoot = styled.div`
  display: flex;
`;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.tooltip};
`;
