import { marked } from "marked";
import styled from "styled-components";

import { makeTransition } from "../styles";

export type MarkdownProps = {
  content: string;
  className?: string;
};

export function Markdown({ content, className }: MarkdownProps) {
  return (
    <StyledRoot className={className}>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(content, { headerIds: false }),
        }}
      />
    </StyledRoot>
  );
}

export const StyledRoot = styled.div`
  width: 100%;
  word-wrap: break-word;
  font-size: ${({ theme }) => theme.typography.htmlFontSize};
  color: ${({ theme }) => theme.typography.defaultColor};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  h1 {
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
    line-height: ${({ theme }) => theme.typography.h1.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h1.textTransform};
  }
  h2 {
    font-family: ${({ theme }) => theme.typography.h2.fontFamily};
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
    line-height: ${({ theme }) => theme.typography.h2.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h2.textTransform};
  }
  h3 {
    font-family: ${({ theme }) => theme.typography.h3.fontFamily};
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
    line-height: ${({ theme }) => theme.typography.h3.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h3.textTransform};
  }
  h4 {
    font-family: ${({ theme }) => theme.typography.h4.fontFamily};
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
    line-height: ${({ theme }) => theme.typography.h4.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h4.textTransform};
  }
  h5 {
    font-family: ${({ theme }) => theme.typography.h5.fontFamily};
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
    line-height: ${({ theme }) => theme.typography.h5.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h5.textTransform};
  }
  h6 {
    font-family: ${({ theme }) => theme.typography.h6.fontFamily};
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
    line-height: ${({ theme }) => theme.typography.h6.lineHeight};
    text-transform: ${({ theme }) => theme.typography.h6.textTransform};
  }
  p {
    font-family: ${({ theme }) => theme.typography.bodyLg.fontFamily};
    font-size: ${({ theme }) => theme.typography.bodyLg.fontSize};
    font-weight: ${({ theme }) => theme.typography.bodyLg.fontWeight};
    line-height: ${({ theme }) => theme.typography.bodyLg.lineHeight};
  }
  a {
    font-size: ${({ theme }) => theme.typography.bodyLg.fontSize};
    text-decoration: none;
    color: ${({ theme }) => theme.typography.defaultColor};
    font-weight: ${({ theme }) => theme.typography.link.fontWeight};
    font-family: ${({ theme }) => theme.typography.link.fontFamily};
    cursor: pointer;
    &:hover {
      text-decoration: ${({ theme }) => theme.typography.link.textDecoration};
    }
    &:active {
      color: ${({ theme }) => theme.palette.primary1.light};
    }
    &.disabled {
      pointer-events: none;
      opacity: ${({ theme }) => theme.opacity.disabled};
    }
    ${makeTransition("all", "standard", "easeOut")}
  }
`;
