import { ReactNode } from "react";

import {
  ColumnDef,
  OnChangeFn,
  RowData,
  SortingState,
  defaultColumnSizing,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styled, { css } from "styled-components";

import { ArrowUpDownIcon } from "../Icons";
import { ArrowDropDownIcon } from "../Icons/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "../Icons/ArrowDropUpIcon";
import { Pagination, PaginationProps } from "./Pagination/Pagination";

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    disabled: boolean;
  }
}

export type SortingProps = {
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

interface TableProps<TData extends RowData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  sortingOptions?: SortingProps;
  paginationOptions?: PaginationProps;
  emptyStateComponent?: ReactNode;
  onRowClick?: (row: TData) => void;
}

export function Table<TData extends RowData>({
  data,
  columns,
  sortingOptions,
  paginationOptions,
  emptyStateComponent,
  onRowClick,
}: TableProps<TData>) {
  const { sorting, setSorting } = sortingOptions || {};

  const pageCount = paginationOptions
    ? Math.ceil(
        paginationOptions.totalItems / paginationOptions.pagination.pageSize,
      )
    : 0;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      pagination: paginationOptions?.pagination,
    },
    pageCount: pageCount,
    manualSorting: true,
    sortDescFirst: false,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange: paginationOptions?.setPagination,
    defaultColumn: {
      ...defaultColumnSizing,
      size: 0,
    },
  });

  return (
    <div>
      <StyledTableWrapper>
        <StyledTable>
          <thead>
            <tr>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <StyledTableHeader
                  key={header.id}
                  onClick={
                    header.column.columnDef.enableSorting
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  disabled={header.column.columnDef.meta?.disabled}
                  size={header.column.columnDef.size}
                >
                  <StyledHeaderWrapper
                    sortingEnabled={
                      table.getState().sorting !== undefined &&
                      header.column.columnDef.enableSorting
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.columnDef.enableSorting
                      ? {
                          asc: <ArrowDropUpIcon />,
                          desc: <ArrowDropDownIcon />,
                        }[header.column.getIsSorted() as string] ?? (
                          <StyledChevronIconWrapper>
                            {table.getState().sorting !== undefined ? (
                              <ArrowUpDownIcon />
                            ) : null}
                          </StyledChevronIconWrapper>
                        )
                      : null}
                  </StyledHeaderWrapper>
                </StyledTableHeader>
              ))}
            </tr>
          </thead>
          {table.getRowModel().rows.length === 0 && emptyStateComponent ? (
            <tbody>
              <tr>
                <StyledEmptyStateContainer
                  colSpan={table.getHeaderGroups()[0].headers.length}
                >
                  {emptyStateComponent}
                </StyledEmptyStateContainer>
              </tr>
            </tbody>
          ) : (
            <StyledTableBody>
              {table.getRowModel().rows.map((row) => (
                <StyledTableRow
                  key={row.id}
                  clickable={!!onRowClick}
                  onClick={() => onRowClick?.(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <StyledTableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </StyledTableBody>
          )}
        </StyledTable>
      </StyledTableWrapper>
      {paginationOptions ? (
        <Pagination
          pageSize={table.getState().pagination.pageSize}
          setPageSize={table.setPageSize}
          paginationOptions={paginationOptions}
          canGetPreviousPage={table.getCanPreviousPage()}
          getPreviousPage={table.previousPage}
          canGetNextPage={table.getCanNextPage()}
          getNextPage={table.nextPage}
        />
      ) : null}
    </div>
  );
}

const StyledTableWrapper = styled.div`
  overflow: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: right;
  background-color: ${({ theme }) => theme.palette.background.white};
  border-collapse: collapse;
  table-layout: fixed;

  td:first-child {
    text-align: left;
  }

  th:first-child div:first-child {
    justify-content: start;
  }
`;

const StyledEmptyStateContainer = styled.td`
  padding: ${({ theme }) => theme.spacing.sm};
`;

const StyledTableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.neutral.lighter};

    &:hover {
      background: ${({ theme }) => theme.palette.neutral.light};
    }
  }
`;

const StyledTableHeader = styled.th<{
  disabled?: boolean;
  size?: number;
}>`
  border-bottom: ${({ theme }) => theme.border.primary.light};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: ${({ theme }) => theme.opacity.disabled};
      pointer-events: none;
    `};

  ${({ size }) =>
    size &&
    css`
      width: ${size}%;
    `}
`;

const StyledHeaderWrapper = styled.div<{
  sortingEnabled: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing.xxs};

  ${({ sortingEnabled }) =>
    sortingEnabled &&
    css`
      cursor: pointer;

      &:hover {
        span {
          opacity: 1;
        }
      }
    `};
`;

const StyledChevronIconWrapper = styled.span`
  opacity: ${({ theme }) => theme.opacity.disabled};
`;

interface StyledTableRowProps {
  clickable: boolean;
}

const StyledTableRow = styled.tr<StyledTableRowProps>`
  &:hover {
    background: ${({ theme }) => theme.palette.neutral.light};
    ${({ clickable }) =>
      clickable &&
      css`
        cursor: pointer;
      `}
  }
`;

const StyledTableCell = styled.td`
  padding: ${({ theme }) => theme.spacing.md};
  max-width: 20rem;
`;
