import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { ProfileDropdown } from "@vapaus/common-v2";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  BikeScooterIcon,
  BunnyOnBike3DIcon,
  Button,
  Flex,
  Logo,
  LogoutIcon,
  PersonIcon,
  Typography,
} from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";
import { useOnboardingMachine } from "../stateMachine";

export function WelcomePage() {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const { goToNext } = useOnboardingMachine();

  const profileMenuProps = useMemo(
    () => ({
      header: {
        icon: PersonIcon,
        title: user?.fullName ?? "",
        description: user?.email ?? "",
      },
      items: [
        {
          label: t("userV2:navigation.buttons.sharedService"),
          value: "sharedService",
          icon: BikeScooterIcon,
          onItemClick: () => {
            window.open(import.meta.env.VITE_FLEET_APP_URL, "_blank");
          },
        },
        {
          label: t("commonV2:logout"),
          value: Path.logout,
          icon: LogoutIcon,
        },
      ],
    }),
    [t, user?.email, user?.fullName],
  );

  return (
    <StyledRoot direction="column" gap="none">
      <StyledTopBar justify="space-between">
        <Logo size="lg" />
        <ProfileDropdown {...profileMenuProps} />
      </StyledTopBar>
      <StyledMainContent
        align="center"
        justify="center"
        direction="column"
        gap="md"
      >
        <BunnyOnBike3DIcon size="lg" />
        <Flex direction="column" gap="sm" align="center">
          <StyledTitleWrapper>
            <Typography variant="h1" align="center">
              {t("userV2:onboarding.welcome.title")}
            </Typography>
          </StyledTitleWrapper>
          <StyledSubtitleWrapper>
            <Typography align="center">
              {t("userV2:onboarding.welcome.subtitle")}
            </Typography>
          </StyledSubtitleWrapper>
        </Flex>
        <Button onClick={goToNext}>
          {t("userV2:onboarding.welcome.getStarted")}
        </Button>
      </StyledMainContent>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.primary2.main};
  height: 100vh;
  width: 100%;
`;

const StyledTopBar = styled(Flex)`
  height: ${({ theme }) => theme.height.navBar};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};

  & button {
    background-color: ${({ theme }) => theme.palette.primary2.main};
  }
`;

const StyledMainContent = styled(Flex)`
  flex: 1;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.height.navBar};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding-left: ${theme.spacing.lg};
      padding-right: ${theme.spacing.lg};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding-left: ${theme.spacing.md};
      padding-right: ${theme.spacing.md};
    `)}
`;

const StyledTitleWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;

const StyledSubtitleWrapper = styled.div`
  width: 30rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
