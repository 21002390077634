import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Logo, Markdown, Typography } from "@vapaus/ui-v2";

interface TermsOfServiceContentProps {
  children: ReactNode;
  content?: string;
}

export function TermsOfServiceContent({
  children,
  content,
}: TermsOfServiceContentProps) {
  const { t } = useTranslation();
  return (
    <StyledRoot>
      <StyledContentTop>
        <Logo size="sm" />
        <Typography variant="h1" align="center">
          {t("authV2:termsOfService.termsOfService")}
        </Typography>
      </StyledContentTop>
      {content && <Markdown content={content} />}
      {children}
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  max-width: 960px;
  margin: auto;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.lg}`};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.sm}`};
    `)}
`;

const StyledContentTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: center;
`;
