import { useTranslation } from "react-i18next";

import { Flex } from "@vapaus/ui-v2";

import { BillingTable } from "../../components/BillingTable";
import { PaymentCards } from "./UserBillingSection/PaymentCards";

export function UserBillingSection() {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="lg">
      <PaymentCards />
      <BillingTable title={t("userV2:settings.billing.title")} />
    </Flex>
  );
}
