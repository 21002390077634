/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { MasterPermissions } from "./MasterPermissions";
import {
  MasterPermissionsFromJSON,
  MasterPermissionsFromJSONTyped,
  MasterPermissionsToJSON,
} from "./MasterPermissions";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";
import type { SupportedLanguagesEnum } from "./SupportedLanguagesEnum";
import {
  SupportedLanguagesEnumFromJSON,
  SupportedLanguagesEnumFromJSONTyped,
  SupportedLanguagesEnumToJSON,
} from "./SupportedLanguagesEnum";

/**
 *
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  costCenter?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  employeeNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  city?: string;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof UserUpdate
   */
  country?: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof UserUpdate
   */
  phoneNumber?: string;
  /**
   *
   * @type {Array<MasterPermissions>}
   * @memberof UserUpdate
   */
  masterPermissions?: Array<MasterPermissions>;
  /**
   *
   * @type {SupportedLanguagesEnum}
   * @memberof UserUpdate
   */
  language?: SupportedLanguagesEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof UserUpdate
   */
  fleetAccessCodes?: Array<string>;
}

/**
 * Check if a given object implements the UserUpdate interface.
 */
export function instanceOfUserUpdate(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
  return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: !exists(json, "company") ? undefined : json["company"],
    costCenter: !exists(json, "cost_center") ? undefined : json["cost_center"],
    employeeNumber: !exists(json, "employee_number")
      ? undefined
      : json["employee_number"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    password: !exists(json, "password") ? undefined : json["password"],
    address: !exists(json, "address") ? undefined : json["address"],
    postCode: !exists(json, "post_code") ? undefined : json["post_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    country: !exists(json, "country")
      ? undefined
      : SupportedCountriesEnumFromJSON(json["country"]),
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    masterPermissions: !exists(json, "master_permissions")
      ? undefined
      : (json["master_permissions"] as Array<any>).map(
          MasterPermissionsFromJSON,
        ),
    language: !exists(json, "language")
      ? undefined
      : SupportedLanguagesEnumFromJSON(json["language"]),
    fleetAccessCodes: !exists(json, "fleet_access_codes")
      ? undefined
      : json["fleet_access_codes"],
  };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: value.company,
    cost_center: value.costCenter,
    employee_number: value.employeeNumber,
    first_name: value.firstName,
    last_name: value.lastName,
    password: value.password,
    address: value.address,
    post_code: value.postCode,
    city: value.city,
    country: SupportedCountriesEnumToJSON(value.country),
    phone_number: value.phoneNumber,
    master_permissions:
      value.masterPermissions === undefined
        ? undefined
        : (value.masterPermissions as Array<any>).map(MasterPermissionsToJSON),
    language: SupportedLanguagesEnumToJSON(value.language),
    fleet_access_codes: value.fleetAccessCodes,
  };
}
