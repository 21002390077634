import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ApiError } from "@vapaus/generated";
import { NotificationType } from "@vapaus/ui-v2";
import { parseApiError } from "@vapaus/utils";

import {
  apiConfiguration,
  useGetUserActiveOrCurrentEmail,
  useSendEmailVerificationToken,
} from "../../api";
import { useAuthContext } from "../../context";

export function useVerifyEmailPage() {
  const { t, i18n } = useTranslation();
  const { token, setToken } = useAuthContext();
  const navigate = useNavigate();

  const { email: activeOrPrimaryEmail, isLoading } =
    useGetUserActiveOrCurrentEmail({
      enabled: !!token,
    });

  useEffect(() => {
    if (!isLoading && activeOrPrimaryEmail?.emailVerified) {
      navigate("/");
    }
  }, [navigate, activeOrPrimaryEmail, isLoading]);

  const sendEmailVerificationToken = useSendEmailVerificationToken();

  const handleSendEmailVerificationToken = () => {
    apiConfiguration.setLanguageHeader(i18n.resolvedLanguage);
    sendEmailVerificationToken.mutate({
      userEmailId: activeOrPrimaryEmail?.id ?? "",
    });
  };

  const status: NotificationType =
    sendEmailVerificationToken.status === "success" ? "success" : "error";
  const statusMessage =
    sendEmailVerificationToken.status === "success"
      ? t("authV2:verifyEmail.emailSent")
      : sendEmailVerificationToken.status === "error"
      ? parseApiError(
          sendEmailVerificationToken.error as unknown as ApiError,
          t,
        )
      : undefined;

  const handleBackToSignUp = () => {
    setToken("");
    navigate("/sign-up");
  };

  return {
    activeOrPrimaryEmail,
    handleSendEmailVerificationToken,
    isLoading: sendEmailVerificationToken.isLoading,
    status,
    statusMessage,
    handleBackToSignUp,
  };
}
