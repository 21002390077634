/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
 */
export interface BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation {
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  monthlyPayment: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  fixedDownPaymentAmount?: number;
  /**
   * Total package price (excluding maintenance budget)
   * @type {number}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  totalPackagePrice: number;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  country: SupportedCountriesEnum;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation
   */
  monthlySalary: number;
}

/**
 * Check if a given object implements the BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation interface.
 */
export function instanceOfBodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "monthlyPayment" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "totalPackagePrice" in value;
  isInstance = isInstance && "country" in value;
  isInstance = isInstance && "monthlySalary" in value;

  return isInstance;
}

export function BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationFromJSON(
  json: any,
): BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation {
  return BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationFromJSONTyped(
    json,
    false,
  );
}

export function BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    monthlyPayment: json["monthly_payment"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    fixedDownPaymentAmount: !exists(json, "fixed_down_payment_amount")
      ? undefined
      : json["fixed_down_payment_amount"],
    totalPackagePrice: json["total_package_price"],
    country: SupportedCountriesEnumFromJSON(json["country"]),
    monthlySalary: json["monthly_salary"],
  };
}

export function BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculationToJSON(
  value?: BodyCalculationsGetPublicFixedMonthlyPaymentWithTaxCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    monthly_payment: value.monthlyPayment,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    fixed_down_payment_amount: value.fixedDownPaymentAmount,
    total_package_price: value.totalPackagePrice,
    country: SupportedCountriesEnumToJSON(value.country),
    monthly_salary: value.monthlySalary,
  };
}
