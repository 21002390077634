import { RouteObject } from "react-router-dom";

import { MyBikePage } from "./MyBikePage";
import { RedemptionProcessWizard } from "./components/RedemptionProcessWizard";
import { ReturnProcessWizard } from "./components/ReturnProcessWizard/ReturnProcessWizard";
import { TabPanelBilling } from "./components/TabPanelBilling";
import { TabPanelMaintenance } from "./components/TabPanelMaintenance";
import { TabPanelOrderDetails } from "./components/TabPanelOrderDetails";
import { TabPanelOrderOrContract } from "./components/TabPanelOrderOrContract";

export const myBikeRoutes: RouteObject = {
  path: ":orderId",
  element: <MyBikePage />,
  children: [
    {
      index: true,
      element: <TabPanelOrderOrContract />,
    },
    {
      path: "billing",
      element: <TabPanelBilling />,
    },
    {
      path: "bike-package",
      element: <TabPanelOrderDetails />,
    },
    {
      path: "maintenance",
      element: <TabPanelMaintenance />,
    },
    {
      path: "redeem-bike",
      element: <RedemptionProcessWizard />,
    },
    {
      path: "return-bike",
      element: <ReturnProcessWizard />,
    },
  ],
};
