import { createSvgIcon } from "./SvgIcon";

export const EmailReadIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4 6L12 11L20 6H4ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V10.35L21 11.35L20 12.35V8L12.525 12.675C12.475 12.7083 12.3 12.7583 12 12.825C11.9167 12.825 11.8292 12.8125 11.7375 12.7875C11.6458 12.7625 11.5583 12.725 11.475 12.675L4 8V18H9.15L11.15 20H4ZM15.95 19.2L20.9 14.25C21.0833 14.0667 21.3167 13.975 21.6 13.975C21.8833 13.975 22.1167 14.0667 22.3 14.25C22.4833 14.4333 22.575 14.6667 22.575 14.95C22.575 15.2333 22.4833 15.4667 22.3 15.65L16.65 21.3C16.45 21.5 16.2167 21.6 15.95 21.6C15.6833 21.6 15.45 21.5 15.25 21.3L12.4 18.45C12.2167 18.2667 12.125 18.0333 12.125 17.75C12.125 17.4667 12.2167 17.2333 12.4 17.05C12.5833 16.8667 12.8167 16.775 13.1 16.775C13.3833 16.775 13.6167 16.8667 13.8 17.05L15.95 19.2Z" />
  </svg>,
  "EmailRead",
);
