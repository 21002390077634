import { TFunction } from "i18next";
import * as z from "zod";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";

import { BusinessIdFormat } from "./constants";

export const businessIdSchema = (t: TFunction<"translation">) =>
  z.discriminatedUnion("country", [
    z.object({
      country: z.literal(SupportedCountriesEnum.Fi),
      businessId: z.string().regex(
        new RegExp(/^\d{7}-(\d{1})$/),
        t("userV2:onboarding.businessIdRequest.invalidBusinessId", {
          format: BusinessIdFormat[SupportedCountriesEnum.Fi],
        }),
      ),
    }),
    z.object({
      country: z.literal(SupportedCountriesEnum.Se),
      businessId: z.string().regex(
        new RegExp(/^\d{6}-\d{4}$/),
        t("userV2:onboarding.businessIdRequest.invalidBusinessId", {
          format: BusinessIdFormat[SupportedCountriesEnum.Se],
        }),
      ),
    }),
  ]);

export type BusinessIDType = z.infer<ReturnType<typeof businessIdSchema>>;
