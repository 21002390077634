import { useTranslation } from "react-i18next";

import { WizardPageViewLayout } from "../../../../WizardPageViewLayout";
import { ImageAddressCard } from "./ImageAddressCard";
import bikeCenterFI from "./images/bikeCenterFI.jpg";

export function BikeCenterInfoDefaultView() {
  const { t } = useTranslation();

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.bikeCenterInfo.title")}
      description={t("userV2:returnProcess.bikeCenterInfo.description")}
    >
      <ImageAddressCard
        image={bikeCenterFI}
        url="https://maps.app.goo.gl/i4Z2Yt9i75DYWF9Z6"
      />
    </WizardPageViewLayout>
  );
}
