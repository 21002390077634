import { Flex, TextButton, Typography } from "@vapaus/ui-v2";

export type Target = "_self" | "_blank" | "_parent" | "_top" | undefined;

export type LinksGroupProps = {
  title: string;
  links: Array<{
    label: string;
    link: string;
    target?: Target;
  }>;
};

export function LinksGroup({ title, links }: LinksGroupProps) {
  return (
    <Flex direction="column">
      <Typography variant="h4" color="secondary1.main">
        {title}
      </Typography>
      <Flex direction="column" gap="xs">
        {links.map((link) => (
          <TextButton
            key={link.label}
            to={link.link}
            target={link.target || "_blank"}
          >
            <Typography color="common.white" weight="bold">
              {link.label}
            </Typography>
          </TextButton>
        ))}
      </Flex>
    </Flex>
  );
}
