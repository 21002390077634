import React from "react";

import { TFunction } from "i18next";

import { BenefitDefinitionAccessRequestStatus } from "@vapaus/api-codegen";
import { Tag, TagSize } from "@vapaus/ui-v2";

type BaseProps = {
  t: TFunction;
  size?: TagSize;
  isBenefitDefinitionActive: boolean;
};

type GetAccessRequestStatusTagProps = BaseProps & {
  status: BenefitDefinitionAccessRequestStatus;
  isActivationActive?: never;
};

type GetActivationStatusTagProps = BaseProps & {
  status?: never;
  isActivationActive: boolean;
};

type GetStatusTagProps =
  | GetAccessRequestStatusTagProps
  | GetActivationStatusTagProps;

export function getBenefitStatusTag({
  t,
  size = "default",
  isBenefitDefinitionActive,
  status,
  isActivationActive,
}: GetStatusTagProps) {
  if (!isBenefitDefinitionActive) {
    return (
      <Tag
        variant="neutral"
        text={t("userV2:benefits.benefitStatus.deactivated")}
        size={size}
      />
    );
  }

  if (status) {
    return mapAccessRequestStatusToTag(t, size, status);
  }

  if (isActivationActive !== undefined) {
    return getActivationStatusTag(t, size, isActivationActive);
  }
}

function mapAccessRequestStatusToTag(
  t: TFunction,
  size: TagSize,
  status?: BenefitDefinitionAccessRequestStatus,
) {
  switch (status) {
    case "pending":
      return (
        <Tag
          variant="pending"
          text={t("userV2:benefits.accessRequestStatus.pending")}
          size={size}
        />
      );
    case "denied":
      return (
        <Tag
          variant="error"
          text={t("userV2:benefits.accessRequestStatus.denied")}
          size={size}
        />
      );
    case "expired":
      return (
        <Tag
          variant="neutral"
          text={t("userV2:benefits.accessRequestStatus.expired")}
          size={size}
        />
      );
    default:
      return null;
  }
}

function getActivationStatusTag(
  t: TFunction,
  size: TagSize,
  isActive: boolean,
) {
  return isActive ? (
    <Tag
      variant="accepted"
      text={t("userV2:benefits.activationStatus.approved")}
      size={size}
    />
  ) : (
    <Tag
      variant="cancelled"
      text={t("userV2:benefits.activationStatus.revoked")}
      size={size}
    />
  );
}
