import { createSvgIcon } from "./SvgIcon";

export const ChevronLeftIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.2998 17.3L8.6998 12.7C8.5998 12.6 8.52897 12.4917 8.4873 12.375C8.44564 12.2584 8.4248 12.1334 8.4248 12C8.4248 11.8667 8.44564 11.7417 8.4873 11.625C8.52897 11.5084 8.5998 11.4 8.6998 11.3L13.2998 6.70005C13.4831 6.51672 13.7165 6.42505 13.9998 6.42505C14.2831 6.42505 14.5165 6.51672 14.6998 6.70005C14.8831 6.88338 14.9748 7.11672 14.9748 7.40005C14.9748 7.68338 14.8831 7.91672 14.6998 8.10005L10.7998 12L14.6998 15.9C14.8831 16.0834 14.9748 16.3167 14.9748 16.6C14.9748 16.8834 14.8831 17.1167 14.6998 17.3C14.5165 17.4834 14.2831 17.575 13.9998 17.575C13.7165 17.575 13.4831 17.4834 13.2998 17.3Z" />
  </svg>,
  "ChevronLeft",
);
