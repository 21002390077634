import { useCallback, useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  BikeBenefitContractRead,
  BikeBenefitContractState,
} from "@vapaus/api-codegen";

import { CONTRACT_CANCELLATION_FROM_STATE } from "../../../../constants/contractNavigation";
import { EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY } from "../../../../constants/emailVerification";
import { Path } from "../../../../constants/paths";
import { RedemptionProcessWizardView } from "./components/RedemptionProcessWizardView";
import { RedemptionProcessProvider } from "./context/RedemptionProcessContext";

export function RedemptionProcessWizard() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(["invoices"]);
      queryClient.invalidateQueries(["invoicePayments"]);
    };
  }, [queryClient]);

  const handleClose = useCallback(() => {
    const { navigatedFrom } = location.state || {};
    if (navigatedFrom === CONTRACT_CANCELLATION_FROM_STATE) {
      navigate(-1);
    } else {
      navigate("..");
    }
  }, [location.state, navigate]);

  const { contract } = useOutletContext<{
    contract?: BikeBenefitContractRead;
  }>();
  const [searchParams] = useSearchParams();

  if (!contract) return <Navigate to={`/${Path.myBikes}/${orderId}`} replace />;

  if (
    contract.state !== BikeBenefitContractState.Active &&
    searchParams.has(EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY)
  ) {
    return <Navigate to={`/${Path.profile}/${Path.settings}`} replace />;
  }

  if (
    !(
      [
        BikeBenefitContractState.Active,
        BikeBenefitContractState.ActivePendingRedemption,
      ] as BikeBenefitContractState[]
    ).includes(contract.state)
  ) {
    return (
      <Navigate
        to={`/${Path.myBikes}/${contract.bikeBenefitOrderId}`}
        replace
      />
    );
  }

  return (
    <RedemptionProcessProvider>
      <RedemptionProcessWizardView onClose={handleClose} />
    </RedemptionProcessProvider>
  );
}
