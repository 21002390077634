import { ReactNode } from "react";

import clsx from "clsx";
import { capitalize } from "lodash-es";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { makeTransition } from "../styles";

type ButtonProps = {
  onClick: () => void;
  to?: never;
};

type LinkProps = {
  to: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  rel?: string;
  onClick?: never;
};

type TextSize = "lg" | "md" | "sm";

export type TextButtonProps = (ButtonProps | LinkProps) & {
  align?: "center" | "inherit" | "left" | "right" | "justify";
  size?: TextSize;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
};

export const TextButton = styled.button.attrs<TextButtonProps>((props) => ({
  as: props?.onClick ? "button" : Link,
  className: clsx(props?.disabled && "disabled", props?.className),
  type: "button",
}))<TextButtonProps>`
  text-align: ${({ align }) => align ?? "left"};
  color: ${({ theme }) => theme.palette.primary1.main};
  font-weight: ${({ theme }) => theme.typography.link.fontWeight};
  font-family: ${({ theme }) => theme.typography.link.fontFamily};
  font-size: ${({ theme, size = "lg" }) =>
    theme.typography[`body${capitalize(size)}`].fontSize};
  cursor: pointer;
  text-decoration: ${({ theme }) => theme.typography.link.textDecoration};
  background: none;
  border: none;
  padding: 0;
  ${makeTransition("all", "standard", "easeOut")}

  &:hover {
    color: ${({ theme }) => theme.palette.primary1.light};
  }
  &:active {
    color: ${({ theme }) => theme.palette.primary1.light};
  }
  &.disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }
`;
