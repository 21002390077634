import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Flex,
  LocalShippingIcon,
  Modal,
  OptionButton,
  PersonIcon,
  TextButton,
} from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { useShippingFees } from "./hooks/useShippingFees";

export function ChooseReturnMethodView() {
  const { t } = useTranslation();
  const { onSelfReturn, onPickup, isContractEndingSoon } =
    useReturnProcessContext();
  const { description, pickupOptionText } = useShippingFees();
  const [isContractEndDateModalOpen, setIsContractEndDateModalOpen] =
    useState<boolean>(false);

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.chooseReturnMethod.title")}
      description={description}
    >
      <StyledRoot gap="lg" direction="column" fullWidth>
        <Flex gap="md" xsDirection="column" fullWidth align="stretch">
          <OptionButton
            icon={PersonIcon}
            title={t(
              "userV2:returnProcess.chooseReturnMethod.selfReturnButton.title",
            )}
            subtitle={t(
              "userV2:returnProcess.chooseReturnMethod.selfReturnButton.subtitle",
            )}
            onClick={onSelfReturn}
          />
          <OptionButton
            icon={LocalShippingIcon}
            title={t(
              "userV2:returnProcess.chooseReturnMethod.pickupButton.title",
            )}
            subtitle={pickupOptionText}
            onClick={onPickup}
          />
        </Flex>
        {!isContractEndingSoon ? (
          <TextButton
            align="center"
            size="md"
            onClick={() => setIsContractEndDateModalOpen(true)}
          >
            {t(
              "userV2:returnProcess.chooseReturnMethod.howIsTheDateDetermined",
            )}
          </TextButton>
        ) : null}
      </StyledRoot>
      <Modal
        isOpen={isContractEndDateModalOpen}
        title={t(
          "userV2:returnProcess.chooseReturnMethod.contractEndDateModal.title",
        )}
        description={t(
          "userV2:returnProcess.chooseReturnMethod.contractEndDateModal.description",
        )}
        confirmText={t("commonV2:gotIt")}
        onClose={() => setIsContractEndDateModalOpen(false)}
        onConfirm={() => setIsContractEndDateModalOpen(false)}
      />
    </WizardPageViewLayout>
  );
}

const StyledRoot = styled(Flex)`
  margin-top: -1rem;
`;
