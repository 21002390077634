import { useParams } from "react-router-dom";

import { useGetBenefitDefinitionAccessRequests } from "../../../api/benefitDefinitionAccessRequests";
import { BenefitPage } from "../BenefitPage";

export function AccessRequestBenefitPage() {
  const { accessRequestId } = useParams();

  const { data: accessRequests } = useGetBenefitDefinitionAccessRequests(
    {
      ids: [accessRequestId ?? ""],
    },
    {
      enabled: !!accessRequestId,
    },
  );

  const accessRequest = accessRequests?.items[0];

  if (!accessRequestId) {
    return null;
  }

  return (
    <BenefitPage
      benefitId={accessRequestId}
      benefitDefinitionId={accessRequest?.benefitDefinitionId ?? ""}
      status={accessRequest?.status}
      businessId={accessRequest?.businessId}
    />
  );
}
