import { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Flex, Notification, Typography } from "@vapaus/ui-v2";

import { useApiError } from "./useApiError";

type SubtitleWidth = "sm" | "md" | "lg";

type OnboardingPageLayoutProps = {
  children?: ReactNode;
  title: string;
  subtitle: ReactNode;
  subtitleWidth?: SubtitleWidth;
  additionalTitle?: ReactNode;
};

export function OnboardingPageLayout({
  children,
  title,
  subtitle,
  subtitleWidth = "lg",
  additionalTitle,
}: OnboardingPageLayoutProps) {
  const { apiError } = useApiError();

  return (
    <StyledMainContent align="center" gap="md" direction="column">
      <Flex direction="column">
        {additionalTitle ? (
          <StyledAdditionalTitleWrapper>
            <Typography variant="h5" align="center">
              {additionalTitle}
            </Typography>
          </StyledAdditionalTitleWrapper>
        ) : null}
        <StyledTitleWrapper>
          <Typography variant="h1" align="center">
            {title}
          </Typography>
        </StyledTitleWrapper>
        <StyledContentWrapper $subtitleWidth={subtitleWidth}>
          <Typography align="center" variant="bodyLg">
            {subtitle}
          </Typography>
        </StyledContentWrapper>
        {apiError ? (
          <StyledContentWrapper $subtitleWidth={subtitleWidth}>
            <Notification type="error" message={apiError} />
          </StyledContentWrapper>
        ) : null}
      </Flex>
      {children}
    </StyledMainContent>
  );
}

const StyledMainContent = styled(Flex)`
  flex: 1;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${({ theme }) => theme.palette.background.secondary};

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${theme.spacing.xl} ${theme.spacing.lg};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${theme.spacing.xl} ${theme.spacing.md};
    `)}
`;

const StyledAdditionalTitleWrapper = styled.div`
  margin-bottom: -${({ theme }) => theme.spacing.xs};
`;

const StyledTitleWrapper = styled.div`
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;

const StyledContentWrapper = styled.div<{ $subtitleWidth: SubtitleWidth }>`
  width: ${({ $subtitleWidth }) =>
    $subtitleWidth === "sm"
      ? "24rem"
      : $subtitleWidth === "md"
      ? "30rem"
      : "35rem"};

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
