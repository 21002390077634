import { createSvgIcon } from "./SvgIcon";

export const HeartIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 20.325C11.7667 20.325 11.5292 20.2834 11.2875 20.2C11.0458 20.1167 10.8333 19.9834 10.65 19.8L8.925 18.225C7.15833 16.6084 5.5625 15.0042 4.1375 13.4125C2.7125 11.8209 2 10.0667 2 8.15002C2 6.58336 2.525 5.27502 3.575 4.22502C4.625 3.17502 5.93333 2.65002 7.5 2.65002C8.38333 2.65002 9.21667 2.83752 10 3.21252C10.7833 3.58752 11.45 4.10002 12 4.75002C12.55 4.10002 13.2167 3.58752 14 3.21252C14.7833 2.83752 15.6167 2.65002 16.5 2.65002C18.0667 2.65002 19.375 3.17502 20.425 4.22502C21.475 5.27502 22 6.58336 22 8.15002C22 10.0667 21.2917 11.825 19.875 13.425C18.4583 15.025 16.85 16.6334 15.05 18.25L13.35 19.8C13.1667 19.9834 12.9542 20.1167 12.7125 20.2C12.4708 20.2834 12.2333 20.325 12 20.325Z" />
  </svg>,
  "Heart",
);
