/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
} from "./BikeBrand";

/**
 *
 * @export
 * @interface PaginatedBikeBrandOut
 */
export interface PaginatedBikeBrandOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBikeBrandOut
   */
  total: number;
  /**
   *
   * @type {Array<BikeBrand>}
   * @memberof PaginatedBikeBrandOut
   */
  items: Array<BikeBrand>;
}

/**
 * Check if a given object implements the PaginatedBikeBrandOut interface.
 */
export function instanceOfPaginatedBikeBrandOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedBikeBrandOutFromJSON(
  json: any,
): PaginatedBikeBrandOut {
  return PaginatedBikeBrandOutFromJSONTyped(json, false);
}

export function PaginatedBikeBrandOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBikeBrandOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BikeBrandFromJSON),
  };
}

export function PaginatedBikeBrandOutToJSON(
  value?: PaginatedBikeBrandOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(BikeBrandToJSON),
  };
}
