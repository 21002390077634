/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeCondition = {
  New: "new",
  LikeNew: "like_new",
  Excellent: "excellent",
  VeryGood: "very_good",
  Satisfactory: "satisfactory",
  Poor: "poor",
} as const;
export type BikeCondition = (typeof BikeCondition)[keyof typeof BikeCondition];

export function BikeConditionFromJSON(json: any): BikeCondition {
  return BikeConditionFromJSONTyped(json, false);
}

export function BikeConditionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeCondition {
  return json as BikeCondition;
}

export function BikeConditionToJSON(value?: BikeCondition | null): any {
  return value as any;
}
