import { compact } from "lodash-es";

import { useReturnProcessContext } from "../../../context/ReturnProcessContext";
import { ReturnProcessAccessory } from "../../../types";

export function useReturnProcessAccessories() {
  const {
    accessoryIdsSelectedForRedemption,
    accessories: accessoriesFromContract,
    preliminaryData,
  } = useReturnProcessContext();

  const accessories: ReturnProcessAccessory[] = compact(
    accessoriesFromContract.map((accessoryFromContract) => {
      const accessoryFromPreliminaryData =
        preliminaryData?.accessoryRedemptionCalculations.find(
          (accessory) => accessory.accessoryId === accessoryFromContract.id,
        );

      if (!accessoryFromPreliminaryData) {
        return null;
      }

      return {
        id: accessoryFromContract.id!,
        type: accessoryFromContract.accessoryType,
        manufacturer: accessoryFromContract.manufacturer,
        model: accessoryFromContract.model,
        purchasePrice: accessoryFromPreliminaryData.purchasePrice,
        redemptionPrice: accessoryFromPreliminaryData.redemptionPrice,
        discountPercentage: accessoryFromPreliminaryData.discountPercentage,
      };
    }),
  );

  const accessoriesSorted = accessories.sort((a, b) =>
    a.type === "helmet" ? -1 : b.type === "helmet" ? 1 : 0,
  );

  const selectedAccessoriesTotalPrice = accessories
    .filter((accessory) =>
      accessoryIdsSelectedForRedemption.includes(accessory.id!),
    )
    .map((accessory) => accessory.redemptionPrice)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return {
    accessories: accessoriesSorted,
    selectedAccessoriesTotalPrice,
  };
}
