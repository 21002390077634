import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import { useCurrentUser } from "@vapaus/shared-api";

export function useUserCountry() {
  const { data: user } = useCurrentUser();

  const isSwedishUser = user?.country === SupportedCountriesEnum.Se;

  return {
    isSwedishUser,
  };
}
