import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Input } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { AdditionalData, useOnboardingMachine } from "../stateMachine";

export function AdditionalDataPage() {
  const { t } = useTranslation();

  const {
    selectedBenefit,
    selectedEmployer,
    additionalData,
    isLoading,
    setAdditionalData,
    goToNext,
  } = useOnboardingMachine();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<AdditionalData>({
    defaultValues: additionalData,
  });

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.additionalData.title")}
      subtitle={
        <Trans
          i18nKey="userV2:onboarding.additionalData.subtitle"
          values={{ employer: selectedEmployer?.name || "" }}
          components={{ b: <b /> }}
        />
      }
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(goToNext)}>
          <Flex direction="column" gap="md" align="center">
            {selectedBenefit?.isEmployeeNumberMandatory ? (
              <Input
                fullWidth
                placeholder={t(
                  "userV2:onboarding.additionalData.employeeNumber",
                )}
                label={t("userV2:onboarding.additionalData.employeeNumber")}
                tooltip={t("userV2:onboarding.additionalData.tooltip")}
                {...register("employeeNumber", {
                  required: true,
                  onChange: (e) =>
                    setAdditionalData({ employeeNumber: e.target.value }),
                })}
              />
            ) : null}
            {selectedBenefit?.isCostCenterMandatory ? (
              <Input
                fullWidth
                placeholder={t("userV2:onboarding.additionalData.costCenter")}
                label={t("userV2:onboarding.additionalData.costCenter")}
                tooltip={t("userV2:onboarding.additionalData.tooltip")}
                {...register("costCenter", {
                  required: true,
                  onChange: (e) =>
                    setAdditionalData({ costCenter: e.target.value }),
                })}
              />
            ) : null}
            <Button
              type="submit"
              fullWidth
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
    </OnboardingPageLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
