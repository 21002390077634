import styled, { css } from "styled-components";

export const StyledRoot = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xl}`};
  min-height: calc(100vh - ${({ theme }) => theme.height.navBar});

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${theme.spacing.xl} ${theme.spacing.md};
    `)}
`;
