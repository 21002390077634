/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ShopLocation
 */
export interface ShopLocation {
  /**
   *
   * @type {string}
   * @memberof ShopLocation
   */
  city: string;
}

/**
 * Check if a given object implements the ShopLocation interface.
 */
export function instanceOfShopLocation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "city" in value;

  return isInstance;
}

export function ShopLocationFromJSON(json: any): ShopLocation {
  return ShopLocationFromJSONTyped(json, false);
}

export function ShopLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopLocation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    city: json["city"],
  };
}

export function ShopLocationToJSON(value?: ShopLocation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    city: value.city,
  };
}
