import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { parseApiError } from "@vapaus/shared-api";

import { useOnboardingMachine } from "../stateMachine";

export function useApiError() {
  const [apiError, setApiError] = useState<string | undefined>(undefined);
  const { wizardSubmitError } = useOnboardingMachine();
  const { t } = useTranslation();

  useEffect(() => {
    if (wizardSubmitError) {
      const parseError = async () => {
        const error = await wizardSubmitError.response.clone().json();
        if (
          error.code &&
          error.code === "USER_EMAIL_AlREADY_EXISTS_SAME_ACCOUNT"
        ) {
          setApiError(t("userV2:onboarding.error.emailAlreadyAdded"));
          return;
        }
        setApiError(await parseApiError(wizardSubmitError));
      };

      parseError();
    }
  }, [t, wizardSubmitError]);

  return { apiError };
}
