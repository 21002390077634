import { ReactNode } from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import { Flex, NavigationButton } from "@vapaus/ui-v2";

import { useGetCurrentUser } from "../../../api/user";
import { Path } from "../../../constants/paths";

export function NavigationButtons(): ReactNode {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUser();

  return (
    <Flex gap="xs">
      <NavLink to={Path.myBikes}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("userV2:navigation.buttons.myBikes")}
          </NavigationButton>
        )}
      </NavLink>
      <NavLink to={Path.getABike}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("userV2:navigation.buttons.getABike")}
          </NavigationButton>
        )}
      </NavLink>
      {user?.country === SupportedCountriesEnum.Fi ? (
        <NavLink to={Path.calculator}>
          {({ isActive }) => (
            <NavigationButton isActivated={isActive}>
              {t("userV2:navigation.buttons.calculator")}
            </NavigationButton>
          )}
        </NavLink>
      ) : null}
      <NavLink to={Path.benefits}>
        {({ isActive }) => (
          <NavigationButton isActivated={isActive}>
            {t("userV2:navigation.buttons.benefits")}
          </NavigationButton>
        )}
      </NavLink>
    </Flex>
  );
}
