/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { UserBenefitDefinitionAccessRequestRead } from "./UserBenefitDefinitionAccessRequestRead";
import {
  UserBenefitDefinitionAccessRequestReadFromJSON,
  UserBenefitDefinitionAccessRequestReadFromJSONTyped,
  UserBenefitDefinitionAccessRequestReadToJSON,
} from "./UserBenefitDefinitionAccessRequestRead";

/**
 *
 * @export
 * @interface UserBenefitActivationRead
 */
export interface UserBenefitActivationRead {
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  benefitDefinitionId: string;
  /**
   * If this value is null, it has been chosen by shop using the old 'choose what you want' logic
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  accessRulesId?: string;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  revokedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  revokedByUserId?: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitActivationRead
   */
  id: string;
  /**
   *
   * @type {UserBenefitDefinitionAccessRequestRead}
   * @memberof UserBenefitActivationRead
   */
  accessRequest?: UserBenefitDefinitionAccessRequestRead;
  /**
   *
   * @type {boolean}
   * @memberof UserBenefitActivationRead
   */
  isActive: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserBenefitActivationRead
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the UserBenefitActivationRead interface.
 */
export function instanceOfUserBenefitActivationRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "isActive" in value;

  return isInstance;
}

export function UserBenefitActivationReadFromJSON(
  json: any,
): UserBenefitActivationRead {
  return UserBenefitActivationReadFromJSONTyped(json, false);
}

export function UserBenefitActivationReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserBenefitActivationRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
    benefitDefinitionId: json["benefit_definition_id"],
    accessRulesId: !exists(json, "access_rules_id")
      ? undefined
      : json["access_rules_id"],
    revokedAt: !exists(json, "revoked_at")
      ? undefined
      : new Date(json["revoked_at"]),
    revokedByUserId: !exists(json, "revoked_by_user_id")
      ? undefined
      : json["revoked_by_user_id"],
    id: json["id"],
    accessRequest: !exists(json, "access_request")
      ? undefined
      : UserBenefitDefinitionAccessRequestReadFromJSON(json["access_request"]),
    isActive: json["is_active"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function UserBenefitActivationReadToJSON(
  value?: UserBenefitActivationRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
    benefit_definition_id: value.benefitDefinitionId,
    access_rules_id: value.accessRulesId,
    revoked_at:
      value.revokedAt === undefined ? undefined : value.revokedAt.toISOString(),
    revoked_by_user_id: value.revokedByUserId,
    id: value.id,
    access_request: UserBenefitDefinitionAccessRequestReadToJSON(
      value.accessRequest,
    ),
    is_active: value.isActive,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
