import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { BenefitDefinitionUserRead } from "@vapaus/api-codegen";
import { Form, Input, Notification } from "@vapaus/form";
import { Flex, HorizontalLine, Modal } from "@vapaus/ui-v2";

import { useMissingInfoForm } from "../hooks/useMissingInfoForm";

export type MissingInfoModalProps = {
  benefitDefinition: BenefitDefinitionUserRead;
  isOpen: boolean;
  onClose: () => void;
};

export function MissingInfoModal({
  isOpen,
  onClose,
  benefitDefinition,
}: MissingInfoModalProps) {
  const { t } = useTranslation();
  const { formMethods, mutation, user, showNameFields, showAddressFields } =
    useMissingInfoForm({
      onSuccess: onClose,
      benefitDefinition,
    });

  const { reset, formState } = formMethods;
  useEffect(() => {
    if (isOpen && user && !formState.isDirty) {
      let defaultValues = {};

      if (showNameFields) {
        defaultValues = {
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
        };
      }

      if (showAddressFields) {
        defaultValues = {
          ...defaultValues,
          address: user?.address || "",
          postCode: user?.postCode || "",
          city: user?.city || "",
        };
      }

      if (benefitDefinition.isEmployeeNumberMandatory) {
        defaultValues = {
          ...defaultValues,
          employeeNumber: user?.employeeNumber || "",
        };
      }
      if (benefitDefinition.isCostCenterMandatory) {
        defaultValues = {
          ...defaultValues,
          costCenter: user?.costCenter || "",
        };
      }
      reset(defaultValues);
    }
  }, [
    user,
    isOpen,
    showNameFields,
    showAddressFields,
    benefitDefinition.isEmployeeNumberMandatory,
    benefitDefinition.isCostCenterMandatory,
    formState.isDirty,
    reset,
  ]);

  return (
    <Modal
      isOpen={Boolean(isOpen && user)}
      title={t("userV2:myBike.missingInfoModal.title")}
      description={t("userV2:myBike.missingInfoModal.description")}
      confirmText={t("commonV2:save")}
      cancelText={t("commonV2:cancel")}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <Form formMethods={formMethods} name="employeeInfo">
        <Flex direction="column" gap="sm">
          <Notification />
          {showNameFields && (
            <>
              <Input
                name="firstName"
                label={t("userV2:myBike.missingInfoModal.firstName")}
                placeholder={t(
                  "userV2:myBike.missingInfoModal.firstNamePlaceholder",
                )}
              />
              <Input
                name="lastName"
                label={t("userV2:myBike.missingInfoModal.lastName")}
                placeholder={t(
                  "userV2:myBike.missingInfoModal.lastNamePlaceholder",
                )}
              />
            </>
          )}
          {showAddressFields && (
            <>
              <Input
                name="address"
                label={t("userV2:myBike.missingInfoModal.address")}
                placeholder={t("userV2:myBike.missingInfoModal.address")}
              />
              <Input
                name="postCode"
                label={t("userV2:myBike.missingInfoModal.postCode")}
                placeholder={t("userV2:myBike.missingInfoModal.postCode")}
              />
              <Input
                name="city"
                label={t("userV2:myBike.missingInfoModal.city")}
                placeholder={t("userV2:myBike.missingInfoModal.city")}
              />
            </>
          )}

          {(benefitDefinition.isEmployeeNumberMandatory ||
            benefitDefinition.isCostCenterMandatory) &&
            (showNameFields || showAddressFields) && (
              <HorizontalLine marginTop="xs" marginBottom="xs" />
            )}

          {benefitDefinition.isEmployeeNumberMandatory && (
            <Input
              name="employeeNumber"
              label={t("userV2:myBike.missingInfoModal.employeeNumber")}
              placeholder={t("userV2:myBike.missingInfoModal.employeeNumber")}
              tooltip={t("userV2:myBike.missingInfoModal.tooltipInfo")}
            />
          )}
          {benefitDefinition.isCostCenterMandatory && (
            <Input
              name="costCenter"
              label={t("userV2:myBike.missingInfoModal.costCenter")}
              placeholder={t("userV2:myBike.missingInfoModal.costCenter")}
              tooltip={t("userV2:myBike.missingInfoModal.tooltipInfo")}
            />
          )}
        </Flex>
      </Form>
    </Modal>
  );
}
