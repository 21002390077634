import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Button, Flex, Select } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { CountryData, useOnboardingMachine } from "../stateMachine";

export function SelectCountryPage() {
  const { t } = useTranslation();
  const { countryData, setCountry, saveCountry } = useOnboardingMachine();

  const { control, handleSubmit } = useForm<CountryData>({
    defaultValues: countryData,
  });

  const { entries: supportedCountries } = useEnumEntries(
    "SupportedCountriesEnum",
  );

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.selectCountry.title")}
      subtitle={t("userV2:onboarding.selectCountry.subtitle")}
      subtitleWidth="sm"
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(saveCountry)}>
          <Flex direction="column" gap="md" align="center">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  options={supportedCountries}
                  placeholder={t("userV2:onboarding.selectCountry.placeholder")}
                  required
                  name={field.name}
                  value={field.value}
                  onChange={(_, value) => {
                    field.onChange(value);
                    setCountry({ country: value as SupportedCountriesEnum });
                  }}
                  fullWidth
                />
              )}
            />
            <Button type="submit" fullWidth disabled={!countryData.country}>
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
    </OnboardingPageLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
