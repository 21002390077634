import { SupportedLanguagesEnum } from "@vapaus/api-codegen";

export const supportedLanguages: Array<{
  id: SupportedLanguagesEnum;
  name: string;
}> = [
  { id: "FI", name: "Suomi" },
  { id: "EN", name: "English" },
  { id: "SV", name: "Svenska" },
];
