import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import invariant from "tiny-invariant";

import { PaymentConsent, ResponseError } from "@vapaus/api-codegen";
import { parseApiError } from "@vapaus/shared-api";
import {
  Flex,
  Modal,
  Notification,
  TextButton,
  Typography,
  useToaster,
} from "@vapaus/ui-v2";

import { useCancelPaymentConsent } from "../../../../api/paymentConsent";

type RemoveCardButtonProps = {
  paymentConsent: PaymentConsent;
};

export function RemoveCardButton({ paymentConsent }: RemoveCardButtonProps) {
  const { t } = useTranslation();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const cancelPaymentConsent = useCancelPaymentConsent();

  const handleOpenModal = () => setIsOpenRemoveModal(true);
  const handleCloseModal = () => setIsOpenRemoveModal(false);

  const handleRemoveCard = () => {
    setErrorMessage(undefined);
    invariant(paymentConsent.id, "paymentConsent.id must have a value");
    cancelPaymentConsent.mutate(paymentConsent.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["paymentConsents"]);
        toaster.add("success", t("userV2:paymentCard.paymentCardRemoved"));
        handleCloseModal();
      },
      onError: async (error) => {
        const message = await parseApiError(error as unknown as ResponseError);
        setErrorMessage(message);
      },
    });
  };

  const handleCancelModal = () => {
    cancelPaymentConsent.reset();
    handleCloseModal();
  };

  return (
    <>
      <TextButton onClick={handleOpenModal} size="md">
        {t("userV2:paymentCard.remove")}
      </TextButton>
      <Modal
        isOpen={isOpenRemoveModal}
        onClose={handleCloseModal}
        onCancel={handleCancelModal}
        onConfirm={handleRemoveCard}
        isSubmitting={cancelPaymentConsent.isLoading}
        confirmButtonVariant="primaryDanger"
        title={t("userV2:paymentCard.removePaymentCard")}
        confirmText={t("userV2:paymentCard.removeCard")}
        cancelText={t("commonV2:cancel")}
      >
        <Flex direction="column" gap="md">
          <Typography variant="bodyLg" align="center">
            <Trans
              t={t}
              i18nKey="userV2:paymentCard.confirmRemovePaymentCard"
              values={{
                info: paymentConsent.sourceBrand
                  ? `${paymentConsent.sourceBrand} ***${paymentConsent?.sourceLabel}`
                  : "",
              }}
              components={{ 1: <strong /> }}
            />
          </Typography>
          {cancelPaymentConsent.isError ? (
            <Notification
              type="error"
              message={
                errorMessage || t("userV2:paymentCard.removePaymentCardFailed")
              }
            />
          ) : null}
        </Flex>
      </Modal>
    </>
  );
}
