import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { SupportedCountriesEnum } from "@vapaus/api-codegen";
import { Form, Input, Notification, Select } from "@vapaus/form";
import { Flex, Modal } from "@vapaus/ui-v2";

import { useDisplayName } from "../../../../packages/i18n/src/hooks";
import { useChangeAddressForm } from "./useChangeAddressForm";

export type ChangeAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ChangeAddressModal({
  isOpen,
  onClose,
}: ChangeAddressModalProps) {
  const { t } = useTranslation();
  const displayNameOf = useDisplayName();
  const { formMethods, mutation, user } = useChangeAddressForm({
    onSuccess: onClose,
  });

  const { setValue } = formMethods;
  useEffect(() => {
    if (isOpen && user) {
      setValue("address", user.address || "");
      setValue("postCode", user.postCode || "");
      setValue("city", user.city || "");
      setValue("country", user.country || "");
    }
  }, [user, setValue, isOpen]);

  const countries = Object.values(SupportedCountriesEnum).map((value) => ({
    value,
    label: displayNameOf(value) || value,
  }));

  return (
    <Modal
      isOpen={Boolean(isOpen && user)}
      title={t("commonV2:changeAddressModal.title")}
      description={t("commonV2:changeAddressModal.description")}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:save")}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <Form formMethods={formMethods} name="address">
        <Flex direction="column" gap="sm">
          <Notification />
          <Input
            name="address"
            label={t("commonV2:changeAddressModal.address")}
            placeholder={t("commonV2:changeAddressModal.address")}
          />
          <Flex>
            <Input
              name="postCode"
              label={t("commonV2:changeAddressModal.postCode")}
              placeholder={t("commonV2:changeAddressModal.postCode")}
            />
            <Input
              name="city"
              label={t("commonV2:changeAddressModal.city")}
              placeholder={t("commonV2:changeAddressModal.city")}
            />
          </Flex>
          <Select
            options={countries}
            name="country"
            label={t("commonV2:changeAddressModal.country")}
            placeholder={t("commonV2:changeAddressModal.country")}
            disabled
          />
        </Flex>
      </Form>
    </Modal>
  );
}
