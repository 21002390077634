import { ComponentType, ReactElement } from "react";

import styled from "styled-components";

import { CircularIcon } from "../../CircularIcon";
import { SvgIconProps } from "../../Icons";
import { Typography } from "../../Typography";

export type DropdownHeaderProps = {
  icon: ComponentType<SvgIconProps>;
  title: string;
  description: string;
};

export const DropdownHeader = ({
  icon: Icon,
  title,
  description,
}: DropdownHeaderProps): ReactElement => (
  <Root>
    <CircularIcon icon={Icon} />
    <StyledContainer>
      <Typography noWrap variant="h6">
        {title}
      </Typography>
      <Typography noWrap variant="bodyMd">
        {description}
      </Typography>
    </StyledContainer>
  </Root>
);

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.md};
  border-bottom: ${({ theme }) => theme.border.primary.light};
  background: ${({ theme }) => theme.palette.background.white};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
