import { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { CheckFillIcon, Flex, Paper, Typography } from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "./WizardPageViewLayout";

type ReturnChecklistPageProps = {
  children?: ReactNode;
};

export function ReturnChecklistPage({ children }: ReturnChecklistPageProps) {
  const { t } = useTranslation();

  const checklistItems = [
    {
      title: t(
        "userV2:returnProcess.returnChecklist.removeRedeemedAccessories.title",
      ),
      description: t(
        "userV2:returnProcess.returnChecklist.removeRedeemedAccessories.description",
      ),
    },
    {
      title: t(
        "userV2:returnProcess.returnChecklist.gatherKeysToolsAndCharger.title",
      ),
      description: t(
        "userV2:returnProcess.returnChecklist.gatherKeysToolsAndCharger.description",
      ),
    },
    {
      title: t("userV2:returnProcess.returnChecklist.disableLocks.title"),
      description: t(
        "userV2:returnProcess.returnChecklist.disableLocks.description",
      ),
    },
    {
      title: t("userV2:returnProcess.returnChecklist.washYourBike.title"),
      description: t(
        "userV2:returnProcess.returnChecklist.washYourBike.description",
      ),
    },
    {
      title: t(
        "userV2:returnProcess.returnChecklist.prepareBikeForShipping.title",
      ),
      description: (
        <Flex direction="column" gap="xs">
          <Typography>
            {t(
              "userV2:returnProcess.returnChecklist.prepareBikeForShipping.description1",
            )}
          </Typography>
          <Typography>
            {t(
              "userV2:returnProcess.returnChecklist.prepareBikeForShipping.description2",
            )}
          </Typography>
        </Flex>
      ),
    },
  ];

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.returnChecklist.title")}
      description={t("userV2:returnProcess.returnChecklist.description")}
    >
      <Paper shadow="md">
        <Flex direction="column" gap="lg">
          {checklistItems.map((item) => (
            <Flex key={item.title} align="flex-start">
              <CheckFillIcon color="secondary1.main" />
              <Flex direction="column" gap="xs">
                <Typography weight="bold">{item.title}</Typography>
                {typeof item.description === "string" ? (
                  <Typography>{item.description}</Typography>
                ) : (
                  item.description
                )}
              </Flex>
            </Flex>
          ))}
          {children}
        </Flex>
      </Paper>
    </WizardPageViewLayout>
  );
}
