import React, { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Flex } from "../../Flex";
import { Logo } from "../../Logo";
import { useBreakpoints } from "../../styles/useBreakpoints";

export type NavigationBarProps = {
  onLogoClick: () => void;
  mainContent: ReactNode;
  secondaryContent?: ReactNode;
  profileMenu: ReactNode;
  className?: string;
};

export const NavigationBar = ({
  onLogoClick,
  mainContent,
  secondaryContent,
  profileMenu,
  className,
}: NavigationBarProps) => {
  const { isMobile } = useBreakpoints();

  const secondaryContentComponents = (
    <>
      {secondaryContent ? (
        <StyledSecondaryContent>{secondaryContent}</StyledSecondaryContent>
      ) : null}
      {profileMenu}
    </>
  );

  return (
    <StyledRoot className={className}>
      <StyledLogoContainer onClick={onLogoClick}>
        <Logo size={isMobile ? "sm" : "lg"} />
      </StyledLogoContainer>
      {!isMobile && <StyledMainContent>{mainContent}</StyledMainContent>}
      {!isMobile ? (
        <StyledSecondaryContentContainer justify="flex-end">
          {secondaryContentComponents}
        </StyledSecondaryContentContainer>
      ) : (
        secondaryContentComponents
      )}
    </StyledRoot>
  );
};

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.lg};
  height: ${({ theme }) => theme.height.navBar};
  background-color: ${({ theme }) => theme.palette.background.white};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
`;

const StyledLogoContainer = styled.span`
  display: flex;
  cursor: pointer;
  flex: 1;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      flex: 0;
    `)}
`;

const StyledSecondaryContentContainer = styled(Flex)`
  flex: 1;
  min-width: 0;

  div {
    min-width: 0;
  }
`;

const StyledMainContent = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;

const StyledSecondaryContent = styled.div`
  display: flex;
  justify-content: end;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      justify-content: center;
    `)}
`;
