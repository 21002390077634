import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import type { ToastItem, ToastType } from "./Toast.types";
import { Toaster } from "./Toaster";

export interface ToastContextType {
  add: (type: ToastType, msg: string) => void;
}

const ToastContext = createContext<ToastContextType>({
  add: (type: ToastType, msg: string) => undefined,
});

export const useToaster = () => useContext(ToastContext);

interface ToastProviderProps {
  children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const keyRef = useRef<number>(0);
  const [items, setItems] = useState<Array<ToastItem>>([]);

  const handleItemEndOfLife = useCallback(
    function handleItemEndOfLife(item: ToastItem) {
      setItems((items) => items.filter((i) => i.key !== item.key));
    },
    [setItems],
  );

  const add = useCallback(
    function add(type: ToastType, msg: string) {
      const newItem: ToastItem = { type, msg, key: keyRef.current++ };
      setItems((items) => [...items, newItem]);
    },
    [setItems],
  );

  const value = useMemo(() => ({ add }), [add]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toaster items={items} onItemEndOfLife={handleItemEndOfLife} />
    </ToastContext.Provider>
  );
}
