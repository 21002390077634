import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Flex } from "@vapaus/ui-v2";

import { useGetContract, useGetOrder } from "../../../../api/benefit";
import { MaintenanceBudgetCard } from "./components/MaintenanceBudgetCard";
import { MaintenanceTableCard } from "./components/MaintenanceTableCard";

export function TabPanelMaintenance() {
  const { orderId } = useParams();
  invariant(orderId);

  const { data: order } = useGetOrder(orderId);
  const { data: contract } = useGetContract(
    order?.currentBikeBenefitContractId,
  );

  if (!contract) return null;

  return (
    <Flex direction="column" gap="lg">
      <MaintenanceBudgetCard contract={contract} />
      <MaintenanceTableCard contract={contract} />
    </Flex>
  );
}
