import { useUserCountry } from "@vapaus/common-v2";

import { FooterDefault } from "./FooterDefault";
import { FooterSweden } from "./FooterSweden";

export function Footer() {
  const { isSwedishUser } = useUserCountry();

  return isSwedishUser ? <FooterSweden /> : <FooterDefault />;
}
