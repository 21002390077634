import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Modal, TextButton } from "@vapaus/ui-v2";

type PayLaterButtonProps = {
  isLoading: boolean;
  handlePayLater: () => void;
};

export function PayLaterButton({
  isLoading,
  handlePayLater,
}: PayLaterButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <TextButton size="md" onClick={() => setIsOpen(true)}>
        {t("userV2:redeemReturnSharedComponents.payLaterButton.payLater")}
      </TextButton>
      <Modal
        isOpen={isOpen}
        title={t("userV2:redeemReturnSharedComponents.payLaterButton.payLater")}
        description={t(
          "userV2:redeemReturnSharedComponents.payLaterButton.payLaterNotice",
        )}
        onCancel={handleClose}
        onClose={handleClose}
        cancelText={t("commonV2:cancel")}
        confirmText={t("commonV2:gotIt")}
        onConfirm={handlePayLater}
        isSubmitting={isLoading}
      />
    </>
  );
}
