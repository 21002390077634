import { useTranslation } from "react-i18next";

import { LanguageSelect, type LanguageSelectProps } from "@vapaus/ui-v2";

type LanguageSelectV2Props = {
  variant?: LanguageSelectProps<string, false>["variant"];
  onChange?: (language: string) => void;
};

export function LanguageSelectV2({ variant, onChange }: LanguageSelectV2Props) {
  const { i18n } = useTranslation();

  return (
    <LanguageSelect
      options={[
        { value: "en", label: "EN" },
        { value: "fi", label: "FI" },
        { value: "sv", label: "SV" },
      ]}
      onChange={(event, value) => {
        if (!value) return;
        i18n.changeLanguage(value);
        onChange?.(value);
      }}
      value={i18n.language}
      variant={variant}
    />
  );
}
