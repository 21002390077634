/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ReturnProcessInvoicingAction,
  ReturnProcessPlanInvoicingIn,
  ReturnProcessPreliminaryData,
  ReturnProcessRead,
  StorageObjectRead,
  UserReturnProcessCreate,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ReturnProcessInvoicingActionFromJSON,
  ReturnProcessInvoicingActionToJSON,
  ReturnProcessPlanInvoicingInFromJSON,
  ReturnProcessPlanInvoicingInToJSON,
  ReturnProcessPreliminaryDataFromJSON,
  ReturnProcessPreliminaryDataToJSON,
  ReturnProcessReadFromJSON,
  ReturnProcessReadToJSON,
  StorageObjectReadFromJSON,
  StorageObjectReadToJSON,
  UserReturnProcessCreateFromJSON,
  UserReturnProcessCreateToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserReturnProcessesGetReturnProcessRequest {
  returnProcessId: string;
}

export interface UserReturnProcessesGetReturnProcessPreliminaryDataRequest {
  bikeBenefitContractId: string;
  isEndOfLeaseReturn: boolean;
}

export interface UserReturnProcessesUserCreateReturnProcessRequest {
  userReturnProcessCreate: UserReturnProcessCreate;
}

export interface UserReturnProcessesUserPlanReturnProcessInvoicingRequest {
  returnProcessPlanInvoicingIn: ReturnProcessPlanInvoicingIn;
}

export interface UserReturnProcessesUserUploadReturnProcessPictureRequest {
  file: Blob;
}

/**
 *
 */
export class UserReturnProcessesApi extends runtime.BaseAPI {
  /**
   * Get Return Process
   */
  async userReturnProcessesGetReturnProcessRaw(
    requestParameters: UserReturnProcessesGetReturnProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ReturnProcessRead>> {
    if (
      requestParameters.returnProcessId === null ||
      requestParameters.returnProcessId === undefined
    ) {
      throw new runtime.RequiredError(
        "returnProcessId",
        "Required parameter requestParameters.returnProcessId was null or undefined when calling userReturnProcessesGetReturnProcess.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/return-processes/{return_process_id}`.replace(
          `{${"return_process_id"}}`,
          encodeURIComponent(String(requestParameters.returnProcessId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReturnProcessReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Return Process
   */
  async userReturnProcessesGetReturnProcess(
    requestParameters: UserReturnProcessesGetReturnProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ReturnProcessRead> {
    const response = await this.userReturnProcessesGetReturnProcessRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Return Process Preliminary Data
   */
  async userReturnProcessesGetReturnProcessPreliminaryDataRaw(
    requestParameters: UserReturnProcessesGetReturnProcessPreliminaryDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ReturnProcessPreliminaryData>> {
    if (
      requestParameters.bikeBenefitContractId === null ||
      requestParameters.bikeBenefitContractId === undefined
    ) {
      throw new runtime.RequiredError(
        "bikeBenefitContractId",
        "Required parameter requestParameters.bikeBenefitContractId was null or undefined when calling userReturnProcessesGetReturnProcessPreliminaryData.",
      );
    }

    if (
      requestParameters.isEndOfLeaseReturn === null ||
      requestParameters.isEndOfLeaseReturn === undefined
    ) {
      throw new runtime.RequiredError(
        "isEndOfLeaseReturn",
        "Required parameter requestParameters.isEndOfLeaseReturn was null or undefined when calling userReturnProcessesGetReturnProcessPreliminaryData.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.bikeBenefitContractId !== undefined) {
      queryParameters["bike_benefit_contract_id"] =
        requestParameters.bikeBenefitContractId;
    }

    if (requestParameters.isEndOfLeaseReturn !== undefined) {
      queryParameters["is_end_of_lease_return"] =
        requestParameters.isEndOfLeaseReturn;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/return-processes/preliminary-data`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReturnProcessPreliminaryDataFromJSON(jsonValue),
    );
  }

  /**
   * Get Return Process Preliminary Data
   */
  async userReturnProcessesGetReturnProcessPreliminaryData(
    requestParameters: UserReturnProcessesGetReturnProcessPreliminaryDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ReturnProcessPreliminaryData> {
    const response =
      await this.userReturnProcessesGetReturnProcessPreliminaryDataRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * User Create Return Process
   */
  async userReturnProcessesUserCreateReturnProcessRaw(
    requestParameters: UserReturnProcessesUserCreateReturnProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ReturnProcessRead>> {
    if (
      requestParameters.userReturnProcessCreate === null ||
      requestParameters.userReturnProcessCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "userReturnProcessCreate",
        "Required parameter requestParameters.userReturnProcessCreate was null or undefined when calling userReturnProcessesUserCreateReturnProcess.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/return-processes`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserReturnProcessCreateToJSON(
          requestParameters.userReturnProcessCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReturnProcessReadFromJSON(jsonValue),
    );
  }

  /**
   * User Create Return Process
   */
  async userReturnProcessesUserCreateReturnProcess(
    requestParameters: UserReturnProcessesUserCreateReturnProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ReturnProcessRead> {
    const response = await this.userReturnProcessesUserCreateReturnProcessRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * User Plan Return Process Invoicing
   */
  async userReturnProcessesUserPlanReturnProcessInvoicingRaw(
    requestParameters: UserReturnProcessesUserPlanReturnProcessInvoicingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ReturnProcessInvoicingAction>>> {
    if (
      requestParameters.returnProcessPlanInvoicingIn === null ||
      requestParameters.returnProcessPlanInvoicingIn === undefined
    ) {
      throw new runtime.RequiredError(
        "returnProcessPlanInvoicingIn",
        "Required parameter requestParameters.returnProcessPlanInvoicingIn was null or undefined when calling userReturnProcessesUserPlanReturnProcessInvoicing.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/return-processes/plan-invoicing`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ReturnProcessPlanInvoicingInToJSON(
          requestParameters.returnProcessPlanInvoicingIn,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ReturnProcessInvoicingActionFromJSON),
    );
  }

  /**
   * User Plan Return Process Invoicing
   */
  async userReturnProcessesUserPlanReturnProcessInvoicing(
    requestParameters: UserReturnProcessesUserPlanReturnProcessInvoicingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ReturnProcessInvoicingAction>> {
    const response =
      await this.userReturnProcessesUserPlanReturnProcessInvoicingRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * User Upload Return Process Picture
   */
  async userReturnProcessesUserUploadReturnProcessPictureRaw(
    requestParameters: UserReturnProcessesUserUploadReturnProcessPictureRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StorageObjectRead>> {
    if (
      requestParameters.file === null ||
      requestParameters.file === undefined
    ) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling userReturnProcessesUserUploadReturnProcessPicture.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/return-processes/pictures`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StorageObjectReadFromJSON(jsonValue),
    );
  }

  /**
   * User Upload Return Process Picture
   */
  async userReturnProcessesUserUploadReturnProcessPicture(
    requestParameters: UserReturnProcessesUserUploadReturnProcessPictureRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StorageObjectRead> {
    const response =
      await this.userReturnProcessesUserUploadReturnProcessPictureRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
