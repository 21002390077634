import { useRef, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, Flex, TextButton, Typography } from "@vapaus/ui-v2";

import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { FullPagePhotoModal } from "./components/FullPagePhotoModal";
import { UploadPhotoDragAndDropArea } from "./components/UploadPhotoDragAndDropArea";
import { UploadedPhotoPreview } from "./components/UploadedPhotoPreview";
import examplePhoto from "./components/images/examplePhoto.jpg";
import { useUploadImages } from "./hooks/useUploadImages";

export function UploadPhotosView() {
  const { t } = useTranslation();
  const { onNext } = useReturnProcessContext();

  const [fullPagePhotoModalSrc, setFullPagePhotoModalSrc] = useState<
    string | undefined
  >(undefined);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onUploadedPhotoPreviewClick = (src: string) =>
    setFullPagePhotoModalSrc(src);

  const {
    images,
    onUploadImages,
    onDeleteImage,
    loadingImagesIds,
    uploadImages,
  } = useUploadImages();

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.uploadPhotos.title")}
      description={
        <Trans
          i18nKey="userV2:returnProcess.uploadPhotos.description"
          components={{ b: <b /> }}
        />
      }
    >
      <StyledContainer direction="column" gap="lg">
        <Flex direction="column">
          <UploadPhotoDragAndDropArea uploadImages={uploadImages}>
            <Typography align="center">
              {t("userV2:returnProcess.uploadPhotos.dragAndDropPhotosHere")}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => inputRef.current?.click()}
            >
              {t("userV2:returnProcess.uploadPhotos.uploadPhotos")}
              <input
                data-testid="upload-input"
                ref={inputRef}
                type="file"
                hidden
                onChange={onUploadImages}
                multiple
                accept="image/*"
              />
            </Button>
            <TextButton
              onClick={() => setFullPagePhotoModalSrc(examplePhoto)}
              align="center"
              size="md"
            >
              {t("userV2:returnProcess.uploadPhotos.showExamplePhoto")}
            </TextButton>
          </UploadPhotoDragAndDropArea>
          {images?.map((file) => (
            <UploadedPhotoPreview
              key={file.id}
              file={file}
              onDelete={() => onDeleteImage(file.id)}
              isLoading={loadingImagesIds.includes(file.id)}
              onUploadedPhotoPreviewClick={onUploadedPhotoPreviewClick}
            />
          ))}
        </Flex>
        <Button
          onClick={onNext}
          disabled={images.length === 0 || loadingImagesIds.length > 0}
          fullWidth
        >
          {t("commonV2:next")}
        </Button>
      </StyledContainer>
      <FullPagePhotoModal
        isOpen={!!fullPagePhotoModalSrc}
        onClose={() => setFullPagePhotoModalSrc(undefined)}
        src={fullPagePhotoModalSrc ?? ""}
      />
    </WizardPageViewLayout>
  );
}

const StyledContainer = styled(Flex)`
  max-width: 490px;
  width: 100%;
`;
