/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { RedemptionReason } from "./RedemptionReason";
import {
  RedemptionReasonFromJSON,
  RedemptionReasonFromJSONTyped,
  RedemptionReasonToJSON,
} from "./RedemptionReason";

/**
 *
 * @export
 * @interface RedemptionProcessCreate
 */
export interface RedemptionProcessCreate {
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessCreate
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessCreate
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {RedemptionReason}
   * @memberof RedemptionProcessCreate
   */
  redemptionReason: RedemptionReason;
}

/**
 * Check if a given object implements the RedemptionProcessCreate interface.
 */
export function instanceOfRedemptionProcessCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitContractId" in value;
  isInstance = isInstance && "plannedRedemptionDate" in value;
  isInstance = isInstance && "redemptionReason" in value;

  return isInstance;
}

export function RedemptionProcessCreateFromJSON(
  json: any,
): RedemptionProcessCreate {
  return RedemptionProcessCreateFromJSONTyped(json, false);
}

export function RedemptionProcessCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    redemptionReason: RedemptionReasonFromJSON(json["redemption_reason"]),
  };
}

export function RedemptionProcessCreateToJSON(
  value?: RedemptionProcessCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_contract_id: value.bikeBenefitContractId,
    planned_redemption_date: value.plannedRedemptionDate
      .toISOString()
      .substring(0, 10),
    redemption_reason: RedemptionReasonToJSON(value.redemptionReason),
  };
}
