/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ReturnProcessStatus = {
  Draft: "draft",
  WaitingForPayment: "waiting_for_payment",
  Processing: "processing",
  Completed: "completed",
  Cancelled: "cancelled",
} as const;
export type ReturnProcessStatus =
  (typeof ReturnProcessStatus)[keyof typeof ReturnProcessStatus];

export function ReturnProcessStatusFromJSON(json: any): ReturnProcessStatus {
  return ReturnProcessStatusFromJSONTyped(json, false);
}

export function ReturnProcessStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessStatus {
  return json as ReturnProcessStatus;
}

export function ReturnProcessStatusToJSON(
  value?: ReturnProcessStatus | null,
): any {
  return value as any;
}
