import { useDateFormat } from "../../../../../../packages/i18n/src/hooks";

type InvoiceDueDateProps = {
  dueDate: Date;
};

export function InvoiceDueDate({ dueDate }: InvoiceDueDateProps) {
  const formatDate = useDateFormat();
  return <article>{formatDate(dueDate)}</article>;
}
