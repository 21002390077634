import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { AnimatedSuccessIcon } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";

export function IdentityVerifiedPage() {
  const { t } = useTranslation();
  const iconAnimationTimeInSeconds = 2;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("verifySuccess")) {
      setSearchParams();
    }
  }, []);

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.identityVerified.title")}
      subtitle={t("userV2:onboarding.identityVerified.subtitle")}
      additionalTitle={
        <AnimatedSuccessIcon
          animationTimeInSeconds={iconAnimationTimeInSeconds}
        />
      }
    />
  );
}
