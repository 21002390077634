import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { BenefitTermsModal } from "@vapaus/common-v2";
import {
  Button,
  Flex,
  HorizontalLine,
  Paper,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { BenefitDetailsPreview } from "../../../components/BenefitDetailsPreview";
import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function BenefitPreviewPage() {
  const { t } = useTranslation();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const {
    selectedBenefit,
    selectedBenefitTerms,
    selectedEmployer,
    isLoading,
    goToNext,
  } = useOnboardingMachine();

  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.benefitPreview.title")}
      subtitle={t("userV2:onboarding.benefitPreview.subtitle")}
      additionalTitle={selectedEmployer?.name}
      subtitleWidth="md"
    >
      <StyledContainer>
        <Paper shadow="md">
          <BenefitDetailsPreview benefit={selectedBenefit} />
          <HorizontalLine />
          <Flex direction="column" gap="md" align="center">
            <Typography align="center">
              {t("userV2:onboarding.benefitPreview.guidelineRecommendation")}
            </Typography>
            {selectedBenefitTerms?.content ? (
              <TextButton size="md" onClick={handleOpenTermsModal}>
                {t("userV2:onboarding.benefitPreview.viewFullGuideline")}
              </TextButton>
            ) : (
              <TextButton
                size="md"
                to={selectedBenefitTerms?.pdfObject?.publicUrl ?? ""}
                target="_blank"
              >
                {t("userV2:onboarding.benefitPreview.viewFullGuideline")}
              </TextButton>
            )}
            <Button onClick={goToNext} isLoading={isLoading}>
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </Paper>
      </StyledContainer>
      {selectedBenefitTerms?.content ? (
        <BenefitTermsModal
          isOpen={isTermsModalOpen}
          onClose={handleCloseTermsModal}
          title={selectedEmployer?.name ?? ""}
          content={selectedBenefitTerms.content}
        />
      ) : null}
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled.div`
  width: 35rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
