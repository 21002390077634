import { createSvgIcon } from "./SvgIcon";

export const CarriageReturnIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.99999 19C7.71665 19 7.47915 18.9042 7.28749 18.7125C7.09582 18.5208 6.99999 18.2833 6.99999 18C6.99999 17.7167 7.09582 17.4792 7.28749 17.2875C7.47915 17.0958 7.71665 17 7.99999 17H14.1C15.15 17 16.0625 16.6667 16.8375 16C17.6125 15.3333 18 14.5 18 13.5C18 12.5 17.6125 11.6667 16.8375 11C16.0625 10.3333 15.15 9.99999 14.1 9.99999H7.79999L9.69999 11.9C9.88332 12.0833 9.97499 12.3167 9.97499 12.6C9.97499 12.8833 9.88332 13.1167 9.69999 13.3C9.51665 13.4833 9.28332 13.575 8.99999 13.575C8.71665 13.575 8.48332 13.4833 8.29999 13.3L4.69999 9.69999C4.59999 9.59999 4.52915 9.49165 4.48749 9.37499C4.44582 9.25832 4.42499 9.13332 4.42499 8.99999C4.42499 8.86665 4.44582 8.74165 4.48749 8.62499C4.52915 8.50832 4.59999 8.39999 4.69999 8.29999L8.29999 4.69999C8.48332 4.51665 8.71665 4.42499 8.99999 4.42499C9.28332 4.42499 9.51665 4.51665 9.69999 4.69999C9.88332 4.88332 9.97499 5.11665 9.97499 5.39999C9.97499 5.68332 9.88332 5.91665 9.69999 6.09999L7.79999 7.99999H14.1C15.7167 7.99999 17.1042 8.52499 18.2625 9.57499C19.4208 10.625 20 11.9333 20 13.5C20 15.0667 19.4208 16.375 18.2625 17.425C17.1042 18.475 15.7167 19 14.1 19H7.99999Z" />
  </svg>,
  "CarriageReturn",
);
