import logo from "./vapaus-logo.svg";
import logoSmall from "./vapaus-logomark.svg";

type LogoSizes = "sm" | "lg";

export type LogoProps = {
  size?: LogoSizes;
  className?: string;
};

export function Logo({ size = "lg", className }: LogoProps) {
  return (
    <img
      src={size === "lg" ? logo : logoSmall}
      alt="Vapaus logo"
      className={className}
    />
  );
}
