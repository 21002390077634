import { createSvgIcon } from "./SvgIcon";

export const TwitterIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.7508 2.9613H20.8175L14.1175 10.6196L22 21.0388H15.8283L10.995 14.7188L5.46333 21.0388H2.395L9.56167 12.8471L2 2.96214H8.32833L12.6975 8.7388L17.7508 2.9613ZM16.675 19.2038H18.3742L7.405 4.70047H5.58167L16.675 19.2038Z" />
  </svg>,
  "Twitter",
);
