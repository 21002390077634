import { snakeCase } from "lodash-es";

import { BikeBenefitContractRead } from "@vapaus/api-codegen";
import { useTableParams } from "@vapaus/ui-v2";

import { useSearchMaintenanceEvents } from "../../../../../api/maintenance";

export function useMaintenanceEventsTable(contract: BikeBenefitContractRead) {
  const { sorting, pagination, setSorting, setPagination } = useTableParams({
    sortId: "workDate",
    sortDesc: true,
  });

  const sortMap: Record<string, string> = {
    shopName: "shop.name",
  };

  const { data, isLoading } = useSearchMaintenanceEvents(
    {
      limit: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
      vapausCode: contract.vapausCode,
      ...(sorting[0]?.id !== "null"
        ? {
            sortField: sortMap[sorting[0]?.id] ?? snakeCase(sorting[0]?.id),
            sortDirection: sorting[0]?.desc ? "desc" : "asc",
          }
        : undefined),
    },
    { keepPreviousData: true },
  );

  return {
    paginationOptions: {
      totalItems: data?.total ?? 0,
      pagination,
      setPagination,
    },
    sortingOptions: {
      sorting,
      setSorting,
    },
    data,
    isLoading,
  };
}
