import { useTranslation } from "react-i18next";

export function useDisplayName() {
  const { i18n } = useTranslation();
  const regionNames = new Intl.DisplayNames([i18n.language], {
    type: "region",
  });
  return (value?: string) =>
    value !== undefined ? regionNames.of(value) : "-";
}
