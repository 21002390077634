/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Transport
 */
export interface Transport {
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  expectedDeliveryDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  deliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  trackingUrl: string;
  /**
   *
   * @type {number}
   * @memberof Transport
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof Transport
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeBenefitOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  bikeId?: string;
  /**
   *
   * @type {string}
   * @memberof Transport
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Transport
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Transport interface.
 */
export function instanceOfTransport(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "trackingUrl" in value;
  isInstance = isInstance && "priceGross" in value;
  isInstance = isInstance && "vatRate" in value;

  return isInstance;
}

export function TransportFromJSON(json: any): Transport {
  return TransportFromJSONTyped(json, false);
}

export function TransportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Transport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expectedDeliveryDate: !exists(json, "expected_delivery_date")
      ? undefined
      : new Date(json["expected_delivery_date"]),
    deliveryDate: !exists(json, "delivery_date")
      ? undefined
      : new Date(json["delivery_date"]),
    trackingUrl: json["tracking_url"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
    bikeBenefitOrderId: !exists(json, "bike_benefit_order_id")
      ? undefined
      : json["bike_benefit_order_id"],
    bikeBenefitContractId: !exists(json, "bike_benefit_contract_id")
      ? undefined
      : json["bike_benefit_contract_id"],
    bikeId: !exists(json, "bike_id") ? undefined : json["bike_id"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function TransportToJSON(value?: Transport | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    expected_delivery_date:
      value.expectedDeliveryDate === undefined
        ? undefined
        : value.expectedDeliveryDate.toISOString().substring(0, 10),
    delivery_date:
      value.deliveryDate === undefined
        ? undefined
        : value.deliveryDate.toISOString().substring(0, 10),
    tracking_url: value.trackingUrl,
    price_gross: value.priceGross,
    vat_rate: value.vatRate,
    bike_benefit_order_id: value.bikeBenefitOrderId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
    bike_id: value.bikeId,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
