import { Trans, useTranslation } from "react-i18next";

import { BikeBenefitContractRead, Currency } from "@vapaus/api-codegen";
import { Flex, HorizontalLine, Typography } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../../packages/i18n/src/hooks";
import { useGetBenefitDefinition } from "../../../../../api/benefitDefinitions";
import { useGetContractRedemptionCalculation } from "../../../../../api/redemptionProcess";
import { getIsContractEndingSoon } from "../../../../../utils/getIsContractEndingSoon";
import {
  RedeemReturnCTAButtons,
  useRedeemReturnCTA,
} from "../../RedeemReturnSharedComponents/RedeemReturnCTAButtons";

type CancelContractInfoProps = {
  contract: BikeBenefitContractRead;
};

export function CancelContractInfo({ contract }: CancelContractInfoProps) {
  const { t } = useTranslation();
  const isEndingSoon = getIsContractEndingSoon(contract.endDate);
  const { data: calculation } = useGetContractRedemptionCalculation(
    contract.id,
    isEndingSoon,
    {
      enabled: !!contract.id && !isEndingSoon,
    },
  );
  const { data: benefitDefinition } = useGetBenefitDefinition(
    contract.benefitDefinitionId,
  );
  const { hasActiveCancellationCoverage } = useRedeemReturnCTA(contract);

  const formatCurrency = useCurrencyFormat(contract.currency || Currency.Eur);
  const formatDate = useDateFormat();

  if (contract.state !== "active") return null;

  if (isEndingSoon) {
    return (
      <>
        <HorizontalLine marginBottom="none" marginTop="none" />
        <Flex direction="column" gap="xs">
          <Typography weight="bold">
            {t("userV2:myBike.contractPanel.status.endOfLease.title")}
          </Typography>
          <Typography marginBottom="sm">
            <Trans
              t={t}
              i18nKey="userV2:myBike.contractPanel.status.endOfLease.description"
              values={{
                date: formatDate(contract.endDate),
                residualValue: formatCurrency(contract.residualValue),
              }}
              components={{ 1: <b /> }}
            />
          </Typography>
          <Flex>
            <RedeemReturnCTAButtons contract={contract} />
          </Flex>
        </Flex>
      </>
    );
  }

  return (
    <>
      <HorizontalLine marginBottom="none" marginTop="none" />
      <Flex direction="column" gap="xs">
        <Typography weight="bold">
          {t("userV2:myBike.contractPanel.status.returnOrRedeemBike.title")}
        </Typography>
        <Typography marginBottom="sm">
          {hasActiveCancellationCoverage ? (
            benefitDefinition?.plan === "legacy" ? (
              <Trans
                t={t}
                i18nKey="userV2:myBike.contractPanel.status.returnOrRedeemBike.descriptionWithCancellationCoverageLegacy"
                values={{
                  redemptionPrice: formatCurrency(calculation?.redemptionPrice),
                }}
                components={{ 1: <b /> }}
              />
            ) : (
              <>
                <Trans
                  t={t}
                  i18nKey="userV2:myBike.contractPanel.status.returnOrRedeemBike.descriptionWithCancellationCoverage"
                  values={{
                    redemptionPrice: formatCurrency(
                      calculation?.redemptionPrice,
                    ),
                  }}
                  components={{ 1: <b /> }}
                />{" "}
                {benefitDefinition?.returnFee ? (
                  <Trans
                    t={t}
                    i18nKey="userV2:myBike.contractPanel.status.returnOrRedeemBike.descriptionWithCancellationCoveragePaidRedemption"
                    values={{
                      redemptionFee: formatCurrency(
                        benefitDefinition?.returnFee,
                      ),
                    }}
                    components={{ 1: <b /> }}
                  />
                ) : (
                  t(
                    "userV2:myBike.contractPanel.status.returnOrRedeemBike.descriptionWithCancellationCoverageFreeRedemption",
                  )
                )}
              </>
            )
          ) : (
            <Trans
              t={t}
              i18nKey="userV2:myBike.contractPanel.status.returnOrRedeemBike.descriptionWithoutCancellationCoverage"
              values={{
                redemptionPrice: formatCurrency(calculation?.redemptionPrice),
              }}
              components={{ 1: <b /> }}
            />
          )}
        </Typography>
        <Flex>
          <RedeemReturnCTAButtons contract={contract} />
        </Flex>
      </Flex>
    </>
  );
}
