import { BikeBenefitContractState, OrderDirection } from "@vapaus/api-codegen";

import { useSearchContracts } from "../../api/benefit";
import {
  useGetBenefitDefinition,
  useGetBenefitDefinitionsActivations,
} from "../../api/benefitDefinitions";

export function useUserBenefitDefinition() {
  const { data: contracts, isLoading: isLoadingContracts } = useSearchContracts(
    {
      sortField: "created_at",
      sortDirection: OrderDirection.Desc,
    },
  );
  const activeContracts = contracts?.items.filter(
    (contract) => contract.state === BikeBenefitContractState.Active,
  );

  const { data: activeActivations, isLoading: isLoadingActivations } =
    useGetBenefitDefinitionsActivations({
      activeOnly: true,
      sortField: "created_at",
      sortDirection: OrderDirection.Desc,
    });

  const currentBenefitDefinitionId =
    activeActivations?.items?.[0]?.benefitDefinitionId ??
    activeContracts?.[0]?.benefitDefinitionId ??
    "";

  const { data: benefitDefinition, fetchStatus: benefitDefinitionFetchStatus } =
    useGetBenefitDefinition(currentBenefitDefinitionId);

  return {
    benefitDefinition,
    isLoading:
      isLoadingContracts ||
      isLoadingActivations ||
      benefitDefinitionFetchStatus !== "idle",
  };
}
