import { CurrentUserRead } from "@vapaus/api-codegen";

import { AdditionalData, Context, CountryData, ProfileData } from "./types";

export const machineInitialContext: Context = {
  currentStep: 0,
  totalSteps: 10,
  employers: [],
  selectedEmployerBenefits: [],
  candidates: [],
  selectedBenefit: undefined,
  selectedBenefitTerms: undefined,
  selectedEmployer: undefined,
  addedEmail: undefined,
  businessId: "",
  user: {} as CurrentUserRead,
  countryData: {} as CountryData,
  profileData: {} as ProfileData,
  additionalData: {} as AdditionalData,
  benefitWithPendingRequestIds: [],
};
