import { createSvgIcon } from "./SvgIcon";

export const VerifiedUserIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M10.95 12.6999L9.55 11.2999C9.35 11.0999 9.11667 10.9999 8.85 10.9999C8.58333 10.9999 8.35 11.0999 8.15 11.2999C7.95 11.4999 7.85 11.7374 7.85 12.0124C7.85 12.2874 7.95 12.5249 8.15 12.7249L10.25 14.8499C10.45 15.0499 10.6833 15.1499 10.95 15.1499C11.2167 15.1499 11.45 15.0499 11.65 14.8499L15.9 10.5999C16.1 10.3999 16.2 10.1624 16.2 9.8874C16.2 9.6124 16.1 9.3749 15.9 9.1749C15.7 8.9749 15.4625 8.8749 15.1875 8.8749C14.9125 8.8749 14.675 8.9749 14.475 9.1749L10.95 12.6999ZM12 21.8999C11.8833 21.8999 11.775 21.8916 11.675 21.8749C11.575 21.8582 11.475 21.8332 11.375 21.7999C9.125 21.0499 7.33333 19.6624 6 17.6374C4.66667 15.6124 4 13.4332 4 11.0999V6.3749C4 5.95824 4.12083 5.58324 4.3625 5.2499C4.60417 4.91657 4.91667 4.6749 5.3 4.5249L11.3 2.2749C11.5333 2.19157 11.7667 2.1499 12 2.1499C12.2333 2.1499 12.4667 2.19157 12.7 2.2749L18.7 4.5249C19.0833 4.6749 19.3958 4.91657 19.6375 5.2499C19.8792 5.58324 20 5.95824 20 6.3749V11.0999C20 13.4332 19.3333 15.6124 18 17.6374C16.6667 19.6624 14.875 21.0499 12.625 21.7999C12.525 21.8332 12.425 21.8582 12.325 21.8749C12.225 21.8916 12.1167 21.8999 12 21.8999ZM12 19.8999C13.7333 19.3499 15.1667 18.2499 16.3 16.5999C17.4333 14.9499 18 13.1166 18 11.0999V6.3749L12 4.1249L6 6.3749V11.0999C6 13.1166 6.56667 14.9499 7.7 16.5999C8.83333 18.2499 10.2667 19.3499 12 19.8999Z" />
  </svg>,
  "VerifiedUser",
);
