import { ComponentType } from "react";

import { useOutletContext } from "react-router-dom";

import { BikeBenefitContractRead, Currency } from "@vapaus/api-codegen";
import {
  CircularIcon,
  Flex,
  SvgIconProps,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../../../../packages/i18n/src/hooks/";

type DescriptionItem = {
  descriptionItem: string;
  itemPrice?: number;
};

type SummaryItemRowProps = {
  icon: ComponentType<SvgIconProps>;
  title: string;
  description: string | DescriptionItem[];
  price: number | string;
};

export function SummaryItemRow({
  icon,
  title,
  description,
  price,
}: SummaryItemRowProps) {
  const { contract } = useOutletContext<{
    contract?: BikeBenefitContractRead;
  }>();
  const formatCurrency = useCurrencyFormat(contract?.currency ?? Currency.Eur);
  const { isXs } = useBreakpoints();

  return (
    <Flex align="flex-start">
      {!isXs ? <CircularIcon icon={icon} /> : null}
      <Flex direction="column" gap="xxs" fullWidth>
        <Flex justify="space-between">
          <Typography weight="bold">{title}</Typography>
          <Typography weight="bold">
            {typeof price === "string" ? price : formatCurrency(price)}
          </Typography>
        </Flex>
        {typeof description === "string" ? (
          <Typography>{description}</Typography>
        ) : (
          description.map((item, index) => (
            <Flex
              justify="space-between"
              key={item.descriptionItem + item.itemPrice + index}
            >
              <Typography>{item.descriptionItem}</Typography>
              {item?.itemPrice != null ? (
                <Typography>{formatCurrency(item.itemPrice)}</Typography>
              ) : null}
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
}
