/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { MaintenanceEventRead } from "./MaintenanceEventRead";
import {
  MaintenanceEventReadFromJSON,
  MaintenanceEventReadFromJSONTyped,
  MaintenanceEventReadToJSON,
} from "./MaintenanceEventRead";

/**
 *
 * @export
 * @interface PaginatedMaintenanceEventOut
 */
export interface PaginatedMaintenanceEventOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedMaintenanceEventOut
   */
  total: number;
  /**
   *
   * @type {Array<MaintenanceEventRead>}
   * @memberof PaginatedMaintenanceEventOut
   */
  items: Array<MaintenanceEventRead>;
}

/**
 * Check if a given object implements the PaginatedMaintenanceEventOut interface.
 */
export function instanceOfPaginatedMaintenanceEventOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedMaintenanceEventOutFromJSON(
  json: any,
): PaginatedMaintenanceEventOut {
  return PaginatedMaintenanceEventOutFromJSONTyped(json, false);
}

export function PaginatedMaintenanceEventOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedMaintenanceEventOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(MaintenanceEventReadFromJSON),
  };
}

export function PaginatedMaintenanceEventOutToJSON(
  value?: PaginatedMaintenanceEventOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(MaintenanceEventReadToJSON),
  };
}
