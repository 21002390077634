/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyBenefitAcceptBikeBenefitOrder
 */
export interface BodyBenefitAcceptBikeBenefitOrder {
  /**
   *
   * @type {string}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  acceptedVersion: string;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  optionalCancellationCoverage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  isDownPaymentInsured?: boolean;
}

/**
 * Check if a given object implements the BodyBenefitAcceptBikeBenefitOrder interface.
 */
export function instanceOfBodyBenefitAcceptBikeBenefitOrder(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "acceptedVersion" in value;
  isInstance = isInstance && "optionalCancellationCoverage" in value;

  return isInstance;
}

export function BodyBenefitAcceptBikeBenefitOrderFromJSON(
  json: any,
): BodyBenefitAcceptBikeBenefitOrder {
  return BodyBenefitAcceptBikeBenefitOrderFromJSONTyped(json, false);
}

export function BodyBenefitAcceptBikeBenefitOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyBenefitAcceptBikeBenefitOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    acceptedVersion: json["accepted_version"],
    optionalCancellationCoverage: json["optional_cancellation_coverage"],
    isDownPaymentInsured: !exists(json, "is_down_payment_insured")
      ? undefined
      : json["is_down_payment_insured"],
  };
}

export function BodyBenefitAcceptBikeBenefitOrderToJSON(
  value?: BodyBenefitAcceptBikeBenefitOrder | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accepted_version: value.acceptedVersion,
    optional_cancellation_coverage: value.optionalCancellationCoverage,
    is_down_payment_insured: value.isDownPaymentInsured,
  };
}
