import { useTranslation } from "react-i18next";

import { Flex, TextButton, Typography } from "@vapaus/ui-v2";

import vismaPayLogo from "../../../../assets/vismaPay.png";

export function InvoicePaymentView() {
  const { t } = useTranslation();
  return (
    <Flex direction="column" align="center" gap="md">
      <img src={vismaPayLogo} alt="Visma Pay Logo" />
      <Typography variant="bodyLg" align="center">
        {t("userV2:billingTable.paymentInstructions")}
      </Typography>
      <TextButton
        to={t("userV2:billingTable.termsOfServiceLink")}
        target="_blank"
      >
        {t("userV2:billingTable.termsOfService")}
      </TextButton>
    </Flex>
  );
}
