import { useState } from "react";

import { useTranslation } from "react-i18next";

import { CurrentUserRead } from "@vapaus/api-codegen";
import { LineItem } from "@vapaus/ui-v2";

import { ChangeNameModal } from "../ChangeNameModal";

interface LineNameProps {
  user?: CurrentUserRead;
}

export function LineName({ user }: LineNameProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <LineItem
        name={t("commonV2:name")}
        content={user?.fullName}
        action={{
          label: t("commonV2:edit"),
          onClick: () => setIsModalOpen(true),
        }}
      />
      <ChangeNameModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
