/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { ShopType } from "./ShopType";
import {
  ShopTypeFromJSON,
  ShopTypeFromJSONTyped,
  ShopTypeToJSON,
} from "./ShopType";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface ShopRead
 */
export interface ShopRead {
  /**
   *
   * @type {boolean}
   * @memberof ShopRead
   */
  isActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  organisationId: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  logoObjectId?: string;
  /**
   *
   * @type {Array<ShopType>}
   * @memberof ShopRead
   */
  types?: Array<ShopType>;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  website?: string;
  /**
   * Payment terms in days
   * @type {number}
   * @memberof ShopRead
   */
  paymentTerms?: number;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  streetAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  country?: string;
  /**
   * Two letters ISO 3166 country code
   * @type {Array<SupportedOrganisationCountries>}
   * @memberof ShopRead
   */
  operationCountries?: Array<SupportedOrganisationCountries>;
  /**
   *
   * @type {boolean}
   * @memberof ShopRead
   */
  isFeatured?: boolean;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ShopRead
   */
  logoUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ShopRead
   */
  currentDiscountBike: number;
  /**
   *
   * @type {number}
   * @memberof ShopRead
   */
  currentDiscountAccessory: number;
  /**
   *
   * @type {Date}
   * @memberof ShopRead
   */
  currentDiscountValidFrom?: Date;
}

/**
 * Check if a given object implements the ShopRead interface.
 */
export function instanceOfShopRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "organisationId" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "currentDiscountBike" in value;
  isInstance = isInstance && "currentDiscountAccessory" in value;

  return isInstance;
}

export function ShopReadFromJSON(json: any): ShopRead {
  return ShopReadFromJSONTyped(json, false);
}

export function ShopReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShopRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isActive: !exists(json, "is_active") ? undefined : json["is_active"],
    name: json["name"],
    description: !exists(json, "description") ? undefined : json["description"],
    organisationId: json["organisation_id"],
    logoObjectId: !exists(json, "logo_object_id")
      ? undefined
      : json["logo_object_id"],
    types: !exists(json, "types")
      ? undefined
      : (json["types"] as Array<any>).map(ShopTypeFromJSON),
    email: !exists(json, "email") ? undefined : json["email"],
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    website: !exists(json, "website") ? undefined : json["website"],
    paymentTerms: !exists(json, "payment_terms")
      ? undefined
      : json["payment_terms"],
    streetAddress: !exists(json, "street_address")
      ? undefined
      : json["street_address"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    country: !exists(json, "country") ? undefined : json["country"],
    operationCountries: !exists(json, "operation_countries")
      ? undefined
      : (json["operation_countries"] as Array<any>).map(
          SupportedOrganisationCountriesFromJSON,
        ),
    isFeatured: !exists(json, "is_featured") ? undefined : json["is_featured"],
    id: json["id"],
    logoUrl: !exists(json, "logo_url") ? undefined : json["logo_url"],
    currentDiscountBike: json["current_discount_bike"],
    currentDiscountAccessory: json["current_discount_accessory"],
    currentDiscountValidFrom: !exists(json, "current_discount_valid_from")
      ? undefined
      : new Date(json["current_discount_valid_from"]),
  };
}

export function ShopReadToJSON(value?: ShopRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_active: value.isActive,
    name: value.name,
    description: value.description,
    organisation_id: value.organisationId,
    logo_object_id: value.logoObjectId,
    types:
      value.types === undefined
        ? undefined
        : (value.types as Array<any>).map(ShopTypeToJSON),
    email: value.email,
    phone_number: value.phoneNumber,
    website: value.website,
    payment_terms: value.paymentTerms,
    street_address: value.streetAddress,
    postal_code: value.postalCode,
    city: value.city,
    country: value.country,
    operation_countries:
      value.operationCountries === undefined
        ? undefined
        : (value.operationCountries as Array<any>).map(
            SupportedOrganisationCountriesToJSON,
          ),
    is_featured: value.isFeatured,
    id: value.id,
    logo_url: value.logoUrl,
    current_discount_bike: value.currentDiscountBike,
    current_discount_accessory: value.currentDiscountAccessory,
    current_discount_valid_from:
      value.currentDiscountValidFrom === undefined
        ? undefined
        : value.currentDiscountValidFrom.toISOString().substring(0, 10),
  };
}
