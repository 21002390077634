import { ReactNode, createContext, useContext, useMemo } from "react";

import { useMachine } from "@xstate/react";
import { useOutletContext, useSearchParams } from "react-router-dom";

import {
  BikeBenefitContractRead,
  RedemptionProcessCalculation,
  RedemptionReason,
} from "@vapaus/api-codegen";

import { useGetContractRedemptionCalculation } from "../../../../../api/redemptionProcess";
import { EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY } from "../../../../../constants/emailVerification";
import { VISMA_PAY_RETURN_KEY } from "../../../../../constants/vismaPay";
import { getIsContractEndingSoon } from "../../../../../utils/getIsContractEndingSoon";
import { redemptionProcessMachine } from "../machines/RedemptionProcessMachine";
import { Typegen0 } from "../machines/RedemptionProcessMachine.typegen";
import { Context } from "../machines/types";

export type RedemptionProcessContextProps = {
  onNext: () => void;
  onPrevious: () => void;
  onCompleted: () => void;
  stateMatches: (state: Typegen0["matchesStates"]) => boolean;
  step: number;
  calculation?: RedemptionProcessCalculation;
  redemptionReason?: RedemptionReason;
  setRedemptionReason: (data: RedemptionReason) => void;
  isContractEndingSoon: boolean;
};

export const RedemptionProcessContext =
  createContext<RedemptionProcessContextProps>({
    onCompleted: () => {},
    onNext: () => {},
    onPrevious: () => {},
    stateMatches: (state: Typegen0["matchesStates"]) => false,
    step: 0,
    setRedemptionReason: () => {},
    isContractEndingSoon: false,
  });

export const useRedemptionProcessContext = () =>
  useContext(RedemptionProcessContext);

type RedemptionProcessProviderProps = {
  children: ReactNode;
  redemptionReason?: RedemptionReason;
};

export function RedemptionProcessProvider({
  children,
}: RedemptionProcessProviderProps) {
  const [searchParams] = useSearchParams();

  function getLandingState(): Context["startingState"] {
    if (searchParams.has(VISMA_PAY_RETURN_KEY)) {
      return "paymentProcessing";
    } else if (searchParams.has(EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY)) {
      return "emailAddress";
    }
    return "welcome";
  }

  const { contract } = useOutletContext<{
    contract: BikeBenefitContractRead;
  }>();

  const isContractEndingSoon = getIsContractEndingSoon(contract.endDate);

  const [state, send] = useMachine(redemptionProcessMachine, {
    context: {
      step: 0,
      startingState: getLandingState(),
      redemptionReason: undefined,
      isContractEndingSoon,
    },
  });

  const stateMatches = state.matches;
  const step = state.context.step;
  const redemptionReason = state.context.redemptionReason;

  const { data: calculation } = useGetContractRedemptionCalculation(
    contract.id,
    isContractEndingSoon,
  );

  const contextValue = useMemo(
    () => ({
      calculation,
      step,
      redemptionReason,
      isContractEndingSoon,
      stateMatches,
      onNext: () => send("NEXT"),
      onPrevious: () => send("BACK"),
      onCompleted: () => send("COMPLETED"),
      setRedemptionReason: (redemptionReason: RedemptionReason) =>
        send("SET_REDEMPTION_REASON", { redemptionReason }),
    }),
    [
      calculation,
      step,
      redemptionReason,
      isContractEndingSoon,
      stateMatches,
      send,
    ],
  );

  return (
    <RedemptionProcessContext.Provider value={contextValue}>
      {children}
    </RedemptionProcessContext.Provider>
  );
}
