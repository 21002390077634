import { useState } from "react";

import { useTranslation } from "react-i18next";

import { LineItem, Typography } from "@vapaus/ui-v2";

import { ChangePasswordModal } from "../ChangePasswordModal";

export function LinePassword() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <LineItem
        name={t("commonV2:password")}
        content={
          <Typography>{t("commonV2:passwordChange.description")}</Typography>
        }
        action={{
          label: t("commonV2:change"),
          onClick: () => setIsModalOpen(true),
        }}
      />
      <ChangePasswordModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
