import { ReactNode } from "react";

import styled from "styled-components";

import { Flex, SpacingVariants, TextButton, Typography } from "@vapaus/ui-v2";

type TableEmptyStateProps = {
  icon: ReactNode;
  title: string;
  subtitle: string;
  link?: {
    text: string;
    to: string;
  };
  padding?: SpacingVariants;
};

export function TableEmptyState({
  icon,
  title,
  subtitle,
  link,
  padding,
}: TableEmptyStateProps) {
  return (
    <StyledEmptyStateContainer
      padding={padding}
      direction="column"
      align="center"
      gap="md"
    >
      {icon}
      <Flex direction="column" align="center" gap="md">
        <Typography variant="h2" align="center">
          {title}
        </Typography>
        <StyledSubtitleWrapper>
          <Typography align="center">{subtitle}</Typography>
        </StyledSubtitleWrapper>
        {link ? (
          <TextButton to={link.to} target="_blank">
            {link.text}
          </TextButton>
        ) : null}
      </Flex>
    </StyledEmptyStateContainer>
  );
}

const StyledEmptyStateContainer = styled(Flex)<{
  padding?: SpacingVariants;
}>`
  padding: ${({ theme, padding = "xl" }) => theme.spacing[padding]};
`;

const StyledSubtitleWrapper = styled.div`
  max-width: 600px;
`;
