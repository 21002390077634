import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { BenefitTermsModal } from "@vapaus/common-v2";
import { Button, Card, Flex, HorizontalLine, Typography } from "@vapaus/ui-v2";

import {
  useGetBenefitDefinition,
  useGetBenefitDefinitionsActivations,
} from "../../../api/benefitDefinitions";
import { useGetBenefitDefinitionTerms } from "../../../api/benefitDefitinionTerms";
import { BenefitDetailsPreview } from "../../../components/BenefitDetailsPreview";

export function BenefitGuidelineTabContent() {
  const { t } = useTranslation();
  const { activationId } = useParams();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const { data: activations } = useGetBenefitDefinitionsActivations(
    {
      ids: [activationId ?? ""],
    },
    {
      enabled: !!activationId,
    },
  );

  const activation = activations?.items[0];

  const benefitDefinitionId = activation?.benefitDefinitionId;

  const { data: benefitDefinition } =
    useGetBenefitDefinition(benefitDefinitionId);

  const { data: benefitDefinitionTerms } = useGetBenefitDefinitionTerms(
    benefitDefinition?.currentBenefitDefinitionTerm?.id ?? "",
  );

  const handleOnClick = () => {
    if (benefitDefinitionTerms?.content) {
      return handleOpenTermsModal();
    }
    window.open(benefitDefinitionTerms?.pdfObject?.publicUrl, "_blank");
  };

  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  return (
    <StyledRoot>
      <Card title={t("userV2:benefit.tabs.benefitGuideline.title")}>
        <BenefitDetailsPreview benefit={benefitDefinition} />
        <HorizontalLine />
        <Flex direction="column" gap="md" align="center">
          <Typography align="center">
            {t("userV2:benefit.tabs.benefitGuideline.guidelineRecommendation")}
          </Typography>
          <Button onClick={handleOnClick} disabled={!activation?.isActive}>
            {t("userV2:benefit.tabs.benefitGuideline.viewFullGuideline")}
          </Button>
        </Flex>
        {benefitDefinitionTerms?.content ? (
          <BenefitTermsModal
            isOpen={isTermsModalOpen}
            onClose={handleCloseTermsModal}
            title={benefitDefinition?.organisation?.name ?? ""}
            content={benefitDefinitionTerms.content}
          />
        ) : null}
      </Card>
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;
