import React from "react";

import styled, { keyframes, useTheme } from "styled-components";

import { Theme } from "../styles";
import { CircularIcon } from "./CircularIcon";

type AnimatedSuccessIconProps = {
  animationTimeInSeconds?: number;
};

export function AnimatedSuccessIcon({
  animationTimeInSeconds = 1,
}: AnimatedSuccessIconProps) {
  const { palette } = useTheme() as Theme;

  return (
    <CircularIcon colour={palette.success}>
      <StyledCheckmarkSvg data-testid={"AnimatedSuccessIcon"}>
        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
          <StyledPath
            $animationTimeInSeconds={animationTimeInSeconds}
            fill="none"
            d="M616.306,283.025L634.087,300.805L673.361,261.53"
          />
        </g>
      </StyledCheckmarkSvg>
    </CircularIcon>
  );
}

const StyledCheckmarkSvg = styled.svg`
  stroke: ${({ theme }) => theme.palette.success};
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px;
`;

const drawCheck = keyframes`
  0% {
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
    opacity: 0;
  }

  50% {
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
    opacity: 1;
  }

  100% {
    stroke-dasharray: 130, 80;
    stroke-dashoffset: 48;
  }
`;

const StyledPath = styled.path<{ $animationTimeInSeconds: number }>`
  animation: ${drawCheck} ease-out;
  animation-duration: ${({ $animationTimeInSeconds }) =>
    `${$animationTimeInSeconds}s`};
`;
