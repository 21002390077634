import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { UserRead } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useCurrentUser, userApi } from "@vapaus/shared-api";
import { useToaster } from "@vapaus/ui-v2";

interface UseChangeAddressFormArgs {
  onSuccess: () => void;
}

export function useChangeAddressForm({ onSuccess }: UseChangeAddressFormArgs) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const { data: user } = useCurrentUser();

  const addressSchema = useMemo(
    () =>
      zod.object({
        address: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        city: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        postCode: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        country: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      }),
    [t],
  );

  type AddressSchema = zod.infer<typeof addressSchema>;

  const { formMethods, mutation } = useFormMutation<AddressSchema, UserRead>(
    {
      mutationFn: ({ country, ...formData }) =>
        userApi.userUpdateCurrentUser({
          userUpdate: formData,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
        toaster.add("success", t("commonV2:changeAddressForm.successMessage"));
        onSuccess();
      },
    },
    {
      resolver: zodResolver(addressSchema),
      defaultValues: {
        address: user?.address,
        postCode: user?.postCode,
        city: user?.city,
        country: user?.country,
      },
    },
  );

  return { formMethods, mutation, user };
}
