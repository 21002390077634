/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBenefitOrderRead } from "./BikeBenefitOrderRead";
import {
  BikeBenefitOrderReadFromJSON,
  BikeBenefitOrderReadFromJSONTyped,
  BikeBenefitOrderReadToJSON,
} from "./BikeBenefitOrderRead";

/**
 *
 * @export
 * @interface PaginatedBikeBenefitOrderOut
 */
export interface PaginatedBikeBenefitOrderOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBikeBenefitOrderOut
   */
  total: number;
  /**
   *
   * @type {Array<BikeBenefitOrderRead>}
   * @memberof PaginatedBikeBenefitOrderOut
   */
  items: Array<BikeBenefitOrderRead>;
}

/**
 * Check if a given object implements the PaginatedBikeBenefitOrderOut interface.
 */
export function instanceOfPaginatedBikeBenefitOrderOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedBikeBenefitOrderOutFromJSON(
  json: any,
): PaginatedBikeBenefitOrderOut {
  return PaginatedBikeBenefitOrderOutFromJSONTyped(json, false);
}

export function PaginatedBikeBenefitOrderOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBikeBenefitOrderOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(BikeBenefitOrderReadFromJSON),
  };
}

export function PaginatedBikeBenefitOrderOutToJSON(
  value?: PaginatedBikeBenefitOrderOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(BikeBenefitOrderReadToJSON),
  };
}
