import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Flex, Notification, TextButton, Typography } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function VerifyYourEmailPage() {
  const { t } = useTranslation();

  const {
    addedEmail,
    emailVerificationNotification,
    resendVerificationEmail,
    isLoading,
  } = useOnboardingMachine();

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.verifyEmail.title")}
      subtitle={
        <Trans
          i18nKey="userV2:onboarding.verifyEmail.subtitle"
          values={{ email: addedEmail?.email || "" }}
          components={{ b: <b /> }}
        />
      }
    >
      {emailVerificationNotification && !isLoading ? (
        <StyledNotificationWrapper>
          <Notification
            message={t("userV2:onboarding.verifyEmail.sentAgainMessage")}
            type="success"
          />
        </StyledNotificationWrapper>
      ) : null}
      <Flex direction="column" gap="xs" align="center">
        <Typography>
          {t("userV2:onboarding.verifyEmail.didNotReceiveEmail")}
        </Typography>
        <TextButton size="md" onClick={resendVerificationEmail}>
          {t("userV2:onboarding.verifyEmail.sendAgain")}
        </TextButton>
      </Flex>
    </OnboardingPageLayout>
  );
}

const StyledNotificationWrapper = styled.div`
  width: 24rem;
`;
