import { ReactNode } from "react";

import styled from "styled-components";

import { CircularButton } from "../CircularButton";
import { Flex } from "../Flex";
import { CloseIcon } from "../Icons";
import { Typography } from "../Typography";

export type InputListBoxProps = {
  title: string;
  children: ReactNode;
  onRemove?: () => void;
};

export function InputListBox({ title, children, onRemove }: InputListBoxProps) {
  return (
    <StyledRoot padding="lg" smPadding="md" direction="column" gap="md">
      <Flex justify="space-between" align="flex-start">
        <Typography variant="h3">{title}</Typography>
        {onRemove && (
          <CircularButton
            variant="outlined"
            icon={<CloseIcon />}
            onClick={onRemove}
            size="md"
          />
        )}
      </Flex>
      {children}
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  border-radius: ${({ theme }) => theme.spacing.md};
  border: ${({ theme }) => theme.border.secondary.main};
  background: ${({ theme }) => theme.palette.neutral.light};
`;
