/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BenefitDefinitionAccessRequestStatus {
  ACCEPTED = "accepted",
  CANCELLED = "cancelled",
  DENIED = "denied",
  EXPIRED = "expired",
  PENDING = "pending",
  INVALID = "invalid",
}
