import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { AnyStyledComponent, css } from "styled-components";

import { SupportedLanguagesEnum } from "@vapaus/api-codegen";
import { useUpdateCurrentUser } from "@vapaus/shared-api";
import {
  Button,
  CircularButton,
  FacebookIcon,
  Flex,
  InstagramIcon,
  LinkedinIcon,
  Logo,
  TextButton,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { LanguageSelectV2 } from "../../../i18n/src/LanguageSelectV2";
import { useUserCountry } from "../hooks";
import { LinksGroup, type LinksGroupProps } from "./LinksGroup";

type PageFooterProps = {
  linkGroups: Array<LinksGroupProps>;
};

export function PageFooter({ linkGroups = [] }: PageFooterProps) {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();
  const { mutate } = useUpdateCurrentUser();
  const { isSwedishUser } = useUserCountry();

  const onChangeLanguage = (newLanguage: string) =>
    mutate({ language: newLanguage.toUpperCase() as SupportedLanguagesEnum });

  return (
    <StyledRoot justify="center">
      <StyledInnerContainer
        direction="column"
        padding="xxl"
        xsPadding="lg"
        mdPadding="xl"
        gap="xl"
        fullWidth
      >
        <Flex justify="space-between">
          <StyledLogo />
          <LanguageSelectV2 variant="primary2" onChange={onChangeLanguage} />
        </Flex>
        <Flex gap="xxl" mdGap="xl" align="flex-start" xsDirection="column">
          {linkGroups.map((group) => (
            <LinksGroup
              key={group.title}
              title={group.title}
              links={group.links}
            />
          ))}
          <StyledBikeMailWrapper direction="column">
            <Typography variant="h4" color="secondary1.main">
              {t("commonV2:footer.bikeMail.title")}
            </Typography>
            <Flex align="flex-start" direction="column">
              <Typography color="common.white">
                {isSwedishUser
                  ? t("commonV2:sweden.footer.bikeMail.message")
                  : t("commonV2:footer.bikeMail.message")}
              </Typography>
              <Link
                to="https://tyosuhdepyora.vapaus.io/pyoraposti-uutiskirje"
                target="_blank"
              >
                <Button variant="primary2" size="small">
                  {t("commonV2:footer.bikeMail.subscribe")}
                </Button>
              </Link>
            </Flex>
          </StyledBikeMailWrapper>
        </Flex>
        <Flex
          justify="space-between"
          xsDirection="column"
          xsJustify="center"
          gap="lg"
        >
          <Flex
            gap="xs"
            xsAlign="center"
            xsJustify="center"
            xsDirection="column"
          >
            <Typography color="common.white" weight="bold">
              © Vapaus Bikes Finland Oy
            </Typography>
            {isXs ? null : (
              <Typography color="common.white" weight="bold">
                ·
              </Typography>
            )}

            <Flex gap="xs">
              <TextButton to="/terms-of-service" target="_blank">
                <Typography color="common.white" weight="bold">
                  {t("commonV2:footer.termsOfService")}
                </Typography>
              </TextButton>
              <Typography color="common.white" weight="bold">
                ·
              </Typography>
              <TextButton
                to={
                  isSwedishUser
                    ? t("commonV2:sweden.footer.vapausLink.privacyPolicy")
                    : t("commonV2:footer.vapausLink.privacyPolicy")
                }
                target="_blank"
              >
                <Typography color="common.white" weight="bold">
                  {t("commonV2:footer.privacyPolicy")}
                </Typography>
              </TextButton>
            </Flex>
          </Flex>
          <Flex gap="xs">
            <Link to="https://facebook.com/VapausBikes" target="_blank">
              <StyledCircularButton
                variant="outlined"
                icon={<FacebookIcon />}
                size="md"
              />
            </Link>
            <Link to="https://www.instagram.com/vapaus.io" target="_blank">
              <StyledCircularButton
                variant="outlined"
                icon={<InstagramIcon />}
                size="md"
              />
            </Link>
            <Link
              to="https://fi.linkedin.com/company/vapaus-io"
              target="_blank"
            >
              <StyledCircularButton
                variant="outlined"
                icon={<LinkedinIcon />}
                size="md"
              />
            </Link>
          </Flex>
        </Flex>
      </StyledInnerContainer>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.primary1.main};
`;

const StyledInnerContainer = styled(Flex)`
  max-width: 1200px;
  ${({ theme }) =>
    theme.breakpoints.md.up(css`
      padding-left: 0;
      padding-right: 0;
    `)}
`;

const StyledLogo = styled(Logo)`
  filter: brightness(0) invert(1);
`;

const StyledBikeMailWrapper = styled(Flex)`
  max-width: 295px;
`;

const StyledCircularButton = styled(CircularButton as AnyStyledComponent)`
  outline: none;
  :hover {
    background-color: ${({ theme }) =>
      theme.palette.primary1.lighter} !important;
  }
`;
