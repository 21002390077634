import { useFindOrdersQuery } from "../../api/benefit";
import { NoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/NoApprovedBenefitsBanner";
import { useNoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/useNoApprovedBenefitsBanner";
import { PageLayout } from "../../components/PageLayout";
import { Bikes } from "./components/Bikes";
import { Empty } from "./components/Empty";

export function MyBikesPage() {
  const { shouldShowNoApprovedBenefitsBanner } = useNoApprovedBenefitsBanner();

  const { data: orders } = useFindOrdersQuery({
    sortDirection: "desc",
    sortField: "updated_at",
    limit: 100,
  });

  return (
    <>
      {shouldShowNoApprovedBenefitsBanner ? <NoApprovedBenefitsBanner /> : null}
      <PageLayout>
        {orders ? (
          orders.items.length > 0 ? (
            <Bikes orders={orders.items} />
          ) : (
            <Empty hasActivation={!shouldShowNoApprovedBenefitsBanner} />
          )
        ) : null}
      </PageLayout>
    </>
  );
}
