/**
 * Function to determine if the calculator run on a oomi domain, if so we will use Oomi theme, logo, footer, ...
 */
export function isOomi(): boolean {
  return [
    // uncomment below to test on local
    // "localhost:5173",
    "savings-pr-1395.dev-vapaus.com",
    "fillarilaskuri.oomi.fi",
  ].includes(window.location.host);
}
