import { ReactNode, forwardRef, useRef, useState } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Popper } from "@mui/base/Popper";
import {
  UseAutocompleteProps,
  useAutocomplete,
} from "@mui/base/useAutocomplete";
import { unstable_useForkRef as useForkRef } from "@mui/utils";
import clsx from "clsx";
import styled, { AnyStyledComponent } from "styled-components";

import { CircularButton } from "../CircularButton";
import { Flex } from "../Flex";
import { CloseIcon } from "../Icons";
import { ArrowDropDownIcon } from "../Icons/ArrowDropDownIcon";
import { SearchIcon } from "../Icons/SearchIcon";
import { Spinner } from "../Spinner";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { StyledDropdownItemRoot } from "../dropdown/StyledDropdownItemRoot";
import { addAlpha, makeTransition } from "../styles";

export type AutocompleteSelectOptionType = {
  label: string;
  value: string | number;
};

export interface AutocompleteSelectProps
  extends UseAutocompleteProps<
    AutocompleteSelectOptionType,
    false,
    false,
    false
  > {
  placeholder: ReactNode;
  noResultMessage: string;
  inputPlaceholder: string;
  label?: string;
  tooltip?: string;
  error?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  autoPopperWidth?: boolean;
}

export const AutocompleteSelect = forwardRef<
  HTMLDivElement,
  AutocompleteSelectProps
>(function AutocompleteSelect(
  {
    placeholder,
    label,
    tooltip,
    error,
    noResultMessage,
    inputPlaceholder,
    isLoading,
    fullWidth,
    autoPopperWidth,
    ...rest
  },
  ref,
) {
  const inputWrapRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    setAnchorEl,
    groupedOptions,
    inputValue,
    popupOpen,
    anchorEl,
    focused,
    value,
    id,
  } = useAutocomplete({
    ...rest,
    open,
    onOpen: () => setOpen(true),
    onClose: (_, reason) =>
      ["toggleInput", "blur"].includes(reason) ? null : setOpen(false),
  });

  const rootRef = useForkRef(ref, setAnchorEl);

  const handleClickAway = () => setOpen(false);

  const handleOpenPopup = () => {
    setOpen((previousOpen) => !previousOpen);
    setTimeout(() => {
      inputWrapRef.current?.querySelector("input")?.focus();
    }, 200);
  };

  const renderSelectedValue = (
    value: AutocompleteSelectOptionType | string | null,
  ) => {
    const valueString = typeof value === "string" ? value : value?.value;
    const matchingOption = (rest.options || []).find(
      (opt) => opt.value === valueString,
    );

    if (!matchingOption) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
    }
    return (
      <StyledSelectedValueContainer>
        {matchingOption.label}
      </StyledSelectedValueContainer>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledRoot {...getRootProps(rest)} ref={rootRef} fullWidth={fullWidth}>
        <Flex direction="column" gap="xs">
          {label || tooltip ? (
            <StyledLabelContainer>
              {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
              {tooltip && <StyledTooltip>{tooltip}</StyledTooltip>}
            </StyledLabelContainer>
          ) : null}
          <StyledButton
            onClick={handleOpenPopup}
            className={clsx(error && "error")}
            type="button"
          >
            {renderSelectedValue(value)}
            <StyledAdornment>
              <ArrowDropDownIcon />
            </StyledAdornment>
          </StyledButton>
          {error && <StyledErrorText role="alert">{error}</StyledErrorText>}
        </Flex>
        <Popper
          open
          anchorEl={anchorEl}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ]}
          slots={{
            root: StyledPopover,
          }}
          style={{
            width: autoPopperWidth ? "auto" : anchorEl?.clientWidth,
            display: popupOpen ? "initial" : "none",
          }}
        >
          <StyledInputWrapper
            ref={inputWrapRef}
            padding="md"
            gap="xs"
            className={focused ? "Mui-focused" : ""}
          >
            <SearchIcon />
            <StyledSearchInput
              id={id}
              {...getInputProps()}
              placeholder={inputPlaceholder}
              tabIndex={-1}
              onBlur={(e) => e.stopPropagation()}
            />
            <StyledCircularButton
              size="sm"
              icon={<CloseIcon />}
              {...getClearProps()}
              $visible={!!inputValue}
            />
          </StyledInputWrapper>
          <StyledList {...getListboxProps()}>
            {isLoading ? (
              <li>
                <Flex padding="xxl">
                  <Spinner />
                </Flex>
              </li>
            ) : (
              <>
                {(groupedOptions as AutocompleteSelectOptionType[]).map(
                  (option, index) => (
                    <StyledListItem
                      {...getOptionProps({ option, index })}
                      key={option.value}
                      tabIndex={0}
                    >
                      <StyledDropdownItemRoot disabled={false}>
                        <Typography variant="bodyMd" weight="bold">
                          {option.label}
                        </Typography>
                      </StyledDropdownItemRoot>
                    </StyledListItem>
                  ),
                )}
                {groupedOptions.length === 0 ? (
                  <li>
                    <StyledNothingFoundTextWrapper
                      padding="sm"
                      justify="center"
                    >
                      <Typography
                        variant="bodyMd"
                        weight="bold"
                        align="center"
                        noWrap
                      >
                        {noResultMessage}
                      </Typography>
                    </StyledNothingFoundTextWrapper>
                  </li>
                ) : null}
              </>
            )}
          </StyledList>
        </Popper>
      </StyledRoot>
    </ClickAwayListener>
  );
});

const StyledRoot = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

const StyledInputWrapper = styled(Flex)`
  background: ${({ theme }) => addAlpha(theme.palette.primary1.lighter, 0.2)};
  border-bottom: ${({ theme }) => theme.border.primary.light};
  padding-top: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.md};
`;

const StyledCircularButton = styled(CircularButton as AnyStyledComponent)<{
  $visible: boolean;
}>`
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;

const StyledNothingFoundTextWrapper = styled(Flex)`
  height: 120px;
`;

const StyledSearchInput = styled.input`
  background: none;
  border: none;
  flex: 1;
  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  color: ${({ theme }) => theme.typography.defaultColor};
  &:focus {
    outline: none;
  }
`;

const StyledPopover = styled.div`
  width: max-content;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.white};
  border-radius: ${({ theme }) => theme.radius.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.selectPopper};
`;

const StyledList = styled.ul`
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.palette.primary1.lighter};
  }

  max-height: 400px;
  outline: 0;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  &.Mui-focused {
    background-color: ${({ theme }) => theme.palette.neutral.light};
  }
`;

const StyledSelectedValueContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledTooltip = styled(Tooltip as AnyStyledComponent)`
  margin-left: auto;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.none};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  border-color: ${({ theme }) => theme.palette.primary1.main};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  height: 56px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  color: ${({ theme }) => theme.typography.defaultColor};

  ${makeTransition("all", "shortest", "easeOut")}
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary1.light};
  }

  &:active,
  &:focus-within {
    box-shadow: ${({ theme }) => theme.shadows.input};
  }

  &:disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.error};
  }

  ::placeholder {
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }
`;

const StyledErrorText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledAdornment = styled.div`
  &:first-child {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }

  &:last-child {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }

  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.typography.defaultColor};
`;

const StyledPlaceholder = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  flex: 1;
  text-align: left;
`;
