import { ReactNode } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserShopRead } from "@vapaus/api-codegen";

import {
  amplerShopId,
  canyonShopId,
  commencalShopId,
  econicOneShopId,
  silverbackShopId,
  verkkokauppaShopId,
} from "./hardCodedShopIds";
import amplerLogo from "./logos/ampler-logo.png";
import canyonLogo from "./logos/canyon-logo.png";
import commencalLogo from "./logos/commencal-logo.png";
import econicOneLogo from "./logos/econic-one-logo.png";
import silverbackLogo from "./logos/silverback-logo.png";

export type HardCodedContentType = {
  title: string;
  description: ReactNode;
  instructionLink: string;
  logo?: string;
  ctaText: string;
  ctaLink?: string;
};

export function useHardCodedContent(
  shop?: UserShopRead,
): HardCodedContentType | undefined {
  const { t } = useTranslation();

  const hardCodedContent: Record<string, HardCodedContentType> = {
    [amplerShopId]: {
      title: t("userV2:getABike.customShopModal.content.emailOrder.title"),
      description: t(
        "userV2:getABike.customShopModal.content.emailOrder.description",
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.ampler.instructionLink",
      ),
      ctaText: t("userV2:getABike.genericShopModal.visitWebsite"),
      ctaLink: shop?.website,
      logo: amplerLogo,
    },
    [silverbackShopId]: {
      title: t("userV2:getABike.customShopModal.content.emailOrder.title"),
      description: t(
        "userV2:getABike.customShopModal.content.emailOrder.description",
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.silverback.instructionLink",
      ),
      ctaText: t("userV2:getABike.genericShopModal.visitWebsite"),
      ctaLink: shop?.website,
      logo: silverbackLogo,
    },
    [econicOneShopId]: {
      title: t("userV2:getABike.customShopModal.content.vapausOrderForm.title"),
      description: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.description",
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.instructionLink",
      ),
      ctaText: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaText",
      ),
      ctaLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaLink",
      ),
      logo: econicOneLogo,
    },
    [verkkokauppaShopId]: {
      title: t("userV2:getABike.customShopModal.content.vapausOrderForm.title"),
      description: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.description",
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.instructionLink",
      ),
      ctaText: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaText",
      ),
      ctaLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaLink",
      ),
    },
    [commencalShopId]: {
      title: t("userV2:getABike.customShopModal.content.vapausOrderForm.title"),
      description: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.description",
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.instructionLink",
      ),
      ctaText: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaText",
      ),
      ctaLink: t(
        "userV2:getABike.customShopModal.content.vapausOrderForm.ctaLink",
      ),
      logo: commencalLogo,
    },
    [canyonShopId]: {
      title: t("userV2:getABike.customShopModal.content.canyon.title"),
      description: (
        <Trans
          i18nKey={t(
            "userV2:getABike.customShopModal.content.canyon.description",
          )}
          components={{
            1: (
              <Link
                target="_blank"
                rel="noreferrer"
                to={t(
                  "userV2:getABike.customShopModal.content.canyon.formLink",
                )}
              >
                order form
              </Link>
            ),
          }}
        />
      ),
      instructionLink: t(
        "userV2:getABike.customShopModal.content.canyon.instructionLink",
      ),
      ctaText: t("userV2:getABike.genericShopModal.visitWebsite"),
      ctaLink: shop?.website,
      logo: canyonLogo,
    },
  };

  return shop?.id ? hardCodedContent[shop.id] : undefined;
}
