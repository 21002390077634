import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { BikeBenefitContractRead } from "@vapaus/api-codegen";

import { useGetContractRedemptionCalculation } from "../../../../../../api/redemptionProcess";
import { useGetReturnProcessPreliminaryData } from "../../../../../../api/returnProcess";
import { CONTRACT_CANCELLATION_FROM_STATE } from "../../../../../../constants/contractNavigation";
import { getIsContractEndingSoon } from "../../../../../../utils/getIsContractEndingSoon";

export function useRedeemReturnCTA(contract?: BikeBenefitContractRead) {
  const navigate = useNavigate();
  const [openForRedeemClicked, setOpenForRedeemClicked] =
    useState<boolean>(false);
  const [openForReturnClicked, setOpenForReturnClicked] =
    useState<boolean>(false);
  const { data: calculation } = useGetContractRedemptionCalculation(
    contract?.id,
    getIsContractEndingSoon(contract?.endDate),
    {
      enabled: !!contract?.id && openForRedeemClicked,
    },
  );

  const { data: preliminaryData } = useGetReturnProcessPreliminaryData(
    contract?.id,
    getIsContractEndingSoon(contract?.endDate),
    {
      enabled: !!contract?.id && openForReturnClicked,
    },
  );

  const availableMaintenanceBudgetForRedemption =
    calculation?.availableMaintenanceBudget ?? 0;

  const availableMaintenanceBudgetForReturn =
    preliminaryData?.remainingMaintenanceBudget ?? 0;

  const [isOpenForRedeem, setIsOpenForRedeem] = useState(false);
  const [isOpenForReturn, setIsOpenForReturn] = useState(false);

  const navigateTo = useCallback(
    (path: "redeem-bike" | "return-bike") => {
      navigate(path, {
        state: { navigatedFrom: CONTRACT_CANCELLATION_FROM_STATE },
      });
    },
    [navigate],
  );

  const handleClickRedeemBikeButton = useCallback(() => {
    if (!openForRedeemClicked) {
      setOpenForRedeemClicked(true);
    }
  }, [openForRedeemClicked]);

  useEffect(() => {
    if (calculation && openForRedeemClicked) {
      if (availableMaintenanceBudgetForRedemption) setIsOpenForRedeem(true);
      else navigateTo("redeem-bike");
      setOpenForRedeemClicked(false);
    }
  }, [
    availableMaintenanceBudgetForRedemption,
    calculation,
    navigateTo,
    openForRedeemClicked,
  ]);

  const handleClickReturnBikeButton = useCallback(() => {
    if (!openForReturnClicked) {
      setOpenForReturnClicked(true);
    }
  }, [openForReturnClicked]);

  useEffect(() => {
    if (preliminaryData && openForReturnClicked) {
      if (availableMaintenanceBudgetForReturn) setIsOpenForReturn(true);
      else navigateTo("return-bike");
      setOpenForReturnClicked(false);
    }
  }, [
    availableMaintenanceBudgetForReturn,
    navigateTo,
    openForReturnClicked,
    preliminaryData,
  ]);

  const handleCloseModalForRedeem = () => setIsOpenForRedeem(false);
  const handleCloseModalForReturn = () => setIsOpenForReturn(false);

  return {
    hasActiveCancellationCoverage:
      contract?.cancellationCoverageStatus === "active",
    isOpenForRedeem,
    isOpenForReturn,
    availableMaintenanceBudget:
      availableMaintenanceBudgetForRedemption ||
      availableMaintenanceBudgetForReturn,
    handleClickRedeemBikeButton,
    handleClickReturnBikeButton,
    handleCloseModalForRedeem,
    handleCloseModalForReturn,
  };
}
