import { createSvgIcon } from "./SvgIcon";

export const LineEndCircleIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 18C14.5 18 13.1958 17.525 12.0875 16.575C10.9792 15.625 10.3083 14.4333 10.075 13H3C2.71667 13 2.47917 12.9042 2.2875 12.7125C2.09583 12.5208 2 12.2833 2 12C2 11.7167 2.09583 11.4792 2.2875 11.2875C2.47917 11.0958 2.71667 11 3 11H10.075C10.3083 9.56667 10.9792 8.375 12.0875 7.425C13.1958 6.475 14.5 6 16 6C17.6667 6 19.0833 6.58333 20.25 7.75C21.4167 8.91667 22 10.3333 22 12C22 13.6667 21.4167 15.0833 20.25 16.25C19.0833 17.4167 17.6667 18 16 18Z" />
  </svg>,
  "LineEndCircle",
);
