import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { PaymentConsentStatus } from "@vapaus/api-codegen";
import { useToaster } from "@vapaus/ui-v2";

import {
  useGetPaymentConsent,
  useVismaPayCallback,
} from "../../../../api/paymentConsent";
import { VISMA_PAY_RETURN_KEY } from "../../../../constants/vismaPay";

export function useVismaPayCardCallback() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toaster = useToaster();
  const [searchParams, setSearchParams] = useSearchParams();
  const vismaPayCallback = useVismaPayCallback();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [processingConsentId, setProcessingConsentId] = useState<string>();
  const { data: processingConsent } = useGetPaymentConsent(processingConsentId);

  useEffect(() => {
    if (processingConsentId) {
      if (
        (
          [
            PaymentConsentStatus.Failed,
            PaymentConsentStatus.Cancelled,
            PaymentConsentStatus.Unknown,
          ] as PaymentConsentStatus[]
        ).includes(processingConsent?.status as PaymentConsentStatus)
      ) {
        setErrorMessage(processingConsent?.failureReason);
        setProcessingConsentId(undefined);
      } else if (processingConsent?.status === PaymentConsentStatus.Confirmed) {
        toaster.add("success", t("userV2:billingTable.cardAddedSuccessfully"));
        setProcessingConsentId(undefined);
      }
    }
  }, [
    processingConsentId,
    toaster,
    t,
    processingConsent?.status,
    processingConsent?.failureReason,
  ]);

  useEffect(() => {
    if (
      searchParams.get("ORDER_NUMBER") &&
      !searchParams.get(VISMA_PAY_RETURN_KEY)
    ) {
      setProcessingConsentId(searchParams.get("ORDER_NUMBER") || undefined);
      vismaPayCallback.mutate(
        {
          oRDERNUMBER: searchParams.get("ORDER_NUMBER") || undefined,
          rETURNCODE: searchParams.get("RETURN_CODE") || undefined,
          sETTLED: searchParams.get("SETTLED") || undefined,
          iNCIDENTID: searchParams.get("INCIDENT_ID") || undefined,
          aUTHCODE: searchParams.get("AUTHCODE") || undefined,
        },
        {
          onSettled: () => {
            queryClient.invalidateQueries(["paymentConsents"]);
          },
        },
      );
      setSearchParams();
    }
  }, [
    processingConsentId,
    queryClient,
    searchParams,
    setSearchParams,
    t,
    toaster,
    vismaPayCallback,
  ]);

  return {
    errorMessage,
    clearError: () => setErrorMessage(undefined),
  };
}
