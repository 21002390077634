import { ReactNode } from "react";

import get from "lodash/get";
import styled, { css } from "styled-components";

import type { PaletteVariants, Theme } from "@vapaus/ui-v2";
import { Logo, Typography } from "@vapaus/ui-v2";

// TODO: Import LanguageSelectV2 from @vapaus/i18n after LanguageSelect has been completely removed to avoid importing legacy styles.
import { LanguageSelectV2 } from "../../../../i18n/src/LanguageSelectV2";
import bunnyImg from "../../../assets/bunny.png";

type AuthLayoutProps = {
  title: string;
  description: string;
  icon?: ReactNode;
  bannerImage?: string;
  children: ReactNode;
  bgColor?: PaletteVariants;
};

export function AuthLayout({
  title,
  description,
  icon,
  bannerImage,
  children,
  bgColor,
}: AuthLayoutProps) {
  return (
    <Root>
      <Header bgColor={bgColor}>
        <Logo />
        <LanguageSelectV2 />
      </Header>
      <Content>
        <Banner bgColor={bgColor}>
          <BannerImage
            data-testid="banner-image"
            src={bannerImage ?? bunnyImg}
          />
        </Banner>
        <FormContainer>
          <FormWrapper>
            {icon}
            <Typography variant="h1" align="center" fullWidth>
              {title}
            </Typography>
            <Typography variant="bodyLg" align="center" fullWidth>
              {description}
            </Typography>
            {children}
          </FormWrapper>
        </FormContainer>
      </Content>
    </Root>
  );
}

function getBgColor({
  bgColor,
  theme,
}: {
  bgColor?: PaletteVariants;
  theme: Theme;
}) {
  return css`
    background-color: ${get(theme.palette, bgColor ?? "primary2.main")};
  `;
}

const Root = styled.main`
  height: 100vh;
  position: relative;
`;

const Banner = styled.div<{
  bgColor?: PaletteVariants;
}>`
  flex: 1;
  ${getBgColor};
  padding-top: ${({ theme }) => theme.height.navBar};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: ${({ theme }) => theme.border.secondary.main};

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      display: none;
    `)}
`;

const BannerImage = styled.img`
  width: 100%;
  max-width: 720px;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xl};
  max-width: 480px;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      padding: ${`${theme.spacing.xl} ${theme.spacing.md}`};
      align-self: flex-start;
    `)}
`;

const FormContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding-top: ${({ theme }) => theme.height.navBar};
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`;

const Header = styled.header<{
  bgColor?: PaletteVariants;
}>`
  height: ${({ theme }) => theme.height.navBar};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      ${getBgColor};
      border-bottom: ${({ theme }) => theme.border.secondary.main};
    `)}
`;

const Content = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;
