import { ReactNode } from "react";

import styled from "styled-components";

type MobileNavigationBarProps = {
  children: ReactNode;
};

export const MobileNavigationBar = ({ children }: MobileNavigationBarProps) => {
  return <StyledRoot>{children}</StyledRoot>;
};

const StyledRoot = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: ${({ theme }) => theme.height.navBar};
  border-top: ${({ theme }) => theme.border.secondary.main};
  background-color: ${({ theme }) => theme.palette.background.white};
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
`;
