/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { ReturnProcessPickupDetailsRead } from "./ReturnProcessPickupDetailsRead";
import {
  ReturnProcessPickupDetailsReadFromJSON,
  ReturnProcessPickupDetailsReadFromJSONTyped,
  ReturnProcessPickupDetailsReadToJSON,
} from "./ReturnProcessPickupDetailsRead";
import type { ReturnProcessStatus } from "./ReturnProcessStatus";
import {
  ReturnProcessStatusFromJSON,
  ReturnProcessStatusFromJSONTyped,
  ReturnProcessStatusToJSON,
} from "./ReturnProcessStatus";
import type { ReturnReason } from "./ReturnReason";
import {
  ReturnReasonFromJSON,
  ReturnReasonFromJSONTyped,
  ReturnReasonToJSON,
} from "./ReturnReason";

/**
 *
 * @export
 * @interface ReturnProcessRead
 */
export interface ReturnProcessRead {
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  initiatedByUserId: string;
  /**
   *
   * @type {boolean}
   * @memberof ReturnProcessRead
   */
  isEndOfLeaseReturn: boolean;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessRead
   */
  leaseEndDate: Date;
  /**
   *
   * @type {ReturnReason}
   * @memberof ReturnProcessRead
   */
  returnReason: ReturnReason;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  id: string;
  /**
   *
   * @type {ReturnProcessStatus}
   * @memberof ReturnProcessRead
   */
  status: ReturnProcessStatus;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessRead
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  saleInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  hubspotTicketId?: string;
  /**
   *
   * @type {ReturnProcessPickupDetailsRead}
   * @memberof ReturnProcessRead
   */
  pickupDetails?: ReturnProcessPickupDetailsRead;
}

/**
 * Check if a given object implements the ReturnProcessRead interface.
 */
export function instanceOfReturnProcessRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bikeBenefitContractId" in value;
  isInstance = isInstance && "initiatedByUserId" in value;
  isInstance = isInstance && "isEndOfLeaseReturn" in value;
  isInstance = isInstance && "leaseEndDate" in value;
  isInstance = isInstance && "returnReason" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;

  return isInstance;
}

export function ReturnProcessReadFromJSON(json: any): ReturnProcessRead {
  return ReturnProcessReadFromJSONTyped(json, false);
}

export function ReturnProcessReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    initiatedByUserId: json["initiated_by_user_id"],
    isEndOfLeaseReturn: json["is_end_of_lease_return"],
    leaseEndDate: new Date(json["lease_end_date"]),
    returnReason: ReturnReasonFromJSON(json["return_reason"]),
    id: json["id"],
    status: ReturnProcessStatusFromJSON(json["status"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    saleInvoiceId: !exists(json, "sale_invoice_id")
      ? undefined
      : json["sale_invoice_id"],
    hubspotTicketId: !exists(json, "hubspot_ticket_id")
      ? undefined
      : json["hubspot_ticket_id"],
    pickupDetails: !exists(json, "pickup_details")
      ? undefined
      : ReturnProcessPickupDetailsReadFromJSON(json["pickup_details"]),
  };
}

export function ReturnProcessReadToJSON(value?: ReturnProcessRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bike_benefit_contract_id: value.bikeBenefitContractId,
    initiated_by_user_id: value.initiatedByUserId,
    is_end_of_lease_return: value.isEndOfLeaseReturn,
    lease_end_date: value.leaseEndDate.toISOString().substring(0, 10),
    return_reason: ReturnReasonToJSON(value.returnReason),
    id: value.id,
    status: ReturnProcessStatusToJSON(value.status),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    sale_invoice_id: value.saleInvoiceId,
    hubspot_ticket_id: value.hubspotTicketId,
    pickup_details: ReturnProcessPickupDetailsReadToJSON(value.pickupDetails),
  };
}
