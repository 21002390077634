import { useCurrentUser } from "@vapaus/auth-v2";
import { Flex } from "@vapaus/ui-v2";

import { FeaturedShops } from "./components/FeaturedShops";
import { PrecycledCard } from "./components/PrecycledCard";
import { ShopsTable } from "./components/ShopsTable";
import { SpecialDeals } from "./components/SpecialDeals";

export function PartnerShopsTab() {
  const { data: user } = useCurrentUser();
  return (
    <Flex direction="column" gap="xl">
      {user?.country === "FI" ? <SpecialDeals /> : null}
      <PrecycledCard />
      <FeaturedShops />
      <ShopsTable />
    </Flex>
  );
}
