/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
} from "./StorageObjectRead";

/**
 *
 * @export
 * @interface BenefitDefinitionTermsRead
 */
export interface BenefitDefinitionTermsRead {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  pdfObjectId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionTermsRead
   */
  isCurrent?: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionTermsRead
   */
  id: string;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof BenefitDefinitionTermsRead
   */
  pdfObject?: StorageObjectRead;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionTermsRead
   */
  createdAt?: Date;
}

/**
 * Check if a given object implements the BenefitDefinitionTermsRead interface.
 */
export function instanceOfBenefitDefinitionTermsRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "id" in value;

  return isInstance;
}

export function BenefitDefinitionTermsReadFromJSON(
  json: any,
): BenefitDefinitionTermsRead {
  return BenefitDefinitionTermsReadFromJSONTyped(json, false);
}

export function BenefitDefinitionTermsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionTermsRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    benefitDefinitionId: json["benefit_definition_id"],
    content: !exists(json, "content") ? undefined : json["content"],
    pdfObjectId: !exists(json, "pdf_object_id")
      ? undefined
      : json["pdf_object_id"],
    isCurrent: !exists(json, "is_current") ? undefined : json["is_current"],
    id: json["id"],
    pdfObject: !exists(json, "pdf_object")
      ? undefined
      : StorageObjectReadFromJSON(json["pdf_object"]),
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
  };
}

export function BenefitDefinitionTermsReadToJSON(
  value?: BenefitDefinitionTermsRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    benefit_definition_id: value.benefitDefinitionId,
    content: value.content,
    pdf_object_id: value.pdfObjectId,
    is_current: value.isCurrent,
    id: value.id,
    pdf_object: StorageObjectReadToJSON(value.pdfObject),
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
  };
}
