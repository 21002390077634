import { useDateFormat } from "packages/i18n/src/hooks";
import styled from "styled-components";

import {
  BuildIcon,
  CircularIcon,
  Flex,
  Typography,
  useBreakpoints,
} from "@vapaus/ui-v2";

interface EventCellProps {
  type: string;
  date: Date;
}

export function EventCell({ type, date }: EventCellProps) {
  const formatDate = useDateFormat();
  const { isXs } = useBreakpoints();

  return (
    <Flex>
      {!isXs ? <CircularIcon icon={BuildIcon} /> : null}
      <StyledFlex direction="column" gap="xss">
        <Typography>{type}</Typography>
        <Typography variant="bodySm" weight="bold">
          {formatDate(date)}
        </Typography>
      </StyledFlex>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;
