import { ReactElement } from "react";

import { Flex, Typography } from "@vapaus/ui-v2";

interface NameValueDescriptionProps {
  name: string;
  value: string;
  description: string | ReactElement;
}

export function NameValueDescription({
  name,
  value,
  description,
}: NameValueDescriptionProps) {
  return (
    <Flex direction="column" gap="xs">
      <Flex justify="space-between">
        <Typography weight="bold">{name}</Typography>
        <Typography weight="bold">{value}</Typography>
      </Flex>
      <Typography>{description}</Typography>
    </Flex>
  );
}
