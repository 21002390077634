import { ReactNode } from "react";

import { get } from "lodash-es";
import styled, { css } from "styled-components";

import { CircularButton } from "../CircularButton";
import { ArrowBackIcon, CloseIcon } from "../Icons";
import { Typography } from "../Typography";
import { PaletteVariants } from "../styles";

export type ModalNavBarProps = {
  title?: ReactNode;
  onBack?: () => void;
  backEnabled?: boolean;
  onClose?: () => void;
  bgColor?: PaletteVariants;
  hasBorder?: boolean;
};

export function ModalNavBar({
  title,
  bgColor = "primary2.main",
  hasBorder = true,
  onBack,
  backEnabled = true,
  onClose,
}: ModalNavBarProps) {
  return (
    <StyledRoot bgColor={bgColor} hasBorder={hasBorder}>
      <ButtonWrapper>
        {onBack && (
          <CircularButton
            onClick={onBack}
            icon={<ArrowBackIcon />}
            variant="outlined"
            disabled={!backEnabled}
          />
        )}
      </ButtonWrapper>
      {title && (
        <Typography
          variant="h6"
          colorOn={bgColor}
          align="center"
          fullWidth
          noWrap
        >
          {title}
        </Typography>
      )}
      <ButtonWrapper>
        {onClose && (
          <CircularButton
            onClick={onClose}
            icon={<CloseIcon />}
            variant="outlined"
          />
        )}
      </ButtonWrapper>
    </StyledRoot>
  );
}

const StyledRoot = styled.div<{
  bgColor: PaletteVariants;
  hasBorder: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.height.navBar};
  background-color: ${({ theme, bgColor }) => get(theme.palette, bgColor)};
  gap: ${({ theme }) => theme.spacing.lg};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  ${({ hasBorder, theme }) =>
    hasBorder &&
    css`
      border-bottom: ${theme.border.secondary.main};
    `}
`;

const ButtonWrapper = styled.div`
  min-width: 48px;
`;
