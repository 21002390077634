import { ReactElement, ReactNode } from "react";

import { get } from "lodash-es";
import styled, { css } from "styled-components";

import {
  Flex,
  PaletteVariants,
  TabRow,
  TabRowItemProps,
  Typography,
} from "@vapaus/ui-v2";

import { BackButton } from "./BackButton";

interface HeroProps {
  background: PaletteVariants;
  beforeContent?: ReactNode;
  navigationHeader?: ReactNode;
  inBetweenContent?: ReactNode;
  afterContent?: ReactNode;
  tabs?: Array<TabRowItemProps>;
  title: string;
  description: ReactElement | string;
  backButtonText?: string;
  backButtonPath?: string;
}

export function Hero({
  beforeContent,
  inBetweenContent,
  afterContent,
  title,
  description,
  background,
  tabs,
  backButtonText,
  backButtonPath,
  navigationHeader,
}: HeroProps) {
  return (
    <StyledRoot background={background}>
      <StyledContainer>
        {navigationHeader || (
          <BackButton label={backButtonText} to={backButtonPath} />
        )}
        <StyledContent>
          <Flex direction="column" align="center" gap="lg">
            <Flex direction="column" align="center" gap="sm">
              {beforeContent}
              <Typography variant="h1" align="center">
                {title}
              </Typography>
              {inBetweenContent}
              <StyledDescriptionContainer>
                <Typography variant="bodyLg" align="center">
                  {description}
                </Typography>
              </StyledDescriptionContainer>
            </Flex>
            {afterContent}
          </Flex>
        </StyledContent>
      </StyledContainer>
      {tabs && tabs.length > 0 && <TabRow items={tabs} />}
    </StyledRoot>
  );
}

interface StyledRootProps {
  background: PaletteVariants;
}

const StyledRoot = styled.div<StyledRootProps>`
  background-color: ${({ theme, background }) =>
    get(theme.palette, background)};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
  padding-top: ${({ theme }) => theme.spacing.xl};
  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      padding-top: ${theme.spacing.lg};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding-top: ${theme.spacing.md};
    `)}
`;

const StyledContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: ${({ theme }) => `0 ${theme.spacing.lg} ${theme.spacing.xl}`};

  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      padding: 0 ${theme.spacing.md} ${theme.spacing.lg};
    `)}

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: 0 ${theme.spacing.md} ${theme.spacing.md};
    `)}
`;

const StyledContent = styled.div`
  margin: ${({ theme }) => `${theme.spacing.lg} 0`};
`;

const StyledDescriptionContainer = styled.div`
  max-width: 600px;
`;
