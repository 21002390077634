import { useEffect, useMemo } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { PageSpinner } from "@vapaus/ui-v2";

import { useFindOrdersQuery } from "../api/benefit";
import { useGetBenefitDefinitionAccessRequests } from "../api/benefitDefinitionAccessRequests";
import { useGetBenefitDefinitionsActivations } from "../api/benefitDefinitions";
import { Path } from "../constants/paths";

export function OnboardingChecker() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: orders, isLoading: isLoadingOrders } = useFindOrdersQuery({
    limit: 1,
  });
  const { data: accessRequests, isLoading: isLoadingAccessRequests } =
    useGetBenefitDefinitionAccessRequests({ limit: 1 }, { cacheTime: 0 });
  const { data: activations, isLoading: isLoadingActivations } =
    useGetBenefitDefinitionsActivations({ limit: 1 }, { cacheTime: 0 });

  const isLoading =
    isLoadingOrders || isLoadingAccessRequests || isLoadingActivations;

  const shouldRedirectToOnboarding = useMemo(
    () =>
      !isLoading &&
      orders?.total === 0 &&
      accessRequests?.total === 0 &&
      activations?.total === 0 &&
      !location.pathname.includes(Path.onboarding),
    [
      isLoading,
      orders?.total,
      accessRequests?.total,
      activations?.total,
      location.pathname,
    ],
  );

  useEffect(() => {
    if (shouldRedirectToOnboarding) {
      navigate(`/${Path.onboarding}`);
    }
  }, [navigate, shouldRedirectToOnboarding]);

  return (
    <>
      <Outlet />
      {isLoading ? <PageSpinner /> : null}
    </>
  );
}
