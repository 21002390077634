/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
 */
export interface BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation {
  /**
   *
   * @type {string}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  monthlyPayment: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  fixedDownPaymentAmount?: number;
  /**
   * Total package price (excluding maintenance budget)
   * @type {number}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  totalPackagePrice: number;
  /**
   *
   * @type {number}
   * @memberof BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation
   */
  monthlySalary: number;
}

/**
 * Check if a given object implements the BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation interface.
 */
export function instanceOfBodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "monthlyPayment" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "totalPackagePrice" in value;
  isInstance = isInstance && "monthlySalary" in value;

  return isInstance;
}

export function BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationFromJSON(
  json: any,
): BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation {
  return BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationFromJSONTyped(
    json,
    false,
  );
}

export function BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    benefitDefinitionId: json["benefit_definition_id"],
    monthlyPayment: json["monthly_payment"],
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    fixedDownPaymentAmount: !exists(json, "fixed_down_payment_amount")
      ? undefined
      : json["fixed_down_payment_amount"],
    totalPackagePrice: json["total_package_price"],
    monthlySalary: json["monthly_salary"],
  };
}

export function BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculationToJSON(
  value?: BodyCalculationsGetFixedMonthlyPaymentWithTaxCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    benefit_definition_id: value.benefitDefinitionId,
    monthly_payment: value.monthlyPayment,
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    fixed_down_payment_amount: value.fixedDownPaymentAmount,
    total_package_price: value.totalPackagePrice,
    monthly_salary: value.monthlySalary,
  };
}
