import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Banner, Typography } from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";

export function NoApprovedBenefitsBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Banner
      variant="warning"
      onButtonClick={() => navigate(`../${Path.benefits}`)}
      buttonText={t("userV2:noApprovedBenefitBanner.button")}
    >
      <Typography weight="bold">
        {t("userV2:noApprovedBenefitBanner.content")}
      </Typography>
    </Banner>
  );
}
