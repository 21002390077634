import {
  ChangeEvent,
  FocusEvent,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import styled from "styled-components";

import { Flex } from "../Flex";
import { CheckboxFillIcon, CheckboxOutlineIcon } from "../Icons";
import { Typography } from "../Typography";

export interface CheckboxProps {
  label?: string | ReactNode;
  id?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  ariaLabel?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  helpText?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    { id, label, disabled, checked, onChange, helpText, onBlur, ...rest },
    ref,
  ) {
    const generatedId = useId();
    const resolvedId = id ?? generatedId;

    const checkboxRef = useRef<HTMLInputElement | null>(null);
    const resolvedRef = (ref as RefObject<HTMLInputElement>) ?? checkboxRef;

    const [isChecked, setIsChecked] = useState(!!checked);

    useEffect(() => {
      if (resolvedRef.current && !disabled) {
        resolvedRef.current.checked = !!checked;
        setIsChecked(resolvedRef.current.checked);
      }
    }, [resolvedRef, checked, disabled]);

    const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      setIsChecked(event.target.checked);
    };

    // Improve checkbox accessibility
    const handleKeyPressOnLabel = (e: React.KeyboardEvent) => {
      if ((e.key === " " || e.key === "Enter") && !disabled) {
        e.preventDefault();
        const checkbox = resolvedRef.current as HTMLInputElement;

        checkbox.checked = !checkbox.checked;
        const event = { target: checkbox } as ChangeEvent<HTMLInputElement>;
        handleChangeEvent(event);
      }
    };

    return (
      <Root
        htmlFor={resolvedId}
        data-testid="checkbox-root"
        onKeyDown={handleKeyPressOnLabel}
        tabIndex={0}
        aria-label={rest?.ariaLabel}
        aria-labelledby={rest?.ariaLabelledby}
        aria-describedby={rest?.ariaDescribedby}
        className={clsx(disabled && "disabled")}
      >
        <HiddenCheckbox
          ref={resolvedRef}
          id={resolvedId}
          disabled={disabled}
          defaultChecked={!!checked}
          onChange={handleChangeEvent}
          onBlur={onBlur}
          {...rest}
        />
        {isChecked ? <CheckboxFillIcon /> : <CheckboxOutlineIcon />}
        <Flex direction="column" gap="xxs">
          {label && (
            <Label data-testid="checkbox-label" bold={!!helpText}>
              {label}
            </Label>
          )}
          {helpText && <Typography>{helpText}</Typography>}
        </Flex>
      </Root>
    );
  },
);

const Root = styled.label`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }
`;

const Label = styled.span<{ bold: boolean }>`
  flex: 1;
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  font-weight: ${({ theme, bold }) =>
    bold
      ? theme.typography.fontWeight.bold
      : theme.typography.bodyMd.fontWeight};
  line-height: ${({ theme }) => theme.typography.bodyMd.lineHeight};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;
