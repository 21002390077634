import { useTranslation } from "react-i18next";

export const usePercentFormat = (
  options?: Omit<Intl.NumberFormatOptions, "style"> | undefined,
) => {
  const { i18n } = useTranslation();
  const percentFormatter = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
    ...(options || {}),
    style: "percent",
  });
  return (value?: number) =>
    value !== undefined ? percentFormatter.format(value) : "-";
};
