/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BodyUserInvoicesPaySaleInvoice,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedSaleInvoiceOut,
  PaymentDetailsRead,
  SaleInvoiceRead,
  SaleInvoiceStatus,
} from "../models/index";
import {
  BodyUserInvoicesPaySaleInvoiceFromJSON,
  BodyUserInvoicesPaySaleInvoiceToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedSaleInvoiceOutFromJSON,
  PaginatedSaleInvoiceOutToJSON,
  PaymentDetailsReadFromJSON,
  PaymentDetailsReadToJSON,
  SaleInvoiceReadFromJSON,
  SaleInvoiceReadToJSON,
  SaleInvoiceStatusFromJSON,
  SaleInvoiceStatusToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserInvoicesGetSaleInvoiceRequest {
  saleInvoiceId: string;
}

export interface UserInvoicesPaySaleInvoiceRequest {
  saleInvoiceId: string;
  bodyUserInvoicesPaySaleInvoice: BodyUserInvoicesPaySaleInvoice;
}

export interface UserInvoicesSearchSaleInvoicesRequest {
  status?: Set<SaleInvoiceStatus>;
  bikeBenefitOrderId?: string;
  bikeBenefitContractId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class UserInvoicesApi extends runtime.BaseAPI {
  /**
   * Get Sale Invoice
   */
  async userInvoicesGetSaleInvoiceRaw(
    requestParameters: UserInvoicesGetSaleInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SaleInvoiceRead>> {
    if (
      requestParameters.saleInvoiceId === null ||
      requestParameters.saleInvoiceId === undefined
    ) {
      throw new runtime.RequiredError(
        "saleInvoiceId",
        "Required parameter requestParameters.saleInvoiceId was null or undefined when calling userInvoicesGetSaleInvoice.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/sale-invoices/{sale_invoice_id}`.replace(
          `{${"sale_invoice_id"}}`,
          encodeURIComponent(String(requestParameters.saleInvoiceId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SaleInvoiceReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Sale Invoice
   */
  async userInvoicesGetSaleInvoice(
    requestParameters: UserInvoicesGetSaleInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SaleInvoiceRead> {
    const response = await this.userInvoicesGetSaleInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Pay Sale Invoice
   */
  async userInvoicesPaySaleInvoiceRaw(
    requestParameters: UserInvoicesPaySaleInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentDetailsRead>> {
    if (
      requestParameters.saleInvoiceId === null ||
      requestParameters.saleInvoiceId === undefined
    ) {
      throw new runtime.RequiredError(
        "saleInvoiceId",
        "Required parameter requestParameters.saleInvoiceId was null or undefined when calling userInvoicesPaySaleInvoice.",
      );
    }

    if (
      requestParameters.bodyUserInvoicesPaySaleInvoice === null ||
      requestParameters.bodyUserInvoicesPaySaleInvoice === undefined
    ) {
      throw new runtime.RequiredError(
        "bodyUserInvoicesPaySaleInvoice",
        "Required parameter requestParameters.bodyUserInvoicesPaySaleInvoice was null or undefined when calling userInvoicesPaySaleInvoice.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/sale-invoices/{sale_invoice_id}/pay`.replace(
          `{${"sale_invoice_id"}}`,
          encodeURIComponent(String(requestParameters.saleInvoiceId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyUserInvoicesPaySaleInvoiceToJSON(
          requestParameters.bodyUserInvoicesPaySaleInvoice,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentDetailsReadFromJSON(jsonValue),
    );
  }

  /**
   * Pay Sale Invoice
   */
  async userInvoicesPaySaleInvoice(
    requestParameters: UserInvoicesPaySaleInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentDetailsRead> {
    const response = await this.userInvoicesPaySaleInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Sale Invoices
   */
  async userInvoicesSearchSaleInvoicesRaw(
    requestParameters: UserInvoicesSearchSaleInvoicesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedSaleInvoiceOut>> {
    const queryParameters: any = {};

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.bikeBenefitOrderId !== undefined) {
      queryParameters["bike_benefit_order_id"] =
        requestParameters.bikeBenefitOrderId;
    }

    if (requestParameters.bikeBenefitContractId !== undefined) {
      queryParameters["bike_benefit_contract_id"] =
        requestParameters.bikeBenefitContractId;
    }

    if (requestParameters.q !== undefined) {
      queryParameters["q"] = requestParameters.q;
    }

    if (requestParameters.ids) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sort_field"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sort_direction"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.xFilterIds !== undefined &&
      requestParameters.xFilterIds !== null
    ) {
      headerParameters["X-Filter-Ids"] = String(requestParameters.xFilterIds);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/sale-invoices`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedSaleInvoiceOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Sale Invoices
   */
  async userInvoicesSearchSaleInvoices(
    requestParameters: UserInvoicesSearchSaleInvoicesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedSaleInvoiceOut> {
    const response = await this.userInvoicesSearchSaleInvoicesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
