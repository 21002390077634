import { useMutation, useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionTermsAcceptBenefitDefinitionTermsRequest,
  BenefitDefinitionTermsApi,
  BenefitDefinitionTermsRead,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const userBenefitDefinitionTermsAPI = new BenefitDefinitionTermsApi(
  apiConfiguration,
);

export const useGetBenefitDefinitionTerms = (
  benefitDefinitionTermsId: string,
) =>
  useQuery<BenefitDefinitionTermsRead, ResponseError>({
    queryKey: ["benefitDefinitionTerms", benefitDefinitionTermsId],
    queryFn: () =>
      userBenefitDefinitionTermsAPI.benefitDefinitionTermsGetBenefitDefinitionTerms(
        {
          benefitDefinitionTermsId,
        },
      ),
    enabled: Boolean(benefitDefinitionTermsId),
  });

export const useAcceptBenefitDefinitionTerms = () =>
  useMutation<
    void,
    ResponseError,
    BenefitDefinitionTermsAcceptBenefitDefinitionTermsRequest
  >((data) =>
    userBenefitDefinitionTermsAPI.benefitDefinitionTermsAcceptBenefitDefinitionTerms(
      data,
    ),
  );
