import { createSvgIcon } from "./SvgIcon";

export const DiamondIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 19.875C11.7167 19.875 11.4417 19.8125 11.175 19.6875C10.9083 19.5625 10.675 19.3833 10.475 19.15L2.825 10C2.675 9.81667 2.5625 9.61667 2.4875 9.4C2.4125 9.18333 2.375 8.95833 2.375 8.725C2.375 8.575 2.3875 8.42083 2.4125 8.2625C2.4375 8.10417 2.49167 7.95833 2.575 7.825L4.45 4.1C4.63333 3.76667 4.87917 3.5 5.1875 3.3C5.49583 3.1 5.84167 3 6.225 3H17.775C18.1583 3 18.5042 3.1 18.8125 3.3C19.1208 3.5 19.3667 3.76667 19.55 4.1L21.425 7.825C21.5083 7.95833 21.5625 8.10417 21.5875 8.2625C21.6125 8.42083 21.625 8.575 21.625 8.725C21.625 8.95833 21.5875 9.18333 21.5125 9.4C21.4375 9.61667 21.325 9.81667 21.175 10L13.525 19.15C13.325 19.3833 13.0917 19.5625 12.825 19.6875C12.5583 19.8125 12.2833 19.875 12 19.875ZM9.625 8H14.375L12.875 5H11.125L9.625 8ZM11 16.675V10H5.45L11 16.675ZM13 16.675L18.55 10H13V16.675ZM16.6 8H19.25L17.75 5H15.1L16.6 8ZM4.75 8H7.4L8.9 5H6.25L4.75 8Z" />
  </svg>,
  "Diamond",
);
