/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { UserRead } from "./UserRead";
import {
  UserReadFromJSON,
  UserReadFromJSONTyped,
  UserReadToJSON,
} from "./UserRead";

/**
 *
 * @export
 * @interface PaginatedUserOut
 */
export interface PaginatedUserOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserOut
   */
  total: number;
  /**
   *
   * @type {Array<UserRead>}
   * @memberof PaginatedUserOut
   */
  items: Array<UserRead>;
}

/**
 * Check if a given object implements the PaginatedUserOut interface.
 */
export function instanceOfPaginatedUserOut(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedUserOutFromJSON(json: any): PaginatedUserOut {
  return PaginatedUserOutFromJSONTyped(json, false);
}

export function PaginatedUserOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedUserOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(UserReadFromJSON),
  };
}

export function PaginatedUserOutToJSON(value?: PaginatedUserOut | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(UserReadToJSON),
  };
}
