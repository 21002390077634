import React from "react";

import styled, { keyframes, useTheme } from "styled-components";

import { Theme } from "../styles";
import { CircularIcon } from "./CircularIcon";

export function AnimatedErrorIcon() {
  const { palette } = useTheme() as Theme;
  return (
    <CircularIcon colour={palette.error}>
      <StyledCrossSvg>
        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
          <StyledFirstLine d="M634.087,300.805L673.361,261.53" fill="none" />
        </g>
        <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
          <StyledSecondLine d="M634.087,300.805L673.361,261.53" />
        </g>
      </StyledCrossSvg>
    </CircularIcon>
  );
}

const StyledCrossSvg = styled.svg`
  stroke: ${({ theme }) => theme.palette.error};
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;
`;

const drawFirstLine = keyframes`
  0% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 56, 330;
    stroke-dashoffset: 0;
  }
`;

const drawSecondLine = keyframes`
  0% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  50% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  100% {
    stroke-dasharray: 55, 0;
    stroke-dashoffset: 70;
  }
`;

const StyledFirstLine = styled.path`
  animation: 0.7s ${drawFirstLine} ease-out;
`;

const StyledSecondLine = styled.path`
  animation: 0.7s ${drawSecondLine} ease-out;
`;
