import { useQuery } from "@tanstack/react-query";

import {
  OrganisationRead,
  ResponseError,
  ShopOrganisationApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

import { UseQueryOptions } from "../types";

const shopOrganisation = new ShopOrganisationApi(apiConfiguration);
// FIXME: This is some shop specific code and should be moved to the shop app
export const useGetOrganisation = (
  id?: string,
  options?: UseQueryOptions<OrganisationRead>,
) =>
  useQuery<OrganisationRead, ResponseError>(
    ["organisations", id],
    () =>
      shopOrganisation.shopOrganisationGetOrganisation({
        organisationId: id as string,
      }),
    { ...options, enabled: !!id },
  );
