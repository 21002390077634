import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { ShopType, UserShopRead } from "@vapaus/api-codegen";
import {
  EmailField,
  Flex,
  Notification,
  PhoneField,
  Typography,
} from "@vapaus/ui-v2";

import { extractHostnameFromUrl } from "../../../../../../../../utils/extractHostnameFromUrl";
import { ShopLogo } from "./ShopLogo";

type GenericModalContentProps = {
  shop?: UserShopRead;
};

export function GenericModalContent({ shop }: GenericModalContentProps) {
  const [activeTab, setActiveTab] = useState<ShopType | undefined>(
    shop?.types?.includes("local_store")
      ? ShopType.LocalStore
      : ShopType.OnlineStore,
  );

  const { t } = useTranslation();

  const { streetAddress, postalCode, city } = shop || {};
  const addressString = [
    streetAddress,
    postalCode && city ? `${postalCode} ${city}` : city,
  ]
    .filter(Boolean)
    .join(", ");

  const hasLocalStore = shop?.types?.includes(ShopType.LocalStore);
  const hasOnlineStore = shop?.types?.includes(ShopType.OnlineStore);

  return (
    <Flex direction="column" align="center" gap="md">
      <Flex direction="column" align="center" gap="xs">
        <ShopLogo logoUrl={shop?.logoUrl} />
        <Typography variant="h2" align="center">
          {shop?.name}
        </Typography>
        {shop?.website ? (
          <a href={shop?.website} target="_blank" rel="noreferrer">
            <Typography>{extractHostnameFromUrl(shop.website)}</Typography>
          </a>
        ) : null}

        {addressString ? (
          <a
            href={`https://www.google.com/maps/place/${addressString}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography>{addressString}</Typography>
          </a>
        ) : null}
      </Flex>
      {shop?.email || shop?.phoneNumber ? (
        <Flex
          smDirection="column"
          align="center"
          justify="center"
          flexWrap="wrap"
        >
          {shop?.email ? <EmailField email={shop.email} gap="xs" /> : null}
          {shop?.phoneNumber ? (
            <PhoneField phoneNumber={shop.phoneNumber} gap="xs" />
          ) : null}
        </Flex>
      ) : null}

      <StyledNotification type="success">
        <StyledTabWrapper align="center" justify="center">
          {hasLocalStore && (
            <StyledTabTextWrapper
              isActive={activeTab === ShopType.LocalStore}
              onClick={() => setActiveTab(ShopType.LocalStore)}
            >
              <Typography weight="bold">
                {t(
                  "userV2:getABike.genericShopModal.localShopInstruction.title",
                )}
              </Typography>
            </StyledTabTextWrapper>
          )}
          {hasOnlineStore && (
            <StyledTabTextWrapper
              isActive={activeTab === ShopType.OnlineStore}
              onClick={() => setActiveTab(ShopType.OnlineStore)}
            >
              <Typography weight="bold">
                {t(
                  "userV2:getABike.genericShopModal.onlineShopInstruction.title",
                )}
              </Typography>
            </StyledTabTextWrapper>
          )}
        </StyledTabWrapper>
        <Typography align="center">
          {activeTab === ShopType.LocalStore &&
            t(
              "userV2:getABike.genericShopModal.localShopInstruction.description",
            )}
          {activeTab === ShopType.OnlineStore &&
            t(
              "userV2:getABike.genericShopModal.onlineShopInstruction.description",
            )}
        </Typography>
      </StyledNotification>
    </Flex>
  );
}

const StyledNotification: typeof Notification = styled(Notification)`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
`;

const StyledTabWrapper = styled(Flex)`
  border-bottom: ${({ theme }) => theme.border.primary.light};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const StyledTabTextWrapper = styled.div<{
  isActive: boolean;
}>`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-bottom: ${theme.border.primary.main};
    `}
`;
