/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { UserBenefitDefinitionAccessRequestRead } from "./UserBenefitDefinitionAccessRequestRead";
import {
  UserBenefitDefinitionAccessRequestReadFromJSON,
  UserBenefitDefinitionAccessRequestReadFromJSONTyped,
  UserBenefitDefinitionAccessRequestReadToJSON,
} from "./UserBenefitDefinitionAccessRequestRead";

/**
 *
 * @export
 * @interface PaginatedUserBenefitDefinitionAccessRequestOut
 */
export interface PaginatedUserBenefitDefinitionAccessRequestOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserBenefitDefinitionAccessRequestOut
   */
  total: number;
  /**
   *
   * @type {Array<UserBenefitDefinitionAccessRequestRead>}
   * @memberof PaginatedUserBenefitDefinitionAccessRequestOut
   */
  items: Array<UserBenefitDefinitionAccessRequestRead>;
}

/**
 * Check if a given object implements the PaginatedUserBenefitDefinitionAccessRequestOut interface.
 */
export function instanceOfPaginatedUserBenefitDefinitionAccessRequestOut(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "items" in value;

  return isInstance;
}

export function PaginatedUserBenefitDefinitionAccessRequestOutFromJSON(
  json: any,
): PaginatedUserBenefitDefinitionAccessRequestOut {
  return PaginatedUserBenefitDefinitionAccessRequestOutFromJSONTyped(
    json,
    false,
  );
}

export function PaginatedUserBenefitDefinitionAccessRequestOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedUserBenefitDefinitionAccessRequestOut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(
      UserBenefitDefinitionAccessRequestReadFromJSON,
    ),
  };
}

export function PaginatedUserBenefitDefinitionAccessRequestOutToJSON(
  value?: PaginatedUserBenefitDefinitionAccessRequestOut | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    items: (value.items as Array<any>).map(
      UserBenefitDefinitionAccessRequestReadToJSON,
    ),
  };
}
