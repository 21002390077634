import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";

import { UserEmailRead } from "@vapaus/api-codegen";
import { useDeleteUserEmail } from "@vapaus/shared-api";
import { Modal, TextButton, Typography, useToaster } from "@vapaus/ui-v2";

type DeleteEmailButtonProps = {
  readonly email: UserEmailRead;
};

export function DeleteEmailButton({ email }: DeleteEmailButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);

  const deleteEmail = useDeleteUserEmail();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    deleteEmail.mutate(email.id, {
      onSuccess: () => {
        handleCloseModal();
        toaster.add(
          "success",
          t("commonV2:alternateEmail.deleteEmailModal.successMessage"),
        );
        queryClient.invalidateQueries(["currentUserEmails"]);
      },
      onError: async (error) => {
        const message = await error.response.json();
        toaster.add("error", message.detail);
      },
    });
  };

  return (
    <>
      <TextButton onClick={() => setIsOpen(true)} size="md">
        {t("commonV2:delete")}
      </TextButton>

      <Modal
        isOpen={isOpen}
        title={t("commonV2:alternateEmail.deleteEmailModal.title")}
        cancelText={t("commonV2:cancel")}
        confirmText={t("commonV2:alternateEmail.deleteEmailModal.deleteMail")}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        isSubmitting={deleteEmail.isLoading}
        confirmButtonVariant="primaryDanger"
      >
        <Typography align="center" variant="bodyLg">
          <Trans
            t={t}
            i18nKey="commonV2:alternateEmail.deleteEmailModal.description"
            values={{ email: email.email }}
            components={{ 1: <strong /> }}
          />
        </Typography>
      </Modal>
    </>
  );
}
