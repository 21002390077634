import { ReactNode } from "react";

import styled from "styled-components";

import { Flex, Typography } from "@vapaus/ui-v2";

type PageViewLayoutProps = {
  title: string;
  description: ReactNode;
  children: ReactNode;
};

export function WizardPageViewLayout({
  title,
  description,
  children,
}: PageViewLayoutProps) {
  return (
    <Flex align="flex-start" justify="center" fullWidth fullHeight>
      <StyledRoot
        direction="column"
        align="center"
        gap="xl"
        padding="xl"
        xsPadding="md"
      >
        <Flex direction="column" align="center">
          <Typography variant="h1" align="center">
            {title}
          </Typography>
          <StyledDescription variant="bodyLg" align="center">
            {description}
          </StyledDescription>
        </Flex>
        <StyledContentWrapper
          direction="column"
          align="center"
          justify="center"
        >
          {children}
        </StyledContentWrapper>
      </StyledRoot>
    </Flex>
  );
}

const StyledRoot = styled(Flex)`
  width: 800px;
  max-width: 100%;
`;

const StyledContentWrapper = styled(Flex)`
  width: 600px;
  max-width: 100%;
`;

const StyledDescription = styled(Typography)`
  width: 100%;
  max-width: 600px;
`;
