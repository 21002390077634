import { ReactNode } from "react";

import styled from "styled-components";

import { Flex } from "../Flex";
import { Separator } from "../Separator";
import { Typography } from "../Typography";

export interface CardRowProps {
  title: string;
  description: string;
  action: ReactNode;
  separator?: boolean;
}

export function CardRow({
  description,
  title,
  action,
  separator,
}: CardRowProps) {
  return (
    <div>
      <Flex justify="space-between" xsDirection="column" xsAlign="stretch">
        <div>
          <Typography weight="bold" marginBottom="xs">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </div>
        <div>{action}</div>
      </Flex>
      {separator && <StyledSeparator data-testid="separator" />}
    </div>
  );
}

const StyledSeparator = styled(Separator)`
  margin: ${({ theme }) => theme.spacing.md} 0;
`;
