import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useGetContract, useGetOrder } from "../../../api/benefit";
import { TabPanelContract } from "./TabPanelContract";
import { TabPanelOrder } from "./TabPanelOrder";

export function TabPanelOrderOrContract() {
  const { orderId } = useParams();
  invariant(orderId);

  const { data: order } = useGetOrder(orderId);
  const { data: contract, status: contractStatus } = useGetContract(
    order?.currentBikeBenefitContractId,
  );

  if (
    (order?.currentBikeBenefitContractId &&
      !["error", "success"].includes(contractStatus)) ||
    !order
  )
    return null;

  return contract ? (
    <TabPanelContract order={order} contract={contract} />
  ) : (
    <TabPanelOrder order={order} />
  );
}
