import { useGetBenefitDefinitionsActivations } from "../../api/benefitDefinitions";

export function useNoApprovedBenefitsBanner() {
  const { data: activations, isLoading: isLoadingActivations } =
    useGetBenefitDefinitionsActivations();

  const shouldShowNoApprovedBenefitsBanner =
    !isLoadingActivations && activations?.total === 0;

  return {
    shouldShowNoApprovedBenefitsBanner,
  };
}
