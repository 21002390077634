import { useEffect, useRef, useState } from "react";

import { animated } from "@react-spring/web";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { usePageTransition } from "@vapaus/common-v2";
import { Flex, Modal, ModalNavBar, ProgressBar } from "@vapaus/ui-v2";

import { EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY } from "../../../../../constants/emailVerification";
import { EmailAddressView } from "../../RedeemReturnSharedComponents/RedeemReturnEmailAddressView";
import { PaymentProcessingView } from "../../RedeemReturnSharedComponents/RedeemReturnPaymentProcessing";
import { useRedemptionProcessContext } from "../context/RedemptionProcessContext";
import { PriceConfirmationView } from "./pages/PriceConfirmation";
import { RedeemBikeWelcomeView } from "./pages/RedeemBikeWelcome";
import { RedemptionReasonView } from "./pages/RedemptionReason";

type RedemptionProcessWizardViewProps = {
  onClose: () => void;
};

export function RedemptionProcessWizardView({
  onClose,
}: RedemptionProcessWizardViewProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isExitConfirmationModalOpen, setIsExitConfirmationModalOpen] =
    useState<boolean>(false);
  const { step, onNext, onPrevious, stateMatches } =
    useRedemptionProcessContext();
  const REDEEM_BIKE_WIZARD_STEPS_TOTAL = 5;
  const WIZARD_STARTUP_STEP = 1;
  const [isTransitionFinished, setIsTransitionFinished] =
    useState<boolean>(true);

  const transitions = usePageTransition({
    current: step,
    startUpStep: WIZARD_STARTUP_STEP,
    onStart: () => setIsTransitionFinished(false),
    onFinished: () => setIsTransitionFinished(true),
  });
  const containerDivRef = useRef<HTMLDivElement>(null);

  const onBack = step > WIZARD_STARTUP_STEP ? onPrevious : undefined;

  useEffect(() => {
    if (containerDivRef.current) {
      containerDivRef.current.scrollTo({ top: 0 });
    }
  }, [step]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const emailVerificationResumePath =
    location.pathname + "?" + EMAIL_VERIFICATION_REDEMPTION_PROCESS_KEY;

  return (
    <StyledRoot
      data-testid="redemption-process-wizard-modal"
      direction="column"
      gap="none"
    >
      {stateMatches("paymentProcessing") ? null : (
        <ModalNavBar
          bgColor="common.transparent"
          hasBorder={false}
          title={
            <Flex align="center" justify="center">
              <StyledProgressBarContainer>
                <ProgressBar
                  progressPercentage={
                    (100 * step) / REDEEM_BIKE_WIZARD_STEPS_TOTAL
                  }
                />
              </StyledProgressBarContainer>
            </Flex>
          }
          onClose={() => setIsExitConfirmationModalOpen(true)}
          onBack={onBack}
          backEnabled={onBack && isTransitionFinished}
        />
      )}
      <StyledContent ref={containerDivRef} fullWidth fullHeight>
        {stateMatches("paymentProcessing") ? (
          <PaymentProcessingView cancellationType="redemption" />
        ) : (
          <>
            {transitions((style) => (
              <animated.div style={{ ...style, width: "100%", height: "100%" }}>
                {stateMatches("welcome") ? <RedeemBikeWelcomeView /> : null}
                {stateMatches("redemptionReason") ? (
                  <RedemptionReasonView />
                ) : null}
                {stateMatches("emailAddress") ? (
                  <EmailAddressView
                    onNext={onNext}
                    emailVerificationResumePath={emailVerificationResumePath}
                  />
                ) : null}
                {stateMatches("priceConfirmation") ? (
                  <PriceConfirmationView />
                ) : null}
              </animated.div>
            ))}
          </>
        )}
      </StyledContent>
      <Modal
        isOpen={isExitConfirmationModalOpen}
        title={t("userV2:redemptionProcess.exitConfirmationModal.title")}
        description={t(
          "userV2:redemptionProcess.exitConfirmationModal.description",
        )}
        confirmText={t("userV2:redemptionProcess.exitConfirmationModal.title")}
        cancelText={t("commonV2:goBack")}
        onClose={() => setIsExitConfirmationModalOpen(false)}
        onCancel={() => setIsExitConfirmationModalOpen(false)}
        onConfirm={onClose}
      />
    </StyledRoot>
  );
}

const StyledProgressBarContainer = styled.div`
  width: 12.5rem;
`;

const StyledRoot = styled(Flex)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.background.secondary};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const StyledContent = styled(Flex)`
  overflow-y: scroll;
  overflow-x: hidden;
`;
