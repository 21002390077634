import { get } from "lodash-es";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import {
  Select as UISelect,
  SelectProps as UISelectProps,
} from "@vapaus/ui-v2";

export type SelectProps<TValue extends string | number> = Omit<
  UISelectProps<TValue, false>,
  "error" | "pattern"
> &
  Pick<RegisterOptions, "validate" | "pattern"> & {
    name: string;
  };

export function Select<TValue extends string | number>({
  name,
  required,
  validate,
  value,
  onChange,
  onBlur,
  pattern,
  options,
  optionGroups,
  ...rest
}: SelectProps<TValue>) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string | undefined;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required, validate, pattern, value }}
      render={({ field }) => (
        <UISelect
          value={field.value}
          onChange={(_, value) => field.onChange(value)}
          onBlur={field.onBlur}
          name={field.name}
          error={errorMessage}
          required={required}
          {...(optionGroups
            ? { optionGroups: optionGroups!, options: undefined }
            : { options: options!, optionGroups: undefined })}
          {...rest}
        />
      )}
    />
  );
}
