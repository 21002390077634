import { useMutation, useQuery } from "@tanstack/react-query";

import {
  RedemptionProcessCalculation,
  RedemptionProcessCreate,
  RedemptionProcessRead,
  ResponseError,
  UserRedemptionProcessesApi,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

const userRedemptionProcessesApi = new UserRedemptionProcessesApi(
  apiConfiguration,
);

export const useGetRedemptionProcess = (
  redemptionProcessId?: string,
  options?: UseQueryOptions<RedemptionProcessRead>,
) =>
  useQuery<RedemptionProcessRead, ResponseError>(
    ["redemptionProcesses", redemptionProcessId],
    () =>
      userRedemptionProcessesApi.userRedemptionProcessesGetRedemptionProcess({
        redemptionProcessId: redemptionProcessId as string,
      }),
    { ...options, enabled: !!redemptionProcessId },
  );

export const useGetContractRedemptionCalculation = (
  bikeBenefitContractId?: string,
  isEndOfLeaseRedemption?: boolean,
  options?: UseQueryOptions<RedemptionProcessCalculation>,
) =>
  useQuery<RedemptionProcessCalculation, ResponseError>(
    ["redemptionProcessCalculation", bikeBenefitContractId],
    () =>
      userRedemptionProcessesApi.userRedemptionProcessesGetRedemptionProcessCalculation(
        {
          bikeBenefitContractId: bikeBenefitContractId as string,
          isEndOfLeaseRedemption: Boolean(isEndOfLeaseRedemption),
        },
      ),
    options,
  );

export const useCreateRedemptionProcess = () =>
  useMutation<RedemptionProcessRead, ResponseError, RedemptionProcessCreate>(
    (data) =>
      userRedemptionProcessesApi.userRedemptionProcessesCreateRedemptionProcess(
        {
          redemptionProcessCreate: data,
        },
      ),
  );
