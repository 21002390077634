import React, { ReactNode } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Popper } from "@mui/base/Popper";

type PopupWrapperProps = {
  triggerElement: HTMLElement | null;
  onOutsideClick: () => void;
  children: ReactNode;
};

export function PopupWrapper({
  triggerElement,
  onOutsideClick,
  children,
}: PopupWrapperProps) {
  const isOpen = Boolean(triggerElement);

  return (
    <ClickAwayListener onClickAway={onOutsideClick}>
      <Popper
        open={isOpen}
        anchorEl={triggerElement}
        placement={"bottom-end"}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        {children}
      </Popper>
    </ClickAwayListener>
  );
}
