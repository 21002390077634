import { useEffect, useState } from "react";

import {
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";

import { Input, Select } from "@vapaus/ui-v2";

import { useReturnProcessContext } from "../../../context/ReturnProcessContext";
import { PickupAddressDetailsForm } from "../ChoosePickupDetailsView";

type CitySelectProps = {
  register: UseFormRegister<PickupAddressDetailsForm>;
  setValue: UseFormSetValue<PickupAddressDetailsForm>;
};

export function CitySelect({ register, setValue }: CitySelectProps) {
  const { t } = useTranslation();
  const { setPickupAddressDetails, pickupAddressDetails } =
    useReturnProcessContext();

  const helsinkiAreaCities = ["Helsinki", "Espoo", "Vantaa", "Kauniainen"];
  const citySelectionOptions = [
    ...helsinkiAreaCities.map((city) => ({
      label: city,
      value: city,
    })),
    {
      label: t("userV2:returnProcess.choosePickupDetails.city.other.value"),
      value: "other",
    },
  ];
  const [selectedCity, setSelectedCity] = useState<string>(
    pickupAddressDetails.city &&
      !helsinkiAreaCities.includes(pickupAddressDetails.city)
      ? "other"
      : pickupAddressDetails.city ?? "",
  );

  const shouldTriggerSelectChange =
    (selectedCity === "other" &&
      helsinkiAreaCities.includes(pickupAddressDetails.city ?? "")) ||
    (selectedCity !== "other" && selectedCity !== pickupAddressDetails.city);

  useEffect(() => {
    if (shouldTriggerSelectChange) {
      if (selectedCity !== "other") {
        setPickupAddressDetails({ city: selectedCity });
        return;
      }
      setPickupAddressDetails({ city: undefined });
      setValue("city", undefined);
    }
  }, [selectedCity]);

  return pickupAddressDetails.country === "FI" ? (
    <>
      <Select
        options={citySelectionOptions}
        label={t("userV2:returnProcess.choosePickupDetails.city.label")}
        placeholder={t(
          "userV2:returnProcess.choosePickupDetails.city.placeholder",
        )}
        required
        name="selectedCity"
        value={selectedCity}
        onChange={(_, value) => setSelectedCity(value as string)}
        fullWidth
      />
      {selectedCity === "other" ? (
        <Input
          fullWidth
          placeholder={t(
            "userV2:returnProcess.choosePickupDetails.city.other.placeholder",
          )}
          {...register("city", {
            onChange: (e) => setPickupAddressDetails({ city: e.target.value }),
          })}
        />
      ) : null}
    </>
  ) : (
    <Input
      fullWidth
      label={t("userV2:returnProcess.choosePickupDetails.city.label")}
      placeholder={t(
        "userV2:returnProcess.choosePickupDetails.city.other.placeholder",
      )}
      {...register("city", {
        onChange: (e) => setPickupAddressDetails({ city: e.target.value }),
        required: true,
      })}
    />
  );
}
