import { ReactNode } from "react";

import styled from "styled-components";

import { Flex } from "../Flex";
import { TextButton } from "../TextButton";
import { Typography } from "../Typography";

type LinkAction = {
  label: string;
  to: string;
  onClick?: never;
};

type ClickAction = {
  label: string;
  onClick: () => void;
  to?: never;
};

export type LineItemProps = {
  name: string;
  content?: ReactNode;
  action?: LinkAction | ClickAction;
};

export function LineItem({ name, content, action }: LineItemProps): ReactNode {
  return (
    <StyledRoot>
      <Flex direction="column" gap="xxs" fullHeight fullWidth>
        <Typography weight="bold">{name}</Typography>
        <div>{content}</div>
      </Flex>
      {action && (
        <TextButton
          {...(action?.onClick
            ? { onClick: action.onClick }
            : { to: action.to })}
          size="md"
        >
          {action.label}
        </TextButton>
      )}
    </StyledRoot>
  );
}

const StyledRoot = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const StyledMainContent = styled.div`
  flex: 1;
`;
