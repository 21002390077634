import { createSvgIcon } from "./SvgIcon";

export const EcoIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M5.4 19.6001C4.65 18.8501 4.0625 17.9835 3.6375 17.0001C3.2125 16.0168 3 15.0001 3 13.9501C3 12.9001 3.2 11.8626 3.6 10.8376C4 9.81262 4.65 8.85012 5.55 7.95012C6.13333 7.36679 6.85417 6.86679 7.7125 6.45012C8.57083 6.03345 9.5875 5.70429 10.7625 5.46262C11.9375 5.22095 13.2792 5.07512 14.7875 5.02512C16.2958 4.97512 17.9833 5.03345 19.85 5.20012C19.9833 6.96679 20.025 8.59179 19.975 10.0751C19.925 11.5585 19.7875 12.896 19.5625 14.0876C19.3375 15.2793 19.0208 16.321 18.6125 17.2126C18.2042 18.1043 17.7 18.8501 17.1 19.4501C16.2167 20.3335 15.2792 20.9793 14.2875 21.3876C13.2958 21.796 12.2833 22.0001 11.25 22.0001C10.1667 22.0001 9.10833 21.7876 8.075 21.3626C7.04167 20.9376 6.15 20.3501 5.4 19.6001ZM8.2 19.2001C8.68333 19.4835 9.17917 19.6876 9.6875 19.8126C10.1958 19.9376 10.7167 20.0001 11.25 20.0001C12.0167 20.0001 12.775 19.846 13.525 19.5376C14.275 19.2293 14.9917 18.7335 15.675 18.0501C15.975 17.7501 16.2792 17.3293 16.5875 16.7876C16.8958 16.246 17.1625 15.5376 17.3875 14.6626C17.6125 13.7876 17.7833 12.7293 17.9 11.4876C18.0167 10.246 18.0333 8.76679 17.95 7.05012C17.1333 7.01679 16.2125 7.00429 15.1875 7.01262C14.1625 7.02095 13.1417 7.10012 12.125 7.25012C11.1083 7.40012 10.1417 7.64179 9.225 7.97512C8.30833 8.30845 7.55833 8.76679 6.975 9.35012C6.225 10.1001 5.70833 10.8418 5.425 11.5751C5.14167 12.3085 5 13.0168 5 13.7001C5 14.6835 5.1875 15.546 5.5625 16.2876C5.9375 17.0293 6.26667 17.5501 6.55 17.8501C7.25 16.5168 8.175 15.2376 9.325 14.0126C10.475 12.7876 11.8167 11.7835 13.35 11.0001C12.15 12.0501 11.1042 13.2376 10.2125 14.5626C9.32083 15.8876 8.65 17.4335 8.2 19.2001Z" />
  </svg>,
  "Eco",
);
