/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
} from "./PaymentServiceProvider";

/**
 *
 * @export
 * @interface BodyUserInvoicesPaySaleInvoice
 */
export interface BodyUserInvoicesPaySaleInvoice {
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof BodyUserInvoicesPaySaleInvoice
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {string}
   * @memberof BodyUserInvoicesPaySaleInvoice
   */
  returnUrl: string;
}

/**
 * Check if a given object implements the BodyUserInvoicesPaySaleInvoice interface.
 */
export function instanceOfBodyUserInvoicesPaySaleInvoice(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "paymentServiceProvider" in value;
  isInstance = isInstance && "returnUrl" in value;

  return isInstance;
}

export function BodyUserInvoicesPaySaleInvoiceFromJSON(
  json: any,
): BodyUserInvoicesPaySaleInvoice {
  return BodyUserInvoicesPaySaleInvoiceFromJSONTyped(json, false);
}

export function BodyUserInvoicesPaySaleInvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyUserInvoicesPaySaleInvoice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    returnUrl: json["return_url"],
  };
}

export function BodyUserInvoicesPaySaleInvoiceToJSON(
  value?: BodyUserInvoicesPaySaleInvoice | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payment_service_provider: PaymentServiceProviderToJSON(
      value.paymentServiceProvider,
    ),
    return_url: value.returnUrl,
  };
}
