import { Navigate, Outlet } from "react-router-dom";

import { createAuthRoutes } from "@vapaus/auth-v2";
import { AccountSettingsCard, UserProfileCard } from "@vapaus/settings";

import { Navigation } from "./components/Navigation/Navigation";
import { OnboardingChecker } from "./components/OnboardingChecker";
import { Path } from "./constants/paths";
import { benefitRoutes } from "./pages/BenefitPage";
import { BenefitsPage } from "./pages/BenefitsPage/BenefitsPage";
import { CalculatorPage } from "./pages/CalculatorPage/CalculatorPage";
import { getABikeRoutes } from "./pages/GetABikePage";
import { myBikeRoutes } from "./pages/MyBikePage";
import { MyBikesPage } from "./pages/MyBikesPage";
import { OnboardingWizard } from "./pages/OnboardingWizard/stateMachine/OnboardingWizard";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { UserBillingSection } from "./pages/SettingsPage/UserBillingSection";
import { SupportPage } from "./pages/SupportPage";

export const createRoutes = () =>
  createAuthRoutes(
    {
      allowSignUp: true,
      app: "user",
    },
    [
      {
        element: <OnboardingChecker />,
        children: [
          {
            element: <Navigation />,
            children: [
              {
                index: true,
                element: <Navigate to={Path.myBikes} replace />,
              },
              {
                path: Path.myBikes,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <MyBikesPage />,
                  },
                  myBikeRoutes,
                ],
              },
              getABikeRoutes,
              {
                path: Path.calculator,
                element: <CalculatorPage />,
              },
              {
                path: Path.benefits,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <BenefitsPage />,
                  },
                  ...benefitRoutes,
                ],
              },
              {
                path: Path.profile,
                element: <SettingsPage />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={Path.userProfile} />,
                  },
                  {
                    path: Path.userProfile,
                    element: <UserProfileCard />,
                  },
                  {
                    path: Path.billing,
                    element: <UserBillingSection />,
                  },
                  {
                    path: Path.settings,
                    element: <AccountSettingsCard />,
                  },
                ],
              },
              {
                path: Path.support,
                element: <SupportPage />,
              },
            ],
          },
        ],
      },
      {
        path: Path.onboarding,
        element: <OnboardingWizard machineType="onboard" />,
      },
      {
        path: Path.addNewBenefit,
        element: <OnboardingWizard machineType="addNewBenefit" />,
      },
    ],
  );
