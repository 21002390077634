/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";

/**
 *
 * @export
 * @interface SaleInvoiceTransaction
 */
export interface SaleInvoiceTransaction {
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  saleInvoiceId: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  paymentId?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  date: Date;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceTransaction
   */
  amount: number;
  /**
   *
   * @type {Currency}
   * @memberof SaleInvoiceTransaction
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceTransaction
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceTransaction
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the SaleInvoiceTransaction interface.
 */
export function instanceOfSaleInvoiceTransaction(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "saleInvoiceId" in value;
  isInstance = isInstance && "date" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "currency" in value;

  return isInstance;
}

export function SaleInvoiceTransactionFromJSON(
  json: any,
): SaleInvoiceTransaction {
  return SaleInvoiceTransactionFromJSONTyped(json, false);
}

export function SaleInvoiceTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceTransaction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    saleInvoiceId: json["sale_invoice_id"],
    paymentId: !exists(json, "payment_id") ? undefined : json["payment_id"],
    date: new Date(json["date"]),
    amount: json["amount"],
    currency: CurrencyFromJSON(json["currency"]),
    description: !exists(json, "description") ? undefined : json["description"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function SaleInvoiceTransactionToJSON(
  value?: SaleInvoiceTransaction | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sale_invoice_id: value.saleInvoiceId,
    payment_id: value.paymentId,
    date: value.date.toISOString().substring(0, 10),
    amount: value.amount,
    currency: CurrencyToJSON(value.currency),
    description: value.description,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
