import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { useEnumEntries } from "@vapaus/shared-api";
import { Button, Flex, Input, RowItem, Spinner } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../../../../packages/i18n/src/hooks";
import { WizardPageViewLayout } from "../../../WizardPageViewLayout";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";
import { PickupAddressDetails } from "../../machines/types";
import { CitySelect } from "./components/CitySelect";
import { useDeliveryFee } from "./hooks/useDeliveryFee";

export type PickupAddressDetailsForm =
  | Omit<PickupAddressDetails, "country">
  | { country: string };

export function ChoosePickupDetailsView() {
  const { t } = useTranslation();
  const { getEnumLabel: getCountryLabel } = useEnumEntries(
    "SupportedCountriesEnum",
  );

  const {
    onNext,
    pickupAddressDetails,
    setPickupAddressDetails,
    preliminaryData,
    currency,
  } = useReturnProcessContext();
  const formatDate = useDateFormat();
  const formatCurrency = useCurrencyFormat(currency);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<PickupAddressDetailsForm>({
    defaultValues: {
      ...pickupAddressDetails,
      country: getCountryLabel(pickupAddressDetails.country),
    },
  });

  const { deliveryFee, isFetching } = useDeliveryFee();

  return (
    <WizardPageViewLayout
      title={t("userV2:returnProcess.choosePickupDetails.title")}
      description={
        <Trans
          i18nKey="userV2:returnProcess.choosePickupDetails.description"
          values={{ date: formatDate(preliminaryData?.leaseEndDate) }}
          components={{ b: <b /> }}
        />
      }
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(onNext)}>
          <Flex direction="column" gap="md" align="center">
            <Input
              fullWidth
              placeholder={t(
                "userV2:returnProcess.choosePickupDetails.address.placeholder",
              )}
              label={t(
                "userV2:returnProcess.choosePickupDetails.address.label",
              )}
              {...register("address", {
                required: true,
                onChange: (e) =>
                  setPickupAddressDetails({ address: e.target.value }),
              })}
            />
            <Input
              fullWidth
              placeholder={t(
                "userV2:returnProcess.choosePickupDetails.postalCode.placeholder",
              )}
              label={t(
                "userV2:returnProcess.choosePickupDetails.postalCode.label",
              )}
              {...register("postalCode", {
                required: true,
                onChange: (e) =>
                  setPickupAddressDetails({ postalCode: e.target.value }),
              })}
            />
            <CitySelect register={register} setValue={setValue} />
            <Input
              fullWidth
              label={t("userV2:returnProcess.choosePickupDetails.country")}
              {...register("country", {
                required: true,
              })}
              disabled
            />
            <RowItemContainer>
              <RowItem
                label={t(
                  "userV2:returnProcess.choosePickupDetails.pickupPrice",
                )}
                value={isFetching ? <Spinner /> : formatCurrency(deliveryFee)}
                labelIsBold
                valueIsBold
              />
            </RowItemContainer>
            <Button
              type="submit"
              fullWidth
              disabled={!isValid || !pickupAddressDetails.city}
            >
              {t("commonV2:next")}
            </Button>
          </Flex>
        </form>
      </StyledFormContainer>
    </WizardPageViewLayout>
  );
}

const StyledFormContainer = styled.div`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      max-width: 24rem;
      width: 100%;
    `)}
`;

const RowItemContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.xs} 0;
`;
