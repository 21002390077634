import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Select, TextButton, Typography } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function SelectEmployerPage() {
  const { t } = useTranslation();
  const {
    employers,
    selectedEmployer,
    setEmployer,
    goToNext,
    goToAddNewEmployer,
  } = useOnboardingMachine();

  const organisations = employers.map((employer) => ({
    value: employer.id,
    label: employer.name,
  }));

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.selectEmployer.title")}
      subtitle={t("userV2:onboarding.selectEmployer.subtitle")}
    >
      <StyledContainer direction="column" gap="md">
        <Select
          options={organisations}
          required
          label={t("userV2:onboarding.selectEmployer.label")}
          tooltip={t("userV2:onboarding.selectEmployer.tooltip")}
          placeholder={t("userV2:onboarding.selectEmployer.placeholder")}
          value={selectedEmployer?.id}
          onChange={(_, value) => setEmployer(value!)}
          fullWidth
        />
        <Button fullWidth onClick={goToNext} disabled={!selectedEmployer}>
          {t("userV2:onboarding.next")}
        </Button>
        <Flex direction="column" gap="xs" align="center">
          <Typography>
            {t("userV2:onboarding.selectEmployer.dontSeeYourEmployer")}
          </Typography>
          <TextButton size="md" onClick={goToAddNewEmployer}>
            {t("userV2:onboarding.selectEmployer.addDifferentEmployer")}
          </TextButton>
        </Flex>
      </StyledContainer>
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled(Flex)`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
