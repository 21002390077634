/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Payer } from "./Payer";
import { PayerFromJSON, PayerFromJSONTyped, PayerToJSON } from "./Payer";
import type { SaleInvoiceLineCreate } from "./SaleInvoiceLineCreate";
import {
  SaleInvoiceLineCreateFromJSON,
  SaleInvoiceLineCreateFromJSONTyped,
  SaleInvoiceLineCreateToJSON,
} from "./SaleInvoiceLineCreate";

/**
 *
 * @export
 * @interface ReturnProcessInvoicingAction
 */
export interface ReturnProcessInvoicingAction {
  /**
   *
   * @type {SaleInvoiceLineCreate}
   * @memberof ReturnProcessInvoicingAction
   */
  lineIn: SaleInvoiceLineCreate;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessInvoicingAction
   */
  notes: string;
  /**
   *
   * @type {Payer}
   * @memberof ReturnProcessInvoicingAction
   */
  payer: Payer;
}

/**
 * Check if a given object implements the ReturnProcessInvoicingAction interface.
 */
export function instanceOfReturnProcessInvoicingAction(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "lineIn" in value;
  isInstance = isInstance && "notes" in value;
  isInstance = isInstance && "payer" in value;

  return isInstance;
}

export function ReturnProcessInvoicingActionFromJSON(
  json: any,
): ReturnProcessInvoicingAction {
  return ReturnProcessInvoicingActionFromJSONTyped(json, false);
}

export function ReturnProcessInvoicingActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessInvoicingAction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lineIn: SaleInvoiceLineCreateFromJSON(json["line_in"]),
    notes: json["notes"],
    payer: PayerFromJSON(json["payer"]),
  };
}

export function ReturnProcessInvoicingActionToJSON(
  value?: ReturnProcessInvoicingAction | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    line_in: SaleInvoiceLineCreateToJSON(value.lineIn),
    notes: value.notes,
    payer: PayerToJSON(value.payer),
  };
}
