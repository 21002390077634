import { flow, map, uniqBy } from "lodash-es";

import { BenefitDefinitionCandidate } from "@vapaus/api-codegen";

import {
  BenefitDefinitionCandidateWithPendingStatus,
  Context,
  OrganisationSummaryReadWithAvailability,
} from "./types";

export const filterUniqueEmployers = (
  candidates: BenefitDefinitionCandidateWithPendingStatus[],
): OrganisationSummaryReadWithAvailability[] =>
  flow(
    (benefits) => map(benefits, "organisation"),
    (organisations) => uniqBy(organisations, "id"),
  )(candidates);

export const standardizeCandidates = (
  context: Context,
  candidates: BenefitDefinitionCandidate[],
): BenefitDefinitionCandidateWithPendingStatus[] =>
  map(candidates, (candidate) => ({
    ...candidate,
    isPendingRequest: context.benefitWithPendingRequestIds.includes(
      candidate.id,
    ),
  }));
