import { ComponentType, useState } from "react";

import { animated, useSpring } from "@react-spring/web";

import { SvgIconProps } from "../../Icons";
import { DoneIcon } from "../../Icons/DoneIcon";
import { Typography } from "../../Typography";
import { DropdownItemProps } from "../DropdownItem";
import { StyledDropdownItemRoot } from "../StyledDropdownItemRoot";

export type SelectableDropdownItemProps = DropdownItemProps & {
  selected?: boolean;
  onHoverAdornment?: ComponentType<SvgIconProps>;
};

export const SelectableDropdownItem = ({
  icon: Icon,
  label,
  disabled = false,
  onClick,
  selected = false,
  onHoverAdornment: Adornment,
}: SelectableDropdownItemProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const shouldRenderSelectedIcon =
    selected && (!isHovered || disabled || !Adornment);
  const shouldRenderOnHoverAdornment =
    Adornment && !disabled && (!selected || isHovered);

  const selectedIconSpringStyle = useSpring({
    opacity: isHovered ? 0 : 1,
  });
  const onHoverAdornmentSpringStyle = useSpring({
    opacity: isHovered ? 1 : 0,
  });

  return (
    <StyledDropdownItemRoot
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      data-testid="selectable-dropdown-item-root"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Icon && <Icon />}
      <Typography variant="bodyMd" weight="bold" fullWidth>
        {label}
      </Typography>
      {shouldRenderSelectedIcon && (
        <animated.span style={selectedIconSpringStyle}>
          <DoneIcon />
        </animated.span>
      )}
      {shouldRenderOnHoverAdornment && (
        <animated.span style={onHoverAdornmentSpringStyle}>
          <Adornment />
        </animated.span>
      )}
    </StyledDropdownItemRoot>
  );
};
