import { ChangeEvent, useCallback, useState } from "react";

import { v4 as uuid } from "uuid";

import { StorageObjectRead } from "@vapaus/api-codegen";
import { parseApiError } from "@vapaus/shared-api";
import { useToaster } from "@vapaus/ui-v2";

import { usePostReturnProcessUploadPicture } from "../../../../../../../api/returnProcess";
import { useReturnProcessContext } from "../../../context/ReturnProcessContext";

export function useUploadImages() {
  const { images, setImages } = useReturnProcessContext();
  const [loadingImagesIds, setLoadingImagesIds] = useState<string[]>([]);
  const toaster = useToaster();

  const { mutateAsync } = usePostReturnProcessUploadPicture();

  const createSkeletonAndAttachOriginalId = useCallback(
    (file: File) => {
      const originalId = uuid();
      setImages((prevState) => [
        ...prevState,
        { id: originalId, name: file.name, size: file.size },
      ]);
      setLoadingImagesIds((prevState) => [...prevState, originalId]);

      return { file, originalId };
    },
    [setImages],
  );

  const saveImagesFromResponse = useCallback(
    (responses: (StorageObjectRead & { originalId: string })[]) => {
      setImages((prevState) => [
        ...prevState.map((image) => {
          const fileFromResponse = responses.find(
            (response) => response.originalId === image.id,
          );
          return fileFromResponse
            ? {
                id: fileFromResponse.id,
                name: image.name,
                size: image.size,
                src: fileFromResponse.publicUrl,
              }
            : image;
        }),
      ]);
      setLoadingImagesIds([]);
    },
    [setImages],
  );

  const onDeleteImage = useCallback(
    (id: string) => {
      setImages((prevState) => prevState.filter((file) => file.id !== id));
    },
    [setImages],
  );

  const uploadReturnProcessPhoto = useCallback(
    (file: { file: File; originalId: string }) =>
      mutateAsync(file.file, {
        onError: async (error) => {
          const message = await parseApiError(error);
          toaster.add("error", message);
          onDeleteImage(file.originalId);
        },
      }).then((data: StorageObjectRead) => ({
        ...data,
        originalId: file.originalId,
      })),
    [mutateAsync, onDeleteImage, toaster],
  );

  const uploadImages = useCallback(
    (files: Array<File>) => {
      const filesWithIds = files.map(createSkeletonAndAttachOriginalId);
      Promise.all(filesWithIds.map(uploadReturnProcessPhoto)).then(
        saveImagesFromResponse,
      );
    },
    [
      createSkeletonAndAttachOriginalId,
      saveImagesFromResponse,
      uploadReturnProcessPhoto,
    ],
  );

  const onUploadImages = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadImages(Array.from(event.target.files));
    }
  };

  return {
    images,
    uploadImages,
    onUploadImages,
    onDeleteImage,
    loadingImagesIds,
  };
}
