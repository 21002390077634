import { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as zod from "zod";

import { ErrorWithCodeResponse, Token } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";

import { authApi } from "../../api";
import { useAuthContext } from "../../context";

export const useResetPasswordPage = () => {
  const { t } = useTranslation();
  const { setToken } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const email = searchParams.get("email") ?? "";

  const setPasswordDataSchema = useMemo(
    () =>
      zod.object({
        newPassword: zod
          .string()
          .nonempty(t("authV2:signIn.enterPassword"))
          .min(10, t("authV2:signIn.passwordTooShort")),
      }),
    [t],
  );

  type SetPasswordDataType = zod.infer<typeof setPasswordDataSchema>;

  useEffect(() => {
    if (!token || !email) navigate("/sign-in");
  }, [token, email, navigate]);

  return useFormMutation<SetPasswordDataType, Token>(
    {
      mutationFn: ({ newPassword }) =>
        authApi.authResetPassword({
          email,
          passwordResetToken: {
            token,
            newPassword,
          },
        }),
      async onSuccess(res) {
        setToken(res.accessToken);
        navigate("/");
      },
      async onError(error) {
        const _error = (await error?.response?.json()) as ErrorWithCodeResponse;
        if (_error.code === "VERIFICATION_TOKEN_INVALID") {
          navigate("/reset-password-link-expired");
        }
      },
    },
    {
      resolver: zodResolver(setPasswordDataSchema),
    },
  );
};
