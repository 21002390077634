import { ReactNode } from "react";

import { Flex, Justify } from "../Flex";
import { Typography } from "../Typography";
import { SpacingVariants, TypographyVariants } from "../styles";

type OrderRowItemProps = {
  label?: string;
  value?: ReactNode;
  labelIsBold?: boolean;
  valueIsBold?: boolean;
  justify?: Justify;
  typoVariant?: TypographyVariants;
  gap?: SpacingVariants;
};

export function RowItem({
  label,
  value,
  typoVariant,
  labelIsBold = true,
  valueIsBold = false,
  justify = "space-between",
  gap = "xs",
}: OrderRowItemProps) {
  return (
    <Flex align="center" justify={justify} gap={gap}>
      <Typography
        weight={labelIsBold ? "bold" : "none"}
        align="left"
        variant={typoVariant}
        textTransform="capitalize"
      >
        {label ?? "-"}
      </Typography>
      {typeof value === "string" ? (
        <Typography
          align="right"
          weight={valueIsBold ? "bold" : "none"}
          variant={typoVariant}
          textTransform="capitalize"
        >
          {value}
        </Typography>
      ) : (
        <span>{value}</span>
      )}
    </Flex>
  );
}
