/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitContractCancellationReason = {
  Redeemed: "redeemed",
  Returned: "returned",
  Revised: "revised",
  InsuranceTheft: "insurance_theft",
  InsuranceAccident: "insurance_accident",
  MergerAcquisition: "merger_acquisition",
  Other: "other",
} as const;
export type BikeBenefitContractCancellationReason =
  (typeof BikeBenefitContractCancellationReason)[keyof typeof BikeBenefitContractCancellationReason];

export function BikeBenefitContractCancellationReasonFromJSON(
  json: any,
): BikeBenefitContractCancellationReason {
  return BikeBenefitContractCancellationReasonFromJSONTyped(json, false);
}

export function BikeBenefitContractCancellationReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractCancellationReason {
  return json as BikeBenefitContractCancellationReason;
}

export function BikeBenefitContractCancellationReasonToJSON(
  value?: BikeBenefitContractCancellationReason | null,
): any {
  return value as any;
}
