import { Outlet } from "react-router-dom";

import { PageRoot } from "@vapaus/common-v2";

import { NoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/NoApprovedBenefitsBanner";
import { useNoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/useNoApprovedBenefitsBanner";
import { GetABikeHero } from "./components/GetABikeHero";

export function GetABikePage() {
  const { shouldShowNoApprovedBenefitsBanner } = useNoApprovedBenefitsBanner();

  return (
    <>
      {shouldShowNoApprovedBenefitsBanner ? <NoApprovedBenefitsBanner /> : null}
      <GetABikeHero />
      <PageRoot>
        <Outlet />
      </PageRoot>
    </>
  );
}
