/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeBenefitOrderDeliveryTransport
 */
export interface BikeBenefitOrderDeliveryTransport {
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  expectedDeliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  trackingUrl?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderDeliveryTransport
   */
  priceGross: number;
}

/**
 * Check if a given object implements the BikeBenefitOrderDeliveryTransport interface.
 */
export function instanceOfBikeBenefitOrderDeliveryTransport(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "priceGross" in value;

  return isInstance;
}

export function BikeBenefitOrderDeliveryTransportFromJSON(
  json: any,
): BikeBenefitOrderDeliveryTransport {
  return BikeBenefitOrderDeliveryTransportFromJSONTyped(json, false);
}

export function BikeBenefitOrderDeliveryTransportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderDeliveryTransport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expectedDeliveryDate: !exists(json, "expected_delivery_date")
      ? undefined
      : new Date(json["expected_delivery_date"]),
    trackingUrl: !exists(json, "tracking_url")
      ? undefined
      : json["tracking_url"],
    priceGross: json["price_gross"],
  };
}

export function BikeBenefitOrderDeliveryTransportToJSON(
  value?: BikeBenefitOrderDeliveryTransport | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    expected_delivery_date:
      value.expectedDeliveryDate === undefined
        ? undefined
        : value.expectedDeliveryDate.toISOString().substring(0, 10),
    tracking_url: value.trackingUrl,
    price_gross: value.priceGross,
  };
}
