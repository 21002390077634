import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { Form, Input, PasswordInput } from "@vapaus/form";
import {
  Button,
  MailIcon,
  Notification,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import bunny2Img from "../../../assets/bunny2.png";
import { AvailableScopes } from "../../constants";
import { AuthLayout } from "../AuthLayout";
import { useSignInPage } from "./useSignInPage";

export type SignInPageProps = {
  allowSignUp?: boolean;
  scope?: AvailableScopes;
};

export function SignInPage({ allowSignUp, scope }: SignInPageProps) {
  const { t } = useTranslation();
  const { formMethods, mutation } = useSignInPage(scope);
  const rootErrorMessage = formMethods.formState.errors.root?.message;
  return (
    <AuthLayout
      title={t("authV2:signIn.title")}
      description={t("authV2:signIn.description")}
      bannerImage={bunny2Img}
    >
      <Form formMethods={formMethods}>
        <StyledRoot>
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <Input
            name="username"
            placeholder={t("authV2:signIn.email")}
            startAdornment={<MailIcon />}
          />
          <PasswordInput
            name="password"
            placeholder={t("authV2:signIn.password")}
          />
          <Button
            variant="primary1"
            marginBottom="xs"
            marginTop="xs"
            isLoading={mutation.isLoading}
            fullWidth
          >
            {t("authV2:signIn.logIn")}
          </Button>
          <StyledFooter>
            <TextButton to="/forgot-password" align="center">
              {t("authV2:signIn.forgotPassword")}
            </TextButton>
            {allowSignUp && (
              <Typography variant="bodyLg" align="center">
                <Trans t={t} i18nKey="authV2:signIn.noAccountYet">
                  No account yet?
                  <TextButton to="/sign-up">Sign up</TextButton>
                </Trans>
              </Typography>
            )}
          </StyledFooter>
        </StyledRoot>
      </Form>
    </AuthLayout>
  );
}

const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`;
