import { useMutation, useQuery } from "@tanstack/react-query";

import type {
  CurrentUserRead,
  Msg,
  PaginatedUserEmailOut,
  ResponseError,
  Token,
  UserCreateUserEmailRequest,
  UserEmailRead,
  UserRead,
  UserSendEmailVerificationTokenRequest,
  UserUpdate,
} from "@vapaus/api-codegen";
import { UserApi, UserChangePasswordRequest } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

import { UseQueryOptions } from "../types";

export const userApi = new UserApi(apiConfiguration);

export const useCurrentUser = (options?: UseQueryOptions<CurrentUserRead>) =>
  useQuery<CurrentUserRead, ResponseError>(
    ["currentUser"],
    () => userApi.userReadCurrentUser(),
    options,
  );

export const useChangeUserPassword = () =>
  useMutation<Token, ResponseError, UserChangePasswordRequest>((data) =>
    userApi.userChangePassword(data),
  );

export const useCurrentUserEmails = (
  options?: UseQueryOptions<PaginatedUserEmailOut>,
) =>
  useQuery<PaginatedUserEmailOut, ResponseError>(
    ["currentUserEmails"],
    () => userApi.userSearchUserEmails(),
    options,
  );

export const useAddUserEmail = () =>
  useMutation<UserEmailRead, ResponseError, UserCreateUserEmailRequest>(
    (data) => userApi.userCreateUserEmail(data),
  );

export const useDeleteUserEmail = () =>
  useMutation<void, ResponseError, string>((userEmailId) =>
    userApi.userDeleteUserEmail({ userEmailId }),
  );

export const useSendEmailVerificationToken = () =>
  useMutation<Msg, ResponseError, UserSendEmailVerificationTokenRequest>(
    (data) => userApi.userSendEmailVerificationToken(data),
  );

export const useVerifyUserEmail = () =>
  useMutation<Msg, ResponseError, string>((token: string) =>
    userApi.userVerifyEmail({ verificationToken: { token } }),
  );

export const useMarkUserEmailAsPrimary = () =>
  useMutation<Msg, ResponseError, string>((userEmailId) =>
    userApi.userMakeUserEmailPrimary({ userEmailId }),
  );

export const useUpdateCurrentUser = () =>
  useMutation<UserRead, ResponseError, UserUpdate>((userUpdate) =>
    userApi.userUpdateCurrentUser({ userUpdate }),
  );

export const useVerifyPhoneNumber = () =>
  useMutation<UserRead, ResponseError, number>((code) =>
    userApi.userVerifyPhoneNumber({ verificationCode: { code } }),
  );

export const useSendPhoneNumberVerificationCode = () =>
  useMutation<Msg, ResponseError>(() =>
    userApi.userSendPhoneVerificationCode(),
  );
