import { ReactNode } from "react";

import styled, { css } from "styled-components";

import { Flex, TabRow, type TabRowItemProps, Typography } from "@vapaus/ui-v2";

type SettingsPageLayoutProps = {
  title?: string;
  name?: string;
  email?: string;
  tabItems: Array<TabRowItemProps>;
  children: ReactNode;
};

export function SettingsPageLayout({
  title,
  name,
  email,
  tabItems,
  children,
}: SettingsPageLayoutProps) {
  return (
    <>
      <StyledRoot direction="column" align="center">
        <StyledContent>
          <Typography variant="h6" align="center" marginBottom="xxs">
            {title}
          </Typography>
          <Typography variant="h1" align="center" marginBottom="xxs">
            {name}
          </Typography>
          <Typography variant="bodyLg" align="center">
            {email}
          </Typography>
        </StyledContent>
        <TabRow items={tabItems} />
      </StyledRoot>
      <StyledPageContent>{children}</StyledPageContent>
    </>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.primary2.main};
  border-bottom: ${({ theme }) => theme.border.secondary.main};
`;

const StyledContent = styled.div`
  padding-top: 120px;
  padding-bottom: 80px;
  width: 100%;
`;

const StyledPageContent = styled.div`
  max-width: 960px;
  margin: ${({ theme }) => theme.spacing.xl} auto;
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      margin: ${theme.spacing.lg};
      padding-bottom: ${theme.height.navBar};
    `)}
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      margin: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.sm}`};
    `)}
`;
