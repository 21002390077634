import { createSvgIcon } from "./SvgIcon";

export const LineStartCircleIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 18C6.33333 18 4.91667 17.4167 3.75 16.25C2.58333 15.0833 2 13.6667 2 12C2 10.3333 2.58333 8.91667 3.75 7.75C4.91667 6.58333 6.33333 6 8 6C9.5 6 10.8042 6.475 11.9125 7.425C13.0208 8.375 13.6917 9.56667 13.925 11H21C21.2833 11 21.5208 11.0958 21.7125 11.2875C21.9042 11.4792 22 11.7167 22 12C22 12.2833 21.9042 12.5208 21.7125 12.7125C21.5208 12.9042 21.2833 13 21 13H13.925C13.6917 14.4333 13.0208 15.625 11.9125 16.575C10.8042 17.525 9.5 18 8 18Z" />
  </svg>,
  "LineStartCircle",
);
