/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitContractState = {
  Scheduled: "scheduled",
  Active: "active",
  ActivePendingRevision: "active_pending_revision",
  ActivePendingCorrection: "active_pending_correction",
  ActivePendingRedemption: "active_pending_redemption",
  ActivePendingReturn: "active_pending_return",
  Ended: "ended",
  EndedPendingPayment: "ended_pending_payment",
  Cancelled: "cancelled",
  CancelledPendingPayment: "cancelled_pending_payment",
  Invalidated: "invalidated",
} as const;
export type BikeBenefitContractState =
  (typeof BikeBenefitContractState)[keyof typeof BikeBenefitContractState];

export function BikeBenefitContractStateFromJSON(
  json: any,
): BikeBenefitContractState {
  return BikeBenefitContractStateFromJSONTyped(json, false);
}

export function BikeBenefitContractStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitContractState {
  return json as BikeBenefitContractState;
}

export function BikeBenefitContractStateToJSON(
  value?: BikeBenefitContractState | null,
): any {
  return value as any;
}
