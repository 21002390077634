/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { StorageObjectState } from "./StorageObjectState";
import {
  StorageObjectStateFromJSON,
  StorageObjectStateFromJSONTyped,
  StorageObjectStateToJSON,
} from "./StorageObjectState";

/**
 *
 * @export
 * @interface StorageObjectRead
 */
export interface StorageObjectRead {
  /**
   *
   * @type {StorageObjectState}
   * @memberof StorageObjectRead
   */
  state: StorageObjectState;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  bucketName: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  contentType: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  originalFilename: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  storedFilename: string;
  /**
   *
   * @type {number}
   * @memberof StorageObjectRead
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  addedByUserId?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  errorText?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  errorTraceback?: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof StorageObjectRead
   */
  publicUrl?: string;
}

/**
 * Check if a given object implements the StorageObjectRead interface.
 */
export function instanceOfStorageObjectRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "bucketName" in value;
  isInstance = isInstance && "contentType" in value;
  isInstance = isInstance && "originalFilename" in value;
  isInstance = isInstance && "storedFilename" in value;
  isInstance = isInstance && "id" in value;

  return isInstance;
}

export function StorageObjectReadFromJSON(json: any): StorageObjectRead {
  return StorageObjectReadFromJSONTyped(json, false);
}

export function StorageObjectReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StorageObjectRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    state: StorageObjectStateFromJSON(json["state"]),
    bucketName: json["bucket_name"],
    contentType: json["content_type"],
    originalFilename: json["original_filename"],
    storedFilename: json["stored_filename"],
    size: !exists(json, "size") ? undefined : json["size"],
    addedByUserId: !exists(json, "added_by_user_id")
      ? undefined
      : json["added_by_user_id"],
    errorText: !exists(json, "error_text") ? undefined : json["error_text"],
    errorTraceback: !exists(json, "error_traceback")
      ? undefined
      : json["error_traceback"],
    id: json["id"],
    publicUrl: !exists(json, "public_url") ? undefined : json["public_url"],
  };
}

export function StorageObjectReadToJSON(value?: StorageObjectRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    state: StorageObjectStateToJSON(value.state),
    bucket_name: value.bucketName,
    content_type: value.contentType,
    original_filename: value.originalFilename,
    stored_filename: value.storedFilename,
    size: value.size,
    added_by_user_id: value.addedByUserId,
    error_text: value.errorText,
    error_traceback: value.errorTraceback,
    id: value.id,
    public_url: value.publicUrl,
  };
}
