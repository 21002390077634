import { useMemo } from "react";

import { animated, useTransition } from "@react-spring/web";
import { get } from "lodash-es";
import styled, { AnyStyledComponent, css } from "styled-components";

import { Typography } from "../Typography";
import { PaletteVariants, addAlpha } from "../styles";
import type { ToastItem, ToastType } from "./Toast.types";

const config = { tension: 125, friction: 20, precision: 0.1 };
const timeout = 3000;

type ColorMapping = {
  [key in ToastType]: PaletteVariants;
};

const borderColorMapping: ColorMapping = {
  success: "secondary1.main",
  error: "secondary2.main",
};

const backgroundColorMapping: ColorMapping = {
  success: "secondary1.lighter",
  error: "secondary2.lighter",
};

export interface ToasterProps {
  items: Array<ToastItem>;
  onItemEndOfLife: (item: ToastItem) => void;
}

export function Toaster({ items, onItemEndOfLife }: ToasterProps) {
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0, life: "100%" },
    keys: (item: ToastItem) => item.key,
    enter: (item: ToastItem) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 1, height: refMap.get(item).offsetHeight + 34 });
      await next({ life: "0%" });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (result, ctrl, item) => onItemEndOfLife(item),
    config: (item, index, phase) => (key) =>
      phase === "enter" && key === "life" ? { duration: timeout } : config,
  });

  return (
    <Container>
      {transitions(({ life, ...style }, item) => (
        <Message style={style}>
          <Content
            ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
            background={backgroundColorMapping[item.type]}
            border={borderColorMapping[item.type]}
            onClick={(e) => {
              e.stopPropagation();
              if (
                items.includes(item) &&
                cancelMap.has(item) &&
                life.get() !== "0%"
              )
                cancelMap.get(item)();
            }}
            role="status"
          >
            <Typography color="primary1.main" weight="bold">
              {item.msg}
            </Typography>
          </Content>
        </Message>
      ))}
    </Container>
  );
}

export const Container = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.toaster};
  width: 0 auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  gap: ${({ theme }) => theme.spacing.xs};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      align-items: center;
    `)}
`;

export const Message = styled(animated.div as AnyStyledComponent)`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  max-width: 350px;

  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 100%;
    `)}
`;

interface ContentProps {
  background: PaletteVariants;
  border: PaletteVariants;
}

export const Content = styled.div<ContentProps>`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.radius.toast};
  background-color: ${({ theme, background }) =>
    get(theme.palette, background)};
  border: 2px solid ${({ theme, border }) => get(theme.palette, border)};
  cursor: pointer;
  height: auto;
`;
