import React, { ReactNode } from "react";

import styled, { keyframes } from "styled-components";

type CircularIconProps = {
  children: ReactNode;
  colour: string;
};

export function CircularIcon({ children, colour }: CircularIconProps) {
  return (
    <StyledSvgWrapper>
      <StyledCircularSvg colour={colour}>
        <StyledCircle
          cx="75"
          cy="75"
          r="50"
          fill="none"
          strokeWidth="5"
          strokeMiterlimit="10"
        />
      </StyledCircularSvg>
      {children}
    </StyledSvgWrapper>
  );
}

const StyledSvgWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 150px;
`;

const StyledCircularSvg = styled.svg<Pick<CircularIconProps, "colour">>`
  width: 100%;
  stroke: ${({ colour }) => colour};
`;

const drawCircle = keyframes`
  0% {
    stroke-dasharray: 0, 330;
    stroke-dashoffset: 0;
    opacity: 1;
  }

  80% {
    stroke-dasharray: 330, 330;
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
`;

const StyledCircle = styled.circle`
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  opacity: 0.4;
  animation: 0.7s ${drawCircle} ease-out;
`;
