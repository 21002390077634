import { createSvgIcon } from "./SvgIcon";

export const DotFilledIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 19C10.05 19 8.39583 18.3208 7.0375 16.9625C5.67917 15.6042 5 13.95 5 12C5 10.05 5.67917 8.39583 7.0375 7.0375C8.39583 5.67917 10.05 5 12 5C13.95 5 15.6042 5.67917 16.9625 7.0375C18.3208 8.39583 19 10.05 19 12C19 13.95 18.3208 15.6042 16.9625 16.9625C15.6042 18.3208 13.95 19 12 19Z" />
  </svg>,
  "DotFilled",
);
