/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
} from "./BikeBrand";
import type { ShopType } from "./ShopType";
import {
  ShopTypeFromJSON,
  ShopTypeFromJSONTyped,
  ShopTypeToJSON,
} from "./ShopType";

/**
 *
 * @export
 * @interface UserShopRead
 */
export interface UserShopRead {
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  streetAddress?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  country?: string;
  /**
   *
   * @type {Array<ShopType>}
   * @memberof UserShopRead
   */
  types: Array<ShopType>;
  /**
   *
   * @type {Array<BikeBrand>}
   * @memberof UserShopRead
   */
  bikeBrands: Array<BikeBrand>;
}

/**
 * Check if a given object implements the UserShopRead interface.
 */
export function instanceOfUserShopRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "types" in value;
  isInstance = isInstance && "bikeBrands" in value;

  return isInstance;
}

export function UserShopReadFromJSON(json: any): UserShopRead {
  return UserShopReadFromJSONTyped(json, false);
}

export function UserShopReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserShopRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    logoUrl: !exists(json, "logo_url") ? undefined : json["logo_url"],
    phoneNumber: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    website: !exists(json, "website") ? undefined : json["website"],
    email: !exists(json, "email") ? undefined : json["email"],
    streetAddress: !exists(json, "street_address")
      ? undefined
      : json["street_address"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    city: !exists(json, "city") ? undefined : json["city"],
    country: !exists(json, "country") ? undefined : json["country"],
    types: (json["types"] as Array<any>).map(ShopTypeFromJSON),
    bikeBrands: (json["bike_brands"] as Array<any>).map(BikeBrandFromJSON),
  };
}

export function UserShopReadToJSON(value?: UserShopRead | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    logo_url: value.logoUrl,
    phone_number: value.phoneNumber,
    website: value.website,
    email: value.email,
    street_address: value.streetAddress,
    postal_code: value.postalCode,
    city: value.city,
    country: value.country,
    types: (value.types as Array<any>).map(ShopTypeToJSON),
    bike_brands: (value.bikeBrands as Array<any>).map(BikeBrandToJSON),
  };
}
