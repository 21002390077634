import { useMutation, useQuery } from "@tanstack/react-query";

import {
  BodyPaymentsCreatePaymentConsent,
  Msg,
  PaginatedPaymentConsentOut,
  PaginatedPaymentOut,
  PaymentConsentDetailsRead,
  PaymentsApi,
  PaymentsSearchPaymentConsentsRequest,
  PaymentsVismaPayCallbackRequest,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const paymentsAPI = new PaymentsApi(apiConfiguration);

export const useSearchPaymentConsents = (
  params?: PaymentsSearchPaymentConsentsRequest,
  options?: UseQueryOptions<PaginatedPaymentConsentOut>,
) =>
  useQuery<PaginatedPaymentConsentOut, ResponseError>(
    ["paymentConsents", params],
    () => paymentsAPI.paymentsSearchPaymentConsents(params),
    options,
  );

export const useCancelPaymentConsent = () =>
  useMutation<PaymentConsentDetailsRead, ResponseError, string>(
    (paymentConsentId) =>
      paymentsAPI.paymentsCancelPaymentConsent({
        paymentConsentId,
      }),
  );

export const useCreatePaymentConsent = () =>
  useMutation<
    PaymentConsentDetailsRead,
    ResponseError,
    BodyPaymentsCreatePaymentConsent
  >((data) =>
    paymentsAPI.paymentsCreatePaymentConsent({
      bodyPaymentsCreatePaymentConsent: data,
    }),
  );

export const useVismaPayCallback = () =>
  useMutation<Msg, ResponseError, PaymentsVismaPayCallbackRequest>(
    ["paymentServicesVismaPayCallback"],
    (data) => paymentsAPI.paymentsVismaPayCallback(data),
  );

export const useSearchLatestPaymentWithInvoiceId = (saleInvoiceId?: string) =>
  useQuery<PaginatedPaymentOut, ResponseError>(
    ["invoicePayments", saleInvoiceId],
    () =>
      paymentsAPI.paymentsSearchPayments({
        saleInvoiceId: saleInvoiceId as string,
        limit: 1,
        sortDirection: "desc",
        sortField: "created_at",
      }),
    {
      enabled: !!saleInvoiceId,
    },
  );

export const useGetPaymentConsent = (
  paymentConsentId?: string,
  options?: UseQueryOptions<PaymentConsentDetailsRead>,
) =>
  useQuery<PaymentConsentDetailsRead, ResponseError>(
    ["paymentConsents", paymentConsentId],
    () =>
      paymentsAPI.paymentsGetPaymentConsent({
        paymentConsentId: paymentConsentId as string,
      }),
    { ...options, enabled: !!paymentConsentId },
  );
