/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  RedemptionProcessCalculation,
  RedemptionProcessCreate,
  RedemptionProcessRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RedemptionProcessCalculationFromJSON,
  RedemptionProcessCalculationToJSON,
  RedemptionProcessCreateFromJSON,
  RedemptionProcessCreateToJSON,
  RedemptionProcessReadFromJSON,
  RedemptionProcessReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserRedemptionProcessesCreateRedemptionProcessRequest {
  redemptionProcessCreate: RedemptionProcessCreate;
}

export interface UserRedemptionProcessesGetRedemptionProcessRequest {
  redemptionProcessId: string;
}

export interface UserRedemptionProcessesGetRedemptionProcessCalculationRequest {
  bikeBenefitContractId: string;
  isEndOfLeaseRedemption: boolean;
}

/**
 *
 */
export class UserRedemptionProcessesApi extends runtime.BaseAPI {
  /**
   * Create Redemption Process
   */
  async userRedemptionProcessesCreateRedemptionProcessRaw(
    requestParameters: UserRedemptionProcessesCreateRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RedemptionProcessRead>> {
    if (
      requestParameters.redemptionProcessCreate === null ||
      requestParameters.redemptionProcessCreate === undefined
    ) {
      throw new runtime.RequiredError(
        "redemptionProcessCreate",
        "Required parameter requestParameters.redemptionProcessCreate was null or undefined when calling userRedemptionProcessesCreateRedemptionProcess.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/redemption-processes`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RedemptionProcessCreateToJSON(
          requestParameters.redemptionProcessCreate,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RedemptionProcessReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Redemption Process
   */
  async userRedemptionProcessesCreateRedemptionProcess(
    requestParameters: UserRedemptionProcessesCreateRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RedemptionProcessRead> {
    const response =
      await this.userRedemptionProcessesCreateRedemptionProcessRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Get Redemption Process
   */
  async userRedemptionProcessesGetRedemptionProcessRaw(
    requestParameters: UserRedemptionProcessesGetRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RedemptionProcessRead>> {
    if (
      requestParameters.redemptionProcessId === null ||
      requestParameters.redemptionProcessId === undefined
    ) {
      throw new runtime.RequiredError(
        "redemptionProcessId",
        "Required parameter requestParameters.redemptionProcessId was null or undefined when calling userRedemptionProcessesGetRedemptionProcess.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/redemption-processes/{redemption_process_id}`.replace(
          `{${"redemption_process_id"}}`,
          encodeURIComponent(String(requestParameters.redemptionProcessId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RedemptionProcessReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Redemption Process
   */
  async userRedemptionProcessesGetRedemptionProcess(
    requestParameters: UserRedemptionProcessesGetRedemptionProcessRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RedemptionProcessRead> {
    const response = await this.userRedemptionProcessesGetRedemptionProcessRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Redemption Process Calculation
   */
  async userRedemptionProcessesGetRedemptionProcessCalculationRaw(
    requestParameters: UserRedemptionProcessesGetRedemptionProcessCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RedemptionProcessCalculation>> {
    if (
      requestParameters.bikeBenefitContractId === null ||
      requestParameters.bikeBenefitContractId === undefined
    ) {
      throw new runtime.RequiredError(
        "bikeBenefitContractId",
        "Required parameter requestParameters.bikeBenefitContractId was null or undefined when calling userRedemptionProcessesGetRedemptionProcessCalculation.",
      );
    }

    if (
      requestParameters.isEndOfLeaseRedemption === null ||
      requestParameters.isEndOfLeaseRedemption === undefined
    ) {
      throw new runtime.RequiredError(
        "isEndOfLeaseRedemption",
        "Required parameter requestParameters.isEndOfLeaseRedemption was null or undefined when calling userRedemptionProcessesGetRedemptionProcessCalculation.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.isEndOfLeaseRedemption !== undefined) {
      queryParameters["is_end_of_lease_redemption"] =
        requestParameters.isEndOfLeaseRedemption;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/redemption-processes/calculation/{bike_benefit_contract_id}`.replace(
          `{${"bike_benefit_contract_id"}}`,
          encodeURIComponent(String(requestParameters.bikeBenefitContractId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RedemptionProcessCalculationFromJSON(jsonValue),
    );
  }

  /**
   * Get Redemption Process Calculation
   */
  async userRedemptionProcessesGetRedemptionProcessCalculation(
    requestParameters: UserRedemptionProcessesGetRedemptionProcessCalculationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RedemptionProcessCalculation> {
    const response =
      await this.userRedemptionProcessesGetRedemptionProcessCalculationRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
