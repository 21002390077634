import { ReactNode } from "react";

import { useNavigate } from "react-router-dom";
import styled, { AnyStyledComponent, css } from "styled-components";

import {
  ArrowBackIcon,
  CircularButton,
  CloseIcon,
  Flex,
  ProgressBar,
} from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

type OnboardingPageWrapperProps = {
  children: ReactNode;
  handleGoBack?: () => void;
  shouldHideHeader?: boolean;
  progress: {
    total: number;
    current: number;
  };
};

export function OnboardingPageWrapper({
  children,
  handleGoBack,
  shouldHideHeader,
  progress,
}: OnboardingPageWrapperProps) {
  const navigate = useNavigate();

  const progressPercentage = ((progress.current + 1) / progress.total) * 100;

  return (
    <StyledRoot direction="column" gap="none">
      {!shouldHideHeader ? (
        <StyledTopBar justify="space-between">
          <StyledCircularButton
            $isVisible={!!handleGoBack}
            variant="outlined"
            icon={<ArrowBackIcon />}
            onClick={handleGoBack}
          />
          <StyledProgressBarContainer>
            <ProgressBar progressPercentage={progressPercentage} />
          </StyledProgressBarContainer>
          <CircularButton
            variant="outlined"
            icon={<CloseIcon />}
            onClick={() => navigate(`/${Path.benefits}`)}
          />
        </StyledTopBar>
      ) : null}
      <Flex>{children}</Flex>
    </StyledRoot>
  );
}

const StyledRoot = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  min-height: 100vh;
  width: 100%;
`;

const StyledTopBar = styled(Flex)`
  height: ${({ theme }) => theme.height.navBar};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
`;

const StyledCircularButton = styled(CircularButton as AnyStyledComponent)<{
  $isVisible: boolean;
}>`
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      visibility: hidden;
    `}
`;

const StyledProgressBarContainer = styled.div`
  width: 12.5rem;
`;
