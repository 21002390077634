import { useTranslation } from "react-i18next";

import { type LinksGroupProps, PageFooter } from "@vapaus/common-v2";

import { Path } from "../../../constants/paths";

export function FooterSweden() {
  const { t } = useTranslation();

  const linkGroups: LinksGroupProps[] = [
    {
      title: t("userV2:footer.benefitBikes.title"),
      links: [
        {
          label: t("userV2:footer.benefitBikes.forEmployees.label"),
          link: t("userV2:sweden.footer.benefitBikes.forEmployees.link"),
        },
        {
          label: t("userV2:footer.benefitBikes.forEmployers.label"),
          link: t("userV2:sweden.footer.benefitBikes.forEmployers.link"),
        },
        {
          label: t("userV2:footer.benefitBikes.getABike.label"),
          link: "/" + Path.getABike,
          target: "_self",
        },
      ],
    },
    {
      title: t("userV2:footer.about.title"),
      links: [
        {
          label: t("userV2:footer.about.whoWeAre.label"),
          link: t("userV2:sweden.footer.about.whoWeAre.link"),
        },
        {
          label: t("userV2:footer.about.blog.label"),
          link: t("userV2:sweden.footer.about.blog.link"),
        },
        {
          label: t("userV2:footer.about.careers.label"),
          link: "https://careers.vapaus.io/",
        },
        {
          label: t("userV2:footer.about.sustainability.label"),
          link: "https://www.vapaus.io/en/sustainability",
        },
      ],
    },
    {
      title: t("userV2:footer.support.title"),
      links: [
        {
          label: t("userV2:sweden.footer.support.faq.label"),
          link: t("userV2:sweden.footer.support.faq.link"),
        },
        {
          label: t("userV2:footer.support.contactUs.label"),
          link: "/" + Path.support,
          target: "_self",
        },
      ],
    },
  ];
  return <PageFooter linkGroups={linkGroups} />;
}
