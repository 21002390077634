/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionTermsRead,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
} from "../models/index";
import {
  BenefitDefinitionTermsReadFromJSON,
  BenefitDefinitionTermsReadToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface BenefitDefinitionTermsAcceptBenefitDefinitionTermsRequest {
  benefitDefinitionTermsId: string;
  orderOrContractId: string;
}

export interface BenefitDefinitionTermsGetBenefitDefinitionTermsRequest {
  benefitDefinitionTermsId: string;
}

/**
 *
 */
export class BenefitDefinitionTermsApi extends runtime.BaseAPI {
  /**
   * Accept Benefit Definition Terms
   */
  async benefitDefinitionTermsAcceptBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionTermsAcceptBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.benefitDefinitionTermsId === null ||
      requestParameters.benefitDefinitionTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionTermsId",
        "Required parameter requestParameters.benefitDefinitionTermsId was null or undefined when calling benefitDefinitionTermsAcceptBenefitDefinitionTerms.",
      );
    }

    if (
      requestParameters.orderOrContractId === null ||
      requestParameters.orderOrContractId === undefined
    ) {
      throw new runtime.RequiredError(
        "orderOrContractId",
        "Required parameter requestParameters.orderOrContractId was null or undefined when calling benefitDefinitionTermsAcceptBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.orderOrContractId !== undefined) {
      queryParameters["order_or_contract_id"] =
        requestParameters.orderOrContractId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definition-terms/{benefit_definition_terms_id}/accept`.replace(
          `{${"benefit_definition_terms_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionTermsId),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept Benefit Definition Terms
   */
  async benefitDefinitionTermsAcceptBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionTermsAcceptBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.benefitDefinitionTermsAcceptBenefitDefinitionTermsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Get Benefit Definition Terms
   */
  async benefitDefinitionTermsGetBenefitDefinitionTermsRaw(
    requestParameters: BenefitDefinitionTermsGetBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BenefitDefinitionTermsRead>> {
    if (
      requestParameters.benefitDefinitionTermsId === null ||
      requestParameters.benefitDefinitionTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "benefitDefinitionTermsId",
        "Required parameter requestParameters.benefitDefinitionTermsId was null or undefined when calling benefitDefinitionTermsGetBenefitDefinitionTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definition-terms/{benefit_definition_terms_id}`.replace(
          `{${"benefit_definition_terms_id"}}`,
          encodeURIComponent(
            String(requestParameters.benefitDefinitionTermsId),
          ),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BenefitDefinitionTermsReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition Terms
   */
  async benefitDefinitionTermsGetBenefitDefinitionTerms(
    requestParameters: BenefitDefinitionTermsGetBenefitDefinitionTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BenefitDefinitionTermsRead> {
    const response =
      await this.benefitDefinitionTermsGetBenefitDefinitionTermsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
