import { TFunction } from "i18next";
import { useCurrencyFormat } from "packages/i18n/src/hooks";
import { useTranslation } from "react-i18next";

import { BikeBenefitOrderRead } from "@vapaus/api-codegen";
import { Card, Flex } from "@vapaus/ui-v2";

import { NameValueDescription } from "../../../components/NameValueDescription";

interface MaintenanceCardProps {
  order: BikeBenefitOrderRead;
}

export function MaintenanceCard({ order }: MaintenanceCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);

  return (
    <Card title={t("userV2:myBike.maintenanceCard.title")}>
      <Flex direction="column" gap="md">
        <NameValueDescription
          name={getMaintenanceBudgetName(t, order.monthlyMaintenanceBudget)}
          value={formatCurrency(
            order.monthlyMaintenanceBudget * order.leasingPeriodMonths,
          )}
          description={t("userV2:myBike.maintenanceCard.description", {
            value: formatCurrency(order.monthlyMaintenanceBudget),
          })}
        />
      </Flex>
    </Card>
  );
}

function getMaintenanceBudgetName(t: TFunction, maintenanceBudget: number) {
  if (maintenanceBudget === 0)
    return t("userV2:myBike.maintenanceCard.budget.selfService");
  if (maintenanceBudget <= 12)
    return t("userV2:myBike.maintenanceCard.budget.small");
  if (maintenanceBudget <= 16)
    return t("userV2:myBike.maintenanceCard.budget.medium");
  return t("userV2:myBike.maintenanceCard.budget.large");
}
