/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const SupportedLanguagesEnum = {
  Fi: "FI",
  En: "EN",
  Sv: "SV",
} as const;
export type SupportedLanguagesEnum =
  (typeof SupportedLanguagesEnum)[keyof typeof SupportedLanguagesEnum];

export function SupportedLanguagesEnumFromJSON(
  json: any,
): SupportedLanguagesEnum {
  return SupportedLanguagesEnumFromJSONTyped(json, false);
}

export function SupportedLanguagesEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SupportedLanguagesEnum {
  return json as SupportedLanguagesEnum;
}

export function SupportedLanguagesEnumToJSON(
  value?: SupportedLanguagesEnum | null,
): any {
  return value as any;
}
