import { Configuration, ConfigurationParameters } from "@vapaus/api-codegen";

import { authTokenKey } from "../constants";

const configProperties = {
  basePath: import.meta.env.VITE_API_BASE_URL,
  credentials: "include" as RequestCredentials,
  headers: { "Accept-Language": "en" },
};

export class ApiConfiguration extends Configuration {
  tokenString: string | null = null;

  constructor(configuration?: ConfigurationParameters) {
    const token = localStorage.getItem(authTokenKey);
    super({
      ...configuration,
      ...configProperties,
      accessToken: token ? `Bearer ${token}` : undefined,
      headers: {
        ...configuration?.headers,
        ...configProperties.headers,
      },
    });
    this.tokenString = token;
  }

  setToken(token: string) {
    localStorage.setItem(authTokenKey, token);
    this.config = new Configuration({
      ...configProperties,
      accessToken: `Bearer ${token}`,
    });
    this.tokenString = token;
  }

  clearToken() {
    localStorage.removeItem(authTokenKey);
    this.config = new Configuration({
      ...configProperties,
    });
    this.tokenString = null;
  }

  setLanguageHeader(language?: string) {
    const headers = language ? { "Accept-Language": language } : {};
    this.config = new Configuration({
      ...configProperties,
      accessToken: `Bearer ${this.tokenString}`,
      headers: {
        ...configProperties.headers,
        ...headers,
      },
    });
  }
}

export const apiConfiguration = new ApiConfiguration();
