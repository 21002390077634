import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionUserRead,
  BenefitDefinitionsApi,
  BenefitDefinitionsGetBenefitDefinitionsActivationsRequest,
  PaginatedUserBenefitActivationOut,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userBenefitDefinitionsAPI = new BenefitDefinitionsApi(
  apiConfiguration,
);

export const useGetBenefitDefinitionsActivations = (
  params?: BenefitDefinitionsGetBenefitDefinitionsActivationsRequest,
  options?: UseQueryOptions<PaginatedUserBenefitActivationOut>,
) => {
  return useQuery<PaginatedUserBenefitActivationOut, ResponseError>(
    ["activations", params],
    () =>
      userBenefitDefinitionsAPI.benefitDefinitionsGetBenefitDefinitionsActivations(
        params,
      ),
    options,
  );
};

export const useGetBenefitDefinition = (benefitId?: string) =>
  useQuery<BenefitDefinitionUserRead, ResponseError>({
    queryKey: ["benefitDefinitions", benefitId],
    queryFn: () =>
      userBenefitDefinitionsAPI.benefitDefinitionsGetBenefitDefinition({
        benefitId: benefitId as string,
        onlyActive: false,
      }),
    enabled: Boolean(benefitId),
  });
