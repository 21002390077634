import { createSvgIcon } from "./SvgIcon";

export const ExpandMoreIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12.0002 14.95C11.8668 14.95 11.7418 14.9292 11.6252 14.8875C11.5085 14.8458 11.4002 14.775 11.3002 14.675L6.67515 10.05C6.49182 9.86667 6.40432 9.63751 6.41265 9.36251C6.42099 9.08751 6.51682 8.85834 6.70015 8.67501C6.88349 8.49167 7.11682 8.40001 7.40015 8.40001C7.68349 8.40001 7.91682 8.49167 8.10015 8.67501L12.0002 12.575L15.9252 8.65001C16.1085 8.46667 16.3377 8.37917 16.6127 8.38751C16.8877 8.39584 17.1168 8.49167 17.3002 8.67501C17.4835 8.85834 17.5752 9.09167 17.5752 9.37501C17.5752 9.65834 17.4835 9.89167 17.3002 10.075L12.7002 14.675C12.6002 14.775 12.4918 14.8458 12.3752 14.8875C12.2585 14.9292 12.1335 14.95 12.0002 14.95Z" />
  </svg>,
  "ExpandMore",
);
