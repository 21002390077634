import { useCurrencyFormat } from "packages/i18n/src/hooks";

import { Currency } from "@vapaus/api-codegen";
import { Typography } from "@vapaus/ui-v2";

interface PriceCellProps {
  price: number;
  currency: Currency;
}

export function PriceCell({ price, currency }: PriceCellProps) {
  const formatCurrency = useCurrencyFormat(currency);

  return <Typography align="right">{formatCurrency(price)}</Typography>;
}
