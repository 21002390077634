import { useEffect, useRef } from "react";

export function useForwardedRef<HTMLElementT>(
  ref: React.ForwardedRef<HTMLElementT>,
) {
  const innerRef = useRef<HTMLElementT>(null);

  useEffect(() => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}
