import { ReactNode } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  BenefitDefinitionUserRead,
  Currency,
  Plan,
  SupportedCountriesEnum,
} from "@vapaus/api-codegen";
import {
  Card,
  Flex,
  Paper,
  Separator,
  Tooltip,
  Typography,
} from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../packages/i18n/src/hooks";
import { useGetCurrentUser } from "../../../api/user";
import { NameValueDescription } from "../../../components/NameValueDescription";
import { Path } from "../../../constants/paths";

interface ContractSummaryCardProps {
  benefitDefinition?: BenefitDefinitionUserRead;
  redemptionPrice?: number;
  residualRatio?: number;
  monthlyTaxableValue?: number;
  leasingPeriodMonths: number;
  monthlyMaintenanceBudget: number;
  downPaymentAmount?: number;
  bikeOrderPrice: number;
  leasingCost?: number;
  children?: ReactNode;
}

export function ContractSummaryCard({
  benefitDefinition,
  redemptionPrice,
  residualRatio,
  monthlyTaxableValue,
  leasingPeriodMonths,
  monthlyMaintenanceBudget,
  downPaymentAmount,
  bikeOrderPrice,
  leasingCost,
  children,
}: ContractSummaryCardProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(
    benefitDefinition?.currency || Currency.Eur,
  );
  const { data: user } = useGetCurrentUser();

  const totalPayment = (monthlyTaxableValue || 0) * leasingPeriodMonths;

  return (
    <Card title={t("userV2:myBike.contractSummaryCard.title")}>
      <Typography marginBottom="lg">
        <Trans
          i18nKey="userV2:myBike.contractSummaryCard.description"
          values={{
            amount: formatCurrency(totalPayment),
            monthlyValue: formatCurrency(monthlyTaxableValue),
            durationMonths: leasingPeriodMonths,
            residualValue: formatCurrency(redemptionPrice),
          }}
          components={{
            b: <b />,
          }}
        />
        {user?.country === SupportedCountriesEnum.Fi ? (
          <>
            {" "}
            <br />
            <br />
            <Trans
              i18nKey="userV2:myBike.contractSummaryCard.calculatorLink"
              components={{
                a: <Link to={`/${Path.calculator}`} target="_blank" />,
              }}
            />
          </>
        ) : null}
      </Typography>
      <Flex direction="column" gap="md">
        <NameValueDescription
          name={t("userV2:myBike.contractSummaryCard.bikeOrder.name")}
          value={formatCurrency(bikeOrderPrice)}
          description={t(
            "userV2:myBike.contractSummaryCard.bikeOrder.description",
          )}
        />
        <NameValueDescription
          name={t("userV2:myBike.contractSummaryCard.maintenance.name")}
          value={`+${formatCurrency(
            monthlyMaintenanceBudget * leasingPeriodMonths,
          )}`}
          description={
            monthlyMaintenanceBudget > 0
              ? t("userV2:myBike.contractSummaryCard.maintenance.description", {
                  value: formatCurrency(monthlyMaintenanceBudget),
                })
              : t(
                  "userV2:myBike.contractSummaryCard.maintenance.descriptionNoMaintenanceBudget",
                  { value: formatCurrency(monthlyMaintenanceBudget) },
                )
          }
        />
        {!!downPaymentAmount && (
          <NameValueDescription
            name={t("userV2:myBike.contractSummaryCard.downPayment.name")}
            value={formatCurrency(-downPaymentAmount)}
            description={t(
              "userV2:myBike.contractSummaryCard.downPayment.description",
            )}
          />
        )}
        <NameValueDescription
          name={t("userV2:myBike.contractSummaryCard.leasingCosts.name")}
          value={`+${formatCurrency(leasingCost)}`}
          description={t(
            "userV2:myBike.contractSummaryCard.leasingCosts.description",
          )}
        />
        <Paper background="secondary1.lighter" padding="lg">
          <Flex direction="column" gap="lg">
            <Flex direction="column" gap="sm">
              <Flex justify="space-between">
                <Typography weight="bold">
                  {t("userV2:myBike.contractSummaryCard.total")}
                </Typography>
                <Flex gap="xs">
                  <Typography weight="bold">
                    {formatCurrency(totalPayment)}
                  </Typography>
                  {benefitDefinition &&
                    ([Plan.Custom, Plan.Legacy] as Plan[]).includes(
                      benefitDefinition.plan,
                    ) && (
                      <Tooltip>
                        {t("userV2:myBike.contractSummaryCard.totalLegacyInfo")}
                      </Tooltip>
                    )}
                </Flex>
              </Flex>
              <Flex justify="space-between">
                <Typography weight="bold">
                  {t("userV2:myBike.contractSummaryCard.monthlyPayment")}
                </Typography>
                <Typography>{formatCurrency(monthlyTaxableValue)}</Typography>
              </Flex>
              <Flex justify="space-between">
                <Typography weight="bold">
                  {t("userV2:myBike.contractSummaryCard.contractLength")}
                </Typography>
                <Typography>
                  {t("userV2:myBike.contractSummaryCard.contractLengthValue", {
                    value: leasingPeriodMonths,
                  })}
                </Typography>
              </Flex>
            </Flex>
            <Separator />
            <NameValueDescription
              name={t("userV2:myBike.contractSummaryCard.redemptionPrice.name")}
              value={formatCurrency(redemptionPrice)}
              description={t(
                "userV2:myBike.contractSummaryCard.redemptionPrice.description",
                {
                  residualRatio: (residualRatio || 0) * 100,
                },
              )}
            />
            {children}
          </Flex>
        </Paper>
      </Flex>
    </Card>
  );
}
