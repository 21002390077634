import { useTranslation } from "react-i18next";

import { useUserCountry } from "@vapaus/common-v2";

export function useVapausSelfReturnBikeCenterAddress() {
  const { t } = useTranslation();
  const { isSwedishUser } = useUserCountry();

  return isSwedishUser
    ? {
        name: t("userV2:returnProcess.vapausSelfReturnBikeCenter.sweden.name"),
        addressLine1: t(
          "userV2:returnProcess.vapausSelfReturnBikeCenter.sweden.addressLine1",
        ),
        addressLine2: t(
          "userV2:returnProcess.vapausSelfReturnBikeCenter.sweden.addressLine2",
        ),
      }
    : {
        name: t("userV2:returnProcess.vapausSelfReturnBikeCenter.name"),
        addressLine1: t(
          "userV2:returnProcess.vapausSelfReturnBikeCenter.addressLine1",
        ),
        addressLine2: t(
          "userV2:returnProcess.vapausSelfReturnBikeCenter.addressLine2",
        ),
      };
}
