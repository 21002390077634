import { assign, createMachine } from "xstate";

import { Currency, ReturnProcessPreliminaryData } from "@vapaus/api-codegen";

import { userReturnProcessesApi } from "../../../../../api/returnProcess";
import {
  Actions,
  Context,
  Events,
  PickupAddressDetails,
  Services,
} from "./types";

export const returnProcessMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "ReturnProcessWizard",
    initial: "loading",
    context: {
      step: 1,
      startingState: "default",
      preliminaryData: {} as ReturnProcessPreliminaryData,
      currency: Currency.Eur,
      contractId: "",
      isContractEndingSoon: false,
      pickupAddressDetails: {} as PickupAddressDetails,
      accessoryIdsSelectedForRedemption: [],
      accessories: [],
      images: [],
    },
    schema: {
      events: {} as Events,
      context: {} as Context,
      actions: {} as Actions,
      services: {} as Services,
    },
    tsTypes: {} as import("./ReturnProcessMachine.typegen").Typegen0,
    states: {
      loading: {
        invoke: {
          src: "getPreliminaryData",
          onDone: {
            actions: "setPreliminaryData",
            target: "initialize",
          },
          onError: "loading",
        },
      },
      initialize: {
        always: [
          {
            target: "processingPayment",
            cond: (context) => context.startingState === "processingPayment",
          },
          {
            target: "emailAddress",
            cond: (context) => context.startingState === "emailAddress",
          },
          {
            target: "chooseReturnMethod",
            cond: (context) => context.isContractEndingSoon,
          },
          {
            target: "returnReason",
          },
        ],
      },
      returnReason: {
        entry: assign({ step: 1 }),
        on: {
          NEXT: "chooseReturnMethod",
          SET_RETURN_REASON: {
            actions: "setReturnReason",
          },
        },
      },
      chooseReturnMethod: {
        entry: assign({ step: 2 }),
        on: {
          SELF_RETURN: {
            actions: assign({ returnMethod: "selfReturn" }),
            target: "bikeCenterInfo",
          },
          PICKUP: {
            actions: assign({ returnMethod: "pickup" }),
            target: "choosePickupDetails",
          },
          BACK: {
            target: "returnReason",
            cond: (context) => !context.isContractEndingSoon,
          },
        },
      },
      bikeCenterInfo: {
        entry: assign({ step: 3 }),
        on: {
          NEXT: [
            {
              target: "accessories",
              cond: (context) => context.accessories.length > 0,
            },
            {
              target: "uploadPhotos",
            },
          ],
          BACK: "chooseReturnMethod",
        },
      },
      choosePickupDetails: {
        entry: assign({ step: 3 }),
        on: {
          NEXT: [
            {
              target: "accessories",
              cond: (context) => context.accessories.length > 0,
            },
            {
              target: "uploadPhotos",
            },
          ],
          BACK: "chooseReturnMethod",
          SET_PICKUP_ADDRESS_DETAILS: {
            actions: "setPickupAddressDetails",
          },
        },
      },
      accessories: {
        entry: assign({ step: 4 }),
        on: {
          NEXT: "uploadPhotos",
          BACK: [
            {
              target: "choosePickupDetails",
              cond: (context) => context.returnMethod === "pickup",
            },
            {
              target: "bikeCenterInfo",
            },
          ],
          SET_ACCESSORY_SELECTED_FOR_REDEMPTION: {
            actions: "setAccessorySelectedForRedemption",
          },
        },
      },
      uploadPhotos: {
        entry: assign({ step: 5 }),
        on: {
          NEXT: "returnChecklist",
          BACK: [
            {
              target: "accessories",
              cond: (context) => context.accessories.length > 0,
            },
            {
              target: "choosePickupDetails",
              cond: (context) => context.returnMethod === "pickup",
            },
            {
              target: "bikeCenterInfo",
            },
          ],
          SET_IMAGES: {
            actions: "setImages",
          },
        },
      },
      returnChecklist: {
        entry: assign({ step: 6 }),
        on: {
          NEXT: "emailAddress",
          BACK: "uploadPhotos",
        },
      },
      emailAddress: {
        entry: assign({ step: 7 }),
        on: {
          NEXT: "priceConfirmation",
          BACK: "returnChecklist",
        },
      },
      priceConfirmation: {
        entry: assign({ step: 8 }),
        on: {
          BACK: "emailAddress",
        },
      },
      processingPayment: {
        entry: assign({ step: 9 }),
        on: {
          COMPLETED: "processCompleted",
        },
      },
      processCompleted: {
        type: "final",
        entry: assign({ step: 10 }),
      },
    },
  },
  {
    actions: {
      setPreliminaryData: assign((context, event) => {
        const preliminaryData = event.data;

        return { preliminaryData };
      }),
      setReturnReason: assign((context, event) => ({
        returnReason: event.returnReason,
      })),
      setPickupAddressDetails: assign((context, event) => {
        const data = event.pickupAddressDetails;

        return {
          pickupAddressDetails: { ...context.pickupAddressDetails, ...data },
        };
      }),
      setAccessorySelectedForRedemption: assign((context, event) => {
        const data = event.accessoryId;
        if (context.accessoryIdsSelectedForRedemption.includes(data)) {
          return {
            accessoryIdsSelectedForRedemption:
              context.accessoryIdsSelectedForRedemption.filter(
                (id: string) => id !== data,
              ),
          };
        }

        return {
          accessoryIdsSelectedForRedemption: [
            ...context.accessoryIdsSelectedForRedemption,
            data,
          ],
        };
      }),
      setImages: assign((context, event) => ({
        images: event.setImagesFromPrev(context.images),
      })),
    },
    services: {
      getPreliminaryData: (context) =>
        userReturnProcessesApi.userReturnProcessesGetReturnProcessPreliminaryData(
          {
            bikeBenefitContractId: context.contractId,
            isEndOfLeaseReturn: context.isContractEndingSoon,
          },
        ),
    },
  },
);
