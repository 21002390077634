/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyBenefitDefinitionsProcessBenefitDefinitionMerger
 */
export interface BodyBenefitDefinitionsProcessBenefitDefinitionMerger {
  /**
   *
   * @type {string}
   * @memberof BodyBenefitDefinitionsProcessBenefitDefinitionMerger
   */
  targetOrganisationBillingDetailsId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BodyBenefitDefinitionsProcessBenefitDefinitionMerger
   */
  contractIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BodyBenefitDefinitionsProcessBenefitDefinitionMerger
   */
  orderIds: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof BodyBenefitDefinitionsProcessBenefitDefinitionMerger
   */
  mergerDate: Date;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitDefinitionsProcessBenefitDefinitionMerger
   */
  copyManagers: boolean;
}

/**
 * Check if a given object implements the BodyBenefitDefinitionsProcessBenefitDefinitionMerger interface.
 */
export function instanceOfBodyBenefitDefinitionsProcessBenefitDefinitionMerger(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "targetOrganisationBillingDetailsId" in value;
  isInstance = isInstance && "contractIds" in value;
  isInstance = isInstance && "orderIds" in value;
  isInstance = isInstance && "mergerDate" in value;
  isInstance = isInstance && "copyManagers" in value;

  return isInstance;
}

export function BodyBenefitDefinitionsProcessBenefitDefinitionMergerFromJSON(
  json: any,
): BodyBenefitDefinitionsProcessBenefitDefinitionMerger {
  return BodyBenefitDefinitionsProcessBenefitDefinitionMergerFromJSONTyped(
    json,
    false,
  );
}

export function BodyBenefitDefinitionsProcessBenefitDefinitionMergerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyBenefitDefinitionsProcessBenefitDefinitionMerger {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    targetOrganisationBillingDetailsId:
      json["target_organisation_billing_details_id"],
    contractIds: json["contract_ids"],
    orderIds: json["order_ids"],
    mergerDate: new Date(json["merger_date"]),
    copyManagers: json["copy_managers"],
  };
}

export function BodyBenefitDefinitionsProcessBenefitDefinitionMergerToJSON(
  value?: BodyBenefitDefinitionsProcessBenefitDefinitionMerger | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    target_organisation_billing_details_id:
      value.targetOrganisationBillingDetailsId,
    contract_ids: value.contractIds,
    order_ids: value.orderIds,
    merger_date: value.mergerDate.toISOString().substring(0, 10),
    copy_managers: value.copyManagers,
  };
}
