import { createContext, useContext } from "react";

import { machineInitialContext } from "./machineInitialContext";
import { Typegen0 } from "./onboardingMachine.typegen";
import { AdditionalData, Context, CountryData, ProfileData } from "./types";

export interface OnboardingMachineContextProps extends Context {
  isLoading: boolean;
  requireAdditionalDetails: boolean;
  goToNext: () => void;
  goBack?: () => void;
  goToRequestByEmail: () => void;
  goToRequestByBusinessID: () => void;
  goToAddNewEmployer: () => void;
  setCountry: (data: CountryData) => void;
  setProfileData: (data: ProfileData) => void;
  setAdditionalData: (data: AdditionalData) => void;
  setBusinessId: (id: string) => void;
  setEmployer: (v: string) => void;
  setBenefit: (v: string) => void;
  saveCountry: () => void;
  saveProfileData: () => void;
  createNewEmail: (email: string) => void;
  doneAndSubmit: () => void;
  resendVerificationEmail: () => void;
  hideVerificationEmailNotification: () => void;
  stateMatches: (state: Typegen0["matchesStates"]) => boolean;
}

export const OnboardingMachineContext =
  createContext<OnboardingMachineContextProps>({
    ...machineInitialContext,
    isLoading: false,
    requireAdditionalDetails: false,
    goToNext: () => null,
    goBack: () => null,
    goToRequestByEmail: () => null,
    goToRequestByBusinessID: () => null,
    goToAddNewEmployer: () => null,
    setCountry: (data: CountryData) => null,
    setProfileData: (data: ProfileData) => null,
    setAdditionalData: (data: AdditionalData) => null,
    setBusinessId: (id: string) => null,
    setEmployer: (v: string) => null,
    setBenefit: (v: string) => null,
    saveCountry: () => null,
    saveProfileData: () => null,
    createNewEmail: (email: string) => null,
    doneAndSubmit: () => null,
    resendVerificationEmail: () => null,
    hideVerificationEmailNotification: () => null,
    stateMatches: (state: Typegen0["matchesStates"]) => false,
  });

export const useOnboardingMachine = () => useContext(OnboardingMachineContext);
