import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { ClearFiltersSection } from "@vapaus/common-v2";
import { Flex, SearchBar } from "@vapaus/ui-v2";

import { BrandSelect } from "./BrandSelect";
import { LocationSelect } from "./LocationSelect";
import { ShopTypeSelect } from "./ShopTypeSelect";

type ShopTableFiltersProps = {
  totalDisplay?: number;
  searchQuery: string;
  filteredCity: string;
  filteredShopType: string;
  filteredBrandId: string;
  areFiltersEmpty: boolean;
  setSearchQuery: (value: string) => void;
  setFilteredCity: (value: string | null) => void;
  setFilteredBrand: (value: string | null) => void;
  setFilteredShopType: (value: string | null) => void;
  clearFilters: () => void;
};

export function ShopTableFilters({
  totalDisplay,
  searchQuery,
  filteredCity,
  areFiltersEmpty,
  filteredBrandId,
  filteredShopType,
  setSearchQuery,
  setFilteredCity,
  setFilteredBrand,
  setFilteredShopType,
  clearFilters,
}: ShopTableFiltersProps) {
  const { t } = useTranslation();
  return (
    <>
      <Flex data-testid="table-filters" smDirection="column" fullWidth>
        <SearchBar
          placeholder={t("commonV2:search")}
          onSearch={setSearchQuery}
          defaultValue={searchQuery}
          fullWidth
        />
        <StyledFlex xsDirection="column">
          <BrandSelect
            brandId={filteredBrandId}
            onChange={(value) => setFilteredBrand(value)}
          />
          <ShopTypeSelect
            value={filteredShopType}
            onChange={(_, value) => setFilteredShopType(value)}
          />
          <LocationSelect
            value={filteredCity}
            onChange={(_, value) => setFilteredCity(value)}
          />
        </StyledFlex>
      </Flex>
      <ClearFiltersSection
        showingItemsText={t("userV2:getABike.partnerShops.showingNShops", {
          totalShops: totalDisplay ?? 0,
        })}
        onClick={clearFilters}
        buttonActive={!areFiltersEmpty}
      />
    </>
  );
}

const StyledFlex = styled(Flex)`
  ${({ theme }) =>
    theme.breakpoints.sm.down(css`
      width: 100%;
    `)}
`;
