import { useTranslation } from "react-i18next";

import { Button } from "@vapaus/ui-v2";

import { ReturnChecklistPage } from "../../../ReturnChecklistPage";
import { useReturnProcessContext } from "../../context/ReturnProcessContext";

export function ReturnChecklistView() {
  const { t } = useTranslation();
  const { onNext } = useReturnProcessContext();

  return (
    <ReturnChecklistPage>
      <Button onClick={onNext} fullWidth>
        {t("commonV2:next")}
      </Button>
    </ReturnChecklistPage>
  );
}
