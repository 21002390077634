import { useTranslation } from "react-i18next";

import { useCurrentUser } from "@vapaus/auth-v2";
import { Card, HorizontalLine, LineItem, Typography } from "@vapaus/ui-v2";

import { AlternateEmailList } from "../AlternateEmailList";
import { LineLanguage } from "../LineLanguage";
import { LinePassword } from "../LinePassword";

export function AccountSettingsCard() {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  return (
    <Card title={t("commonV2:accountSettings.title")}>
      <LineItem
        name={t("commonV2:accountSettings.primaryEmail")}
        content={<Typography>{user?.email}</Typography>}
      />
      <HorizontalLine />
      <AlternateEmailList />
      <HorizontalLine />
      <LinePassword />
      <HorizontalLine />
      <LineLanguage user={user} />
    </Card>
  );
}
