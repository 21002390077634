import { Path } from "../../constants/paths";
import { GetABikePage } from "./GetABikePage";
import { GoodToKnowTab } from "./components/GoodToKnowTab";
import { PartnerShopsTab } from "./components/PartnerShopsTab";

export const getABikeRoutes = {
  path: Path.getABike,
  element: <GetABikePage />,
  children: [
    {
      index: true,
      element: <PartnerShopsTab />,
    },
    {
      path: Path.goodToKnow,
      element: <GoodToKnowTab />,
    },
  ],
};
