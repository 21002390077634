import {
  ChangeEvent,
  RefObject,
  forwardRef,
  useEffect,
  useId,
  useRef,
} from "react";

import clsx from "clsx";
import styled from "styled-components";

import { Flex } from "../Flex";
import { makeTransition } from "../styles";

export type TextAreaEventHandler = (
  event: ChangeEvent<HTMLTextAreaElement>,
) => void;

export interface TextAreaProps {
  value?: string;
  onChange?: TextAreaEventHandler;
  onBlur?: TextAreaEventHandler;
  rows?: number;
  id?: string;
  name?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  error?: string;
  label?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const actualRef = (ref ?? textareaRef) as RefObject<HTMLTextAreaElement>;

    useEffect(() => {
      if (actualRef.current) {
        actualRef.current.style.height = "inherit";
        const scrollHeight = actualRef.current.scrollHeight;
        actualRef.current.style.height = scrollHeight + "px";
      }
    }, [props.value, actualRef]);

    const generatedId = useId();
    const id = props.id ?? generatedId;
    return (
      <Flex direction="column" gap="xs">
        {props.label && <StyledLabel htmlFor={id}>{props.label}</StyledLabel>}
        <StyledTextarea
          {...props}
          id={id}
          className={clsx(props.disabled && "disabled", props.error && "error")}
          ref={actualRef}
        />
        {props.error && <ErrorText role="alert">{props.error}</ErrorText>}
      </Flex>
    );
  },
);

const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const StyledTextarea = styled.textarea`
  resize: none;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
  border-color: ${({ theme }) => theme.palette.primary1.main};
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.radius.textarea};

  box-shadow: ${({ theme }) => theme.shadows.none};
  line-height: 24px;
  font-family: ${({ theme }) => theme.typography.bodyMd.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: ${({ theme }) => theme.typography.bodyMd.fontSize};
  color: ${({ theme }) => theme.typography.defaultColor};
  outline: none;

  ${makeTransition("all", "shortest", "easeOut")}
  &::placeholder {
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary1.light};
  }

  &:active,
  &:focus-within {
    box-shadow: ${({ theme }) => theme.shadows.input};
  }

  &.disabled {
    pointer-events: none;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.error};
  }
`;

const ErrorText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.typography.bodySm.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;
