import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Input } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../../stateMachine";

export function BusinessIdRequestAdditionalDataPage() {
  const { t } = useTranslation();

  const { additionalData, isLoading, goToNext, setAdditionalData } =
    useOnboardingMachine();

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.additionalData.title")}
      subtitle={t("userV2:onboarding.businessIdRequestAdditionalData.subtitle")}
    >
      <StyledContainer direction="column" gap="md" align="center">
        <Input
          fullWidth
          placeholder={t("userV2:onboarding.additionalData.employeeNumber")}
          label={t("userV2:onboarding.additionalData.employeeNumber")}
          tooltip={t("userV2:onboarding.additionalData.tooltip")}
          value={additionalData?.employeeNumber}
          onChange={(e) =>
            setAdditionalData({ employeeNumber: e.target.value })
          }
        />
        <Input
          fullWidth
          placeholder={t("userV2:onboarding.additionalData.costCenter")}
          label={t("userV2:onboarding.additionalData.costCenter")}
          tooltip={t("userV2:onboarding.additionalData.tooltip")}
          value={additionalData?.costCenter}
          onChange={(e) => setAdditionalData({ costCenter: e.target.value })}
        />
        <Button onClick={goToNext} fullWidth isLoading={isLoading}>
          {t("userV2:onboarding.next")}
        </Button>
      </StyledContainer>
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled(Flex)`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
