import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { CardCollapsible, PsychologyIcon, Typography } from "@vapaus/ui-v2";

import { BenefitCalculationWithExtra } from "../types";
import { CostAfterTaxSavings } from "./CostAfterTaxSavings";
import { IncomeTaxSaving } from "./IncomeTaxSavings";
import { LeasingContract } from "./LeasingContract";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

interface HowItWorksProps {
  calculation?: BenefitCalculationWithExtra;
}

export function HowItWorks({ calculation }: HowItWorksProps) {
  const { t } = useTranslation();
  return (
    <CardCollapsible
      icon={<PsychologyIcon />}
      title={t("calculator:howItWorks.title")}
    >
      <Column>
        <Typography>
          {calculation ? (
            calculation.savings > 0 ? (
              <Trans
                i18nKey="calculator:howItWorks.description"
                values={{
                  value: calculation.savings,
                  formatParams: {
                    value: { currency: "EUR" },
                  },
                }}
                defaults="After you choose a bike, your employer will lease it with the additional costs of <b>insurance</b> and <b>financing</b> added on top. While this increases the initial cost of the bike, you actually end up saving a total of {{ value, currency }} thanks to <b>income tax savings</b>."
                components={{ b: <b /> }}
              />
            ) : (
              <Trans
                i18nKey="calculator:howItWorks.descriptionNoSavings"
                values={{
                  value: calculation.savings,
                  formatParams: {
                    value: { currency: "EUR" },
                  },
                }}
                defaults="After you choose a bike, your employer will lease it with the additional costs of <b>insurance</b> and <b>financing</b> added on top."
                components={{ b: <b /> }}
              />
            )
          ) : (
            "-"
          )}
        </Typography>

        <LeasingContract calculation={calculation} />
        <IncomeTaxSaving calculation={calculation} />
        <CostAfterTaxSavings calculation={calculation} />
      </Column>
    </CardCollapsible>
  );
}
