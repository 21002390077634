import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PaymentConsent, PaymentConsentStatus } from "@vapaus/api-codegen";
import { CreditCardIcon, Flex, Spinner, Typography } from "@vapaus/ui-v2";

import { RemoveCardButton } from "./RemoveCardButton";

type PaymentCardItemProp = {
  paymentConsent: PaymentConsent;
};

export function PaymentCardItem({ paymentConsent }: PaymentCardItemProp) {
  const { t } = useTranslation();
  const isExpired =
    DateTime.fromObject({
      year: paymentConsent.sourceCardExpirationYear,
      month: paymentConsent.sourceCardExpirationMonth,
    }).endOf("month") <= DateTime.now();

  const isPending =
    paymentConsent.status === PaymentConsentStatus.Pending ||
    !paymentConsent.sourceBrand ||
    !paymentConsent.sourceLabel;

  return (
    <Flex align="center">
      <Flex direction="column" gap="xxs" fullWidth>
        {isPending ? (
          <StyledPendingFlex>
            <Spinner />
            <Flex direction="column" fullWidth gap="none">
              <Typography weight="bold">
                {t("userV2:paymentCard.pendingPayment.title")}
              </Typography>
              <Typography>
                {t("userV2:paymentCard.pendingPayment.description")}
              </Typography>
            </Flex>
          </StyledPendingFlex>
        ) : (
          <Flex>
            <CreditCardIcon />
            <Flex direction="column" fullWidth gap="none">
              <Typography weight="bold">
                {paymentConsent.sourceBrand} ***{paymentConsent.sourceLabel}
              </Typography>
              <Typography
                weight={isExpired ? "bold" : undefined}
                color={isExpired ? "error" : undefined}
              >
                {isExpired
                  ? t("userV2:paymentCard.expired")
                  : t("userV2:paymentCard.expires")}{" "}
                {(paymentConsent.sourceCardExpirationMonth || 0)
                  .toString()
                  .padStart(2, "0")}
                /{paymentConsent.sourceCardExpirationYear}
              </Typography>
            </Flex>
          </Flex>
        )}
      </Flex>
      <RemoveCardButton paymentConsent={paymentConsent} />
    </Flex>
  );
}

const StyledPendingFlex = styled(Flex)`
  opacity: ${({ theme }) => theme.opacity.muted};
`;
