/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BenefitCancellationCoverageStatus = {
  Active: "active",
  Pending: "pending",
  Inactive: "inactive",
} as const;
export type BenefitCancellationCoverageStatus =
  (typeof BenefitCancellationCoverageStatus)[keyof typeof BenefitCancellationCoverageStatus];

export function BenefitCancellationCoverageStatusFromJSON(
  json: any,
): BenefitCancellationCoverageStatus {
  return BenefitCancellationCoverageStatusFromJSONTyped(json, false);
}

export function BenefitCancellationCoverageStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitCancellationCoverageStatus {
  return json as BenefitCancellationCoverageStatus;
}

export function BenefitCancellationCoverageStatusToJSON(
  value?: BenefitCancellationCoverageStatus | null,
): any {
  return value as any;
}
