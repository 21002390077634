/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ProviderTerms
 */
export interface ProviderTerms {
  /**
   *
   * @type {string}
   * @memberof ProviderTerms
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ProviderTerms
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof ProviderTerms
   */
  isCurrent: boolean;
}

/**
 * Check if a given object implements the ProviderTerms interface.
 */
export function instanceOfProviderTerms(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "content" in value;
  isInstance = isInstance && "isCurrent" in value;

  return isInstance;
}

export function ProviderTermsFromJSON(json: any): ProviderTerms {
  return ProviderTermsFromJSONTyped(json, false);
}

export function ProviderTermsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ProviderTerms {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    content: json["content"],
    isCurrent: json["is_current"],
  };
}

export function ProviderTermsToJSON(value?: ProviderTerms | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    content: value.content,
    is_current: value.isCurrent,
  };
}
