import { useState } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { SaleInvoiceRead } from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import { Card, Lens3DIcon, Table, useBreakpoints } from "@vapaus/ui-v2";

import { InvoiceViewModal } from "./InvoiceViewModal/InvoiceViewModal";
import { InvoiceAmount } from "./cells/InvoiceAmount";
import { InvoiceDueDate } from "./cells/InvoiceDueDate";
import { InvoiceNumber } from "./cells/InvoiceNumber";
import { InvoiceStatus } from "./cells/InvoiceStatus";
import { useBillingTable } from "./useBillingTable";

interface BillingTableProps {
  title: string;
  bikeBenefitOrderId?: string;
  bikeBenefitContractId?: string;
}

export function BillingTable({
  title,
  bikeBenefitContractId,
  bikeBenefitOrderId,
}: BillingTableProps) {
  const { t } = useTranslation();

  const [openedInvoice, setOpenedInvoice] = useState<SaleInvoiceRead>();

  const handleOpenInvoiceView = (invoice: SaleInvoiceRead) =>
    setOpenedInvoice(invoice);
  const handleCloseInvoiceView = () => setOpenedInvoice(undefined);

  const { isMobile } = useBreakpoints();
  const columns: ColumnDef<SaleInvoiceRead>[] = [
    {
      header: t("userV2:billingTable.table.invoice"),
      cell: ({ row }) => (
        <InvoiceNumber
          invoiceNumber={row.original.invoiceNumber}
          createdAt={row.original.createdAt}
        />
      ),
      accessorKey: "createdAt",
      enableSorting: true,
      size: !isMobile ? 35 : 60,
    },
    ...(isMobile
      ? []
      : [
          {
            header: t("userV2:billingTable.table.amount"),
            cell: ({ row }: { row: Row<SaleInvoiceRead> }) => (
              <InvoiceAmount
                amount={row.original.total}
                currency={row.original.currency}
              />
            ),
            accessorKey: "total",
            enableSorting: true,
            size: 25,
          },
          {
            header: t("userV2:billingTable.table.due"),
            cell: ({ row }: { row: Row<SaleInvoiceRead> }) => (
              <InvoiceDueDate dueDate={row.original.dueDate} />
            ),
            accessorKey: "dueDate",
            enableSorting: true,
          },
        ]),
    {
      header: t("userV2:billingTable.table.status"),
      cell: ({ row }) => <InvoiceStatus status={row.original.status} />,
      accessorKey: "status",
      enableSorting: true,
      size: 20,
    },
  ];

  const { data, isLoading, paginationOptions, sortingOptions } =
    useBillingTable({
      bikeBenefitContractId,
      bikeBenefitOrderId,
    });

  return (
    <Card title={title}>
      {isLoading ? null : (
        <Table<SaleInvoiceRead>
          data={data}
          columns={data?.length ? columns : []}
          paginationOptions={paginationOptions}
          sortingOptions={sortingOptions}
          emptyStateComponent={
            <TableEmptyState
              icon={<Lens3DIcon size="md" />}
              title={t("userV2:billingTable.emptyTable.title")}
              subtitle={t("userV2:billingTable.emptyTable.subtitle")}
            />
          }
          onRowClick={handleOpenInvoiceView}
        />
      )}
      <InvoiceViewModal
        onClose={handleCloseInvoiceView}
        onResumePaymentProcess={(invoice) => setOpenedInvoice(invoice)}
        openedInvoice={openedInvoice}
      />
    </Card>
  );
}
