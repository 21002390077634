export function formatFileSize(bytes: number) {
  const multiplier = 1000;
  const decimal = 1;
  const kiloBytes = multiplier;
  const megaBytes = multiplier * multiplier;

  if (bytes < kiloBytes) {
    return bytes + " bytes";
  }
  if (bytes < megaBytes) {
    return (bytes / kiloBytes).toFixed(decimal) + " kb";
  }
  return (bytes / megaBytes).toFixed(decimal) + " mb";
}
