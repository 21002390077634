/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { WorkTypes } from "./WorkTypes";
import {
  WorkTypesFromJSON,
  WorkTypesFromJSONTyped,
  WorkTypesToJSON,
} from "./WorkTypes";

/**
 *
 * @export
 * @interface AdminMaintenanceEventCreate
 */
export interface AdminMaintenanceEventCreate {
  /**
   *
   * @type {number}
   * @memberof AdminMaintenanceEventCreate
   */
  partsPrice: number;
  /**
   *
   * @type {number}
   * @memberof AdminMaintenanceEventCreate
   */
  workPrice: number;
  /**
   *
   * @type {WorkTypes}
   * @memberof AdminMaintenanceEventCreate
   */
  workType: WorkTypes;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  workDescription: string;
  /**
   *
   * @type {Date}
   * @memberof AdminMaintenanceEventCreate
   */
  workDate: Date;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  bikeId: string;
  /**
   *
   * @type {string}
   * @memberof AdminMaintenanceEventCreate
   */
  bikeBenefitContractId?: string;
  /**
   *
   * @type {Currency}
   * @memberof AdminMaintenanceEventCreate
   */
  currency: Currency;
}

/**
 * Check if a given object implements the AdminMaintenanceEventCreate interface.
 */
export function instanceOfAdminMaintenanceEventCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "partsPrice" in value;
  isInstance = isInstance && "workPrice" in value;
  isInstance = isInstance && "workType" in value;
  isInstance = isInstance && "workDescription" in value;
  isInstance = isInstance && "workDate" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "bikeId" in value;
  isInstance = isInstance && "currency" in value;

  return isInstance;
}

export function AdminMaintenanceEventCreateFromJSON(
  json: any,
): AdminMaintenanceEventCreate {
  return AdminMaintenanceEventCreateFromJSONTyped(json, false);
}

export function AdminMaintenanceEventCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdminMaintenanceEventCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    partsPrice: json["parts_price"],
    workPrice: json["work_price"],
    workType: WorkTypesFromJSON(json["work_type"]),
    workDescription: json["work_description"],
    workDate: new Date(json["work_date"]),
    shopId: json["shop_id"],
    bikeId: json["bike_id"],
    bikeBenefitContractId: !exists(json, "bike_benefit_contract_id")
      ? undefined
      : json["bike_benefit_contract_id"],
    currency: CurrencyFromJSON(json["currency"]),
  };
}

export function AdminMaintenanceEventCreateToJSON(
  value?: AdminMaintenanceEventCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parts_price: value.partsPrice,
    work_price: value.workPrice,
    work_type: WorkTypesToJSON(value.workType),
    work_description: value.workDescription,
    work_date: value.workDate.toISOString().substring(0, 10),
    shop_id: value.shopId,
    bike_id: value.bikeId,
    bike_benefit_contract_id: value.bikeBenefitContractId,
    currency: CurrencyToJSON(value.currency),
  };
}
