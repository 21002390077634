import { filter, find, get, map, pick } from "lodash-es";
import invariant from "tiny-invariant";
import { assign, createMachine } from "xstate";

import {
  BenefitDefinitionAccessRequestStatus,
  BenefitDefinitionAccessRulesRequestRequirements,
  CurrentUserRead,
} from "@vapaus/api-codegen";

import { userBenefitDefinitionAccessRequestsAPI } from "../../../api/benefitDefinitionAccessRequests";
import { userBenefitDefinitionsAPI } from "../../../api/benefitDefinitions";
import { userBenefitDefinitionTermsAPI } from "../../../api/benefitDefitinionTerms";
import { userAPI } from "../../../api/user";
import { machineInitialContext } from "./machineInitialContext";
import {
  filterUniqueEmployers,
  standardizeCandidates,
} from "./onboardingMachineUtils";
import { Actions, Context, Events, Guards, Services } from "./types";

export const onboardingMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "main",
    initial: "loading",
    tsTypes: {} as import("./onboardingMachine.typegen").Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
      guards: {} as Guards,
      actions: {} as Actions,
    },
    context: machineInitialContext,
    states: {
      loading: {
        initial: "loadingUserData",
        states: {
          loadingUserData: {
            invoke: {
              src: "getCurrentUser",
              onDone: {
                actions: "setupUserData",
                target: "loadingPendingRequests",
              },
              onError: "loadingUserData",
            },
          },
          loadingPendingRequests: {
            invoke: {
              src: "getPendingRequests",
              onDone: {
                actions: "setupPendingRequestIds",
                target: "loadingCandidatesData",
              },
              onError: "loadingPendingRequests",
            },
          },
          loadingCandidatesData: {
            invoke: {
              src: "getCandidates",
              onDone: {
                actions: "setUpData",
                target: "#main.welcome",
              },
              onError: "loadingCandidatesData",
            },
          },
        },
      },
      welcome: {
        entry: assign({ currentStep: 1 }),
        on: {
          NEXT: "selectCountry",
          START_ADD_NEW_BENEFIT_FLOW: "updateProfile.profileForm",
          CONTINUE_AFTER_EMAIL_VERIFICATION: [
            {
              target: "selectEmployer",
              cond: "hasAtLeastOneEmployer",
            },
            {
              target: "addNewEmployer",
            },
          ],
          CONTINUE_AFTER_SUCCESSFUL_IDENTITY_VERIFICATION:
            "verifyIdentity.verificationSuccessful",
          CONTINUE_AFTER_FAILED_IDENTITY_VERIFICATION:
            "verifyIdentity.initiateVerification",
        },
      },
      selectCountry: {
        entry: assign({ currentStep: 2 }),
        initial: "countryForm",
        states: {
          countryForm: {
            on: {
              NEXT: "#main.verifyIdentity",
              SET_COUNTRY: {
                actions: assign({
                  countryData: (context, event) => event.countryData,
                }),
              },
              SAVE_COUNTRY: "saving",
            },
          },
          saving: {
            invoke: {
              src: "saveUserCountry",
              onDone: {
                target: "#main.verifyIdentity",
                actions: "clearWizardSubmitError",
              },
              onError: {
                target: "countryForm",
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
              },
            },
          },
        },
      },
      verifyIdentity: {
        entry: assign({ currentStep: 3 }),
        initial: "initiateVerification",
        states: {
          initiateVerification: {
            on: {
              BACK: "#main.selectCountry",
              NEXT: "verificationSuccessful",
            },
          },
          verificationSuccessful: {
            after: {
              3000: {
                target: "#main.updateProfile",
              },
            },
          },
        },
      },
      updateProfile: {
        entry: assign({ currentStep: 4 }),
        initial: "loadingUserData",
        states: {
          loadingUserData: {
            invoke: {
              src: "getCurrentUser",
              onDone: {
                actions: "setupUserData",
                target: "loadingCandidatesData",
              },
              onError: "loadingUserData",
            },
          },
          loadingCandidatesData: {
            invoke: {
              src: "getCandidates",
              onDone: {
                actions: "setUpData",
                target: "profileForm",
              },
              onError: "loadingCandidatesData",
            },
          },
          profileForm: {
            on: {
              NEXT: [
                {
                  target: "#main.selectEmployer",
                  cond: "hasAtLeastOneEmployer",
                },
                {
                  target: "#main.addNewEmployer",
                },
              ],
              SET_PROFILE_DATA: {
                actions: assign({
                  profileData: (context, event) => ({
                    ...context.profileData,
                    ...event.profileData,
                  }),
                }),
              },
              SAVE_PROFILE: "saving",
            },
          },
          saving: {
            invoke: {
              src: "updateCurrentUser",
              onDone: [
                {
                  target: "#main.selectEmployer",
                  cond: "hasAtLeastOneEmployer",
                  actions: "clearWizardSubmitError",
                },
                {
                  target: "#main.addNewEmployer",
                  actions: "clearWizardSubmitError",
                },
              ],
              onError: {
                target: "profileForm",
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
              },
            },
          },
        },
      },
      addNewEmployer: {
        entry: assign((context) => ({
          currentStep: context.employers.length > 0 ? 9 : 5,
        })),
        on: {
          BACK: [
            {
              target: "selectEmployer",
              cond: "hasAtLeastOneEmployer",
            },
            {
              target: "updateProfile.profileForm",
            },
          ],
          SEND_REQUEST_BY_EMAIL: "addEmailAddress",
          SEND_REQUEST_BY_BUSINESS_ID: "requestByBusinessId",
        },
      },
      addEmailAddress: {
        initial: "enterEmailForm",
        states: {
          enterEmailForm: {
            entry: assign({ currentStep: 6 }),
            on: {
              SEND_REQUEST_BY_BUSINESS_ID: {
                actions: "clearWizardSubmitError",
                target: "#main.requestByBusinessId",
              },
              BACK: {
                actions: "clearWizardSubmitError",
                target: "#main.addNewEmployer",
              },
              CREATE_NEW_EMAIL: "addingNewEmail",
            },
          },
          addingNewEmail: {
            invoke: {
              src: "createNewEmail",
              onDone: {
                actions: [
                  assign({
                    addedEmail: (_, event) => event.data,
                  }),
                  "clearWizardSubmitError",
                ],
                target: "confirm",
              },
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
                target: "enterEmailForm",
              },
            },
          },
          confirm: {
            entry: assign({ currentStep: 7 }),
            on: {
              BACK: "enterEmailForm",
              RESEND_VERIFICATION_EMAIL: "resendingVerification",
              HIDE_VERIFICATION_EMAIL_NOTIFICATION: {
                actions: assign({ emailVerificationNotification: false }),
              },
            },
          },
          resendingVerification: {
            invoke: {
              src: "resendVerificationEmail",
              onDone: {
                actions: [
                  assign({
                    emailVerificationNotification: true,
                  }),
                  "clearWizardSubmitError",
                ],
                target: "confirm",
              },
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
                target: "confirm",
              },
            },
          },
        },
      },

      requestByBusinessId: {
        initial: "enterBusinessId",
        on: {
          SET_BUSINESS_ID: {
            actions: assign({ businessId: (_, event) => event.id }),
          },
        },
        states: {
          enterBusinessId: {
            entry: assign({ currentStep: 6 }),
            on: {
              BACK: "#main.addNewEmployer",
              NEXT: "enterAdditionalDetails",
              SEND_REQUEST_BY_EMAIL: "#main.addEmailAddress",
            },
          },
          enterAdditionalDetails: {
            entry: assign({ currentStep: 7 }),
            on: {
              BACK: "enterBusinessId",
              SET_ADDITIONAL_DATA: {
                actions: "setAdditionalData",
              },
              NEXT: "updatingAdditionalData",
            },
          },
          updatingAdditionalData: {
            invoke: {
              src: "updateAdditionalData",
              onDone: {
                target: "sendingRequest",
                actions: "clearWizardSubmitError",
              },
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
                target: "enterAdditionalDetails",
              },
            },
          },
          sendingRequest: {
            invoke: {
              src: "sendRequestByBusinessId",
              onDone: {
                target: "requestSent",
                actions: "clearWizardSubmitError",
              },
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
                target: "enterAdditionalDetails",
              },
            },
          },
          requestSent: {
            type: "final",
          },
        },
      },
      selectEmployer: {
        entry: assign({ currentStep: 7 }),
        on: {
          BACK: "updateProfile.profileForm",
          NEXT: "selectBenefit",
          ADD_NEW_EMPLOYER: "addNewEmployer",
          CHANGE_EMPLOYER: {
            actions: "changeEmployer",
          },
        },
      },
      selectBenefit: {
        entry: assign({ currentStep: 8 }),
        on: {
          BACK: "selectEmployer",
          NEXT: [
            {
              target: "additionalDetails",
              cond: "requireAdditionalDetails",
            },
            {
              target: "selectionPreview",
            },
          ],
          CHANGE_BENEFIT: {
            actions: "changeBenefit",
          },
        },
      },

      additionalDetails: {
        entry: assign({ currentStep: 9 }),
        initial: "additionalDetailsForm",
        states: {
          additionalDetailsForm: {
            on: {
              BACK: "#main.selectBenefit",
              NEXT: "updatingAdditionalData",
              SET_ADDITIONAL_DATA: {
                actions: "setAdditionalData",
              },
            },
          },
          updatingAdditionalData: {
            invoke: {
              src: "updateAdditionalData",
              onDone: [
                {
                  target: "#main.selectionPreview",
                  actions: "clearWizardSubmitError",
                },
              ],
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
                target: "additionalDetailsForm",
              },
            },
          },
        },
      },

      selectionPreview: {
        initial: "loadingBenefitTerms",
        states: {
          loadingBenefitTerms: {
            invoke: {
              src: "getBenefitTerms",
              onDone: {
                actions: assign({
                  selectedBenefitTerms: (_, event) => event.data,
                }),
                target: "summary",
              },
              onError: "loadingBenefitTerms",
            },
          },
          summary: {
            entry: assign({ currentStep: 10 }),
            on: {
              BACK: [
                {
                  target: "#main.additionalDetails",
                  cond: "requireAdditionalDetails",
                },
                {
                  target: "#main.selectBenefit",
                },
              ],
              NEXT: [
                {
                  target: "#main.submitting.sendingRequest",
                  cond: "shouldSendRequest",
                },
                {
                  target: "#main.submitting.creatingActivation",
                },
              ],
            },
          },
        },
      },

      submitting: {
        states: {
          creatingActivation: {
            invoke: {
              src: "createActivation",
              onDone: {
                target: "#main.doneActivationCreated",
                actions: "clearWizardSubmitError",
              },
              onError: {
                actions: assign({
                  wizardSubmitError: (_, event) => event.data,
                }),
              },
            },
          },
          sendingRequest: {
            invoke: {
              src: "sendRequestByBenefit",
              onDone: {
                target: "#main.doneRequestSent",
                actions: "clearWizardSubmitError",
              },
              onError: [
                {
                  target: "#main.selectionPreview",
                  actions: assign({
                    wizardSubmitError: (_, event) => event.data,
                  }),
                },
              ],
            },
          },
        },
      },

      doneActivationCreated: {
        type: "final",
      },
      doneRequestSent: {
        type: "final",
      },
    },
  },
  {
    guards: {
      shouldSendRequest: (context) => {
        return (
          context.selectedBenefit?.accessRules?.requestRequirement ===
          BenefitDefinitionAccessRulesRequestRequirements.Always
        );
      },
      requireAdditionalDetails: (context) => {
        return Boolean(
          context.selectedBenefit?.isEmployeeNumberMandatory ||
            context.selectedBenefit?.isCostCenterMandatory,
        );
      },
      hasAtLeastOneEmployer: (context) => context.employers.length > 0,
    },
    actions: {
      setAdditionalData: assign((context, event) => {
        const data = event.additionalData;
        return { additionalData: { ...context.additionalData, ...data } };
      }),
      setupUserData: assign((_, event) => {
        const user: CurrentUserRead = event.data;
        const profileData = {
          firstName: get(user, "firstName", ""),
          lastName: get(user, "lastName", ""),
          address: get(user, "address", ""),
          postCode: get(user, "postCode", ""),
          city: get(user, "city", ""),
        };
        const countryData = {
          country: get(user, "country", undefined),
        };
        const additionalData = pick(user, ["costCenter", "employeeNumber"]);
        return { user, countryData, profileData, additionalData };
      }),
      setupPendingRequestIds: assign((_, event) => {
        const benefitWithPendingRequestIds = map(
          filter(
            event.data.items,
            (item) =>
              item.status === BenefitDefinitionAccessRequestStatus.Pending &&
              !!item.benefitDefinitionId,
          ),
          "benefitDefinitionId",
        ) as string[];
        return { benefitWithPendingRequestIds };
      }),
      setUpData: assign((context, event) => {
        const candidates = standardizeCandidates(context, event.data.items);

        const employers = filterUniqueEmployers(candidates);

        const selectedEmployer =
          employers.length === 1 ? employers[0] : undefined;

        const selectedEmployerBenefits = selectedEmployer
          ? filter(candidates, ["organisation.id", selectedEmployer.id])
          : [];

        return {
          candidates,
          employers,
          selectedEmployer,
          selectedEmployerBenefits,
        };
      }),

      changeEmployer: assign((context, event) => {
        const employerId = event.id;
        const selectedEmployer = find(context.employers, ["id", employerId]);
        const selectedEmployerBenefits = filter(context.candidates, [
          "organisation.id",
          employerId,
        ]);
        const selectedBenefit =
          selectedEmployerBenefits.length === 1
            ? selectedEmployerBenefits[0]
            : undefined;

        return { selectedEmployer, selectedEmployerBenefits, selectedBenefit };
      }),

      changeBenefit: assign((context, event) => {
        const id = event.id;
        const selectedBenefit = find(context.selectedEmployerBenefits, [
          "id",
          id,
        ]);
        return { selectedBenefit };
      }),

      clearWizardSubmitError: assign((_) => {
        return { wizardSubmitError: undefined };
      }),
    },
    services: {
      sendRequestByBenefit: ({ selectedBenefit, selectedEmployer, user }) => {
        invariant(
          selectedBenefit,
          "OnboardingMachine: A benefit must be selected before sending a request",
        );
        invariant(
          selectedEmployer?.businessId,
          "OnboardingMachine: An employer must be selected before sending a request",
        );
        return userBenefitDefinitionAccessRequestsAPI.benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequest(
          {
            userBenefitDefinitionAccessRequestCreate: {
              benefitDefinitionId: selectedBenefit.id,
              businessId: selectedEmployer?.businessId,
              userEmail: user.email,
            },
          },
        );
      },
      sendRequestByBusinessId: ({ user, businessId }) => {
        invariant(
          businessId,
          "OnboardingMachine: businessId must have a value",
        );
        return userBenefitDefinitionAccessRequestsAPI.benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequest(
          {
            userBenefitDefinitionAccessRequestCreate: {
              businessId: businessId,
              userEmail: user.email,
            },
          },
        );
      },
      createActivation: ({ selectedBenefit }) => {
        invariant(selectedBenefit?.id);
        return userBenefitDefinitionsAPI.benefitDefinitionsActivateBenefitDefinition(
          {
            benefitId: selectedBenefit?.id,
          },
        );
      },
      saveUserCountry: ({ countryData }) =>
        userAPI.userUpdateCurrentUser({
          userUpdate: {
            ...countryData,
          },
        }),
      updateCurrentUser: ({ profileData }) => {
        return userAPI.userUpdateCurrentUser({
          userUpdate: profileData,
        });
      },
      updateAdditionalData: ({ additionalData }) =>
        userAPI.userUpdateCurrentUser({
          userUpdate: {
            ...additionalData,
          },
        }),
      getCurrentUser: () => userAPI.userReadCurrentUser(),
      getPendingRequests: (_, __) =>
        userBenefitDefinitionAccessRequestsAPI.benefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequests(
          {
            limit: 100,
          },
        ),
      getCandidates: () =>
        userBenefitDefinitionsAPI.benefitDefinitionsGetBenefitDefinitionsCandidates(),
      createNewEmail: (_, { email }) =>
        userAPI.userCreateUserEmail({
          userEmailPrivateCreate: {
            email: email,
          },
          resumePath: `${window.location.pathname}?resume-onboarding=true`,
        }),
      resendVerificationEmail: ({ addedEmail }) => {
        invariant(addedEmail?.id);
        return userAPI.userSendEmailVerificationToken({
          userEmailId: addedEmail.id,
          resumePath: `${window.location.pathname}?resume-onboarding=true`,
        });
      },
      getBenefitTerms: ({ selectedBenefit }) =>
        userBenefitDefinitionTermsAPI.benefitDefinitionTermsGetBenefitDefinitionTerms(
          {
            benefitDefinitionTermsId:
              selectedBenefit?.currentBenefitDefinitionTermId ?? "",
          },
        ),
    },
  },
);
