import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import type { SnakeCasedProperties } from "type-fest";
import * as zod from "zod";

import { UserEmailRead } from "@vapaus/api-codegen";
import { Form, Input, useFormMutation } from "@vapaus/form";
import { userApi } from "@vapaus/shared-api";
import { Flex, MailIcon, Modal, Notification, Typography } from "@vapaus/ui-v2";

export type AddNewEmailModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  title?: string;
  description?: string;
  resumePath?: string;
};

export function AddNewEmailModal({
  isOpen,
  onClose,
  title,
  description,
  resumePath,
}: AddNewEmailModalProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();
  const emailSchema = useMemo(
    () =>
      zod.object({
        email: zod
          .string()
          .nonempty(t("authV2:signIn.enterEmail"))
          .email(t("authV2:signIn.enterValidEmail")),
      }),
    [t],
  );
  type SignInDataType = zod.infer<typeof emailSchema>;
  const { formMethods, mutation } = useFormMutation<
    SnakeCasedProperties<SignInDataType>,
    UserEmailRead
  >(
    {
      mutationFn: ({ email }) =>
        userApi.userCreateUserEmail({
          resumePath: resumePath ?? location.pathname,
          userEmailPrivateCreate: { email },
        }),
      onSuccess: () => queryClient.invalidateQueries(["currentUserEmails"]),
    },
    {
      resolver: zodResolver(emailSchema),
    },
  );

  const rootErrorMessage = formMethods.formState.errors.root?.message;
  const newAddedEmail = mutation.data;

  const handleCancel = () => {
    formMethods.reset();
    onClose();
  };

  const handleClose = () => {
    onClose();
    if (newAddedEmail) {
      formMethods.reset();
      mutation.reset();
    }
  };

  const modalTitle = title ?? t("commonV2:addEmailModal.title");
  const modalTitleAdded = t("commonV2:addEmailModal.titleAdded");
  const modalDescription =
    description ?? t("commonV2:addEmailModal.description");
  const modalCancel = t("commonV2:cancel");
  const modalGotIt = t("commonV2:gotIt");
  const modalSubmit = t("commonV2:submit");

  return (
    <Modal
      isOpen={isOpen}
      title={newAddedEmail ? modalTitleAdded : modalTitle}
      description={newAddedEmail ? undefined : modalDescription}
      cancelText={newAddedEmail ? undefined : modalCancel}
      onCancel={newAddedEmail ? undefined : handleCancel}
      confirmText={newAddedEmail ? modalGotIt : modalSubmit}
      onClose={handleClose}
      onConfirm={newAddedEmail ? handleClose : formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      {newAddedEmail ? (
        <Typography variant="bodyLg" align="center">
          <Trans
            t={t}
            i18nKey="commonV2:addEmailModal.successMessage"
            values={{ email: newAddedEmail.email }}
            components={{ 1: <strong /> }}
          />
        </Typography>
      ) : (
        <Form formMethods={formMethods}>
          <Flex direction="column" gap="sm">
            {rootErrorMessage && (
              <Notification message={rootErrorMessage} type="error" />
            )}
            <Input
              type="email"
              name="email"
              label={t("commonV2:emailAddress")}
              placeholder={t("commonV2:emailAddress")}
              startAdornment={<MailIcon />}
            />
          </Flex>
        </Form>
      )}
    </Modal>
  );
}
