/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyUserMergeUsers
 */
export interface BodyUserMergeUsers {
  /**
   *
   * @type {string}
   * @memberof BodyUserMergeUsers
   */
  obsoleteUserId: string;
  /**
   *
   * @type {string}
   * @memberof BodyUserMergeUsers
   */
  targetUserId: string;
}

/**
 * Check if a given object implements the BodyUserMergeUsers interface.
 */
export function instanceOfBodyUserMergeUsers(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "obsoleteUserId" in value;
  isInstance = isInstance && "targetUserId" in value;

  return isInstance;
}

export function BodyUserMergeUsersFromJSON(json: any): BodyUserMergeUsers {
  return BodyUserMergeUsersFromJSONTyped(json, false);
}

export function BodyUserMergeUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyUserMergeUsers {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    obsoleteUserId: json["obsolete_user_id"],
    targetUserId: json["target_user_id"],
  };
}

export function BodyUserMergeUsersToJSON(
  value?: BodyUserMergeUsers | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    obsolete_user_id: value.obsoleteUserId,
    target_user_id: value.targetUserId,
  };
}
