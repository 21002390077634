import { useUserCountry } from "@vapaus/common-v2";

import { GoodToKnowDefault } from "./components/GoodToKnowDefault";
import { GoodToKnowSweden } from "./components/GoodToKnowSweden";

export function GoodToKnowTab() {
  const { isSwedishUser } = useUserCountry();

  return isSwedishUser ? <GoodToKnowSweden /> : <GoodToKnowDefault />;
}
