import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UserEmailRead } from "@vapaus/api-codegen";
import { Flex, Typography } from "@vapaus/ui-v2";

import { DeleteEmailButton } from "./DeleteEmailButton";
import { MakePrimaryEmailButton } from "./MakePrimaryEmailButton";
import { ResendVerificationButton } from "./ResendVerificationButton";

type AlternateEmailItemProps = {
  readonly email: UserEmailRead;
};

export function AlternateEmailItem({ email }: AlternateEmailItemProps) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" gap="xs">
      <StyledTypography isVerified={email?.emailVerified}>
        {email.email} {!email.emailVerified && `(${t("commonV2:pending")})`}
      </StyledTypography>
      <Flex align="center" gap="xs">
        {email.emailVerified ? (
          <MakePrimaryEmailButton email={email} />
        ) : (
          <ResendVerificationButton email={email} />
        )}
        <span>•</span>
        <DeleteEmailButton email={email} />
      </Flex>
    </Flex>
  );
}

const StyledTypography = styled(Typography)<{
  isVerified?: boolean;
}>`
  opacity: ${({ theme, isVerified }) =>
    isVerified ? 1 : theme.opacity.disabled};
`;
