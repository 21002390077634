import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, Flex, Paper, Typography } from "@vapaus/ui-v2";

import { useReturnProcessContext } from "../../../context/ReturnProcessContext";
import { useVapausSelfReturnBikeCenterAddress } from "../../../hooks/useVapausSelfReturnBikeCenterAddress";

type ImageAddressCardProps = {
  image: string;
  url: string;
};

export function ImageAddressCard({ image, url }: ImageAddressCardProps) {
  const { t } = useTranslation();
  const { addressLine1, addressLine2 } = useVapausSelfReturnBikeCenterAddress();
  const { onNext } = useReturnProcessContext();

  return (
    <StyledCard gap="none" direction="column">
      <a href={url} target="_blank" rel="noreferrer">
        <StyledImage data-testid="background-image" image={image} />
      </a>
      <Paper radius="none">
        <Flex direction="column" gap="lg" align="center">
          <Flex direction="column" gap="xxs" align="center">
            <Typography weight="bold">
              {t("userV2:returnProcess.bikeCenterInfo.ourAddress")}
            </Typography>
            <div>
              <Typography align="center">{addressLine1}</Typography>
              <Typography align="center">{addressLine2}</Typography>
            </div>
          </Flex>
          <Button onClick={onNext} fullWidth>
            {t("commonV2:continue")}
          </Button>
        </Flex>
      </Paper>
    </StyledCard>
  );
}

const StyledCard = styled(Flex)`
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.card};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const StyledImage = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  height: 200px;
`;
