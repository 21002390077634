import { useTranslation } from "react-i18next";

import { UserShopRead } from "@vapaus/api-codegen";
import {
  EmailField,
  Flex,
  Notification,
  PhoneField,
  TextButton,
  Typography,
} from "@vapaus/ui-v2";

import { extractHostnameFromUrl } from "../../../../../../../../utils/extractHostnameFromUrl";
import { ShopLogo } from "./ShopLogo";
import { HardCodedContentType } from "./useHardCodedContent";

type CustomModalContentProps = {
  shop?: UserShopRead;
  hardCodedContent: HardCodedContentType;
};

export function CustomModalContent({
  shop,
  hardCodedContent,
}: CustomModalContentProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" align="center" gap="md">
      <Flex direction="column" align="center" gap="xs">
        <ShopLogo logoUrl={shop?.logoUrl ?? hardCodedContent.logo} />
        <Typography variant="h2" align="center">
          {shop?.name}
        </Typography>
        {shop?.website ? (
          <a href={shop?.website} target="_blank" rel="noreferrer">
            <Typography>{extractHostnameFromUrl(shop.website)}</Typography>
          </a>
        ) : null}
      </Flex>
      {shop?.email || shop?.phoneNumber ? (
        <Flex
          smDirection="column"
          align="center"
          justify="center"
          flexWrap="wrap"
        >
          {shop?.email ? <EmailField email={shop.email} gap="xs" /> : null}
          {shop?.phoneNumber ? (
            <PhoneField phoneNumber={shop.phoneNumber} gap="xs" />
          ) : null}
        </Flex>
      ) : null}

      <Notification type="success">
        <Typography weight="bold" marginBottom="xs">
          {hardCodedContent.title}
        </Typography>
        <Typography marginBottom="sm">
          {hardCodedContent.description}
        </Typography>
        <TextButton
          to={hardCodedContent.instructionLink}
          target="_blank"
          size="md"
        >
          {t("userV2:getABike.customShopModal.viewDetailedInstructions")}
        </TextButton>
      </Notification>
    </Flex>
  );
}
