import { MouseEventHandler, ReactNode } from "react";

import { get } from "lodash-es";
import styled, { css } from "styled-components";

import { Spinner } from "../Spinner";
import {
  PaletteVariants,
  ShadowVariants,
  SpacingVariants,
  Theme,
} from "../styles";
import {
  ResponsiveProps,
  ResponsivePropsBase,
  createResponsiveProps,
} from "../styles/createResponsiveProps";

export type ButtonVariants =
  | "primary1"
  | "primary2"
  | "primaryDanger"
  | "outlined"
  | "outlinedDanger";

export type ButtonSizes = "default" | "small";

interface ButtonStateDescription {
  border: PaletteVariants;
  background: PaletteVariants;
  text: PaletteVariants;
  shadow: ShadowVariants;
}

type VariantsDescription = {
  [key in ButtonVariants]: ButtonStateDescription;
};

const variants: VariantsDescription = {
  primary1: {
    border: "primary1.main",
    background: "primary1.main",
    text: "primary2.main",
    shadow: "lg",
  },
  primary2: {
    border: "primary2.main",
    background: "primary2.main",
    text: "primary1.main",
    shadow: "lg",
  },
  primaryDanger: {
    border: "error",
    background: "error",
    text: "common.white",
    shadow: "lg",
  },
  outlined: {
    border: "primary1.main",
    background: "common.transparent",
    text: "primary1.main",
    shadow: "none",
  },
  outlinedDanger: {
    border: "error",
    background: "common.transparent",
    text: "error",
    shadow: "none",
  },
};

function makeButtonColor({
  theme,
  variant = "primary1",
  isLoading = false,
}: {
  theme: Theme;
  variant?: ButtonProps["variant"];
  isLoading?: boolean;
}) {
  const variantDesc = variants[variant];

  return css`
    background-color: ${get(theme.palette, variantDesc.background)};
    color: ${get(theme.palette, variantDesc.text)};
    outline-color: ${get(theme.palette, variantDesc.border)};
    outline-width: 2px;
    outline-style: solid;
    outline-offset: -2px;
    border: none;
    box-shadow: ${get(theme.shadows, variantDesc.shadow)};

    &:hover:enabled {
      background-color: ${get(theme.palette, "background.white")};
      color: ${get(theme.palette, "primary1.main")};
      outline: none;
      box-shadow: ${get(theme, "shadows.lg")};
    }

    &:active:enabled {
      background-color: ${get(theme.palette, "background.white")};
      color: ${get(theme.palette, "primary1.main")};
      outline: none;
      box-shadow: ${get(theme, "shadows.inset")};
    }

    ${isLoading &&
    css`
      background-color: ${get(theme.palette, "background.white")};
      color: ${get(theme.palette, "primary1.main")};
      outline: none;
      box-shadow: ${get(theme, "shadows.inset")};
      pointer-events: none;
    `}
  `;
}

interface ButtonResponsivePropsBase extends ResponsivePropsBase {
  fullWidth?: boolean;
}

const responsiveProps = createResponsiveProps<ButtonResponsivePropsBase>(
  {
    FullWidth: "width",
  },
  { FullWidth: (theme, value?: boolean) => (value ? "100%" : "auto") },
);

export interface ButtonProps
  extends ButtonResponsivePropsBase,
    ResponsiveProps<ButtonResponsivePropsBase> {
  variant?: ButtonVariants;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  marginBottom?: SpacingVariants;
  marginTop?: SpacingVariants;
  size?: ButtonSizes;
  disabled?: boolean;
  isLoading?: boolean;
}

export const Button = (props: ButtonProps) => (
  <StyledRoot {...props} size={props.size ?? "default"}>
    {props.isLoading ? <Spinner data-testid="spinner" /> : props.children}
  </StyledRoot>
);

const StyledRoot = styled.button<ButtonProps>`
  ${(props) =>
    props.size === "small"
      ? css`
          padding: ${({ theme }) => css`
            ${theme.spacing.xs} ${theme.spacing.md}
          `};
        `
      : css`
          padding: ${({ theme }) =>
            css`
        calc(${theme.spacing.sm} - 2px) ${theme.spacing.lg}
      `};
        `};

  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
  text-transform: ${({ theme }) => theme.typography.button.textTransform};
  border-radius: ${({ theme }) => theme.radius.button};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  min-width: 10rem;
  margin-top: ${({ marginTop = "none", theme }) => theme.spacing[marginTop]};
  margin-bottom: ${({ marginBottom = "none", theme }) =>
    theme.spacing[marginBottom]};
  box-sizing: border-box;
  cursor: pointer;

  ${responsiveProps}

  ${makeButtonColor};
  transition:
    all ${({ theme }) => theme.transitions.duration.shortest}ms
      ${({ theme }) => theme.transitions.easing.easeOut},
    outline 0s;

  &:disabled {
    opacity: ${({ theme }) => theme.opacity.disabled};
    cursor: not-allowed;
  }
`;
