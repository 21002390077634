import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Flex, FullPageModal, Markdown, Typography } from "@vapaus/ui-v2";

interface BenefitTermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
}

export function BenefitTermsModal({
  isOpen,
  onClose,
  title,
  content,
}: BenefitTermsModalProps) {
  const { t } = useTranslation();

  return (
    <FullPageModal isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" align="center" gap="none">
        <Typography variant="h5" align="center" marginBottom="sm">
          {title}
        </Typography>
        <Typography variant="h1" align="center" marginBottom="md">
          {t("commonV2:benefitGuideline.title")}
        </Typography>
        <StyledMarkdown content={content} />
      </Flex>
    </FullPageModal>
  );
}

const StyledMarkdown = styled(Markdown)`
  max-width: 960px;
  width: 100%;
  ${({ theme }) =>
    theme.breakpoints.md.down(css`
      padding-left: ${theme.spacing.lg};
      padding-right: ${theme.spacing.lg};
    `)}
  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding-left: ${theme.spacing.md};
      padding-right: ${theme.spacing.md};
    `)}
`;
