import { assign, createMachine } from "xstate";

import { Context, Events } from "./types";

export const redemptionProcessMachine = createMachine({
  predictableActionArguments: true,
  id: "RedemptionProcess",
  initial: "initialize",
  context: {
    step: 1,
    startingState: "welcome",
    isContractEndingSoon: false,
  },
  schema: {
    events: {} as Events,
    context: {} as Context,
  },
  tsTypes: {} as import("./RedemptionProcessMachine.typegen").Typegen0,
  states: {
    initialize: {
      always: [
        {
          target: "paymentProcessing",
          cond: (context) => context.startingState === "paymentProcessing",
        },
        {
          target: "emailAddress",
          cond: (context) => context.startingState === "emailAddress",
        },
        {
          target: "welcome",
        },
      ],
    },
    welcome: {
      entry: assign({ step: 1 }),
      on: {
        NEXT: [
          {
            target: "redemptionReason",
            cond: (context): boolean => !context.isContractEndingSoon,
          },
          {
            target: "emailAddress",
          },
        ],
      },
    },
    redemptionReason: {
      entry: assign({ step: 2 }),
      on: {
        NEXT: "emailAddress",
        BACK: "welcome",
        SET_REDEMPTION_REASON: {
          actions: assign({
            redemptionReason: (_, event) => event.redemptionReason,
          }),
        },
      },
    },
    emailAddress: {
      entry: assign({ step: 3 }),
      on: {
        BACK: [
          {
            target: "redemptionReason",
            cond: (context): boolean => !context.isContractEndingSoon,
          },
          {
            target: "welcome",
          },
        ],
        NEXT: "priceConfirmation",
      },
    },
    priceConfirmation: {
      entry: assign({ step: 4 }),
      on: {
        BACK: "emailAddress",
      },
    },
    paymentProcessing: {
      entry: assign({ step: 5 }),
      on: {
        COMPLETED: "processCompleted",
      },
    },
    processCompleted: {
      type: "final",
      entry: assign({ step: 6 }),
    },
  },
});
