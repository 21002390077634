/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  newPasswordConfirmation: string;
}

/**
 * Check if a given object implements the PasswordChange interface.
 */
export function instanceOfPasswordChange(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "oldPassword" in value;
  isInstance = isInstance && "newPassword" in value;
  isInstance = isInstance && "newPasswordConfirmation" in value;

  return isInstance;
}

export function PasswordChangeFromJSON(json: any): PasswordChange {
  return PasswordChangeFromJSONTyped(json, false);
}

export function PasswordChangeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PasswordChange {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    oldPassword: json["old_password"],
    newPassword: json["new_password"],
    newPasswordConfirmation: json["new_password_confirmation"],
  };
}

export function PasswordChangeToJSON(value?: PasswordChange | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    old_password: value.oldPassword,
    new_password: value.newPassword,
    new_password_confirmation: value.newPasswordConfirmation,
  };
}
