import { ReactNode } from "react";

import { get } from "lodash-es";
import styled, { css } from "styled-components";

import { Typography } from "../Typography";
import { PaletteVariants, Theme } from "../styles";

export type TagSize = "default" | "large";

export type TagVariant =
  | "pending"
  | "accepted"
  | "waiting"
  | "error"
  | "done"
  | "neutral"
  | "cancelled";

interface TagStateDescription {
  borderColor: PaletteVariants | string;
  background: PaletteVariants;
  text: PaletteVariants;
}

type VariantsDescription = {
  [key in TagVariant]: TagStateDescription;
};

// TODO: it would be good to not include business logic in our ui library
// either we should come up with a generic color scheme or move it outside of the ui package,
// currently it's tighten to the order state.
export const variants: VariantsDescription = {
  pending: {
    borderColor: "secondary3.main",
    background: "secondary3.light",
    text: "primary1.main",
  },
  accepted: {
    borderColor: "secondary1.main",
    background: "secondary1.light",
    text: "primary1.main",
  },
  waiting: {
    borderColor: "primary2.main",
    background: "primary2.light",
    text: "primary1.main",
  },
  error: {
    borderColor: "secondary2.main",
    background: "secondary2.light",
    text: "primary1.main",
  },
  done: {
    borderColor: "primary1.main",
    background: "common.transparent",
    text: "primary1.main",
  },
  neutral: {
    borderColor: "primary1.light",
    background: "common.transparent",
    text: "primary1.light",
  },
  cancelled: {
    borderColor: "error",
    background: "common.transparent",
    text: "error",
  },
};

export type TagProps = {
  size?: TagSize;
  variant: TagVariant;
  text: ReactNode;
};

function makeTagColor({
  theme,
  variant = "done",
}: {
  theme: Theme;
  variant?: TagProps["variant"];
}) {
  const variantDesc = variants[variant];

  return css`
    background-color: ${get(theme.palette, variantDesc.background)};
    border: 2px solid
      ${get(theme.palette, variantDesc.borderColor, variantDesc.borderColor)};
  `;
}

export function Tag({ size = "default", variant, text }: TagProps) {
  return (
    <StyledRoot size={size} variant={variant}>
      <Typography
        variant={size === "default" ? "bodyXs" : "h6"}
        weight="bold"
        color={variants[variant].text}
      >
        {text}
      </Typography>
    </StyledRoot>
  );
}

const StyledRoot = styled.div<Omit<TagProps, "text">>`
  ${({ theme, size, variant }) =>
    size === "default"
      ? css`
          padding: calc(${theme.spacing.xxs} - 2px) ${theme.spacing.xs};
          border-radius: 0.375rem;
        `
      : css`
          padding: calc(${theme.spacing.xs} - 2px) ${theme.spacing.sm};
          border-radius: 0.5rem;
        `};

  width: fit-content;
  ${makeTagColor};
`;
