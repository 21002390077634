/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const WorkTypes = {
  FirstMaintenance: "first-maintenance",
  SeasonalMaintenance: "seasonal-maintenance",
  OtherMaintenance: "other-maintenance",
} as const;
export type WorkTypes = (typeof WorkTypes)[keyof typeof WorkTypes];

export function WorkTypesFromJSON(json: any): WorkTypes {
  return WorkTypesFromJSONTyped(json, false);
}

export function WorkTypesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WorkTypes {
  return json as WorkTypes;
}

export function WorkTypesToJSON(value?: WorkTypes | null): any {
  return value as any;
}
