import { capitalize, lowerCase } from "lodash-es";

import { ResponseError } from "@vapaus/api-codegen";

export async function parseApiError(
  responseError: ResponseError,
): Promise<string> {
  const error = await responseError.response?.clone().json();
  if (typeof error.detail === "string") return error.detail;
  if (Array.isArray(error.detail)) {
    const field = error.detail[0].loc.slice(1).join(" ");
    return `${capitalize(lowerCase(field))}: ${error.detail[0].msg}`;
  }
  return "Unknown error!";
}
