import { useTranslation } from "react-i18next";

import { Button, Flex, Typography } from "@vapaus/ui-v2";

export function GoodToKnowSweden() {
  const { t } = useTranslation();

  return (
    <Flex direction="column" align="flex-start">
      <Typography variant="h2">{t("userV2:getABike.goodToKnow")}</Typography>
      <Typography variant="bodyLg" marginBottom="xs">
        {t("userV2:sweden.getABike.goodToKnowDescription")}
      </Typography>
      <a
        href={t("userV2:sweden.getABike.knowledgeBaseLink")}
        target="_blank"
        rel="noreferrer"
      >
        <Button>{t("userV2:getABike.learnMore")}</Button>
      </a>
    </Flex>
  );
}
