/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { Accessory } from "./Accessory";
import {
  AccessoryFromJSON,
  AccessoryFromJSONTyped,
  AccessoryToJSON,
} from "./Accessory";
import type { AdditionalService } from "./AdditionalService";
import {
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
} from "./AdditionalService";
import type { BenefitCancellationCoverage } from "./BenefitCancellationCoverage";
import {
  BenefitCancellationCoverageFromJSON,
  BenefitCancellationCoverageFromJSONTyped,
  BenefitCancellationCoverageToJSON,
} from "./BenefitCancellationCoverage";
import type { BikeAdminRead } from "./BikeAdminRead";
import {
  BikeAdminReadFromJSON,
  BikeAdminReadFromJSONTyped,
  BikeAdminReadToJSON,
} from "./BikeAdminRead";
import type { BikeBenefitOrderCancellationReason } from "./BikeBenefitOrderCancellationReason";
import {
  BikeBenefitOrderCancellationReasonFromJSON,
  BikeBenefitOrderCancellationReasonFromJSONTyped,
  BikeBenefitOrderCancellationReasonToJSON,
} from "./BikeBenefitOrderCancellationReason";
import type { BikeBenefitOrderDeliveryTransport } from "./BikeBenefitOrderDeliveryTransport";
import {
  BikeBenefitOrderDeliveryTransportFromJSON,
  BikeBenefitOrderDeliveryTransportFromJSONTyped,
  BikeBenefitOrderDeliveryTransportToJSON,
} from "./BikeBenefitOrderDeliveryTransport";
import type { BikeBenefitOrderState } from "./BikeBenefitOrderState";
import {
  BikeBenefitOrderStateFromJSON,
  BikeBenefitOrderStateFromJSONTyped,
  BikeBenefitOrderStateToJSON,
} from "./BikeBenefitOrderState";
import type { BikeBenefitOrderingMethod } from "./BikeBenefitOrderingMethod";
import {
  BikeBenefitOrderingMethodFromJSON,
  BikeBenefitOrderingMethodFromJSONTyped,
  BikeBenefitOrderingMethodToJSON,
} from "./BikeBenefitOrderingMethod";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { UserRead } from "./UserRead";
import {
  UserReadFromJSON,
  UserReadFromJSONTyped,
  UserReadToJSON,
} from "./UserRead";

/**
 *
 * @export
 * @interface BikeBenefitOrderRead
 */
export interface BikeBenefitOrderRead {
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  vapausCode: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  orderNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  correctsBikeBenefitContractId?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  revisesBikeBenefitContractId?: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  revisionApplyDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  legacyUserName?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  userId?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isCancelled?: boolean;
  /**
   *
   * @type {BikeBenefitOrderCancellationReason}
   * @memberof BikeBenefitOrderRead
   */
  cancellationReason?: BikeBenefitOrderCancellationReason;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  currentVersion?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  acceptedVersion?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  benefitDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  benefitDefinitionTermsId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  leasingPeriodMonths: number;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  shopId: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  bikeId: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  bikePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BikeBenefitOrderRead
   */
  currency: Currency;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  monthlyMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  flatVatRate: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  hasExtendedWarranty: boolean;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  fixedDownPaymentAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  fixedMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderRead
   */
  taxablePackagePriceReduction?: number;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isDownPaymentInsured: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBenefitOrderRead
   */
  isBillable?: boolean;
  /**
   *
   * @type {BenefitCancellationCoverage}
   * @memberof BikeBenefitOrderRead
   */
  cancellationCoverage?: BenefitCancellationCoverage;
  /**
   *
   * @type {BikeBenefitOrderingMethod}
   * @memberof BikeBenefitOrderRead
   */
  orderingMethod?: BikeBenefitOrderingMethod;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {UserRead}
   * @memberof BikeBenefitOrderRead
   */
  user?: UserRead;
  /**
   *
   * @type {BikeAdminRead}
   * @memberof BikeBenefitOrderRead
   */
  bike: BikeAdminRead;
  /**
   *
   * @type {Array<Accessory>}
   * @memberof BikeBenefitOrderRead
   */
  accessories: Array<Accessory>;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof BikeBenefitOrderRead
   */
  additionalServices: Array<AdditionalService>;
  /**
   *
   * @type {BikeBenefitOrderState}
   * @memberof BikeBenefitOrderRead
   */
  state: BikeBenefitOrderState;
  /**
   *
   * @type {BikeBenefitOrderDeliveryTransport}
   * @memberof BikeBenefitOrderRead
   */
  deliveryTransport?: BikeBenefitOrderDeliveryTransport;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  currentBikeBenefitContractId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  bikeBenefitContractIds: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof BikeBenefitOrderRead
   */
  deliveryDate?: Date;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  revisesBikeBenefitContractVapausCode?: string;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderRead
   */
  correctsBikeBenefitContractVapausCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  revisedByBikeBenefitOrderVapausCodes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  correctedByBikeBenefitOrderVapausCodes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  revisedByBikeBenefitOrderIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BikeBenefitOrderRead
   */
  correctedByBikeBenefitOrderIds: Array<string>;
}

/**
 * Check if a given object implements the BikeBenefitOrderRead interface.
 */
export function instanceOfBikeBenefitOrderRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "vapausCode" in value;
  isInstance = isInstance && "benefitDefinitionId" in value;
  isInstance = isInstance && "benefitDefinitionTermsId" in value;
  isInstance = isInstance && "leasingPeriodMonths" in value;
  isInstance = isInstance && "shopId" in value;
  isInstance = isInstance && "bikeId" in value;
  isInstance = isInstance && "bikePrice" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "monthlyMaintenanceBudget" in value;
  isInstance = isInstance && "flatVatRate" in value;
  isInstance = isInstance && "hasExtendedWarranty" in value;
  isInstance = isInstance && "isDownPaymentInsured" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "bike" in value;
  isInstance = isInstance && "accessories" in value;
  isInstance = isInstance && "additionalServices" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "bikeBenefitContractIds" in value;
  isInstance = isInstance && "revisedByBikeBenefitOrderVapausCodes" in value;
  isInstance = isInstance && "correctedByBikeBenefitOrderVapausCodes" in value;
  isInstance = isInstance && "revisedByBikeBenefitOrderIds" in value;
  isInstance = isInstance && "correctedByBikeBenefitOrderIds" in value;

  return isInstance;
}

export function BikeBenefitOrderReadFromJSON(json: any): BikeBenefitOrderRead {
  return BikeBenefitOrderReadFromJSONTyped(json, false);
}

export function BikeBenefitOrderReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vapausCode: json["vapaus_code"],
    orderNumber: !exists(json, "order_number")
      ? undefined
      : json["order_number"],
    correctsBikeBenefitContractId: !exists(
      json,
      "corrects_bike_benefit_contract_id",
    )
      ? undefined
      : json["corrects_bike_benefit_contract_id"],
    revisesBikeBenefitContractId: !exists(
      json,
      "revises_bike_benefit_contract_id",
    )
      ? undefined
      : json["revises_bike_benefit_contract_id"],
    revisionApplyDate: !exists(json, "revision_apply_date")
      ? undefined
      : new Date(json["revision_apply_date"]),
    legacyUserName: !exists(json, "legacy_user_name")
      ? undefined
      : json["legacy_user_name"],
    userId: !exists(json, "user_id") ? undefined : json["user_id"],
    isCancelled: !exists(json, "is_cancelled")
      ? undefined
      : json["is_cancelled"],
    cancellationReason: !exists(json, "cancellation_reason")
      ? undefined
      : BikeBenefitOrderCancellationReasonFromJSON(json["cancellation_reason"]),
    currentVersion: !exists(json, "current_version")
      ? undefined
      : json["current_version"],
    acceptedVersion: !exists(json, "accepted_version")
      ? undefined
      : json["accepted_version"],
    benefitDefinitionId: json["benefit_definition_id"],
    benefitDefinitionTermsId: json["benefit_definition_terms_id"],
    leasingPeriodMonths: json["leasing_period_months"],
    shopId: json["shop_id"],
    bikeId: json["bike_id"],
    bikePrice: json["bike_price"],
    currency: CurrencyFromJSON(json["currency"]),
    monthlyMaintenanceBudget: json["monthly_maintenance_budget"],
    flatVatRate: json["flat_vat_rate"],
    hasExtendedWarranty: json["has_extended_warranty"],
    fixedDownPaymentAmount: !exists(json, "fixed_down_payment_amount")
      ? undefined
      : json["fixed_down_payment_amount"],
    fixedMonthlyTaxableValue: !exists(json, "fixed_monthly_taxable_value")
      ? undefined
      : json["fixed_monthly_taxable_value"],
    taxablePackagePriceReduction: !exists(
      json,
      "taxable_package_price_reduction",
    )
      ? undefined
      : json["taxable_package_price_reduction"],
    isDownPaymentInsured: json["is_down_payment_insured"],
    notes: !exists(json, "notes") ? undefined : json["notes"],
    isBillable: !exists(json, "is_billable") ? undefined : json["is_billable"],
    cancellationCoverage: !exists(json, "cancellation_coverage")
      ? undefined
      : BenefitCancellationCoverageFromJSON(json["cancellation_coverage"]),
    orderingMethod: !exists(json, "ordering_method")
      ? undefined
      : BikeBenefitOrderingMethodFromJSON(json["ordering_method"]),
    id: json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
    user: !exists(json, "user") ? undefined : UserReadFromJSON(json["user"]),
    bike: BikeAdminReadFromJSON(json["bike"]),
    accessories: (json["accessories"] as Array<any>).map(AccessoryFromJSON),
    additionalServices: (json["additional_services"] as Array<any>).map(
      AdditionalServiceFromJSON,
    ),
    state: BikeBenefitOrderStateFromJSON(json["state"]),
    deliveryTransport: !exists(json, "delivery_transport")
      ? undefined
      : BikeBenefitOrderDeliveryTransportFromJSON(json["delivery_transport"]),
    currentBikeBenefitContractId: !exists(
      json,
      "current_bike_benefit_contract_id",
    )
      ? undefined
      : json["current_bike_benefit_contract_id"],
    bikeBenefitContractIds: json["bike_benefit_contract_ids"],
    deliveryDate: !exists(json, "delivery_date")
      ? undefined
      : new Date(json["delivery_date"]),
    revisesBikeBenefitContractVapausCode: !exists(
      json,
      "revises_bike_benefit_contract_vapaus_code",
    )
      ? undefined
      : json["revises_bike_benefit_contract_vapaus_code"],
    correctsBikeBenefitContractVapausCode: !exists(
      json,
      "corrects_bike_benefit_contract_vapaus_code",
    )
      ? undefined
      : json["corrects_bike_benefit_contract_vapaus_code"],
    revisedByBikeBenefitOrderVapausCodes:
      json["revised_by_bike_benefit_order_vapaus_codes"],
    correctedByBikeBenefitOrderVapausCodes:
      json["corrected_by_bike_benefit_order_vapaus_codes"],
    revisedByBikeBenefitOrderIds: json["revised_by_bike_benefit_order_ids"],
    correctedByBikeBenefitOrderIds: json["corrected_by_bike_benefit_order_ids"],
  };
}

export function BikeBenefitOrderReadToJSON(
  value?: BikeBenefitOrderRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vapaus_code: value.vapausCode,
    order_number: value.orderNumber,
    corrects_bike_benefit_contract_id: value.correctsBikeBenefitContractId,
    revises_bike_benefit_contract_id: value.revisesBikeBenefitContractId,
    revision_apply_date:
      value.revisionApplyDate === undefined
        ? undefined
        : value.revisionApplyDate.toISOString().substring(0, 10),
    legacy_user_name: value.legacyUserName,
    user_id: value.userId,
    is_cancelled: value.isCancelled,
    cancellation_reason: BikeBenefitOrderCancellationReasonToJSON(
      value.cancellationReason,
    ),
    current_version: value.currentVersion,
    accepted_version: value.acceptedVersion,
    benefit_definition_id: value.benefitDefinitionId,
    benefit_definition_terms_id: value.benefitDefinitionTermsId,
    leasing_period_months: value.leasingPeriodMonths,
    shop_id: value.shopId,
    bike_id: value.bikeId,
    bike_price: value.bikePrice,
    currency: CurrencyToJSON(value.currency),
    monthly_maintenance_budget: value.monthlyMaintenanceBudget,
    flat_vat_rate: value.flatVatRate,
    has_extended_warranty: value.hasExtendedWarranty,
    fixed_down_payment_amount: value.fixedDownPaymentAmount,
    fixed_monthly_taxable_value: value.fixedMonthlyTaxableValue,
    taxable_package_price_reduction: value.taxablePackagePriceReduction,
    is_down_payment_insured: value.isDownPaymentInsured,
    notes: value.notes,
    is_billable: value.isBillable,
    cancellation_coverage: BenefitCancellationCoverageToJSON(
      value.cancellationCoverage,
    ),
    ordering_method: BikeBenefitOrderingMethodToJSON(value.orderingMethod),
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    user: UserReadToJSON(value.user),
    bike: BikeAdminReadToJSON(value.bike),
    accessories: (value.accessories as Array<any>).map(AccessoryToJSON),
    additional_services: (value.additionalServices as Array<any>).map(
      AdditionalServiceToJSON,
    ),
    state: BikeBenefitOrderStateToJSON(value.state),
    delivery_transport: BikeBenefitOrderDeliveryTransportToJSON(
      value.deliveryTransport,
    ),
    current_bike_benefit_contract_id: value.currentBikeBenefitContractId,
    bike_benefit_contract_ids: value.bikeBenefitContractIds,
    delivery_date:
      value.deliveryDate === undefined
        ? undefined
        : value.deliveryDate.toISOString().substring(0, 10),
    revises_bike_benefit_contract_vapaus_code:
      value.revisesBikeBenefitContractVapausCode,
    corrects_bike_benefit_contract_vapaus_code:
      value.correctsBikeBenefitContractVapausCode,
    revised_by_bike_benefit_order_vapaus_codes:
      value.revisedByBikeBenefitOrderVapausCodes,
    corrected_by_bike_benefit_order_vapaus_codes:
      value.correctedByBikeBenefitOrderVapausCodes,
    revised_by_bike_benefit_order_ids: value.revisedByBikeBenefitOrderIds,
    corrected_by_bike_benefit_order_ids: value.correctedByBikeBenefitOrderIds,
  };
}
