import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { ApiError } from "@vapaus/generated";
import { useToaster } from "@vapaus/ui-v2";
import { parseApiError } from "@vapaus/utils";

import {
  useAcceptAppTerms,
  useCurrentAppTerm,
  useCurrentUser,
} from "../../api";
import { AppName } from "../../constants";
import { useLogout } from "../../hooks";

export const useTermsConsentPanel = (app: AppName) => {
  const { data: appUserTerms } = useCurrentAppTerm(app, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const acceptAppTerms = useAcceptAppTerms(app);

  const queryClient = useQueryClient();
  const toaster = useToaster();

  const { t } = useTranslation();
  const { error: currentUserError, data: user } = useCurrentUser();

  useEffect(() => {
    if (!appUserTerms && currentUserError)
      toaster.add("error", currentUserError.message);
  }, [appUserTerms, currentUserError, toaster, t]);

  const areTermsUpdated =
    app === "provider"
      ? !!user?.acceptedProviderTermsId
      : app === "shop"
      ? !!user?.acceptedShopTermsId
      : !!user?.acceptedUserTermsId;

  const handleAcceptTerms = async () => {
    if (acceptAppTerms.isLoading) return;
    try {
      await acceptAppTerms.mutateAsync(appUserTerms!.id);
      const updatedTermsAcceptanceMessage = t(
        "authV2:termsOfService.updatedTermsAccepted",
      );
      const termsAcceptanceMessage = t("authV2:termsOfService.termsAccepted");
      toaster.add(
        "success",
        areTermsUpdated
          ? updatedTermsAcceptanceMessage
          : termsAcceptanceMessage,
      );
      queryClient.invalidateQueries(["currentUser"]);
    } catch (error: unknown) {
      toaster.add("error", parseApiError(error as ApiError, t));
    }
  };

  const handleLogout = useLogout();

  return { handleAcceptTerms, handleLogout, appUserTerms, areTermsUpdated };
};
