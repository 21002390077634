import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { Token } from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { Form, PasswordInput, useFormMutation } from "@vapaus/form";
import { userApi } from "@vapaus/shared-api";
import { Flex, Modal, Notification, useToaster } from "@vapaus/ui-v2";

export type ChangePasswordModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export function ChangePasswordModal({
  isOpen,
  onClose,
}: ChangePasswordModalProps) {
  const { t } = useTranslation();
  const toaster = useToaster();

  const passwordSchema = useMemo(
    () =>
      zod.object({
        old_password: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        new_password: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      }),
    [t],
  );

  type PasswordDataType = zod.infer<typeof passwordSchema>;

  const { formMethods, mutation } = useFormMutation<PasswordDataType, Token>(
    {
      mutationFn: (data) =>
        userApi.userChangePassword({
          passwordChange: {
            oldPassword: data.old_password,
            newPassword: data.new_password,
            newPasswordConfirmation: data.new_password,
          },
        }),
      onSuccess: (res) => {
        apiConfiguration.setToken(res.accessToken);
        toaster.add(
          "success",
          t("commonV2:changePasswordModal.successMessage"),
        );
        handleCancel();
      },
    },
    {
      resolver: zodResolver(passwordSchema),
    },
  );
  const rootErrorMessage = formMethods.formState.errors.root?.message;

  const handleCancel = () => {
    formMethods.reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("commonV2:changePasswordModal.title")}
      description={t("commonV2:changePasswordModal.description")}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:submit")}
      onCancel={handleCancel}
      onClose={onClose}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <Form formMethods={formMethods}>
        <Flex direction="column" gap="sm">
          {rootErrorMessage && (
            <Notification message={rootErrorMessage} type="error" />
          )}
          <PasswordInput
            name="new_password"
            label={t("commonV2:changePasswordModal.newPassword")}
            placeholder={t("commonV2:changePasswordModal.newPassword")}
          />
          <PasswordInput
            name="old_password"
            label={t("commonV2:changePasswordModal.currentPassword")}
            placeholder={t("commonV2:changePasswordModal.currentPassword")}
          />
        </Flex>
      </Form>
    </Modal>
  );
}
