import { ReactNode } from "react";

import styled, { css } from "styled-components";

type PageLayoutProps = {
  children: ReactNode;
};

export function PageLayout({ children }: PageLayoutProps) {
  return (
    <StyledRoot>
      <StyledContent>{children}</StyledContent>
    </StyledRoot>
  );
}

// TODO: The min-height should be removed when we add the footer
const StyledRoot = styled.div`
  background-color: ${({ theme }) => theme.palette.background.secondary};
  padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xl}`};
  min-height: calc(100vh - ${({ theme }) => theme.height.navBar});

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      padding: ${theme.spacing.xl} ${theme.spacing.md};
    `)}
`;

const StyledContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
