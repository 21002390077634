import { SelectProps } from "@mui/base/Select/Select.types";
import { useTranslation } from "react-i18next";

import { Select } from "@vapaus/ui-v2";

import { useSearchLocation } from "../../../../../../../api/shop";

type LocationSelectProps = Pick<
  SelectProps<string, false>,
  "value" | "onChange"
>;

export function LocationSelect({ value, onChange }: LocationSelectProps) {
  const { t } = useTranslation();

  const { data } = useSearchLocation();
  const orderStatusSelectOptions = [
    { value: "", label: t("userV2:getABike.partnerShops.allLocations") },
    ...(data || []).map((location) => ({
      value: location.city,
      label: location.city,
    })),
  ];

  return (
    <Select<string>
      options={orderStatusSelectOptions}
      value={value}
      onChange={onChange}
      fullWidth
    />
  );
}
