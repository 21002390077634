/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const BikeBenefitOrderCancellationReason = {
  Expired: "expired",
} as const;
export type BikeBenefitOrderCancellationReason =
  (typeof BikeBenefitOrderCancellationReason)[keyof typeof BikeBenefitOrderCancellationReason];

export function BikeBenefitOrderCancellationReasonFromJSON(
  json: any,
): BikeBenefitOrderCancellationReason {
  return BikeBenefitOrderCancellationReasonFromJSONTyped(json, false);
}

export function BikeBenefitOrderCancellationReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderCancellationReason {
  return json as BikeBenefitOrderCancellationReason;
}

export function BikeBenefitOrderCancellationReasonToJSON(
  value?: BikeBenefitOrderCancellationReason | null,
): any {
  return value as any;
}
