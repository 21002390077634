import {
  Slider as BaseSlider,
  SliderOwnProps,
  sliderClasses,
} from "@mui/base/Slider";
import styled from "styled-components";

import { MainPaletteVariants, makeTransition } from "../styles";

export interface SliderProps extends SliderOwnProps {
  color: Exclude<MainPaletteVariants, "neutral">;
}

export const Slider = styled(BaseSlider)<SliderProps>`
  height: 6px;
  padding: 18px 0;
  width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.neutral.main};
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme, color = "secondary1" }) =>
      theme.palette[color].main};
  }

  & .${sliderClasses.thumb} {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-left: -5px;
    margin-top: -5px;
    border-radius: 50%;
    background-color: ${({ theme, color = "secondary1" }) =>
      theme.palette[color].main};
    ${makeTransition("outline-width", "shorter", "easeInOut")}
    outline: 0 solid ${({ theme, color = "secondary1" }) =>
      theme.palette[color].light};

    &:hover {
      ${makeTransition("outline-width", "shorter", "easeOut")}
      outline: 4px solid
        ${({ theme, color = "secondary1" }) => theme.palette[color].light};
    }

    &.${sliderClasses.active}, &.${sliderClasses.focusVisible} {
      ${makeTransition("outline-width", "shorter", "easeOut")}
      outline: 6px solid
        ${({ theme, color = "secondary1" }) => theme.palette[color].light};
    }
  }

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  }
`;
