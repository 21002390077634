import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Button, Flex, Input, TextButton, Typography } from "@vapaus/ui-v2";

import { OnboardingPageLayout } from "../../layout/OnboardingPageLayout";
import { useOnboardingMachine } from "../../stateMachine";
import { BusinessIdPlaceholder, BusinessIdSearchUrl } from "./constants";
import { BusinessIDType, businessIdSchema } from "./formSchemaAndType";

export function BusinessIdRequestPage() {
  const { t } = useTranslation();

  const {
    goToNext,
    goToRequestByEmail,
    setBusinessId,
    businessId,
    countryData,
  } = useOnboardingMachine();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessIDType>({
    resolver: zodResolver(businessIdSchema(t)),
    defaultValues: { country: countryData.country, businessId: businessId },
  });
  const businessIdSearchUrl = BusinessIdSearchUrl[countryData.country!];
  const businessIdPlaceholder = BusinessIdPlaceholder[countryData.country!];

  return (
    <OnboardingPageLayout
      title={t("userV2:onboarding.businessIdRequest.title")}
      subtitle={t("userV2:onboarding.businessIdRequest.subtitle")}
    >
      <StyledContainer direction="column" gap="md">
        <form onSubmit={handleSubmit(() => goToNext())}>
          <Flex direction="column" gap="md" align="center">
            <Input
              fullWidth
              placeholder={t(
                "userV2:onboarding.businessIdRequest.businessId.placeholder",
                { format: businessIdPlaceholder },
              )}
              label={t("userV2:onboarding.businessIdRequest.businessId.label")}
              error={errors.businessId?.message}
              {...register("businessId", {
                required: true,
                onChange: (e) => setBusinessId(e.target.value),
              })}
            />
            <Typography variant="bodySm">
              <span>
                {t("userV2:onboarding.businessIdRequest.instructions")}
              </span>
              <a
                href={`https://${businessIdSearchUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {businessIdSearchUrl}
              </a>
            </Typography>
            <Button fullWidth type="submit">
              {t("userV2:onboarding.next")}
            </Button>
          </Flex>
        </form>
        <Flex direction="column" gap="xs" align="center">
          <Typography>
            {t("userV2:onboarding.businessIdRequest.doYouHaveWorkEmail")}
          </Typography>
          <TextButton size="md" onClick={goToRequestByEmail}>
            {t("userV2:onboarding.businessIdRequest.useWorkEmail")}
          </TextButton>
        </Flex>
      </StyledContainer>
    </OnboardingPageLayout>
  );
}

const StyledContainer = styled(Flex)`
  width: 24rem;

  ${({ theme }) =>
    theme.breakpoints.xs.down(css`
      width: 20rem;
    `)}
`;
