/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { BenefitDefinitionAccessRulesUserRead } from "./BenefitDefinitionAccessRulesUserRead";
import {
  BenefitDefinitionAccessRulesUserReadFromJSON,
  BenefitDefinitionAccessRulesUserReadFromJSONTyped,
  BenefitDefinitionAccessRulesUserReadToJSON,
} from "./BenefitDefinitionAccessRulesUserRead";
import type { BenefitDefinitionCancellationCoverage } from "./BenefitDefinitionCancellationCoverage";
import {
  BenefitDefinitionCancellationCoverageFromJSON,
  BenefitDefinitionCancellationCoverageFromJSONTyped,
  BenefitDefinitionCancellationCoverageToJSON,
} from "./BenefitDefinitionCancellationCoverage";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
} from "./Currency";
import type { OrganisationSummaryRead } from "./OrganisationSummaryRead";
import {
  OrganisationSummaryReadFromJSON,
  OrganisationSummaryReadFromJSONTyped,
  OrganisationSummaryReadToJSON,
} from "./OrganisationSummaryRead";
import type { Plan } from "./Plan";
import { PlanFromJSON, PlanFromJSONTyped, PlanToJSON } from "./Plan";

/**
 *
 * @export
 * @interface BenefitDefinitionCandidate
 */
export interface BenefitDefinitionCandidate {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCandidate
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCandidate
   */
  endDate: Date;
  /**
   *
   * @type {OrganisationSummaryRead}
   * @memberof BenefitDefinitionCandidate
   */
  organisation: OrganisationSummaryRead;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  toWhom?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  accessRulesUuid?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isEmployeeNumberMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isCompanyMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isCostCenterMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isDownpaymentAllowed: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  minimumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumTotalPackagePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BenefitDefinitionCandidate
   */
  currency: Currency;
  /**
   *
   * @type {Plan}
   * @memberof BenefitDefinitionCandidate
   */
  plan: Plan;
  /**
   *
   * @type {BenefitDefinitionCancellationCoverage}
   * @memberof BenefitDefinitionCandidate
   */
  cancellationCoverage: BenefitDefinitionCancellationCoverage;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  individualOnetimeCancellationCoverage: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isAvailable: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  currentBenefitDefinitionTermId?: string;
  /**
   *
   * @type {BenefitDefinitionAccessRulesUserRead}
   * @memberof BenefitDefinitionCandidate
   */
  accessRules?: BenefitDefinitionAccessRulesUserRead;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumTaxablePackagePrice?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  minimumMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumMonthlyTaxableValue?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BenefitDefinitionCandidate
   */
  fixedMonthlyTaxableValues?: Array<number>;
}

/**
 * Check if a given object implements the BenefitDefinitionCandidate interface.
 */
export function instanceOfBenefitDefinitionCandidate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "organisation" in value;
  isInstance = isInstance && "active" in value;
  isInstance = isInstance && "isEmployeeNumberMandatory" in value;
  isInstance = isInstance && "isCompanyMandatory" in value;
  isInstance = isInstance && "isCostCenterMandatory" in value;
  isInstance = isInstance && "isDownpaymentAllowed" in value;
  isInstance = isInstance && "minimumLeasingPeriod" in value;
  isInstance = isInstance && "maximumLeasingPeriod" in value;
  isInstance = isInstance && "maximumTotalPackagePrice" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "plan" in value;
  isInstance = isInstance && "cancellationCoverage" in value;
  isInstance = isInstance && "individualOnetimeCancellationCoverage" in value;
  isInstance = isInstance && "isAvailable" in value;

  return isInstance;
}

export function BenefitDefinitionCandidateFromJSON(
  json: any,
): BenefitDefinitionCandidate {
  return BenefitDefinitionCandidateFromJSONTyped(json, false);
}

export function BenefitDefinitionCandidateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCandidate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    organisation: OrganisationSummaryReadFromJSON(json["organisation"]),
    active: json["active"],
    toWhom: !exists(json, "to_whom") ? undefined : json["to_whom"],
    accessRulesUuid: !exists(json, "access_rules_uuid")
      ? undefined
      : json["access_rules_uuid"],
    isEmployeeNumberMandatory: json["is_employee_number_mandatory"],
    isCompanyMandatory: json["is_company_mandatory"],
    isCostCenterMandatory: json["is_cost_center_mandatory"],
    isDownpaymentAllowed: json["is_downpayment_allowed"],
    minimumLeasingPeriod: json["minimum_leasing_period"],
    maximumLeasingPeriod: json["maximum_leasing_period"],
    maximumTotalPackagePrice: json["maximum_total_package_price"],
    currency: CurrencyFromJSON(json["currency"]),
    plan: PlanFromJSON(json["plan"]),
    cancellationCoverage: BenefitDefinitionCancellationCoverageFromJSON(
      json["cancellation_coverage"],
    ),
    individualOnetimeCancellationCoverage:
      json["individual_onetime_cancellation_coverage"],
    isAvailable: json["is_available"],
    currentBenefitDefinitionTermId: !exists(
      json,
      "current_benefit_definition_term_id",
    )
      ? undefined
      : json["current_benefit_definition_term_id"],
    accessRules: !exists(json, "access_rules")
      ? undefined
      : BenefitDefinitionAccessRulesUserReadFromJSON(json["access_rules"]),
    maximumTaxablePackagePrice: !exists(json, "maximum_taxable_package_price")
      ? undefined
      : json["maximum_taxable_package_price"],
    minimumMonthlyTaxableValue: !exists(json, "minimum_monthly_taxable_value")
      ? undefined
      : json["minimum_monthly_taxable_value"],
    maximumMonthlyTaxableValue: !exists(json, "maximum_monthly_taxable_value")
      ? undefined
      : json["maximum_monthly_taxable_value"],
    fixedMonthlyTaxableValues: !exists(json, "fixed_monthly_taxable_values")
      ? undefined
      : json["fixed_monthly_taxable_values"],
  };
}

export function BenefitDefinitionCandidateToJSON(
  value?: BenefitDefinitionCandidate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    start_date: value.startDate.toISOString().substring(0, 10),
    end_date: value.endDate.toISOString().substring(0, 10),
    organisation: OrganisationSummaryReadToJSON(value.organisation),
    active: value.active,
    to_whom: value.toWhom,
    access_rules_uuid: value.accessRulesUuid,
    is_employee_number_mandatory: value.isEmployeeNumberMandatory,
    is_company_mandatory: value.isCompanyMandatory,
    is_cost_center_mandatory: value.isCostCenterMandatory,
    is_downpayment_allowed: value.isDownpaymentAllowed,
    minimum_leasing_period: value.minimumLeasingPeriod,
    maximum_leasing_period: value.maximumLeasingPeriod,
    maximum_total_package_price: value.maximumTotalPackagePrice,
    currency: CurrencyToJSON(value.currency),
    plan: PlanToJSON(value.plan),
    cancellation_coverage: BenefitDefinitionCancellationCoverageToJSON(
      value.cancellationCoverage,
    ),
    individual_onetime_cancellation_coverage:
      value.individualOnetimeCancellationCoverage,
    is_available: value.isAvailable,
    current_benefit_definition_term_id: value.currentBenefitDefinitionTermId,
    access_rules: BenefitDefinitionAccessRulesUserReadToJSON(value.accessRules),
    maximum_taxable_package_price: value.maximumTaxablePackagePrice,
    minimum_monthly_taxable_value: value.minimumMonthlyTaxableValue,
    maximum_monthly_taxable_value: value.maximumMonthlyTaxableValue,
    fixed_monthly_taxable_values: value.fixedMonthlyTaxableValues,
  };
}
