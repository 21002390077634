import { toUnicode } from "punycode";

export function extractHostnameFromUrl(urlString: string): string {
  try {
    const url = new URL(urlString);
    return toUnicode(url.hostname);
  } catch (error) {
    return urlString;
  }
}
