import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Card3DIcon, Flex, Typography } from "@vapaus/ui-v2";

import { Path } from "../../../constants/paths";

export function EmptyPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex direction="column" align="center" gap="md">
      <Card3DIcon size="lg" />
      <Flex direction="column" align="center" gap="md">
        <Flex direction="column" align="center" gap="sm">
          <Typography variant="h1" align="center">
            {t("userV2:benefits.empty.title")}
          </Typography>
          <Typography variant="bodyLg" align="center">
            {t("userV2:benefits.empty.description")}
          </Typography>
        </Flex>
        <Button onClick={() => navigate(`/${Path.onboarding}`)}>
          {t("userV2:benefits.empty.linkBenefit")}
        </Button>
      </Flex>
    </Flex>
  );
}
