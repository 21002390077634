import { words } from "lodash-es";

export function getStringInitials(s?: string | null, length?: number) {
  const wordsInString = words(s ?? "");
  return wordsInString
    .map((w) => w[0])
    .slice(0, length ?? wordsInString.length)
    .join("")
    .toUpperCase();
}
