/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { StorageObjectRead } from "./StorageObjectRead";
import {
  StorageObjectReadFromJSON,
  StorageObjectReadFromJSONTyped,
  StorageObjectReadToJSON,
} from "./StorageObjectRead";
import type { SupportedOrganisationCountries } from "./SupportedOrganisationCountries";
import {
  SupportedOrganisationCountriesFromJSON,
  SupportedOrganisationCountriesFromJSONTyped,
  SupportedOrganisationCountriesToJSON,
} from "./SupportedOrganisationCountries";

/**
 *
 * @export
 * @interface OrganisationSummaryRead
 */
export interface OrganisationSummaryRead {
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OrganisationSummaryRead
   */
  businessId: string;
  /**
   *
   * @type {StorageObjectRead}
   * @memberof OrganisationSummaryRead
   */
  logoObject?: StorageObjectRead;
  /**
   *
   * @type {SupportedOrganisationCountries}
   * @memberof OrganisationSummaryRead
   */
  country: SupportedOrganisationCountries;
}

/**
 * Check if a given object implements the OrganisationSummaryRead interface.
 */
export function instanceOfOrganisationSummaryRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "businessId" in value;
  isInstance = isInstance && "country" in value;

  return isInstance;
}

export function OrganisationSummaryReadFromJSON(
  json: any,
): OrganisationSummaryRead {
  return OrganisationSummaryReadFromJSONTyped(json, false);
}

export function OrganisationSummaryReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganisationSummaryRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    businessId: json["business_id"],
    logoObject: !exists(json, "logo_object")
      ? undefined
      : StorageObjectReadFromJSON(json["logo_object"]),
    country: SupportedOrganisationCountriesFromJSON(json["country"]),
  };
}

export function OrganisationSummaryReadToJSON(
  value?: OrganisationSummaryRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    business_id: value.businessId,
    logo_object: StorageObjectReadToJSON(value.logoObject),
    country: SupportedOrganisationCountriesToJSON(value.country),
  };
}
