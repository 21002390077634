import { useTranslation } from "react-i18next";

import { Flex, SpinningLogo, Typography } from "@vapaus/ui-v2";

export function PaymentProcessingView() {
  const { t } = useTranslation();
  return (
    <Flex direction="column" align="center" gap="md">
      <SpinningLogo />
      <Typography variant="h2" align="center">
        {t("userV2:billingTable.paymentProcessing.title")}
      </Typography>
      <Typography variant="bodyLg" align="center" marginBottom="lg">
        {t("userV2:billingTable.paymentProcessing.description")}
      </Typography>
    </Flex>
  );
}
