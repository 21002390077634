import { CallIcon } from "../Icons";
import { Typography } from "../Typography";
import { FieldRoot, FieldRootProps } from "./Field";

interface PhoneFieldProps extends Pick<FieldRootProps, "marginBottom" | "gap"> {
  phoneNumber: string;
}

export function PhoneField({
  phoneNumber,
  marginBottom,
  gap,
}: PhoneFieldProps) {
  return (
    <FieldRoot
      marginBottom={marginBottom}
      as="a"
      href={`tel:${phoneNumber.replace(" ", "")}`}
      gap={gap}
    >
      <CallIcon />
      <Typography weight="bold">{phoneNumber}</Typography>
    </FieldRoot>
  );
}
