import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";

import { UserEmailRead } from "@vapaus/api-codegen";
import { useMarkUserEmailAsPrimary } from "@vapaus/shared-api";
import { Modal, TextButton, Typography, useToaster } from "@vapaus/ui-v2";

type MakePrimaryEmailButtonProps = {
  readonly email: UserEmailRead;
};

export function MakePrimaryEmailButton({ email }: MakePrimaryEmailButtonProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);

  const makeEmailPrimary = useMarkUserEmailAsPrimary();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    makeEmailPrimary.mutate(email.id, {
      onSuccess: (data) => {
        handleCloseModal();
        toaster.add("success", data.msg);
        queryClient.invalidateQueries(["currentUserEmails"]);
        queryClient.invalidateQueries(["currentUser"]);
      },
      onError: async (error) => {
        const message = await error.response.json();
        toaster.add("error", message.detail);
      },
    });
  };

  return (
    <>
      <TextButton onClick={() => setIsOpen(true)} size="md">
        {t("commonV2:alternateEmail.makePrimaryEmailModal.makePrimary")}
      </TextButton>

      <Modal
        isOpen={isOpen}
        title={t("commonV2:alternateEmail.makePrimaryEmailModal.title")}
        cancelText={t("commonV2:cancel")}
        confirmText={t(
          "commonV2:alternateEmail.makePrimaryEmailModal.changeEmail",
        )}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        onConfirm={handleDelete}
        isSubmitting={makeEmailPrimary.isLoading}
      >
        <Typography align="center" variant="bodyLg">
          <Trans
            t={t}
            i18nKey="commonV2:alternateEmail.makePrimaryEmailModal.description"
            values={{ email: email.email }}
            components={{ 1: <strong /> }}
          />
        </Typography>
      </Modal>
    </>
  );
}
