import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ErrorPage } from "../ErrorPage";

export function EmailVerificationLinkExpiredErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogIn = () => navigate("/sign-in");
  const handleResetPassword = () => navigate("/forgot-password");

  return (
    <ErrorPage
      title={t("authV2:error.emailVerificationLinkExpiredErrorPage.title")}
      description={t(
        "authV2:error.emailVerificationLinkExpiredErrorPage.description",
      )}
      primaryButton={{
        text: t("authV2:error.emailVerificationLinkExpiredErrorPage.logIn"),
        onClick: handleLogIn,
      }}
      secondaryButton={{
        text: t(
          "authV2:error.emailVerificationLinkExpiredErrorPage.resetPassword",
        ),
        onClick: handleResetPassword,
      }}
    />
  );
}
