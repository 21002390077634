/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface RedemptionProcessCalculation
 */
export interface RedemptionProcessCalculation {
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessCalculation
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  availableMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionPrice: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  monthsSinceDelivered: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  leasingFeesPaid: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionFee: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  discount: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  overspentMaintenanceBudget: number;
}

/**
 * Check if a given object implements the RedemptionProcessCalculation interface.
 */
export function instanceOfRedemptionProcessCalculation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "plannedRedemptionDate" in value;
  isInstance = isInstance && "availableMaintenanceBudget" in value;
  isInstance = isInstance && "redemptionPrice" in value;
  isInstance = isInstance && "redemptionPercentage" in value;
  isInstance = isInstance && "monthsSinceDelivered" in value;
  isInstance = isInstance && "leasingFeesPaid" in value;
  isInstance = isInstance && "redemptionFee" in value;
  isInstance = isInstance && "discount" in value;
  isInstance = isInstance && "overspentMaintenanceBudget" in value;

  return isInstance;
}

export function RedemptionProcessCalculationFromJSON(
  json: any,
): RedemptionProcessCalculation {
  return RedemptionProcessCalculationFromJSONTyped(json, false);
}

export function RedemptionProcessCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessCalculation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    availableMaintenanceBudget: json["available_maintenance_budget"],
    redemptionPrice: json["redemption_price"],
    redemptionPercentage: json["redemption_percentage"],
    monthsSinceDelivered: json["months_since_delivered"],
    leasingFeesPaid: json["leasing_fees_paid"],
    redemptionFee: json["redemption_fee"],
    discount: json["discount"],
    overspentMaintenanceBudget: json["overspent_maintenance_budget"],
  };
}

export function RedemptionProcessCalculationToJSON(
  value?: RedemptionProcessCalculation | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    planned_redemption_date: value.plannedRedemptionDate
      .toISOString()
      .substring(0, 10),
    available_maintenance_budget: value.availableMaintenanceBudget,
    redemption_price: value.redemptionPrice,
    redemption_percentage: value.redemptionPercentage,
    months_since_delivered: value.monthsSinceDelivered,
    leasing_fees_paid: value.leasingFeesPaid,
    redemption_fee: value.redemptionFee,
    discount: value.discount,
    overspent_maintenance_budget: value.overspentMaintenanceBudget,
  };
}
