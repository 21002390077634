import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { SettingsPageLayout } from "@vapaus/settings";
import { useCurrentUser } from "@vapaus/shared-api";
import { PersonIcon, ReceiptIcon, SettingsIcon } from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";

export function SettingsPage() {
  const { data: user } = useCurrentUser();
  const { t } = useTranslation();

  const tabItems = [
    {
      icon: PersonIcon,
      label: t("commonV2:userProfile.profile"),
      to: Path.userProfile,
    },
    {
      icon: SettingsIcon,
      label: t("commonV2:userProfile.settings"),
      to: Path.settings,
    },
    {
      icon: ReceiptIcon,
      label: t("commonV2:userProfile.billing"),
      to: Path.billing,
    },
  ];

  return (
    <SettingsPageLayout
      title={t("commonV2:userProfile.accountSettings")}
      name={user?.fullName}
      email={user?.email}
      tabItems={tabItems}
    >
      <Outlet />
    </SettingsPageLayout>
  );
}
