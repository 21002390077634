import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ErrorPage } from "../ErrorPage";

export function GenericErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);
  const handleTryAgain = () => window.location.reload();
  return (
    <ErrorPage
      title={t("authV2:error.genericErrorPage.title")}
      description={t("authV2:error.genericErrorPage.description")}
      primaryButton={{
        text: t("authV2:error.genericErrorPage.tryAgain"),
        onClick: handleTryAgain,
      }}
      secondaryButton={{
        text: t("authV2:error.genericErrorPage.goBack"),
        onClick: handleGoBack,
      }}
    />
  );
}
