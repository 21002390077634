import { useTranslation } from "react-i18next";

import { SaleInvoiceRead } from "@vapaus/api-codegen";
import { useEnumEntries } from "@vapaus/shared-api";
import { Flex, HorizontalLine, RowItem, Typography } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  useDateFormat,
} from "../../../../../../packages/i18n/src/hooks";
import { useGetBikeBenefitOrder } from "../../../api/order";
import { InvoiceStatus } from "../cells/InvoiceStatus";

type InvoiceDetailsViewProps = {
  invoice: SaleInvoiceRead;
};

export function InvoiceDetailsView({ invoice }: InvoiceDetailsViewProps) {
  const { t } = useTranslation();
  const formatDate = useDateFormat();
  const formatCurrency = useCurrencyFormat(invoice.currency);
  const { data: bikeOrder } = useGetBikeBenefitOrder(invoice?.vapausCode);
  const { getEnumLabel } = useEnumEntries("SaleInvoiceLineType");

  return (
    <Flex direction="column" align="center" gap="md">
      <InvoiceStatus status={invoice.status} size="large" />
      <Flex direction="column" gap="xs">
        <Typography variant="h6" align="center">
          {bikeOrder?.bike?.bikeBrand.name} {bikeOrder?.bike?.model}
        </Typography>
        <Typography variant="h2" align="center">
          {t("userV2:billingTable.invoiceNumber", {
            invoiceNumber: invoice.invoiceNumber,
          })}
        </Typography>
        <Typography align="center">{formatDate(invoice.createdAt)}</Typography>
      </Flex>
      <Flex direction="column" gap="sm" fullWidth>
        <RowItem
          label={t("userV2:billingTable.type")}
          value={t("userV2:billingTable.sum")}
          valueIsBold
          justify="space-between"
        />
        <HorizontalLine marginTop="xs" marginBottom="xs" />
        {invoice.saleInvoiceLines.map((line) => (
          <RowItem
            key={line.id}
            label={getEnumLabel(line.type)}
            value={formatCurrency(line.total)}
            labelIsBold={false}
            justify="space-between"
          />
        ))}
        <HorizontalLine marginTop="xs" marginBottom="xs" />
        <RowItem
          label={t("userV2:billingTable.total")}
          value={formatCurrency(invoice.total)}
          valueIsBold
          justify="space-between"
        />
        <RowItem
          label={t("userV2:billingTable.dueDate")}
          value={formatDate(invoice.dueDate)}
          valueIsBold
          justify="space-between"
        />
      </Flex>
    </Flex>
  );
}
