import { createSvgIcon } from "./SvgIcon";

export const LabelIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M5 19C4.45 19 3.97917 18.8042 3.5875 18.4125C3.19583 18.0208 3 17.55 3 17V7C3 6.45 3.19583 5.97917 3.5875 5.5875C3.97917 5.19583 4.45 5 5 5H15C15.3333 5 15.6458 5.075 15.9375 5.225C16.2292 5.375 16.4667 5.58333 16.65 5.85L20.175 10.85C20.4083 11.2 20.525 11.5833 20.525 12C20.525 12.4167 20.4083 12.8 20.175 13.15L16.65 18.15C16.4667 18.4167 16.2292 18.625 15.9375 18.775C15.6458 18.925 15.3333 19 15 19H5ZM18.55 12L15 7H5V17H15L18.55 12ZM5 12V17V7V12Z" />
  </svg>,
  "Label",
);
