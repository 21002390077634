import { useCallback, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

import { apiConfiguration } from "../../api";
import { authTokenKey } from "../../constants";

export function useApiTokenState(): [
  string | null,
  (token: string | null) => void,
] {
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [token, setToken] = useState<string | null>(() => {
    const searchToken = searchParams.get("access_token");
    if (searchToken) localStorage.setItem(authTokenKey, searchToken);
    const token = localStorage.getItem(authTokenKey);
    if (token) apiConfiguration.setToken(token);
    return token;
  });

  const _setToken = useCallback(
    (token: string | null) => {
      setToken(token);
      if (token) {
        apiConfiguration.setToken(token);
      } else {
        queryClient.clear();
        apiConfiguration.clearToken();
      }
    },
    [setToken, queryClient],
  );

  return [token, _setToken];
}
