import { useEffect, useMemo } from "react";

import { useMachine } from "@xstate/react";
import { useSearchParams } from "react-router-dom";

import { OnboardingContainer } from "./OnboardingContainer";
import { OnboardingMachineContext } from "./OnboardingMachineContext";
import { onboardingMachine } from "./onboardingMachine";
import {
  AdditionalData,
  CountryData,
  Guards,
  MachineType,
  ProfileData,
} from "./types";

interface OnboardingWizardProps {
  machineType: MachineType;
}

export function OnboardingWizard({ machineType }: OnboardingWizardProps) {
  const params = useSearchParams();
  const resumeOnboarding =
    params?.length > 0 ? Boolean(params[0].get("resume-onboarding")) : false;
  const identityVerificationFailed =
    params?.length > 0 ? Boolean(params[0].get("verifyError")) : false;
  const identityVerificationSucceeded =
    params?.length > 0 ? Boolean(params[0].get("verifySuccess")) : false;

  const [state, send] = useMachine(onboardingMachine);
  const context = state.context;
  const stateMatches = state.matches;

  const isLoading =
    stateMatches("selectCountry.saving") ||
    stateMatches("updateProfile.loadingCandidatesData") ||
    stateMatches("updateProfile.loadingUserData") ||
    stateMatches("updateProfile.saving") ||
    stateMatches("addEmailAddress.addingNewEmail") ||
    stateMatches("addEmailAddress.resendingVerification") ||
    stateMatches("requestByBusinessId.sendingRequest") ||
    stateMatches("requestByBusinessId.updatingAdditionalData") ||
    stateMatches("additionalDetails.updatingAdditionalData") ||
    stateMatches("submitting.creatingActivation") ||
    stateMatches("submitting.sendingRequest");

  useEffect(() => {
    if (!stateMatches("welcome")) {
      return;
    }

    if (resumeOnboarding) {
      send("CONTINUE_AFTER_EMAIL_VERIFICATION");
    }
    if (machineType === "addNewBenefit") {
      send("START_ADD_NEW_BENEFIT_FLOW");
    }
    if (machineType === "onboard" && identityVerificationFailed) {
      send("CONTINUE_AFTER_FAILED_IDENTITY_VERIFICATION");
    }
    if (machineType === "onboard" && identityVerificationSucceeded) {
      send("CONTINUE_AFTER_SUCCESSFUL_IDENTITY_VERIFICATION");
    }
  }, [
    machineType,
    send,
    stateMatches,
    resumeOnboarding,
    identityVerificationFailed,
    identityVerificationSucceeded,
  ]);

  const guards = onboardingMachine.options.guards as unknown as Guards;
  const requireAdditionalDetails = guards.requireAdditionalDetails(context);

  const canGoBack = !(
    stateMatches("selectCountry") ||
    stateMatches("verifyIdentity.verificationSuccessful") ||
    stateMatches("updateProfile")
  );

  const value = useMemo(
    () => ({
      ...context,
      isLoading,
      requireAdditionalDetails,

      stateMatches,

      goToNext: () => send("NEXT"),
      goBack: canGoBack ? () => send("BACK") : undefined,
      goToRequestByEmail: () => send("SEND_REQUEST_BY_EMAIL"),
      goToRequestByBusinessID: () => send("SEND_REQUEST_BY_BUSINESS_ID"),
      goToAddNewEmployer: () => send("ADD_NEW_EMPLOYER"),

      setCountry: (countryData: CountryData) =>
        send("SET_COUNTRY", { countryData }),
      setProfileData: (profileData: ProfileData) =>
        send("SET_PROFILE_DATA", { profileData }),
      setAdditionalData: (additionalData: AdditionalData) =>
        send("SET_ADDITIONAL_DATA", { additionalData }),
      setBusinessId: (id: string) => send("SET_BUSINESS_ID", { id }),
      setEmployer: (id: string) => send("CHANGE_EMPLOYER", { id }),
      setBenefit: (id: string) => send("CHANGE_BENEFIT", { id }),

      saveCountry: () => send("SAVE_COUNTRY"),
      saveProfileData: () => send("SAVE_PROFILE"),
      createNewEmail: (email: string) => send("CREATE_NEW_EMAIL", { email }),
      doneAndSubmit: () => send("DONE_AND_SUBMIT"),
      resendVerificationEmail: () => send("RESEND_VERIFICATION_EMAIL"),
      hideVerificationEmailNotification: () =>
        send("HIDE_VERIFICATION_EMAIL_NOTIFICATION"),
    }),
    [
      context,
      canGoBack,
      requireAdditionalDetails,
      isLoading,
      stateMatches,
      send,
    ],
  );

  return (
    <OnboardingMachineContext.Provider value={value}>
      <OnboardingContainer />
    </OnboardingMachineContext.Provider>
  );
}
