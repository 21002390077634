/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BikeBrand
 */
export interface BikeBrand {
  /**
   *
   * @type {string}
   * @memberof BikeBrand
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof BikeBrand
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof BikeBrand
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof BikeBrand
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof BikeBrand
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the BikeBrand interface.
 */
export function instanceOfBikeBrand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;

  return isInstance;
}

export function BikeBrandFromJSON(json: any): BikeBrand {
  return BikeBrandFromJSONTyped(json, false);
}

export function BikeBrandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBrand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    active: !exists(json, "active") ? undefined : json["active"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function BikeBrandToJSON(value?: BikeBrand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    active: value.active,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
