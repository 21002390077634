import { useQuery } from "@tanstack/react-query";

import {
  BenefitDefinitionAccessRequestsApi,
  BenefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRequest,
  PaginatedUserBenefitDefinitionAccessRequestOut,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userBenefitDefinitionAccessRequestsAPI =
  new BenefitDefinitionAccessRequestsApi(apiConfiguration);

export const useGetBenefitDefinitionAccessRequests = (
  params?: BenefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRequest,
  options?: UseQueryOptions<PaginatedUserBenefitDefinitionAccessRequestOut>,
) => {
  return useQuery<
    PaginatedUserBenefitDefinitionAccessRequestOut,
    ResponseError
  >(
    ["accessRequests", params],
    () =>
      userBenefitDefinitionAccessRequestsAPI.benefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequests(
        params,
      ),
    options,
  );
};
