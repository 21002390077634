/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { SaleInvoiceLineType } from "./SaleInvoiceLineType";
import {
  SaleInvoiceLineTypeFromJSON,
  SaleInvoiceLineTypeFromJSONTyped,
  SaleInvoiceLineTypeToJSON,
} from "./SaleInvoiceLineType";

/**
 *
 * @export
 * @interface SaleInvoiceLine
 */
export interface SaleInvoiceLine {
  /**
   *
   * @type {SaleInvoiceLineType}
   * @memberof SaleInvoiceLine
   */
  type: SaleInvoiceLineType;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  vatRate: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  saleInvoiceId: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  subtotal: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLine
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLine
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceLine
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof SaleInvoiceLine
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the SaleInvoiceLine interface.
 */
export function instanceOfSaleInvoiceLine(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "quantity" in value;
  isInstance = isInstance && "vatRate" in value;
  isInstance = isInstance && "saleInvoiceId" in value;
  isInstance = isInstance && "subtotal" in value;
  isInstance = isInstance && "vat" in value;
  isInstance = isInstance && "total" in value;

  return isInstance;
}

export function SaleInvoiceLineFromJSON(json: any): SaleInvoiceLine {
  return SaleInvoiceLineFromJSONTyped(json, false);
}

export function SaleInvoiceLineFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: SaleInvoiceLineTypeFromJSON(json["type"]),
    description: json["description"],
    price: json["price"],
    quantity: json["quantity"],
    vatRate: json["vat_rate"],
    saleInvoiceId: json["sale_invoice_id"],
    subtotal: json["subtotal"],
    vat: json["vat"],
    total: json["total"],
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    updatedAt: !exists(json, "updated_at")
      ? undefined
      : new Date(json["updated_at"]),
  };
}

export function SaleInvoiceLineToJSON(value?: SaleInvoiceLine | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: SaleInvoiceLineTypeToJSON(value.type),
    description: value.description,
    price: value.price,
    quantity: value.quantity,
    vat_rate: value.vatRate,
    sale_invoice_id: value.saleInvoiceId,
    subtotal: value.subtotal,
    vat: value.vat,
    total: value.total,
    id: value.id,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at:
      value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
