import { useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import * as zod from "zod";

import { UserRead } from "@vapaus/api-codegen";
import { useFormMutation } from "@vapaus/form";
import { useCurrentUser, userApi } from "@vapaus/shared-api";
import { useToaster } from "@vapaus/ui-v2";

export function useChangeNameModal(onClose: () => void) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const queryClient = useQueryClient();
  const [hasSetDefault, setHasSetDefault] = useState<boolean>(false);

  const nameInputSchema = useMemo(
    () =>
      zod.object({
        firstName: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
        lastName: zod.string().nonempty(t("commonV2:fieldCantBeEmpty")),
      }),
    [t],
  );

  type NameInputDataType = zod.infer<typeof nameInputSchema>;

  const { formMethods, mutation } = useFormMutation<
    NameInputDataType,
    UserRead
  >(
    {
      mutationFn: (data) =>
        userApi.userUpdateCurrentUser({
          userUpdate: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(["currentUser"]);
        toaster.add("success", t("commonV2:changeNameModal.successMessage"));
        onClose();
      },
    },
    {
      resolver: zodResolver(nameInputSchema),
    },
  );

  useCurrentUser({
    enabled: !hasSetDefault,
    onSuccess(user) {
      if (!hasSetDefault) {
        formMethods.setValue("firstName", user.firstName ?? "");
        formMethods.setValue("lastName", user.lastName ?? "");
        setHasSetDefault(true);
      }
    },
  });

  // TODO proper resetting

  const handleReset = () => setHasSetDefault(false);

  const rootErrorMessage = formMethods.formState.errors.root?.message;

  return { formMethods, mutation, rootErrorMessage, handleReset };
}
