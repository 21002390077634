import { useState } from "react";

import {
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
} from "@vapaus/api-codegen";
import { BenefitTermsModal } from "@vapaus/common-v2";
import { Flex } from "@vapaus/ui-v2";

import { useGetBenefitDefinition } from "../../../../api/benefitDefinitions";
import { ContractSummaryCard } from "../ContractSummaryCard";
import { StatusCard } from "./components/StatusCard";

interface TabPanelContractProps {
  order: BikeBenefitOrderRead;
  contract: BikeBenefitContractRead;
}

export function TabPanelContract({ order, contract }: TabPanelContractProps) {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { data: benefitDefinition } = useGetBenefitDefinition(
    contract.benefitDefinitionId,
  );

  const bikeOrderPrice =
    contract.taxablePackagePrice + contract.downPaymentAmount;

  /*
  TODO: We should not need to rely on calculation API or on benefit definition for determining contract related values AT ALL
    Once this backend task is completed: https://vapaus.atlassian.net/browse/NEXT-2319, this is what the leasing cost should look like:

    const leasingCost =
      contract.insuranceFee * contract.leasingPeriodMonths +
      contract.totalFinancingCost +
      contract.totalServiceCost;
  */

  const effectiveMonthlyTaxableValue =
    contract.fixedMonthlyTaxableValue || contract.monthlyTaxableValue;

  const leasingCost =
    (effectiveMonthlyTaxableValue -
      contract.monthlyLeasingFee -
      contract.monthlyMaintenanceBudget) *
      contract.leasingPeriodMonths -
    (benefitDefinition?.deliveryFee || 0);

  return (
    <Flex direction="column" gap="lg">
      <StatusCard
        order={order}
        contract={contract}
        benefitDefinition={benefitDefinition}
        onModalOpen={() => setIsTermsModalOpen(true)}
      />
      <ContractSummaryCard
        benefitDefinition={benefitDefinition}
        redemptionPrice={contract.discountedResidualValue}
        residualRatio={contract.residualRatio}
        monthlyTaxableValue={
          contract.fixedMonthlyTaxableValue ?? contract.monthlyTaxableValue
        }
        leasingPeriodMonths={contract.leasingPeriodMonths}
        monthlyMaintenanceBudget={contract.monthlyMaintenanceBudget}
        downPaymentAmount={contract.downPaymentAmount}
        bikeOrderPrice={bikeOrderPrice}
        leasingCost={leasingCost}
      />
      <BenefitTermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        title={benefitDefinition?.organisation.name ?? ""}
        content={benefitDefinition?.currentBenefitDefinitionTerm?.content ?? ""}
      />
    </Flex>
  );
}
