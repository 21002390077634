import { createSvgIcon } from "./SvgIcon";

export const DoneIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M9.55015 17.5749C9.41682 17.5749 9.29182 17.5541 9.17515 17.5124C9.05849 17.4707 8.95015 17.3999 8.85015 17.2999L4.55015 12.9999C4.36682 12.8166 4.27932 12.5791 4.28765 12.2874C4.29599 11.9957 4.39182 11.7582 4.57515 11.5749C4.75849 11.3916 4.99182 11.2999 5.27515 11.2999C5.55849 11.2999 5.79182 11.3916 5.97515 11.5749L9.55015 15.1499L18.0252 6.6749C18.2085 6.49157 18.446 6.3999 18.7377 6.3999C19.0293 6.3999 19.2668 6.49157 19.4502 6.6749C19.6335 6.85824 19.7252 7.09574 19.7252 7.3874C19.7252 7.67907 19.6335 7.91657 19.4502 8.0999L10.2502 17.2999C10.1502 17.3999 10.0418 17.4707 9.92515 17.5124C9.80849 17.5541 9.68349 17.5749 9.55015 17.5749Z" />
  </svg>,
  "Done",
);
