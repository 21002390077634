/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  ShopTerms,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ShopTermsFromJSON,
  ShopTermsToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface ShopTermsAcceptShopTermsRequest {
  shopTermsId: string;
}

export interface ShopTermsDownloadShopTermsRequest {
  shopTermsId: string;
}

export interface ShopTermsGetShopTermsRequest {
  shopTermsId: string;
}

/**
 *
 */
export class ShopTermsApi extends runtime.BaseAPI {
  /**
   * Accept Shop Terms
   */
  async shopTermsAcceptShopTermsRaw(
    requestParameters: ShopTermsAcceptShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.shopTermsId === null ||
      requestParameters.shopTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "shopTermsId",
        "Required parameter requestParameters.shopTermsId was null or undefined when calling shopTermsAcceptShopTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/shop-terms/{shop_terms_id}/accept`.replace(
          `{${"shop_terms_id"}}`,
          encodeURIComponent(String(requestParameters.shopTermsId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept Shop Terms
   */
  async shopTermsAcceptShopTerms(
    requestParameters: ShopTermsAcceptShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.shopTermsAcceptShopTermsRaw(requestParameters, initOverrides);
  }

  /**
   * Current Shop Terms
   */
  async shopTermsCurrentShopTermsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopTerms>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/shop-terms/current`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopTermsFromJSON(jsonValue),
    );
  }

  /**
   * Current Shop Terms
   */
  async shopTermsCurrentShopTerms(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopTerms> {
    const response = await this.shopTermsCurrentShopTermsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Download Shop Terms
   */
  async shopTermsDownloadShopTermsRaw(
    requestParameters: ShopTermsDownloadShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.shopTermsId === null ||
      requestParameters.shopTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "shopTermsId",
        "Required parameter requestParameters.shopTermsId was null or undefined when calling shopTermsDownloadShopTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/shop-terms/{shop_terms_id}/download`.replace(
          `{${"shop_terms_id"}}`,
          encodeURIComponent(String(requestParameters.shopTermsId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Download Shop Terms
   */
  async shopTermsDownloadShopTerms(
    requestParameters: ShopTermsDownloadShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.shopTermsDownloadShopTermsRaw(requestParameters, initOverrides);
  }

  /**
   * Get Shop Terms
   */
  async shopTermsGetShopTermsRaw(
    requestParameters: ShopTermsGetShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ShopTerms>> {
    if (
      requestParameters.shopTermsId === null ||
      requestParameters.shopTermsId === undefined
    ) {
      throw new runtime.RequiredError(
        "shopTermsId",
        "Required parameter requestParameters.shopTermsId was null or undefined when calling shopTermsGetShopTerms.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/shop-api/shop-terms/{shop_terms_id}`.replace(
          `{${"shop_terms_id"}}`,
          encodeURIComponent(String(requestParameters.shopTermsId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShopTermsFromJSON(jsonValue),
    );
  }

  /**
   * Get Shop Terms
   */
  async shopTermsGetShopTerms(
    requestParameters: ShopTermsGetShopTermsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ShopTerms> {
    const response = await this.shopTermsGetShopTermsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
