import { createSvgIcon } from "./SvgIcon";

export const UnsubscribeIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V11.7C21.7 11.55 21.3792 11.425 21.0375 11.325C20.6958 11.225 20.35 11.15 20 11.1V8L12.525 12.675C12.4417 12.725 12.3542 12.7625 12.2625 12.7875C12.1708 12.8125 12.0833 12.825 12 12.825C11.9167 12.825 11.8292 12.8125 11.7375 12.7875C11.6458 12.7625 11.5583 12.725 11.475 12.675L4 8V18H12C12 18.35 12.025 18.6875 12.075 19.0125C12.125 19.3375 12.2 19.6667 12.3 20H4ZM12 11L20 6H4L12 11ZM4 18V11V11.075V8V8.25V6.8C4 6.61667 4 6.61667 4 6.8V6.7875V8.25V8V18ZM19 23C17.6167 23 16.4375 22.5125 15.4625 21.5375C14.4875 20.5625 14 19.3833 14 18C14 16.6167 14.4875 15.4375 15.4625 14.4625C16.4375 13.4875 17.6167 13 19 13C20.3833 13 21.5625 13.4875 22.5375 14.4625C23.5125 15.4375 24 16.6167 24 18C24 19.3833 23.5125 20.5625 22.5375 21.5375C21.5625 22.5125 20.3833 23 19 23ZM16.5 18.5H21.5C21.6333 18.5 21.75 18.45 21.85 18.35C21.95 18.25 22 18.1333 22 18C22 17.8667 21.95 17.75 21.85 17.65C21.75 17.55 21.6333 17.5 21.5 17.5H16.5C16.3667 17.5 16.25 17.55 16.15 17.65C16.05 17.75 16 17.8667 16 18C16 18.1333 16.05 18.25 16.15 18.35C16.25 18.45 16.3667 18.5 16.5 18.5Z" />
  </svg>,
  "Unsubscribe",
);
