import { Trans, useTranslation } from "react-i18next";

import { Path } from "../../../constants/paths";
import { OnboardingFinalStepPageLayout } from "../layout/OnboardingFinalStepPageLayout";
import { useOnboardingMachine } from "../stateMachine";

export function RequestSentPage() {
  const { t } = useTranslation();
  const { selectedEmployer } = useOnboardingMachine();
  return (
    <OnboardingFinalStepPageLayout
      title={t("userV2:onboarding.requestSent.title")}
      subtitle={
        <Trans
          i18nKey="userV2:onboarding.requestSent.subtitle"
          values={{ employer: selectedEmployer?.name || "" }}
          components={{ b: <b /> }}
        />
      }
      nextButtonText={t("userV2:onboarding.requestSent.gotIt")}
      nextButtonPath={`../${Path.benefits}`}
    />
  );
}
