import { useCallback } from "react";

import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  BikeBenefitContractRead,
  BikeBenefitContractState,
} from "@vapaus/api-codegen";

import { CONTRACT_CANCELLATION_FROM_STATE } from "../../../../constants/contractNavigation";
import { EMAIL_VERIFICATION_RETURN_PROCESS_KEY } from "../../../../constants/emailVerification";
import { Path } from "../../../../constants/paths";
import { ReturnProcessWizardView } from "./ReturnProcessWizardView";
import { ReturnProcessProvider } from "./context/ReturnProcessContext";

export function ReturnProcessWizard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();

  const handleClose = useCallback(() => {
    const { navigatedFrom } = location.state || {};
    if (navigatedFrom === CONTRACT_CANCELLATION_FROM_STATE) {
      navigate(-1);
    } else {
      navigate("..");
    }
  }, [location.state, navigate]);

  const { contract } = useOutletContext<{
    contract?: BikeBenefitContractRead;
  }>();
  const [searchParams] = useSearchParams();

  if (!contract) return <Navigate to={`/${Path.myBikes}/${orderId}`} replace />;

  if (
    contract.state !== BikeBenefitContractState.Active &&
    searchParams.has(EMAIL_VERIFICATION_RETURN_PROCESS_KEY)
  ) {
    return <Navigate to={`/${Path.profile}/${Path.settings}`} replace />;
  }

  if (
    contract.state !== BikeBenefitContractState.Active &&
    contract.state !== BikeBenefitContractState.ActivePendingReturn
  ) {
    return (
      <Navigate
        to={`/${Path.myBikes}/${contract.bikeBenefitOrderId}`}
        replace
      />
    );
  }

  return (
    <ReturnProcessProvider>
      <ReturnProcessWizardView onClose={handleClose} />
    </ReturnProcessProvider>
  );
}
